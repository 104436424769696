import React, { useState, useEffect, useContext } from 'react';
import { Text } from 'liber-components';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';

import ScreenTitle from '../../../common-components/ScreenTitle/ScreenTitle';
import {
  CardsRow,
  SelectableCard,
  Row,
  GlobalStyle,
  Container,
} from './InvestorRenegotiations.styles';
import { DASHBOARD_PATH, monetizationIconPath } from '../utils';
import RenegotiationsTable from './RenegotiationsTable';
import Api from '../api';
import { TokenContext } from '../../../hocs/withTokenProvider';
import { handleError, convertToCamelCase } from '../../../vendor/Utils';
import { Loading, Bunny } from './RenegotiationsTable.styles';

const initialValues = {
  total: 0,
  accepted: {
    quantity: 0,
    amount: 'R$ 0,00',
  },
  paid: {
    quantity: 0,
    amount: 'R$ 0,00',
  },
};

const InvestorRenegotiations = () => {
  const [summary, setSummary] = useState(initialValues);
  const [loading, setLoading] = useState(true);
  const [showPaidOnly, setShowPaidOnly] = useState(false);

  const { token } = useContext(TokenContext);

  useEffect(() => {
    Api.fetchSummary(token)
      .then(response => {
        const { overview } = response?.data || {};
        setSummary(overview ? convertToCamelCase(overview) : initialValues);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        handleError();
      });
  }, []);

  const handleCardClick = type => () => {
    setShowPaidOnly(type === 'paid');
  };

  const handleBack = () => {
    window.location.assign(DASHBOARD_PATH);
  };

  const { total, accepted, paid } = summary || {};

  return (
    <>
      <GlobalStyle />
      <ScreenTitle handleBack={handleBack}>Renegociações</ScreenTitle>
      <Loading loading={loading ? 1 : 0}>
        {loading ? <Bunny /> : null}
        <Container>
          <Text large>
            Existem <b>{total}</b> renegociações, {accepted?.quantity} aceitas e {paid?.quantity}{' '}
            pagas.
            <br />
            Verifique a seguir as informações dos títulos renegociados.
          </Text>
          <CardsRow>
            <SelectableCard selected={!showPaidOnly} onClick={handleCardClick('accepted')}>
              <Row>
                <Text large>
                  <Icon path={mdiCheckCircle} />
                  <b>Aceitas</b>
                </Text>
              </Row>
              <Row end={1}>
                <Text xlarge>{accepted?.amount}</Text>
              </Row>
            </SelectableCard>
            <SelectableCard selected={showPaidOnly} onClick={handleCardClick('paid')}>
              <Row>
                <Text large>
                  <Icon path={monetizationIconPath} />
                  <b>Pagas</b>
                </Text>
              </Row>
              <Row end={1}>
                <Text xlarge>{paid?.amount}</Text>
              </Row>
            </SelectableCard>
          </CardsRow>
        </Container>
      </Loading>
      <RenegotiationsTable showPaidOnly={showPaidOnly} />
    </>
  );
};

export default InvestorRenegotiations;
