import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextFieldFilter from '../../../../../common-components/Filters/TextFieldFilter/TextFieldFilter';
import DateFieldFilter from '../../../../../common-components/Filters/DateFieldFilter/DateFieldFilter';
import {
  FilterText,
  CleanFilters,
  Button,
  Row,
  ButtonsRow,
  FiltersRow,
  FlexColumn,
} from './Filters.styles';
import {
  VENDOR_NAME_FILTER,
  VENDOR_CNPJ_FILTER,
  VENDOR_EMAIL_FILTER,
  VENDOR_REPORT_COOKIE_NAME,
  BLOCKED_ORGANIZATIONS_UPDATED_AT_LTEQ,
  BLOCKED_ORGANIZATIONS_UPDATED_AT_GTEQ,
  STATUS_FILTER,
  STATUS_OPTIONS,
} from '../../../constants';
import LoadingButton from '../../../../../common-components/Buttons/LoadingButton';
import {
  dispatchAmplitudeEvent,
  handleError,
  handleNavigate,
  setCookie,
} from '../../../../../vendor/Utils';
import { IMPORT_VENDORS_PATH } from '../../../urls';
import { fetchVendorList } from '../../../api';
import { Context } from '../../../context';
import FilterTag from '../../../../../common-components/Filters/FilterTag';
import FilterFooter from '../../../../../common-components/Filters/FilterFooter';
import SelectTag from '../../../../../common-components/SelectTag/SelectTag';

const hasFilters = filters =>
  Object.keys(filters).reduce((flag, key) => flag || Boolean(filters[key]), false);

export const Filters = ({ clearFilters, changeFilters, filters, mustApproveVendors }) => {
  const [reportLoading, setReportLoading] = useState(false);
  const [isSignatureFilterOpen, setIsSignatureFilterOpen] = useState(false);
  const [signatureSelected, setSignatureSelected] = useState(null);

  const { token } = useContext(Context);

  const handleFetchVendorList = async () => {
    dispatchAmplitudeEvent('buyer_vendors_export');

    setReportLoading(true);
    const reportGeneratedAt = new Date();
    const startTime = reportGeneratedAt.getTime();
    reportGeneratedAt.setUTCHours(0, 0, 0, 0);

    fetchVendorList(token, filters)
      .then(({ fileUrl }) => {
        setReportLoading(false);

        const duration = new Date().getTime() - startTime;
        dispatchAmplitudeEvent(VENDOR_REPORT_COOKIE_NAME, { duration });
        setCookie('vendors_report_last_generated', reportGeneratedAt.toISOString(), 90);

        window.open(fileUrl);
      })
      .catch(() => {
        handleError();
        setReportLoading(false);
      });
  };

  return (
    <Row>
      <FilterText>Filtrar por: </FilterText>
      <FiltersRow>
        <TextFieldFilter
          label="Empresa"
          selectedItems={filters[VENDOR_NAME_FILTER]}
          onFilter={text => changeFilters(VENDOR_NAME_FILTER, text)}
          onClear={() => changeFilters(VENDOR_NAME_FILTER, null)}
        />
        <TextFieldFilter
          label="CNPJ"
          selectedItems={filters[VENDOR_CNPJ_FILTER]}
          onFilter={text => changeFilters(VENDOR_CNPJ_FILTER, text)}
          onClear={() => changeFilters(VENDOR_CNPJ_FILTER, null)}
        />
        <TextFieldFilter
          label="E-Mail"
          selectedItems={filters[VENDOR_EMAIL_FILTER]}
          onFilter={text => changeFilters(VENDOR_EMAIL_FILTER, text)}
          onClear={() => changeFilters(VENDOR_EMAIL_FILTER, null)}
        />
        {mustApproveVendors && (
          <DateFieldFilter
            label="Data cadastro sacado"
            onFilter={date => {
              changeFilters(
                BLOCKED_ORGANIZATIONS_UPDATED_AT_GTEQ,
                moment(date.start).format('YYYY-MM-DD'),
              );
              changeFilters(
                BLOCKED_ORGANIZATIONS_UPDATED_AT_LTEQ,
                moment(date.end).format('YYYY-MM-DD'),
              );
            }}
            onClear={() => {
              changeFilters(BLOCKED_ORGANIZATIONS_UPDATED_AT_GTEQ, null);
              changeFilters(BLOCKED_ORGANIZATIONS_UPDATED_AT_LTEQ, null);
            }}
            selectedItems={[
              filters[BLOCKED_ORGANIZATIONS_UPDATED_AT_GTEQ],
              filters[BLOCKED_ORGANIZATIONS_UPDATED_AT_LTEQ],
            ]}
          />
        )}

        <FilterTag
          show={isSignatureFilterOpen}
          onClear={() => {
            changeFilters(STATUS_FILTER, null);
            setSignatureSelected(null);
          }}
          onChange={setIsSignatureFilterOpen}
          label="Aprovação cadastral"
          selected={signatureSelected ? [signatureSelected.label] : []}
        >
          <FlexColumn>
            {STATUS_OPTIONS.map(option => (
              <SelectTag
                key={option.label}
                selected={signatureSelected && signatureSelected.value === option.value}
                onChange={() => {
                  setSignatureSelected(option);
                }}
              >
                {option.label}
              </SelectTag>
            ))}
          </FlexColumn>
          <FilterFooter
            disabled={!signatureSelected}
            onClear={() => {
              changeFilters(STATUS_FILTER, null);
              setSignatureSelected(null);
            }}
            onFilter={() => {
              setIsSignatureFilterOpen(false);
              changeFilters(STATUS_FILTER, signatureSelected.value);
            }}
          />
        </FilterTag>
        {hasFilters(filters) ? (
          <CleanFilters onClick={() => clearFilters()}>Limpar filtros</CleanFilters>
        ) : null}
      </FiltersRow>
      <ButtonsRow>
        <Button outlined onClick={() => handleNavigate(IMPORT_VENDORS_PATH)}>
          IMPORTAR FORNECEDORES
        </Button>
        <LoadingButton
          id="download-report-btn"
          ButtonComponent={Button}
          onClick={handleFetchVendorList}
          loading={reportLoading}
        >
          EXPORTAR FORNECEDORES
        </LoadingButton>
      </ButtonsRow>
    </Row>
  );
};

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any),
  changeFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  mustApproveVendors: PropTypes.bool,
};

Filters.defaultProps = {
  filters: {},
  changeFilters: () => {},
  clearFilters: () => {},
  mustApproveVendors: false,
};

export default Filters;
