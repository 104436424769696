import styled from 'styled-components';
import { LoadingBunny as Bunny } from 'liber-components';

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 522px;
  height: 418px;
  position: relative;
`;

export const LoadingBunny = styled(Bunny)`
  width: 161px;
  height: 128px;
`;

export const ProgressContainer = styled.div`
  margin-top: 24px;
  width: 70%;
`;

export const ProgressText = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: -0.29px;
  text-align: center;
  color: #798e9b;
`;

export const TitleText = styled.span`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 0.35px;
  text-align: center;
  color: #405f71;
  margin-bottom: 8px;
`;

export const DescriptionText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.38px;
  text-align: center;
  color: #798e9b;
`;
