import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer } from 'liber-components';
import hydrateStore from '../store';
import ChangePassword from './components/ChangePassword';

const View = ({ store }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <ChangePassword />
    </Provider>
    <ToastContainer />
  </>
);

View.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

View.defaultProps = {};

export default View;
