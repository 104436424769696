import { connect } from 'react-redux';
import Confirmacao from './Confirmacao';
import { fetchContratos } from '../../../actions/contratos';
import {
  previousStep as onPrevious,
  nextStep as onNext,
  setCurrent,
} from '../../../actions/progressBar';
import { getDadosPessoais } from '../../../presenters/dadosPessoais';
import { getDocumentacao } from '../../../presenters/documentacao';
import { getEnderecos } from '../../../presenters/enderecos';
import { getDadosBancarios } from '../../../presenters/dadosBancarios';

const mapStateToProps = state => ({
  dadosBancarios: getDadosBancarios(state),
  dadosPessoais: getDadosPessoais(state),
  documentacao: getDocumentacao(state),
  enderecos: getEnderecos(state),
});

const mapDispatchToProps = {
  fetchContratos,
  setCurrent,
  onPrevious,
  onNext,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmacao);
