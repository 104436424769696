import styled from 'styled-components';

export const LimiterContainer = styled.div`
  width: 100%;
  min-width: 930px;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 160px;
  margin: 24px 0 -80px 0;
`;
