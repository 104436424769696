import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HandleFunderStatusModal from '../../../../common-components/HandleFunderStatusModal';
import { handleError, handleSuccess } from '../../../../vendor/Utils';
import {
  postBatchBlockUnblock,
  postVendorBlockUnblock,
  postVendorBlockUnblockOnBuyer,
} from '../../api';
import {
  fetchVendors,
  handleVendorBlockUnblockModalCancel,
  handleClearBatchBlockUnblock,
  setIsBatchBlockUnblock,
} from '../../actions/AdminVendorsActions';
import { ACTION_KIND, STATUSES } from '../../constants';

export const VendorBlockUnblockModal = ({
  selectedFunderToBlockOrUnblock,
  token,
  setVendorBlockUnblockModalCancel,
  showVendorBlockUnblockModal,
  vendors,
  getVendors,
  isBatch,
  selectedCnpjs,
  clearBatchBlockUnblock,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { target, action, name, id } = selectedFunderToBlockOrUnblock;

  const getSelectedVendorCnpj = vendorId =>
    vendors
      .filter(vendor => vendor.id === vendorId)
      .map(vendor => String(vendor.cnpj).replace(/\D/g, ''));

  const onLeaveModal = () => {
    setVendorBlockUnblockModalCancel(id);
    if (isBatch) {
      clearBatchBlockUnblock();
    }
  };

  const handleVendorBlockUnblockFunder = async comment => {
    const payload = {
      cnpjs: getSelectedVendorCnpj(id),
      comment,
      funder_id: target.id,
      status: STATUSES[action],
    };

    await postVendorBlockUnblock(token, payload);
  };

  const handleVendorBlockUnblockBuyer = async comment => {
    const payload = {
      comment,
      vendor_ids: [id],
    };

    await postVendorBlockUnblockOnBuyer(token, action, payload);
  };

  const onConfirmAction = {
    funder: handleVendorBlockUnblockFunder,
    buyer: handleVendorBlockUnblockBuyer,
  };

  const onSuccess = async () => {
    onLeaveModal();
    handleSuccess(`${ACTION_KIND[action]} realizado com sucesso.`);
    await getVendors();
  };

  const onConfirmBatch = async comment => {
    const payload = {
      cnpjs: selectedCnpjs,
      comment,
      funder_id: target.id,
      status: STATUSES[action],
    };

    await postBatchBlockUnblock(token, payload);
  };

  const handleBlockUnblock = comment =>
    isBatch ? onConfirmBatch(comment) : onConfirmAction[target.kind](comment);

  const onConfirm = async comment => {
    setIsLoading(true);
    try {
      await handleBlockUnblock(comment);
      await onSuccess();
    } catch {
      handleError(`Ocorreu um erro ao realizar o ${ACTION_KIND[action]}.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HandleFunderStatusModal
      vendor={name}
      funder={target?.name}
      onConfirm={onConfirm}
      onCancel={onLeaveModal}
      action={action}
      kind={target?.kind}
      show={showVendorBlockUnblockModal}
      isLoading={isLoading}
      isBatch={isBatch}
    />
  );
};

VendorBlockUnblockModal.propTypes = {
  selectedFunderToBlockOrUnblock: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    action: PropTypes.oneOf(['block', 'unblock']),
    target: PropTypes.shape({
      id: PropTypes.number,
      kind: PropTypes.oneOf(['funder', 'buyer']),
      name: PropTypes.string,
    }),
  }).isRequired,
  token: PropTypes.string.isRequired,
  setVendorBlockUnblockModalCancel: PropTypes.func.isRequired,
  showVendorBlockUnblockModal: PropTypes.bool.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      blockedByBuyer: PropTypes.bool,
      buyer: PropTypes.shape({
        company: PropTypes.string,
        id: PropTypes.number,
      }),
      cnpj: PropTypes.string,
      company: PropTypes.string,
      editVendorPath: PropTypes.string,
      funders: PropTypes.shape({
        active: PropTypes.number,
        total: PropTypes.number,
      }),
      fundersIds: PropTypes.arrayOf(PropTypes.number),
      loginAs: PropTypes.string,
      showVendorPath: PropTypes.string,
      signature: PropTypes.shape({
        type: PropTypes.string,
        method: PropTypes.string,
      }),
      status: PropTypes.string,
      tradeName: PropTypes.string,
    }),
  ).isRequired,
  getVendors: PropTypes.func.isRequired,
  isBatch: PropTypes.bool,
  selectedCnpjs: PropTypes.arrayOf(PropTypes.string),
  clearBatchBlockUnblock: PropTypes.func.isRequired,
};

VendorBlockUnblockModal.defaultProps = {
  isBatch: false,
  selectedCnpjs: [],
};

const mapStateToProps = ({
  adminVendors: {
    selectedFunderToBlockOrUnblock,
    showVendorBlockUnblockModal,
    token,
    vendors,
    selectedCnpjsToBatchBlockUnblock,
    isBatchBlockUnblock,
  },
}) => ({
  selectedFunderToBlockOrUnblock,
  showVendorBlockUnblockModal,
  token,
  vendors,
  selectedCnpjs: selectedCnpjsToBatchBlockUnblock,
  isBatch: isBatchBlockUnblock,
});

const mapDispatchToProps = {
  setVendorBlockUnblockModalCancel: handleVendorBlockUnblockModalCancel,
  getVendors: fetchVendors,
  handleIsBatchBlockUnblock: setIsBatchBlockUnblock,
  clearBatchBlockUnblock: handleClearBatchBlockUnblock,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorBlockUnblockModal);
