import React from 'react';
import PropTypes from 'prop-types';

import { SelectionTableRow } from 'liber-components';

import { tabType, INVOICE_TABS, INVOICE_TABLE_COLUMNS, ALL_TAB_COLUMNS } from '../../utils';
import { Header, Table } from './InvoiceSimulationTableHeader.styles';
import InvoiceSimulationTableHeaderColumn from './InvoiceSimulationTableHeaderColumn';
import {
  SelectionTableHeaderColumn,
  ActionHeaderContainer,
  CheckboxTable,
} from './InvoiceSimulationTable.styles';

const { AVAILABLE, ALL } = INVOICE_TABS;

const InvoiceSimulationTableHeader = ({
  onSort,
  orderedColumn,
  sortAsc,
  sortDesc,
  activeTab,
  isAllOfPageAreSelected,
  selectStatus,
  onSelectAllInvoicesPerPage,
}) => {
  const columns = activeTab === ALL ? ALL_TAB_COLUMNS : INVOICE_TABLE_COLUMNS;
  const checkBoxColor = selectStatus === 'multiple' ? '#fabe00' : null;
  const proportionSpaceAvailable = activeTab === AVAILABLE ? 0.87 : 0.9;

  return (
    <Table>
      <Header>
        <SelectionTableRow>
          {activeTab === AVAILABLE && (
            <SelectionTableHeaderColumn width="3%">
              <CheckboxTable
                borderSize={2}
                color={checkBoxColor}
                onChange={onSelectAllInvoicesPerPage}
                checked={isAllOfPageAreSelected}
              />
            </SelectionTableHeaderColumn>
          )}
          {columns.map(({ name, label, width }) => (
            <InvoiceSimulationTableHeaderColumn
              key={name}
              orderedColumn={orderedColumn}
              column={name}
              onSort={onSort}
              sortAsc={sortAsc}
              sortDesc={sortDesc}
              width={`${proportionSpaceAvailable * width}%`}
            >
              {label}
            </InvoiceSimulationTableHeaderColumn>
          ))}
          <SelectionTableHeaderColumn width="10%">
            <ActionHeaderContainer>Ações</ActionHeaderContainer>
          </SelectionTableHeaderColumn>
        </SelectionTableRow>
      </Header>
    </Table>
  );
};

export const tableHeaderShape = PropTypes.shape({
  activeTab: tabType,
  selectStatus: PropTypes.string,
  onSort: PropTypes.func,
  orderedColumn: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortDesc: PropTypes.bool,
  isAllOfPageAreSelected: PropTypes.bool,
  onSelectAllInvoicesPerPage: PropTypes.func.isRequired,
});

InvoiceSimulationTableHeader.propTypes = {
  activeTab: tabType,
  selectStatus: PropTypes.string,
  onSort: PropTypes.func,
  orderedColumn: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortDesc: PropTypes.bool,
  isAllOfPageAreSelected: PropTypes.bool,
  onSelectAllInvoicesPerPage: PropTypes.func.isRequired,
};

InvoiceSimulationTableHeader.defaultProps = {
  selectStatus: 'empty',
  activeTab: AVAILABLE,
  onSort: () => {},
  orderedColumn: '',
  sortAsc: false,
  sortDesc: false,
  isAllOfPageAreSelected: false,
};

export default InvoiceSimulationTableHeader;
