import styled, { css } from 'styled-components';
import { DisplayRow as BaseDisplayRow, handleThemeFromObject } from 'liber-components';

export const BarDisplayRow = styled(BaseDisplayRow)`
  margin-top: 10px;
`;

export const SimpleDisplayRow = styled(BaseDisplayRow)`
  min-height: 105px;
`;

export const TooltipWrapper = styled.div`
  position: relative;

  & svg {
    width: 16px;
    height: 16px;
    fill: #798e9b;
    margin-left: 4px;
    cursor: pointer;
  }

  & svg:hover {
    fill: ${({ theme }) => handleThemeFromObject(theme, 'colors.primary', '#009dff')};
  }
`;

export const MainValueLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font: Regular 16px/1px Roboto;
  letter-spacing: 0.06px;
  color: #798e9b;

  & svg {
    width: 16px;
    height: 16px;
    fill: #798e9b;
    margin-left: 4px;
    cursor: pointer;
  }

  & svg:hover {
    fill: ${({ theme }) => handleThemeFromObject(theme, 'colors.primary', '#009dff')};
  }
`;

export const MainValueRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #405f71;
  margin-bottom: -8px;
`;

export const MainValuePrefix = styled.div`
  font-size: 16px;
  letter-spacing: 0.06px;
  margin-right: 8px;
  margin-top: 12px;
`;

export const MainValue = styled.div`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.13px;

  @media (max-width: 439px) {
    margin-bottom: 24px;
  }
`;

export const DisplayRow = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  padding-top: 0px;
  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  @media (max-width: 439px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 0px;
  }
`;

export const Divider = styled.div`
  @media (min-width: 440px) {
    height: 73px;
    border-left: 1px solid #e8ebee;
  }
`;

export const UpdateInfo = styled.div`
  font-size: 12px;
  weight: 500,
  color: #6287a7;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
