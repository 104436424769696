import { TARGETING_MODE_INTEGRATION } from '../../common-components/EditOperationMode/constants';

export const VENDORS_GROUPS_PATH = '/sacado/fornecedores/grupos';
export const VENDORS_GROUPS_SHOW_PATH = '/sacado/fornecedores/grupos/:id';

export const apiUrl = SERVICE_API_URL; // eslint-disable-line

const vendorsRoute = '/api/v1/buyer/vendors';
const sortingParam = 'q[s]=trade_name asc';

export const VENDORS_LIST_URL = `${apiUrl}${vendorsRoute}?q[vendors_group_id_not_eq]=:id&${sortingParam}`;
export const GROUP_VENDORS_LIST_URL = `${apiUrl}${vendorsRoute}?q[vendors_group_id_eq]=:id&${sortingParam}`;
export const CREATE_GROUP_URL = `${apiUrl}/api/v1/buyer/vendors_groups`;
export const EDIT_GROUP_URL = `${apiUrl}/api/v1/buyer/vendors_groups/:id`;
export const SHOW_GROUP_URL = `${apiUrl}/api/v1/buyer/vendors_groups/:id`;
export const FUNDERS_LIST_URL = `${apiUrl}/api/v1/buyer/buyer_funder_agreements/funders`;
export const BUYER_USER_URL = `${apiUrl}/api/v1/buyer/user`;

export const REQUEST_TIMEOUT = 60 * 4 * 1000; // 4 mins
export const MAX_VENDOR_AMOUNT = Number.MAX_SAFE_INTEGER;
export const BATCH_SIZE = 1000;

export const DEFAULT_GROUP_NAME = 'Padrão';
export const DEFAULT_FUNDER_TARGETING_MODE = TARGETING_MODE_INTEGRATION;
