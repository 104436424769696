import React, { useContext, useState } from 'react';
import { TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import RatesGraph from '../RatesGraph';
import SpreadsheetRatesUploadModal from '../../../../common-components/SpreadsheetRatesUploadModal';
import { RatesPreviewContext } from '../../context';
import * as S from './styles';
import ScreenTitle from '../../../../common-components/ScreenTitle';
import GroupsList from './components/GroupsList';
import { sendSpreadsheet } from '../../api';
import ConfirmationModal from '../ConfirmationModal';

const RatesConfirmation = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    loading,
    token,
    exampleFile,
    roomId,
    ratesPreview: { fileName, buyers },
  } = useContext(RatesPreviewContext);

  const onUploadModalLeaved = () => setShowUploadModal(false);

  return (
    <S.Loading loading={loading}>
      {loading && <S.Bunny />}
      <S.Container>
        <S.Flex>
          <TooltipBox mount="top" fixed content="Voltar">
            <S.IconButton onClick={() => window.history.back()}>
              <Icon path={mdiArrowLeft} />
            </S.IconButton>
          </TooltipBox>
          <ScreenTitle>Novas Taxas</ScreenTitle>
        </S.Flex>
        <S.FlexEnd alignItems="end" gap="20px">
          <div>
            <S.LabelTitle>Arquivo</S.LabelTitle>
            <S.Input value={fileName} />
          </div>
          <S.SecondaryButton onClick={() => setShowUploadModal(true)}>
            Substituir arquivo
          </S.SecondaryButton>
        </S.FlexEnd>
        <S.LabelTitle>{buyers.length > 1 ? 'Sacados' : 'Sacado'}</S.LabelTitle>
        <S.Label>{buyers.map(({ name }) => name).join(', ')}</S.Label>
        <GroupsList buyers={buyers} />
        <SpreadsheetRatesUploadModal
          show={showUploadModal}
          onLeaved={onUploadModalLeaved}
          sendSpreadsheet={sendSpreadsheet}
          roomId={roomId}
          token={token}
          exampleFile={exampleFile}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          onLeaved={() => setShowConfirmationModal(false)}
        />
        <RatesGraph />
        <S.ButtonsContainer>
          <S.FlatButton onClick={() => window.history.back()}>Cancelar</S.FlatButton>
          <S.PrimaryButton onClick={() => setShowConfirmationModal(true)}>
            Confirmar
          </S.PrimaryButton>
        </S.ButtonsContainer>
      </S.Container>
    </S.Loading>
  );
};

export default RatesConfirmation;
