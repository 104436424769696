import axios from 'axios';
import { FETCH_GROUPS_URL } from './constants';

export const fetchGroups = (token, params) =>
  axios.get(FETCH_GROUPS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
