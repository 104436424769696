export const getId = (state, index) => state.dadosBancarios[index].id || -1;
export const getDefault = (state, index) => Boolean(state.dadosBancarios[index].default);
export const getKind = (state, index) => state.dadosBancarios[index].kind || '';
export const getBankCode = (state, index) => state.dadosBancarios[index].bank_code || '';
export const getAgency = (state, index) => state.dadosBancarios[index].agency || '';
export const getAgencyDigit = (state, index) => state.dadosBancarios[index].agency_digit || '';
export const getAccount = (state, index) => state.dadosBancarios[index].account || '';
export const getAccountDigit = (state, index) => state.dadosBancarios[index].account_digit || '';
export const getConjunct = (state, index) => Boolean(state.dadosBancarios[index].conjunct);
export const getSavingsComplement = (state, index) =>
  state.dadosBancarios[index].savings_complement || '';
export const getIsPatch = (state, index) => Boolean(state.dadosBancarios[index].is_patch);
export const getErrors = (state, index) => state.dadosBancarios[index].errors || {};
export const getDadosBancarios = state =>
  Object.keys(state.dadosBancarios).reduce(
    (last, index) => ({
      ...last,
      [index]: {
        id: getId(state, index),
        default: getDefault(state, index),
        kind: getKind(state, index),
        bankCode: getBankCode(state, index),
        agency: getAgency(state, index),
        agencyDigit: getAgencyDigit(state, index),
        account: getAccount(state, index),
        accountDigit: getAccountDigit(state, index),
        conjunct: getConjunct(state, index),
        savingsComplement: getSavingsComplement(state, index),
        isPatch: getIsPatch(state, index),
        errors: getErrors(state, index),
      },
    }),
    {},
  );
