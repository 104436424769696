import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hasFilters } from '../../../../vendor/Utils';
import DateFieldFilter from '../../../Filters/DateFieldFilter/DateFieldFilter';
import TextFieldFilter from '../../../Filters/TextFieldFilter/TextFieldFilter';
import { EXPIRES_AT_LT, EXPIRES_AT_GT, DUPLICATA_NUMBER_IN } from '../../utils';
import { updateDetailsFilters } from '../../actions/VendorAdvancesActions';
import { FilterText, CleanFilters } from './DetailsDrawerFilters.styles';

const emptyExpiresAtFilter = {
  [EXPIRES_AT_LT]: null,
  [EXPIRES_AT_GT]: null,
};

export const DetailsDrawerFilters = ({ filters, updateFilters }) => {
  const handleClearExpiresAtFilter = () => {
    updateFilters({ ...filters, ...emptyExpiresAtFilter });
  };

  const handleChangeExpiresAtFilter = range => {
    const expiresAtFilter = { ...emptyExpiresAtFilter };

    if (range && range.end) {
      expiresAtFilter[EXPIRES_AT_GT] = range.start.format();
      expiresAtFilter[EXPIRES_AT_LT] = range.end.format();
    } else if (range && range.start) {
      expiresAtFilter[EXPIRES_AT_GT] = range.start.startOf('day').format();
      expiresAtFilter[EXPIRES_AT_LT] = range.start.endOf('day').format();
    }

    updateFilters({ ...filters, ...expiresAtFilter });
  };

  const handleClearNumberFilter = () => {
    updateFilters({ ...filters, [DUPLICATA_NUMBER_IN]: null });
  };

  const handleChangeNumberFilter = input => {
    updateFilters({ ...filters, [DUPLICATA_NUMBER_IN]: input });
  };

  const handleClearAllFilters = () => {
    updateFilters({});
  };

  return (
    <>
      <FilterText>Filtrar por</FilterText>
      <TextFieldFilter
        label="Título"
        selectedItems={filters[DUPLICATA_NUMBER_IN]}
        onClear={handleClearNumberFilter}
        onFilter={handleChangeNumberFilter}
      />
      <DateFieldFilter
        label="Vencimento"
        onFilter={handleChangeExpiresAtFilter}
        onClear={handleClearExpiresAtFilter}
        selectedItems={[filters[EXPIRES_AT_GT], filters[EXPIRES_AT_LT]]}
      />
      {hasFilters(filters) && (
        <CleanFilters onClick={handleClearAllFilters}>Limpar filtros</CleanFilters>
      )}
    </>
  );
};

DetailsDrawerFilters.propTypes = {
  filters: PropTypes.shape({}),
  updateFilters: PropTypes.func,
};

DetailsDrawerFilters.defaultProps = {
  filters: {},
  updateFilters: () => undefined,
};

const mapStateToProps = ({
  vendorAdvances: {
    details: { filters },
  },
}) => ({ filters });

const mapDispatchToProps = {
  updateFilters: updateDetailsFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsDrawerFilters);
