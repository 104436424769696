import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectField, TextField, InfoLabel, handleThemeFromObject } from 'liber-components';
import { Countries, States, Cities } from '../../../../../vendor/Utils';
import { Layout, Form, Row, LoadSpinner, TooltipFlex } from '../styles';
import { NavigationContainer, Next, Previous, Remove } from '../../Navigation';

const Enderecos = ({
  changeAddress,
  changeNumber,
  changeComplement,
  changeCity,
  changeState,
  changeCountry,
  changeDistrict,
  changeZipCodeListener,
  enderecos,
  submitForm,
  // addAddress,
  removeAddress,
  onPrevious,
  theme,
}) => (
  <Layout>
    <h2>Endereço</h2>
    {Object.keys(enderecos).map(index => (
      <Form key={index}>
        {/* {
            enderecos[index].kind !== null
              ? (
                <p>Este é seu endereço principal</p>
              )
              : null
          } */}
        <Row>
          <TooltipFlex grow>
            <TextField
              label="CEP"
              name={`enderecos[${index}][zip_code]`}
              id={`enderecos_${index}_zip_code`}
              errorMessage={enderecos[index].errors.zip_code}
              onChange={event => changeZipCodeListener(event, index)}
              value={enderecos[index].zipCode}
              mask="99999-999"
              maskChar={null}
            />
            <LoadSpinner
              color={handleThemeFromObject(theme, 'colors.liberBlue', '#009DFF')}
              visible={enderecos[index].isFetching}
            />
          </TooltipFlex>
          <div style={{ flexGrow: 4 }}>
            <InfoLabel label="Ao digitar o CEP o nome da rua será preenchido automaticamente" />
          </div>
        </Row>
        <Row>
          <TextField
            label="Rua, Av., etc."
            name={`enderecos[${index}][address]`}
            id={`enderecos_${index}_address`}
            errorMessage={enderecos[index].errors.address}
            onChange={event => changeAddress(event, index)}
            disabled={!enderecos[index].isLoaded}
            value={enderecos[index].address}
          />
          <TextField
            label="Número"
            name={`enderecos[${index}][number]`}
            id={`enderecos_${index}_number`}
            errorMessage={enderecos[index].errors.number}
            onChange={event => changeNumber(event, index)}
            disabled={!enderecos[index].isLoaded}
            value={enderecos[index].number}
          />
          <TextField
            label="Complemento"
            name={`enderecos[${index}][complement]`}
            id={`enderecos_${index}_complement`}
            errorMessage={enderecos[index].errors.complement}
            onChange={event => changeComplement(event, index)}
            disabled={!enderecos[index].isLoaded}
            value={enderecos[index].complement}
          />
        </Row>
        <Row>
          <TextField
            label="Bairro"
            name={`enderecos[${index}][district]`}
            id={`enderecos_${index}_district`}
            errorMessage={enderecos[index].errors.district}
            onChange={event => changeDistrict(event, index)}
            disabled={!enderecos[index].isLoaded}
            value={enderecos[index].district}
          />
          {enderecos[index].country === 'BR' ? (
            <SelectField
              searchable
              label="Estado"
              name={`enderecos[${index}][state]`}
              id={`enderecos_${index}_state`}
              errorMessage={enderecos[index].errors.state}
              onChange={event => changeState(event, index)}
              disabled={!enderecos[index].isLoaded}
              value={enderecos[index].state}
            >
              {Object.keys(States).map(st => (
                <option key={_.uniqueId()} value={States[st]}>
                  {st}
                </option>
              ))}
            </SelectField>
          ) : (
            <TextField
              label="Estado"
              name={`enderecos[${index}][state]`}
              id={`enderecos_${index}_state`}
              errorMessage={enderecos[index].errors.state}
              onChange={event => changeState(event, index)}
              disabled={!enderecos[index].isLoaded}
              value={enderecos[index].state}
            />
          )}
          {enderecos[index].country === 'BR' ? (
            <SelectField
              searchable
              label="Cidade"
              name={`enderecos[${index}][city]`}
              id={`enderecos_${index}_city`}
              disabled={!enderecos[index].state}
              errorMessage={enderecos[index].errors.city}
              onChange={event => changeCity(event, index)}
              value={enderecos[index].city}
            >
              {Cities[enderecos[index].state] ? (
                Cities[enderecos[index].state].map(city => (
                  <option key={_.uniqueId()} value={city}>
                    {city}
                  </option>
                ))
              ) : (
                <option value="">Selecione uma cidade</option>
              )}
            </SelectField>
          ) : (
            <TextField
              label="Cidade"
              name={`enderecos[${index}][city]`}
              id={`enderecos_${index}_city`}
              errorMessage={enderecos[index].errors.city}
              onChange={event => changeCity(event, index)}
              disabled={!enderecos[index].isLoaded}
              value={enderecos[index].city}
            />
          )}
        </Row>
        <SelectField
          searchable
          label="País"
          name={`enderecos[${index}][country]`}
          id={`enderecos_${index}_country`}
          errorMessage={enderecos[index].errors.country}
          onChange={event => changeCountry(event, index)}
          disabled={!enderecos[index].isLoaded}
          value={enderecos[index].country}
        >
          {Object.keys(Countries).map(cnt => (
            <option key={_.uniqueId()} value={Countries[cnt]}>
              {cnt}
            </option>
          ))}
        </SelectField>
        {enderecos[index].kind === null ? (
          <Remove
            label="Remover endereço"
            onClick={() => removeAddress(index, enderecos[index].id)}
          />
        ) : null}
      </Form>
    ))}
    {/* <Add label="Adicionar outro endereço" onClick={addAddress} /> */}
    <NavigationContainer>
      <Previous onClick={onPrevious} />
      <Next onClick={() => submitForm(enderecos)} style={{ float: 'right' }} />
    </NavigationContainer>
  </Layout>
);

Enderecos.propTypes = {
  changeAddress: PropTypes.func.isRequired,
  changeNumber: PropTypes.func.isRequired,
  changeComplement: PropTypes.func.isRequired,
  changeCity: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired,
  changeCountry: PropTypes.func.isRequired,
  changeDistrict: PropTypes.func.isRequired,
  changeZipCodeListener: PropTypes.func.isRequired,
  enderecos: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string,
      number: PropTypes.string,
      complement: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      district: PropTypes.string,
      zipCode: PropTypes.string,
      errors: PropTypes.objectOf(PropTypes.string),
      isPatch: PropTypes.bool,
      isFetching: PropTypes.bool,
      isLoaded: PropTypes.bool,
      kind: PropTypes.string,
    }),
  ).isRequired,
  submitForm: PropTypes.func.isRequired,
  // addAddress: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

Enderecos.defaultProps = {
  theme: {},
};

export default Enderecos;
