import React from 'react';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';

import Alert from './Alert';

function View(props) {
  return (
    <ThemeProvider theme={LiberV4}>
      <Alert {...props} />
    </ThemeProvider>
  );
}

export default View;
