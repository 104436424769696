import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchScheduled } from '../api';
import { handleError } from '../../../vendor/Utils';
import { STATUS_EQUALS, DATE_LOWER, DATE_GRATER } from '../constants';

const SchedulesContext = React.createContext();

const SchedulesStore = ({ token, children }) => {
  const [schedules, setSchedules] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState('1');
  const [listing, setListing] = React.useState('10');
  const [pages, setPages] = React.useState(1);
  const [totalItems, setTotalItems] = React.useState('0');
  const [filtered, setFiltered] = React.useState(false);
  const [filters, setFilters] = React.useState({
    page: currentPage,
    per: listing,
    [STATUS_EQUALS]: 'scheduled',
    [DATE_LOWER]: null,
    [DATE_GRATER]: null,
  });

  const updateFilter = key => valueArray => {
    const value = valueArray[0];
    setFilters({ [key]: value });
    setFiltered(true);
  };

  const clearFilter = key => () => {
    setFilters({ [key]: null });
    setFiltered(false);
  };

  const clearAllFilters = () => {
    setFilters({
      [STATUS_EQUALS]: 'scheduled',
      [DATE_LOWER]: null,
      [DATE_GRATER]: null,
    });
    setFiltered(false);
  };

  const hasFilters = () =>
    Object.keys(filters).reduce((flag, key) => {
      const hasValue = Boolean(filters[key]);

      if (hasValue && filters[key] instanceof Array) {
        return flag || filters[key].length > 0;
      }

      return flag || hasValue;
    }, false);

  const fetchSchedules = async () => {
    try {
      const response = await fetchScheduled(token, { ...filters, page: currentPage, per: listing });
      setSchedules(response.data.schedules);
      setTotalItems(response.data.pagination.count);
      setPages(response.data.pagination.pages);
    } catch (error) {
      handleError('Ocorreu um erro ao requisitar os agendamentos');
      setSchedules([]);
    }
  };

  function buildDateFilter({ start, end }) {
    if (start && end) {
      setFilters({
        ...filters,
        [DATE_GRATER]: start.format(),
        [DATE_LOWER]: end.format(),
      });
    } else if (start && !end) {
      setFilters({
        ...filters,
        [DATE_GRATER]: start.format(),
        [DATE_LOWER]: start.add('days', 1).format(),
      });
    }
    setFiltered(true);
  }

  function clearDateFilters() {
    setFilters({
      ...filters,
      [DATE_GRATER]: null,
      [DATE_LOWER]: null,
    });
  }

  useEffect(() => {
    async function fetchData() {
      await fetchSchedules();
    }

    fetchData();
  }, [listing, currentPage, filters, listing]);

  return (
    <SchedulesContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        listing,
        setListing,
        schedules,
        totalItems,
        filters,
        setFilters,
        fetchSchedules,
        updateFilter,
        clearFilter,
        clearAllFilters,
        hasFilters,
        buildDateFilter,
        clearDateFilters,
        filtered,
        pages,
      }}
    >
      {children}
    </SchedulesContext.Provider>
  );
};

SchedulesStore.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export { SchedulesContext, SchedulesStore };
