import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectField, TextField } from 'liber-components';
import { Countries, States, Cities } from '../../../../../vendor/Utils';
import { Form, Layout, Row } from '../styles';
import { NavigationContainer, Next } from '../../Navigation';

const DadosPessoais = ({
  changeName,
  changeCpf,
  changeEmail,
  changeCellphone,
  changePhone,
  changeGender,
  changeBirthDate,
  changeCivilStatus,
  changeCountryOrigin,
  changeStateOrigin,
  changeCityOrigin,
  dadosPessoais,
  submitForm,
}) => (
  <Layout>
    <h2>Dados Pessoais</h2>
    <Form>
      <Row>
        <TextField
          label="Nome Completo"
          name="investidor[name]"
          id="investidor_name"
          errorMessage={dadosPessoais.errors.name}
          onChange={event => changeName(event)}
          value={dadosPessoais.name}
        />
        <TextField
          label="CPF"
          name="investidor[cpf]"
          id="investidor_cpf"
          errorMessage={dadosPessoais.errors.cpf}
          onChange={event => changeCpf(event)}
          value={dadosPessoais.cpf}
          mask="999.999.999-99"
          maskChar={null}
        />
      </Row>
      <Row>
        <TextField
          label="Email"
          name="investidor[email]"
          id="investidor_email"
          errorMessage={dadosPessoais.errors.email}
          onChange={event => changeEmail(event)}
          value={dadosPessoais.email}
        />
        <TextField
          label="Celular"
          name="investidor[cellphone]"
          id="investidor_cellphone"
          errorMessage={dadosPessoais.errors.cellphone}
          onChange={event => changeCellphone(event)}
          value={dadosPessoais.cellphone}
          mask={dadosPessoais.cellphone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
          maskChar={null}
          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
        />
        <TextField
          label="Telefone (opcional)"
          name="investidor[phone]"
          id="investidor_phone"
          errorMessage={dadosPessoais.errors.phone}
          onChange={event => changePhone(event)}
          value={dadosPessoais.phone}
          mask={dadosPessoais.phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
          maskChar={null}
          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
        />
      </Row>
      <Row>
        <SelectField
          label="Gênero"
          name="investidor[gender]"
          id="investidor_gender"
          errorMessage={dadosPessoais.errors.gender}
          onChange={event => changeGender(event)}
          value={dadosPessoais.gender}
        >
          <option value="masculino">Masculino</option>
          <option value="feminino">Feminino</option>
        </SelectField>
        <TextField
          label="Data de Nascimento"
          name="investidor[birthdate]"
          id="investidor_birthdate"
          hintText="DD/MM/AAAA"
          errorMessage={dadosPessoais.errors.birthdate}
          onChange={event => changeBirthDate(event)}
          value={dadosPessoais.birthDate}
          mask="99/99/9999"
          maskChar={null}
        />
        <SelectField
          label="Estado Civil"
          name="investidor[civil-status]"
          id="investidor_civil_status"
          errorMessage={dadosPessoais.errors.civil_status}
          onChange={event => changeCivilStatus(event)}
          value={dadosPessoais.civilStatus}
        >
          <option value="solteiro">Solteiro(a)</option>
          <option value="casado">Casado(a)</option>
          <option value="divorciado">Divorciado(a)</option>
          <option value="viuvo">Viúvo(a)</option>
          <option value="separado">Separado(a)</option>
          <option value="uniao_estavel">União Estável</option>
        </SelectField>
      </Row>
      <Row>
        <SelectField
          label="País de Origem"
          searchable
          name="investidor[country-origin]"
          id="investidor_country_origin"
          errorMessage={dadosPessoais.errors.country_origin}
          onChange={event => changeCountryOrigin(event)}
          value={dadosPessoais.countryOrigin}
        >
          {Object.keys(Countries).map(country => (
            <option key={_.uniqueId()} value={Countries[country]}>
              {country}
            </option>
          ))}
        </SelectField>
        {dadosPessoais.countryOrigin === 'BR' ? (
          <SelectField
            searchable
            label="UF de Nascimento"
            name="investidor[state-origin]"
            id="investidor_state_origin"
            errorMessage={dadosPessoais.errors.state_origin}
            onChange={event => changeStateOrigin(event)}
            value={dadosPessoais.stateOrigin}
          >
            {Object.keys(States).map(st => (
              <option key={_.uniqueId()} value={States[st]}>
                {st}
              </option>
            ))}
          </SelectField>
        ) : (
          <TextField
            label="UF de Nascimento"
            name="investidor[state-origin]"
            id="investidor_state_origin"
            errorMessage={dadosPessoais.errors.state_origin}
            onChange={event => changeStateOrigin(event)}
            value={dadosPessoais.stateOrigin}
          />
        )}
        {dadosPessoais.countryOrigin === 'BR' ? (
          <SelectField
            searchable
            label="Cidade de Nascimento"
            name="investidor[city-origin]"
            id="investidor_city_origin"
            disabled={!dadosPessoais.stateOrigin}
            errorMessage={dadosPessoais.errors.city_origin}
            onChange={event => changeCityOrigin(event)}
            value={dadosPessoais.cityOrigin}
          >
            {Cities[dadosPessoais.stateOrigin] ? (
              Cities[dadosPessoais.stateOrigin].map(city => (
                <option key={_.uniqueId()} value={city}>
                  {city}
                </option>
              ))
            ) : (
              <option value="">Selecione uma cidade</option>
            )}
          </SelectField>
        ) : (
          <TextField
            label="Cidade de Nascimento"
            name="investidor[city-origin]"
            id="investidor_city_origin"
            errorMessage={dadosPessoais.errors.city_origin}
            onChange={event => changeCityOrigin(event)}
            value={dadosPessoais.cityOrigin}
          />
        )}
      </Row>
    </Form>
    <NavigationContainer style={{ padding: '25px 0px' }}>
      <Next onClick={() => submitForm(dadosPessoais)} style={{ float: 'right' }} />
    </NavigationContainer>
  </Layout>
);

DadosPessoais.propTypes = {
  changeName: PropTypes.func.isRequired,
  changeCpf: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changeCellphone: PropTypes.func.isRequired,
  changePhone: PropTypes.func.isRequired,
  changeGender: PropTypes.func.isRequired,
  changeBirthDate: PropTypes.func.isRequired,
  changeCivilStatus: PropTypes.func.isRequired,
  changeCountryOrigin: PropTypes.func.isRequired,
  changeStateOrigin: PropTypes.func.isRequired,
  changeCityOrigin: PropTypes.func.isRequired,
  dadosPessoais: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
    email: PropTypes.string,
    mailerSubscription: PropTypes.bool,
    cellphone: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string,
    birthDate: PropTypes.string,
    civilStatus: PropTypes.string,
    countryOrigin: PropTypes.string,
    stateOrigin: PropTypes.string,
    cityOrigin: PropTypes.string,
    errors: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default DadosPessoais;
