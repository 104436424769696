import { useEffect, useState } from 'react';
import { fetchBuyerOrganizationGroup } from '../services/requests';
import { convertToCamelCase, handleError } from '../../../../../vendor/Utils';

export const useGetGroupInfo = ({ groupId, token }) => {
  const [isLoadingGroup, setIsLoading] = useState(false);
  const [buyerOrganizationGroup, setBuyerOrganizationGroup] = useState(undefined);

  const getBuyerOrganizationGroup = async () => {
    try {
      setIsLoading(true);
      const response = await fetchBuyerOrganizationGroup(token, groupId);
      const { data } = convertToCamelCase(response.data);
      setBuyerOrganizationGroup(data);
    } catch (error) {
      handleError('Ocorreu um erro ao buscar os detalhes do grupo econômico');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      getBuyerOrganizationGroup();
    }
  }, []);

  return { isLoadingGroup, buyerOrganizationGroup };
};
