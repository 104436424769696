import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4, ToastContainer } from 'liber-components';
import AdminVendorAnticipations from './components/AdminVendorAnticipations';

const View = props => (
  <>
    <ThemeProvider theme={LiberV4}>
      <ToastContainer />
      <AdminVendorAnticipations {...props} />
    </ThemeProvider>
  </>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  vendorId: PropTypes.number.isRequired,
  operator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cpf: PropTypes.string,
  }).isRequired,
  borderoIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  startingTab: PropTypes.string,
};

View.defaultProps = {
  startingTab: null,
};

export default View;
