import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    border-color: ${theme.colors.primary};
    border-width: 6px;
    border-style: dashed;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  `}
`;

export const Input = styled.input`
  display: none;
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
