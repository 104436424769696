import axios from 'axios';
import { FUNDER_REPORTS_URL, TRANSFER_AGREEMENTS_URL } from './constants';

export async function downloadReport({ token, filters }) {
  return axios.post(
    FUNDER_REPORTS_URL,
    { filters },
    { headers: { Authorization: `Bearer ${token}` } },
  );
}

export async function downloadTransferAgreements({ token, filters }) {
  return axios.post(
    TRANSFER_AGREEMENTS_URL,
    { filters },
    { headers: { Authorization: `Bearer ${token}` } },
  );
}
