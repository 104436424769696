import React from 'react';
import PropTypes from 'prop-types';
import { SuccessLink } from './styles';

const Next = ({ label, ...props }) => <SuccessLink {...props}>{label}</SuccessLink>;

Next.propTypes = {
  label: PropTypes.string,
};

Next.defaultProps = {
  label: 'Próximo',
};

export default Next;
