import Immutable from 'seamless-immutable';
import errorMessages from './errorMessages';

export const initialState = Immutable({
  loading: true,
  status: true,
  error: {
    code: null,
    message: '',
  },
  data: {
    balance: 0,
    acquired_invoices: 0,
    to_settle: 0,
    invested: 0,
    yield: 0,
    payment_overdue: 0,
    payment_conciliation: 0,
    summary: {
      average: {
        reference: {
          cdi: 0,
        },
        term: {
          working: 0,
          running: 0,
        },
        profitability: 0,
      },
      forecast: {
        date: '',
        amount: 0,
      },
    },
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INVESTOR_DASHBOARD_GET_MY_WALLET_DATA_FULFILLED':
      return state.merge(
        {
          loading: false,
          data: action.payload.data.data ? action.payload.data.data : initialState.data,
          status: action.payload.data.status,
          error: {
            message: action.payload.data.error ? action.payload.data.error.message : '',
            code: action.payload.data.error ? action.payload.data.error.code : null,
          },
        },
        { deep: true },
      );
    case 'INVESTOR_DASHBOARD_GET_MY_WALLET_DATA_PENDING':
      return state.merge({ loading: true }, { deep: true });
    case 'INVESTOR_DASHBOARD_GET_MY_WALLET_DATA_REJECTED':
      if (
        action.payload.response &&
        (typeof action.payload.response.data === 'string' ||
          action.payload.response.data instanceof String)
      ) {
        return state.merge(
          {
            error: action.payload.response.data.error,
            status: false,
          },
          { deep: true },
        );
      }
      return state.merge(
        {
          error: {
            message: errorMessages[action.payload.response.status] || action.payload.message,
          },
          status: false,
        },
        { deep: true },
      );
    default:
      return state;
  }
};
