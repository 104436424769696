import React, { useContext } from 'react';
import TextFieldFilter from '../../../../../Filters/TextFieldFilter/TextFieldFilter';
import { BUYER_TRADE_NAME_FILTER, BUYER_CNPJ_FILTER } from '../../../../constants';
import { DetailsDrawerContext } from '../../context';
import * as S from './styles';

const FundersTableFilters = () => {
  const { filters, handleChangeFilters, handleClearAllFilters } = useContext(DetailsDrawerContext);

  const hasFilters = filtersObject =>
    Object.keys(filtersObject).reduce((flag, key) => flag || Boolean(filters[key]), false);

  return (
    <>
      <S.FilterText>Filtrar por:</S.FilterText>
      <TextFieldFilter
        label="Sacado"
        selectedItems={filters[BUYER_TRADE_NAME_FILTER]}
        onFilter={text => handleChangeFilters(BUYER_TRADE_NAME_FILTER, text)}
        onClear={() => handleChangeFilters(BUYER_TRADE_NAME_FILTER, null)}
      />
      <TextFieldFilter
        label="CNPJ"
        selectedItems={filters[BUYER_CNPJ_FILTER]}
        onFilter={text => handleChangeFilters(BUYER_CNPJ_FILTER, (text || '').replace(/\D/g, ''))}
        onClear={() => handleChangeFilters(BUYER_CNPJ_FILTER, null)}
      />
      {hasFilters(filters) && (
        <S.CleanFiltersButton onClick={handleClearAllFilters}>Limpar Filtros</S.CleanFiltersButton>
      )}
    </>
  );
};

export default FundersTableFilters;
