import React from 'react';
import PropTypes from 'prop-types';
import { Modal, LinearProgress } from 'liber-components';
import { connect } from 'react-redux';
import { LoadingBox, Bunny } from './styles';

export const LoadingDialog = ({ loading }) => (
  <Modal closable={false} show={loading}>
    <LoadingBox>
      <Bunny />
      <LinearProgress value={50} />
    </LoadingBox>
  </Modal>
);

LoadingDialog.propTypes = {
  loading: PropTypes.bool,
};

LoadingDialog.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ replicateVendorReducer: { loading } }) => ({
  loading,
});

export default connect(mapStateToProps, null)(LoadingDialog);
