import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../Dialog/Dialog';
import { DialogContent, DialogUpperText, DialogText } from './CancellationDialog.styles';

const CancellationDialog = ({ onClose, onConfirm, open, advanceId }) => (
  <Dialog
    title="Desistir da antecipação?"
    cancelLabel="MANTER ANTECIPAÇÃO"
    open={open}
    onClose={onClose}
    confirmationButtonProps={{
      color: 'danger',
      label: 'DESISTIR DA ANTECIPAÇÃO',
      onConfirm,
    }}
  >
    <DialogContent data-testid="CancellationDialogContent">
      <DialogUpperText>Esta ação cancelará a antecipação {advanceId}.</DialogUpperText>
      <DialogText>
        Os títulos contidos nela voltarão à lista de títulos disponíveis para antecipação.
      </DialogText>
    </DialogContent>
  </Dialog>
);

CancellationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  advanceId: PropTypes.number,
};

CancellationDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
  advanceId: 0,
};

export default CancellationDialog;
