import axios from 'axios';
import { INVESTOR_GROUP_VENDORS_LIST_URL, BUYER_GROUP_VENDORS_LIST_URL } from './constants';

export async function fetchVendors(token = '', filters, groupId, isInvestor) {
  const URL = isInvestor ? INVESTOR_GROUP_VENDORS_LIST_URL : BUYER_GROUP_VENDORS_LIST_URL;

  return axios.get(URL.replace(':id', groupId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...filters,
    },
  });
}
