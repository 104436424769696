import { connect } from 'react-redux';
import DadosBancarios from './DadosBancarios';
import { previousStep as onPrevious } from '../../../actions/progressBar';
import {
  changeKind,
  changeBankCode,
  changeAgency,
  changeAgencyDigit,
  changeAccount,
  changeAccountDigit,
  changeConjunct,
  changeSavingsComplement,
  addAccount,
  removeAccount,
  submitForm,
} from '../../../actions/dadosBancarios';
import { getDadosBancarios } from '../../../presenters/dadosBancarios';

const mapStateToProps = state => ({ dadosBancarios: getDadosBancarios(state) });

const mapDispatchToProps = {
  changeKind,
  changeBankCode,
  changeAgency,
  changeAgencyDigit,
  changeAccount,
  changeAccountDigit,
  changeConjunct,
  changeSavingsComplement,
  addAccount,
  removeAccount,
  submitForm,
  onPrevious,
};

export default connect(mapStateToProps, mapDispatchToProps)(DadosBancarios);
