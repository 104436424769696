export const mapActionToTitle = action => {
  switch (action) {
    case 'created':
      return 'criado';
    case 'updated':
      return 'atualizado';
    case 'archived':
      return 'arquivado';
    default:
      return 'recuperado';
  }
};
