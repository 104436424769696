// eslint-disable-next-line no-undef
export const BASE_URL = SERVICE_API_URL;

// eslint-disable-next-line no-undef
export const wsUrl = WEBSOCKET_URL;
export const INVOICES_ACTION_CABLE_CHANNEL = 'FornecedorScheduleBatchInvoicesChannel';
export const INVOICES_ACTION_CABLE_ROOM = 'fornecedor_schedule_batch_invoices_channel_';
export const ADVANCES_ACTION_CABLE_CHANNEL = 'LiberSignSignatureChannel';
export const ADVANCES_ACTION_CABLE_ROOM = 'liber_sign_signature_channel_';
export const ANTICIPATION_SUMMARY_URL = `${BASE_URL}/api/v1/fornecedor/anticipations/:id/summary`;

export const ADVANCES_URL = `${BASE_URL}/api/v1/fornecedor/adiantamentos`;
export const SIGN_DOCUMENTS_URL = `${ADVANCES_URL}/sign`;
export const QUOTE_BORDERO_URL = `${BASE_URL}/api/v1/fornecedor/adiantamentos/quote`;
export const GENERATE_BORDERO_URL = `${BASE_URL}/api/v1/fornecedor/adiantamentos/setup_borderos`;

export const CLEAN_ANTICIPATION_SIGN_URL = '/fornecedor/antecipacoes/sign';
export const CLEAN_ANTICIPATION_START_URL = '/fornecedor/anticipacoes/start';
export const INVOICES_PAGE_URL = '/fornecedor/duplicatas';
export const ANTICIPATIONS_PAGE_URL = '/fornecedor/negociacoes';
export const GENERATE_ANTICIPATION_URL = `${BASE_URL}/api/v1/fornecedor/anticipations/:id/setup`;
