import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'liber-components';

import {
  Container,
  Header,
  HeaderTitle,
  Description,
  Caption,
  PlusIcon,
  MinusIcon,
  SwapIcon,
  NameText,
  AmountContainer,
  TargetingModeContent,
  MutedText,
} from './styles';
import { mapActionToTitle } from '../../utils';
import { dispatchAmplitudeEvent } from '../../../../vendor/Utils';
import { VendorsGroupHistoryContext } from '../../context';
import {
  TARGETING_MODE_INTEGRATION,
  TARGETING_MODE_PLATFORM,
} from '../../../../common-components/EditOperationMode/constants';

const FUNDER_NAME_PLACEHOLDER = 'FUNDER_NAME_PLACEHOLDER';

const targetingModeLabel = {
  [TARGETING_MODE_INTEGRATION]: 'Direcionado via integração',
  [TARGETING_MODE_PLATFORM]: `Direcionado via plataforma para o investidor ${FUNDER_NAME_PLACEHOLDER}`,
};

export default function HistoryItem({
  createdAt,
  operatorName,
  vendorsInCount,
  vendorsOutCount,
  vendorsSpreadsheetUrl,
  action,
  alterations,
}) {
  const inCountString = vendorsInCount.toLocaleString('pt-BR');
  const outCountString = vendorsOutCount.toLocaleString('pt-BR');

  const { name, groupId, referenceCode } = useContext(VendorsGroupHistoryContext);

  const {
    name: nameAlterations,
    funder: funderAlterations,
    funderTargetingMode: funderTargetingModeAlterations,
    rebateIncrement: rebateIncrementAlterations,
  } = alterations || {};

  const [oldName, newName] = nameAlterations || [];
  const [oldFunder, newFunder] = funderAlterations || [];
  const [oldFunderTargetingMode, newFunderTargetingMode] = funderTargetingModeAlterations || [];
  const [oldRebateIncrement, newRebateIncrement] = rebateIncrementAlterations || [];

  const getTargetingModeLabel = (funderName, targetingMode = TARGETING_MODE_PLATFORM) =>
    targetingModeLabel[targetingMode].replace(FUNDER_NAME_PLACEHOLDER, funderName);

  const handleDownload = () => {
    dispatchAmplitudeEvent('buyer_vendors_groups_history_download', {
      name,
      id: groupId,
      referenceCode,
    });
    window.open(vendorsSpreadsheetUrl, '_blank');
  };

  return (
    <Container>
      <Header>
        <HeaderTitle>
          {`Grupo ${mapActionToTitle(action)} em ${createdAt}`}
          {operatorName && `, por ${operatorName}`}
        </HeaderTitle>
        {(vendorsInCount > 0 || vendorsOutCount > 0) && (
          <PrimaryButton version={2} size="medium" outlined onClick={handleDownload}>
            BAIXAR PLANILHA DE ALTERAÇÕES
          </PrimaryButton>
        )}
      </Header>
      <Description>
        {nameAlterations && (
          <NameText>
            Nome do grupo alterado de <b>{oldName}</b> para <b>{newName}</b>
          </NameText>
        )}
        {rebateIncrementAlterations && (
          <NameText>
            Incremento de rebate alterado de{' '}
            <b>{parseFloat((oldRebateIncrement * 100).toFixed(6))}%</b> para{' '}
            <b>{parseFloat((newRebateIncrement * 100).toFixed(6))}%</b>
          </NameText>
        )}
        {funderAlterations && (
          <div>
            <SwapIcon path="#" />
            <Caption>Modo de operação alterado</Caption>

            <TargetingModeContent>
              <MutedText>De: {getTargetingModeLabel(oldFunder, oldFunderTargetingMode)}</MutedText>
              <MutedText>
                Para: {getTargetingModeLabel(newFunder, newFunderTargetingMode)}
              </MutedText>
            </TargetingModeContent>
          </div>
        )}
        <AmountContainer>
          {vendorsInCount > 0 && (
            <span>
              <PlusIcon path="#" />
              <Caption>
                {vendorsInCount > 1
                  ? `Entraram ${inCountString} fornecedores`
                  : `Entrou ${inCountString} fornecedor`}
              </Caption>
            </span>
          )}
          {vendorsOutCount > 0 && (
            <span>
              <MinusIcon path="#" />
              <Caption>
                {vendorsOutCount > 1
                  ? `Saíram ${outCountString} fornecedores`
                  : `Saiu ${outCountString} fornecedor`}
              </Caption>
            </span>
          )}
        </AmountContainer>
      </Description>
    </Container>
  );
}

HistoryItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  operatorName: PropTypes.string.isRequired,
  alterations: PropTypes.objectOf(PropTypes.any),
  vendorsInCount: PropTypes.number,
  vendorsOutCount: PropTypes.number,
  vendorsSpreadsheetUrl: PropTypes.string,
  action: PropTypes.string.isRequired,
};

HistoryItem.defaultProps = {
  alterations: {},
  vendorsInCount: 0,
  vendorsOutCount: 0,
  vendorsSpreadsheetUrl: '',
};
