import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Drawer,
  TableHeader,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  Accordion,
  TooltipBox,
} from 'liber-components';

import {
  CardHeader,
  ModalTitle,
  ModalClose,
  Close,
  CloseButton,
  SummaryTitleRow,
  SummaryRow,
  SummaryInfo,
  SummaryValue,
  ModalContainer,
  Loading,
  LoadingBlock,
  InvoiceListItem,
  InvoiceTop,
  InvoiceBottom,
  Divider,
  AccordionLabel,
  Badge,
  InvoicesList,
  ToolTipInfo,
  InfoIcon,
  DrawerContainer,
  Table,
  BuyerTitle,
  BuyerAmount,
  InvoiceModalContainer,
  InvoiceModalHeader,
  TooltipTitle,
  InvoiceModalItemTitle,
  InvoiceModalItemValue,
  InvoiceModalList,
  ModalRow,
} from './styles';
import InvoiceTooltip from './InvoiceTooltip';
import { closeInvoicesDialog } from './redux';
import withScreenSize from '../../../../hocs/withScreenSize';
import { formatCurrency } from '../utils';
import { INVOICE_FIELD_MAP } from './constants';

const INITIAL_INVOICE = {
  slug: '',
  number: '',
  vendor: { tradeName: '' },
  liquidationDate: '',
  term: { working: '' },
  profitability: '',
  reference: { cdi: '' },
  toReceive: '',
  invested: '',
  yield: '',
};

export function InvoicesDialog({ screenSize }) {
  const { show, loading, title, buyerInvoices, summary } = useSelector(
    ({ investorDashboard: { invoicesDialog } }) => invoicesDialog,
  );

  const dispatch = useDispatch();
  const onClose = () => dispatch(closeInvoicesDialog());

  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(INITIAL_INVOICE);

  const mapInvoicesToListItem = (buyer, invoice) => {
    const {
      slug,
      number,
      liquidationDate,
      term: { working },
      toReceive,
    } = invoice;

    return (
      <InvoiceListItem
        key={slug}
        onClick={() => {
          setSelectedInvoice(invoice);
          setShowInvoiceModal(true);
        }}
      >
        <InvoiceTop>
          <span>{number}</span>
          <span>{working} dias</span>
        </InvoiceTop>
        <InvoiceBottom>
          <span>{formatCurrency(toReceive)}</span>
          <Divider />
          <span>{liquidationDate}</span>
        </InvoiceBottom>
      </InvoiceListItem>
    );
  };

  const mapBuyersToList = buyer => {
    const {
      buyer: { slug, tradeName, invoices },
    } = buyer;
    return (
      <Accordion
        key={slug}
        headerColor="#fafafa"
        open
        label={
          <AccordionLabel>
            <Badge>{invoices.length}</Badge>
            <BuyerTitle>{tradeName}</BuyerTitle>
            <BuyerAmount>
              {formatCurrency(
                invoices.reduce((prev, next) => prev + parseFloat(next.toReceive), 0),
              )}
            </BuyerAmount>
          </AccordionLabel>
        }
      >
        <InvoicesList>
          {invoices.map((...args) => mapInvoicesToListItem(buyer, ...args))}
        </InvoicesList>
      </Accordion>
    );
  };

  const mapInvoicesToTableRow = invoice => {
    const {
      slug,
      number,
      vendor: { tradeName },
      liquidationDate,
      term: { working },
      profitability,
      toReceive,
    } = invoice;

    return (
      <TableRow key={slug}>
        <TableRowColumn>{number}</TableRowColumn>
        <TableRowColumn>{tradeName}</TableRowColumn>
        <TableRowColumn>{liquidationDate}</TableRowColumn>
        <TableRowColumn>
          {working} {working === 1 ? 'dia útil' : 'dias úteis'}
        </TableRowColumn>
        <TableRowColumn>{formatCurrency(toReceive)}</TableRowColumn>
        <TableRowColumn>
          {profitability} a.m.
          <TooltipBox
            key={slug}
            label={`Título ${number}`}
            mount="top"
            content={<InvoiceTooltip invoice={invoice} />}
            fixed
          >
            <ToolTipInfo>
              <InfoIcon />
            </ToolTipInfo>
          </TooltipBox>
        </TableRowColumn>
      </TableRow>
    );
  };

  const mapBuyersToTable = buyer => {
    const {
      buyer: { slug, tradeName, invoices },
    } = buyer;
    return (
      <Accordion
        key={slug}
        open
        label={
          <AccordionLabel>
            <Badge>{invoices.length}</Badge>
            <BuyerTitle>{tradeName}</BuyerTitle>
            <BuyerAmount>
              {formatCurrency(invoices.reduce((prev, next) => prev + next.toReceive, 0))}
            </BuyerAmount>
          </AccordionLabel>
        }
      >
        <Table key={slug}>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn>Número</TableHeaderColumn>
              <TableHeaderColumn>Fornecedor</TableHeaderColumn>
              <TableHeaderColumn>Liquidação</TableHeaderColumn>
              <TableHeaderColumn>Prazo</TableHeaderColumn>
              <TableHeaderColumn>Valor a Receber</TableHeaderColumn>
              <TableHeaderColumn>Rentabilidade</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>{invoices.map(mapInvoicesToTableRow)}</TableBody>
        </Table>
      </Accordion>
    );
  };

  const handleCloseInvoiceModal = () => setShowInvoiceModal(false);

  const renderDrawerInvoices = () => {
    const {
      number,
      vendor: { tradeName },
    } = selectedInvoice;

    return (
      <>
        <Drawer show={show} onLeaved={onClose} fitScreen>
          <CardHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseButton onClick={onClose}>
              <Close />
            </CloseButton>
          </CardHeader>
          <DrawerContainer>
            {loading ? <Loading loop /> : null}
            <LoadingBlock loading={loading}>{buyerInvoices.map(mapBuyersToList)}</LoadingBlock>
          </DrawerContainer>
        </Drawer>

        <Modal show={showInvoiceModal} onLeaved={handleCloseInvoiceModal}>
          <InvoiceModalContainer>
            <InvoiceModalHeader>
              <TooltipTitle>Título {number}</TooltipTitle>
              <ModalClose onClick={handleCloseInvoiceModal}>
                <Close />
              </ModalClose>
            </InvoiceModalHeader>
            <InvoiceModalHeader>
              <InvoiceModalItemTitle>Sacado:</InvoiceModalItemTitle>
              <InvoiceModalItemValue>{tradeName}</InvoiceModalItemValue>
              <InvoiceModalItemTitle>Fornecedor:</InvoiceModalItemTitle>
              <InvoiceModalItemValue>{tradeName}</InvoiceModalItemValue>
            </InvoiceModalHeader>
            <InvoiceModalList>
              {INVOICE_FIELD_MAP.map(({ label, getValue }) => (
                <ModalRow key={`invoice-row-${label}`}>
                  <InvoiceModalItemTitle>{label}</InvoiceModalItemTitle>
                  <Divider />
                  <InvoiceModalItemValue>{getValue(selectedInvoice)}</InvoiceModalItemValue>
                </ModalRow>
              ))}
            </InvoiceModalList>
          </InvoiceModalContainer>
        </Modal>
      </>
    );
  };

  const renderInvoicesModal = () => {
    const { settle, invested, yields } = summary;
    return (
      <Modal show={show} onLeaved={onClose}>
        <CardHeader style={{ position: 'relative' }}>
          <ModalTitle>{title}</ModalTitle>
          <ModalClose onClick={onClose}>
            <Close />
          </ModalClose>
        </CardHeader>
        <SummaryTitleRow>Resumo</SummaryTitleRow>
        <SummaryRow>
          <SummaryInfo>
            Total a liquidar:
            <SummaryValue>{formatCurrency(settle)}</SummaryValue>
          </SummaryInfo>
          <SummaryInfo>
            Total investido:
            <SummaryValue>{formatCurrency(invested)}</SummaryValue>
          </SummaryInfo>
          <SummaryInfo>
            Rendimento:
            <SummaryValue>{formatCurrency(yields)}</SummaryValue>
          </SummaryInfo>
        </SummaryRow>
        <ModalContainer hasSummary={Boolean(summary)}>
          {loading ? <Loading loop /> : null}
          <LoadingBlock loading={loading}>{buyerInvoices.map(mapBuyersToTable)}</LoadingBlock>
        </ModalContainer>
      </Modal>
    );
  };

  if (screenSize === 'small' || screenSize === 'medium') {
    return <>{renderDrawerInvoices()}</>;
  }
  return <>{renderInvoicesModal()}</>;
}

InvoicesDialog.propTypes = {
  screenSize: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
};

InvoicesDialog.defaultProps = {
  screenSize: 'large',
};

export default withScreenSize(InvoicesDialog);
