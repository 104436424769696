import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectionTableRowColumn, SimpleDropDown } from 'liber-components';
import {
  DropDownButton,
  DropDownContent,
  DropDownContentRow,
  DropDownContentRowIcon,
  DescriptionIcon,
  MoreVertIcon,
  DropDownContentRelative,
  ActionHeaderContainer,
  TableRow,
  CheckboxTable,
} from './InvoiceSimulationTable.styles';

import { INVOICE_TABS, ALL_TAB_COLUMNS, INVOICE_TABLE_COLUMNS } from '../../utils';
import { TableCell } from './InvoiceSimulationTableRow.styles';

const { AVAILABLE, ALL } = INVOICE_TABS;

class InvoiceSimulationTableRow extends Component {
  shouldComponentUpdate = nextProps => {
    const { selectedInvoices, invoice, checkBoxColor } = this.props;
    const { selectedInvoices: nextSelectedInvoices, checkBoxColor: nextCheckBoxColor } = nextProps;

    if (selectedInvoices.length && !nextSelectedInvoices.length) {
      return true;
    }

    if (checkBoxColor !== nextCheckBoxColor) {
      return true;
    }

    const difference = selectedInvoices
      .filter(selectedInvoiceId => !nextSelectedInvoices.includes(selectedInvoiceId))
      .concat(
        nextSelectedInvoices.filter(
          nextSelectedInvoiceId => !selectedInvoices.includes(nextSelectedInvoiceId),
        ),
      );

    return difference.includes(invoice.id);
  };

  selectRow = event => {
    const { invoice, onSelectInvoice } = this.props;
    event.preventDefault();
    onSelectInvoice(invoice.id);
  };

  render() {
    const { activeTab, checkBoxColor, selectedInvoices, rateByPeriod, invoice } = this.props;

    const columns = activeTab === ALL ? ALL_TAB_COLUMNS : INVOICE_TABLE_COLUMNS;
    const proportionSpaceAvailable = activeTab === AVAILABLE ? 0.87 : 0.9;

    const isSelectable = activeTab === AVAILABLE;
    return (
      <TableRow
        selected={selectedInvoices.includes(invoice.id)}
        onClick={isSelectable ? this.selectRow : undefined}
      >
        {isSelectable && (
          <SelectionTableRowColumn width="3%">
            <CheckboxTable
              color={checkBoxColor}
              checked={selectedInvoices.includes(invoice.id)}
              onChange={this.selectRow}
            />
          </SelectionTableRowColumn>
        )}
        {columns.map(({ name, getCellValue, width }) => (
          <TableCell
            key={`${invoice.number}${name}`}
            width={`${proportionSpaceAvailable * width}%`}
          >
            {getCellValue ? getCellValue(invoice, rateByPeriod) : invoice[name]}
          </TableCell>
        ))}
        <SelectionTableRowColumn width="10%">
          <ActionHeaderContainer>
            <DropDownContentRelative>
              <SimpleDropDown
                customButton={
                  <DropDownButton onClick={e => e.stopPropagation()}>
                    <MoreVertIcon />
                  </DropDownButton>
                }
                position="bottom-left-down-left"
              >
                <DropDownContent>
                  <DropDownContentRow
                    onClick={e => e.stopPropagation()}
                    href={invoice.path_detalhes_do_titulo}
                  >
                    <DropDownContentRowIcon>
                      <DescriptionIcon />
                    </DropDownContentRowIcon>
                    <div>Detalhes do título</div>
                  </DropDownContentRow>
                </DropDownContent>
              </SimpleDropDown>
            </DropDownContentRelative>
          </ActionHeaderContainer>
        </SelectionTableRowColumn>
      </TableRow>
    );
  }
}

InvoiceSimulationTableRow.propTypes = {
  activeTab: PropTypes.number,
  checkBoxColor: PropTypes.string,
  rateByPeriod: PropTypes.bool,
  selectedInvoices: PropTypes.arrayOf(PropTypes.number),
  onSelectInvoice: PropTypes.func,
  invoice: PropTypes.shape({
    id: PropTypes.number,
    face: PropTypes.string,
    expires_at: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taxa: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    path_detalhes_do_titulo: PropTypes.string,
  }),
};

InvoiceSimulationTableRow.defaultProps = {
  activeTab: 0,
  checkBoxColor: '',
  selectedInvoices: [],
  onSelectInvoice: () => {},
  invoice: {},
  rateByPeriod: false,
};

const mapStateToProps = ({ invoiceSimulation }) => ({
  selectedInvoices: invoiceSimulation.selectedInvoices,
});

export default connect(mapStateToProps)(InvoiceSimulationTableRow);
