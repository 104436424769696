import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast/components/ToastContainer';
import hydrateStore from '../store';
import AdminVendors from './components/AdminVendors';

const View = ({ token, exampleSheetUrl }) => (
  <>
    <Provider store={hydrateStore({ adminVendors: { token, exampleSheetUrl } })}>
      <ThemeProvider theme={LiberV4}>
        <AdminVendors />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  exampleSheetUrl: PropTypes.string.isRequired,
};

export default View;
