import styled, { css, keyframes } from 'styled-components';
import { Card as BaseCard, RadioButton as BaseRadioButton } from 'liber-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled(BaseCard)`
  width: 640px;
  height: 152px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: solid 1px #cdd7df;
  margin: 0px;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 24px;
    `}

  ${({ checked }) =>
    checked &&
    css`
      background-color: #f5f5f5;
    `}
`;

const placeholderShimmer = keyframes`
  0% {
    background-position: -1700px 0;
  }
  100% {
    background-position: 1700px 0; 
  }
`;

const shimmerColorLight = 'rgba(75, 111, 133, 0.03)';
const shimmerColorDark = 'rgba(75, 111, 133, 0.08)';

export const ShimmeringCard = styled.div`
  width: 640px;
  height: 152px;
  margin: 0;
  border-radius: 4px;

  background: ${shimmerColorLight};
  background-image: linear-gradient(
    to right,
    ${shimmerColorLight} 0%,
    ${shimmerColorDark} 20%,
    ${shimmerColorLight} 40%,
    ${shimmerColorLight} 100%
  );
  background-repeat: no-repeat;
  background-size: 1920px 152px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
`;

export const RadioButton = styled(BaseRadioButton)`
  max-width: 21px;
  margin-right: 16px;

  &:focus > div:before {
    border-color: #6287a7 !important;
  }
  & input[type='radio']:checked + div:before,
  input[type='checkbox']:checked + div:before {
    border-color: #6287a7;
  }
  & div:after {
    background: #6287a7;
  }
`;

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 278px;
  margin: 0px 8px;

  & > button {
    margin: 8px 0 0 0;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const InfoIconWrapper = styled.div`
  height: 100%;
  margin-right: 8px;

  svg {
    color: #6287a7;
    width: 16px;
    height: 16px;
  }
`;

export const InfoMessage = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #6287a7;
`;
