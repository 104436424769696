import React, { useContext } from 'react';
import { TooltipBox } from 'liber-components/components/Helpers';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import { PaddingLeft, DownloadIconButton, CellWrapper } from './components/CommonStyles';
import LimitUsageBar from '../../common-components/LimitUsageBar';
import InfoButton from './components/InfoButton';
import SelectStatus from '../../common-components/SelectStatus';
import { Context } from './context';

export const VENDOR_REPORT_COOKIE_NAME = 'buyer_vendors_export_time';

export const VENDOR_NAME_FILTER = 'q[company_or_trade_name_cont]';
export const VENDOR_EMAIL_FILTER = 'q[email_cont]';
export const VENDOR_CNPJ_FILTER = 'q[cnpj_cont]';
export const START_DATE_FILTER = 'q[start_date]';
export const END_DATE_FILTER = 'q[end_date]';
export const ONLY_REGISTRATION_UPDATES_FILTER = 'q[only_status_updates]';
export const BLOCKED_ORGANIZATIONS_UPDATED_AT_LTEQ = 'q[blocked_organizations_updated_at_lteq]';
export const BLOCKED_ORGANIZATIONS_UPDATED_AT_GTEQ = 'q[blocked_organizations_updated_at_gteq]';
export const CREATED_AT_GTEQ = 'q[created_at_gteq]';
export const CREATED_AT_LTEQ = 'q[created_at_lteq]';
export const STATUS_FILTER = 'q[blocked_organizations_status_eq]';

export const STATUS_OPTIONS = [
  {
    label: 'Pendente',
    value: 'pre_approved',
  },
  {
    label: 'Aprovado',
    value: 'unblocked',
  },
  {
    label: 'Bloqueado',
    value: 'blocked',
  },
  {
    label: 'Em análise',
    value: 'under_review',
  },
];

const commonColumns = [
  {
    name: 'vendor',
    groupedColumns: ['company', 'tradeName', 'cnpj'],
    label: <>&nbsp;&nbsp;&nbsp;Fornecedor</>,
    width: 20,
    getCellValue: item => {
      const { tradeName, company, cnpj } = item;
      return (
        <PaddingLeft>
          {tradeName}
          <br />
          {company}
          <br />
          {cnpj}
        </PaddingLeft>
      );
    },
  },

  {
    name: 'email',
    label: 'E-mail',
    width: 15,
    // eslint-disable-next-line react/prop-types
    getCellValue: ({ email }) => <CellWrapper title={email}>{email}</CellWrapper>,
  },
  {
    name: 'funders',
    label: 'Investidores',
    width: 10,
    // eslint-disable-next-line react/prop-types
    getCellValue: ({ id, funders, statusOnFunderChanges }) => {
      // eslint-disable-next-line react/prop-types
      const { total, active } = funders;
      return (
        <InfoButton
          vendorId={id}
          activeFunders={active}
          totalFunders={total}
          notificationContent={statusOnFunderChanges}
        />
      );
    },
  },
  {
    name: 'registrationApproval',
    label: 'Aprovação cadastral',
    width: 15,
    getCellValue: ({ operationPermissionStatus, id }) => {
      const { handleUpdateStatus } = useContext(Context);
      return (
        <SelectStatus
          initialStatus={operationPermissionStatus || 'unblocked'}
          onChangeStatus={(status, message) => handleUpdateStatus(id, status, message)}
        />
      );
    },
  },
];

const limitColumn = onRemoveLimit => ({
  name: 'anticipation_limit',
  label: 'Limite Tomado',
  width: 15,
  getCellValue: vendor => {
    const { anticipationLimit } = vendor;
    if (anticipationLimit) {
      return <LimitUsageBar item={vendor} onRemoveLimit={onRemoveLimit} />;
    }
    return 'Nenhum limite definido';
  },
});

const groupsColumn = {
  name: 'vendorsGroupName',
  label: 'Grupo',
  width: 10,
};

const accessColumn = {
  name: 'active',
  label: 'Cadastro na Liber',
  width: 15,
  getCellValue: item => {
    const { active } = item;
    return <div>{active ? 'Ativo' : 'Bloqueado'}</div>;
  },
};

const actionColumn = {
  name: 'actions',
  label: 'Ações',
  width: 8,
  align: 'center',
  getCellValue: item => {
    const { id } = item;
    return (
      <TooltipBox mount="top" fixed content="Ver detalhes">
        <DownloadIconButton href={`/sacado/fornecedores/${id}`}>
          <Icon path={mdiInformation} />
        </DownloadIconButton>
      </TooltipBox>
    );
  },
};

export const COLUMNS = (onRemoveLimit = null, groupsAvailable = false, mustApproveVendors) => {
  const columns = [...commonColumns];

  if (mustApproveVendors) {
    columns.splice(1, 0, {
      name: 'operation_permission_updated_at',
      label: 'Data cadastro sacado',
      width: 10,
      getCellValue: ({ operationPermissionUpdatedAt }) => (
        <CellWrapper title={operationPermissionUpdatedAt}>
          {operationPermissionUpdatedAt}
        </CellWrapper>
      ),
    });
  }

  if (groupsAvailable) columns.push(groupsColumn);
  columns.push(accessColumn);

  if (onRemoveLimit) columns.push(limitColumn(onRemoveLimit));
  columns.push(actionColumn);

  return columns;
};

export const SORTING_DIALOG_COLUMNS = [
  { label: 'Razão Social', value: 'company' },
  { label: 'Nome Fantasia', value: 'tradeName' },
  { label: 'CNPJ', value: 'cnpj' },
];

export const MOCK_VENDORS_PAYLOAD = {
  data: {
    vendors: [
      {
        id: 193,
        cnpj: '86.575.843/0893-61',
        trade_name: 'Alias Illo. Fornecedor',
        company: 'Alias Illo. Fornecedor',
        email: 'alias@email.com',
        show_vendor_path: '/admin/fornecedores/alias-illo-fornecedor',
        vendors_group_name: 'Padrão',
        anticipation_limit: {
          total_value: 'R$ 3.812.000,89',
          taken_value: 'R$ 2.056.153,04',
          usage_percent: 0.67,
        },
        funders: {
          total: 10,
          active: 2,
        },
      },
      {
        id: 196,
        cnpj: '21.096.353/3102-11',
        trade_name: 'Alliage S/A Fornecedor',
        company: 'Alliage S/A Fornecedor',
        email: 'alliage@email.com',
        show_vendor_path: '/admin/fornecedores/alliage-s-a-fornecedor',
        vendors_group_name: 'ESG',
        anticipation_limit: null,
        funders: {
          total: 10,
          active: 2,
        },
      },
      {
        id: 194,
        cnpj: '96.431.019/7581-45',
        trade_name: 'Brf Fornecedor',
        company: 'Brf Fornecedor',
        email: 'brf@email.com',
        show_vendor_path: '/admin/fornecedores/brf-fornecedor',
        vendors_group_name: 'Padrão',
        anticipation_limit: {
          total_value: 'R$ 9.056.153,04',
          taken_value: 'R$ 2.056.153,04',
          usage_percent: 0.24,
        },
        funders: {
          total: 10,
          active: 2,
        },
      },
      {
        id: 199,
        cnpj: '40.823.750/0001-88',
        trade_name: 'Ccn',
        company: 'Ccn',
        email: 'cnn@email.com',
        show_vendor_path: '/admin/fornecedores/ccn',
        vendors_group_name: 'Padrão',
        anticipation_limit: null,
        funders: {
          total: 10,
          active: 2,
        },
      },
      {
        id: 192,
        cnpj: '54.441.487/8862-30',
        trade_name: 'Equipamentos Brasil Ltda Fornecedor',
        company: 'Equipamentos Brasil Ltda Fornecedor',
        email: 'equipamentos@email.com',
        show_vendor_path: '/admin/fornecedores/equipamentos-brasil-ltda-fornecedor',
        vendors_group_name: 'Padrão',
        anticipation_limit: null,
        funders: {
          total: 10,
          active: 2,
        },
      },
      {
        id: 195,
        cnpj: '70.845.779/3551-75',
        trade_name: 'Jbs Fornecedor',
        company: 'Jbs Fornecedor',
        email: 'jbs@email.com',
        show_vendor_path: '/admin/fornecedores/jbs-fornecedor',
        vendors_group_name: 'Padrão',
        anticipation_limit: {
          total_value: 'R$ 10.000.000,00',
          taken_value: 'R$ 9.000.000,00',
          usage_percent: 0.9,
        },
        funders: {
          total: 10,
          active: 2,
        },
      },
      {
        id: 197,
        cnpj: '35.319.889/3326-66',
        trade_name: 'Martin Brower Fornecedor',
        company: 'Martin Brower Fornecedor',
        email: 'martinho@email.com',
        show_vendor_path: '/admin/fornecedores/martin-brower-fornecedor',
        vendors_group_name: 'Padrão',
        anticipation_limit: null,
        funders: {
          total: 10,
          active: 2,
        },
      },
    ],
    pagination: {
      current: 1,
      previous: null,
      next: null,
      per: 10,
      pages: 1,
      count: 7,
    },
  },
};

export const ERROR_MAPPINGS = {
  cnpj: {
    title: 'Erro com os CNPJs da planilha',
    message: 'Verifique a existência e a formatação dos CNPJs',
  },
  limit: {
    title: 'Erro com os limites da planilha',
    message: 'Verifique a existência e a formatação dos valores de limites',
  },
};

export const SELECT_INTERVAL_VALUE_MAPPER = {
  0: 'Hoje',
  7: 'Últimos 7 dias',
  30: 'Últimos 30 dias',
  last_login: 'Desde o último login',
  last_report: 'Desde o último relatório gerado',
};

export const CARD_FILTER_OPTIONS = {
  interval: {
    label: 'PERÍODOS',
    options: [
      {
        value: 0,
        label: 'Hoje',
      },
      {
        value: 7,
        label: 'Últimos 7 dias',
      },
      {
        value: 30,
        label: 'Últimos 30 dias',
      },
    ],
  },
  activity: {
    label: 'POR ATIVIDADE',
    options: [
      {
        value: 'last_login',
        label: 'Desde o último login',
      },
      {
        value: 'last_report',
        label: 'Desde o último relatório gerado',
      },
    ],
  },
};
