import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const VendorsList = ({ vendors }) => (
  <S.List>
    {vendors.slice(1).map(vendor => (
      <S.Item key={vendor.id}>{vendor.name}</S.Item>
    ))}
  </S.List>
);

export default VendorsList;

VendorsList.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
};
