import Immutable from 'seamless-immutable';

const initialState = Immutable({
  operator: {
    name: '',
    email: '',
    cpf: '',
    logout: '',
  },
  organizations: {
    count: 0,
    main_access_path: '',
    payer: [],
    vendor: [],
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};
