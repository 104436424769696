import React, { useContext, useState } from 'react';
import { Modal } from 'liber-components';

import { Container, Header, Content, InputText, ActionsRow, Button, ButtonCancel } from './styles';
import { VendorsGroupDetailsContext } from '../../context';

function EditNameDialog() {
  const {
    name,
    editNameDialog: { show, onLeaved, handleCancel, handleConfirm, loading },
  } = useContext(VendorsGroupDetailsContext);

  const [editName, setEditName] = useState(name);

  const isEmptyOrEqualName = !editName || editName === name;

  const onClose = cancelAction => () => {
    cancelAction();
    setEditName(name);
  };

  const onSave = () => handleConfirm({ editName });

  return (
    <Modal show={show} onLeaved={onClose(onLeaved)}>
      <Container>
        <Header>Mudar nome do grupo?</Header>
        <Content>
          Use o campo abaixo para mudar o nome deste grupo
          <br />
          <br />
          <InputText
            label="Nome do grupo *"
            value={editName}
            onChange={setEditName}
            data-testid="edit-name-dialog-input"
          />
        </Content>
        <ActionsRow>
          <ButtonCancel onClick={onClose(handleCancel)}>CANCELAR</ButtonCancel>
          <Button loading={loading} disabled={isEmptyOrEqualName} onClick={onSave}>
            MUDAR NOME
          </Button>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

export default EditNameDialog;
