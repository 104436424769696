import styled, { css } from 'styled-components';

export const LoadingContainer = styled.div`
  ${({ isLoading }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition: opacity 150ms ease-in-out;
    z-index: 201;
    position: relative;

    ${isLoading &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
  `}
`;

export const Bunny = styled.div`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
  display: flex;
  align-items: center;
  justify-content: center;
`;
