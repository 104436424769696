import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePageState from '../../../../hooks/usePageState';
import usePagination from '../../../../hooks/usePagination';
import { getSpreadsheetData, getSpreadsheetBuyers } from '../../api';

import { BUYER_TRADE_NAME_FILTER, BUYER_CNPJ_FILTER } from '../../constants';
import { convertToCamelCase, handleError } from '../../../../vendor/Utils';

export const DetailsDrawerContext = createContext({});

const DetailsDrawerProvider = ({ children, token, spreadsheetId }) => {
  const [buyersList, setBuyersList] = useState([]);
  const [spreadsheet, setSpreadsheet] = useState({});
  const [filters, setFilters] = useState({
    [BUYER_TRADE_NAME_FILTER]: null,
    [BUYER_CNPJ_FILTER]: null,
  });
  const [loading, setLoading] = useState(false);

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const fetchSpreadSheetData = () => {
    setLoading(true);
    getSpreadsheetData(spreadsheetId, token)
      .then(({ data }) => {
        setSpreadsheet(convertToCamelCase(data));
      })
      .catch(() => {
        handleError('Ocorreu um erro ao requisitar os dados da planilha.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSpreadSheetBuyers = () => {
    setLoading(true);
    getSpreadsheetBuyers(spreadsheetId, token, { page: current, per, ...filters })
      .then(({ data }) => {
        const { buyers, pagination: incomingPagination } = convertToCamelCase(data);
        if (incomingPagination) {
          updatePagination({ ...incomingPagination, per });
        }
        setBuyersList(buyers);
      })
      .catch(() => {
        handleError('Ocorreu um erro ao requisitar os dados da planilha.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSpreadSheetData();
  }, []);

  useEffect(() => {
    fetchSpreadSheetBuyers();
  }, [currentPage, per, filters]);

  const handleChangeFilters = (key, text) => {
    setFilters({ ...filters, [key]: text });
  };

  const handleClearAllFilters = () => {
    setFilters({});
  };

  return (
    <DetailsDrawerContext.Provider
      value={{
        buyersList,
        filters,
        loading,
        token,
        onSelectListing,
        next,
        previous,
        count,
        per,
        pages,
        currentPage,
        setPageState,
        onKeyDownPage,
        pagination,
        handleChangeFilters,
        handleClearAllFilters,
        spreadsheet,
      }}
    >
      {children}
    </DetailsDrawerContext.Provider>
  );
};

DetailsDrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
  spreadsheetId: PropTypes.number.isRequired,
};

export default DetailsDrawerProvider;
