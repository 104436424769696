import React, { useState, createContext, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import usePagination from '../../hooks/usePagination';
import {
  convertToCamelCase,
  dispatchAmplitudeEvent,
  handleError,
  handleNavigate,
} from '../../vendor/Utils';
import { fetchVendors } from './api';
import { editGroup, fetchFunders } from '../create-vendors-group/api';
import { VENDORS_GROUPS_SHOW_PATH } from '../create-vendors-group/constants';
import { TokenContext } from '../../hocs/withTokenProvider';

const VendorsGroupDetailsContext = createContext({
  editNameDialog: {
    open: () => null,
    show: false,
    onLeaved: () => null,
    handleCancel: () => null,
    handleConfirm: () => null,
    loading: false,
  },
});

const VendorsGroupDetailsStore = ({
  children,
  groupId,
  name,
  referenceCode,
  isDefault,
  funderTargetingMode,
  funderId,
  isFunder,
  funderToken,
}) => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showEditNameDialog, setShowEditNameDialog] = useState(false);
  const [nameEditLoading, setNameEditLoading] = useState(false);

  const [funders, setFunders] = useState([]);

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;

  const { token: buyerToken } = useContext(TokenContext);

  const token = useMemo(
    () => (isFunder ? funderToken : buyerToken),
    [isFunder, funderToken, buyerToken],
  );

  const updateVendors = filters => {
    setLoading(true);
    fetchVendors(token, { ...filters, page: current, per }, groupId, isFunder)
      .then(response => {
        const { data } = response;
        const { vendors: incomingVendors, pagination: incomingPagination } = convertToCamelCase(
          data?.data || {},
        );
        setVendors(incomingVendors);
        updatePagination(incomingPagination);
      })
      .catch(error => {
        if (error.response.status === 404) {
          setVendors([]);
          return;
        }
        handleError('Erro!', error?.response?.data?.error?.message);
      })
      .finally(() => setLoading(false));
  };

  const handleCancelNameEdit = () => setShowEditNameDialog(false);

  const handleSaveNameEdit = ({ editName }) => {
    setNameEditLoading(true);
    editGroup(token, { name: editName }, groupId)
      .then(() => {
        dispatchAmplitudeEvent('buyer_vendors_groups_edit_success', {
          name,
        });
        handleNavigate(VENDORS_GROUPS_SHOW_PATH.replace(':id', groupId));
      })
      .catch(() => {
        handleError(
          'Erro na edição do nome grupo',
          'Houve um problema ao editar o nome do grupo. Por favor, atualize a página e tente novamente.',
        );
        setNameEditLoading(false);
      });
  };

  const handleFetchFunders = () => {
    fetchFunders(token)
      .then(response => {
        const { data } = convertToCamelCase(response.data);
        setFunders(data);
      })
      .catch(() => {
        handleError('Falha ao carregar os investidores do convênio!');
      });
  };

  useEffect(() => {
    if (!isFunder) handleFetchFunders();
  }, [isFunder]);

  return (
    <VendorsGroupDetailsContext.Provider
      value={{
        updateVendors,
        groupId,
        name,
        referenceCode,
        isDefault,
        funderTargetingMode,
        funderId,
        pages,
        current,
        next,
        previous,
        count,
        per,
        setCurrent,
        onSelectListing,
        loading,
        vendors,
        pagination,
        isFunder,
        editNameDialog: {
          open: () => setShowEditNameDialog(true),
          show: showEditNameDialog,
          onLeaved: handleCancelNameEdit,
          handleCancel: handleCancelNameEdit,
          handleConfirm: handleSaveNameEdit,
          loading: nameEditLoading,
        },
        funders,
      }}
    >
      {children}
    </VendorsGroupDetailsContext.Provider>
  );
};

VendorsGroupDetailsStore.propTypes = {
  children: PropTypes.node.isRequired,
  groupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  referenceCode: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  funderTargetingMode: PropTypes.string.isRequired,
  funderId: PropTypes.number.isRequired,
  isFunder: PropTypes.bool.isRequired,
  funderToken: PropTypes.string,
};

VendorsGroupDetailsStore.defaultProps = {
  funderToken: undefined,
};

export { VendorsGroupDetailsContext, VendorsGroupDetailsStore };
