import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { sizes } from 'liber-components/components/Util';
import { LabeledContainer, RadioButton, TextField, SelectField } from 'liber-components';
import { EmissionIssuers, States } from '../../../../../vendor/Utils';
import { Form, Row, Layout, RadioContainer, TooltipFlex, TooltipResponsive } from '../styles';
import { NavigationContainer, Next, Previous } from '../../Navigation';

class Documentacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      small: false,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ small: window.innerWidth < Number(sizes.small.replace('px', '')) });
  };

  render() {
    const {
      changeKind,
      changeNumber,
      changeEmissionDate,
      changeEmissionIssuer,
      changeEmissionState,
      changeMotherName,
      changeFatherName,
      changePep,
      documentacao,
      rg,
      cnh,
      onPrevious,
      submitForm,
    } = this.props;
    const {
      kind,
      number,
      emissionDate,
      emissionIssuer,
      emissionState,
      motherName,
      fatherName,
      pep,
      errors,
    } = documentacao;
    const { small } = this.state;

    return (
      <Layout>
        <h2>Documentação</h2>
        <Form>
          <Row>
            <LabeledContainer label="Tipo de documento" errorMessage={errors.kind}>
              <RadioContainer>
                <RadioButton
                  name="documentacao[kind]"
                  id="documentacao_kind_rg"
                  onChange={event => changeKind(event)}
                  value="rg"
                  checked={kind.includes('rg')}
                >
                  RG - Registro Geral
                </RadioButton>
                <RadioButton
                  name="documentacao[kind]"
                  id="documentacao_kind_cnh"
                  onChange={event => changeKind(event)}
                  value="cnh"
                  checked={kind.includes('cnh')}
                >
                  CNH - Carteira Nacional de Habilitação
                </RadioButton>
                <RadioButton
                  name="documentacao[kind]"
                  id="documentacao_kind_rne"
                  onChange={event => changeKind(event)}
                  value="rne"
                  checked={kind.includes('rne')}
                >
                  RNE - Registro Nacional de Estrangeiros
                </RadioButton>
              </RadioContainer>
            </LabeledContainer>
          </Row>
          <Row>
            <TooltipFlex grow>
              <TextField
                label="Número"
                name="documentacao[number]"
                id="documentacao_number"
                errorMessage={errors.number}
                onChange={event => changeNumber(event)}
                value={number}
              />
              {!kind.includes('rne') ? (
                <TooltipResponsive
                  label="Número"
                  position={small ? 'top-left' : 'top-center'}
                  small={small}
                  hoverable={!small}
                >
                  <img
                    alt="number"
                    src={kind.includes('rg') ? rg.number : cnh.number}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </TooltipResponsive>
              ) : null}
            </TooltipFlex>
            <TooltipFlex grow>
              <TextField
                label="Data de Emissão"
                name="documentacao[emission_date]"
                id="documentacao_emission_date"
                hintText="DD/MM/AAAA"
                errorMessage={errors.emission_date}
                onChange={event => changeEmissionDate(event)}
                value={emissionDate}
                mask="99/99/9999"
                maskChar={null}
              />
              {!kind.includes('rne') ? (
                <TooltipResponsive
                  label="Data de Emissão"
                  position={small ? 'top-left' : 'top-center'}
                  small={small}
                  hoverable={!small}
                >
                  <img
                    alt="emission_date"
                    src={kind.includes('rg') ? rg.emission_date : cnh.emission_date}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </TooltipResponsive>
              ) : null}
            </TooltipFlex>
            {kind.includes('rg') ? (
              <TooltipFlex grow>
                <SelectField
                  searchable
                  label="Órgão Expedidor"
                  name="documentacao[emission_issuer]"
                  id="documentacao_emission_issuer"
                  errorMessage={errors.emission_issuer}
                  onChange={event => changeEmissionIssuer(event)}
                  value={emissionIssuer}
                >
                  {Object.keys(EmissionIssuers).map(iss => (
                    <option key={_.uniqueId()} value={iss}>
                      {EmissionIssuers[iss]}
                    </option>
                  ))}
                </SelectField>
                <TooltipResponsive
                  label="Órgão Expedidor"
                  position={small ? 'top-left' : 'top-center'}
                  small={small}
                  hoverable={!small}
                >
                  <img
                    alt="emission_issuer"
                    src={kind.includes('rg') ? rg.emission_issuer : cnh.emission_issuer}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </TooltipResponsive>
              </TooltipFlex>
            ) : null}
            <TooltipFlex grow>
              <SelectField
                searchable
                label="Estado de Emissão"
                name="documentacao[emission_state]"
                id="documentacao_emission_state"
                errorMessage={errors.emission_state}
                onChange={event => changeEmissionState(event)}
                value={emissionState}
              >
                {Object.keys(States).map(st => (
                  <option key={_.uniqueId()} value={States[st]}>
                    {st}
                  </option>
                ))}
              </SelectField>
              {!kind.includes('rne') ? (
                <TooltipResponsive
                  label="Estado de Emissão"
                  position={small ? 'top-left' : 'top-center'}
                  small={small}
                  hoverable={!small}
                >
                  <img
                    alt="emission_state"
                    src={kind.includes('rg') ? rg.emission_state : cnh.emission_state}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </TooltipResponsive>
              ) : null}
            </TooltipFlex>
          </Row>
          <Row>
            <TooltipFlex grow>
              <TextField
                label="Nome da Mãe"
                name="documentacao[mother_name]"
                id="documentacao_mother_name"
                errorMessage={errors.mother_name}
                onChange={event => changeMotherName(event)}
                value={motherName}
              />
              <TooltipResponsive
                label="Nome da Mãe"
                position={small ? 'top-left' : 'top-center'}
                small={small}
                hoverable={!small}
              >
                <div>Incluir exatamente como consta no seu documento</div>
              </TooltipResponsive>
            </TooltipFlex>
            <TextField
              label="Nome do Pai (opcional)"
              name="documentacao[father_name]"
              id="documentacao_father_name"
              errorMessage={errors.father_name}
              onChange={event => changeFatherName(event)}
              value={fatherName}
            />
          </Row>
          <Row>
            <TooltipFlex alignItems="top">
              <LabeledContainer
                label="Você é uma pessoa Politicamente Exposta (PEP)?"
                errorMessage={errors.pep}
              >
                <RadioContainer style={{ justifyContent: 'center' }}>
                  <RadioButton
                    name="documentacao[pep]"
                    id="documentacao_pep_yes"
                    onChange={event => changePep(event)}
                    value="true"
                    checked={pep}
                  >
                    Sim
                  </RadioButton>
                  <RadioButton
                    name="documentacao[pep]"
                    id="documentacao_pep_no"
                    onChange={event => changePep(event)}
                    value="false"
                    checked={!pep}
                  >
                    Não
                  </RadioButton>
                </RadioContainer>
              </LabeledContainer>
              <TooltipResponsive
                label="Pessoa Políticamente Exposta (PEP)"
                position={small ? 'top-left' : 'top-center'}
                small={small}
                hoverable={!small}
              >
                <div>
                  Consideram-se pessoas politicamente expostas aquelas previstas nos artigos 1o e 2o
                  da Resolução no. 29/17 do COAF.
                </div>
              </TooltipResponsive>
            </TooltipFlex>
          </Row>
        </Form>
        <NavigationContainer>
          <Previous onClick={onPrevious} />
          <Next onClick={() => submitForm(documentacao)} style={{ float: 'right' }} />
        </NavigationContainer>
      </Layout>
    );
  }
}

Documentacao.propTypes = {
  changeKind: PropTypes.func.isRequired,
  changeNumber: PropTypes.func.isRequired,
  changeEmissionDate: PropTypes.func.isRequired,
  changeEmissionIssuer: PropTypes.func.isRequired,
  changeEmissionState: PropTypes.func.isRequired,
  changeMotherName: PropTypes.func.isRequired,
  changeFatherName: PropTypes.func.isRequired,
  changePep: PropTypes.func.isRequired,
  documentacao: PropTypes.shape({
    kind: PropTypes.string,
    number: PropTypes.string,
    emissionDate: PropTypes.string,
    emissionIssuer: PropTypes.string,
    emissionState: PropTypes.string,
    motherName: PropTypes.string,
    fatherName: PropTypes.string,
    pep: PropTypes.bool,
    isPatch: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  rg: PropTypes.objectOf(PropTypes.string).isRequired,
  cnh: PropTypes.objectOf(PropTypes.string).isRequired,
  onPrevious: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default Documentacao;
