import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast/components/ToastContainer';
import hydrateStore from '../store';
import AnticipationCancel from './components/AnticipationCancel';

const View = ({ store }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <ThemeProvider theme={LiberV4}>
        <AnticipationCancel />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  store: PropTypes.shape({}),
};

View.defaultProps = {
  store: {},
};

export default View;
