import Immutable from 'seamless-immutable';
import { SET_LOADING, UPDATE_VENDOR_REPLICATE_PORTALS } from '../actions/replicateVendorActions';

export const initialState = Immutable({
  loading: false,
  vendorId: '',
  token: '',
  vendorPortalOptions: [],
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return state.merge({ loading: payload });

    case UPDATE_VENDOR_REPLICATE_PORTALS: {
      const { portals } = payload;
      return state.merge({
        vendorPortalOptions: portals,
      });
    }

    default:
      return state;
  }
};
