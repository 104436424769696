import styled from 'styled-components';
import { FaFilePdfO, handleThemeFromObject } from 'liber-components';

export const CheckboxLabel = styled.div`
  display: inline;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
`;

export const Icon = styled(FaFilePdfO)`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;

export const Download = styled.div`
  display: flex;
  cursor: pointer;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  float: right;
  text-decoration: none;
  align-items: center;
  transition: all 0.2s ease;
  &:hover,
  &:active {
    color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
    fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  }
`;

export const PdfViewer = styled.object`
  height: 256px;
  width: 100%;
`;
