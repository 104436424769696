import { STATUS_EQ } from '../../../utils';

const statusList = [
  {
    name: 'Aberta',
    value: '0',
  },
  {
    name: 'Agendada',
    value: '7',
  },
  {
    name: 'Aguardando Aceite',
    value: '1',
  },
  {
    name: 'Aguardando Formalização',
    value: '8',
  },
  // { // TODO: uncomment when external term is released
  //   name: 'Aguardando Conclusão sem termo',
  //   value: -1,
  // },
  {
    name: 'Aguardando Liberação',
    value: '9',
  },
  {
    name: 'Aguardando Pagamento Agendado',
    value: '10',
  },
  {
    name: 'Canceladas',
    value: '6',
  },
  // { // TODO: uncomment when external term is released
  //   name: 'Concluídas sem termo de cessão',
  //   value: '-2',
  // },
  {
    name: 'Concluídas',
    value: '5',
  },
];

export const getFilterParamsFromUrl = () => {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.searchParams);
  let newParams = {};
  searchParams.forEach((inputValue, filterKey) => {
    newParams = { ...newParams, [filterKey]: inputValue };
  });
  return newParams;
};

export const INVOICES_LABEL = 'Número do(s) Título(s)';
export const INVOICE_BASED_LABEL = 'Cobrança baseada em';
export const INVESTOR_TYPE_LABEL = 'Tipo de Investidor';
export const RESPONSABILITY_LABEL = 'Responsabilidade do rebate';
export const LABEL_XML = 'XML';

export const INVESTOR_TYPE_MAP = {
  0: 'Pessoa Física',
  1: 'Pessoa Jurídica',
};

export const INVOICE_BASED_MAP = {
  invoice_expires_at: 'Vencimento do título',
  trade_finished_at: 'Data da operação',
};

export const RESPONSABILITY_MAP = {
  funder: 'Investidor',
  liber: 'Liber',
};

export const FILTERS = [
  {
    type: 'text',
    filterKey:
      'q[duplicata_number_or_duplicata_reference_number_or_duplicata_reference_number_edi_contains_any]',
    label: INVOICES_LABEL,
  },
  {
    type: 'text',
    filterKey:
      'q[fornecedor_name_or_fornecedor_company_or_fornecedor_trade_name_or_fornecedor_cnpj_contains_clear_any]',
    label: 'Nome(s) do(s) Fornecedor(es)',
  },
  {
    type: 'text',
    filterKey:
      'q[sacado_name_or_sacado_company_or_sacado_trade_name_or_sacado_cnpj_contains_clear_any]',
    label: 'Nome(s) do(s) Sacado(s)',
  },

  {
    type: 'text',
    filterKey:
      'q[investidor_name_or_investidor_company_or_investidor_trade_name_or_investidor_cnpj_contains_clear_any]',
    label: 'Nome(s) do(s) Investidor(es)',
  },
  {
    type: 'select',
    options: [
      { name: 'Pessoa Física', value: '0' },
      { name: 'Pessoa Jurídica', value: '1' },
    ],
    filterKey: 'q[investidor_kind_person_type_eq]',
    label: INVESTOR_TYPE_LABEL,
  },
  {
    type: 'select',
    options: [
      { name: 'Vencimento do título', value: 'invoice_expires_at' },
      { name: 'Data da operação', value: 'trade_finished_at' },
    ],
    filterKey: 'q[with_buyer_funder_agreement][charge_based_on_eq]',
    label: INVOICE_BASED_LABEL,
  },
  {
    type: 'select',
    options: [
      { name: 'Investidor', value: 'funder' },
      { name: 'Liber', value: 'liber' },
    ],
    filterKey: 'q[with_buyer_funder_agreement][rebate_payer_eq]',
    label: RESPONSABILITY_LABEL,
  },
  {
    type: 'select',
    options: statusList,
    filterKey: STATUS_EQ,
    label: 'Status da Negociação',
  },

  {
    type: 'date',
    filterKey: 'duplicata_expires_at',
    filterKeys: [
      'q[duplicata_expires_at_gteq]',
      'q[duplicata_expires_at_lteq]',
      'q[duplicata_expires_at_eq]',
    ],
    label: 'Vencimento do Título',
    position: 'bottom-left-down-right',
  },
  {
    type: 'date',
    filterKey: 'finished_at',
    filterKeys: ['q[finished_at_gteq]', 'q[finished_at_lteq]', 'q[finished_at_eq]'],
    label: 'Negociação Finalizada',
    position: 'bottom-left-down-right',
  },
  {
    type: 'date',
    filterKey: 'canceled_at',
    filterKeys: ['q[canceled_at_date_gteq]', 'q[canceled_at_date_lteq]', 'q[canceled_at_date_eq]'],
    label: 'Negociação Cancelada',
    position: 'bottom-right-down-left',
  },

  {
    type: 'date',
    filterKey: 'financed_at',
    filterKeys: ['q[financed_at_date_gteq]', 'q[financed_at_date_lteq]', 'q[financed_at_date_eq]'],
    label: 'Antecipação Realizada',
    position: 'top-left-up-right',
  },
  {
    type: 'date',
    filterKey: 'duplicata_expected_payment_at',
    filterKeys: [
      'q[duplicata_expected_payment_at_gteq]',
      'q[duplicata_expected_payment_at_lteq]',
      'q[duplicata_expected_payment_at_eq]',
    ],
    label: 'Recebimento Esperado',
    position: 'top-left-up-right',
  },
  {
    type: 'date',
    filterKey: 'settled_at',
    filterKeys: ['q[settled_at_date_gteq]', 'q[settled_at_date_lteq]', 'q[settled_at_date_eq]'],
    label: 'Recebimento Realizado',
    position: 'top-right-up-left',
  },
];

export const FILTERS_DOCUMENT = [
  {
    type: 'checkbox',
    filterKey: 'q[duplicata_nota_fiscal_xml_file_not_null]',
    label: LABEL_XML,
  },
];

export const getLabelByFilterKey = key => {
  let desiredLabel = 'Filtro';
  FILTERS.forEach(filter => {
    const { filterKey, filterKeys, label } = filter;
    if ((filterKeys && filterKeys.includes(key)) || filterKey === key) {
      desiredLabel = label;
    }
  });
  FILTERS_DOCUMENT.forEach(filter => {
    const { filterKey, filterKeys, label } = filter;
    if ((filterKeys && filterKeys.includes(key)) || filterKey === key) {
      desiredLabel = label;
    }
  });
  return desiredLabel;
};
