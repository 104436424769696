import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import VendorAnticipations from '../../common-components/VendorAnticipations';

const View = props => (
  <>
    <ThemeProvider theme={LiberV4}>
      <VendorAnticipations {...props} />
    </ThemeProvider>
  </>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  fornecedorId: PropTypes.number.isRequired,
  operator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cpf: PropTypes.string,
  }).isRequired,
};

export default View;
