import { handleError } from '../../vendor/Utils';

export const removeDuplicates = (array, rejectArray) =>
  array.filter(({ id }) => !rejectArray.map(item => item.id).includes(id));

export const handleCreationError = (message, setReferenceError) => {
  switch (message) {
    case 'VENDORS_GROUP_EMPTY_NAME':
      handleError(
        'Nome do grupo não pode ser vazio',
        'O campo "Nome do grupo" é obrigatório na criação do grupo.',
      );
      break;
    case 'VENDORS_GROUP_EXISTING_REFERENCE':
      handleError(
        'Código de referência duplicado',
        'Já existe um grupo cadastrado com o mesmo código de referência.',
      );
      setReferenceError('Código de referência já existe');
      break;
    case 'VENDORS_GROUP_EMPTY_VENDORS':
      handleError(
        'Nenhum fornecedor adicionado',
        'Não é possível criar um grupo sem fornecedores.',
      );
      break;
    default:
      handleError(
        'Erro na criação do grupo',
        'Houve um problema ao criar o novo grupo. Por favor, atualize a página e tente novamente.',
      );
      break;
  }
};

export const sortByTradeName = (a, b) => {
  if (a.tradeName < b.tradeName) {
    return -1;
  }
  if (a.tradeName > b.tradeName) {
    return 1;
  }
  return 0;
};

export const arrayFromXtoY = (x, y) => Array.from({ length: y - x + 1 }, (_, i) => i + x);
