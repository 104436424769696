import SimpleMaskMoney from 'simple-mask-money';
import currencyFormatter from 'currency-formatter';

const args = {
  decimalSeparator: ',',
  fixed: true,
  fractionDigits: 2,
  suffix: '',
  thousandsSeparator: '.',
};

SimpleMaskMoney.args = args;

const currencyEmptyState = `0${args.decimalSeparator}0`;

export const formatMoney = (value, withCurrency = false) =>
  `${withCurrency ? 'R$ ' : ''}${currencyFormatter.format(value, { code: 'BRL', format: '%v' })}`;

export const changeMoneyValue = (value, callback) => {
  if (value === currencyEmptyState || value === '0' || value === '') {
    callback('');
  } else {
    callback(SimpleMaskMoney.format(value));
  }
};

export const removeMoneyMask = value => SimpleMaskMoney.formatToNumber(value);
