import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  TabHeader,
  Tabs,
  Tab,
  DropDown,
  DropDownOption as Option,
  PeriodPicker,
  getScreenSize,
} from 'liber-components';
import {
  StatementContainer,
  Row,
  Column,
  Title,
  StatementCard,
  SummaryCard,
  CardHeader,
  TabFilter,
  PeriodBox,
  FilterBox,
  TabSelect,
  SummaryEmptyState,
  SummaryLoadingState,
} from './InvestorStatement-style';
import StatementEntry from '../StatementEntry';
import SortableStatement from './SortableStatement';
import { fetchByPeriod as fetch } from '../../actions/statementActions';
import Fade from './Fade';

export class InvestorStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: 'asc',
      filter: 0,
      screenSize: getScreenSize(window),
      startDate: moment().startOf('day').subtract(4, 'days').format('YYYY-MM-DD'),
      endDate: moment().startOf('day').format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  handleSelectFilter = filter => {
    if (filter === 'Tudo') {
      this.setState({ filter: 0 });
    } else {
      this.setState({ filter });
    }
  };

  handlePeriodSelect = (value, start, end) => {
    const { fetchByPeriod, token } = this.props;
    this.setState(
      {
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
      },
      () => {
        fetchByPeriod(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), token);
      },
    );
  };

  renderSummary = (summary, loading) => {
    if (loading) {
      return (
        <SummaryCard>
          <CardHeader>Resumo do Período</CardHeader>
          <Column>
            <Fade show={loading} maxOpacity={0.5} timeout={750}>
              <SummaryLoadingState />
            </Fade>
            <Fade show={!loading} maxOpacity={0.5} timeout={750}>
              <SummaryEmptyState />
            </Fade>
          </Column>
        </SummaryCard>
      );
    }
    return (
      <SummaryCard>
        <CardHeader>Resumo do Período</CardHeader>
        <Column style={{ marginBottom: '8px', marginTop: '8px' }}>
          <StatementEntry
            type="summary-opening-balance"
            entry={{ date: summary.from, amount: summary.opening_balance }}
          />
          <StatementEntry type="summary-credited" entry={summary.credits} />
          <StatementEntry type="summary-debited" entry={summary.debits} />
          <StatementEntry
            type="summary-closing-balance"
            entry={{ date: summary.to, amount: summary.closing_balance }}
          />
        </Column>
      </SummaryCard>
    );
  };

  render() {
    const { screenSize, filter, startDate, endDate } = this.state;
    const { summary, loading } = this.props;
    return (
      <StatementContainer>
        <Row>
          <Title>Extrato de Movimentações</Title>
        </Row>
        <Row>
          <StatementCard>
            <CardHeader>
              {moment(startDate).locale('pt-br').format('DD MMM')} -{' '}
              {moment(endDate).locale('pt-br').format('DD MMM')}
              <PeriodBox>
                <PeriodPicker value="last-30" onSelect={this.handlePeriodSelect}>
                  <Option value="last-5">Últimos 05 dias</Option>
                  <Option value="last-15">Últimos 15 dias</Option>
                  <Option value="last-30">Últimos 30 dias</Option>
                  <Option value="month-picker" hasAction>
                    Selecionar Mês
                  </Option>
                </PeriodPicker>
              </PeriodBox>
            </CardHeader>
            <Column>
              <Tabs active={filter}>
                <TabHeader>
                  {labels => {
                    const { screenSize: localScreenSize, filter: localFilter } = this.state;
                    const children = labels.props.children[0];
                    const clones = React.Children.toArray(children).map((label, index) =>
                      React.cloneElement(label, {
                        onClick: () => {
                          this.handleSelectFilter(index);
                        },
                      }),
                    );
                    const clonedLabels = React.cloneElement(labels, {
                      children: [clones, labels.props.children[1]],
                    });
                    switch (localScreenSize) {
                      case 'extraLarge':
                      case 'large':
                        return (
                          <>
                            <TabFilter>{clonedLabels}</TabFilter>
                          </>
                        );
                      case 'medium':
                      case 'small':
                        return (
                          <TabSelect>
                            <FilterBox>
                              <DropDown
                                value={localFilter}
                                onSelect={this.handleSelectFilter}
                                position="top-right"
                              >
                                {React.Children.toArray(children).map((option, index) => (
                                  <Option value={index} key={option.props['data-value']}>
                                    {option.props['data-value']}
                                  </Option>
                                ))}
                              </DropDown>
                            </FilterBox>
                          </TabSelect>
                        );
                      default:
                        return null;
                    }
                  }}
                </TabHeader>
                <div data-ref="test">{this.state.orderBy}</div>
                <Tab label="Tudo" key={this.state.orderBy}>
                  <SortableStatement screenSize={screenSize} />
                </Tab>
                <Tab label="Depósitos">
                  <SortableStatement screenSize={screenSize} filter={['deposit']} />
                </Tab>
                <Tab label="Compras">
                  <SortableStatement screenSize={screenSize} filter={['purchase']} />
                </Tab>
                <Tab label="Recebimentos">
                  <SortableStatement screenSize={screenSize} filter={['receipt', 'fee']} />
                </Tab>
                <Tab label="Retiradas">
                  <SortableStatement screenSize={screenSize} filter={['withdraw']} />
                </Tab>
              </Tabs>
            </Column>
          </StatementCard>
          {screenSize === 'large' || screenSize === 'extraLarge'
            ? this.renderSummary(summary, loading)
            : null}
        </Row>
      </StatementContainer>
    );
  }
}

InvestorStatement.propTypes = {
  summary: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchByPeriod: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

const reducer = 'statement';

const parentReducer = 'investorStatement';

const mapStateToProps = state => ({ ...state[parentReducer][reducer] });

const mapDispatchToProps = {
  fetchByPeriod: fetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorStatement);
