import AssinaMeSDK from 'assiname-sdk-js';
import { toast } from 'liber-components';
import errorCodeMap from './errorCodeMap';
import { wsUrl } from '../urls';
import CableConnection from './cableConnection';

const channelName = 'MultiAssinaturaChannel';
let timeout;

const timeoutValue = 600 * 1000;
// const timeoutValue = 60 * 1000;

export const checkIsEcnpj = (certificates, selected) =>
  selected.length === 0
    ? false
    : { ...certificates.find(cert => cert.fingerprint === selected) }.type === 'pj';

export const selectSingleCertificate = (payload, ecnpjSignature) => {
  const { certificates } = payload;
  if (certificates.length) {
    const selected = payload.certificates[0];
    const expired = selected.usage && selected.usage.expired;
    if (selected.type && selected.type !== 'empty' && !expired) {
      if (!ecnpjSignature && checkIsEcnpj(certificates, selected.fingerprint)) {
        return '';
      }
      return selected.fingerprint;
    }
  }
  return '';
};

function checkBrowserSupport() {
  const errorList = [];
  if (AssinaMeSDK.Utils.currentPlatform() !== 'computer') {
    errorList.push({
      title: `Plataforma não suportada (${AssinaMeSDK.Utils.platformDisplayName()})`,
      type: 'platform',
      critical: true,
    });
    return errorList;
  }

  if (!AssinaMeSDK.Support.isOSSupported()) {
    errorList.push({
      title: `Sistema Operacional não suportado (${AssinaMeSDK.Utils.osDisplayName()})`,
      type: 'os',
      critical: true,
    });
    return errorList;
  }
  if (!AssinaMeSDK.Support.isBrowserSupported()) {
    errorList.push({
      title: `Navegador não suportado (${AssinaMeSDK.Utils.browserDisplayName()})`,
      type: 'browser',
      critical: false,
    });
  }
  if (!AssinaMeSDK.hasExtension()) {
    errorList.push({
      title: 'Extensão para o navegador não localizada',
      type: 'extension',
      critical: false,
    });
  }

  if (!AssinaMeSDK.hasNative()) {
    errorList.push({
      title: 'Aplicativo nativo não localizado',
      type: 'native',
      critical: false,
    });
  }
  return errorList;
}

export function showPdf(url) {
  return {
    type: 'MULTI_SIGN_FORNECEDOR_SHOW_PDF',
    payload: url,
  };
}

export function hidePdf() {
  return {
    type: 'MULTI_SIGN_FORNECEDOR_HIDE_PDF',
  };
}

export function setSelectedPartner(id) {
  return {
    type: 'MULTI_SIGN_FORNECEDOR_SELECT_PARTNER',
    payload: id,
  };
}

export function setSelectedCertificate(value, isEcnpj, ecnpjSignature) {
  const status = !(isEcnpj && ecnpjSignature === false);
  const label = 'Esta assinatura não pode ser feita utilizando um certificado eCNPJ';
  const toasts = status
    ? []
    : [
        {
          message: label,
          type: 'warning',
          noButton: true,
        },
      ];
  const error = {
    display: [],
    flow: status ? 'no-error' : 'non-blocking',
    type: 'ecnpj',
    toasts,
  };
  return {
    type: 'MULTI_SIGN_FORNECEDOR_SELECT_CERTIFICATE',
    payload: { status, error, value },
  };
}

export function assinaMeWaitForExtension() {
  return {
    type: 'MULTI_SIGN_FORNECEDOR_ASSINAME_WAITING_EXTENSION',
    payload: {
      title: 'Verificando dependências',
      message: 'Estamos verificando se as dependências estão instaladas.',
    },
  };
}

export function assinaMeBrowserSupportErrors() {
  const supportErrors = checkBrowserSupport();
  const errors = supportErrors.filter(error => error.critical);
  const toasts = [];
  if (errors.length > 0) {
    toasts.push({
      message: errors[0].title,
      type: 'error',
    });
  } else {
    toasts.push({
      message:
        supportErrors.length > 1
          ? `${supportErrors.length} problemas precisam da sua atenção`
          : 'Um problema precisa da sua atenção',
      type: 'warning',
    });
  }
  return {
    type: 'MULTI_SIGN_FORNECEDOR_ASSINAME_SUPPORT_ERRORS',
    payload: {
      display: ['support'],
      flow: errors.length > 0 ? 'blocker' : 'no-error',
      supportErrors,
      toasts,
    },
  };
}

export function assinaMeSystemCheck(invoices, ecnpjSignature) {
  return dispatch =>
    AssinaMeSDK.systemCheck(timeoutValue).then(
      () =>
        AssinaMeSDK.listCertificates(timeoutValue).then(
          certificates => {
            const payload = {
              certificates:
                certificates.length !== 0
                  ? certificates
                  : [
                      {
                        name: 'Nenhum certificado encontrado',
                        type: 'empty',
                        fingerprint: '',
                        usage: { valid: false },
                      },
                    ],
              loadingMessage: {
                title: 'Preparando Documentos',
                message: 'Aguarde enquanto estamos gerando seus documentos',
              },
            };
            const selected = selectSingleCertificate(payload, ecnpjSignature);
            dispatch({
              type: 'MULTI_SIGN_FORNECEDOR_ASSINAME_GET_LIST_SUCCESS',
              payload,
            });
            dispatch(
              setSelectedCertificate(
                selected,
                checkIsEcnpj(payload.certificates, selected),
                ecnpjSignature,
              ),
            );
          },
          () => {
            const message =
              invoices.length > 1
                ? `A preparação dos ${invoices.length} documentos falhou`
                : 'A preparação do documento falhou';
            dispatch({
              type: 'MULTI_SIGN_FORNECEDOR_ASSINAME_GET_LIST_ERROR',
              payload: {
                display: ['what-to-do'],
                message: 'Erro na Leitura dos Certificados',
                ...errorCodeMap(0x4000),
                label: message,
                toasts: [
                  {
                    message,
                    type: 'error',
                  },
                ],
              },
            });
          },
        ),
      () => dispatch(assinaMeBrowserSupportErrors()),
    );
}

const signDocumentsMessage = length => {
  const message = length === 1 ? 'documento sendo assinado' : 'documentos sendo assinados';
  return `${length > 0 && length < 10 ? '0' : ''}${length} ${message}`;
};

const waitForPassword = (dispatch, filteredInvoices) => {
  window.removeEventListener('AssinaMe:signHashes:postSign', waitForPassword);
  dispatch({
    type: 'MULTI_SIGN_FORNECEDOR_PASSWORD_ACCEPTED',
    payload: {
      title: 'Formalizando Operação',
      message: signDocumentsMessage(filteredInvoices.length),
    },
  });
};

export function signDocuments(requestIp, certificate, invoices, partnerId, ecnpjEnabled) {
  if (!ecnpjEnabled) {
    return dispatch => {
      dispatch({
        type: 'MULTI_SIGN_FORNECEDOR_SIGN_ERROR',
        payload: {
          display: [],
          flow: 'non-blocking',
          type: 'ecnpj',
          toasts: [
            {
              message: 'Esta assinatura não pode ser feita utilizando um certificado eCNPJ',
              type: 'warning',
              noButton: true,
            },
          ],
        },
      });
    };
  }
  const notFailed = invoices.filter(invoice => invoice.jobStatus !== 'failed');
  const filteredInvoices = notFailed.filter(
    invoice =>
      !invoice.signedIds.includes(partnerId) &&
      invoice.finished !== 'true' &&
      invoice.jobStatus !== 'failed',
  );
  const tokenList = filteredInvoices.map(invoice => invoice.token);
  return dispatch => {
    window.addEventListener(
      'AssinaMe:signHashes:postSign',
      waitForPassword.bind(null, dispatch, filteredInvoices),
    );
    dispatch({
      type: 'MULTI_SIGN_FORNECEDOR_SIGN_PENDING',
      payload: {
        title: 'Aguardando o PIN',
        message: `Se não encontrar a janela para digitar,
                  procure e selecione o ícone destacado em sua barra de tarefas`,
      },
    });
    return AssinaMeSDK.signHashes(certificate, tokenList, timeoutValue).then(
      () => {
        dispatch({
          type: 'MULTI_SIGN_FORNECEDOR_SIGN_SUCCESS',
        });
        const data = {
          worker: 'signature',
          ip: requestIp,
          representante_legal_id: partnerId,
          invoices: filteredInvoices.map(invoice => ({
            invoice_id: invoice.id,
            token: invoice.token,
          })),
        };
        const label =
          notFailed.length > 1
            ? `A assinatura dos ${notFailed.length} documentos falhou`
            : 'A assinatura do documento falhou';
        const errorAction = {
          type: 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_TIMEOUT_ERROR',
          payload: {
            display: ['what-to-do'],
            flow: 'non-blocking',
            message:
              notFailed.length > 1
                ? 'Erro na assinatura dos Documentos'
                : 'Erro na assinatura do Documento',
            type: 'server-sign-timeout',
            label,
            toasts: [
              {
                message: label,
                type: 'error',
              },
            ],
          },
        };
        const error = () => {
          timeout = setTimeout(() => {
            CableConnection.resetConnection();
            dispatch(errorAction);
          }, timeoutValue);
        };
        const conn = CableConnection.getConnection();
        conn.sendMessage(channelName, data, error);
      },
      signError => {
        const label =
          notFailed.length > 1
            ? `A assinatura dos ${notFailed.length} documentos falhou`
            : 'A assinatura do documento falhou';
        dispatch({
          type: 'MULTI_SIGN_FORNECEDOR_SIGN_ERROR',
          payload: {
            ...errorCodeMap(signError.code),
            display: ['what-to-do'],
            message: 'Erro na assinatura dos Documentos',
            failedInvoices: filteredInvoices,
            label,
            toasts: [
              {
                message: label,
                type: 'error',
              },
            ],
          },
        });
      },
    );
  };
}

const checkInvoiceError = invoices => invoices.some(invoice => invoice.invoiceError);

const handleSignInvoiceError = invoices => {
  const label =
    invoices.length > 1
      ? `A assinatura dos ${invoices.length} documentos falhou`
      : 'A assinatura do documento falhou';
  return {
    error: {
      display: ['error-list'],
      flow: 'non-blocking',
      failedInvoices: invoices,
      message:
        invoices.length > 1
          ? 'Erro na assinatura dos Documentos'
          : 'Erro na assinatura do Documento',
      type: 'server-sign',
      label,
      toasts: [
        {
          message: label,
          type: 'error',
        },
      ],
    },
    invoices,
  };
};

const handlePrepareInvoiceError = invoices => {
  const label =
    invoices.length > 1
      ? `A preparação dos ${invoices.length} documentos falhou`
      : 'A preparação do documento falhou';
  return {
    error: {
      display: ['what-to-do'],
      flow: 'blocker',
      failedInvoices: invoices,
      message:
        invoices.length > 1
          ? 'Erro na preparação dos Documentos'
          : 'Erro na preparação do Documento',
      type: 'server-gen',
      label,
      toasts: [
        {
          message: label,
          type: 'error',
        },
      ],
    },
    invoices,
  };
};

const handleGenFailedAllJobs = (dispatch, invoiceError, invoices) => {
  let payload;
  if (invoiceError) {
    payload = handleSignInvoiceError(invoices);
  } else {
    payload = handlePrepareInvoiceError(invoices);
  }
  dispatch({
    type: 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_ERROR',
    payload,
  });
};

const handleGenFailedSomeJobs = (
  dispatch,
  invoices,
  done = true,
  type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION_WITH_ERRORS',
) => {
  const failedInvoices = invoices.filter(
    invoice => invoice.jobStatus === 'failed' || invoice.invoiceError,
  );
  dispatch({
    type,
    payload: {
      invoices,
      done,
      error: {
        display: ['error-list'],
        flow: 'no-error',
        message: '',
        type: 'server-gen-soft',
        failedInvoices,
        toasts: [
          {
            message:
              failedInvoices.length > 1
                ? `${failedInvoices.length} problemas precisam da sua atenção`
                : 'Um problema precisa da sua atenção',
            type: 'warning',
          },
        ],
      },
    },
  });
};

const handleGenSuccessfulJobs = (
  dispatch,
  invoices,
  done = true,
  type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION',
) => {
  dispatch({
    type,
    payload: { invoices, done },
  });
};

const handleInvoiceError = (dispatch, response) => {
  const { invoices, invoicesDone, partners, downloadAllDone } = response;
  const failedInvoices = invoices.filter(
    invoice => invoice.jobStatus === 'failed' || invoice.invoiceError,
  );
  const label =
    invoices.length > 1
      ? `A assinatura dos ${failedInvoices.length} documento${
          failedInvoices.length > 0 ? 's' : ''
        } falhou`
      : 'A assinatura do documento falhou';
  dispatch({
    type: 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_WITH_ERRORS',
    payload: {
      invoices,
      invoicesDone,
      partners,
      downloadAllDone,
      error: {
        display: ['error-list'],
        flow: 'non-blocking',
        failedInvoices,
        message: '',
        type: 'server-sign-soft',
        label,
        toasts: [
          {
            message:
              failedInvoices.length > 1
                ? `${failedInvoices.length} problemas precisam da sua atenção`
                : 'Um problema precisa da sua atenção',
            type: 'warning',
          },
        ],
      },
    },
  });
};

const checkEmptyTokenInvoices = (invoices, fornecedorId) => {
  const emptyTokenInvoices = invoices.filter(
    invoice => !invoice.token && invoice.finished !== 'true',
  );
  const nonEmptyTokenInvoices = invoices.filter(
    invoice => invoice.token || invoice.finished === 'true',
  );
  const conn = CableConnection.getConnection();
  const counter = conn.getCounter();
  if (emptyTokenInvoices.length !== 0) {
    const data = {
      worker: 'term',
      fornecedor_id: fornecedorId,
      proposta_ids: emptyTokenInvoices.map(invoice => invoice.id),
    };
    const sended = conn.sendCountedMessage(channelName, data);
    if (sended) {
      return {
        emptyToken: true,
        done: false,
        fail: nonEmptyTokenInvoices.some(
          invoice => invoice.jobStatus === 'failed' || invoice.invoiceError,
        ),
        invoices: nonEmptyTokenInvoices,
        counter,
      };
    }
    return {
      emptyToken: true,
      done: true,
      fail: true,
      invoices: emptyTokenInvoices.map(invoice => ({ ...invoice, jobStatus: 'failed' })),
      counter,
    };
  }
  return {
    emptyToken: counter !== 0,
    done: true,
    fail: false,
    invoices,
    counter,
  };
};

const handleGenEmptyTokenFailedSomeJobs = (...args) => {
  const type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION_WITH_ERRORS_EMPTY_TOKEN';
  handleGenFailedSomeJobs(...[...args, type]);
};

const handleGenEmptyTokenSuccessfulJobs = (...args) => {
  const type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION_EMPTY_TOKEN';
  handleGenSuccessfulJobs(...[...args, type]);
};

const handleSignSuccessfulJobs = (
  dispatch,
  jointSignature,
  ecnpjSignature,
  invoices,
  response,
  done = true,
  updatePartners = true,
  type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED',
) => {
  const { partners, invoicesDone, downloadAllDone, worker } = response;
  dispatch({
    type,
    payload: {
      invoices,
      invoicesDone,
      partners: updatePartners ? partners : null,
      downloadAllDone,
      done,
    },
  });
  if (worker === 'signatures') {
    const message =
      invoices.length > 1
        ? 'Documentos assinados com sucesso. Favor aguardar a confirmação do pagamento.'
        : 'Documento assinado com sucesso. Favor aguardar a confirmação do pagamento.';
    toast(message, 'success', 8000);
    if (jointSignature && !ecnpjSignature) {
      if (!invoicesDone) {
        setTimeout(() => {
          toast('Lembre-se de assinar com os demais representantes legais.', 'warning', 8000);
        }, 1000);
      }
    }
  }
};

const handleSignFailedAllJobs = (dispatch, invoices) => {
  const label =
    invoices.length > 1
      ? `A assinatura dos ${invoices.length} documentos falhou`
      : 'A assinatura do documento falhou';
  dispatch({
    type: 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_ERROR',
    payload: {
      error: {
        display: ['error-list', 'what-to-do'],
        flow: 'blocker',
        failedInvoices: invoices,
        message:
          invoices.length > 1
            ? 'Erro na assinatura dos Documentos'
            : 'Erro na assinatura do Documento',
        type: 'server-sign',
        label,
        toasts: [
          {
            message: label,
            type: 'error',
          },
        ],
      },
      invoices,
    },
  });
};

const handleSignFailedSomeJobs = (
  dispatch,
  invoices,
  response,
  done = true,
  updatePartners = true,
  type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_WITH_ERRORS',
) => {
  const { invoicesDone, partners, downloadAllDone, worker } = response;
  const failedInvoices = invoices.filter(
    invoice => invoice.jobStatus === 'failed' || invoice.invoiceError,
  );
  const label =
    failedInvoices.length > 1
      ? `A ${worker === 'signatures' ? 'assinatura' : 'preparação'} dos ${
          failedInvoices.length
        } documentos falhou`
      : `A ${worker === 'signatures' ? 'assinatura' : 'preparação'} do documento falhou`;
  const errorType = worker === 'signatures' ? 'server-sign-soft' : 'server-gen-soft';
  dispatch({
    type,
    payload: {
      invoices,
      invoicesDone,
      partners: updatePartners ? partners : null,
      downloadAllDone,
      done,
      error: {
        display: ['error-list'],
        flow: 'non-blocking',
        failedInvoices,
        message: '',
        type: errorType,
        label,
        toasts: [
          {
            message:
              failedInvoices.length > 1
                ? `${failedInvoices.length} problemas precisam da sua atenção`
                : 'Um problema precisa da sua atenção',
            type: 'warning',
          },
        ],
      },
    },
  });
};

const handleSignEmptyTokenFailedSomeJobs = (...args) => {
  const type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_WITH_ERRORS_EMPTY_TOKEN';
  handleSignFailedSomeJobs(...[...args, type]);
};

const handleSignEmptyTokenSuccessfulJobs = (...args) => {
  const type = 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_EMPTY_TOKEN';
  handleSignSuccessfulJobs(...[...args, type]);
};

const dispatchGeneratedDocuments = (dispatch, data, fornecedorId) => {
  const { failedCount, jobsFailed, invoices } = data.response;
  const {
    emptyToken,
    done,
    fail,
    invoices: filteredInvoices,
    counter,
  } = checkEmptyTokenInvoices(invoices, fornecedorId);
  const invoiceError = checkInvoiceError(invoices);
  if (jobsFailed && failedCount === invoices.length && !emptyToken && counter === 0) {
    handleGenFailedAllJobs(dispatch, invoiceError, invoices);
  } else if (jobsFailed && invoiceError) {
    handleInvoiceError(dispatch, data.response);
  } else if (emptyToken) {
    if (fail) {
      handleGenEmptyTokenFailedSomeJobs(dispatch, filteredInvoices, done);
    } else {
      handleGenEmptyTokenSuccessfulJobs(dispatch, filteredInvoices, done);
    }
  } else if (jobsFailed) {
    handleGenFailedSomeJobs(dispatch, filteredInvoices);
  } else {
    handleGenSuccessfulJobs(dispatch, filteredInvoices);
  }
};

const dispatchSignedDocuments = (dispatch, data, jointSignature, ecnpjSignature, fornecedorId) => {
  const { invoices, failedCount, jobsFailed } = data.response;
  const {
    emptyToken,
    done,
    fail,
    invoices: filteredInvoices,
    counter,
  } = checkEmptyTokenInvoices(invoices, fornecedorId);
  if (jobsFailed && failedCount === invoices.length && counter === 0) {
    handleSignFailedAllJobs(dispatch, invoices);
  } else if (emptyToken) {
    if (fail) {
      handleSignEmptyTokenFailedSomeJobs(
        dispatch,
        filteredInvoices,
        data.response,
        done,
        counter === 0,
      );
    } else {
      handleSignEmptyTokenSuccessfulJobs(
        dispatch,
        jointSignature,
        ecnpjSignature,
        filteredInvoices,
        data.response,
        done,
        counter === 0,
      );
    }
  } else if (jobsFailed) {
    handleSignFailedSomeJobs(dispatch, filteredInvoices, data.response);
  } else {
    handleSignSuccessfulJobs(
      dispatch,
      jointSignature,
      ecnpjSignature,
      filteredInvoices,
      data.response,
    );
  }
};

const receivedCallback = (dispatch, jointSignature, ecnpjSignature, fornecedorId) => data => {
  clearTimeout(timeout);
  const { anySigned, responseException } = data.response;
  if (responseException) {
    dispatch({
      type: 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_ERROR',
      payload: {
        invoices: [],
        error: {
          display: ['what-to-do'],
          flow: 'blocker',
          message: 'Ops, algo inesperado aconteceu.',
          type: 'unknown',
          label: 'Erro na finalização do(s) documento(s)',
          toasts: [
            {
              message: 'Erro na finalização do(s) documento(s)',
              type: 'error',
            },
          ],
        },
      },
    });
  } else if (!anySigned) {
    dispatchGeneratedDocuments(dispatch, data, fornecedorId);
  } else {
    dispatchSignedDocuments(dispatch, data, jointSignature, ecnpjSignature, fornecedorId);
  }
};

const errorCallback = (dispatch, errorAction) => () => {
  timeout = setTimeout(() => {
    CableConnection.resetConnection();
    dispatch(errorAction);
  }, timeoutValue);
};

export function startSocket(fornecedorId, jointSignature, ecnpjSignature, propostaIds, invoices) {
  const label =
    invoices.length > 1
      ? `A preparação dos ${invoices.length} documentos falhou`
      : 'A preparação do documento falhou';
  const errorAction = {
    type: 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_TIMEOUT_ERROR',
    payload: {
      display: ['what-to-do'],
      flow: 'blocker',
      message: 'Erro na preparação dos Documentos',
      type: 'server-gen-timeout',
      label,
      toasts: [
        {
          message: label,
          type: 'error',
        },
      ],
    },
  };
  return dispatch => {
    const conn = new CableConnection(wsUrl);
    const received = receivedCallback(dispatch, jointSignature, ecnpjSignature, fornecedorId);

    const error = errorCallback(dispatch, errorAction);
    const connected = () => {
      const data = {
        worker: 'term',
        fornecedor_id: fornecedorId,
        proposta_ids: propostaIds,
      };
      conn.sendMessage(channelName, data, error);
    };
    conn.setUpChannel(channelName, received, connected);
  };
}

export function removeSocket() {
  CableConnection.resetConnection();
  return {
    type: 'MULTI_SIGN_FORNECEDOR_REMOVE_SOCKET',
  };
}
