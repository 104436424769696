import axios from 'axios';
import moment from 'moment';
import { INVOICES_MODAL_MAP } from '../components/MyWallet/utils';
import { INVOICES_URL, LIQUIDATED_INVOICES_URL } from '../url';

export const INVESTOR_DASH_FETCH_INVOICES = 'INVESTOR_DASH_FETCH_INVOICES';
export const INVESTOR_DASH_UPDATE_INVOICES = 'INVESTOR_DASH_UPDATE_INVOICES';
export const INVESTOR_DASH_INVOICES_FAIL = 'INVESTOR_DASH_INVOICES_FAIL';
export const INVESTOR_DASH_CLOSE_INVOICES = 'INVESTOR_DASH_CLOSE_INVOICES';
export const DIALOG_UPDATE_CONTENT = 'DIALOG_UPDATE_CONTENT';

export const openModalLoading = modalTitle => ({
  type: INVESTOR_DASH_FETCH_INVOICES,
  payload: modalTitle,
});
export const updateInvoices = response => ({
  type: INVESTOR_DASH_UPDATE_INVOICES,
  payload: response,
});
export const invoicesFail = response => ({ type: INVESTOR_DASH_INVOICES_FAIL, payload: response });
export const closeInvoiceList = () => ({ type: INVESTOR_DASH_CLOSE_INVOICES });
export const updateDialogContent = response => ({
  type: DIALOG_UPDATE_CONTENT,
  payload: response,
});

export function getInvoicesData(token, params, title, type) {
  return async dispatch => {
    let url = INVOICES_URL;

    let filters = {};
    let selectedTitle = title || INVOICES_MODAL_MAP.all.title;

    if (type) {
      const {
        title: mappedTitle,
        filters: mappedFilters,
        url: mappedUrl,
      } = INVOICES_MODAL_MAP[type];
      filters = mappedFilters;
      url = mappedUrl;
      selectedTitle = mappedTitle;
    }

    dispatch(openModalLoading(selectedTitle));

    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { ...filters, ...params },
      })
      .then(response => dispatch(updateInvoices(response)))
      .catch(error => dispatch(invoicesFail(error)));
  };
}

export function getInvoicesByLiquidation(token, selectedDate) {
  return async dispatch => {
    const hasLiquidated = selectedDate.startOf('day').isBefore(moment().startOf('day'));
    const showFormatted = selectedDate.format('DD/MM/YYYY');
    const searchFormatted = selectedDate.format('YYYY-MM-DD');

    const url = hasLiquidated ? LIQUIDATED_INVOICES_URL : INVOICES_URL;

    const modalTitle = `Títulos ${
      hasLiquidated ? 'liquidados' : 'a liquidar'
    } no dia ${showFormatted}`;

    dispatch(openModalLoading(modalTitle));

    const liquidatedFilter = hasLiquidated ? { payment_overdue_eq: false } : {};
    const params = {
      liquidation_date_from: searchFormatted,
      liquidation_date_to: searchFormatted,
      ...liquidatedFilter,
    };

    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(response => dispatch(updateInvoices(response)))
      .catch(error => dispatch(invoicesFail(error)));
  };
}
