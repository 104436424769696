import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  media,
  CardContent as RawCardContent,
  SuccessButton,
  Button as RawButton,
  FaCheck,
  FaEdit,
  FaTrash,
  FaPlus,
  FaTimes,
  FaStar,
  FaStarEmpty,
  PrimaryButton as RawPrimaryButton,
  handleThemeFromObject as theme,
  LoadingSpinner,
  Drawer as RawDrawer,
  Card as RawCard,
  CardHeader,
} from 'liber-components';

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  width: auto;
  padding: 0px 24px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  min-height: 58px;
  & > * {
    flex-grow: 1;
    padding: 4px 0px;
  }

  & > div {
    width: 100%;
  }

  & span:after {
    border-color: ${props => theme(props.theme, 'colors.liberBlue', '#00df99')};
  }

  ${media.large`
    flex-direction: row;
    align-items: center;
    &:hover{
      background-color: ${props => theme(props.theme, 'colors.lightGrey', '#ffffff')};
    }
    & > div {
      width: unset;
    }
  `}
`;

const Spinner = styled.span`
  margin-right: 8px;
  & span:after {
    border-color: ${props => theme(props.theme, 'colors.white', '#ffffff')};
  }
`;
export const StarIcon = styled(FaStar)`
  --height: 20px;
  --width: 20px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  fill: ${props => theme(props.theme, 'colors.warningYellow', '#fabe00')};
  display: inline-block;
  margin-right: 8px;
`;

export const EmptyStarIcon = styled(FaStarEmpty)`
  --height: 20px;
  --width: 20px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  fill: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
  display: inline-block;
  margin-right: 8px;
`;

export const EditIcon = styled(FaEdit)`
  --height: 20px;
  --width: 20px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  display: block;
  ${media.large`
    margin-right: 8px;
    margin-bottom: -2px;
  `}
`;
export const CloseIcon = styled(FaTimes)`
  --height: 30px;
  --width: 30px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  display: block;
  margin-right: 8px;
  margin-bottom: -2px;
  fill: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
  margin-left: auto;
  margin-top: 16px;
`;

export const EraseIcon = styled(FaTrash)`
  --height: 20px;
  --width: 20px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  display: block;

  ${media.large`
    margin-right: 8px;
    margin-bottom: -2px;
  `}
`;

export const AddIcon = styled(FaPlus)`
  --height: 20px;
  --width: 20px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  display: block;
  ${media.large`
    margin-right: 8px;
    margin-bottom: -2px;
  `}
`;

export const CheckIcon = styled(FaCheck)`
  --height: 20px;
  --width: 20px;
  min-height: var(--height);
  max-height: var(--height);
  min-width: var(--width);
  max-width: var(--width);
  display: block;
  margin-right: 8px;
  fill: ${props => theme(props.theme, 'colors.white', '#ffffff')};
`;

export const Button = styled(RawButton)`
  --color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
  --hover-color: ${props => theme(props.theme, 'colors.actionBlue', '#4cbaff')};
  background-color: ${props => theme(props.theme, 'colors.white', '#ffffff')};
  color: var(--color);
  display: flex;
  justify-content: center;
  align-items: center;

  & ${Spinner} span:after {
    border-color: var(--color);
  }

  &:hover ${Spinner} span:after {
    border-color: var(--hover-color);
  }

  &:hover {
    background-color: ${props => theme(props.theme, 'colors.white', '#ffffff')};
    color: var(--hover-color);
  }
  & ${EditIcon}, & ${EraseIcon}, & ${CheckIcon}, & ${EmptyStarIcon} {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    fill: var(--color);
  }
  &:after {
    background: var(--hover-color);
  }
  &:hover ${EditIcon}, &:hover ${EraseIcon}, &:hover ${CheckIcon}, &:hover ${EmptyStarIcon} {
    fill: var(--hover-color);
  }
  & > span:not(${Spinner}) {
    display: none;
    ${media.large`
      display: inline-block;
    `}
  }
  ${media.large`
    justify-content: flex-start;
  `}
`;

export const PrimaryButton = styled(RawPrimaryButton)`
  float: right;

  & svg {
    fill: ${props => theme(props.theme, 'colors.white', '#ffffff')};
  }
`;

export const CardContent = styled(RawCardContent)`
  min-height: 350px;
  padding: 24px 0px;
`;

export const ConfirmationContent = styled(RawCardContent)`
  width: 100%;
  height: 200px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  ${media.large`
    width: 400px;
    height: 250px;
  `}
`;

export const ConfirmationText = styled.div`
  flex-grow: 1;
  font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => theme(props.theme, 'fontWeights.regular', 400)};
  color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
`;

export const Divider = styled.hr`
  margin: 16px 8px;
  display: ${props => (props.last ? 'none' : null)};
  border-color: ${props => theme(props.theme, 'colors.mediumGrey2', '#cecece')};
  ${media.large`
    margin: ${props => (props.atStart ? '8px 16px 64px 16px' : '64px 16px')};
  `}
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  padding: 0px 24px;

  ${media.large`
    flex-direction: row;
    justify-content: space-between;
    min-height: 58px;
  `}

  & ${SuccessButton}, & ${Button} {
    align-self: center;
    ${media.large`
      margin-left: auto;
    `}
  }

  & h1 {
    margin: 0px 0px 8px 0px !important;
    font-size: ${props => theme(props.theme, 'fontSizes.h4', '24px')};
    font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};
    color: ${props => theme(props.theme, 'colors.liberBlack', '#4e4e4e')};
    ${media.large`
      font-size: ${props => theme(props.theme, 'fontSizes.h3', '32px')};
      margin: 0px 16px 8px 0px!important;
    `}
  }
  & h2 {
    margin: 0px 0px 8px 0px !important;
    font-size: ${props => theme(props.theme, 'fontSizes.h6', '18px')};
    font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};
    color: ${props => theme(props.theme, 'colors.liberBlack', '#4e4e4e')};
    display: flex;
    align-items: center;
    flex-direction: column;
    ${media.large`
    flex-direction: row;
      font-size: ${props => theme(props.theme, 'fontSizes.h4', '24px')};
      margin: 0px 16px 8px 0px!important;
    `}
  }
  & h2 > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & h1 > small {
    font-size: ${props => theme(props.theme, 'fontSizes.h6', '18px')};
    position: relative;
    display: inline;
    font-weight: ${props => theme(props.theme, 'fontWeights.regular', 400)};
    color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
    ${media.large`
      margin-left: 21px;
      font-size: ${props => theme(props.theme, 'fontSizes.h5', '20px')};
    `}
  }
  & h2 > small {
    font-size: ${props => theme(props.theme, 'fontSizes.h6', '16px')};
    position: relative;

    display: inline;
    font-weight: ${props => theme(props.theme, 'fontWeights.regular', 400)};
    color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
    ${media.large`
    margin-left: 21px;
      font-size: ${props => theme(props.theme, 'fontSizes.h5', '20px')};
    `}
  }
  & h1 > p {
    font-size: ${props => theme(props.theme, 'fontSizes.h5', '20px')};
    position: relative;
    margin-left: 8px;
    display: inline;
    font-weight: ${props => theme(props.theme, 'fontWeights.regular', 400)};
    color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
  }
  & h2 > p {
    font-size: ${props => theme(props.theme, 'fontSizes.h6', '18px')};
    position: relative;
    margin-left: 8px;
    display: inline;
    font-weight: ${props => theme(props.theme, 'fontWeights.regular', 400)};
    color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
  }

  & h1 > small:before {
    --color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
    content: '';
    display: none;
    background-color: var(--color);
    width: 5px;
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    left: -12px;
    border-radius: 2.5px;

    ${media.large`
      display: block;
    `}
  }
  & h2 > small:before {
    --color: ${props => theme(props.theme, 'colors.darkGrey', '#717171')};
    content: '';
    display: none;
    background-color: var(--color);
    width: 5px;
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    left: -12px;
    border-radius: 2.5px;
    ${media.large`
      display: block;
    `}
  }
  & p {
    margin: 0px;
    color: var(--color);
  }
`;

export const ButtonsContainer = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.large`
    justify-content: flex-end;
  `}

  & > * {
    flex-grow: 0;
    padding: 0 16px;
  }
  &:hover {
    background-color: unset;
  }
`;

export const ModalContent = styled(CardContent)`
  & ${HeaderRow} {
    margin-top: 0px;
  }

  height: calc(100vh - 58px);

  overflow: auto;
  padding: 0px 0px 24px 0px;
  margin: 0px;

  ${media.large`
    overflow: hidden;
    height: unset;
    max-height: 80vh;
    overflow: auto;
    padding: 24px 0px;
    & ${HeaderRow}{
      margin-top: 19px;
    }
    & ${Row} > div:not(${ButtonsContainer}) {
      margin: 0px 8px;
    }
  `}
`;

export const Info = styled.div`
  --weight: ${props => theme(props.theme, 'fontWeights.medium', 500)};
  color: ${props => theme(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  font-weight: var(--weight);

  & span {
    color: ${props => theme(props.theme, 'colors.liberBlack', '#4e4e4e')};
    font-weight: var(--weight);
  }

  ${media.large`
    ${({ fullWidth }) =>
      fullWidth ||
      css`
        max-width: 33%;
      `}
  `}
`;

export const SubmitButton = ({ children, loading, onClick, ...props }) => (
  <SuccessButton {...props} onClick={loading ? null : onClick}>
    {loading ? (
      <Spinner>
        <LoadingSpinner size="20px" />
      </Spinner>
    ) : (
      <CheckIcon />
    )}
    {children}
  </SuccessButton>
);
SubmitButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
SubmitButton.defaultProps = { children: '', loading: false, onClick: () => null };

export const EditButton = ({ children, ...props }) => (
  <Button {...props}>
    <EditIcon />
    <span>{children}</span>
  </Button>
);
EditButton.propTypes = { children: PropTypes.node };
EditButton.defaultProps = { children: '' };

export const EraseButton = ({ children, loading, onClick, ...props }) => (
  <Button {...props} onClick={loading ? null : onClick}>
    {loading ? (
      <Spinner>
        <LoadingSpinner size="20px" />
      </Spinner>
    ) : (
      <EraseIcon />
    )}
    <span>{children}</span>
  </Button>
);
EraseButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
EraseButton.defaultProps = { children: '', loading: false, onClick: () => null };

export const DefaultButton = ({ children, loading, onClick, ...props }) => (
  <Button {...props} onClick={loading ? null : onClick}>
    {loading ? (
      <Spinner>
        <LoadingSpinner size="20px" />
      </Spinner>
    ) : (
      <EmptyStarIcon />
    )}
    <span>{children}</span>
  </Button>
);
DefaultButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
DefaultButton.defaultProps = { children: '', loading: false, onClick: () => null };

export const AddButton = ({ children, ...props }) => (
  <PrimaryButton {...props}>
    <AddIcon />
    {children}
  </PrimaryButton>
);
AddButton.propTypes = { children: PropTypes.node };
AddButton.defaultProps = { children: '' };

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  ${media.large`
    width: unset;
    justify-content: flex-end;
  `}
`;

export const RadioContainer = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-wrap: wrap;

  label {
    display: block;
    margin: 15px 0px;
  }

  ${media.medium`
    display: block;
    label {
      display: inline-block;
      margin: 5px 0px;
    }
  `}
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      closing: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.closing) {
      return { closing: false };
    }
    return { show: nextProps.show };
  }

  render() {
    const { children, show: propShow, ...props } = this.props;
    const { show } = this.state;
    return (
      <RawDrawer {...props} fitScreen show={show}>
        <Row
          onClick={() => {
            this.setState({ show: false, closing: true });
          }}
        >
          <CloseIcon />
        </Row>
        {children}
      </RawDrawer>
    );
  }
}

Drawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]),
  show: PropTypes.bool,
};

Drawer.defaultProps = {
  children: '',
  show: false,
};

export const PDFContainer = styled.div`
  flex-grow: 1;
  min-width: calc(100% - 16px);
  margin: 0px 8px;
  box-sizing: border-box;

  & object {
    width: 100%;
    height: 400px;
  }

  & object p {
    text-align: center;
  }

  ${media.large`
  min-width: calc(50% - 16px);
  `}
`;

export const Card = styled(RawCard)`
  width: 100%;
`;

export const TabHead = styled(CardHeader)`
  width: 100%;
  height: 63px;
  padding: 0px;
`;

export const SelectHead = styled(TabHead)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 8px;
`;
