import React from 'react';
import LimitsHeader from '../LimitsHeader';
import LimitsModal from '../LimitsModal';
import LimitsTable from '../LimitsTable';

const LimitsView = () => (
  <>
    <LimitsHeader />
    <LimitsTable />
    <LimitsModal />
  </>
);

export default LimitsView;
