import styled from 'styled-components';
import { Text, FlatButton } from 'liber-components';

export const FlashAlert = styled.div`
  width: 98%;
  min-height: 64px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(35, 39, 47, 0.64);
  background-color: #f9fafa;
  margin: 0 4px 24px 4px;
  padding: 12px 12px 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: rgba(35, 39, 47, 0.64);
  border-left: #009dff solid 4px;

  & > ${Text} {
    max-width: calc(100% - 140px);
  }
`;

export const LinkButton = styled(FlatButton).attrs(() => ({ as: 'a' }))`
  text-transform: uppercase;

  :hover {
    color: #009dff;
  }
`;
