import axios from 'axios';
import { SHEETS_LIST_URL } from './constants';

export async function sendSpreadsheet(token = '', spreadsheet, roomId) {
  const formData = new FormData();
  formData.append('spreadsheet', spreadsheet);
  formData.append('room_id', roomId);

  return axios.post(SHEETS_LIST_URL, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    timeout: 15000,
  });
}
