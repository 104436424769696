import Immutable from 'seamless-immutable';
import {
  FETCH_VENDORS,
  UPDATE_VENDORS,
  UPDATE_SORTING,
  VENDOR_FILTER,
  BUYER_FILTER,
  UPDATE_FILTERS,
  SIGNATURE_METHOD_FILTER,
  SIGNATURE_CERTIFICATION_FILTER,
  CHANGE_PAGE,
  HIDE_SCHEDULES,
  SET_SELECTED_VENDORS,
  SHOW_SCHEDULES_MODAL,
  SET_SELECTED_VENDOR_BUYER_ID,
  SET_SHOW_FUNDERS_LIST_MODAL,
  SET_SELECTED_VENDORS_FUNDERS_IDS,
  CHANGE_LISTING,
  SET_SELECTED_VENDOR_ID,
  CHANGE_SELECTED_FUNDER_TO_BLOCK_OR_UNBLOCK,
  CHANGE_BLOCK_UNBLOCK_MODAL_STATUS,
  CHANGE_INTERMEDIATE_STATUS_MODAL,
  SET_SHOW_BATCH_BLOCK_UNBLOCK_MODAL,
  SET_SELECTED_CNPJS_TO_BATCH_BLOCK_OR_UNBLOCK,
  SET_IS_BATCH_BLOCK_UNBLOCK,
} from '../actions/AdminVendorsActions';
import { EMPTY_VENDOR_OBJECT } from '../constants';

export const initialState = Immutable({
  token: null,
  vendors: [],
  loading: false,
  filters: {
    [VENDOR_FILTER]: null,
    [BUYER_FILTER]: null,
    [SIGNATURE_METHOD_FILTER]: [],
    [SIGNATURE_CERTIFICATION_FILTER]: [],
  },
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 10,
    pages: 1,
    count: 1,
  },
  sorting: [{ columnName: 'company', direction: 'asc' }],
  showSchedules: true,
  selectedVendors: [],
  showSchedulesModal: false,
  selectedVendorBuyerId: null,
  showFundersListModal: false,
  selectedVendorsFundersIds: [],
  selectedVendorId: null,
  selectedFunderToBlockOrUnblock: EMPTY_VENDOR_OBJECT,
  showVendorBlockUnblockModal: false,
  showHandleBlockUnblockModal: false,
  showBatchBlockUnblockModal: false,
  exampleSheetUrl: null,
  selectedCnpjsToBatchBlockUnblock: [],
  isBatchBlockUnblock: false,
});

export default (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case FETCH_VENDORS:
      return state.merge({ loading: true });

    case UPDATE_VENDORS: {
      const { payload } = action;
      return state.merge({ ...payload, loading: false });
    }

    case UPDATE_SORTING: {
      const {
        payload: { sorting },
      } = action;
      return state.merge({ sorting });
    }

    case UPDATE_FILTERS: {
      const {
        payload: { filters: updates },
      } = action;
      const { filters, pagination } = state;

      return state.merge({
        filters: { ...filters, ...updates },
        pagination: { ...pagination, current: 1 },
      });
    }

    case CHANGE_PAGE: {
      const { pagination } = state;
      const {
        payload: { page },
      } = action;

      return state.merge({ pagination: { ...pagination, current: page } });
    }

    case HIDE_SCHEDULES: {
      const {
        payload: { showSchedules },
      } = action;
      return state.merge({ showSchedules });
    }

    case SET_SELECTED_VENDORS: {
      const {
        payload: { selectedVendors },
      } = action;

      return state.merge({ selectedVendors });
    }

    case SHOW_SCHEDULES_MODAL: {
      const {
        payload: { shouldShowSchedulesModal },
      } = action;

      return state.merge({ showSchedulesModal: shouldShowSchedulesModal });
    }

    case SET_SELECTED_VENDOR_BUYER_ID: {
      const {
        payload: { selectedVendorBuyerId },
      } = action;

      return state.merge({ selectedVendorBuyerId });
    }

    case SET_SELECTED_VENDOR_ID: {
      const {
        payload: { vendorId },
      } = action;

      return state.merge({ selectedVendorId: vendorId });
    }

    case SET_SHOW_FUNDERS_LIST_MODAL: {
      const {
        payload: { shouldShowFundersListModal },
      } = action;

      return state.merge({ showFundersListModal: shouldShowFundersListModal });
    }

    case SET_SELECTED_VENDORS_FUNDERS_IDS: {
      const {
        payload: { fundersIds },
      } = action;

      return state.merge({ selectedVendorsFundersIds: fundersIds });
    }

    case CHANGE_LISTING: {
      const {
        payload: { per },
      } = action;

      return state.merge({ pagination: { per } });
    }

    case CHANGE_SELECTED_FUNDER_TO_BLOCK_OR_UNBLOCK: {
      const { payload } = action;

      return state.merge({ selectedFunderToBlockOrUnblock: payload });
    }

    case CHANGE_BLOCK_UNBLOCK_MODAL_STATUS: {
      const { payload } = action;

      return state.merge({ showVendorBlockUnblockModal: payload });
    }

    case CHANGE_INTERMEDIATE_STATUS_MODAL: {
      const { payload } = action;

      return state.merge({ showHandleBlockUnblockModal: payload });
    }

    case SET_SHOW_BATCH_BLOCK_UNBLOCK_MODAL: {
      const { payload } = action;

      return state.merge({ showBatchBlockUnblockModal: payload });
    }

    case SET_SELECTED_CNPJS_TO_BATCH_BLOCK_OR_UNBLOCK: {
      const {
        payload: { cnpjs },
      } = action;

      return state.merge({ selectedCnpjsToBatchBlockUnblock: cnpjs });
    }

    case SET_IS_BATCH_BLOCK_UNBLOCK: {
      const {
        payload: { isBatchBlockUnblock },
      } = action;

      return state.merge({ isBatchBlockUnblock });
    }

    default:
      return state;
  }
};
