import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mdiClose } from '@mdi/js';
import VirtualizedList from '../../../../common-components/VirtualizedList';
import ListItem from '../ListItem';

import {
  Container,
  TextInput,
  HelperText,
  SelectAllRow,
  Separator,
  BoldText,
  Loading,
  Bunny,
  TextInputWrapper,
  RemoveIcon,
  CenterItem,
  Spinner,
} from './styles';

import { SelectCheckbox, Text } from '../CommonStyles';

const filterFields = ['cnpj', 'tradeName', 'company', 'role'];

const organizationFilter = (organization, searchString) =>
  Object.keys(organization).some(k => {
    if (typeof organization[k] === 'string' && filterFields.includes(k)) {
      return organization[k]
        .toString()
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '')
        .includes(searchString.toLowerCase().replace(/[^a-z0-9]/g, ''));
    }
    return false;
  });

const TransferList = ({
  loading,
  firstLoading,
  organizations,
  selectedOrganizationsIds,
  organizationsCount,
  handleSelect,
}) => {
  const [filter, setFilter] = useState('');
  const [selectedAll, setSelectedAll] = useState(false);

  const handleFilter = string => {
    setFilter(string);
  };

  const filteredOrganizations = useMemo(
    () => organizations.filter(organization => organizationFilter(organization, filter)),
    [organizations, filter],
  );

  const filteredOrganizationsSelectedCount = useMemo(
    () =>
      filteredOrganizations.reduce(
        (count, organization) =>
          selectedOrganizationsIds.includes(organization.id) ? count + 1 : count,
        0,
      ),
    [filteredOrganizations, selectedOrganizationsIds],
  );

  const onSelect = organizationId => {
    handleSelect([organizationId]);
  };

  const onSelectAll = e => {
    e?.preventDefault();
    if (loading) return setSelectedAll(true);

    return handleSelect(
      filteredOrganizations.map(({ id }) => id),
      true,
      filteredOrganizationsSelectedCount === filteredOrganizations.length,
    );
  };

  const handleClearFilter = () => {
    setFilter('');
  };

  const isLoading = useMemo(() => {
    if (firstLoading) return true;
    return loading && (filter || selectedAll);
  }, [loading, filter, selectedAll, firstLoading]);

  useEffect(() => {
    if (!loading && selectedAll) onSelectAll();
  }, [loading]);

  const isEmpty = useMemo(
    () => !(loading || firstLoading || organizationsCount > 0),
    [loading, firstLoading, organizationsCount],
  );

  return (
    <Container>
      <TextInputWrapper>
        <TextInput
          value={filter}
          onChange={text => handleFilter(text)}
          label="Buscar organizações"
          prefix={filter.length > 0 && <RemoveIcon onClick={handleClearFilter} path={mdiClose} />}
        />
      </TextInputWrapper>
      {isLoading && (
        <Loading full={firstLoading}>
          <Bunny />
        </Loading>
      )}
      <HelperText>Pesquise por Nome ou CNPJ</HelperText>
      <SelectAllRow onClick={onSelectAll}>
        <SelectCheckbox
          checked={
            filteredOrganizationsSelectedCount === filteredOrganizations.length &&
            filteredOrganizationsSelectedCount > 0
          }
        />
        <div>
          <BoldText>Todas as organizações</BoldText>
          <Text>
            {selectedOrganizationsIds.length}/{organizationsCount} Selecionado
            {selectedOrganizationsIds.length !== 1 && 's'}
          </Text>
        </div>
      </SelectAllRow>
      <Separator />
      {!isEmpty ? (
        <VirtualizedList
          rowRenderer={({ index, key, style }) => {
            const { id, tradeName, cnpj, role } = filteredOrganizations[index] || {};
            return (
              <div key={key} style={style}>
                {id ? (
                  <ListItem
                    selected={selectedOrganizationsIds.includes(id)}
                    handleSelect={() => onSelect(id)}
                    tradeName={tradeName}
                    cnpj={cnpj}
                    role={role}
                  />
                ) : (
                  <CenterItem>
                    {filteredOrganizations.length > 5 && (
                      <>
                        <Spinner />
                        Carregando mais organizações...
                      </>
                    )}
                  </CenterItem>
                )}
              </div>
            );
          }}
          rowCount={
            loading && !firstLoading
              ? filteredOrganizations.length + 1
              : filteredOrganizations.length
          }
        />
      ) : (
        <CenterItem>
          Ainda não há organizações no grupo.
          <br />
          Selecione-as na lista ao lado para adicionar.
        </CenterItem>
      )}
    </Container>
  );
};

TransferList.propTypes = {
  loading: PropTypes.bool,
  firstLoading: PropTypes.bool,
  organizations: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedOrganizationsIds: PropTypes.arrayOf(PropTypes.number),
  organizationsCount: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

TransferList.defaultProps = {
  loading: false,
  firstLoading: false,
  selectedOrganizationsIds: [],
};

export default TransferList;
