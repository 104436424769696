import axios from 'axios';

// eslint-disable-next-line no-undef
const BASE_URL = process.env.SERVICE_API_URL;

export async function fetchBuyerOrganizationGroup(token = '', buyerOrganizationGroupId = '') {
  return axios.get(
    `${BASE_URL}/api/v1/admin/buyer_organization_groups/${buyerOrganizationGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
