import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Button, ListItem } from 'liber-salti';

import LoginFooter from '../../../../common-components/LoginFooter';
import { handleNavigate } from '../../../../vendor/Utils';

import {
  Container,
  Content,
  CardContent,
  Logo,
  LogoWrapper,
  CaptionWrapper,
  ActionsWrapper,
  CompaniesContainer,
  SectionTitle,
} from './styles';
import { Analytics } from '../../../../libs/googleAnalytics';
import { isAdmin } from '../../../../libs/auth/admin';

const OperatorCompanySelection = ({ operator, organizations }) => {
  const { name, logout, cpf } = operator;
  const { payer, vendor, funder } = organizations;

  useEffect(() => {
    Analytics.emitCustomEvent('operator_company_start_selection', {
      operator: {
        name,
        cpf,
        type: isAdmin() ? 'admin' : 'user',
      },
    });
  }, []);

  const handleClick = ({ path, tradeName, type, ...data }) => {
    handleNavigate(path);
    Analytics.emitCustomEvent('operator_company_selected', {
      type,
      tradeName,
      operator: {
        name,
        cpf,
        type: isAdmin() ? 'admin' : 'user',
      },
      ...data,
    });
  };

  return (
    <Container>
      <Content>
        <Card>
          <CardContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography variant="h2" color="textPrimary">
              Olá, {name}
            </Typography>
            <CaptionWrapper>
              <Typography variant="body1" color="textSecondary">
                Qual empresa você quer acessar?
              </Typography>
            </CaptionWrapper>
            <CompaniesContainer>
              {payer.length > 0 && (
                <>
                  <SectionTitle>
                    <Typography variant="overline">Comprador</Typography>
                  </SectionTitle>
                  {payer.map(({ path, organization: { tradeName, cnpj } }) => (
                    <ListItem
                      key={`${tradeName}-payer`}
                      onClick={() => handleClick({ path, tradeName, type: 'buyer', cnpj })}
                      primaryText={tradeName}
                      secondaryIconName="ChevronRight"
                    />
                  ))}
                </>
              )}
              {vendor.length > 0 && (
                <>
                  <SectionTitle>
                    <Typography variant="overline">Fornecedor</Typography>
                  </SectionTitle>
                  {vendor.map(
                    ({
                      path,
                      target: { tradeName: buyerTradeName },
                      organization: { tradeName, cnpj },
                    }) => (
                      <ListItem
                        key={`${tradeName}-${buyerTradeName}`}
                        onClick={() =>
                          handleClick({ path, tradeName, type: 'vendor', buyerTradeName, cnpj })
                        }
                        primaryText={tradeName}
                        secondaryText={`Fornecedor da ${buyerTradeName}`}
                        secondaryIconName="ChevronRight"
                      />
                    ),
                  )}
                </>
              )}
              {funder.length > 0 && (
                <>
                  <SectionTitle>
                    <Typography variant="overline">Investidor</Typography>
                  </SectionTitle>
                  {funder.map(({ path, organization: { tradeName, cnpj } }) => (
                    <ListItem
                      key={`${tradeName}-funder`}
                      onClick={() => handleClick({ path, tradeName, type: 'funder', cnpj })}
                      primaryText={tradeName}
                      secondaryIconName="ChevronRight"
                    />
                  ))}
                </>
              )}
            </CompaniesContainer>
            <ActionsWrapper>
              <Button
                href={logout}
                data-method="delete"
                component="a"
                variant="text"
                size="large"
                fullWidth
              >
                Sair
              </Button>
            </ActionsWrapper>
          </CardContent>
        </Card>
      </Content>
      <LoginFooter />
    </Container>
  );
};

const companyProps = PropTypes.arrayOf(
  PropTypes.shape({
    organization: PropTypes.shape({
      cnpj: PropTypes.string.isRequired,
      tradeName: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    target: PropTypes.shape({
      tradeName: PropTypes.string.isRequired,
    }),
  }),
);

OperatorCompanySelection.propTypes = {
  operator: PropTypes.shape({
    cpf: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    logout: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  organizations: PropTypes.shape({
    count: PropTypes.number.isRequired,
    mainAccessPath: PropTypes.string.isRequired,
    funder: companyProps.isRequired,
    payer: companyProps.isRequired,
    vendor: companyProps.isRequired,
  }).isRequired,
};

OperatorCompanySelection.defaultProps = {};

export default OperatorCompanySelection;
