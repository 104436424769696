import styled from 'styled-components';
import {
  TextField,
  SecondaryButton as LiberSecondaryButton,
  handleThemeFromObject as getTheme,
  PrimaryButton as RawPrimaryButton,
  FlatButton as RawFlatButton,
  LoadingBunny,
} from 'liber-components';
import { TEXT_COLOR } from '../CommonStyles';

export const Container = styled.div`
  width: 100%;

  * {
    font-family: Roboto;
  }
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const SecondaryButton = styled(LiberSecondaryButton)`
  margin-bottom: 15px;
  height: 45px;
`;

export const Input = styled(TextField).attrs(() => ({ version: 2, disabled: true }))`
  margin-bottom: 15px;
`;

export const LabelTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #405f71;
  margin-top: 6px;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${TEXT_COLOR};
  margin-bottom: 24px;
`;

export const PrimaryButton = styled(RawPrimaryButton).attrs(() => ({
  version: 2,
  size: 'large',
}))``;

export const FlatButton = styled(RawFlatButton).attrs(() => ({
  version: 2,
  size: 'large',
}))``;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  background-color: white;
  transition: opacity 150ms ease-in-out;
  z-index: 201;
  position: relative;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexEnd = styled(Flex)`
  align-items: end;
  gap: 20px;
`;

export const ButtonsContainer = styled(Flex)`
  gap: 20px;
  justify-content: end;
`;
