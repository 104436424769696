import React from 'react';
import { Button } from 'liber-components/components/Buttons/Buttons';
import { Edit, InfoRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import * as S from './styles';

export const ActionButtons = ({ onEdit, onViewInfo }) => (
  <S.ButtonsGroup>
    <Button data-testid="btn-action-view-info" version={2} size="large" onClick={onViewInfo}>
      <InfoRounded />
    </Button>
    <Button data-testid="btn-action-edit" version={2} size="large" onClick={onEdit}>
      <Edit />
    </Button>
  </S.ButtonsGroup>
);

ActionButtons.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onViewInfo: PropTypes.func.isRequired,
};

export default ActionButtons;
