export const UPLOAD_ERROR_STATUSES = {
  CNPJS_AND_CODES_ERROR: {
    title: 'Erro com os CNPJs e códigos de grupos de fornecedores da planilha',
    message: 'Ao informar códigos de grupos de fornecedores só é possível o envio de um CNPJ',
  },
  CODES_ERROR: {
    title: 'Erro com os códigos de grupos de fornecedores da planilha',
    message: 'Verifique a existência e a formatação dos códigos de grupos de fornecedores',
  },
  CNPJS_ERROR: {
    title: 'Erro com os CNPJs da planilha',
    message: 'Verifique a existência e a formatação dos CNPJs',
  },
  DATES_ERROR: {
    title: 'Erro com as datas da planilha',
    message: 'Verifique a existência e a formatação das datas',
  },
  RATES_ERROR: {
    title: 'Erro com as taxas da planilha',
    message: 'Verifique a existência e a formatação das taxas',
  },
  RATES_INSERTION_ERROR: {
    title: 'Erro ao inserir as taxas na base de dados',
    message: 'Verifique a existência e a formatação das taxas',
  },
  INVOICE_UPDATE_ERROR: {
    title: 'Erro ao atualizar duplicatas com as novas taxas',
    message: 'Verifique a existência e a formatação das taxas',
  },
  VALIDATION_SUCCESS: {
    title: 'Validação da planilha com sucesso',
    message: 'A planilha foi validada com sucesso',
  },
};
