import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast/components/ToastContainer';
import hydrateStore from '../store';
import AnticipationCheckout from './components/AnticipationCheckout';

const View = ({ store, buyerIdentifier }) => (
  <>
    <Provider store={hydrateStore(store, buyerIdentifier)}>
      <ThemeProvider theme={LiberV4}>
        <AnticipationCheckout buyerIdentifier={buyerIdentifier} />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  store: PropTypes.shape({}).isRequired,
  buyerIdentifier: PropTypes.string.isRequired,
};

export default View;
