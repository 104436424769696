import { connect } from 'react-redux';
import Enderecos from './Enderecos';
import { previousStep as onPrevious } from '../../../actions/progressBar';
import {
  changeAddress,
  changeNumber,
  changeComplement,
  changeCity,
  changeState,
  changeCountry,
  changeDistrict,
  changeZipCodeListener,
  addAddress,
  removeAddress,
  getAddressFromZipCode,
  submitForm,
} from '../../../actions/enderecos';
import { getEnderecos } from '../../../presenters/enderecos';

const mapStateToProps = state => ({ enderecos: getEnderecos(state) });

const mapDispatchToProps = {
  changeAddress,
  changeNumber,
  changeComplement,
  changeCity,
  changeState,
  changeCountry,
  changeDistrict,
  changeZipCodeListener,
  getAddressFromZipCode,
  submitForm,
  addAddress,
  removeAddress,
  onPrevious,
};

export default connect(mapStateToProps, mapDispatchToProps)(Enderecos);
