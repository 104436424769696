import { combineReducers } from 'redux';
import wallet from './walletReducer';
import distribution from './distributionReducer';
import calendar from './calendarReducer';
import invoices from './invoicesReducer';
import yieldReducer from './yieldReducer';
import walletMean from './walletMeanReducer';
import invoicesDialog from '../components/InvoicesDialog/redux';

export default combineReducers({
  wallet,
  distribution,
  calendar,
  invoices,
  yield: yieldReducer,
  walletMean,
  invoicesDialog,
});
