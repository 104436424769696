import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 24px 0px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const ContentBlock = styled.div`
  padding: 0px 30px;

  @media (max-width: 991px) {
    margin-top: 24px;
  }
  @media (max-width: 575px) {
    width: calc(100vw - 24px);
  }
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  padding-bottom: 8px;
`;

export const Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 8px;
  color: #009dff;
`;
