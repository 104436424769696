import styled, { css } from 'styled-components';
import { LoadingBunny as Bunny, Card as BaseCard, Link } from 'liber-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoadingBunny = styled(Bunny)`
  width: 161px;
  height: 128px;
`;

export const ProgressContainer = styled.div`
  width: 480px;
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ProgressText = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #009dff;
  margin-bottom: 42px;
`;

export const Card = styled(BaseCard)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #6287a7;
  width: 640px;
  padding: 16px 48px;
  margin: 0px;
  border: solid 1px #cdd7df;
`;

export const Row = styled.div`
  width: 544px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
`;

export const Cell = styled.div`
  ${({ center }) =>
    center
      ? css`
          text-align: center;
        `
      : css`
          text-align: right;
        `}

  ${({ width }) =>
    css`
      width: ${width};
    `}

  ${({ strong }) =>
    strong &&
    css`
      font-weight: 500;
      color: #405f71;
    `}

  & svg {
    width: 36px;
    height: 36px;
    fill: #6287a7;
    cursor: pointer;
    padding: 8px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: solid 1px #ecf0f3;
  margin: 16px 0px;
`;

export const SecondaryButton = styled(Link).attrs(() => ({ version: '2', size: 'large' }))`
  margin: 8px;
  margin-bottom: 0px;
`;
