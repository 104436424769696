import { errorMsg } from '../messages';

export function changeToken(value) {
  return {
    type: 'RESET_CHANGE_TOKEN',
    payload: value,
  };
}

export function changePassword(value) {
  return {
    type: 'RESET_CHANGE_PASSWORD',
    payload: value,
  };
}

export function changeConfirmation(value) {
  return {
    type: 'RESET_CHANGE_CONFIRMATION_PASSWORD',
    payload: value,
  };
}

export const submitToken = (token, passwordPath) => {
  if (token.length === 0) {
    return {
      type: 'RESET_FAILED_SUBMIT',
      payload: {
        tokenError: errorMsg.empty,
      },
    };
  }

  window.location.assign(`${passwordPath}/edit?reset_password_token=${token}`);

  return {
    type: 'RESET_SUCESSFULL_SUBMIT',
  };
};

export function submitForm(event, password, confirmation) {
  event.preventDefault();
  if (password === confirmation && password.length >= 6) {
    event.target.submit();
    return {
      type: 'RESET_SUCESSFULL_SUBMIT',
    };
  }

  if (password.length < 6) {
    return {
      type: 'RESET_FAILED_SUBMIT',
      payload: {
        passwordError: errorMsg.shortPassword,
      },
    };
  }

  return {
    type: 'RESET_FAILED_SUBMIT',
    payload: {
      passwordError: errorMsg.unconfirmedPassword,
    },
  };
}
