import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'liber-salti';
import SaltiThemeWrapper from '../../common-components/SaltiThemeWrapper';

import * as S from './styles';
import { FunderTradeSettlementProvider } from './context';
import TradeSettlementsTable from './components/Table';
import Summary from './components/Summary';
import Filters from './components/Filters';

const View = ({ token }) => (
  <SaltiThemeWrapper>
    <FunderTradeSettlementProvider token={token}>
      <Grid container direction="column">
        <Grid container item direction="column">
          <Typography color="textPrimary" variant="h1">
            Conciliação.
          </Typography>
          <Typography color="textSecondary" variant="body2">
            (Títulos vendidos)
          </Typography>
        </Grid>
        <Filters />
        <S.StyledCollapsibleSection title="Resumo das negociações de acordo com filtros aplicados">
          <Summary />
        </S.StyledCollapsibleSection>
        <TradeSettlementsTable />
      </Grid>
    </FunderTradeSettlementProvider>
  </SaltiThemeWrapper>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
};

export default View;
