export const IFILTER_TYPES = {
  GROUP_NAME: 'q[group_name_cont]',
  BUYER_NAME: 'q[organizations_sacado_trade_name_cont]',
};

export const IBUYER_ORGANIZATION_KIND_TYPES = {
  small: 'Pequeno',
  medium: 'Médio',
  large: 'Grande',
  extra_large: 'Extra Grande',
  other: 'Outro',
};
