import React from 'react';
import PropTypes from 'prop-types';
import { EditBuyerOrganizationGroup } from '../../features/buyer-organization-groups';

const EditBuyerOrganizationGroupView = ({ token, id }) => (
  <EditBuyerOrganizationGroup token={token} editGroupId={id} />
);

EditBuyerOrganizationGroupView.propTypes = {
  token: PropTypes.string.isRequired,
  id: PropTypes.string,
};

EditBuyerOrganizationGroupView.defaultProps = {
  id: undefined,
};

export default EditBuyerOrganizationGroupView;
