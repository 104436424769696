import styled, { css } from 'styled-components';

import { FaCaretDown, SimpleDropDown } from 'liber-components';

export const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.01px;
  text-align: left;
  color: #4b6f85;
  margin-bottom: 8px;
`;

export const BankName = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #4b6f85;
`;

export const BankInfo = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.01px;
  text-align: left;
  color: #798e9b;
`;

export const BankDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled(FaCaretDown)`
  fill: #4b6f85;
  width: 20px;
  height: 20px;
`;

export const DropDownValue = styled.div`
  border-radius: 2px;
  border: solid 1px #4b6f85;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const Dropdown = styled(SimpleDropDown)`
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const DropDownContainer = styled.div`
  position: relative;
  & div {
    min-height: unset;
  }
`;

const OPTION_HEIGHT = 76;

export const Options = styled.div`
  max-height: ${2.5 * OPTION_HEIGHT}px;
  overflow: auto;
`;

export const Option = styled.div`
  padding: 16px;
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid #f3f3f3;
  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: #f3f3f3;
  }

  ${({ selected }) =>
    selected
      ? css`
          ${BankInfo} {
            color: #4b6f85;
            font-weight: 500;
          }
        `
      : css`
          ${BankName} {
            color: #798e9b;
          }
        `}
`;
