import React from 'react';
import PropTypes from 'prop-types';
import { SelectionTable, SelectionTableBody } from 'liber-components';
import InvoiceSimulationTableRow from './InvoiceSimulationTableRow';
import { tabType, INVOICE_TABS } from '../../utils';

const { AVAILABLE } = INVOICE_TABS;

const InvoiceSimulationTable = ({
  activeTab,
  invoices,
  rateByPeriod,
  onSelectInvoice,
  selectStatus,
}) => {
  const checkBoxColor = selectStatus === 'multiple' ? '#fabe00' : null;
  return (
    <SelectionTable>
      <SelectionTableBody>
        {invoices.map(invoice => (
          <InvoiceSimulationTableRow
            key={invoice.id.toString()}
            activeTab={activeTab}
            checkBoxColor={checkBoxColor}
            onSelectInvoice={onSelectInvoice}
            invoice={invoice}
            rateByPeriod={rateByPeriod}
          />
        ))}
      </SelectionTableBody>
    </SelectionTable>
  );
};

InvoiceSimulationTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  selectStatus: PropTypes.string,
  onSelectInvoice: PropTypes.func.isRequired,
  activeTab: tabType,
  rateByPeriod: PropTypes.bool,
};

InvoiceSimulationTable.defaultProps = {
  invoices: [],
  selectStatus: 'empty',
  activeTab: AVAILABLE,
  rateByPeriod: false,
};

export default InvoiceSimulationTable;
