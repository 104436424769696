import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'liber-components/components/Inputs/Inputs';
import { Checkbox } from 'liber-components';
import * as S from './styles';
import { useCreateOrganizationGroup } from '../../context';
import { useGetCategories } from '../../hooks/useGetCategories';
import Loading from '../../../../../../common-components/Loading';
import { KIND_OPTION_TYPES, NOT_DEFINED_OPTION_VALUE } from '../../interfaces';
import FormFooter from '../FormFooter';

const BuyerOrganizationGroupForm = ({ token }) => {
  const {
    formData,
    onChangeForm,
    formErrors,
    isLoading: isLoadingSubmit,
  } = useCreateOrganizationGroup();
  const { categories, isLoading: isLoadingCategories } = useGetCategories(token);

  const hasError = field => formErrors.some(error => error === field);

  return (
    <Loading isLoading={isLoadingCategories || isLoadingSubmit}>
      <S.Container>
        <S.FormTitle>Dados do Grupo econômico</S.FormTitle>
        <S.FormGroup>
          <S.FormRow>
            <S.FormCol>
              <S.FormInput
                value={formData.groupName}
                data-testid="input-group-name"
                onChange={value => onChangeForm('groupName', value)}
                label="Nome do grupo"
                errorMessage={hasError('groupName') ? 'Campo obrigatório' : ''}
              />
            </S.FormCol>
          </S.FormRow>
          <S.FormRow>
            <S.FormCol>
              <SelectField
                version="2"
                hintText="Porte"
                onChange={value => onChangeForm('kind', value)}
                value={formData.kind}
                errorMessage={hasError('kind') ? 'Campo obrigatório' : ''}
              >
                <option value={NOT_DEFINED_OPTION_VALUE}>Não definido</option>
                {KIND_OPTION_TYPES.map(kind => (
                  <option key={kind.value} value={kind.value}>
                    {kind.label}
                  </option>
                ))}
              </SelectField>
            </S.FormCol>
            <S.FormCol>
              <SelectField
                version="2"
                hintText="Segmento"
                onChange={value => onChangeForm('category', value)}
                value={formData.category}
                errorMessage={hasError('category') ? 'Campo obrigatório' : ''}
              >
                <option value={NOT_DEFINED_OPTION_VALUE}>Não definido</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </SelectField>
            </S.FormCol>
          </S.FormRow>
          <S.FormRow>
            <S.FormCol>
              <S.FormInput
                value={formData.traderName}
                data-testid="input-trader-name"
                onChange={value => onChangeForm('traderName', value)}
                label="Trader responsável"
                version="2"
                errorMessage={hasError('traderName') ? 'Campo obrigatório' : ''}
              />
            </S.FormCol>
            <S.FormCol>
              <S.FormInput
                data-testid="input-trader-email"
                value={formData.traderEmail}
                onChange={value => onChangeForm('traderEmail', value)}
                label="Email"
                version="2"
                errorMessage={hasError('traderEmail') ? 'Campo obrigatório' : ''}
              />
            </S.FormCol>
          </S.FormRow>
          <S.FormRow>
            <S.FormCol>
              <S.FormInput
                data-testid="input-commercial-name"
                value={formData.commercialName}
                onChange={value => onChangeForm('commercialName', value)}
                label="Comercial"
                version="2"
                errorMessage={hasError('commercialName') ? 'Campo obrigatório' : ''}
              />
            </S.FormCol>
            <S.FormCol>
              <S.FormInput
                data-testid="input-commercial-email"
                value={formData.commercialEmail}
                onChange={value => onChangeForm('commercialEmail', value)}
                label="Email"
                version="2"
                errorMessage={hasError('commercialEmail') ? 'Campo obrigatório' : ''}
              />
            </S.FormCol>
          </S.FormRow>
          <S.FormRow>
            <S.FormCol>
              <Checkbox
                checked={formData.allowVendorReplication}
                onChange={event => onChangeForm('allowVendorReplication', event.target.checked)}
              >
                <S.CheckboxLabel>
                  Replicar o cadastro dos fornecedores com todos os sacados do grupo?
                </S.CheckboxLabel>
              </Checkbox>
            </S.FormCol>
          </S.FormRow>
        </S.FormGroup>
        <FormFooter />
      </S.Container>
    </Loading>
  );
};

BuyerOrganizationGroupForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default BuyerOrganizationGroupForm;
