import React, { useContext, useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import {
  FullWidth,
  GraphHeader,
  GraphTitle,
  GraphSubtitle,
  UpdatedText,
  LegendText,
  LegendCircle,
  LegendContainer,
  LegendItem,
  CardFullWidth,
  GraphHeaderContainer,
  Loading,
  Bunny,
} from './styles';
import { fetchRates } from './api';
import { handleError } from '../../vendor/Utils';
import { TokenContext } from '../../hocs/withTokenProvider';
import { graphColors, graphOptions } from './constants';
import Filters from './components/Filters';

function parseGraphData(ratesData) {
  const datasets = [];

  ratesData.datasets.forEach(({ name, rates }, i) => {
    datasets.push({
      lineTension: 0.3,
      label: name,
      data: rates,
      fill: false,
      backgroundColor: graphColors[i],
      borderColor: graphColors[i],
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
    });
  });

  return {
    labels: ratesData.labels,
    datasets,
  };
}

const RatesGraph = ({ funderIds, vendorsGroupId, emptyState, noShadow, hasFilters }) => {
  const [loading, setLoading] = useState(false);
  const [rates, setRates] = useState({ datasets: [] });

  const { token } = useContext(TokenContext);
  const getRates = async (filters = {}) => {
    setLoading(true);
    try {
      const { data } = await fetchRates({ token, funderIds, vendorsGroupId, ...filters });
      setRates(data);
    } catch (err) {
      setRates({ datasets: [] });
      if (err?.response?.status !== 404) {
        handleError('Erro ao carregar taxas para o(s) investidor(es)');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRates();
  }, [funderIds]);

  const onChangeFilters = filters => {
    const filtersParsed = {};
    Object.keys(filters).forEach(key => {
      if (filters[key]) filtersParsed[key] = filters[key];
    });
    getRates(filtersParsed);
  };

  const shouldShowEmptyState = () => emptyState && !rates.datasets.length && !loading;

  return shouldShowEmptyState() ? (
    emptyState
  ) : (
    <FullWidth>
      <CardFullWidth noShadow={noShadow}>
        <Loading loading={loading ? 1 : 0}>
          {loading && <Bunny />}
          <GraphHeaderContainer>
            <GraphHeader>
              <GraphTitle>Curva de Taxas Ativa por Prazo</GraphTitle>
            </GraphHeader>
            <GraphSubtitle>
              {rates.financing_date && (
                <UpdatedText>
                  Curva válida para{' '}
                  {moment(rates.financing_date).tz('America/Sao_Paulo').format('DD/MM/YYYY')}
                </UpdatedText>
              )}
              <LegendContainer>
                {rates.datasets.map(({ name }, index) => (
                  <LegendItem key={name}>
                    <LegendCircle color={graphColors[index]} />
                    <LegendText>{name}</LegendText>
                  </LegendItem>
                ))}
              </LegendContainer>
            </GraphSubtitle>
            {hasFilters && <Filters handleFilter={onChangeFilters} />}
          </GraphHeaderContainer>
          {!rates.datasets.length && !loading ? (
            <div>Nenhuma informação do gráfico encontrada</div>
          ) : (
            <Line options={graphOptions} height={70} data={parseGraphData(rates)} />
          )}
        </Loading>
      </CardFullWidth>
    </FullWidth>
  );
};

RatesGraph.propTypes = {
  funderIds: PropTypes.arrayOf(PropTypes.number),
  vendorsGroupId: PropTypes.number,
  emptyState: PropTypes.node,
  noShadow: PropTypes.bool,
  hasFilters: PropTypes.bool,
};

RatesGraph.defaultProps = {
  funderIds: [],
  vendorsGroupId: undefined,
  emptyState: '',
  noShadow: false,
  hasFilters: false,
};

export default memo(RatesGraph, (prevProps, nextProps) => {
  const prevFunderIds = prevProps.funderIds.sort();
  const nextFunderIds = nextProps.funderIds.sort();

  const funderIdsAndVendorsGroupIdAreEqual =
    prevFunderIds.length === nextFunderIds.length &&
    !nextFunderIds.some((id, index) => prevFunderIds[index] !== id) &&
    prevProps.vendorsGroupId === nextProps.vendorsGroupId;

  return funderIdsAndVendorsGroupIdAreEqual; // re-renders only if funderIds or vendorsGroupId changes
});
