import React, { useContext } from 'react';
import TextFieldFilter from '../../../../common-components/Filters/TextFieldFilter/TextFieldFilter';
import { FilterText, ClearFilters } from './styles';
import { FunderVendorsGroupsContext } from '../../context';
import {
  VENDORS_GROUPS_BUYER_NAME_FILTER,
  VENDORS_GROUPS_NAME_FILTER,
  VENDORS_GROUPS_REFERENCE_CODE_FILTER,
} from '../../constants';

const hasFilters = filters =>
  Object.keys(filters).reduce((flag, key) => flag || Boolean(filters[key]), false);

const Filters = () => {
  const { filters, handleChangeFilters, handleClearAllFilters } = useContext(
    FunderVendorsGroupsContext,
  );

  return (
    <>
      <FilterText>Filtrar por</FilterText>
      <TextFieldFilter
        label="Nome do Grupo"
        selectedItems={filters[VENDORS_GROUPS_NAME_FILTER]}
        onFilter={input => handleChangeFilters(VENDORS_GROUPS_NAME_FILTER, input)}
        onClear={() => handleChangeFilters(VENDORS_GROUPS_NAME_FILTER, null)}
      />
      <TextFieldFilter
        label="Sacado"
        selectedItems={filters[VENDORS_GROUPS_BUYER_NAME_FILTER]}
        onFilter={input => handleChangeFilters(VENDORS_GROUPS_BUYER_NAME_FILTER, input)}
        onClear={() => handleChangeFilters(VENDORS_GROUPS_BUYER_NAME_FILTER, null)}
      />
      <TextFieldFilter
        label="Código de Referência"
        selectedItems={filters[VENDORS_GROUPS_REFERENCE_CODE_FILTER]}
        onFilter={input => handleChangeFilters(VENDORS_GROUPS_REFERENCE_CODE_FILTER, input)}
        onClear={() => handleChangeFilters(VENDORS_GROUPS_REFERENCE_CODE_FILTER, null)}
      />
      {hasFilters(filters) && (
        <ClearFilters onClick={() => handleClearAllFilters()}>Limpar filtros</ClearFilters>
      )}
    </>
  );
};

export default Filters;
