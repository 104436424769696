import React from 'react';
import PropTypes from 'prop-types';
import { WhatToDo } from 'liber-components';
import { Link, Mark } from 'liber-components/components/GenericStyles';
import { EnumeratedList } from '../../../styles';

const selector = type => {
  switch (type) {
    case 'server-gen-timeout':
    case 'server-sign-timeout':
    case 'server-gen':
      return (
        <WhatToDo>
          <p>
            Estamos fazendo o possível para resolver este problema. Por favor,{' '}
            <b>Tente novamente mais tarde.</b>
          </p>
          <p>
            Se o erro persistir, entre em contato com nosso suporte pelo email
            <Link href="mailto:contato@libercapital.com.br"> contato@libercapital.com.br </Link>
            ou pelo telefone <Mark>(11) 3534-7808</Mark>
          </p>
        </WhatToDo>
      );
    case 'server-gen-soft':
      return (
        <WhatToDo>
          <p>
            Clique em &ldquo;Assinar Documentos&rdquo; para assinar os documentos preparados com
            sucesso.
          </p>
          <p>
            Se o erro persistir, entre em contato com nosso suporte pelo email
            <Link href="mailto:contato@libercapital.com.br"> contato@libercapital.com.br </Link>
            ou pelo telefone <Mark>(11) 3534-7808</Mark>
          </p>
        </WhatToDo>
      );
    case 'native-app':
      return (
        <WhatToDo>
          <EnumeratedList>
            <li>
              Certifique-se de que seu cartão e-CPF está bem inserido no leitor. Se necessário,
              retire-o do leitor e insira novamente.
            </li>
            <li>
              Reinicie o processo selecionando seu e-CPF, em seguida clique em “Assinar Documentos”
            </li>
            <li>
              Se o erro persistir, entre em contato com nosso suporte pelo email
              <Link href="mailto:contato@libercapital.com.br"> contato@libercapital.com.br </Link>
              ou pelo telefone <Mark>(11) 3534-7808</Mark>
            </li>
          </EnumeratedList>
        </WhatToDo>
      );
    case 'server-sign':
    case 'server-sign-soft':
    case 'sdk':
    case 'extension':
    case 'api':
    case 'unknown':
    default:
      return (
        <WhatToDo>
          <p>
            Estamos fazendo o possível para resolver este problema. Por favor,{' '}
            <b>tente novamente mais tarde.</b>
          </p>
          <p>
            Se o erro persistir, entre em contato com nosso suporte pelo email
            <Link href="mailto:contato@libercapital.com.br"> contato@libercapital.com.br </Link>
            ou pelo telefone <Mark>(11) 3534-7808</Mark>
          </p>
        </WhatToDo>
      );
  }
};

const WhatToDoError = ({ type }) => selector(type);

WhatToDoError.propTypes = {
  type: PropTypes.string.isRequired,
};

export default WhatToDoError;
