import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import { Container, Header, Content, ActionsRow, Button } from './styles';
import { formatMoney } from '../../views/withdraw/moneyHandler';

function UnavailableLimitDialog({ surplusValue, onLeaved, show, redirectUrl }) {
  const handleOk = redirectUrl ? () => window.location.assign(redirectUrl) : onLeaved;

  return (
    <Modal onLeaved={onLeaved} show={show}>
      <Container>
        <Header>Limite Indisponível</Header>
        <Content>
          {surplusValue ? (
            <>
              A antecipação não poderá ser realizada, pois o valor líquido excedeu o limite de
              antecipações definido pelo sacado.
              <br />
              <br />O excesso tem o valor de <strong>{formatMoney(surplusValue, true)}</strong>.
              Para solicitar sua antecipação escolha um conjunto de títulos de menor valor.
            </>
          ) : (
            <>
              A antecipação não poderá ser realizada, pois o valor líquido excedeu o limite de
              antecipações definido pelo sacado.
            </>
          )}
        </Content>
        <ActionsRow>
          <Button onClick={handleOk}>OK</Button>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

UnavailableLimitDialog.propTypes = {
  surplusValue: PropTypes.number,
  onLeaved: PropTypes.func,
  show: PropTypes.bool,
  redirectUrl: PropTypes.string,
};

UnavailableLimitDialog.defaultProps = {
  surplusValue: 0,
  onLeaved: () => {},
  show: false,
  redirectUrl: null,
};

export default UnavailableLimitDialog;
