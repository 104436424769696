import React from 'react';
import PropTypes from 'prop-types';
import { Menu as RawMenu, MenuItem } from 'liber-salti';

const Menu = ({ anchorEl, open, setOpen, subPaths, pathname, navigate }) => (
  <RawMenu position="right" anchorEl={anchorEl} open={open} onClose={() => setOpen(false)}>
    {subPaths.map(({ path, label, routePatterns }) => (
      <MenuItem
        key={path}
        selected={routePatterns.some(pattern => pattern.test(pathname))}
        onClick={() => navigate(path, label)}
      >
        {label}
      </MenuItem>
    ))}
  </RawMenu>
);

Menu.propTypes = {
  anchorEl: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  subPaths: PropTypes.arrayOf(PropTypes.any).isRequired,
  pathname: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

Menu.defaultProps = { anchorEl: undefined };

export default Menu;
