import React, { useContext } from 'react';
import { TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { handleNavigate } from '../../../../vendor/Utils';
import ScreenTitle from '../../../../common-components/ScreenTitle';
import {
  Container,
  Row,
  IconButton,
  HistoryContainer,
  EmptyStateContainer,
  TimelineContainer,
  Timeline,
  List,
  LoadingContainer,
  Bunny,
  Button,
} from './styles';
import { VENDORS_GROUPS_PATH, VENDORS_GROUP_DETAILS_PATH } from '../../constants';
import { VendorsGroupHistoryContext } from '../../context';
import HistoryItem from '../HistoryItem';

function HistoryEntries() {
  const { history } = useContext(VendorsGroupHistoryContext);

  return history?.length ? (
    <HistoryContainer>
      <TimelineContainer>
        <Timeline />
      </TimelineContainer>
      <List>
        {history.map(({ id, ...historyEntry }) => (
          <HistoryItem key={id} {...historyEntry} />
        ))}
      </List>
    </HistoryContainer>
  ) : (
    <EmptyStateContainer>Ainda não há alterações no grupo.</EmptyStateContainer>
  );
}

export default function VendorsGroups() {
  const { name, loading, groupId } = useContext(VendorsGroupHistoryContext);

  return (
    <>
      <Container>
        <Row>
          <TooltipBox mount="top" fixed content="Voltar">
            <IconButton onClick={() => handleNavigate(VENDORS_GROUPS_PATH)}>
              <Icon path={mdiArrowLeft} />
            </IconButton>
          </TooltipBox>
          <ScreenTitle>{`Histórico ${
            name.length > 80 ? `${name.substr(0, 80)}...` : name
          }`}</ScreenTitle>
          <Button
            onClick={() => handleNavigate(VENDORS_GROUP_DETAILS_PATH.replace(':id', groupId))}
          >
            VER DETALHES
          </Button>
        </Row>
        {loading ? (
          <LoadingContainer>
            <Bunny />
          </LoadingContainer>
        ) : (
          <HistoryEntries />
        )}
      </Container>
    </>
  );
}
