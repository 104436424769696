import ActionCable from 'actioncable';

const DEFAULT_CONNECTION_TIMEOUT = 5000;

export function createSubscription(channel, roomId, options = {}) {
  const {
    received = () => {},
    connected = () => {},
    connectionFailed = () => {},
    connectionTimeout = DEFAULT_CONNECTION_TIMEOUT,
  } = options;

  // eslint-disable-next-line no-undef
  const cable = ActionCable.createConsumer(WEBSOCKET_URL);

  const timeout = setTimeout(() => {
    connectionFailed();
  }, connectionTimeout);

  return cable.subscriptions.create(
    {
      channel,
      room_id: roomId,
    },
    {
      received,
      connected: () => {
        clearTimeout(timeout);
        connected();
      },
    },
  );
}
