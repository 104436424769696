import styled from 'styled-components';
import { FaCheck, FaTimes, handleThemeFromObject } from 'liber-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconSuccess = styled(FaCheck)`
  display: block;
  width: 42px;
  height: 42px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.successGreen', '#13ce66')};
`;

export const IconFailure = styled(FaTimes)`
  display: block;
  width: 42px;
  height: 42px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.dangerRed', '#dc3545')};
`;
