import React from 'react';
import PropTypes from 'prop-types';
import { IconAdd, LinkButton } from './styles';

const Add = ({ label, ...props }) => (
  <LinkButton {...props}>
    <IconAdd />
    {label}
  </LinkButton>
);

Add.propTypes = {
  label: PropTypes.string,
};

Add.defaultProps = {
  label: 'Voltar',
};

export default Add;
