import { validateCpf, validateCnpj } from '../../../vendor/validation';
import { errorMsg } from '../messages';

export function changeDocumento(value) {
  if (value.length === 0) {
    return {
      type: 'INVESTIDOR_CHANGE_DOCUMENTO_ERROR',
      payload: {
        documento: value,
        error: errorMsg.empty,
      },
    };
  }

  if (validateCpf(value)) {
    return {
      type: 'INVESTIDOR_CHANGE_DOCUMENTO_AS_CPF',
      payload: value,
    };
  }

  if (validateCnpj(value)) {
    return {
      type: 'INVESTIDOR_CHANGE_DOCUMENTO_AS_CNPJ',
      payload: value,
    };
  }

  return {
    type: 'INVESTIDOR_CHANGE_DOCUMENTO_ERROR',
    payload: {
      documento: value,
      error: errorMsg.invalidFormat,
    },
  };
}

export function changeDocumentoType(value) {
  return {
    type: 'INVESTIDOR_CHANGE_DOCUMENTO_TYPE',
    payload: value,
  };
}

export function changePassword(value) {
  if (value.length === 0) {
    return {
      type: 'INVESTIDOR_CHANGE_PASSWORD_ERROR',
      payload: {
        password: value,
        error: errorMsg.empty,
      },
    };
  }
  return {
    type: 'INVESTIDOR_CHANGE_PASSWORD',
    payload: value,
  };
}

export function toogleRemember() {
  return {
    type: 'INVESTIDOR_TOOGLE_REMEMBER',
  };
}

export const setFingerprint = data => ({
  type: 'INVESTIDOR_SET_FINGERPRINT',
  payload: data,
});

export const submitLogin = (target, documento, password) => dispatch => {
  if (
    documento.length !== 0 &&
    (validateCnpj(documento) || validateCpf(documento)) &&
    password.length !== 0
  ) {
    target.submit();
    return dispatch({ type: 'INVESTIDOR_SUCESSFULL_LOGIN' });
  }
  let documentoError = documento.length === 0 ? errorMsg.empty : '';
  documentoError =
    validateCnpj(documento) || validateCpf(documento) || documento.length === 0
      ? documentoError
      : errorMsg.invalidFormat;
  return dispatch({
    type: 'INVESTIDOR_FAILED_LOGIN',
    payload: {
      documentoError,
      passwordError: password.length === 0 ? errorMsg.empty : '',
    },
  });
};
