export const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const FETCH_RATES_URL = `${apiUrl}/api/v1/buyer/buyer_funder_agreements/grouped_rates_by_funders`;
export const FETCH_VENDOR_GROUPS_URL = `${apiUrl}/api/v1/buyer/vendors_groups`;

export const graphColors = [
  '#00EAC2',
  '#A06BF6',
  '#B7B7B7',
  '#D53F50',
  '#00C2FF',
  '#002654',
  '#5E9627',
  '#F8B5B3',
  '#006AEB',
  '#FFA11D',
];

export const graphOptions = {
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          padding: 20,
          labelString: 'Prazo',
          fontStyle: 'bold',
          fontColor: '#506286',
          fontFamily: 'Sora',
        },
        ticks: {
          padding: 10,
          suggestedMax: 150,
          maxTicksLimit: 3,
          stepSize: 50,
          maxRotation: 0,
          fontStyle: 'bold',
          fontColor: '#506286',
          fontFamily: 'Sora',
          callback: value => `${value.split(' ')[0]}`,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          padding: 15,
          labelString: 'Taxa',
          fontStyle: 'bold',
          fontColor: '#506286',
          fontFamily: 'Sora',
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 2,
          padding: 10,
          stepSize: 0.5,
          fontStyle: 'bold',
          fontColor: '#506286',
          fontFamily: 'Sora',
          callback: value => `${value}%`,
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: '#ffffff',
    mode: 'index',
    titleFontColor: '#000000',
    bodyFontColor: '#000000',
    displayColors: false,
    borderColor: '#CCC',
    borderWidth: 1,
    position: 'nearest',
  },
  legend: {
    display: false,
  },
  elements: {
    point: { radius: 3 },
  },
  normalized: true,
};

export const FILTER_TYPES = {
  VENDOR_GROUP_FILTER: 'vendorsGroupId',
  FINANCING_DATE_FILTER: 'financingDate',
};
