import RawIcon from '@mdi/react';
import styled from 'styled-components';
import { Card as CardRaw, PrimaryButton } from 'liber-components';

export const Card = styled(CardRaw)`
  width: 100%;
`;

export const Content = styled.div`
  padding: 26px;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    margin-bottom: 0;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
`;

export const Input = styled.input.attrs(() => ({
  accept: '.xls,.xlsx',
  type: 'file',
}))`
  display: none;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2 }))``;

export const Icon = styled(RawIcon).attrs(() => ({ size: '14px' }))``;
