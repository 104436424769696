import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { mdiPackageDown, mdiHistory, mdiEye, mdiPencil } from '@mdi/js';
import { TooltipBox } from 'liber-components';
import { Icon } from '@mdi/react';

import { VendorsGroupsContext } from '../context';
import IncrementChangeModal from './IncrementChangeModal';
import { ActionIconButton as IconButton } from './CommonStyles';
import { handleNavigate, dispatchAmplitudeEvent } from '../../../vendor/Utils';
import { VENDORS_GROUP_DETAILS_PATH, VENDORS_GROUP_HISTORY_PATH } from '../constants';

const ActionsCell = ({
  group: { active, default: isDefault, name, id, referenceCode, rebateIncrement },
  allowRebateIncrement,
}) => {
  const { handleArchive, updateOperationIncrement } = useContext(VendorsGroupsContext);
  const [showIncrementModal, setShowIncrementModal] = useState(false);

  const dispatchEvent = event => {
    dispatchAmplitudeEvent(event, { id, name, referenceCode });
  };

  const onDetails = () => {
    dispatchEvent('buyer_vendors_groups_details');
    handleNavigate(VENDORS_GROUP_DETAILS_PATH.replace(':id', id));
  };

  const onHistory = () => {
    dispatchEvent('buyer_vendors_groups_history');
    handleNavigate(VENDORS_GROUP_HISTORY_PATH.replace(':id', id));
  };

  const onArchive = () => {
    dispatchEvent('buyer_vendors_groups_archive');
    handleArchive({ name, id });
  };

  const onUpdateIncrement = incrementNumber => {
    updateOperationIncrement(id, incrementNumber);
  };

  return (
    <>
      <TooltipBox mount="top" fixed content="Ver detalhes">
        <IconButton onClick={() => onDetails()}>
          <Icon path={mdiEye} />
        </IconButton>
      </TooltipBox>
      <TooltipBox mount="top" fixed content="Ver histórico de edições">
        <IconButton onClick={() => onHistory()}>
          <Icon path={mdiHistory} />
        </IconButton>
      </TooltipBox>

      {allowRebateIncrement && (
        <IconButton onClick={() => setShowIncrementModal(true)}>
          <Icon path={mdiPencil} />
        </IconButton>
      )}

      {active && !isDefault && (
        <TooltipBox mount="top" fixed content="Arquivar grupo">
          <IconButton onClick={() => onArchive()}>
            <Icon path={mdiPackageDown} />
          </IconButton>
        </TooltipBox>
      )}

      <IncrementChangeModal
        isOpen={showIncrementModal}
        onCloseModal={() => setShowIncrementModal(false)}
        onSave={incrementNumber => onUpdateIncrement(incrementNumber)}
        rebateIncrement={rebateIncrement}
      />
    </>
  );
};

ActionsCell.propTypes = {
  allowRebateIncrement: PropTypes.bool,
  group: {
    active: PropTypes.bool,
    default: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.number,
    referenceCode: PropTypes.string,
  },
};

ActionsCell.defaultProps = {
  allowRebateIncrement: false,
  group: {
    active: false,
    default: false,
    name: '',
    id: null,
    referenceCode: '',
  },
};

export default ActionsCell;
