import styled, { css } from 'styled-components';

const expirationTextModifiers = {
  danger: theme => css`
    color: ${theme.palette.error.main};
  `,
  warning: theme => css`
    color: ${theme.palette.error.dark};
  `,
  primary: theme => css`
    color: ${theme.palette.text.primary};
  `,
};

export const ExpirationText = styled.span`
  ${({ theme, type }) => css`
    ${expirationTextModifiers[type](theme)}
  `}
`;
