import Immutable from 'seamless-immutable';
import moment from 'moment';
import { uniq, differenceBy } from 'lodash';
import {
  ADMIN_SET_LOADING,
  UPDATE_ADMIN_NEGOTIATIONS,
  UPDATE_INVESTOR_ACCOUNT,
  ADMIN_SET_FILTER,
  ADMIN_CHANGE_PAGE,
  ADMIN_SET_LISTING,
  ADMIN_TOGGLE_PAGE_SELECTION,
  ADMIN_TOGGLE_ITEM_SELECTION,
  ADMIN_CLEAR_SELECTION,
  ADMIN_UPDATE_SELECTION,
  ADMIN_UPDATE_IS_ALL_SELECTED,
  ADMIN_CHANGE_TAB,
  ADMIN_REPORT_RECEIVED,
  ADMIN_REPORT_LOADING,
  ADMIN_TRADES_SET_SORTING,
} from '../actions/AdminNegotiationsActions';
import { MAP_TAB_TO_STATUS } from '../utils';
import { handleSuccess, handleError, convertToCamelCase } from '../../../vendor/Utils';
import { SORT_KEY } from '../../../hooks/useSorting';

const ALL_TAB = MAP_TAB_TO_STATUS.length - 1;
export const DEFAULT_SORTING = {};

export const initialState = Immutable({
  token: null,
  activeTab: ALL_TAB,
  negotiations: [],
  loading: false,
  filters: {},
  sorting: {
    [SORT_KEY]: `${DEFAULT_SORTING.columnName} ${DEFAULT_SORTING.direction}`,
  },
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 25,
    pages: 1,
    count: 0,
  },
  selectedItems: [],
  isPageSelected: false,
  hasSelectedAllItems: false,
  downloadReportLoading: false,
  downloadReportId: '',
  investorAccounts: [],
  selectedFunderIds: [],
  disabledButtonFile: true,
  datesRangeLimit: 0,
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_SET_LOADING:
      return state.merge({ loading: payload });
    case UPDATE_INVESTOR_ACCOUNT: {
      const { data } = payload;
      return state.merge({ investorAccounts: data });
    }

    case UPDATE_ADMIN_NEGOTIATIONS: {
      const { negotiations, pagination } = payload;
      const { selectedItems, hasSelectedAllItems } = state;

      const negotiationsIds = negotiations.map(({ id }) => id);
      const hasItemsOnPageSelected = negotiationsIds.every(id => selectedItems.includes(id));

      const isPageSelected = hasSelectedAllItems || hasItemsOnPageSelected;

      let updateSelected = null;
      if (hasSelectedAllItems) {
        updateSelected = uniq(selectedItems.concat(negotiationsIds));
      }

      return state.merge({
        negotiations,
        pagination,
        isPageSelected,
        selectedItems: updateSelected ?? selectedItems,
        loading: false,
      });
    }

    case ADMIN_SET_FILTER: {
      const { filters } = state;
      const containsDate = Object.values(filters).some(filterValue =>
        moment(filterValue, 'DD/MM/YYYY').isValid(),
      );
      return state.merge({
        filters: payload,
        disabledButtonFile: !containsDate,
      });
    }

    case ADMIN_CHANGE_PAGE: {
      const {
        payload: { page },
      } = action;
      const { pagination } = state;

      return state.merge({ pagination: { ...pagination, current: page } });
    }

    case ADMIN_SET_LISTING: {
      const {
        payload: { per },
      } = action;
      const { pagination } = state;

      return state.merge({
        pagination: { ...pagination, current: 1, per },
      });
    }

    case ADMIN_TOGGLE_PAGE_SELECTION: {
      const { isPageSelected, negotiations, selectedItems } = state;

      let updateSelected = [];
      let funderIds = [];
      const invoicesIds = negotiations.map(({ id }) => id);
      invoicesIds
        .map(invoiceId => negotiations.filter(({ id }) => invoiceId === id))
        .forEach(([negotiation]) => funderIds.push(negotiation?.funder?.id));
      funderIds = uniq(funderIds);

      funderIds = funderIds.filter(Number);

      if (isPageSelected) {
        updateSelected = selectedItems.filter(id => !invoicesIds.includes(id));
        funderIds = [];
      } else {
        updateSelected = uniq(selectedItems.concat(invoicesIds));
      }

      return state.merge({
        isPageSelected: !isPageSelected,
        selectedItems: updateSelected,
        hasSelectedAllItems: false,
        selectedFunderIds: funderIds,
      });
    }

    case ADMIN_TOGGLE_ITEM_SELECTION: {
      const { id: selectedId } = payload;
      const { selectedItems, negotiations } = state;

      let updatedSelected = [];
      let countInvestor = [];

      if (selectedItems.includes(selectedId)) {
        updatedSelected = selectedItems.filter(id => id !== selectedId);
      } else {
        updatedSelected = selectedItems.concat([selectedId]);
      }

      const difference = differenceBy(negotiations, updatedSelected, item => item.id || item);
      const isPageSelected = difference.length === 0;
      if (updatedSelected) {
        updatedSelected
          .map(id => negotiations.filter(i => id === i.id))
          .filter(([item]) => countInvestor.push(item?.funder?.id));
        countInvestor = countInvestor.filter(Number);
      } else {
        countInvestor = [];
      }
      return state.merge({
        isPageSelected,
        selectedItems: uniq(updatedSelected),
        hasSelectedAllItems: false,
        selectedFunderIds: uniq(countInvestor),
      });
    }

    case ADMIN_CLEAR_SELECTION: {
      return state.merge({
        selectedItems: [],
        hasSelectedAllItems: false,
        isPageSelected: false,
      });
    }

    case ADMIN_UPDATE_SELECTION: {
      const ids = payload;
      const { negotiations } = state;

      const difference = differenceBy(
        negotiations.map(({ id }) => id),
        ids,
        id => id,
      );
      const isPageSelected = difference.length === 0;

      return state.merge({ selectedItems: ids, isPageSelected });
    }

    case ADMIN_UPDATE_IS_ALL_SELECTED:
      return state.merge({
        hasSelectedAllItems: payload,
      });

    case ADMIN_CHANGE_TAB:
      return state.merge({
        activeTab: payload,
      });

    case ADMIN_REPORT_LOADING: {
      const { loading, reportId } = payload;
      return state.merge({
        downloadReportLoading: loading,
        downloadReportId: loading ? reportId : '',
      });
    }

    case ADMIN_REPORT_RECEIVED: {
      const { downloadReportId } = state;
      const { status, url, errorMsg, requestId } = convertToCamelCase(payload);
      if (downloadReportId === requestId) {
        if (status) {
          handleSuccess('Sucesso!', 'Sua planilha será baixada agora');
          window.location.assign(url);
        } else {
          handleError('Erro!', errorMsg || 'Ocorreu um erro ao gerar a planilha');
        }
        return state.merge({ downloadReportLoading: false, downloadReportId: '' });
      }
      return state;
    }

    case ADMIN_TRADES_SET_SORTING: {
      const { sorting } = payload;
      return state.merge({ sorting, pagination: { ...state.pagination, current: 1 } });
    }

    default:
      return state;
  }
};
