import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CompleteTable } from 'liber-components';
import AnticipationsTableActions from '../AnticipationsTableActions';
import VendorsAccordion from '../VendorsAccordion';
import { SchedulesContext } from '../../context';
import AnticipationsTableFilters from '../AnticipationsTableFilter';
import { STATUS } from '../../constants';

const AnticipationsTable = ({ token }) => {
  const { schedules, currentPage, setCurrentPage, setListing, totalItems, pages, listing } =
    React.useContext(SchedulesContext);
  const columns = [
    {
      name: 'title',
      label: 'Título',
      width: 30,
      getCellValue: scheduled => {
        const { sender, receipt } = scheduled;
        return (
          <p>
            Envio de fornecedores de {sender} para {receipt}
          </p>
        );
      },
    },
    {
      name: 'scheduled_for',
      label: 'Data',
      width: 30,
      getCellValue: scheduled => {
        const { scheduled_for: scheduledFor } = scheduled;
        return <p>{moment(scheduledFor).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}</p>;
      },
    },
    {
      name: 'status',
      label: 'Status',
      width: 30,
      getCellValue: scheduled => STATUS[scheduled.status],
    },
    {
      name: 'vendors',
      label: 'Fornecedores',
      width: 30,
      getCellValue: scheduled => {
        const { vendors } = scheduled;
        return <VendorsAccordion vendors={vendors} />;
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      width: 10,
      align: 'center',
      getCellValue: scheduled => <AnticipationsTableActions scheduled={scheduled} token={token} />,
    },
  ];

  const handleListing = selectedListing => {
    setCurrentPage('1');
    setListing(selectedListing);
  };

  return (
    <CompleteTable
      columns={columns}
      items={schedules}
      filters={<AnticipationsTableFilters />}
      paginationProps={{
        currentPage,
        onChangePage: target => setCurrentPage(target.value),
        onClickNext: () => setCurrentPage(`${parseInt(currentPage, 10) + 1}`),
        onClickPrev: () => setCurrentPage(`${parseInt(currentPage, 10) - 1}`),
        hasListing: true,
        pageTotal: pages,
        listingProps: {
          onSelectListing: selectedListing => handleListing(selectedListing),
          listing,
          listingLabelSingular: 'item',
          listingLabelPlural: 'items',
          total: totalItems,
        },
      }}
    />
  );
};

AnticipationsTable.propTypes = {
  token: PropTypes.string.isRequired,
};

export default AnticipationsTable;
