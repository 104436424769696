import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'liber-components/components/Inputs/Inputs';
import { Box } from './styles';
import FilterTag from '../FilterTag';
import FilterFooter from '../FilterFooter';

const SingleDateFieldFilter = ({ label, onFilter, onClear, selectedDate }) => {
  const [showTag, setShowTag] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (!selectedDate) {
      setDate(null);
    }
  }, [selectedDate]);

  const isDisabled = () => !date;
  const handleClear = () => {
    setDate(null);
    onClear();
    setShowTag(false);
  };
  const handleFilter = () => {
    onFilter(date);
    setShowTag(false);
  };

  const handleChange = value => {
    if (value) {
      setDate(value);
    } else {
      handleClear();
    }
  };

  const toggleShow = (shouldShow = undefined) =>
    shouldShow === undefined ? setShowTag(!showTag) : setShowTag(shouldShow);

  const formatLabel = selected => {
    const [singleDay] = selected;
    return singleDay || 'Data de Criação';
  };

  return (
    <FilterTag
      show={showTag}
      onChange={toggleShow}
      onClear={handleClear}
      selected={[selectedDate]}
      formatSelected={formatLabel}
      label={label}
      overflow="center"
    >
      <Box>
        <DatePicker showTime={false} onChange={handleChange} closeOnSelect />
      </Box>
      <FilterFooter disabled={isDisabled()} onFilter={handleFilter} onClear={handleClear} />
    </FilterTag>
  );
};

SingleDateFieldFilter.propTypes = {
  label: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
  selectedDate: PropTypes.string,
};

SingleDateFieldFilter.defaultProps = {
  label: 'Data',
  onFilter: () => null,
  onClear: () => null,
  selectedDate: null,
};

export default SingleDateFieldFilter;
