import styled from 'styled-components';
import {
  PrimaryButton as RawPrimaryButton,
  SelectField,
  NumberField,
  SecondaryButton,
} from 'liber-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: #6287a7;
  width: 408px;
  height: 250px;
`;

export const SubTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;
  margin-bottom: 8px;
`;

export const PrimaryButton = styled(RawPrimaryButton).attrs(() => ({
  version: 2,
  size: 'medium',
}))``;

export const SelectBox = styled(SelectField).attrs(() => ({
  version: 2,
  openLabel: true,
}))`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 16px;
  & label {
    font-weight: normal;
  }
`;

export const TextField = styled(NumberField).attrs(() => ({
  version: 2,
  openLabel: true,
}))`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
`;

export const CheckboxLabel = styled.div`
  color: #6287a7;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  user-select: none;
`;

export const SaveButton = styled(RawPrimaryButton).attrs(() => ({ size: 'large', version: 2 }))`
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
`;

export const CanceledButton = styled(SecondaryButton).attrs(() => ({ size: 'medium' }))`
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const TitleModal = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #6287a7;
  margin-bottom: 4px;
`;

export const DialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 18px;
`;

export const ContainerModal = styled.div`
  padding: 24px;
`;
