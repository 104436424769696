import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast/components/ToastContainer';
import ReplicateVendor from './components/replicateVendor';
import hydrateStore from '../store';

function View({ vendorId, token }) {
  return (
    <Provider store={hydrateStore({ replicateVendorReducer: { vendorId, token } })}>
      <ThemeProvider theme={LiberV4}>
        <ReplicateVendor />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}
View.propTypes = {
  vendorId: PropTypes.string,
  token: PropTypes.string,
};
View.defaultProps = {
  vendorId: '',
  token: '',
};
export default View;
