import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4, ToastContainer } from 'liber-components';
import LimitsStore from './context';
import LimitsView from './components/LimitsView';

const View = ({ token }) => (
  <ThemeProvider theme={LiberV4}>
    <ToastContainer />
    <LimitsStore token={token}>
      <LimitsView />
    </LimitsStore>
  </ThemeProvider>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
};

export default View;
