import axios from 'axios';
import { CAN_RECEIVE_VENDORS_FILTER } from './constants';
import {
  EXTRACT_CNPJS_URL,
  BLOCK_UNBLOCK_BUYER_URL,
  BLOCK_UNBLOCK_URL,
  GET_FUNDERS_URL,
  BATCH_BLOCK_UNBLOCK_URL,
} from './urls';

export function fetchFundersForSchedule(token, params) {
  const defaultParams = {
    [CAN_RECEIVE_VENDORS_FILTER]: true,
  };

  return axios.get(GET_FUNDERS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { ...defaultParams, ...params },
  });
}

export function postVendorBlockUnblock(token, data) {
  return axios.post(BLOCK_UNBLOCK_URL, data, {
    headers: {
      Authorization: `Bearer: ${token}`,
    },
  });
}

export function postVendorBlockUnblockOnBuyer(token, action, data) {
  return axios.post(BLOCK_UNBLOCK_BUYER_URL[action], data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function fetchFunders(token) {
  return axios.get(GET_FUNDERS_URL, {
    headers: {
      Authorization: `Bearer: ${token}`,
    },
  });
}

export function postBatchBlockUnblock(token, data) {
  return axios.post(BATCH_BLOCK_UNBLOCK_URL, data, {
    headers: {
      Authorization: `Bearer: ${token}`,
    },
  });
}

export function uploadBatchBlockUnblockFile(token = '', file) {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(EXTRACT_CNPJS_URL, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}
