export const BUTTON_LABEL = {
  block: 'Bloquear',
  unblock: 'Desbloquear',
};

export const REASONS = {
  unblock: [
    'Cadastro pré-aprovado',
    'Cadastro aprovado',
    'Situação regularizada',
    'Documentação atualizada',
  ],
  block: [
    'Aguardando aprovação do cadastro',
    'Cadastro não aprovado',
    'Situação irregular',
    'Documentação desatualizada',
  ],
};

export const MESSAGES = {
  title: {
    unblock: 'Confirmação de desbloqueio',
    block: 'Confirmação de bloqueio',
  },
  blockOptionsTitle: {
    block: 'Motivo do bloqueio:',
    unblock: 'Motivo do desbloqueio:',
  },
  funder: {
    block: {
      confirmation: (vendor, funder) => `Confimar bloqueio do ${vendor} com o ${funder}?`,
      info: funder =>
        `Este operador ficará impedido de realizar operações com o investidor ${funder}.`,
      observation: funder =>
        // eslint-disable-next-line max-len
        `Se este fornecedor estiver em um grupo direcionado para o investidor ${funder}, ele não poderá realizar adiantamentos`,
    },
    unblock: {
      confirmation: (vendor, funder) =>
        `Confirmar desbloqueio do fornecedor ${vendor} com o investidor ${funder}?`,
      info: funder => `Este fornecedor poderá realizar operações com o investidor ${funder}`,
    },
  },
  buyer: {
    block: {
      confirmation: vendor => `Confirmar bloqueio do fornecedor ${vendor}?`,
      info: () => 'Este fornecedor ficará impedido de realizar operações no portal deste sacado.',
    },
    unblock: {
      confirmation: vendor => `Confirmar desbloqueio do fornecedor ${vendor}?`,
      info: () => 'Este fornecedor poderá realizar operações no portal deste sacado.',
    },
  },
};

export const BATCH_MESSAGES = {
  title: {
    block: 'Confirmar bloqueio no investidor?',
    unblock: 'Confirmar desbloqueio no investidor?',
  },
  block: {
    confirmation: funder => `Confirmar o bloqueio dos fornecedores com o ${funder}?`,
    info: funder =>
      `Esses fornecedores ficarao impedidos de realizar operações com o investidor ${funder}.`,
    observation: funder =>
      // eslint-disable-next-line max-len
      `Se estes fornecedores estiverem em um grupo direcionado para investidor ${funder}, eles não poderão realizar adiantamentos.`,
  },
  unblock: {
    confirmation: funder => `Confirmar o desbloqueio dos fornecedores com o ${funder}?`,
    info: funder => `Esses fornecedores poderão realizar operações com o investidor ${funder}.`,
  },
};

export const ACTIONS = {
  BLOCK: 'block',
  UNBLOCK: 'unblock',
};
