import { connect } from 'react-redux';
import SupportErrors from './SupportErrors';

const reducer = 'sdkErrors';

const mapStateToProps = state => ({ ...state[reducer] });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SupportErrors);
