import styled from 'styled-components';
import { Button as LiberButton, handleThemeFromObject as getTheme } from 'liber-components';

export const Label = styled.div`
  font-family: Roboto;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #405f71;
  margin-right: 16px;
`;

export const Button = styled(LiberButton).attrs(() => ({ version: '2' }))`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  border: 1px solid #009dff;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
`;
