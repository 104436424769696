import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { toast } from 'liber-components';

import messages from './messages';

export const changeCurrentPassword = event => ({
  type: 'CHANGE_PASSWORD/CURRENT_PASSWORD',
  payload: event,
});

export const changeNewPassword = event => ({
  type: 'CHANGE_PASSWORD/NEW_PASSWORD',
  payload: event,
});

export const changePasswordConfirmation = event => ({
  type: 'CHANGE_PASSWORD/PASSWORD_CONFIRMATION',
  payload: event,
});

export const handleSuccess = () => ({
  type: 'CHANGE_PASSWORD/SUCCESSFULL_SUBMIT',
});

export const handleFailure = payload => ({
  type: 'CHANGE_PASSWORD/FAILED_SUBMIT',
  payload,
});

export const validateForm = (currentPassword, newPassword, passwordConfirmation) => {
  const payload = {};

  if (currentPassword.length === 0) {
    payload.currentPassword = messages.empty;
  }
  if (passwordConfirmation !== newPassword) {
    payload.newPassword = messages.match;
  }
  if (newPassword.length < 6) {
    payload.newPassword = messages.small;
  }
  if (newPassword.length === 0) {
    payload.newPassword = messages.empty;
  }
  if (passwordConfirmation.length === 0) {
    payload.passwordConfirmation = messages.empty;
  }

  return payload;
};

export const sendToBackend = (currentPassword, newPassword, passwordConfirmation) => dispatch =>
  axios({
    method: 'PATCH',
    url: '/investidor/passwords/update_change',
    data: {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: passwordConfirmation,
      authenticity_token: ReactOnRails.authenticityToken(),
    },
    responseType: 'json',
  })
    .then(response => {
      dispatch(handleSuccess());
      window.location.assign(response.data.location);
    })
    .catch(error => {
      toast(error.response.data.error.message, 'error', 8000);
      dispatch(handleFailure({ currentPassword: error.response.data.error.reason }));
    });

export const submitForm = (currentPassword, newPassword, passwordConfirmation) => dispatch => {
  const payload = validateForm(currentPassword, newPassword, passwordConfirmation);

  if (Object.keys(payload).length === 0) {
    dispatch(sendToBackend(currentPassword, newPassword, passwordConfirmation));
    dispatch(handleSuccess());
  } else {
    dispatch(handleFailure(payload));
  }

  return dispatch({
    type: 'CHANGE_PASSWORD/SUBMIT_FORM',
  });
};
