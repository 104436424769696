import { validateCnpj } from '../../../vendor/validation';
import { errorMsg } from '../messages';

export function changeCNPJ(value) {
  if (validateCnpj(value)) {
    return {
      type: 'SACADO_CHANGE_CNPJ',
      payload: value,
    };
  }

  if (value.length === 0) {
    return {
      type: 'SACADO_CHANGE_CNPJ_ERROR',
      payload: {
        cnpj: value,
        error: errorMsg.empty,
      },
    };
  }

  return {
    type: 'SACADO_CHANGE_CNPJ_ERROR',
    payload: {
      cnpj: value,
      error: errorMsg.invalidFormat,
    },
  };
}

export function changePassword(value) {
  if (value.length === 0) {
    return {
      type: 'SACADO_CHANGE_PASSWORD_ERROR',
      payload: {
        password: value,
        error: errorMsg.empty,
      },
    };
  }
  return {
    type: 'SACADO_CHANGE_PASSWORD',
    payload: value,
  };
}

export function toogleRemember() {
  return {
    type: 'SACADO_TOOGLE_REMEMBER',
  };
}

export const setFingerprint = data => ({
  type: 'SACADO_SET_FINGERPRINT',
  payload: data,
});

export const submitLogin = (target, cnpj, password) => dispatch => {
  if (cnpj.length !== 0 && validateCnpj(cnpj) && password.length !== 0) {
    target.submit();
    return dispatch({
      type: 'SACADO_SUCESSFULL_LOGIN',
    });
  }
  let cnpjError = cnpj.length === 0 ? errorMsg.empty : '';
  cnpjError = validateCnpj(cnpj) || cnpj.length === 0 ? cnpjError : errorMsg.invalidFormat;
  return dispatch({
    type: 'SACADO_FAILED_LOGIN',
    payload: {
      cnpjError,
      passwordError: password.length === 0 ? errorMsg.empty : '',
    },
  });
};
