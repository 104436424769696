import React, { useState, useMemo } from 'react';
import Icon from '@mdi/react';

import ScreenTitle from '../../../../common-components/ScreenTitle';
import { Container, Subtitle, CardsRow, SelectableCard, Text } from './styles';
import LimitsTable from '../LimitsTable';
import { monetizationIconPath } from '../../../investor-renegotiations/utils';
import { SELECTED_TABLE_MAPPINGS, TABS } from '../../constants';

export default function BuyerAnticipationLimits() {
  const tabs = useMemo(() => TABS, []);

  const [selectedTable, setSelectedTable] = useState(tabs[0]);

  const handleCardClick = type => () => setSelectedTable(type);

  return (
    <Container>
      <ScreenTitle>Limites</ScreenTitle>
      <Subtitle>
        Defina e controle os limites de antecipação.
        <br />
        Confira a seguir as opções de limite disponíveis.
      </Subtitle>
      <CardsRow>
        {tabs.map(tab => (
          <SelectableCard
            data-testid={`${tab}-tab`}
            key={tab}
            selected={selectedTable === tab}
            onClick={handleCardClick(tab)}
          >
            <Text>
              <Icon path={monetizationIconPath} />
              {SELECTED_TABLE_MAPPINGS[tab].tabName}
            </Text>
          </SelectableCard>
        ))}
      </CardsRow>
      <LimitsTable selectedTable={selectedTable} />
    </Container>
  );
}
