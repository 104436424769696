import styled from 'styled-components';
import { Button as ButtonBase } from 'liber-components';
import IconBase from '@mdi/react';

export const IconButton = styled(ButtonBase).attrs(() => ({ version: 2 }))`
  padding: 4px;
  border-radius: 50%;
  & > svg {
    max-width: 20px !important;
    max-height: 20px !important;
  }
`;

export const IconLink = styled(ButtonBase).attrs(() => ({ version: 2, as: 'a', target: '_blank' }))`
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
`;

export const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Icon = styled(IconBase).attrs(() => ({ color: '#6287a7', size: '20px' }))``;
