import React from 'react';
import { Container, Spinner } from './styles';

const Loading = () => (
  <Container>
    <Spinner>
      <span />
    </Spinner>
  </Container>
);

export default Loading;
