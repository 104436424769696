const domain = SERVICE_API_URL; // eslint-disable-line
export const SCHEDULES_DELETE_URL = `${domain}/api/v1/admin/schedule_vendor_data_sends`;
export const SCHEDULES_LIST_URL = `${domain}/api/v1/admin/schedule_vendor_data_sends`;

export const STATUS_EQUALS = 'q[status_eq]';
export const DATE_LOWER = 'q[scheduled_for_lteq]';
export const DATE_GRATER = 'q[scheduled_for_gteq]';
export const DATE_EQUALS = 'q[scheduled_for_eq]';
export const STATUS_NOT_EQUALS = 'q[status_not_eq]';

export const STATUS = {
  scheduled: 'Agendado',
  canceled: 'Cancelado',
  processing: 'Processando',
  sent: 'Enviado',
  failed: 'Falha no Envio',
};
