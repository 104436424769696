import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'liber-components';
import { toast } from 'liber-components/components/Toast';
import Pagination from 'liber-components/components/Pagination';
import InvoiceSimulationTableTooltipContent from './InvoiceSimulationTableTooltipContent';
import InvoiceSimulationTable from './InvoiceSimulationTable';
import {
  SimulationPaginationSection,
  Loading,
  LoadingBlock,
  BottomSpacer,
} from './InvoiceSimulationTableContainer.styles';
import {
  fetchInvoices,
  updatePagination,
  changeTab,
  updateSelectedInvoices,
  sortByColumn,
} from '../../actions/InvoiceSimulationAction';
import InvoiceSimulationNegotiationList from './InvoiceSimulationNegotiationList';
import {
  TOOLTIP_MESSAGES,
  calculateQuote,
  getInvoiceById,
  INVOICE_TABS,
  INVOICE_TABS_ARRAY,
  MAP_INVOICE_STATUS,
  tabType,
  TABS,
} from '../../utils';
import { formatMoney } from '../../../withdraw/moneyHandler';
import InvoiceSimulationEmptyState from './InvoiceSimulationEmptyState';
import TabControl from '../TabControl/TabControl';

const { AVAILABLE, NEGOTIATION, ALL } = INVOICE_TABS;

export class InvoiceSimulationTableContainer extends Component {
  componentDidMount() {
    this.getStatusEq();
  }

  shouldComponentUpdate(nextProps) {
    const {
      selectStatus,
      invoiceSubscribeReceived,
      isAllOfPageAreSelected,
      invoiceSubscribeReportReceived,
    } = this.props;
    if (selectStatus === 'multiple' && nextProps.selectStatus === 'empty') {
      // when delect all invoices
      return true;
    }
    if (!invoiceSubscribeReceived && nextProps.invoiceSubscribeReceived) {
      // when receive data from action cable
      return true;
    }
    if (!invoiceSubscribeReportReceived && nextProps.invoiceSubscribeReportReceived) {
      // when receive data from action cable
      return true;
    }

    if (isAllOfPageAreSelected !== nextProps.isAllOfPageAreSelected) {
      return true;
    }

    return this.props.loading !== nextProps.loading; // when fetch invoices finish
  }

  componentDidUpdate(prevProps) {
    const {
      invoiceSubscribeReceived,
      invoiceSubscribeReportReceived,
      invoiceSubscribeStatus,
      invoiceSubscribeUrl,
      invoiceSubscribeReport,
    } = this.props;
    if (!prevProps.invoiceSubscribeReceived && invoiceSubscribeReceived) {
      if (invoiceSubscribeStatus) {
        window.location.href = invoiceSubscribeUrl;
      } else {
        toast(
          {
            message: 'Erro no Adiantamento',
            info: `Ocorreu um erro ao realizar o adiantamento, tente novamente.
            Se o problema persistir, entre em contato com o suporte`,
          },
          'error',
          8000,
        );
      }
    }
    if (!prevProps.invoiceSubscribeReportReceived && invoiceSubscribeReportReceived) {
      if (invoiceSubscribeStatus) {
        window.location.href = invoiceSubscribeReport;
      } else {
        toast(
          {
            message: 'Erro ao baixar o Relatório da Simulação',
            info: `Ocorreu um erro ao baixar o relatório, tente novamente.
            Se o problema persistir, entre em contato com o suporte`,
          },
          'error',
          8000,
        );
      }
    }
  }

  getStatusEq = () => {
    const { onChangeTab, token, onFetchInvoices, activeTab } = this.props;
    this.params = new URLSearchParams(decodeURIComponent(window.location.search));
    if (this.params.has('q[status_eq]')) {
      const tab = this.params.get('q[status_eq]');
      onChangeTab(token, TABS[tab] || ALL);
    } else if (activeTab !== ALL) {
      onChangeTab(token, activeTab);
      this.setStatusEq(activeTab);
    } else {
      onFetchInvoices(token);
    }
  };

  setStatusEq = tab => {
    const url = new URL(window.location.href);
    this.params.set('q[status_eq]', parseInt(MAP_INVOICE_STATUS[tab], 10) || '');
    url.search = this.params.toString();
    // eslint-disable-next-line
    history.replaceState(
      {
        id: 'homepage',
      },
      null,
      url,
    );
  };

  handleChangeTab = index => {
    const { onChangeTab, token } = this.props;
    const tab = INVOICE_TABS_ARRAY[index];
    onChangeTab(token, tab);
    this.setStatusEq(tab);
  };

  handleChangePage = e => {
    const { onUpdatePagination, pagination, token } = this.props;
    const { value } = e.target;
    if (value >= 1 && value <= pagination.pages) {
      onUpdatePagination(token, {
        current: value,
        per: pagination.per,
      });
    }
  };

  handleSelectListing = value => {
    const { onUpdatePagination, token } = this.props;
    onUpdatePagination(token, {
      current: 1,
      per: parseInt(value, 10),
    });
  };

  handleClickNext = () => {
    const { pagination, onUpdatePagination, token } = this.props;
    if (pagination.current < pagination.pages) {
      onUpdatePagination(token, {
        current: pagination.current + 1,
        per: pagination.per,
      });
    }
  };

  handleClickPrev = () => {
    const { pagination, onUpdatePagination, token } = this.props;
    if (pagination.current > 1) {
      onUpdatePagination(token, {
        current: pagination.current - 1,
        per: pagination.per,
      });
    }
  };

  handleSelectAllInMultiple = () => {
    const { onUpdateSelectedInvoices, selectedInvoices, selectStatus, unselectedInvoices } =
      this.props;
    if (selectStatus === 'multiple') {
      const newUnselectedInvoices = [];
      const newSelectedInvoices = [
        ...selectedInvoices,
        ...unselectedInvoices.map(invoice => invoice.id),
      ];
      const newQuotes = calculateQuote(newSelectedInvoices, newUnselectedInvoices, this.props);
      onUpdateSelectedInvoices(newSelectedInvoices, newUnselectedInvoices, newQuotes);
    }
  };

  handleSelectInvoice = id => {
    const { onUpdateSelectedInvoices, selectedInvoices, selectStatus, unselectedInvoices } =
      this.props;
    let newUnselectedInvoices = [];
    let newSelectedInvoices;
    if (selectedInvoices.includes(id)) {
      // if invoice was already selected, deselect
      newSelectedInvoices = selectedInvoices.filter(invoiceId => invoiceId !== id);
      if (selectStatus === 'multiple') {
        newUnselectedInvoices = [...unselectedInvoices, getInvoiceById(id, this.props)];
      }
    } else {
      newSelectedInvoices = [...selectedInvoices, id];
      if (selectStatus === 'multiple') {
        newUnselectedInvoices = unselectedInvoices.filter(invoice => invoice.id !== id);
      }
    }
    const newQuotes = calculateQuote(newSelectedInvoices, newUnselectedInvoices, this.props);
    onUpdateSelectedInvoices(newSelectedInvoices, newUnselectedInvoices, newQuotes);
  };

  handleSelectAllInvoicesPerPage = () => {
    const { invoices, onUpdateSelectedInvoices, selectedInvoices } = this.props;

    let newUnselectedInvoices = [];
    let newSelectedInvoices = selectedInvoices;
    const invoicesId = invoices.map(invoice => invoice.id);
    // Verify if selectedInvoices is a subset of invoicesId
    const match = invoicesId.every(id => selectedInvoices.includes(id));

    if (!match) {
      // there are unselected invoices, so they should be selected
      // Merge the two arrays and remove duplicates
      newSelectedInvoices = [...new Set([...invoicesId, ...selectedInvoices])];
    } else {
      // remove all invoices from this page
      newUnselectedInvoices = invoices;
      newSelectedInvoices = selectedInvoices.filter(id => !invoicesId.includes(id));
    }
    const newQuotes = calculateQuote(newSelectedInvoices, newUnselectedInvoices, this.props);
    onUpdateSelectedInvoices(newSelectedInvoices, newUnselectedInvoices, newQuotes);
  };

  handleSortByColumn = (column, type) => {
    const { onSortByColumn, token } = this.props;
    onSortByColumn(token, column, type);
  };

  renderTableContent = tab => {
    const { emptyInvoices, negotiations, invoices, rateByPeriod, selectStatus, activeTab } =
      this.props;

    if (emptyInvoices) {
      return <InvoiceSimulationEmptyState tabId={INVOICE_TABS[tab]} />;
    }
    if (negotiations.length) {
      return <InvoiceSimulationNegotiationList negotiations={negotiations} />;
    }
    return (
      <>
        <InvoiceSimulationTable
          invoices={invoices}
          selectStatus={selectStatus}
          onSelectInvoice={this.handleSelectInvoice}
          activeTab={activeTab}
          rateByPeriod={rateByPeriod}
        />
      </>
    );
  };

  render() {
    const {
      pagination,
      loading,
      activeTab,
      faceValue,
      negotiations,
      avarageDiscountRate,
      rateByPeriod,
      selectStatus,
      sortAsc,
      sortDesc,
      isAllOfPageAreSelected,
      orderedColumn,
      token,
      invoices,
      isFiltered,
    } = this.props;

    return (
      <>
        {loading ? <Loading loop /> : null}
        <LoadingBlock loading={loading}>
          <Tabs
            version="2"
            ControlComponent={
              <TabControl
                token={token}
                invoices={invoices}
                onSelectAllInMultiple={this.handleSelectAllInMultiple}
                isFiltered={isFiltered}
                tableHeaderProps={{
                  activeTab,
                  selectStatus,
                  sortAsc,
                  sortDesc,
                  isAllOfPageAreSelected,
                  onSelectAllInvoicesPerPage: this.handleSelectAllInvoicesPerPage,
                  onSort: this.handleSortByColumn,
                  orderedColumn,
                }}
              />
            }
            onChange={this.handleChangeTab}
            activeTab={INVOICE_TABS_ARRAY.indexOf(activeTab)}
          >
            {Object.keys(INVOICE_TABS).map(tab => (
              <Tab
                key={tab}
                version="2"
                strongBorder
                label={INVOICE_TABS[tab]}
                tooltipDelay={500}
                tooltip={
                  <InvoiceSimulationTableTooltipContent
                    simple={activeTab !== INVOICE_TABS[tab] || activeTab === NEGOTIATION}
                    text={TOOLTIP_MESSAGES[INVOICE_TABS[tab]]}
                    invoiceNumber={pagination.count}
                    avarageDiscountRate={avarageDiscountRate}
                    totalFaceValue={formatMoney(faceValue)}
                    rateByPeriod={rateByPeriod}
                  />
                }
              >
                {activeTab === INVOICE_TABS[tab] && this.renderTableContent(tab)}
              </Tab>
            ))}
          </Tabs>
          {!negotiations.length && (
            <SimulationPaginationSection>
              <Pagination
                page={String(pagination.current)}
                pageTotal={String(pagination.pages)}
                onChangePage={this.handleChangePage}
                onClickNext={this.handleClickNext}
                onClickPrev={this.handleClickPrev}
                withListing
                listingProps={{
                  listing: String(pagination.per),
                  total: String(pagination.count),
                  onSelectListing: this.handleSelectListing,
                }}
              />
            </SimulationPaginationSection>
          )}
        </LoadingBlock>
        <BottomSpacer />
      </>
    );
  }
}

InvoiceSimulationTableContainer.propTypes = {
  token: PropTypes.string.isRequired,
  onFetchInvoices: PropTypes.func.isRequired,
  onUpdatePagination: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onUpdateSelectedInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object),
  rateByPeriod: PropTypes.bool,
  // invoicesPerPage: PropTypes.arrayOf({}),
  pagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per: PropTypes.number,
    previous: PropTypes.number,
  }),
  loading: PropTypes.bool,
  selectStatus: PropTypes.string,
  selectedInvoices: PropTypes.arrayOf(PropTypes.number),
  unselectedInvoices: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  activeTab: tabType,
  quoteAll: PropTypes.shape({
    discount_rate: PropTypes.string,
    face_value: PropTypes.string,
    net_value: PropTypes.string,
    term: PropTypes.string,
  }),
  faceValue: PropTypes.string,
  invoiceSubscribeReceived: PropTypes.bool,
  invoiceSubscribeReportReceived: PropTypes.bool,
  invoiceSubscribeStatus: PropTypes.bool,
  negotiations: PropTypes.arrayOf(PropTypes.any),
  emptyInvoices: PropTypes.bool,
  orderedColumn: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortDesc: PropTypes.bool,
  onSortByColumn: PropTypes.func.isRequired,
  isAllOfPageAreSelected: PropTypes.bool,
  invoiceSubscribeUrl: PropTypes.string,
  invoiceSubscribeReport: PropTypes.string,
  avarageDiscountRate: PropTypes.number,
  isFiltered: PropTypes.bool.isRequired,
};

InvoiceSimulationTableContainer.defaultProps = {
  invoices: [],
  rateByPeriod: false,
  // invoicesPerPage: [],
  pagination: {},
  loading: false,
  selectStatus: 'empty',
  selectedInvoices: [],
  unselectedInvoices: [],
  activeTab: AVAILABLE,
  quoteAll: {},
  faceValue: '0',
  invoiceSubscribeReceived: false,
  invoiceSubscribeReportReceived: false,
  invoiceSubscribeStatus: false,
  negotiations: [],
  emptyInvoices: false,
  orderedColumn: '',
  sortAsc: false,
  sortDesc: false,
  isAllOfPageAreSelected: false,
  invoiceSubscribeUrl: '',
  invoiceSubscribeReport: null,
  avarageDiscountRate: 0,
};

const mapStateToProps = ({
  invoiceSimulation: {
    invoices,
    rateByPeriod,
    pagination,
    loading,
    activeTab,
    faceValue,
    quoteAll,
    unselectedInvoices,
    selectedInvoices,
    selectStatus,
    invoicesPerPage,
    invoiceSubscribeReceived,
    invoiceSubscribeStatus,
    invoiceSubscribeReport,
    invoiceSubscribeReportReceived,
    negotiations,
    emptyInvoices,
    orderedColumn,
    sortAsc,
    sortDesc,
    isAllOfPageAreSelected,
    invoiceSubscribeUrl,
    avarageDiscountRate,
    isFiltered,
  },
}) => ({
  invoices,
  rateByPeriod,
  pagination,
  loading,
  activeTab,
  faceValue,
  quoteAll,
  unselectedInvoices,
  selectedInvoices,
  selectStatus,
  invoicesPerPage,
  invoiceSubscribeReceived,
  invoiceSubscribeStatus,
  invoiceSubscribeReport,
  invoiceSubscribeReportReceived,
  negotiations,
  emptyInvoices,
  orderedColumn,
  sortAsc,
  sortDesc,
  isAllOfPageAreSelected,
  invoiceSubscribeUrl,
  avarageDiscountRate,
  isFiltered,
});

const mapDispatchToProps = {
  onFetchInvoices: fetchInvoices,
  onUpdatePagination: updatePagination,
  onChangeTab: changeTab,
  onUpdateSelectedInvoices: updateSelectedInvoices,
  onSortByColumn: sortByColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSimulationTableContainer);
