import React from 'react';
import PropTypes from 'prop-types';
import ScreenTitle from '../../common-components/ScreenTitle/ScreenTitle';
import { handleNavigate } from '../../vendor/Utils';
import UploadContextProvider from './context';
import FunderSpreadsheetUpload from './components/FunderSpreadsheetUpload';
import LiberComponentsThemeWrapper from '../../common-components/LiberComponentsThemeWrapper';

const View = ({ token, exampleFiles }) => (
  <LiberComponentsThemeWrapper>
    <UploadContextProvider token={token} exampleFiles={exampleFiles}>
      <ScreenTitle handleBack={() => handleNavigate('investidor/painel_taxas')}>
        Criar nova taxa
      </ScreenTitle>
      <FunderSpreadsheetUpload />
    </UploadContextProvider>
  </LiberComponentsThemeWrapper>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  exampleFiles: PropTypes.shape({
    term: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default View;
