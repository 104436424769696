import Immutable from 'seamless-immutable';

const initialState = Immutable({
  bankAccounts: [],
  showBankAccountForm: false,
  loading: '',
});
const deleteFromArray = (array, index) => {
  const base = [...array];
  base.splice(index, 1);
  return base;
};

const setDefault = (bankAccounts, id) =>
  bankAccounts.map(account => {
    if (account.id === id) {
      return account.merge({ default: true });
    }
    return account.merge({ default: false });
  });

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_HIDRATE_FORM':
      return state.merge({ showBankAccountForm: true });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SUBMIT_SUCCESS':
      if (action.payload.index === -1) {
        return state.merge({
          bankAccounts: [...state.bankAccounts, action.payload.bankAccount],
          showBankAccountForm: false,
        });
      }
      return state.merge({
        bankAccounts: state.bankAccounts.set(action.payload.index, action.payload.bankAccount),
        showBankAccountForm: false,
      });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_CLOSE_MODAL_FORM':
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SUBMIT_REJECT':
      return state.merge({ showBankAccountForm: false });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_DELETE_PENDING':
      return state.merge({
        loading: 'delete',
      });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_DELETE_SUCCESS':
      return state.merge({
        bankAccounts: deleteFromArray(state.bankAccounts, action.payload),
        loading: '',
      });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SET_DEFAULT_SUCCESS':
      return state.merge({
        bankAccounts: setDefault(state.bankAccounts, action.payload),
        loading: '',
      });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SET_DEFAULT_PENDING':
      return state.merge({
        loading: 'default',
      });
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_DELETE_REJECT':
    case 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SET_DEFAULT_REJECT':
      return state.merge({
        loading: '',
      });
    default:
      return state;
  }
};
