import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMoney } from '../withdraw/moneyHandler';

export const normalizeAccounts = accounts =>
  accounts.map(account => ({
    agency: account.branch,
    agencyDigit: account.branch_cd,
    account: account.account,
    accountDigit: account.account_cd,
    bankName: account.bank_name,
    id: account.id,
    default: account.default,
    kind: account.kind,
    conjunct: account.joint_account,
    bankCode: account.bank_code,
    savingsComplement: account.account_suffix,
  }));

export const INVOICE_STATUS = {
  inserido: 'Inserido',
  confirmado: 'Confirmado',
  verificado: 'Disponível',
  bloqueado: 'Bloqueado',
  negociacao: 'Em negociação',
  vendido: 'Antecipado',
  pendente: 'Pendente',
  vencido: 'Vencido',
};

export const INVOICE_TABLE_COLUMNS = [
  {
    name: 'number',
    label: 'Número',
    width: 10,
  },
  {
    name: 'expires_at',
    label: 'Vencimento',
    width: 21.66,
    getCellValue: ({ expires_at: expires }) => moment(expires).format('DD/MM/YYYY'),
  },
  {
    name: 'value',
    label: 'Valor Bruto',
    width: 21.66,
    getCellValue: ({ face }) => `R$ ${formatMoney(face)}`,
  },
  {
    name: 'agreed_rate',
    label: 'Taxa de Desconto',
    width: 21.66,
    getCellValue: ({ ...invoice }, rateByPeriod) => {
      if (invoice.auctioned_and_negotiable) return 'A negociar';
      const discountRate = rateByPeriod
        ? (invoice.taxa * invoice.prazo_corrido) / 30
        : invoice.taxa;

      return (discountRate * 100).toFixed(4).replace('.', ',') + (rateByPeriod ? '%' : '% a.m.');
    },
  },
  {
    name: 'emitter_cnpj',
    label: 'CNPJ',
    width: 25,
  },
];

export const ALL_TAB_COLUMNS = [
  {
    name: 'number',
    label: 'Número',
    width: 15,
  },
  {
    name: 'status',
    label: 'Status',
    width: 21.5,
    getCellValue: ({ status }) => INVOICE_STATUS[status],
  },
  {
    name: 'expires_at',
    label: 'Vencimento',
    width: 21.5,
    getCellValue: ({ expires_at: expires }) => moment(expires).format('DD/MM/YYYY'),
  },
  {
    name: 'value',
    label: 'Valor Bruto',
    width: 21.5,
    getCellValue: ({ face }) => `R$ ${formatMoney(face)}`,
  },
  {
    name: 'agreed_rate',
    label: 'Taxa de Desconto',
    width: 21.5,
    getCellValue: ({ ...invoice }, rateByPeriod) => {
      if (invoice.auctioned_and_negotiable) return 'A negociar';
      const discountRate = rateByPeriod
        ? (invoice.taxa * invoice.prazo_corrido) / 30
        : invoice.taxa;

      return (discountRate * 100).toFixed(4).replace('.', ',') + (rateByPeriod ? '%' : '% a.m.');
    },
  },
];

export const calculateAvarageDiscount = (invoices, rateByPeriod) => {
  if (!invoices.length) {
    return 0;
  }

  const total = invoices.reduce((accumulation, invoice) => {
    const { taxa, prazo_corrido: daysPassed } = invoice;
    const rate = parseFloat(taxa);

    return rateByPeriod ? accumulation + (rate * daysPassed) / 30 : accumulation + rate;
  }, 0);

  return (total / invoices.length) * 100;
};

export const INVOICE_TABS = {
  AVAILABLE: 'Disponíveis',
  NEGOTIATION: 'Em Negociação',
  ADVANCED: 'Antecipados',
  NOT_ADVANCED: 'Não Antecipados',
  ALL: 'Todos',
};

const { AVAILABLE, NEGOTIATION, ADVANCED, NOT_ADVANCED, ALL } = INVOICE_TABS;

export const TABS = {
  2: AVAILABLE,
  4: NEGOTIATION,
  5: ADVANCED,
  7: NOT_ADVANCED,
  null: ALL,
};

export const INVOICE_TABS_ARRAY = [AVAILABLE, NEGOTIATION, ADVANCED, NOT_ADVANCED, ALL];

export const tabType = PropTypes.oneOf(INVOICE_TABS_ARRAY);

export const TOOLTIP_MESSAGES = {
  [ALL]: 'Aqui ficam listados todos os títulos.',
  [AVAILABLE]: `Aqui ficam listados os títulos que já foram
  confirmados pelo comprador e já estão disponíveis para serem antecipados.`,
  [NEGOTIATION]: `Aqui ficam os títulos que estão em negociação,
  aguardando propostas dos investidores ou que você formalize as operações.`,
  [ADVANCED]: 'Aqui ficam todos os títulos que você já operou conosco até hoje.',
  [NOT_ADVANCED]:
    'Aqui ficam os títulos que já passaram da data de vencimento antes de terem sido negociados.',
};

export const MAP_INVOICE_STATUS = {
  [AVAILABLE]: '2',
  [NEGOTIATION]: '4',
  [ADVANCED]: '5',
  [NOT_ADVANCED]: '7',
  [ALL]: '',
};

export const MAP_STATUS_NEGOTIATION = {
  proposta_pendente: 'Proposta Pendente',
  aguardando_aceite: 'Aguardando Aceite',
  aguardando_formalizacao: 'Aguardando Formalização',
};

export const getInvoiceById = (invoiceId, props) => {
  const { invoicesPerPage } = props;
  return invoicesPerPage.find(invoice => invoice.id === invoiceId);
};

export const getDiscountValue = action => {
  const {
    payload: {
      quote: { net_value: netValue, face_value: faceValue },
    },
  } = action;

  return faceValue - netValue;
};

export const calculateQuote = (selectedInvoices, unselectedInvoices, props) => {
  const { selectStatus, quoteAll, rateByPeriod } = props;
  let totalFaceValue;
  let totalNetValue;
  let weightedTerm;
  let weightedAverageTerm;

  if (selectStatus !== 'multiple') {
    // if in simple selection mode
    totalFaceValue = selectedInvoices.reduce(
      (acc, invoiceId) => acc + Number(getInvoiceById(invoiceId, props).face),
      0,
    );
    totalNetValue = selectedInvoices.reduce(
      (acc, invoiceId) => acc + Number(getInvoiceById(invoiceId, props).net_value),
      0,
    );

    weightedTerm = selectedInvoices.reduce(
      (acc, invoiceId) =>
        acc +
        Number(getInvoiceById(invoiceId, props).prazo_corrido) *
          Number(getInvoiceById(invoiceId, props).face),
      0,
    );

    weightedAverageTerm = weightedTerm / totalFaceValue || 0;
  } else {
    // if in multiple selection mode
    const unselectedFaceValue = unselectedInvoices.reduce(
      (acc, invoice) => acc + Number(invoice.face),
      0,
    );
    const unselectedNetValue = unselectedInvoices.reduce(
      (acc, invoice) => acc + Number(invoice.net_value),
      0,
    );
    const unselectedWeightedTerm = unselectedInvoices.reduce(
      (acc, invoice) => acc + Number(invoice.prazo_corrido) * Number(invoice.face),
      0,
    );

    const totalWeightedTerm = quoteAll.term * quoteAll.face_value;
    totalFaceValue = quoteAll.face_value - unselectedFaceValue;
    totalNetValue = quoteAll.net_value - unselectedNetValue;

    weightedAverageTerm =
      (totalWeightedTerm - unselectedWeightedTerm) / (quoteAll.face_value - unselectedFaceValue) ||
      0;
  }

  const discountRateByPeriod = 1 - totalNetValue / totalFaceValue;
  const discountRateByMonth = discountRateByPeriod * (30 / weightedAverageTerm);

  return {
    discount_rate: (rateByPeriod ? discountRateByPeriod : discountRateByMonth) || 0,
    face_value: totalFaceValue,
    net_value: totalNetValue,
    discount_value: totalFaceValue - totalNetValue,
    term: weightedAverageTerm,
  };
};

export const hasFilters = action => {
  const {
    payload: { filters },
  } = action;

  const isFiltered = Object.keys(filters).reduce(
    (previous, current) => previous || Boolean(filters[current]),
    false,
  );

  return isFiltered;
};
