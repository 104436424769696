import styled from 'styled-components';

export const RadioWrapper = styled.div`
  height: 28px;
  width: 28px;
  margin-left: 12px;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 32px;
`;
