import React, { Component } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from 'prop-types';
import { LinearProgress, Modal, Show, TooltipBox, toast, dispel } from 'liber-components';
import {
  Container,
  Header2,
  Header4,
  Row,
  ModalContent,
  ModalContainer,
  ModalButtons,
} from 'liber-components/components/GenericStyles';
import {
  InfoMessage,
  LoadingBox,
  BlueBunny,
  PdfContainer,
  ShowMore,
  DisabledOverlay,
  WhiteButton,
  BlueButton,
} from '../styles';

import InvoiceTable from './InvoiceTable';
import DocumentSignForm from './DocumentSignForm';
import ErrorHandler from '../ErrorHandler';
import { checkIsEcnpj } from '../../actions/MultiSignFornecedorViewActions';

const TIMEOUT = 1000 * 15;

smoothscroll.polyfill();

class MultiSignFornecedorView extends Component {
  componentWillMount() {
    const { assinaMeWaitForExtension } = this.props;
    window.addEventListener('AssinaMe:ready', this.assinaMeTimeoutMethod);
    assinaMeWaitForExtension();
  }

  componentDidMount() {
    this.listenerTimeout = setTimeout(() => {
      const { assinaMeBrowserSupportErrors } = this.props;
      clearTimeout(this.listenerTimeout);
      window.removeEventListener('AssinaMe:ready', this.assinaMeTimeoutMethod);
      assinaMeBrowserSupportErrors();
    }, TIMEOUT);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.status &&
      nextProps.error.toasts &&
      nextProps.error.toasts.length !== this.props.error.toasts.length &&
      nextProps.error.toasts.length !== 0
    ) {
      nextProps.error.toasts.forEach(({ message, type, noButton }) => {
        const toastId = toast(
          <>
            <span>
              {message}.
              {noButton ? null : (
                <>
                  <br />
                  <ShowMore role="button" onClick={() => this.scrollUp(toastId)}>
                    {' '}
                    Saiba Mais
                  </ShowMore>
                  .
                </>
              )}
            </span>
          </>,
          type,
          8000,
        );
      });
    }
  }

  componentWillUnmount() {
    this.props.removeSocket();
    window.removeEventListener('AssinaMe:ready', this.assinaMeTimeoutMethod);
  }

  scrollUp = toastId => {
    if (this.fixed) {
      this.fixed.scrollIntoView({ block: 'start', behavior: 'smooth' });
      dispel(toastId);
    }
  };

  assinaMeTimeoutMethod = () => {
    const {
      assinaMeSystemCheck,
      startSocket,
      fornecedorId,
      proposalIds,
      invoices,
      jointSignature,
      ecnpjSignature,
    } = this.props;
    clearTimeout(this.listenerTimeout);
    window.removeEventListener('AssinaMe:ready', this.assinaMeTimeoutMethod);
    assinaMeSystemCheck(invoices, ecnpjSignature);
    startSocket(fornecedorId, jointSignature, ecnpjSignature, proposalIds, invoices);
  };

  handleBackButton = () => {
    this.props.removeSocket();
    if (document.referrer.length) {
      window.location = document.referrer;
    } else {
      window.history.back(-1);
    }
  };

  renderHeader = (status, error, afterSign, invoicesDone) => {
    let message = 'Formalizar Operação';
    if (!status && error.message && error.message.length > 0) {
      ({ message } = error);
    } else if (afterSign && !invoicesDone) {
      message = 'Resumo da Operação';
    } else if (invoicesDone) {
      message = 'Operação Concluída';
    }

    return <Header2>{message}</Header2>;
  };

  renderForm = (status, error, invoicesDone) => {
    const {
      partners,
      selectedPartner,
      setSelectedPartner,
      signaturesNeeded,
      certificates,
      selectedCertificate,
      setSelectedCertificate,
      ecnpjSignature,
      jointSignature,
    } = this.props;
    if (!invoicesDone && (status || (!status && error.flow !== 'blocker'))) {
      return (
        <DocumentSignForm
          partners={partners}
          selectedPartner={selectedPartner}
          setSelectedPartner={setSelectedPartner}
          signaturesNeeded={signaturesNeeded}
          certificates={certificates}
          selectedCertificate={selectedCertificate}
          setSelectedCertificate={setSelectedCertificate}
          ecnpjSignature={ecnpjSignature}
          jointSignature={jointSignature}
        />
      );
    }
    return null;
  };

  renderButtons = (status, error, invoicesDone) => {
    const {
      certificates,
      signDocuments,
      selectedCertificate,
      invoices,
      selectedPartner,
      requestIp,
      ecnpjSignature,
    } = this.props;
    const ecnpjEnabled = !(
      checkIsEcnpj(certificates, selectedCertificate) && ecnpjSignature === false
    );
    const SignButton = (
      <div style={{ position: 'relative' }}>
        <BlueButton
          disabled={!selectedCertificate || selectedCertificate.length === 0 || !ecnpjEnabled}
          onClick={() => {
            signDocuments(requestIp, selectedCertificate, invoices, selectedPartner, ecnpjEnabled);
          }}
        >
          Assinar Documentos
        </BlueButton>
        {!ecnpjEnabled ? (
          <TooltipBox
            mount="top"
            content="O certificado selecionado possui eCNPJ e não está habilitado para assinar"
          >
            <DisabledOverlay />
          </TooltipBox>
        ) : null}
      </div>
    );
    if (invoicesDone) {
      return null;
    }
    if (status || error.flow === 'no-error') {
      return (
        <>
          <WhiteButton onClick={this.handleBackButton}>Voltar</WhiteButton>
          {SignButton}
        </>
      );
    }
    if (error.flow === 'blocker') {
      return <BlueButton onClick={this.handleBackButton}>Voltar</BlueButton>;
    }
    if (error.flow === 'non-blocking') {
      return (
        <>
          <WhiteButton onClick={this.handleBackButton}>Voltar</WhiteButton>
          {SignButton}
        </>
      );
    }
    return null;
  };

  render() {
    const {
      invoices,
      loading,
      loadingMessage,
      showPdf,
      pdfUrl,
      hidePdf,
      status,
      error,
      selectedPartner,
      partners,
      afterSign,
      invoicesDone,
      downloadAllDone,
      jointSignature,
      ecnpjSignature,
      signaturesNeeded,
    } = this.props;
    return (
      <>
        <Modal show={pdfUrl.length !== 0} onLeaved={hidePdf}>
          <ModalContainer>
            <ModalContent>
              <PdfContainer data={pdfUrl} type="application/pdf">
                <p>Não Suportado</p>
              </PdfContainer>
            </ModalContent>
            <ModalButtons>
              <WhiteButton onClick={hidePdf}>Fechar</WhiteButton>
            </ModalButtons>
          </ModalContainer>
        </Modal>
        <Modal closable={false} show={loading}>
          <LoadingBox>
            <Header4>{loadingMessage.title}</Header4>
            <BlueBunny loop />
            <InfoMessage>
              <span>{loadingMessage.message}</span>
            </InfoMessage>
            <LinearProgress value={50} />
          </LoadingBox>
        </Modal>
        <Container>
          <div
            ref={el => {
              this.fixed = el;
            }}
          />
          {this.renderHeader(status, error, afterSign, invoicesDone)}
          <Show in={!status}>
            <ErrorHandler status={status} error={error} invoices={invoices} />
          </Show>
          <InvoiceTable
            invoices={invoices}
            showPdf={showPdf}
            status={status}
            error={error}
            selectedPartner={selectedPartner}
            partners={partners}
            afterSign={afterSign}
            signaturesNeeded={signaturesNeeded}
            invoicesDone={invoicesDone}
            onBack={this.handleBackButton}
            downloadAllDone={downloadAllDone}
            jointSignature={jointSignature}
            ecnpjSignature={ecnpjSignature}
          />
          {this.renderForm(status, error, invoicesDone)}
          <Row justifyContent="flex-end">{this.renderButtons(status, error, invoicesDone)}</Row>
        </Container>
      </>
    );
  }
}

MultiSignFornecedorView.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  invoicesDone: PropTypes.bool.isRequired,
  certificates: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    display: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string,
    flow: PropTypes.string,
    supportErrors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        critical: PropTypes.bool,
      }),
    ),
    failedInvoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    type: PropTypes.string,
    label: PropTypes.string,
    toasts: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  jointSignature: PropTypes.bool.isRequired,
  fornecedorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pdfUrl: PropTypes.string.isRequired,
  selectedPartner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedCertificate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loadingMessage: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  downloadAllDone: PropTypes.string.isRequired,
  signaturesNeeded: PropTypes.number.isRequired,
  requestIp: PropTypes.string.isRequired,
  afterSign: PropTypes.bool.isRequired,
  proposalIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  setSelectedCertificate: PropTypes.func.isRequired,
  ecnpjSignature: PropTypes.bool.isRequired,
  showPdf: PropTypes.func.isRequired,
  hidePdf: PropTypes.func.isRequired,
  assinaMeWaitForExtension: PropTypes.func.isRequired,
  assinaMeBrowserSupportErrors: PropTypes.func.isRequired,
  assinaMeSystemCheck: PropTypes.func.isRequired,
  signDocuments: PropTypes.func.isRequired,
  startSocket: PropTypes.func.isRequired,
  removeSocket: PropTypes.func.isRequired,
};

export default MultiSignFornecedorView;
