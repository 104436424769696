import { mdiLock, mdiLockOpen } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext } from 'react';
import StatusTag from '../../../StatusTag';
import { ACTIONS, KIND } from '../../constants';
import { FundersDrawerContext } from '../../context';

import * as S from './styles';

const VendorInfo = () => {
  const {
    vendorInfo,
    handleSelectedFunderToBlockOrUnblock,
    selectedVendorBuyerId,
    selectedVendorId,
    isAdmin,
  } = useContext(FundersDrawerContext);

  const isBlocked = vendorInfo?.blockedByBuyer;

  const statusColor = isBlocked ? 'error' : 'primary';
  const statusLabel = isBlocked ? 'Bloqueado' : 'Ativo';
  const statusIcon = isBlocked ? mdiLockOpen : mdiLock;

  const handleBlockUnblock = () => {
    const payload = {
      id: selectedVendorId,
      name: vendorInfo.tradeName,
      action: isBlocked ? ACTIONS.UNBLOCK : ACTIONS.BLOCK,
      target: {
        id: selectedVendorBuyerId,
        kind: KIND.BUYER,
      },
    };

    handleSelectedFunderToBlockOrUnblock(payload);
  };

  return (
    <S.Content>
      <S.Row>
        <S.Label>Empresa: </S.Label>
        <S.Info>{vendorInfo?.tradeName}</S.Info>
      </S.Row>
      {isAdmin && (
        <S.Row>
          <S.Label>Situação no sacado:</S.Label>
          <S.Info>
            <StatusTag color={statusColor}>{statusLabel}</StatusTag>
          </S.Info>
          <S.BlockButton onClick={handleBlockUnblock}>
            <Icon path={statusIcon} />
          </S.BlockButton>
        </S.Row>
      )}
      <S.Row>
        <S.Label>Investidores disponíveis:</S.Label>
        <S.Info>
          {vendorInfo?.availableFunders?.activeCount}/{vendorInfo?.availableFunders?.totalCount}
        </S.Info>
      </S.Row>
    </S.Content>
  );
};

export default VendorInfo;
