import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchLimits } from '../api';
import { convertToCamelCase, handleError } from '../../../vendor/Utils';

export const LimitsContext = createContext({});

const LimitsStore = ({ token, children }) => {
  const [limits, setLimits] = useState([]);
  const [showLimitsModal, setShowLimitsModal] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState(null);

  const getLimits = () =>
    fetchLimits(token)
      .then(({ data: { data } }) => setLimits(convertToCamelCase(data)))
      .catch(() => handleError('Ocorreu um erro ao requisitar os limites'));

  const onLeaveLimitsModal = () => setShowLimitsModal(false);

  useEffect(() => {
    getLimits();
  }, []);

  return (
    <LimitsContext.Provider
      value={{
        token,
        limits,
        showLimitsModal,
        onLeaveLimitsModal,
        setShowLimitsModal,
        selectedBuyer,
        setSelectedBuyer,
        getLimits,
      }}
    >
      {children}
    </LimitsContext.Provider>
  );
};

LimitsStore.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LimitsStore;
