import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SimulationSelectControl,
  SimulationLinkSelectAllIcon,
  CheckAllIcon,
  SimulationLinkSelectAll,
  SelectedItemsText,
  BroomIcon,
} from './InvoiceSimulationSelectControl.styles';
import { quoteAllInvoices, deselectAllInvoices } from '../../actions/InvoiceSimulationAction';

const selectAllInvoices = (onSelectAllInvoices, token) => () => {
  onSelectAllInvoices(token);
};

const getSingle = count => (count === 1 ? 'foi selecionado' : 'foram selecionados');

export const InvoiceSimulationSelectControl = ({
  onSelectAllInvoices,
  onDeselectAllInvoices,
  onSelectAllInMultiple,
  selectStatus,
  invoicesCount,
  selectedInvoicesCount,
  numberOfPages,
  token,
}) =>
  selectedInvoicesCount > 0 ? (
    <SimulationSelectControl>
      <SelectedItemsText bold={selectedInvoicesCount === invoicesCount}>
        {selectedInvoicesCount === invoicesCount
          ? 'Todos os títulos foram selecionados'
          : `${selectedInvoicesCount} de ${invoicesCount} títulos ${getSingle(
              selectedInvoicesCount,
            )}`}
      </SelectedItemsText>
      {(numberOfPages > 1 && selectStatus === 'single' && (
        <>
          <SimulationLinkSelectAllIcon>
            <CheckAllIcon active />
          </SimulationLinkSelectAllIcon>
          <SimulationLinkSelectAll
            id="select-all"
            onClick={selectAllInvoices(onSelectAllInvoices, token)}
          >
            Selecionar todos os {invoicesCount} títulos
          </SimulationLinkSelectAll>
        </>
      )) ||
        (selectStatus === 'multiple' && selectedInvoicesCount !== invoicesCount && (
          <>
            <SimulationLinkSelectAllIcon>
              <CheckAllIcon active />
            </SimulationLinkSelectAllIcon>
            <SimulationLinkSelectAll id="select-all-multiple" onClick={onSelectAllInMultiple}>
              Selecionar todos os {invoicesCount} títulos
            </SimulationLinkSelectAll>
          </>
        ))}
      <>
        <SimulationLinkSelectAllIcon>
          <BroomIcon active />
        </SimulationLinkSelectAllIcon>
        <SimulationLinkSelectAll id="unselect-all" onClick={onDeselectAllInvoices}>
          Limpar os títulos selecionados
        </SimulationLinkSelectAll>
      </>
    </SimulationSelectControl>
  ) : null;

InvoiceSimulationSelectControl.propTypes = {
  selectStatus: PropTypes.oneOf(['empty', 'single', 'multiple']),
  onSelectAllInvoices: PropTypes.func.isRequired,
  onSelectAllInMultiple: PropTypes.func.isRequired,
  onDeselectAllInvoices: PropTypes.func.isRequired,
  invoicesCount: PropTypes.number,
  selectedInvoicesCount: PropTypes.number,
  numberOfPages: PropTypes.number,
  token: PropTypes.string.isRequired,
};

InvoiceSimulationSelectControl.defaultProps = {
  selectStatus: 'empty',
  invoicesCount: null,
  selectedInvoicesCount: 0,
  numberOfPages: 0,
};

const mapStateToProps = ({ invoiceSimulation }) => ({
  invoicesCount: invoiceSimulation.pagination.count,
  numberOfPages: invoiceSimulation.pagination.pages,
  selectedInvoicesCount: invoiceSimulation.selectedInvoices.length,
  selectStatus: invoiceSimulation.selectStatus,
});

const mapDispatchToProps = {
  onSelectAllInvoices: quoteAllInvoices,
  onDeselectAllInvoices: deselectAllInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSimulationSelectControl);
