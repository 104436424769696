import React from 'react';
import PropTypes from 'prop-types';
import { TooltipBox } from 'liber-components';

import { Container, Value, LabelContainer, Label } from './ValueDisplay.styles';
import { MdHelpCircleStyled } from '../../../InvoiceSimulation/InvoiceSimulation.styles';

const ValueDisplay = ({ label, children, tooltipContent, tooltipMount, valueColor }) => (
  <Container>
    <LabelContainer>
      <Label>{label}</Label>
      {tooltipContent ? (
        <TooltipBox mount={tooltipMount} fixed content={tooltipContent}>
          <MdHelpCircleStyled />
        </TooltipBox>
      ) : null}
    </LabelContainer>
    <Value valueColor={valueColor}>{children}</Value>
  </Container>
);

ValueDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  tooltipContent: PropTypes.node,
  tooltipMount: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  valueColor: PropTypes.string,
};

ValueDisplay.defaultProps = {
  tooltipContent: null,
  tooltipMount: 'top',
  valueColor: null,
};

export default ValueDisplay;
