import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SuccessButton, Badge } from 'liber-components';
import moment from 'moment';
import {
  SimulationContentControl,
  IconContainer,
  FileIcon,
  Container,
  Grid,
  GridContainer,
  ShimmeringField,
} from './SimulationInfo.styles';
import InvoiceSimulationTableTooltipContent from '../../../InvoiceSimulation/InvoiceSimulationTableTooltipContent';
import { formatMoney } from '../../../../../withdraw/moneyHandler';
import ValueDisplay from '../ValueDisplay/ValueDisplay';
import { dispatchDataLayerEvent } from '../../../../../../vendor/Utils';

export const SimulationInfo = ({
  discountRate,
  netValue,
  payUpAt,
  onRequestAnticipation,
  onBeginAnticipation,
  selectedInvoices,
  rateByPeriod,
  faceValue,
  discountValue,
  inDialog,
  children,
  isSimulationOutdated,
  isAdmin,
  vendor,
  operator,
}) => {
  const formattedDiscountRate = (Number(discountRate) * 100).toFixed(4).replace('.', ',');
  const onRequestAnticipationClick = useCallback(() => {
    const params = {
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    };
    onBeginAnticipation();
    dispatchDataLayerEvent('Vendor antecipation checkout: start antecipation', isAdmin, params);
    onRequestAnticipation();
  }, [vendor, operator, isAdmin]);
  return (
    <Container inDialog={inDialog}>
      <Badge
        size={inDialog ? 'large' : 'medium'}
        childShape="circle"
        content={selectedInvoices.length}
      >
        <IconContainer large={inDialog}>
          <FileIcon large={inDialog} />
        </IconContainer>
      </Badge>
      <SimulationContentControl dense={inDialog}>
        <GridContainer spacing={8}>
          <Grid columnSize={inDialog ? 6 : 2.5}>
            <ValueDisplay label="Valor Bruto">R$ {formatMoney(faceValue)}</ValueDisplay>
          </Grid>
          {payUpAt && (
            <Grid columnSize={inDialog ? 6 : 3}>
              <ValueDisplay label="Data Financiamento">
                {isSimulationOutdated ? (
                  <ShimmeringField />
                ) : (
                  <>{moment(payUpAt).format('DD/MM/YYYY')}</>
                )}
              </ValueDisplay>
            </Grid>
          )}
          <Grid columnSize={inDialog ? 6 : 2.2}>
            <ValueDisplay
              label="Taxa média"
              tooltipContent={
                inDialog ? null : (
                  <InvoiceSimulationTableTooltipContent
                    text="A taxa de desconto média é calculada com
            base na média ponderada da taxa de desconto dos títulos selecionados."
                  />
                )
              }
            >
              {isSimulationOutdated ? (
                <ShimmeringField />
              ) : (
                <>
                  {formattedDiscountRate}
                  {rateByPeriod ? '%' : ' %a.m.'}
                </>
              )}
            </ValueDisplay>
          </Grid>
          <Grid columnSize={inDialog ? 6 : 1.7}>
            <ValueDisplay label="Desconto">
              {isSimulationOutdated ? <ShimmeringField /> : <>R$ {formatMoney(discountValue)}</>}
            </ValueDisplay>
          </Grid>
          <Grid columnSize={inDialog ? 6 : 2.1}>
            <ValueDisplay valueColor="#13ce66" label="Valor Líquido">
              {isSimulationOutdated ? <ShimmeringField /> : <>R$ {formatMoney(netValue)}</>}
            </ValueDisplay>
          </Grid>
        </GridContainer>
      </SimulationContentControl>
      {onRequestAnticipation ? (
        <SuccessButton
          size="large"
          version={2}
          onClick={onRequestAnticipationClick}
          disabled={!selectedInvoices.length}
        >
          SOLICITAR ADIANTAMENTO
        </SuccessButton>
      ) : null}
      {children}
    </Container>
  );
};

SimulationInfo.propTypes = {
  discountRate: PropTypes.number,
  netValue: PropTypes.number,
  payUpAt: PropTypes.instanceOf(Date),
  faceValue: PropTypes.number,
  onRequestAnticipation: PropTypes.func,
  onBeginAnticipation: PropTypes.func,
  selectedInvoices: PropTypes.arrayOf(PropTypes.number),
  rateByPeriod: PropTypes.bool,
  discountValue: PropTypes.number,
  inDialog: PropTypes.bool,
  children: PropTypes.node,
  isSimulationOutdated: PropTypes.bool,
  isAdmin: PropTypes.bool,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

SimulationInfo.defaultProps = {
  discountRate: 0,
  discountValue: 0,
  netValue: 0,
  payUpAt: null,
  faceValue: 0,
  onRequestAnticipation: null,
  onBeginAnticipation: null,
  selectedInvoices: [],
  rateByPeriod: false,
  inDialog: false,
  children: null,
  isSimulationOutdated: false,
  isAdmin: false,
  vendor: {},
  operator: {},
};

const mapStateToProps = ({ invoiceSimulation, vendorAdvances }) => ({
  discountRate: invoiceSimulation.quote.discount_rate,
  discountValue: invoiceSimulation.quote.discount_value,
  netValue: invoiceSimulation.quote.net_value,
  payUpAt: invoiceSimulation.quote.pay_up_at,
  selectedInvoices: invoiceSimulation.selectedInvoices,
  rateByPeriod: invoiceSimulation.rateByPeriod,
  faceValue: invoiceSimulation.quote.face_value,
  isAdmin: vendorAdvances.isAdmin,
  vendor: vendorAdvances.vendor,
  operator: vendorAdvances.operator,
});

export default connect(mapStateToProps)(SimulationInfo);
