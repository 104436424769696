import styled from 'styled-components';
import { Button, SelectField } from 'liber-components';
import Icon from '@mdi/react';

export const DropDownContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DropDownContentRow = styled.a`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 4px;
  margin: 4px 8px 4px 8px;
  color: inherit;
  cursor: pointer;
  & > svg {
    margin-right: 8px;
    margin-top: 4px;
  }
  :hover {
    color: #009dff;
    & > svg {
      fill: #009dff;
    }
  }
`;

export const DropDownButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 8px;
  background-color: #ecf0f3;
`;

export const DropDownContentRowIcon = styled.div`
  margin-right: 8px;
`;

export const DescriptionIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  color: #6287a7;
`;

export const DropDownContentText = styled.div`
  font-weight: 500;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: #6287a7;
  cursor: pointer;
`;

export const DocumentText = styled.div`
  margin: 10px 0 10px 0;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: #6287a7;
  width: 408px;
`;

export const CheckboxLabel = styled.div`
  color: #6287a7;
  font-size: 16px;
`;
export const ContainerRelative = styled.div`
  position: relative;
`;
export const SelectBoxContainer = styled.div`
  width: 100%;
  padding: 2px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  background-color: #fff;
  box-sizing: border-box;
`;

export const SelectBox = styled(SelectField).attrs(() => ({ version: 2 }))`
  width: 100%;
  font-size: 9px;
  color: #6287a7;
  font-family: Roboto;
  input {
    width: 310px;
  }
`;

export const Options = styled.option`
  color: #6287a7;
  cursor: pointer;
  font-size: 9px;
`;
export const TooltipContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  box-sizing: border-box;
`;
export const CnabContainer = styled.div`
  display: ${props => (props.visible !== 1 ? 'none' : 'flex')};
  flex-direction: column;
`;
export const DocumentContainer = styled.div``;

export const WanterText = styled.div`
  color: red;
  font-size: 14px;
  display: ${props => (props.visible !== 1 ? 'flex' : 'none')};
`;
