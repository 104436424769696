import Immutable from 'seamless-immutable';

const initialState = Immutable({
  analise: '',
  cnh: {
    emission_date: '',
    emission_issuer: '',
    emission_state: '',
    number: '',
  },
  rg: {
    emission_date: '',
    emission_issuer: '',
    emission_state: '',
    number: '',
  },
  root: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};
