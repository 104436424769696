export const getKind = state => state.documentacao.kind || '';
export const getNumber = state => state.documentacao.number || '';
export const getEmissionDate = state => state.documentacao.emission_date || '';
export const getEmissionIssuer = state => state.documentacao.emission_issuer || '';
export const getEmissionState = state => state.documentacao.emission_state || '';
export const getMotherName = state => state.documentacao.mother_name || '';
export const getFatherName = state => state.documentacao.father_name || '';
export const getPep = state => Boolean(state.documentacao.pep);
export const getIsPatch = state => Boolean(state.documentacao.is_patch);
export const getErrors = state => state.documentacao.errors || {};
export const getDocumentacao = state => ({
  kind: getKind(state),
  number: getNumber(state),
  emissionDate: getEmissionDate(state),
  emissionIssuer: getEmissionIssuer(state),
  emissionState: getEmissionState(state),
  motherName: getMotherName(state),
  fatherName: getFatherName(state),
  pep: getPep(state),
  isPatch: getIsPatch(state),
  errors: getErrors(state),
});
