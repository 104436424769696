import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  summary: {
    from: '',
    to: '',
    opening_balance: 0,
    closing_balance: 0,
    credits: {
      amount: 0,
      subtotals: [],
    },
    debits: {
      amount: 0,
      subtotals: [],
    },
  },
  statement: [],
});

// const initialState = Immutable({
//   loading: true,
//   summary: {
//     from: '2018-10-02',
//     to: '2018-10-06',
//     opening_balance: 300.0,
//     closing_balance: 352.5,
//     credits: {
//       amount: 360.5,
//       subtotals: [
//         {
//           _name: 'deposit',
//           name: 'depósitos',
//           amount: 150.5,
//         },
//         {
//           _name: 'receipt',
//           name: 'recebimentos',
//           amount: 210.0,
//         },
//       ],
//     },
//     debits: {
//       amount: 308.0,
//       subtotals: [
//         {
//           _name: 'purchase',
//           name: 'compras',
//           amount: 100.0,
//         },
//         {
//           _name: 'fee',
//           name: 'emolumentos',
//           amount: 8.0,
//         },
//         {
//           _name: 'withdraw',
//           name: 'retiradas',
//           amount: 200.0,
//         },
//       ],
//     },
//   },
//   statement: [
//     {
//       year: '2018',
//       month: 'Outubro',
//       from: '2018-10-02',
//       to: '2018-10-06',
//       opening_balance: 300.0,
//       closing_balance: 352.5,
//       days: {
//         '03/10': {
//           entries: [
//             {
//               type: 'credit',
//               _name: 'deposit',
//               name: 'depósito',
//               amount: 150.50,
//             },
//             {
//               type: 'debit',
//               _name: 'purchase',
//               name: 'compra',
//               amount: 100.00,
//               invoices: {
//                 Dafiti: [
//                   {
//                     number: '7189-2',
//                     payer: 'Dafiti', amount: 110.00, price: 100.0, fee: 4.5, due_date: '2018-12-22',
//                   },
//                 ],
//               },
//             },
//           ],
//           balance: 350.5,
//         },
//         '05/10': {
//           entries: [
//             {
//               type: 'credit',
//               _name: 'receipt',
//               name: 'recebimentos',
//               amount: 210.00,
//               invoices: {
//                 Alliage: [
//                   {
//                     number: '8625/1', payer: 'Alliage',
//                     amount: 130.00, price: 115.0, fee: 6.0, due_date: '2018-10-05',
//                   },
//                   {
//                     number: '8626/1', payer: 'Alliage', amount: 80.0, price: 75.0, fee: 2.0, due_date: '2018-10-05',
//                   },
//                 ],
//               },
//             },
//             {
//               type: 'debit',
//               _name: 'fee',
//               name: 'emolumentos',
//               amount: 8.00,
//             },
//             {
//               type: 'debit',
//               _name: 'withdraw',
//               name: 'retirada',
//               amount: 200.00,
//             },
//             {
//               type: 'debit',
//               _name: 'other',
//               name: 'Outra',
//               amount: 200.00,
//             },
//           ],
//           balance: 352.5,
//         },
//       },
//     },
//   ],
// });

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'STATEMENT_PENDING':
      return state.merge({ loading: true });
    case 'STATEMENT_SUCCESS':
      return state.merge({ loading: false, ...action.payload.data.data }, { deep: true });
    case 'STATEMENT_REJECTED':
      return initialState;
    default:
      return state;
  }
};
