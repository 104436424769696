export const apiUrl = SERVICE_API_URL; // eslint-disable-line

const VENDORS = '/api/v1/admin/vendors';

export const GET_VENDOR_URL = `${apiUrl}/api/v1/fornecedor`;
export const VENDOR_ANTICIPATION_SHOW_URL = `${apiUrl}/api/v1/fornecedor/anticipations/:id`;
export const ADMIN_VENDOR_ANTICIPATION_SHOW_URL = `${apiUrl}${VENDORS}/:id/anticipations/:anticipation_id`;
export const VENDOR_BORDERO_SHOW_URL = `${apiUrl}/api/v1/fornecedor/adiantamentos`;
export const CANCEL_PAGE_URL = `/fornecedor/antecipacoes/cancel`;
export const ADMIN_VENDOR_BORDERO_SHOW_URL = `${apiUrl}${VENDORS}/:id/anticipations/:bordero_id`;
export const ADMIN_VENDOR_BORDERO_INVOICES_URL = `${apiUrl}${VENDORS}/:id/anticipations/:bordero_id/invoices`;
export const VENDOR_ANTICIPATION_INVOICES_URL = `${apiUrl}/api/v1/fornecedor/anticipations/:anticipation_id/invoices`;
