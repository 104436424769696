import React from 'react';
import { Text } from './components/AnticipationCheckout.styles';
import Summary from './components/Summary/Summary';
import BankAccount from './components/BankAccount/BankAccount';
import AssignmentTerm from './components/AssignmentTerm/AssignmentTerm';
import Sign from './components/Sign/Sign';

export const STEPS = [
  {
    title: 'Solicitar antecipação',
    name: 'Resumo',
    description: () => (
      <Text>
        Você escolheu antecipar todos os títulos disponíveis. <br />
        Verifique o resumo da sua antecipação antes de prosseguir.
      </Text>
    ),
    content: Summary,
  },
  {
    title: 'Solicitar antecipação',
    name: 'Conta Bancária',
    description: (_a, _b, _c, displayBankAccountsCreationInstructions) => {
      if (displayBankAccountsCreationInstructions) {
        return (
          <Text>
            Confirme se a conta bancaria selecionada está correta. <br />
            Caso seja necessário fazer alguma alteração favor entrar em contato com a Liber. <br />
            Mais informações em
            <a href="/fornecedor/conta_bancarias">
              &nbsp;
              <u>Contas Bancárias</u>
            </a>
          </Text>
        );
      }
      return <Text>Selecione uma conta bancária para recebimento.</Text>;
    },
    content: BankAccount,
  },
  {
    title: 'Solicitar antecipação',
    name: 'Termo de Cessão',
    description: (hasDocumentGenerated, hasFailed) => {
      if (hasFailed) {
        return (
          <Text>
            Ocorreu um erro com o processamento da sua antecipação. <br />
            Repita ou suspenda o processo para corrigi-lo agora ou mais tarde.
          </Text>
        );
      }
      if (hasDocumentGenerated) {
        return (
          <Text>
            Veja o termo de cessão gerado para a sua antecipação. <br />
            Ele será assinado na etapa seguinte para confirmar a sua operação.
          </Text>
        );
      }
      return (
        <Text>
          Aguarde enquanto registramos o seu pedido de antecipação. <br />
          Este processo pode levar alguns minutos.
        </Text>
      );
    },
    content: AssignmentTerm,
  },
  {
    title: 'Solicitar antecipação',
    name: 'Assinatura',
    description: (_a, _b, vendorOtpRequired) => {
      let message = 'Para realizar a sua assinatura, utilize a sua senha do portal Liber.';

      if (vendorOtpRequired) {
        message = 'Para realizar a sua assinatura, utilize a sua senha do portal Liber e token.';
      }

      return (
        <Text>
          Assine o termo da etapa anterior para confirmar a sua antecipação. <br />
          {message}
        </Text>
      );
    },
    content: Sign,
  },
];

export const getSteps = (showSummary, wasDocumentGenerated) => {
  if (showSummary) return STEPS;
  if (wasDocumentGenerated) return STEPS.slice(2, 4);
  return STEPS.slice(1, 4);
};
