import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonsRow, SecondaryButton, PrimaryButton } from '../AnticipationCheckout.styles';
import { Container, FieldContainer, TextField, Caption } from './Sign.styles';
import { signDocuments, fetchAnticipation } from '../../actions/AnticipationCheckoutActions';
import LoadingButton from '../../../../common-components/Buttons/LoadingButton';

export const Sign = ({
  handleBack,
  handleNext,
  inputsError,
  onSignDocuments,
  buttonLoading,
  hasUserSigned,
  getAnticipations,
  hasAnticipations,
  vendorOtpRequired,
  isAdmin,
  vendor,
  operator,
}) => {
  const [pin, setPin] = React.useState('');
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    if (hasUserSigned) {
      handleNext();
    }
  }, [hasUserSigned]);

  React.useEffect(() => {
    if (!hasAnticipations) {
      getAnticipations();
    }
  }, []);

  const handleChangeToken = value => setToken(value);

  const gaParams = useMemo(
    () => ({
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    }),
    [isAdmin, vendor, operator],
  );

  const goToBackStage = useCallback(() => {
    handleBack();
  }, [isAdmin, gaParams]);

  const goToNextStage = useCallback(() => {
    onSignDocuments(pin, token);
  }, [isAdmin, gaParams, pin, token]);

  const errorMsg = vendorOtpRequired
    ? 'A senha ou o token inseridos são inválidos'
    : 'A senha inserida está inválida';

  return (
    <Container>
      <FieldContainer>
        <TextField
          id="pin_attempt"
          version={2}
          label="Senha"
          value={pin}
          onChange={setPin}
          type="password"
          errorMessage={inputsError ? errorMsg : null}
          showPasswordToggle
        />
      </FieldContainer>
      {vendorOtpRequired && (
        <TextField
          id="otp_attempt"
          version={2}
          errorMessage={inputsError ? 'A senha ou o token inseridos são inválidos' : null}
          label="Token"
          value={token}
          onChange={handleChangeToken}
        />
      )}
      <ButtonsRow>
        <SecondaryButton onClick={goToBackStage}>VOLTAR</SecondaryButton>
        <LoadingButton
          ButtonComponent={PrimaryButton}
          onClick={goToNextStage}
          loading={buttonLoading}
          size="large"
        >
          CONTINUAR
        </LoadingButton>
      </ButtonsRow>
      <Caption>
        Ao clicar em <b>CONTINUAR</b>, você manifestará sua aceitação do teor do(s) termo(s) de
        cessão gerado(s) e realizará uma assinatura eletrônica, nos termos do art. 10, §2º da MP
        2.200/01, que será permanentemente incorporada ao documento, com registro de suas
        informações de conta e de acesso.
      </Caption>
    </Container>
  );
};

Sign.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  inputsError: PropTypes.bool,
  onSignDocuments: PropTypes.func,
  buttonLoading: PropTypes.bool,
  hasUserSigned: PropTypes.bool,
  getAnticipations: PropTypes.func,
  hasAnticipations: PropTypes.bool,
  vendorOtpRequired: PropTypes.bool,
  isAdmin: PropTypes.bool,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

Sign.defaultProps = {
  handleBack: () => null,
  handleNext: () => null,
  inputsError: false,
  onSignDocuments: () => null,
  buttonLoading: false,
  hasUserSigned: false,
  getAnticipations: () => null,
  hasAnticipations: false,
  vendorOtpRequired: false,
  isAdmin: false,
  vendor: {},
  operator: {},
};

const mapStateToProps = ({
  anticipationCheckout: {
    vendorOtpRequired,
    inputsError,
    buttonLoading,
    hasUserSigned,
    anticipation,
  },
  vendorAdvances: { isAdmin, vendor, operator },
}) => ({
  vendorOtpRequired,
  inputsError,
  buttonLoading,
  hasUserSigned,
  hasAnticipations: !!anticipation,
  isAdmin,
  vendor,
  operator,
});

const mapDispatchToProps = { onSignDocuments: signDocuments, getAnticipations: fetchAnticipation };

export default connect(mapStateToProps, mapDispatchToProps)(Sign);
