import axios from 'axios';
import { SHEETS_LIST_URL } from './constants';

export async function getSpreadsheetData(spreadsheetId, token = '') {
  return axios.get(`${SHEETS_LIST_URL}/${spreadsheetId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function fetchSpreadsheets(token = '', activeRates, params = {}) {
  return axios.get(SHEETS_LIST_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...params,
      active: activeRates,
    },
  });
}

export async function getSpreadsheetBuyers(spreadsheetId, token = '', params) {
  return axios.get(`${SHEETS_LIST_URL}/${spreadsheetId}/buyers`, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
}
