export const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const VENDORS_PATH = '/sacado/fornecedores';
export const VENDORS_GROUPS_PATH = '/sacado/fornecedores/grupos';
export const VENDORS_GROUP_HISTORY_PATH = '/sacado/fornecedores/grupos/:id/historico';
export const EDIT_VENDORS_GROUP_PATH = '/sacado/fornecedores/grupos/:id/edit';
// eslint-disable-next-line max-len
export const BUYER_GROUP_VENDORS_LIST_URL = `${apiUrl}/api/v1/buyer/vendors?q[vendors_group_id_eq]=:id`;
export const INVESTOR_GROUP_VENDORS_LIST_URL = `${apiUrl}/api/v1/investidor/vendors_groups/vendors/:id`;

export const VENDOR_NAME_FILTER = 'q[company_or_trade_name_cont]';
export const VENDOR_EMAIL_FILTER = 'q[email_cont]';
export const VENDOR_CNPJ_FILTER = 'q[cnpj_cont]';

export const SORTING_DIALOG_COLUMNS = [
  { label: 'Nome', value: 'tradeName' },
  { label: 'CNPJ', value: 'cnpj' },
];

export const EDIT_OP_MODE_URL_PARAM = 'is_editing_op_mode';
