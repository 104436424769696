import axios from 'axios';
import { myWallet } from '../url';

export function getMyWalletData(token) {
  return {
    type: 'INVESTOR_DASHBOARD_GET_MY_WALLET_DATA',
    payload: axios.get(myWallet, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  };
}

export const empty = null;
