import styled, { css } from 'styled-components';
import { Button as RawButton, FlatButton, handleThemeFromObject } from 'liber-components';

const getTheme = (key, fallback) =>
  css`
    ${({ theme }) => handleThemeFromObject(theme, key, fallback)}
  `;

const PRIMARY_TEXT_COLOR = '#4b6f85';

const getTextStyle = () => css`
  font-family: Roboto;
  color: ${PRIMARY_TEXT_COLOR};
`;

export const ModalHeader = styled.div`
  ${getTextStyle()}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${getTheme('colors.darkBorder', '#ecf0f3')};
`;

export const ModalTitle = styled.span`
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.08px;
  text-align: left;
`;

export const ModalContent = styled.div`
  ${getTextStyle()}
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 488px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid ${getTheme('colors.darkBorder', '#ecf0f3')};

  & > button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const PrimaryButton = styled(FlatButton)`
  text-transform: uppercase;
`;

export const Button = styled(RawButton).attrs(() => ({ version: 2 }))`
  text-transform: uppercase;
`;
