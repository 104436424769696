import { PrimaryButton, SelectField } from 'liber-components';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1100px;
  height: 710px;
`;

export const Header = styled.div`
  height: 70px;
  min-height: 50px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  margin-left: 16px;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: left;
  margin-left: 10px;
  color: #009dff;
`;

export const Body = styled.div`
  height: 570px;
  max-height: 570px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectFields = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
`;

export const SelectInput = styled(SelectField).attrs(() => ({
  version: 2,
}))`
  margin-top: 10px;
`;

export const Table = styled.div`
  width: 96%;
  height: 500px;
`;

export const Footer = styled.div`
  height: 70px;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  margin-left: 16px;
`;

export const FooterButton = styled(PrimaryButton).attrs(() => ({
  version: 2,
}))`
  margin-left: 10px;
`;
