import moment from 'moment';

export function getDateIntervalByDays(days) {
  const endDate = moment().format('YYYY-MM-DD');
  const startDate = moment().subtract(days, 'days').format('YYYY-MM-DD');
  return { startDate, endDate };
}

export function getDateIntervalByDate(date) {
  const endDate = moment().format('YYYY-MM-DD');
  const startDate = moment(date).format('YYYY-MM-DD');
  return { startDate, endDate };
}

export function getFilterMinDate() {
  const MIN_TIME_IN_MONTHS = 3;
  return moment().subtract(MIN_TIME_IN_MONTHS, 'months').format('YYYY-MM-DD');
}
