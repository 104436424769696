import React, { useState, useMemo, useRef } from 'react';
import { Card, Typography, TextField, Button } from 'liber-salti';
import ReactOnRails from 'react-on-rails';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import LoginFooter from '../../../../common-components/LoginFooter';
import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';

import { FUNDER_PASSWORD_PATH, FUNDER_LOGIN_PATH } from '../../paths';

import {
  Container,
  Content,
  CardContent,
  Logo,
  LogoWrapper,
  ActionsWrapper,
  InputsWrapper,
  HiddenForm,
  DescriptionWrapper,
} from '../../styles';

const FunderRecoverPassword = ({ alerts, email: contextEmail }) => {
  const formRef = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const [email, setEmail] = useState(contextEmail);

  const csrfToken = ReactOnRails.authenticityToken();

  const fieldsSize = useMemo(() => (isMobile ? 'large' : 'medium'), [isMobile]);

  const errorMessage = useMemo(() => {
    if (!(Array.isArray(alerts) && alerts?.length > 0)) return undefined;

    const [alert] = alerts;
    const [severity, message] = alert;

    if (severity === 'error') return message;
    return undefined;
  }, [alerts]);

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const handleReturn = () => {
    window.location.assign(FUNDER_LOGIN_PATH);
  };

  return (
    <Container>
      <Content>
        <Card>
          <CardContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography variant="h2" color="textPrimary">
              Esqueceu a senha?
            </Typography>
            <DescriptionWrapper>
              <Typography variant="body1" color="textSecondary">
                Sem problemas! Informe seu e-mail abaixo e nós enviaremos instruções para alteração
                da senha.
              </Typography>
            </DescriptionWrapper>
            <InputsWrapper>
              <TextField
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Email"
                required
                fullWidth
                maskOption="email"
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                size={fieldsSize}
                data-testid="EmailInput"
              />
            </InputsWrapper>
            <ActionsWrapper>
              <Button
                onClick={() => handleSubmit()}
                disabled={!(email?.length > 0)}
                size="large"
                fullWidth
                data-testid="SubmitButton"
              >
                RECUPERAR SENHA
              </Button>
              <Button onClick={() => handleReturn()} size="large" variant="text" fullWidth>
                VOLTAR PARA LOGIN
              </Button>
            </ActionsWrapper>
          </CardContent>
        </Card>
      </Content>
      <LoginFooter />
      <HiddenForm data-testid="Form" ref={formRef} method="POST" action={FUNDER_PASSWORD_PATH}>
        <input value={email} name="investidor[email]" readOnly />
        <input value={csrfToken} name="authenticity_token" readOnly />
        <input type="submit" />
      </HiddenForm>
    </Container>
  );
};

const View = ({ recovery: { alerts, email } }) => (
  <SaltiThemeWrapper>
    <FunderRecoverPassword alerts={alerts} email={email} />
  </SaltiThemeWrapper>
);

FunderRecoverPassword.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  email: PropTypes.string,
};

FunderRecoverPassword.defaultProps = {
  alerts: [],
  email: '',
};

View.propTypes = {
  recovery: {
    alerts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    email: PropTypes.string,
  },
};

View.defaultProps = {
  recovery: {
    alerts: [],
    email: '',
  },
};

export default View;
