import styled, { css } from 'styled-components';
import { handleThemeFromObject } from 'liber-components';

const FONT_FAMILY = 'Roboto';
const BORDER_COLOR = 'rgba(35, 39, 47, 0.3)';
const TITLE_COLOR = 'rgba(35, 39, 47, 0.87)';
const TEXT_COLOR = 'rgba(35, 39, 47, 0.64)';

const getTheme = (key, fallback) =>
  css`
    ${({ theme }) => handleThemeFromObject(theme, key, fallback)}
  `;

const getFont = () =>
  css`
    font-family: ${getTheme('fontFamily', FONT_FAMILY)};
  `;

const Flex = styled.div`
  display: flex;

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

    ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb};
    `}
`;

export const Image = styled.img.attrs(() => ({ alt: '' }))`
  width: 100%;
  height: auto;
  max-height: 100%;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 4px;
  border: solid 1px ${getTheme('colors.border', BORDER_COLOR)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  margin-bottom: 32px;
  padding: 14px;
`;

export const CompanyName = styled.div`
  ${getFont()}
  margin: 10px 0px;
  font-size: 20px;
  font-weight: bold;
  line-height: 0.85;
  letter-spacing: 0.08px;
  color: ${getTheme('colors.darkText', TITLE_COLOR)};
`;

export const CompanyInfo = styled.div`
  ${getFont()}
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: 0.06px;
  color: ${getTheme('colors.darkText70', TEXT_COLOR)};
`;
