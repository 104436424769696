import React from 'react';
import PropTypes from 'prop-types';
import WarningCard from './components/WarningCard';
import { Global } from './components/styles';

const View = ({ liberAccounts, investidorPf }) => (
  <>
    <Global />
    <WarningCard
      title="Solicitação de cadastro enviada"
      paragraph1="Sua Solicitação de Cadastro foi enviada com sucesso! Após avaliação do nosso time de suporte,
      enviaremos um email informando que seu cadastro está liberado."
      paragraph2="Lembramos que é necessário cadastrar a Liber capital como Conta Recebedora de Crédito e ajustar o
      Limite de Transferência da sua conta bancária, de forma a facilitar operações futuras."
      accounts={liberAccounts}
      investidorPf={investidorPf}
    />
  </>
);

View.propTypes = {
  liberAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  investidorPf: PropTypes.bool.isRequired,
};

export default View;
