import React from 'react';
import PropTypes from 'prop-types';
import { SpreadsheetRatesContextProvider } from './context';
import Table from './components/Table';

const SpreadsheetRatesTable = ({ token, activeRates }) => (
  <SpreadsheetRatesContextProvider token={token} activeRates={activeRates}>
    <Table />
  </SpreadsheetRatesContextProvider>
);

SpreadsheetRatesTable.propTypes = {
  token: PropTypes.string.isRequired,
  activeRates: PropTypes.bool.isRequired,
};

export default SpreadsheetRatesTable;
