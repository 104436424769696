import Immutable from 'seamless-immutable';

const initialState = Immutable({
  name: '',
  cnpj: '',
  email: '',
  cellphone: '',
  phone: '',
  gender: '',
  birthdate: '',
  civilStatus: '',
  countryOrigin: 'BR',
  stateOrigin: '',
  cityOrigin: '',
  tradeName: '',
  company: '',
  errors: {},
  loading: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_HIDRATE_FORM':
      return state.merge(action.payload);
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_CLOSE_MODAL_FORM':
      return initialState;
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_PENDING':
      return state.merge({ loading: true });
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_SUCCESS':
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_ERROR':
      return state.merge({ loading: false });
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_EDIT_FIELD':
      return state.merge(action.payload);
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_VALIDATION_ERROR':
      return state.merge({
        errors: action.payload,
      });
    default:
      return state;
  }
};
