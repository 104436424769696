import { connect } from 'react-redux';
import { redirectTo } from '../../../actions/wizard';
import { getAnalise, getRoot } from '../../../presenters/paths';
import SolicitacaoEnviada from './SolicitacaoEnviada';

const mapStateToProps = state => ({
  analise: getAnalise(state),
  root: getRoot(state),
});

const mapDispatchToProps = () => ({
  redirectTo,
});

export default connect(mapStateToProps, mapDispatchToProps)(SolicitacaoEnviada);
