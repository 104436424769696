import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer, Liber } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import hydrateStore from '../store';
import MyAccount from './components/MyAccount';

const View = ({ store, userType, acceptedAdhesionTermExternally }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <ThemeProvider theme={Liber}>
        <MyAccount
          userType={userType}
          acceptedAdhesionTermExternally={acceptedAdhesionTermExternally}
        />
      </ThemeProvider>
    </Provider>
    <ToastContainer />
  </>
);

View.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  userType: PropTypes.string.isRequired,
  acceptedAdhesionTermExternally: PropTypes.bool,
};

View.defaultProps = {
  acceptedAdhesionTermExternally: false,
};

export default View;
