import styled, { css } from 'styled-components';
import { handleThemeFromObject } from 'liber-components';
import Icon from '@mdi/react';
import { mdiPlusCircleOutline, mdiMinusCircleOutline, mdiSwapHorizontal } from '@mdi/js';

const getTheme = (key, fallback) =>
  css`
    ${({ theme }) => handleThemeFromObject(theme, key, fallback)}
  `;

export const Container = styled.div`
  background-color: #405f7108;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  width: 100%;
  margin-bottom: 32px;
  &::before {
    content: '';
    position: absolute;
    left: -42px;
    margin-top: 8px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #009dff;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #405f71;
`;

export const Caption = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #6287a7;
  margin-right: 20px;
`;

export const Description = styled.div`
  display: inline-block;
  line-height: 28px;
  margin: 8px 0;
`;

export const PlusIcon = styled(Icon).attrs(() => ({ path: mdiPlusCircleOutline, size: '20' }))`
  color: #13ce66;
  margin-right: 8px;
`;

export const MinusIcon = styled(Icon).attrs(() => ({ path: mdiMinusCircleOutline, size: '20' }))`
  color: #dc3545;
  margin-right: 8px;
`;

export const NameText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #6287a7;
  margin-right: 20px;
  float: left;
`;

export const AmountContainer = styled.span`
  float: left;
`;

export const TargetingModeContent = styled.div`
  margin: 8px 0 8px 24px;
`;

export const MutedText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #6287a7;
`;

export const SwapIcon = styled(Icon).attrs(() => ({ path: mdiSwapHorizontal, size: '20' }))`
  color: ${getTheme('colors.liber', '#009dff')};
  margin-right: 8px;
`;
