import { connect } from 'react-redux';
import AccumulatedYield from './AccumulatedYield';
import { getYieldData } from '../../../actions/YieldActions';

const reducer = 'yield';
const parentReducer = 'investorDashboard';

const mapStateToProps = state => ({ ...state[parentReducer][reducer] });
const mapDispatchToProps = {
  getYieldData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccumulatedYield);
