import Immutable from 'seamless-immutable';

const initialState = Immutable({
  cpf: '',
  cpfError: '',
  password: '',
  passwordError: '',
  token: '',
  tokenError: '',
  remember: false,
  path: '',
  flashes: [],
  passwordPath: '',
  fingerprint: '',
  components: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'ADMIN_CHANGE_CPF':
      return state.merge({ cpf: action.payload, cpfError: '' });
    case 'ADMIN_CHANGE_CPF_ERROR':
      return state.merge({ cpf: action.payload.cpf, cpfError: action.payload.error });
    case 'ADMIN_CHANGE_PASSWORD':
      return state.merge({ password: action.payload, passwordError: '' });
    case 'ADMIN_CHANGE_PASSWORD_ERROR':
      return state.merge({
        password: action.payload.password,
        passwordError: action.payload.error,
      });
    case 'ADMIN_CHANGE_TOKEN':
      return state.merge({ token: action.payload, tokenError: '' });
    case 'ADMIN_CHANGE_TOKEN_ERROR':
      return state.merge({ token: action.payload.token, tokenError: action.payload.error });
    case 'ADMIN_SUCESSFULL_LOGIN':
      return state;
    case 'ADMIN_FAILED_LOGIN':
      return state.merge({
        cpfError: action.payload.cpfError,
        passwordError: action.payload.passwordError,
        tokenError: action.payload.tokenError,
      });
    case 'ADMIN_TOOGLE_REMEMBER':
      return state.merge({ remember: !state.remember });
    case 'ADMIN_SET_FINGERPRINT':
      return state.merge({
        fingerprint: action.payload.fingerprint,
        components: JSON.stringify(action.payload.components),
      });
    default:
      return state;
  }
};
