import React, { useEffect, useState, useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TokenContext } from '../../hocs/withTokenProvider';
import {
  convertToCamelCase,
  dispatchAmplitudeEvent,
  handleError,
  handleNavigate,
} from '../../vendor/Utils';
import {
  fetchVendors,
  fetchGroupVendors,
  createGroup,
  editGroup,
  fetchGroup,
  fetchFunders,
  fetchBuyerUser,
} from './api';
import {
  VENDORS_GROUPS_PATH,
  MAX_VENDOR_AMOUNT,
  DEFAULT_GROUP_NAME,
  DEFAULT_FUNDER_TARGETING_MODE,
  VENDORS_GROUPS_SHOW_PATH,
} from './constants';
import { removeDuplicates, handleCreationError } from './utils';
import { EDIT_OP_MODE_URL_PARAM } from '../vendors-group-details/constants';

const CreateVendorsGroupContext = createContext({});

const CreateVendorsGroupStore = ({ groupId, children }) => {
  const { token } = useContext(TokenContext);

  const isEditing = groupId != null;

  const [groupLoading, setGroupLoading] = useState(Boolean(groupId));

  const [name, setName] = useState('');
  const [originalName, setOriginalName] = useState('');
  const [reference, setReference] = useState('');
  const [funderTargetingMode, setFunderTargetingMode] = useState(DEFAULT_FUNDER_TARGETING_MODE);
  const [selectedFunderId, setSelectedFunderId] = useState();
  const [originalFunderTargetingMode, setOriginalFunderTargetingMode] = useState(
    DEFAULT_FUNDER_TARGETING_MODE,
  );
  const [originalSelectedFunderId, setOriginalSelectedFunderId] = useState();

  const [funders, setFunders] = useState([]);

  const [vendorsCount, setVendorsCount] = useState(0);
  const [groupVendorsCount, setGroupVendorsCount] = useState(0);

  const [apiBuyerVendors, setApiBuyerVendors] = useState([]);
  const [apiGroupVendors, setApiGroupVendors] = useState([]);

  const [buyerVendors, setBuyerVendors] = useState([]);
  const [selectedBuyerVendors, setSelectedBuyerVendors] = useState([]);

  const [originalGroupVendors, setOriginalGroupVendors] = useState([]);
  const [groupVendors, setGroupVendors] = useState([]);
  const [selectedGroupVendors, setSelectedGroupVendors] = useState([]);

  const [alertModal, setAlertModal] = useState(false);
  const [movedVendors, setMovedVendors] = useState(null);

  const [noReference, setNoReference] = useState(false);
  const [acknowledgedChange, setAcknowledgedChange] = useState(false);

  const [referenceError, setReferenceError] = useState('');

  const [firstLoadingBuyer, setFirstLoadingBuyer] = useState(true);
  const [firstLoadingGroup, setFirstLoadingGroup] = useState(false);

  const [loadingBuyerVendors, setLoadingBuyerVendors] = useState(true);
  const [loadingGroupVendors, setLoadingGroupVendors] = useState(isEditing);

  const [rebateIncrement, setRebateIncrement] = useState('');
  const [allowRebateIncrement, setAllowRebateIncrement] = useState(false);

  const [loadingAction, setLoadingAction] = useState(false);

  const [vendorsIdsToAdd, setVendorsIdsToAdd] = useState([]);
  const [vendorsIdsToRemove, setVendorsIdsToRemove] = useState([]);

  const [isOnlyEditingOpMode, setIsOnlyEditingOpMode] = useState();

  const handleChangeReference = val => {
    const cleanedVal = val.replace(/[^a-zA-Z0-9_-]/g, '');
    setReference(cleanedVal);
  };

  const handleFetchBuyerUser = () => {
    fetchBuyerUser(token)
      .then(response => {
        const { data } = convertToCamelCase(response.data);
        setAllowRebateIncrement(data.allowRebateIncrement);
      })
      .catch(() => {
        handleError();
      });
  };

  const handleFetchGroup = () => {
    fetchGroup(token, groupId)
      .then(response => {
        const { data } = convertToCamelCase(response.data);
        setName(data.name);
        setOriginalName(data.name);
        setReference(data.referenceCode);
        setFunderTargetingMode(data.funderTargetingMode);
        setSelectedFunderId(data.funderId);
        setOriginalFunderTargetingMode(data.funderTargetingMode);
        setOriginalSelectedFunderId(data.funderId);
      })
      .catch(() => {
        handleError();
      })
      .finally(() => setGroupLoading(false));
  };

  const handleFetchFunders = () => {
    fetchFunders(token)
      .then(response => {
        const { data } = convertToCamelCase(response.data);
        setFunders(data);
      })
      .catch(() => {
        handleError('Falha ao carregar os investidores do convênio!');
      });
  };

  useEffect(() => {
    const addVendorsIds = [];
    const removeVendorsIds = [];
    groupVendors.forEach(({ id }) => {
      if (!originalGroupVendors.find(vendor => vendor.id === id)) {
        addVendorsIds.push(id);
      }
    });
    originalGroupVendors.forEach(({ id }) => {
      if (!groupVendors.find(vendor => vendor.id === id)) {
        removeVendorsIds.push(id);
      }
    });
    setVendorsIdsToAdd(addVendorsIds);
    setVendorsIdsToRemove(removeVendorsIds);
  }, [groupVendors]);

  const hasChanges = useMemo(
    () =>
      originalName !== name ||
      vendorsIdsToAdd.length > 0 ||
      vendorsIdsToRemove.length > 0 ||
      originalFunderTargetingMode !== funderTargetingMode ||
      originalSelectedFunderId !== selectedFunderId,
    [
      originalName,
      name,
      vendorsIdsToAdd,
      vendorsIdsToRemove,
      originalFunderTargetingMode,
      funderTargetingMode,
      originalSelectedFunderId,
      selectedFunderId,
    ],
  );

  const handleUpdateGroup = (args = {}) => {
    setLoadingAction(true);

    const { newFunderTargetingMode, newSelectedFunderId } = args;

    editGroup(
      token,
      {
        name,
        vendorsIdsToAdd,
        vendorsIdsToRemove,
        funderTargetingMode: newFunderTargetingMode ?? funderTargetingMode,
        selectedFunderId: newSelectedFunderId ?? selectedFunderId,
        allowRebateIncrement,
      },
      groupId,
    )
      .then(() => {
        dispatchAmplitudeEvent('buyer_vendors_groups_edit_success', {
          name,
          id: groupId,
          referenceCode: reference,
        });
        handleNavigate(VENDORS_GROUPS_SHOW_PATH.replace(':id', groupId));
      })
      .catch(() => {
        handleError(
          'Erro na edição do grupo',
          'Houve um problema ao editar o grupo. Por favor, atualize a página e tente novamente.',
        );
        setLoadingAction(false);
      });
  };

  const handleCreateGroup = () => {
    if (+rebateIncrement > 99.9999) {
      handleError('Erro!', 'O valor do incremento deve ser menor que 100%');
      return;
    }

    setLoadingAction(true);
    createGroup(token, {
      name,
      reference,
      vendorIds: groupVendors.map(({ id }) => id),
      funderTargetingMode,
      selectedFunderId,
      allowRebateIncrement,
      rebateIncrement: (+rebateIncrement.replace(',', '.') / 100).toFixed(6),
    })
      .then(() => {
        dispatchAmplitudeEvent('buyer_vendors_groups_create_group_success', {
          name,
          referenceCode: reference,
        });
        handleNavigate(
          `${VENDORS_GROUPS_PATH}?toastMessage=${encodeURI(`Grupo ${name} criado com sucesso!`)}`,
        );
      })
      .catch(err => {
        const { message } = err.response?.data?.error;
        handleCreationError(message, setReferenceError);
        setLoadingAction(false);
      });
  };

  const handleConfirm = args => {
    if (!reference && !noReference && !isEditing) return setAlertModal(true);
    const vendorsMoved = groupVendors.filter(({ vendorsGroupName }) => {
      const isDefaultGroup = vendorsGroupName === DEFAULT_GROUP_NAME;
      if (!isEditing) return !isDefaultGroup;
      return vendorsGroupName !== originalName && !isDefaultGroup;
    });
    if (vendorsMoved.length > 0 && !acknowledgedChange) {
      setAlertModal(true);
      return setMovedVendors(vendorsMoved);
    }
    if (isEditing) return handleUpdateGroup(args);
    return handleCreateGroup();
  };

  const handleConfirmAlert = () => {
    setAlertModal(false);
    if (!reference && !noReference) setNoReference(true);
    if (movedVendors && !acknowledgedChange) setAcknowledgedChange(true);
  };

  const moveVendors = (direction = 'right') => {
    const leftToRight = direction === 'right';

    let vendorsToBeTransfered = [];
    let uniqueVendors = removeDuplicates([...groupVendors], [...buyerVendors]);
    let selectedVendorsBuffer = [...selectedGroupVendors];

    if (leftToRight) {
      uniqueVendors = removeDuplicates([...buyerVendors], [...groupVendors]);
      selectedVendorsBuffer = [...selectedBuyerVendors];

      selectedBuyerVendors.forEach(id => {
        const vendor = uniqueVendors.find(obj => obj.id === id);
        const vendorIndex = uniqueVendors.indexOf(vendor);
        const selectedVendorIndex = selectedVendorsBuffer.indexOf(id);
        vendorsToBeTransfered = [...vendorsToBeTransfered, uniqueVendors[vendorIndex]];

        uniqueVendors.splice(vendorIndex, 1);
        selectedVendorsBuffer.splice(selectedVendorIndex, 1);
      });

      setGroupVendors([...groupVendors, ...vendorsToBeTransfered]);
      setBuyerVendors(uniqueVendors);
      setSelectedBuyerVendors(selectedVendorsBuffer);
    } else {
      selectedGroupVendors.forEach(id => {
        const vendor = uniqueVendors.find(obj => obj.id === id);
        const vendorIndex = uniqueVendors.indexOf(vendor);
        const selectedVendorIndex = selectedVendorsBuffer.indexOf(id);

        if (!buyerVendors.find(obj => obj.id === id)) {
          vendorsToBeTransfered = [...vendorsToBeTransfered, uniqueVendors[vendorIndex]];
        }

        uniqueVendors.splice(vendorIndex, 1);
        selectedVendorsBuffer.splice(selectedVendorIndex, 1);
      });

      setGroupVendors(uniqueVendors);
      setBuyerVendors([...buyerVendors, ...vendorsToBeTransfered]);
      setSelectedGroupVendors(selectedVendorsBuffer);
    }

    const transferedAmount = vendorsToBeTransfered.length;

    if (leftToRight) {
      setGroupVendorsCount(groupVendorsCount + transferedAmount);
      setVendorsCount(vendorsCount - transferedAmount);
    } else {
      setGroupVendorsCount(groupVendorsCount - transferedAmount);
      setVendorsCount(vendorsCount + transferedAmount);
    }
  };

  const moveLeftToRight = () => {
    moveVendors('right');
  };

  const moveRightToLeft = () => {
    moveVendors('left');
  };

  const handleSelect = (
    selectedVendorIds,
    setSelectedVendorIds,
    vendorIds,
    batchAction,
    allSelected,
  ) => {
    let arrayBuffer = [...selectedVendorIds];
    if (batchAction) {
      vendorIds.forEach(id => {
        const index = arrayBuffer.indexOf(id);
        if (allSelected) {
          arrayBuffer.splice(index, 1);
        } else if (index === -1) arrayBuffer = [...arrayBuffer, id];
      });
    } else {
      const index = arrayBuffer.indexOf(vendorIds[0]);
      if (index !== -1) {
        arrayBuffer.splice(index, 1);
      } else {
        arrayBuffer = [...arrayBuffer, ...vendorIds];
      }
    }
    setSelectedVendorIds(arrayBuffer);
  };

  const handleSelectBuyerVendors = (vendorIds, batchAction, allSelected) => {
    handleSelect(
      selectedBuyerVendors,
      setSelectedBuyerVendors,
      vendorIds,
      batchAction,
      allSelected,
    );
  };

  const handleSelectGroupVendors = (vendorIds, batchAction, allSelected) => {
    handleSelect(
      selectedGroupVendors,
      setSelectedGroupVendors,
      vendorIds,
      batchAction,
      allSelected,
    );
  };

  const handleFetchVendors = (amount = 50) => {
    fetchVendors(token, amount, groupId)
      .then(({ data }) => {
        const { vendors, pagination } = convertToCamelCase(data?.data);
        setApiBuyerVendors(vendors);
        if (amount === MAX_VENDOR_AMOUNT) return setLoadingBuyerVendors(false);
        setFirstLoadingBuyer(false);
        return setVendorsCount(pagination.count);
      })
      .catch(() => {
        handleError(
          'Erro na listagem de fornecedores!',
          'Não foi possível carregar todos os fornecedores disponíveis.',
        );
      });
  };

  const handleFetchGroupVendors = (amount = 50) => {
    fetchGroupVendors(token, amount, groupId)
      .then(({ data }) => {
        const { vendors, pagination } = convertToCamelCase(data?.data);
        setOriginalGroupVendors(vendors);
        setApiGroupVendors(vendors);
        if (amount === MAX_VENDOR_AMOUNT) return setLoadingGroupVendors(false);
        setFirstLoadingGroup(false);
        return setGroupVendorsCount(pagination.count);
      })
      .catch(() => {
        handleError(
          'Erro na listagem de fornecedores!',
          'Não foi possível carregar todos os fornecedores do grupo.',
        );
      });
  };

  useEffect(() => {
    const uniqueVendors = removeDuplicates(apiBuyerVendors, [...buyerVendors, ...groupVendors]);
    setBuyerVendors([...uniqueVendors, ...buyerVendors]);
  }, [apiBuyerVendors]);

  useEffect(() => {
    const uniqueVendors = removeDuplicates(apiGroupVendors, [...groupVendors, ...buyerVendors]);
    setGroupVendors([...uniqueVendors, ...groupVendors]);
  }, [apiGroupVendors]);

  useEffect(() => {
    if (noReference || acknowledgedChange) handleConfirm();
  }, [noReference, acknowledgedChange]);

  useEffect(() => {
    handleFetchFunders();
    handleFetchBuyerUser();
    if (isOnlyEditingOpMode !== undefined && !isOnlyEditingOpMode) {
      handleFetchVendors();
      handleFetchVendors(MAX_VENDOR_AMOUNT);
    }
    if (isEditing) {
      setFirstLoadingGroup(true);
      handleFetchGroup();
      if (isOnlyEditingOpMode !== undefined && !isOnlyEditingOpMode) {
        handleFetchGroupVendors();
        handleFetchGroupVendors(MAX_VENDOR_AMOUNT);
      }
    }
  }, [isOnlyEditingOpMode]);

  useEffect(() => setReferenceError(''), [reference]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isEditingOpModeParam = Boolean(params.get(EDIT_OP_MODE_URL_PARAM));

    setIsOnlyEditingOpMode(isEditingOpModeParam);
  }, []);

  return (
    <CreateVendorsGroupContext.Provider
      value={{
        groupLoading,
        name,
        reference,
        funderTargetingMode,
        selectedFunderId,
        handleChangeReference,
        setName,
        setFunderTargetingMode,
        setSelectedFunderId,
        funders,
        buyerVendors,
        selectedBuyerVendors,
        setSelectedBuyerVendors,
        groupVendors,
        selectedGroupVendors,
        setSelectedGroupVendors,
        alertModal,
        setAlertModal,
        movedVendors,
        referenceError,
        loadingBuyerVendors,
        loadingGroupVendors,
        isEditing,
        handleConfirm,
        handleConfirmAlert,
        moveLeftToRight,
        moveRightToLeft,
        handleSelectBuyerVendors,
        handleSelectGroupVendors,
        vendorsCount,
        groupVendorsCount,
        firstLoadingBuyer,
        firstLoadingGroup,
        loadingAction,
        hasChanges,
        groupId,
        isOnlyEditingOpMode,
        setRebateIncrement,
        rebateIncrement,
        allowRebateIncrement,
      }}
    >
      {children}
    </CreateVendorsGroupContext.Provider>
  );
};

CreateVendorsGroupStore.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  groupId: PropTypes.string,
};

CreateVendorsGroupStore.defaultProps = {
  groupId: null,
};

export { CreateVendorsGroupContext, CreateVendorsGroupStore };
