import Immutable from 'seamless-immutable';
import {
  FETCH_WALLET_MEAN,
  UPDATE_WALLET_MEAN,
  WALLET_MEAN_ERROR,
} from '../actions/walletMeanActions';

export const initialState = Immutable({
  token: null,
  loading: true,
  averageWeightedCdi: '0,00%',
  averageRunningDays: 0,
  monthlyWeightedProfitability: '0,00%',
});

export default (state = initialState, action = {}) => {
  const { type, walletMeanData } = action;
  switch (type) {
    case FETCH_WALLET_MEAN:
      return state.merge({ loading: true });

    case UPDATE_WALLET_MEAN:
      return state.merge({ ...walletMeanData, loading: false });

    case WALLET_MEAN_ERROR:
      return state.merge({ ...initialState, loading: false });

    default:
      return state;
  }
};
