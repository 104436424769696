import React, { useContext } from 'react';
import { mdiLock, mdiLockOpen } from '@mdi/js';
import Icon from '@mdi/react';
import { CompleteTable, Button } from 'liber-components';
import FundersTableFilters from '../FundersTableFilters';
import Loading from '../../../Loading';
import * as S from './styles';
import { FundersDrawerContext } from '../../context';
import { formatCnpjOrCpf, getPaginationInterval } from '../../../../vendor/Utils';
import StatusTag from '../../../StatusTag';
import { ACTIONS, KIND, STATUSES, STATUSES_MAPPER } from '../../constants';

const Table = () => {
  const {
    loading,
    funders,
    currentPage,
    pages,
    setCurrent,
    setPageState,
    next,
    previous,
    onKeyDownPage,
    per,
    count,
    onSelectListing,
    pagination,
    handleSelectedFunderToBlockOrUnblock,
    handleIntermediateBlockUnblockStatuses,
    selectedVendorId,
    vendorInfo,
    isAdmin,
  } = useContext(FundersDrawerContext);

  const intermediateStatuses = [STATUSES.PRE_APPROVED, STATUSES.UNDER_REVIEW];

  const getPayloadAction = status => {
    if (status === STATUSES.BLOCKED) return ACTIONS.UNBLOCK;
    if (status === STATUSES.UNBLOCKED) return ACTIONS.BLOCK;
    return null;
  };

  const buildPayload = funder => ({
    id: selectedVendorId,
    name: vendorInfo.tradeName,
    action: getPayloadAction(funder.status),
    target: {
      name: funder.tradeName,
      id: funder.id,
      kind: KIND.FUNDER,
    },
  });

  const handleIntermediateStatuses = funder => {
    handleIntermediateBlockUnblockStatuses(buildPayload(funder));
  };

  const handleBlockedAndUnblockedStatuses = funder => {
    handleSelectedFunderToBlockOrUnblock(buildPayload(funder));
  };

  const handleBlockUnblock = funder => {
    if (intermediateStatuses.includes(funder.status)) handleIntermediateStatuses(funder);
    else handleBlockedAndUnblockedStatuses(funder);
  };

  const getIconPath = status => (status === STATUSES.BLOCKED ? mdiLockOpen : mdiLock);

  const commonColumns = [
    {
      name: 'tradeName',
      label: 'Empresa',
      width: 30,
      // eslint-disable-next-line react/prop-types
      getCellValue: ({ tradeName, cnpj }) => (
        <>
          {tradeName}
          <br />
          {formatCnpjOrCpf(cnpj)}
        </>
      ),
    },
    {
      name: 'Situação no investidor',
      label: 'Situação no investidor',
      width: 30,
      // eslint-disable-next-line react/prop-types
      getCellValue: ({ status, statusOnFunderChanged }) => {
        const { color, label } = STATUSES_MAPPER[status];
        const notificationContent = statusOnFunderChanged ? 1 : null;
        return (
          <StatusTag color={color} notificationContent={notificationContent}>
            {label}
          </StatusTag>
        );
      },
    },
  ];

  const adminColumns = [
    {
      name: 'id',
      label: 'ID',
      width: 5,
    },
    {
      name: 'Ações',
      label: 'Ações',
      width: 10,
      getCellValue: funder => (
        <>
          <Button onClick={() => handleBlockUnblock(funder)} version={2} circle>
            <Icon path={getIconPath(funder.status)} size={0.5} />
          </Button>
        </>
      ),
    },
  ];

  const COLUMNS = () => {
    const columns = [...commonColumns];
    if (isAdmin) columns.push(...adminColumns);
    return columns;
  };

  return (
    <S.Container>
      <Loading isLoading={loading}>
        <CompleteTable
          filters={<FundersTableFilters />}
          columns={COLUMNS()}
          items={funders}
          paginationProps={{
            currentPage,
            pageTotal: pages,
            onChangePage: ({ target: { value } }) => setPageState(value),
            onClickNext: () => setCurrent(next),
            onClickPrev: () => setCurrent(previous),
            onKeyDownPage,
            hasListing: true,
            listingProps: {
              listing: String(per),
              total: String(count),
              showInterval: getPaginationInterval(pagination),
              onSelectListing,
              listingLabelSingular: 'investidor',
              listingLabelPlural: 'investidores',
            },
          }}
        />
      </Loading>
    </S.Container>
  );
};

export default Table;
