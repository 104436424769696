import { connect } from 'react-redux';
import Contratos from './Contratos';
import { getContratos } from '../../../presenters/contratos';
import { changeMailerSubscription, submitContracts } from '../../../actions/contratos';
import { previousStep as onPrevious } from '../../../actions/progressBar';

const mapStateToProps = state => ({ ...getContratos(state) });

const mapDispatchToProps = {
  changeMailerSubscription,
  onPrevious,
  submitContracts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contratos);
