import Immutable from 'seamless-immutable';
import { updatingByIdArrayMerger } from 'seamless-immutable-mergers';

const initialState = Immutable({
  loading: false,
  loadingMessage: {
    title: '',
    message: '',
  },
  status: true,
  error: {
    display: [],
    message: '',
    type: '',
    flow: '',
    supportErrors: [],
    failedInvoices: [],
    label: '',
    toasts: [],
  },
  invoices: [],
  invoicesDone: false,
  afterSign: false,
  loadedInvoices: false,
  signaturesNeeded: 0,
  waitingForSocket: false,
  certificates: [],
  partners: [],
  ecnpjSignature: false,
  jointSignature: false,
  selectedPartner: -1,
  selectedCertificate: '',
  fornecedorId: '',
  pdfUrl: '',
  requestIp: '',
  downloadAllDone: '',
  proposalIds: [],
  signAttempt: false,
});

// const joinInvoices = (invoices, list) => invoices.map(invoice => invoice.merge(list, { deep: true }));

const joinInvoices = (invoices, array) => {
  const obj = Immutable({
    array: invoices,
  });
  const mergeConfig = {
    merger: updatingByIdArrayMerger,
    mergerObjectIdentifier: 'id',
  };
  const merged = obj.merge({ array }, mergeConfig);
  return merged.array;
};

const allFailed = (invoices, done, defaultError) => {
  const nonFinished = invoices.filter(invoice => invoice.finished !== 'true');
  if (
    nonFinished.every(invoice => invoice.jobStatus === 'failed' || invoice.invoiceError) &&
    done
  ) {
    const failedInvoices = nonFinished.filter(
      invoice => invoice.jobStatus === 'failed' || invoice.invoiceError,
    );
    const label =
      invoices.length > 1
        ? `A preparação dos ${failedInvoices.length} documentos falhou`
        : 'A preparação do documento falhou';
    return {
      display: ['what-to-do'],
      flow: 'blocker',
      failedInvoices,
      message:
        failedInvoices.length > 1
          ? 'Erro na preparação dos Documentos'
          : 'Erro na preparação do Documento',
      type: 'server-gen',
      label,
      toasts: [
        {
          message: label,
          type: 'error',
        },
      ],
    };
  }
  return defaultError;
};

export default (state = initialState, action = {}) => {
  let invoices = [];
  switch (action.type) {
    case 'MULTI_SIGN_FORNECEDOR_SHOW_PDF':
      return state.merge({ pdfUrl: action.payload });
    case 'MULTI_SIGN_FORNECEDOR_HIDE_PDF':
      return state.merge({ pdfUrl: '' });
    case 'MULTI_SIGN_FORNECEDOR_SELECT_PARTNER':
      return state.merge({ selectedPartner: action.payload });
    case 'MULTI_SIGN_FORNECEDOR_SELECT_CERTIFICATE':
      return state.merge({
        status: action.payload.status,
        selectedCertificate: action.payload.value,
        error: action.payload.error,
      });
    case 'MULTI_SIGN_FORNECEDOR_ASSINAME_WAITING_EXTENSION':
      return state.merge({ loading: true, loadingMessage: action.payload }, { deep: true });
    case 'MULTI_SIGN_FORNECEDOR_ASSINAME_SUPPORT_ERRORS':
      return state.merge(
        {
          status: false,
          error: action.payload,
          loading: false,
          loadingMessage: {
            title: '',
            message: '',
          },
        },
        { deep: true },
      );
    case 'MULTI_SIGN_FORNECEDOR_ASSINAME_GET_LIST_SUCCESS':
      return state.merge({
        loadingMessage: action.payload.loadingMessage,
        certificates: action.payload.certificates,
        waitingForSocket: !state.loadedInvoices,
        loading: state.loadedInvoices ? false : state.loading,
        // status: state.failedInvoices !== 0 ? true : state.error,
      });
    case 'MULTI_SIGN_FORNECEDOR_ASSINAME_GET_LIST_ERROR':
      return state.merge({
        loading: false,
        status: false,
        error: action.payload,
      });
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION':
      return state.merge({
        loadedInvoices: true,
        signAttempt: false,
        status: true,
        error: initialState.error,
        invoices: action.payload.invoices,
        loading: state.waitingForSocket ? false : state.loading,
      });
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION_EMPTY_TOKEN':
      return state.merge({
        loadedInvoices: true,
        signAttempt: false,
        status: true,
        error: initialState.error,
        invoices: joinInvoices(state.invoices, action.payload.invoices),
        loading: state.waitingForSocket && action.payload.done ? false : state.loading,
      });
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED':
      return state.merge(
        {
          invoices: action.payload.invoices,
          status: true,
          error: initialState.error,
          loading: false,
          invoicesDone: action.payload.invoicesDone,
          partners: action.payload.partners,
          selectedPartner: -1,
          afterSign: true,
          downloadAllDone: action.payload.downloadAllDone,
        },
        { deep: true },
      );
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_EMPTY_TOKEN':
      return state.merge(
        {
          invoices: joinInvoices(state.invoices, action.payload.invoices),
          status: true,
          error: initialState.error,
          loading: action.payload.done ? false : state.loading,
          invoicesDone: action.payload.invoicesDone,
          partners: action.payload.partners || state.partners,
          selectedPartner: -1,
          afterSign: true,
          downloadAllDone: action.payload.downloadAllDone,
        },
        { deep: true },
      );
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION_WITH_ERRORS':
      return state.merge({
        loadedInvoices: true,
        invoices: action.payload.invoices,
        loading: state.waitingForSocket ? false : state.loading,
        status: state.waitingForSocket ? false : !state.loading,
        error: action.payload.error,
      });
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_GENERATION_WITH_ERRORS_EMPTY_TOKEN':
      invoices = joinInvoices(state.invoices, action.payload.invoices); // eslint-disable-line
      return state.merge({
        loadedInvoices: true,
        invoices,
        loading: state.waitingForSocket && action.payload.done ? false : state.loading,
        status: state.waitingForSocket ? false : !state.loading,
        error: allFailed(invoices, action.payload.done, action.payload.error),
      });
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_WITH_ERRORS':
      return state.merge({
        invoices: action.payload.invoices,
        loading: false,
        invoicesDone: action.payload.invoicesDone,
        partners: action.payload.partners,
        selectedPartner: -1,
        afterSign: true,
        status: false,
        error: action.payload.error,
      });
    case 'MULTI_SIGN_FORNECEDOR_SOCKET_RESPONSE_FOR_DOC_SIGNED_WITH_ERRORS_EMPTY_TOKEN':
      invoices = joinInvoices(state.invoices, action.payload.invoices); // eslint-disable-line
      return state.merge({
        invoices,
        loading: action.payload.done ? false : state.loading,
        invoicesDone: action.payload.invoicesDone,
        partners: action.payload.partners || state.partners,
        selectedPartner: -1,
        afterSign: true,
        status: false,
        error: allFailed(invoices, action.payload.done, action.payload.error),
      });
    case 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_TIMEOUT_ERROR':
      return state.merge({
        loading: false,
        status: false,
        error: {
          ...action.payload,
          failedInvoices: state.invoices,
        },
      });
    case 'MULTI_SIGN_FORNECEDOR_GET_DOCUMENTS_ERROR':
      return state.merge({
        loading: false,
        status: false,
        invoices: action.payload.invoices,
        error: action.payload.error,
      });
    case 'MULTI_SIGN_FORNECEDOR_SIGN_PENDING':
      return state.merge(
        {
          loading: true,
          status: true,
          error: initialState.error,
          loadingMessage: action.payload,
        },
        { deep: true },
      );
    case 'MULTI_SIGN_FORNECEDOR_SIGN_ERROR':
      return state.merge({
        loading: false,
        status: false,
        error: action.payload,
      });
    case 'MULTI_SIGN_FORNECEDOR_SIGN_SUCCESS':
      return state.merge({ signAttempt: true });
    case 'MULTI_SIGN_FORNECEDOR_PASSWORD_ACCEPTED':
      return state.merge({ loadingMessage: action.payload });
    case 'MULTI_SIGN_FORNECEDOR_AXAX_SUCCESS':
      return state;
    case 'MULTI_SIGN_FORNECEDOR_AXAX_ERROR':
      return state;
    default:
      return state;
  }
};
