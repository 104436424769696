import { connect } from 'react-redux';

import ChangePassword from './ChangePassword';
import {
  changeCurrentPassword,
  changeNewPassword,
  changePasswordConfirmation,
  submitForm,
} from '../../actions/changePassword/InvestorChangePasswordActions';

const parentReducer = 'myAccount';
const tab = 'changePassword';
const type = 'investor';
const reducer = 'data';

const mapStateToProps = state => ({ ...state[parentReducer][tab][type][reducer] });
const mapDispatchToProps = {
  changeCurrentPassword,
  changeNewPassword,
  changePasswordConfirmation,
  submitForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
