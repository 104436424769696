import axios from 'axios';

import { convertToCamelCase } from '../../../../../vendor/Utils';

const apiUrl = SERVICE_API_URL; // eslint-disable-line
export const RENEGOTIATIONS_COUNT_URL = `${apiUrl}/api/v2/funder/renegotiations/summary`;
export const CHECK_ACCEPTANCE_URL = `${apiUrl}/api/v1/investidor/contracts/check_membership_contract_acceptance`;
export const RENEGOTIATIONS_PATH = '/investidor/renegociacoes';
export const NEW_CONTRACT_PATH = '/investidor/aceitar_termos';

export async function getRenegotiatedCount(token = '') {
  const response = await axios.get(RENEGOTIATIONS_COUNT_URL, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const { total } = response?.data?.data || {};

  return total || 0;
}

export async function checkContractAcceptance(token = '') {
  const response = await axios.get(CHECK_ACCEPTANCE_URL, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return convertToCamelCase(response?.data?.data) || {};
}
