import styled from 'styled-components';

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 6px;
  color: #405f71;
`;

export const Value = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  color: #6287a7;
`;
