import React, { useContext } from 'react';
import { TokenContext } from '../../../../hocs/withTokenProvider';
import VendorDetailsDrawer from '../../../../common-components/VendorDetailsDrawer';
import { Context } from '../../context';
import { END_DATE_FILTER, START_DATE_FILTER } from '../../constants';

const DATE_FILTERS_KEYS = [START_DATE_FILTER, END_DATE_FILTER];

const VendorDrawer = () => {
  const { isDrawerOpen, setIsDrawerOpen, selectedVendorId, filters } = useContext(Context);
  const { token } = useContext(TokenContext);

  const defaultFilters = Object.keys(filters).reduce((acc, key) => {
    if (DATE_FILTERS_KEYS.includes(key)) {
      return { ...acc, [key]: filters[key] };
    }
    return acc;
  }, {});

  return (
    <VendorDetailsDrawer
      open={isDrawerOpen}
      token={token}
      isAdmin={false}
      onLeaved={() => setIsDrawerOpen(current => !current)}
      selectedVendorId={selectedVendorId}
      defaultFilters={defaultFilters}
    />
  );
};

export default VendorDrawer;
