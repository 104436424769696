import React from 'react';
import PropTypes from 'prop-types';

import * as S from '../../styles';

const GroupsList = ({ buyers }) => {
  const groups = buyers.map(({ vendorsGroups }) =>
    vendorsGroups.map(({ name }) => name).join(', '),
  );

  return (
    <>
      <S.LabelTitle>{groups.length > 1 ? 'Grupos' : 'Grupo'}</S.LabelTitle>
      <S.Label>
        {buyers.length > 1 ? 'Todos os grupos dos sacados acima foram selecionados.' : groups}
      </S.Label>
    </>
  );
};

GroupsList.propTypes = {
  buyers: PropTypes.arrayOf(PropTypes.any),
};

GroupsList.defaultProps = {
  buyers: [],
};

export default GroupsList;
