import Immutable from 'seamless-immutable';

const initialState = Immutable({
  account: {
    id: '',
    name: '',
    cnpj: '',
    email: '',
    cellphone: '',
    phone: '',
    gender: '',
    birthdate: '',
    civilStatus: '',
    countryOrigin: 'BR',
    stateOrigin: '',
    cityOrigin: '',
    kind: '',
    tradeName: '',
    company: '',
  },
  documentation: {
    kind: '',
    number: '',
    emissionDate: '',
    emissionIssuer: '',
    emissionState: '',
    motherName: '',
    fatherName: '',
    pep: '',
  },
  showAccountForm: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_HIDRATE_FORM':
      return state.merge({
        showAccountForm: true,
      });
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_SUCCESS':
      return state.merge({
        showAccountForm: false,
        account: action.payload,
      });
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_CLOSE_MODAL_FORM':
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_REJECT':
      return state.merge({
        showAccountForm: false,
      });
    default:
      return state;
  }
};
