import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import { Container, Header, Content, ActionsRow, Button, ButtonCancel } from '../styles';

function ConfirmGroupEdit({ onLeaved, handleConfirm, movedVendors }) {
  const hasMovedVendors = Boolean(movedVendors?.length);

  return (
    <Modal show onLeaved={onLeaved}>
      <Container>
        <Header>
          {hasMovedVendors ? 'Alguns fornecedores mudarão de grupo' : 'Código de referência vazio'}
        </Header>
        <Content>
          {hasMovedVendors ? (
            <>
              {movedVendors.length}{' '}
              {movedVendors.length > 1
                ? 'fornecedores selecionados já pertencem a outros grupos'
                : 'fornecedor selecionado já pertence a outro grupo'}
              . Ao continuar, esses fornecedores serão removidos do grupo original e serão
              adicionados neste novo.
              <br />
              <br />
              Deseja continuar?
            </>
          ) : (
            <>
              Você está prestes a criar um novo grupo sem código de referência. Iremos atribuir um
              código automático, mas este campo não pode ser editado no futuro.
              <br />
              <br />
              Deseja continuar mesmo assim?
            </>
          )}
        </Content>
        <ActionsRow>
          <ButtonCancel onClick={onLeaved}>CANCELAR</ButtonCancel>
          <Button onClick={handleConfirm}>CONTINUAR</Button>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

ConfirmGroupEdit.propTypes = {
  onLeaved: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  movedVendors: PropTypes.arrayOf(PropTypes.any),
};

ConfirmGroupEdit.defaultProps = {
  movedVendors: [],
};

export default ConfirmGroupEdit;
