/* eslint-disable react/prop-types */
import React from 'react';
import { uniqueId } from 'lodash';
import { mdiCurrencyUsd, mdiEye, mdiCalendar, mdiDownload, mdiPencil, mdiCancel } from '@mdi/js';
import { AlignRightNoWrap, ResetSvgMargin } from './components/AdminNegotiations.styles';

const emptyCell = '***';

const clearCurrency = (value = '') => value.replace('R$ ', '');

export const getCleanUrl = () => new URL(window.location.toString().split('?')[0]);

export const STATUS_MAP = {
  aberta: {
    code: 0,
    label: 'Aberta',
    type: 'neutral',
  },
  aguardando_aceite: {
    code: 1,
    label: 'Aguardando Aceite',
    type: 'neutral',
  },
  aguardando_pagamento: {
    code: 3,
    label: 'Aguardando Pagamento',
    type: 'neutral',
  },
  paga: {
    code: 4,
    label: 'Paga',
    type: 'neutral',
  },
  concluida: {
    code: 5,
    label: 'Concluída',
    type: 'positive',
  },
  cancelada: {
    code: 6,
    label: 'Cancelada',
    type: 'negative',
  },
  agendada: {
    code: 7,
    label: 'Agendada',
    type: 'neutral',
  },
  aguardando_formalizacao: {
    code: 8,
    label: 'Aguardando Formalização',
    type: 'neutral',
  },
  em_processamento: {
    code: 9,
    label: 'Em Processamento',
    type: 'neutral',
  },
  acatada: {
    code: 10,
    label: 'Agendado para pagamento',
    type: 'neutral',
  },
};

export const ACTIONS = [
  {
    name: 'Fazer Proposta',
    icon: mdiCurrencyUsd,
    key: 'newBuyOrder',
  },
  {
    name: 'Exibir',
    icon: mdiEye,
    key: 'show',
  },
  {
    name: 'Abrir negociação',
    icon: mdiCalendar,
    key: 'openTrade',
  },
  {
    name: 'Termo de Cessão',
    icon: mdiDownload,
    key: 'invoiceTransferAgreement',
  },
  {
    name: 'Editar',
    icon: mdiPencil,
    key: 'editTrade',
  },
  {
    name: 'Ver Proposta',
    icon: mdiCurrencyUsd,
    key: 'showBuyOrder',
  },
  {
    name: 'Cancelar',
    icon: mdiCancel,
    key: 'cancelTrade',
  },
];

const getLinkCell = (content, path) => (
  <a
    href={path}
    target="_blank"
    rel="noopener noreferrer"
    onClick={event => event.stopPropagation()}
  >
    {content}
  </a>
);

const INVOICE_CELL = {
  name: 'duplicata',
  label: (
    <>
      Título
      <br />
      Nº Referência
      <br />
    </>
  ),
  align: 'right',
  getCellValue: ({ invoice: { number, referenceNumber, showPath } }) => (
    <AlignRightNoWrap>
      {number ? getLinkCell(number, showPath) : emptyCell}
      <br />
      {referenceNumber || emptyCell}
    </AlignRightNoWrap>
  ),
};

const VALUE_CELL = {
  name: 'duplicata_value',
  label: 'Valor (R$)',
  align: 'right',
  getCellValue: ({ invoice: { value } }) => clearCurrency(value) || emptyCell,
};

const TAX_CELL = {
  name: 'duplicata_agreed_rate',
  label: 'Taxa',
  align: 'right',
  getCellValue: ({ invoice: { agreedRate } }) => agreedRate || emptyCell,
  width: 10,
};

const BUYER_CELL = {
  name: 'sacado_trade_name',
  label: 'Sacado',
  align: 'left',
  getCellValue: ({ buyer: { presentationName, showPath } }) =>
    presentationName ? getLinkCell(presentationName, showPath) : emptyCell,
};

const VENDOR_CELL = {
  name: 'fornecedor_trade_name',
  label: 'Fornecedor',
  align: 'left',
  getCellValue: ({ vendor: { presentationName, showPath } }) =>
    presentationName ? getLinkCell(presentationName, showPath) : emptyCell,
};

const FUNDER_CELL = {
  name: 'investidor_name',
  label: 'Investidor',
  align: 'left',
  getCellValue: ({ funder }) => {
    const { presentationName, showPath } = funder || {};
    return presentationName ? getLinkCell(presentationName, showPath) : emptyCell;
  },
};

export const TABS = [
  {
    options: [
      {
        label: 'Agendadas',
        isSelectable: true,
        headers: [
          {
            ...INVOICE_CELL,
            width: 27,
          },
          {
            ...VALUE_CELL,
            width: 15,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 14,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 17,
          },
          {
            ...VENDOR_CELL,
            width: 17,
          },
        ],
      },
    ],
  },
  {
    options: [
      {
        label: 'Abertas',
        isSelectable: true,
        headers: [
          {
            ...INVOICE_CELL,
            width: 28,
          },
          {
            ...VALUE_CELL,
            width: 13,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 11,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            name: 'term',
            label: 'Prazo',
            width: 10,
            align: 'center',
            getCellValue: ({ term }) => term || emptyCell,
          },
          {
            ...BUYER_CELL,
            width: 14,
          },
          {
            ...VENDOR_CELL,
            width: 14,
          },
        ],
      },
    ],
  },
  {
    label: 'Aguardando',
    options: [
      {
        label: 'Aguardando Aceite',
        isSelectable: true,
        headers: [
          {
            ...INVOICE_CELL,
            width: 25,
          },
          {
            ...VALUE_CELL,
            width: 13,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 13,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 13,
          },
          {
            ...VENDOR_CELL,
            width: 13,
          },
          {
            ...FUNDER_CELL,
            width: 13,
          },
        ],
      },
      {
        label: 'Aguardando Formalização',
        isSelectable: true,
        headers: [
          {
            ...INVOICE_CELL,
            width: 25,
          },
          {
            ...VALUE_CELL,
            width: 13,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 13,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 13,
          },
          {
            ...VENDOR_CELL,
            width: 13,
          },
          {
            ...FUNDER_CELL,
            width: 13,
          },
        ],
      },
      {
        label: 'Aguardando Liberação',
        headers: [
          {
            name: 'ediStatus',
            label: 'EDI',
            width: 10,
            align: 'center',
            getCellValue: ({ invoice: { ediStatus } }) =>
              ediStatus ? <img src={`${ediStatus}`} alt="EDI status" /> : emptyCell,
          },
          {
            ...INVOICE_CELL,
            width: 24,
          },
          {
            ...VALUE_CELL,
            width: 11,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 13,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 12,
          },
          {
            ...VENDOR_CELL,
            width: 12,
          },
          {
            ...FUNDER_CELL,
            width: 12,
          },
        ],
      },
      {
        label: 'Aguardando Pagamento Agendado',
        isSelectable: true,
        headers: [
          {
            ...INVOICE_CELL,
            width: 25,
          },
          {
            ...VALUE_CELL,
            width: 13,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 13,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 13,
          },
          {
            ...VENDOR_CELL,
            width: 13,
          },
          {
            ...FUNDER_CELL,
            width: 13,
          },
        ],
      },
    ],
  },
  {
    options: [
      {
        label: 'Canceladas',
        headers: [
          {
            ...INVOICE_CELL,
            width: 29,
          },
          {
            ...VALUE_CELL,
            width: 13,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
              </ResetSvgMargin>
            ),
            width: 11,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt } }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 16,
          },
          {
            ...VENDOR_CELL,
            width: 16,
          },
        ],
      },
    ],
  },
  {
    options: [
      {
        label: 'Concluídas',
        isSelectable: true,
        headers: [
          {
            ...INVOICE_CELL,
            width: 27,
          },
          {
            ...VALUE_CELL,
            width: 12,
          },
          TAX_CELL,
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
                <br />
                Conclusão
              </ResetSvgMargin>
            ),
            width: 11,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt }, finishedAt }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
                <br />
                {finishedAt || emptyCell}
              </div>
            ),
          },
          {
            name: 'term',
            label: 'Prazo',
            width: 9,
            align: 'center',
            getCellValue: ({ term }) => term || emptyCell,
          },
          {
            ...BUYER_CELL,
            width: 10,
          },
          {
            ...VENDOR_CELL,
            width: 10,
          },
          {
            ...FUNDER_CELL,
            width: 10,
          },
        ],
      },
    ],
  },
  {
    options: [
      {
        label: 'Todas',
        headers: [
          {
            ...INVOICE_CELL,
            width: 22,
          },
          {
            ...VALUE_CELL,
            width: 13,
          },
          TAX_CELL,
          {
            name: 'status',
            label: 'Status',
            width: 15,
            align: 'left',
            getCellValue: ({ status }) => status || emptyCell,
          },
          {
            name: 'dates',
            label: (
              <ResetSvgMargin>
                Vencimento
                <br />
                Liquidação
                <br />
                Conclusão
              </ResetSvgMargin>
            ),
            width: 13,
            align: 'left',
            getCellValue: ({ invoice: { expiresAt, expectedPaymentAt }, finishedAt }) => (
              <div>
                {expiresAt || emptyCell}
                <br />
                {expectedPaymentAt || emptyCell}
                <br />
                {finishedAt || emptyCell}
              </div>
            ),
          },
          {
            ...BUYER_CELL,
            width: 11,
          },
          {
            ...VENDOR_CELL,
            width: 11,
          },
          {
            ...FUNDER_CELL,
            width: 11,
          },
        ],
      },
    ],
  },
];

export const SUBTITLE_INDEX_MAP = {
  2: {
    subtitle: 'Status Aguardando Aceite',
    description:
      'Negociações aguardando o aceite do fornecedor após a proposta feita pelo investidor.',
  },
  3: {
    subtitle: 'Status Aguardando Formalização',
    description: 'Negociações aguardando a assinatura do termo de cessão pelo fornecedor.',
  },
  4: {
    subtitle: 'Status Aguardando Liberação',
    description:
      'Negociações aguardando a realização do pagamento do investidor para o fornecedor.',
  },
  5: {
    subtitle: 'Status Aguardando Pagamento Agendado',
    description: 'Negociações acatadas pelo investidor e agendadas para pagamento.',
  },
};

export const MOCKED_TRADES = [
  {
    invoice: {
      number: '12345',
      value: 2353.2,
      agreed_rate: 0.34,
      expires_at: '01/01/2020',
      expected_payment_at: '02/01/2020',
      show_path: '#',
      reference_number: null,
      reference_number_edi: null,
    },
    status: 'cancelada',
    term: '21 dias',
    id: 1,
    buyer: {
      presentation_name: 'Gs Healthcare',
      admin_sacado_path: '#',
      payer_risk_for_btg: true,
    },
    vendor: {
      presentation_name: 'Equipamentos Brasil LTDA',
      admin_fornecedor_path: '#',
    },
    funder: {
      presentation_name: 'Rafael Cavalcanti',
      admin_investidor_path: '#',
    },
    actions: {
      show_path: '#',
      edit_path: '#',
      show_buy_order_path: '#',
      invoice_transfer_agreement_url: '#',
    },
  },
  {
    invoice: {
      number: '12348',
      value: 2353.2,
      agreed_rate: 0.34,
      expires_at: '01/01/2020',
      expected_payment_at: '02/01/2020',
      show_path: '#',
      reference_number: null,
      reference_number_edi: null,
    },
    status: 'cancelada',
    term: '60 dias',
    id: 2,
    buyer: {
      presentation_name: 'Gs Healthcare',
      show_path: '#',
      payer_risk_for_btg: true,
    },
    vendor: {
      presentation_name: 'Equipamentos Brasil LTDA',
      show_path: '#',
    },
    funder: {
      presentation_name: 'Rafael Cavalcanti',
      show_path: '#',
    },
    finished_at: '05/01/2020',
    actions: {
      open_trade_path: '#',
      cancel_trade_path: '#',
      new_buy_order_path: '#',
    },
  },
];

export const MAP_TAB_TO_STATUS = [7, 0, 1, 8, 9, 10, 6, 5, null];
export const MAP_STATUS_TO_TAB = {
  0: 1,
  1: 2,
  9: 4,
  5: 7,
  6: 7,
  7: 0,
  8: 3,
  10: 5,
  null: 8,
};

export const STATUS_EQ = 'q[status_eq]';

export const isUrlKey = key =>
  key.toLowerCase().endsWith('url') || key.toLowerCase().endsWith('path');

export const newUniqueId = () => {
  const timestamp = new Date().getTime();
  return String(uniqueId(timestamp));
};

export const getTabLabelByIndex = tabIndex => {
  const labels = [];
  TABS.forEach(({ options }) => options.forEach(({ label }) => labels.push(label)));
  return labels[tabIndex];
};

export const FiltersFormatter = filters => {
  const formatedFilters = {};

  Object.keys(filters).forEach(key => {
    const formattedFilterKey = key.replace(/(\[|\]|^q)/g, '');
    formatedFilters[formattedFilterKey] = filters[key];
  });

  return formatedFilters;
};
