import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';

const LiberComponentsThemeWrapper = ({ children }) => (
  <ThemeProvider theme={LiberV4}>{children}</ThemeProvider>
);

LiberComponentsThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LiberComponentsThemeWrapper;
