import React from 'react';
import * as S from './styles';
import BuyerInfo from '../BuyerInfo';
import { useBuyerOrganizationGroup } from '../../context';

const BuyersInfo = () => {
  const { buyerGroup } = useBuyerOrganizationGroup();

  return (
    <div>
      <S.Header>Sacados no grupo:</S.Header>
      <S.Flexbox>
        {buyerGroup.buyers && buyerGroup.buyers.map(buyer => <BuyerInfo buyer={buyer} />)}
      </S.Flexbox>
    </div>
  );
};

export default BuyersInfo;
