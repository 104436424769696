import styled from 'styled-components';

export const List = styled.div`
  width: 100%;
  height: 100px;
  max-height: 100;
  overflow-y: scroll;
`;

export const Item = styled.div`
  font-size: 16px;
  color: #6287a7;
  width: 100%;
`;
