import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  handleShowVendorBlockUnblockModal,
  updateSelectedFunderToBlockOrUnblock,
  handleIntermediateBlockUnblockStatuses,
} from '../../actions/AdminVendorsActions';
import VendorDetailsDrawer from '../../../../common-components/VendorDetailsDrawer';

const FundersDrawer = props => <VendorDetailsDrawer {...props} />;

FundersDrawer.propTypes = {
  open: PropTypes.bool,
  onLeaved: PropTypes.func,
  selectedVendorBuyerId: PropTypes.number,
  token: PropTypes.string.isRequired,
  selectedVendorId: PropTypes.number,
  handleSelectedFunderToBlockOrUnblock: PropTypes.func.isRequired,
  handleIntermediateBlockUnblockStatuses: PropTypes.func,
};

FundersDrawer.defaultProps = {
  open: false,
  onLeaved: () => null,
  selectedVendorBuyerId: null,
  selectedVendorId: null,
  handleIntermediateBlockUnblockStatuses: () => null,
};

const mapDispatchToProps = {
  setSelectedFunderToBlockOrUnblock: updateSelectedFunderToBlockOrUnblock,
  handleSelectedFunderToBlockOrUnblock: handleShowVendorBlockUnblockModal,
  handleIntermediateBlockUnblockStatuses,
};

const mapStateToProps = ({
  adminVendors: { selectedVendorBuyerId, selectedVendor, selectedVendorId },
}) => ({
  selectedVendorBuyerId,
  selectedVendor,
  selectedVendorId,
});

export default connect(mapStateToProps, mapDispatchToProps)(FundersDrawer);
