// eslint-disable-next-line no-undef
export const BASE_URL = SERVICE_API_URL;

export const GET_TRADE_SETTLEMENT_URL = `${BASE_URL}/api/v1/investidor/trade_settlements`;

export const COLUMNS = [
  {
    align: 'right',
    inverted: false,
    label: 'Número do título',
    onSort: function noRefCheck() {},
    selected: false,
  },
  {
    align: 'right',
    inverted: false,
    label: 'Status',
    onSort: function noRefCheck() {},
    selected: false,
  },
  {
    align: 'right',
    inverted: false,
    label: 'D. Compra',
    onSort: function noRefCheck() {},
    selected: false,
  },
  {
    align: undefined,
    inverted: false,
    label: 'Vencimento',
    onSort: function noRefCheck() {},
    selected: false,
  },
  {
    align: undefined,
    inverted: false,
    label: 'Prazo',
    onSort: function noRefCheck() {},
    selected: false,
  },
  {
    align: undefined,
    inverted: false,
    label: 'D. Liq.',
    onSort: function noRefCheck() {},
    selected: false,
  },
  {
    align: undefined,
    inverted: false,
    label: 'Sacado',
    onSort: function noRefCheck() {},
    selected: false,
  },
];

export const TRADE_STATUSES = {
  WAITING_SETTLEMENT: 'waiting_settlement',
  SETTLED: 'settled',
  POSTPONED: 'postponed',
  DELAYED: 'delayed',
};

export const TRADE_STATUS_TEXT_MAPPER = {
  waiting_settlement: 'A vencer',
  settled: 'Liquidado',
  postponed: 'Prorrogado',
  delayed: 'Atrasado',
};

export const TRADE_STATUS_COLOR_MAPPER = {
  waiting_settlement: 'success',
  settled: 'success-light',
  postponed: 'warning',
  delayed: 'error',
};

export const STATUSES_FILTER_OPTIONS = [
  { label: 'A vencer', value: 'waiting_settlement' },
  { label: 'Liquidado', value: 'settled' },
  { label: 'Prorrogado', value: 'postponed' },
  { label: 'Atrasado', value: 'delayed' },
];

export const BUYER_EQ_FILTER = 'q[buyer_id_eq]';
export const VENDOR_EQ_FILTER = 'q[vendor_id_eq]';
export const TRADE_NUMBER_EQ_FILTER = 'q[number_eq]';
export const FACE_VALUE_EQ_FILTER = 'q[face_value_eq]';
export const EMOLUMENTS_VALUE_EQ_FILTER = 'q[emoluments_value_eq]';
export const TRADE_STATUS_EQ_FILTER = 'q[status_eq]';
export const TRADE_FINISHED_AT_GTEQ_FILTER = 'q[finished_at_gteq]';
export const TRADE_FISINIHED_AT_LTEQ_FILTER = 'q[finished_at_lteq]';
export const TRADE_EXPIRATION_DATE_GTEQ_FILTER = 'q[expiration_date_gteq]';
export const TRADE_EXPIRATION_DATE_LTEQ_FILTER = 'q[expiration_date_lteq]';

export const EMPTY_FILTERS_STATE = {
  [BUYER_EQ_FILTER]: null,
  [VENDOR_EQ_FILTER]: null,
  [TRADE_NUMBER_EQ_FILTER]: null,
  [FACE_VALUE_EQ_FILTER]: null,
  [EMOLUMENTS_VALUE_EQ_FILTER]: null,
  [TRADE_STATUS_EQ_FILTER]: null,
  [TRADE_FINISHED_AT_GTEQ_FILTER]: null,
  [TRADE_FISINIHED_AT_LTEQ_FILTER]: null,
  [TRADE_EXPIRATION_DATE_GTEQ_FILTER]: null,
  [TRADE_EXPIRATION_DATE_LTEQ_FILTER]: null,
};
