import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip, IconButton } from 'liber-salti';

import { Container, TitleContainer, CollapseContainer } from './styles';

const CollapsibleSection = ({
  title,
  className,
  children,
  onToggleVisibility,
  tooltipLabel: { visible: visibleTooltipLabel, hidden: hiddenTooltipLabel } = {
    visible: 'Esconder',
    hidden: 'Exibir',
  },
}) => {
  const [visible, setVisible] = useState(true);

  const toggleVisibility = () => {
    onToggleVisibility?.(!visible);
    setVisible(!visible);
  };

  return (
    <Container data-testid="CollapsibleSection" open={visible} className={className}>
      <TitleContainer>
        <Typography variant="subtitle1" color="textPrimary">
          {title}
        </Typography>
        <Tooltip title={visible ? visibleTooltipLabel : hiddenTooltipLabel} arrow>
          <IconButton onClick={toggleVisibility} iconName={visible ? 'EyeOff' : 'EyeShow'} />
        </Tooltip>
      </TitleContainer>
      <CollapseContainer data-testid="CollapsibleArea" in={visible}>
        {children}
      </CollapseContainer>
    </Container>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onToggleVisibility: PropTypes.func,
  tooltipLabel: {
    visible: PropTypes.string,
    hidden: PropTypes.string,
  },
  children: PropTypes.node.isRequired,
};

CollapsibleSection.defaultProps = {
  className: '',
  onToggleVisibility: null,
  tooltipLabel: {
    visible: 'Esconder',
    hidden: 'Exibir',
  },
};

export default CollapsibleSection;
