import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePagination from '../../../../hooks/usePagination';
import usePageState from '../../../../hooks/usePageState';

import { RatesPreviewContext } from '../../context';
import { fetchVendorsGroups } from '../../api';
import { convertToCamelCase, handleError } from '../../../../vendor/Utils';
import {
  VENDORS_GROUPS_BUYER_NAME_FILTER,
  VENDORS_GROUPS_ID_IN_FILTER,
  VENDORS_GROUPS_NAME_FILTER,
} from '../../constants';
import { getVendorsGroupsId } from '../../utils';

export const ConfirmationModalContext = createContext({});

const ConfirmationModalProvider = ({ children, onLeaved }) => {
  const {
    token,
    ratesPreview: { id, buyers, startDate, endDate },
  } = useContext(RatesPreviewContext);

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    [VENDORS_GROUPS_NAME_FILTER]: null,
    [VENDORS_GROUPS_BUYER_NAME_FILTER]: null,
  });
  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const fetchAffectedVendorsGroups = () => {
    setLoading(true);
    fetchVendorsGroups(token, {
      ...filters,
      page: currentPage,
      per,
      [VENDORS_GROUPS_ID_IN_FILTER]: getVendorsGroupsId(buyers),
    })
      .then(({ data }) => {
        const { vendorsGroups, pagination: paginationResponse } = convertToCamelCase(data);
        setGroups(vendorsGroups);
        if (paginationResponse) {
          updatePagination(paginationResponse);
        }
      })
      .catch(() => handleError())
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAffectedVendorsGroups();
  }, [per, current, filters, buyers]);

  const handleChangeFilters = (key, text) => setFilters({ ...filters, [key]: text });

  const handleClearAllFilters = () => {
    setFilters({
      [VENDORS_GROUPS_NAME_FILTER]: null,
      [VENDORS_GROUPS_BUYER_NAME_FILTER]: null,
    });
  };

  return (
    <ConfirmationModalContext.Provider
      value={{
        onSelectListing,
        next,
        previous,
        count,
        per,
        pages,
        currentPage,
        setPageState,
        onKeyDownPage,
        pagination,
        loading,
        id,
        token,
        groups,
        onLeaved,
        filters,
        handleChangeFilters,
        handleClearAllFilters,
        startDate,
        endDate,
      }}
    >
      {children}
    </ConfirmationModalContext.Provider>
  );
};

ConfirmationModalProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  onLeaved: PropTypes.func.isRequired,
};

export default ConfirmationModalProvider;
