// eslint-disable-next-line no-undef
export const BASE_URL = SERVICE_API_URL;
// eslint-disable-next-line no-undef
export const wsUrl = WEBSOCKET_URL;

export const GET_VENDOR_URL = `${BASE_URL}/api/v1/fornecedor`;
export const ANTICIPATIONS_URL = `${BASE_URL}/api/v1/fornecedor/anticipations`;
export const ADVANCES_ACTION_CABLE_CHANNEL = 'LiberSignSignatureChannel';
export const ADVANCES_ACTION_CABLE_ROOM = 'liber_sign_signature_channel_';
export const NEGOTIATIONS_URL = `${BASE_URL}/api/v1/fornecedor/negociacoes`;
export const ADMIN_ADVANCES_URL = `${BASE_URL}/api/v1/admin/vendors/:id/anticipations`;
export const ADVANCES_URL = `${BASE_URL}/api/v1/fornecedor/adiantamentos`;
export const SETUP_DOCUMENTS_URL = `${ADVANCES_URL}/setup_documents`;
export const SIGN_DOCUMENTS_URL = `${ADVANCES_URL}/sign`;
export const ANTICIPATIONS_PAGE_URL = `/fornecedor/antecipacoes`;
export const SOLICITATIONS_PAGE_URL = `/fornecedor/solicitacao`;
export const SIGN_PAGE_URL = `${ANTICIPATIONS_PAGE_URL}/sign`;
export const CANCEL_PAGE_URL = `${ANTICIPATIONS_PAGE_URL}/cancel`;
export const ANTICIPATION_SHOW_URL = `${ANTICIPATIONS_URL}/:id`;
export const ANTICIPATION_BORDEROS_URL = `${ANTICIPATIONS_URL}/:id/borderos`;
export const ANTICIPATION_INVOICES_URL = `${ANTICIPATIONS_URL}/:id/invoices`;
export const SOLICITATION_CANCEL_URL = `${SOLICITATIONS_PAGE_URL}/cancel`;
