import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TooltipBox } from 'liber-components';

import FunderTradesHeaderContext from '../context';

import { Row, PrimaryButton } from './styles';
import LoadingButton from '../../../common-components/Buttons/LoadingButton';
import { downloadReport, downloadTransferAgreements } from '../api';
import { dispatchAmplitudeEvent, handleError, handleSuccess } from '../../../vendor/Utils';
import { createSubscription } from '../../../vendor/action-cable';
import TransferAgreementsModal from '../TransferAgreementsModal';
import {
  FINISHED_NEGOTIATION,
  PROCESSING_NEGOTIATION,
  ACCEPTED_NEGOTIATION,
  DOWNLOAD_AMPLITUDE_EVENT_NAME,
  MODAL_AMPLITUDE_EVENT_NAME,
} from '../constants';

const TOOLTIP_TEXT = 'A exportação leva em conta os filtros aplicados';
const MAX_LIMIT_ERROR = 'query_too_long';

const Header = () => {
  const [downloadReportLoading, setDownloadReportLoading] = useState(false);
  const [downloadTransferAgreementsLoading, setDownloadTransferAgreementsLoading] = useState(false);
  const [showTransferAgreementsModal, setShowTransferAgreementsModal] = useState(false);

  const { token, filters, hasTrades, tradesCount } = useContext(FunderTradesHeaderContext);

  const shouldShowFunderAgreementsButton = () => {
    const showStatuses = [FINISHED_NEGOTIATION, PROCESSING_NEGOTIATION, ACCEPTED_NEGOTIATION];
    return showStatuses.includes(Number(filters.status_eq));
  };

  const onBroadcastReceived = useCallback(payload => {
    setDownloadReportLoading(false);
    const { url, error_msg: errorMessage } = payload;

    if (errorMessage === MAX_LIMIT_ERROR) {
      handleError('O relatório é muito grande!', 'Refine melhor sua busca com os filtros.');
      return;
    }
    if (!url) {
      handleError();
      return;
    }

    handleSuccess('Sucesso!', 'Sua planilha será baixada agora.');
    window.location.assign(url);
  }, []);

  useEffect(() => {
    createSubscription('FunderReportChannel', '', {
      received: onBroadcastReceived,
      connectionFailed: () => console.log('Unable to connect to action cable!'),
    });
  }, [onBroadcastReceived]);

  const onDownloadReport = () => {
    setDownloadReportLoading(true);

    downloadReport({ token, filters }).catch(() => {
      setDownloadReportLoading(false);
      handleError();
    });
  };

  const onShowTransferAgreementsModal = () => {
    dispatchAmplitudeEvent(MODAL_AMPLITUDE_EVENT_NAME, {
      status: filters.status_eq,
    });
    setShowTransferAgreementsModal(true);
  };

  const onDownloadTransferAgreements = () => {
    setDownloadTransferAgreementsLoading(true);

    dispatchAmplitudeEvent(DOWNLOAD_AMPLITUDE_EVENT_NAME, {
      status: filters.status_eq,
    });

    downloadTransferAgreements({ token, filters })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setDownloadTransferAgreementsLoading(false);
        setShowTransferAgreementsModal(false);
      });
  };

  return (
    <Row>
      <h1>Negociações</h1>
      <TooltipBox mount="top" fixed content={TOOLTIP_TEXT}>
        <Row spacing={8}>
          {shouldShowFunderAgreementsButton() && (
            <LoadingButton
              data-testid="download-termos-btn"
              ButtonComponent={PrimaryButton}
              onClick={onShowTransferAgreementsModal}
              loading={downloadTransferAgreementsLoading}
              disabled={!hasTrades}
            >
              Baixar Termos de Cessão
            </LoadingButton>
          )}
          <LoadingButton
            data-testid="download-report-btn"
            ButtonComponent={PrimaryButton}
            onClick={onDownloadReport}
            loading={downloadReportLoading}
            disabled={!hasTrades}
          >
            Baixar planilha
          </LoadingButton>
        </Row>
      </TooltipBox>
      <TransferAgreementsModal
        show={showTransferAgreementsModal}
        onConfirm={onDownloadTransferAgreements}
        onLeaved={() => setShowTransferAgreementsModal(false)}
        tradesCount={tradesCount}
      />
    </Row>
  );
};

export default Header;
