import { Ecommerce } from '../../../libs/googleAnalytics';
import { extractInvoices, extractSelectedItems } from './stateExtractor';

// add_to_cart
const quoteSuccess = (store, action, result) => {
  const {
    // eslint-disable-next-line
    payload: { invoice_id },
  } = action;

  const invoices = extractInvoices(store, 'vendorInvoices').filter(item =>
    invoice_id.includes(item.id),
  );

  Ecommerce.addToCart({ items: invoices });

  console.log('add_to_cart - ', { store, action, result });
};

// remove_from_cart
const toggleInvoiceSelection = (store, action, result) => {
  const {
    payload: { invoice },
  } = action;

  const invoices = extractInvoices(store, 'vendorInvoices').filter(item => item.id === invoice.id);

  Ecommerce.removeFromCart({ items: invoices });

  console.log('remove_from_cart - ', { store, action, result });
};

// begin_checkout
const anticipateInvoices = (store, action, result) => {
  const selectedItems = extractSelectedItems(store, 'vendorInvoices');
  const invoices = extractInvoices(store, 'vendorInvoices').filter(item =>
    selectedItems.includes(item.id),
  );

  Ecommerce.beginCheckout({ items: invoices });

  console.log('begin_checkout - ', { store, action, result });
};

// purchase
const documentsSigned = (store, action, result) => {
  const {
    payload: { documents },
  } = action;

  const documentsId = documents.map(item => item.id);
  const invoices = extractInvoices(store, 'vendorInvoices').filter(item =>
    documentsId.includes(item.id),
  );

  Ecommerce.purchase({ items: invoices });

  console.log('purchase - ', { store, action, result });
};

const updateSelectedInvoices = (store, action) => {
  const {
    payload: { selectedInvoices, unselectedInvoices },
  } = action;

  const invoices = extractInvoices(store, 'invoiceSimulation');

  if (selectedInvoices.length > 0) {
    Ecommerce.addToCart({ items: invoices.filter(item => selectedInvoices.includes(item.id)) });
    return;
  }

  if (unselectedInvoices.length > 0) {
    Ecommerce.removeFromCart({
      items: invoices.filter(item => unselectedInvoices.includes(item.id)),
    });
  }
};

const beginAnticipation = store => {
  const selectedItems = extractSelectedItems(store, 'invoiceSimulation');
  const invoices = extractInvoices(store, 'invoiceSimulation').filter(item =>
    selectedItems.includes(item.id),
  );

  Ecommerce.beginCheckout({ items: invoices });
};

const requestAnticipationSuccess = store => {
  const selectedItems = extractSelectedItems(store, 'invoiceSimulation');
  const invoices = extractInvoices(store, 'invoiceSimulation').filter(item =>
    selectedItems.includes(item.id),
  );

  Ecommerce.purchase({ items: invoices });
};

const ecommerceActions = {
  QUOTE_SUCCESS: quoteSuccess,
  TOGGLE_INVOICE_SELECTION: toggleInvoiceSelection,
  ANTICIPATE_INVOICES: anticipateInvoices,
  DOCUMENTS_SIGNED: documentsSigned,
  INVOICE_SIMULATION_UPDATE_SELECTED_INVOICES: updateSelectedInvoices,
  INVOICE_SIMULATION_BEGIN_ANTICIPATION: beginAnticipation,
  INVOICE_SIMULATION_REQUEST_ANTICIPATION_SUCCESS: requestAnticipationSuccess,
};

export const ecommerceGoogleAnalytics = store => next => action => {
  const result = next(action);
  const ecommerceAction = ecommerceActions[action.type];
  console.log({ store, action, ecommerceAction, result, state: store.getState() });
  if (!ecommerceAction) {
    return result;
  }
  ecommerceAction(store, action, result);
  return result;
};
