import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import { Container, Label, CleanAll, Filtered } from './Filters.styles';
import { setFilter } from '../../actions/InvoiceSimulationAction';
import {
  ArrayFieldFilter,
  TextFieldFilter,
  DateFieldFilter,
} from '../../../../common-components/Filters';
import {
  NUMBER_IN,
  CNPJ_EQ,
  EXPIRES_AT_EQ,
  EXPIRES_AT_GTEQ,
  EXPIRES_AT_LTEQ,
  STATUS_EQ,
  MAPPED_STATUS_IN,
} from '../constants';
import OptionsFilter from '../../../../common-components/Filters/OptionsFilter/OptionsFilter';

export class Filters extends React.Component {
  updateInvoicesFilter = values => {
    const { filters, onFilter, token } = this.props;

    const joinedInvoices = filters[NUMBER_IN] ? filters[NUMBER_IN].concat(values) : values;

    const newFilters = { ...filters, ...this.applyFilter('invoices', uniq(joinedInvoices)) };

    onFilter(token, newFilters);
  };

  handleFilterClear = filter => () => {
    this.handleFilter(filter)();
  };

  applyFilter = (filter, value) => {
    switch (filter) {
      case 'invoices':
        return { [NUMBER_IN]: value && value.length > 0 ? value : null };
      case 'cnpj':
        return {
          [CNPJ_EQ]: value && value !== '' ? value : null,
        };
      case 'range':
        return value && value.end
          ? {
              [EXPIRES_AT_GTEQ]: value.start.format(),
              [EXPIRES_AT_LTEQ]: value.end.format(),
              [EXPIRES_AT_EQ]: null,
            }
          : {
              [EXPIRES_AT_EQ]: value && value.start ? value.start.format() : null,
              [EXPIRES_AT_GTEQ]: null,
              [EXPIRES_AT_LTEQ]: null,
            };
      case 'status':
        return {
          [STATUS_EQ]: value,
        };
      case 'mapped_status':
        return {
          [MAPPED_STATUS_IN]: value && value.length > 0 ? value : null,
        };
      default:
        return {};
    }
  };

  handleFilter = filter => value => {
    const { onFilter, token, filters } = this.props;
    const newFilters = { ...filters, ...this.applyFilter(filter, value) };
    onFilter(token, newFilters);
  };

  handleClearAll = () => {
    const { onFilter, token } = this.props;
    const clear = {
      [NUMBER_IN]: null,
      [CNPJ_EQ]: null,
      [EXPIRES_AT_EQ]: null,
      [EXPIRES_AT_GTEQ]: null,
      [EXPIRES_AT_LTEQ]: null,
      [STATUS_EQ]: null,
    };
    onFilter(token, clear);
  };

  pluralize = count => {
    if (count === 1) return '1 título filtrado';
    return `${count} títulos filtrados`;
  };

  renderOnFilter = () => {
    const { filters, invoiceCount } = this.props;
    if (Object.values(filters).filter(item => !!item).length !== 0) {
      return (
        <>
          <CleanAll onClick={this.handleClearAll}>Limpar filtros</CleanAll>
          <Filtered>{this.pluralize(invoiceCount)}</Filtered>
        </>
      );
    }
    return null;
  };

  render() {
    const { filters, withContainer } = this.props;

    const content = (
      <>
        <Label>Filtrar por</Label>
        <ArrayFieldFilter
          label="Título"
          selectedItems={filters[NUMBER_IN]}
          hintText="Digite o(s) número(s) do(s) título(s)"
          onClear={this.handleFilterClear('invoices')}
          onFilter={this.updateInvoicesFilter}
        />
        <DateFieldFilter
          label="Vencimento"
          description="Selecione uma data de início e uma data de fim para o período de vencimento:"
          onFilter={this.handleFilter('range')}
          onClear={this.handleFilterClear('range')}
          selectedItems={[
            filters[EXPIRES_AT_GTEQ],
            filters[EXPIRES_AT_LTEQ],
            filters[EXPIRES_AT_EQ],
          ]}
        />
        <TextFieldFilter
          label="CNPJ"
          mask="99.999.999/9999-99"
          hintText="Digite o número do CNPJ"
          onFilter={this.handleFilter('cnpj')}
          onClear={this.handleFilterClear('cnpj')}
          selectedItems={filters[CNPJ_EQ]}
        />
        <OptionsFilter
          allowMultiple
          filterValues={filters[MAPPED_STATUS_IN]}
          onFilter={this.handleFilter('mapped_status')}
          onClear={this.handleFilterClear('mapped_status')}
          options={[
            { option: 'Disponível', value: 'eligible_for_anticipation' },
            { option: 'Bloqueado', value: 'blocked' },
            { option: 'Indisponível', value: 'unavailable' },
            { option: 'Não elegível', value: 'not_eligible_for_anticipation' },
          ]}
          label="Status"
        />
        {/* {showUnavailable && (
          <BooleanFilter
            label="Títulos Indisponíveis"
            filters={filters[STATUS_EQ]}
            onFilter={this.handleFilter('status')}
          />
        )} */}

        {this.renderOnFilter()}
      </>
    );

    return withContainer ? <Container>{content}</Container> : content;
  }
}

Filters.propTypes = {
  filters: PropTypes.shape({
    [NUMBER_IN]: PropTypes.arrayOf(PropTypes.string),
    [CNPJ_EQ]: PropTypes.string,
    [EXPIRES_AT_EQ]: PropTypes.string,
    [EXPIRES_AT_GTEQ]: PropTypes.string,
    [EXPIRES_AT_LTEQ]: PropTypes.string,
    [STATUS_EQ]: PropTypes.string,
  }).isRequired,
  token: PropTypes.string.isRequired,
  invoiceCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func,
  withContainer: PropTypes.bool,
};

Filters.defaultProps = {
  onFilter: () => {},
  withContainer: true,
};

const mapDispatchToProps = {
  onFilter: setFilter,
};

const mapStateToProps = ({ invoiceSimulation: { filters, invoices } }) => ({
  filters,
  invoiceCount: invoices.length,
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
