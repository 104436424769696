import currencyFormatter from 'currency-formatter';

import { fixPercent } from '../../../vendor/Utils';

export const formatCurrency = value =>
  currencyFormatter.format(value, { code: 'BRL', format: '%s %v' });

export const getPercent = number => `${fixPercent(number)}%`;

export const empty = null;
