import axios from 'axios';
import ReactOnRails from 'react-on-rails';

export const renderMaskedEmail = contextEmail => {
  const tokens = contextEmail.split('@');
  const domain = tokens[1];
  let address = tokens[0];
  address = `${address[0]}${
    address.length > 1 ? '*'.repeat(address.length - 2) + address[address.length - 1] : ''
  }`;
  return `${address}@${domain}`;
};

export const toggleFetch = () => ({ type: 'LOGIN/CONFIRMATION/TOGGLE_FETCH' });

export const resendEmail = () => dispatch => {
  const csrfToken = ReactOnRails.authenticityToken();
  dispatch(toggleFetch());
  return axios({
    method: 'POST',
    url: '/investidores/confirmation',
    data: {
      authenticity_token: csrfToken,
    },
    responseType: 'json',
  })
    .then(() => {
      dispatch(toggleFetch());
      dispatch({ type: 'LOGIN/CONFIRMATION/RESEND_EMAIL/SUCCESSFULL_SUBMIT' });
    })
    .catch(() => {
      dispatch(toggleFetch());
      dispatch({ type: 'LOGIN/CONFIRMATION/RESEND_EMAIL/FAILED_SUBMIT' });
    });
};
