import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiCogs, mdiAccountGroup } from '@mdi/js';
import ScreenTitle from '../../../../common-components/ScreenTitle';
import { Container, Button, Row, ButtonsRow } from './styles';
import { NEW_VENDOR_PATH, VENDORS_GROUP_PATH } from '../../urls';
import VendorsTable from '../VendorsTable';
import UploadModal from '../UploadModal';
import { dispatchAmplitudeEvent, handleNavigate } from '../../../../vendor/Utils';
import { Context } from '../../context';
import CardFilter from '../CardFilter';

export default function BuyerVendors({ limitsAvailable, groupsAvailable }) {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const { updateVendors } = useContext(Context);

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  const navigateToGroups = () => {
    dispatchAmplitudeEvent('buyer_vendors_list_see_groups');
    window.location.assign(VENDORS_GROUP_PATH);
  };

  return (
    <Container>
      <Row>
        <ScreenTitle>Fornecedores</ScreenTitle>
        <ButtonsRow>
          {limitsAvailable && (
            <Button outlined onClick={handleOpenUploadModal}>
              <Icon path={mdiCogs} />
              ATUALIZAR LIMITES
            </Button>
          )}
          {groupsAvailable && (
            <Button outlined onClick={navigateToGroups}>
              <Icon path={mdiAccountGroup} />
              VER GRUPOS DE FORNECEDORES
            </Button>
          )}
          <Button outlined onClick={() => handleNavigate(NEW_VENDOR_PATH)}>
            ADICIONAR FORNECEDOR
          </Button>
        </ButtonsRow>
      </Row>
      <CardFilter />
      <VendorsTable limitsAvailable={limitsAvailable} groupsAvailable={groupsAvailable} />
      <UploadModal
        show={showUploadModal}
        onLeaved={() => setShowUploadModal(false)}
        onUpload={updateVendors}
      />
    </Container>
  );
}

BuyerVendors.propTypes = {
  limitsAvailable: PropTypes.bool,
  groupsAvailable: PropTypes.bool,
};

BuyerVendors.defaultProps = {
  limitsAvailable: true,
  groupsAvailable: true,
};
