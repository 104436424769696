import styled, { css } from 'styled-components';

export const Label = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.06px;
  text-align: left;
  color: #405f71;
`;

export const Value = styled(Label)`
  font-weight: normal;
  line-height: 1.84;
  color: #6287a7;
  margin-top: 8px;
`;

export const SubValue = styled(Value)`
  font-size: 12px;
  line-height: 2.45;
  letter-spacing: 0.05px;
  color: #6287a7;
  margin-top: 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding-top: 24px;
    `}
`;
