import { connect } from 'react-redux';
import Info from './Info';

const mapStateToProps = state => ({
  open: state.info.open,
});

const VisibleInfo = connect(mapStateToProps)(Info);

export default VisibleInfo;
