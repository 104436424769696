import styled from 'styled-components';

export const Label = styled.div`
  font-family: Roboto;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #405f71;
  margin-right: 16px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const Container = styled(Flex)`
  border: 1px solid #b6c2c9;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 32px;
`;
