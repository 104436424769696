import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from 'liber-components';
import { mdiAlert, mdiGridLarge, mdiInformation } from '@mdi/js';
import * as S from './styles';
import { DOWNLOAD_MESSAGE, MAIL_LINK } from '../../constants';
import { UploadContext } from '../../context';

const UploadForm = ({ kind }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef();
  const { exampleFiles } = useContext(UploadContext);

  const handleButtonClick = event => {
    event.stopPropagation();
    inputRef.current.click();
  };

  const handleFileUpload = event => {
    const [file] = event?.target?.files;
    setSelectedFile(file);
  };

  return (
    <S.Card>
      <CardHeader>Curva de Taxas</CardHeader>
      <S.Content>
        <S.Columns>
          {!selectedFile && (
            <S.Row>
              <S.Icon path={mdiAlert} color="#FABE00" />
              Nenhum arquivo selecionado
            </S.Row>
          )}
          <S.Input
            data-testid="spreadsheet-upload-input"
            onChange={handleFileUpload}
            ref={inputRef}
          />
          <S.Button onClick={handleButtonClick}>
            <S.Icon path={mdiGridLarge} color="#fff" />
            {selectedFile?.name || 'ENVIAR ARQUIVO'}
          </S.Button>
          <a href={exampleFiles[kind]}>
            Baixar modelo de planilha para taxas por {DOWNLOAD_MESSAGE[kind]}
          </a>
        </S.Columns>
        <S.Row>
          <S.Icon path={mdiInformation} color="#009DFF" />
          <p>
            Dúvidas sobre o preenchimento da planilha padrão? Veja como preencher{' '}
            <a href={MAIL_LINK}>aqui</a>
          </p>
        </S.Row>
      </S.Content>
    </S.Card>
  );
};

UploadForm.propTypes = {
  kind: PropTypes.oneOf(['term', 'date']).isRequired,
};

export default UploadForm;
