import styled from 'styled-components';
import { Drawer as RawDrawer, Button as RawButton, PrimaryButton } from 'liber-components';

export const Drawer = styled(RawDrawer).attrs(() => ({ size: 700, position: 'right' }))``;

export const Title = styled.h4`
  font-size: 28px;
  font-weight: 700;
  color: #405f71;
`;

export const Header = styled.div`
  padding: 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const Footer = styled(Header)`
  justify-content: flex-end;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const CloseButton = styled(RawButton).attrs(() => ({
  circle: true,
  version: 2,
  flat: true,
}))``;

export const Button = styled(PrimaryButton).attrs(() => ({ outlined: true, version: 2 }))``;
