import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, CardFooter } from 'liber-components';
import {
  NegotiationStatus,
  NegotiationContent,
  NegotiationContentInvoicesCountContent,
  NegotiationContentInvoicesCount,
  NegotiationContentValue,
  NegotiationLinkDetails,
  MdChevronRightStyled,
  CardStyled,
} from './InvoiceSimulationNegotiationCard.styles';
import { formatMoney } from '../../../withdraw/moneyHandler';
import { MAP_STATUS_NEGOTIATION } from '../../utils';

const InvoiceSimulationNegotiationCard = ({
  status,
  invoicesCount,
  value,
  detailsUrl,
  disabled,
}) => (
  <CardStyled>
    <CardContent>
      <NegotiationStatus>{MAP_STATUS_NEGOTIATION[status]}</NegotiationStatus>
      <NegotiationContent>
        <NegotiationContentInvoicesCountContent disabled={disabled}>
          <NegotiationContentInvoicesCount>{invoicesCount}</NegotiationContentInvoicesCount>
          títulos
        </NegotiationContentInvoicesCountContent>
        <NegotiationContentValue disabled={disabled}>
          R$ {formatMoney(value)}
        </NegotiationContentValue>
      </NegotiationContent>
    </CardContent>
    <CardFooter>
      <NegotiationLinkDetails href={detailsUrl} disabled={disabled}>
        Ver detalhes
        <MdChevronRightStyled disabled={disabled} />
      </NegotiationLinkDetails>
    </CardFooter>
  </CardStyled>
);

InvoiceSimulationNegotiationCard.propTypes = {
  status: PropTypes.string,
  invoicesCount: PropTypes.number,
  value: PropTypes.number,
  detailsUrl: PropTypes.string,
  disabled: PropTypes.bool,
};

InvoiceSimulationNegotiationCard.defaultProps = {
  status: '',
  invoicesCount: 0,
  value: 0,
  detailsUrl: '',
  disabled: false,
};

export default InvoiceSimulationNegotiationCard;
