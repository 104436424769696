import React, { useContext } from 'react';
import moment from 'moment';
import {
  Typography,
  Icon,
  Button,
  TextField,
  Select,
  MenuItem,
  RangeDatePicker,
} from 'liber-salti';
import { Grid } from '@mui/material';

import {
  BUYER_EQ_FILTER,
  EMOLUMENTS_VALUE_EQ_FILTER,
  FACE_VALUE_EQ_FILTER,
  STATUSES_FILTER_OPTIONS,
  TRADE_EXPIRATION_DATE_GTEQ_FILTER,
  TRADE_EXPIRATION_DATE_LTEQ_FILTER,
  TRADE_FINISHED_AT_GTEQ_FILTER,
  TRADE_FISINIHED_AT_LTEQ_FILTER,
  TRADE_NUMBER_EQ_FILTER,
  TRADE_STATUS_EQ_FILTER,
  VENDOR_EQ_FILTER,
} from '../../constants';
import { FunderTradesSettlementContext } from '../../context';

const Filters = () => {
  const { filters, updateFilter, fetchTradeSettlements, clearFilters } = useContext(
    FunderTradesSettlementContext,
  );

  const handleDateFilterChange = (startDateFilter, endDateFilter) => dates => {
    const { startDate, endDate } = dates;

    updateFilter(startDateFilter)(startDate);
    updateFilter(endDateFilter)(endDate);
  };

  const getDateFromFilters = filterKey => {
    const filterValue = filters[filterKey];

    if (!filterValue) return null;

    return moment(filterValue);
  };

  return (
    <Grid item container spacing={2}>
      <Grid item container>
        <Grid item container xs sx={{ gap: '16px' }}>
          <Icon name="Options" size="large" />
          <Typography variant="h2" color="textPrimary">
            Filtros
          </Typography>
        </Grid>
        <Grid item container xs sx={{ justifyContent: 'flex-end', gap: '8px' }}>
          <Button onClick={clearFilters} variant="text" size="medium">
            Limpar Filtros
          </Button>
          <Button onClick={fetchTradeSettlements} variant="outlined" size="medium" color="primary">
            Aplicar Filtros
          </Button>
        </Grid>
      </Grid>
      <Grid item container xs spacing={2}>
        <Grid item xs md={3} sm={6} size="large">
          <Select onChange={updateFilter(BUYER_EQ_FILTER)} fullWidth label="Sacado" />
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <Select onChange={updateFilter(VENDOR_EQ_FILTER)} fullWidth label="Cedente" />
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <TextField
            onChange={updateFilter(TRADE_NUMBER_EQ_FILTER)}
            type="number"
            fullWidth
            placeholder="Número do(s) título(s)"
          />
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <TextField
            onChange={updateFilter(FACE_VALUE_EQ_FILTER)}
            fullWidth
            type="number"
            label="Valor face do título"
            prefixText="R$"
          />
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <TextField
            onChange={updateFilter(EMOLUMENTS_VALUE_EQ_FILTER)}
            fullWidth
            type="number"
            label="Rendimento"
            prefixText="R$"
          />
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <Select
            onChange={updateFilter(TRADE_STATUS_EQ_FILTER)}
            fullWidth
            label="Status do título"
          >
            {STATUSES_FILTER_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <RangeDatePicker
            onChange={handleDateFilterChange(
              TRADE_FINISHED_AT_GTEQ_FILTER,
              TRADE_FISINIHED_AT_LTEQ_FILTER,
            )}
            fullWidth
            placeholder="Período de compra"
            startDate={getDateFromFilters(TRADE_FINISHED_AT_GTEQ_FILTER)}
            endDate={getDateFromFilters(TRADE_FISINIHED_AT_LTEQ_FILTER)}
          />
        </Grid>
        <Grid item xs md={3} sm={6} size="large">
          <RangeDatePicker
            fullWidth
            placeholder="Período do vencimento"
            onChange={handleDateFilterChange(
              TRADE_EXPIRATION_DATE_GTEQ_FILTER,
              TRADE_EXPIRATION_DATE_LTEQ_FILTER,
            )}
            startDate={getDateFromFilters(TRADE_EXPIRATION_DATE_GTEQ_FILTER)}
            endDate={getDateFromFilters(TRADE_EXPIRATION_DATE_LTEQ_FILTER)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
