import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer, Liber } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import hydrateStore from '../../../store';
import Selection from './connect';

const View = ({ store }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <ThemeProvider theme={Liber}>
        <Selection />
      </ThemeProvider>
    </Provider>
    <ToastContainer />
  </>
);

View.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

View.defaultProps = {};

export default View;
