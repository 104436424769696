import { connect } from 'react-redux';
import Distribution from './Distribution';
import { getDistributionData } from '../../actions/DistributionActions';
import { getDialogContent } from '../InvoicesDialog/redux';

const reducer = 'distribution';

const parentReducer = 'investorDashboard';

const mapStateToProps = state => ({ ...state[parentReducer][reducer] });
const mapDispatchToProps = {
  getDistributionData,
  getDialogContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
