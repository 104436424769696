import React, { useContext } from 'react';
import { CompleteTable, TooltipBox } from 'liber-components';
import {
  Bunny,
  DetailsIcon,
  IconButton,
  LimiterContainer,
  Loading,
  ScrollContainer,
} from './styles';
import { dispatchAmplitudeEvent, getPaginationInterval } from '../../../../vendor/Utils';
import { FunderVendorsGroupsContext } from '../../context';
import Filters from '../Filters';

const GroupsTable = () => {
  const {
    loading,
    groups,
    currentPage,
    setPageState,
    setCurrent,
    next,
    previous,
    per,
    count,
    pages,
    onKeyDownPage,
    pagination,
    onSelectListing,
  } = useContext(FunderVendorsGroupsContext);

  const dispatchEvent = ({ id, name, referenceCode }) =>
    dispatchAmplitudeEvent('funder_vendors_list_details_groups', {
      id,
      name,
      referenceCode,
    });

  const columns = [
    {
      name: 'referenceCode',
      label: <>&ensp;&ensp;Código de referência</>,
      width: 30,
      // eslint-disable-next-line react/prop-types
      getCellValue: ({ referenceCode }) => <>&ensp;&ensp;{referenceCode}</>,
    },
    {
      name: 'name',
      label: 'Nome do Grupo',
      width: 30,
    },
    {
      name: 'buyer',
      label: 'Sacado',
      width: 30,
      getCellValue: ({ buyer }) => buyer.name,
    },
    {
      name: 'vendorsCount',
      label: 'Número de Fornecedores',
      width: 20,
    },
    {
      name: 'options',
      label: 'Opções',
      width: 10,
      // eslint-disable-next-line react/prop-types
      getCellValue: group => (
        <TooltipBox mount="top" fixed content="Visualizar Grupo">
          <IconButton
            href={`/investidor/painel_taxas/grupos/${group.id}`}
            onClick={() => dispatchEvent(group)}
            data-testid="vendors-group-info"
          >
            <DetailsIcon />
          </IconButton>
        </TooltipBox>
      ),
    },
  ];

  return (
    <Loading loading={loading}>
      {loading && <Bunny />}
      <ScrollContainer>
        <LimiterContainer>
          <CompleteTable
            columns={columns}
            filters={<Filters />}
            paginationProps={{
              currentPage,
              pageTotal: pages,
              onChangePage: ({ target: { value } }) => setPageState(value),
              onClickNext: () => setCurrent(next),
              onClickPrev: () => setCurrent(previous),
              onKeyDownPage,
              hasListing: true,
              listingProps: {
                listing: String(per),
                total: String(count),
                showInterval: getPaginationInterval(pagination),
                onSelectListing,
                listingLabelSingular: 'grupo',
                listingLabelPlural: 'grupos',
              },
            }}
            items={groups}
          />
        </LimiterContainer>
      </ScrollContainer>
    </Loading>
  );
};

export default GroupsTable;
