import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateSelectedVendorBuyerId,
  updateSelectedVendorId,
} from '../../actions/AdminVendorsActions';
import VendorDetailsButton from '../../../../common-components/VendorDetailsButton';

export const FundersButton = ({
  setSelectedVendorId,
  setSelectedVendorBuyerId,
  vendorId,
  buyerId,
  ...props
}) => {
  const handleClick = event => {
    event.stopPropagation();
    setSelectedVendorId(vendorId);
    setSelectedVendorBuyerId(buyerId);
  };

  return <VendorDetailsButton onClick={handleClick} {...props} />;
};

FundersButton.propTypes = {
  vendorId: PropTypes.number.isRequired,
  buyerId: PropTypes.number.isRequired,
  setSelectedVendorBuyerId: PropTypes.func.isRequired,
  setSelectedVendorId: PropTypes.func.isRequired,
  blockedByBuyer: PropTypes.bool,
  disabled: PropTypes.bool,
  activeFunders: PropTypes.number.isRequired,
  totalFunders: PropTypes.number.isRequired,
};

FundersButton.defaultProps = {
  blockedByBuyer: false,
  disabled: false,
};

const mapDispatchToProps = {
  setSelectedVendorId: updateSelectedVendorId,
  setSelectedVendorBuyerId: updateSelectedVendorBuyerId,
};

export default connect(null, mapDispatchToProps)(FundersButton);
