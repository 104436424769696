import styled from 'styled-components';

import MarcaLiber from '../../../../../../assets/images/salti_logo.svg';

export const Container = styled.div`
  min-height: 100vh;
  padding: 40px;
`;

export const Content = styled.div`
  margin: 0 auto 48px auto;
  max-width: 424px;
`;

export const CardContent = styled.div`
  padding: 56px 24px;
`;

export const Logo = styled.img.attrs({ alt: 'liber logo', src: MarcaLiber })`
  width: 98px;
`;

export const LogoWrapper = styled.div`
  margin: 24px auto 48px auto;
  width: fit-content;
`;

export const CaptionWrapper = styled.div`
  margin: 20px 0;
`;

export const ActionsWrapper = styled.div`
  margin-top: 32px;
`;

export const CompaniesContainer = styled.div`
  overflow-y: auto;
  max-height: 320px;
`;

export const SectionTitle = styled.div`
  margin: 12px 0;
`;
