import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

const VirtualizedList = ({ rowRenderer, rowCount }) => (
  <List
    width={460}
    height={368}
    rowHeight={90}
    rowRenderer={rowRenderer}
    rowCount={rowCount}
    overscanRowCount={3}
  />
);

VirtualizedList.propTypes = {
  rowRenderer: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default VirtualizedList;
