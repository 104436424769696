import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BlackboardBunnyImage from '../../../../../../assets/images/empty-states/blackboard_bunny.png';
import RoleBunny from '../../../../../../assets/images/empty-states/role_bunny.png';
import ClockBunny from '../../../../../../assets/images/empty-states/clock_bunny.png';
import Envelope from '../../../../../../assets/images/empty-states/envelope.png';
import Background from '../../../../../../assets/images/empty-states/background.png';
import {
  InvoiceSimulationEmptyStateContent,
  InvoiceSimulationEmptyStateLeftImage,
  InvoiceSimulationEmptyStateRightImage,
  InvoicesimulationEmptyText,
  InvoicesimulationEmptyHeader,
  InvoicesimulationEmptyParagraph,
} from './InvoiceSimulationEmptyState.styles';

import { INVOICE_TABS, tabType } from '../../utils';

const { AVAILABLE, ALL, ADVANCED, NOT_ADVANCED } = INVOICE_TABS;

const getEmptyStateByTabId = (tabId, isFiltered) => {
  let imagePath;
  let headerText;
  let paragraphText;

  if (isFiltered) {
    return {
      imagePath: RoleBunny,
      headerText: 'Nenhum título encontrado',
      paragraphText: 'Verifique se seus filtros foram preenchidos corretamente',
    };
  }

  switch (tabId) {
    case ALL: // Todos
      imagePath = RoleBunny;
      headerText = 'Ainda não há títulos por aqui...';
      paragraphText = `Seu cliente ainda não cadastrou pagamentos na plataforma, 
      entre em contato para que seus títulos sejam inseridos!`;
      break;
    case AVAILABLE: // Disponíveis
      imagePath = ClockBunny;
      headerText = 'Não há títulos disponíveis para antecipação';
      paragraphText = `Você poderá solicitar suas antecipações assim que seu cliente inserir 
      e/ou confirmar os títulos!`;
      break;
    case ADVANCED: // Antecipados
      imagePath = BlackboardBunnyImage;
      headerText = 'Você ainda não antecipou nenhum título';
      paragraphText = `Quando você finalizar sua primeira negociação, 
      aqui estará o seu histórico de antecipações.`;
      break;
    case NOT_ADVANCED: // Vencidos
      imagePath = BlackboardBunnyImage;
      headerText = 'Não há títulos que não foram antecipados';
      paragraphText =
        'Por enquanto, não há títulos que já passaram da data de vencimento e deixaram de ser antecipados.';
      break;
    default:
      imagePath = BlackboardBunnyImage;
      headerText = 'Ainda não há títulos por aqui...';
      paragraphText = `Seu cliente ainda não cadastrou pagamentos na plataforma, 
        entre em contato para que seus títulos sejam inseridos!`;
  }
  return {
    imagePath,
    headerText,
    paragraphText,
  };
};

export const InvoiceSimulationEmptyState = ({ tabId, isFiltered }) => {
  const { imagePath, headerText, paragraphText } = getEmptyStateByTabId(tabId, isFiltered);

  return (
    <InvoiceSimulationEmptyStateContent backgroundPath={Background}>
      <InvoiceSimulationEmptyStateLeftImage src={imagePath} />
      <InvoicesimulationEmptyText>
        <InvoicesimulationEmptyHeader>{headerText}</InvoicesimulationEmptyHeader>
        <InvoicesimulationEmptyParagraph>{paragraphText}</InvoicesimulationEmptyParagraph>
      </InvoicesimulationEmptyText>
      <InvoiceSimulationEmptyStateRightImage src={Envelope} />
    </InvoiceSimulationEmptyStateContent>
  );
};

InvoiceSimulationEmptyState.propTypes = {
  tabId: tabType.isRequired,
  isFiltered: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ invoiceSimulation: { isFiltered } }) => ({ isFiltered });

export default connect(mapStateToProps)(InvoiceSimulationEmptyState);
