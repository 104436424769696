import React from 'react';
import PropTypes from 'prop-types';

import { convertToCamelCase } from '../../vendor/Utils';

import SaltiThemeWrapper from '../../common-components/SaltiThemeWrapper';
import OperatorCompanySelection from './components/OperatorCompanySelection';

const View = props => {
  const { operator, organizations } = convertToCamelCase(props);

  return (
    <SaltiThemeWrapper>
      <OperatorCompanySelection operator={operator} organizations={organizations} />
    </SaltiThemeWrapper>
  );
};

const companyProps = PropTypes.arrayOf(
  PropTypes.shape({
    organization: PropTypes.shape({
      cnpj: PropTypes.string.isRequired,
      trade_name: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    target: PropTypes.shape({
      trade_name: PropTypes.string.isRequired,
    }),
  }),
);

View.propTypes = PropTypes.shape({
  operator: PropTypes.shape({
    cpf: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    logout: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  organizations: PropTypes.shape({
    count: PropTypes.number.isRequired,
    main_access_path: PropTypes.string.isRequired,
    funder: companyProps.isRequired,
    payer: companyProps.isRequired,
    vendor: companyProps.isRequired,
  }),
});

View.defaultProps = {};

export default View;
