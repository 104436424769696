import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import hydrateStore from '../store';
import InvoiceSimulation from './components/InvoiceSimulation';

const View = ({
  token,
  fornecedorId,
  canInputXml,
  inputXmlPath,
  hasClicked,
  vendor,
  showButton,
}) => (
  <>
    <Provider store={hydrateStore({})}>
      <ThemeProvider theme={LiberV4}>
        <InvoiceSimulation
          token={token}
          fornecedorId={fornecedorId}
          canInputXml={canInputXml}
          inputXmlPath={inputXmlPath}
          hasClicked={hasClicked}
          vendor={vendor}
          showButton={showButton}
        />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  fornecedorId: PropTypes.string.isRequired,
  inputXmlPath: PropTypes.string,
  canInputXml: PropTypes.bool,
  hasClicked: PropTypes.bool,
  vendor: PropTypes.bool,
  showButton: PropTypes.bool,
};

View.defaultProps = {
  inputXmlPath: '',
  canInputXml: false,
  hasClicked: false,
  vendor: {},
  showButton: false,
};

export default View;
