import { combineReducers } from 'redux';
import changePassword from './changePassword';
import account from './account';
import contract from './contract';
import address from './address';
import bankAccount from './bankAccount';

export default combineReducers({
  changePassword,
  account,
  contract,
  address,
  bankAccount,
});
