import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import InvestorSpreadsheetRates from './components/InvestorSpreadsheetRates';
import { SpreadsheetRatesContextProvider } from './context';

function View({ token, exampleFile }) {
  return (
    <ThemeProvider theme={Theme}>
      <SpreadsheetRatesContextProvider token={token} exampleFile={exampleFile}>
        <InvestorSpreadsheetRates />
        <ToastContainer />
      </SpreadsheetRatesContextProvider>
    </ThemeProvider>
  );
}

View.propTypes = {
  token: PropTypes.string,
  exampleFile: PropTypes.string,
};

View.defaultProps = {
  token: '',
  exampleFile: '',
};

export default View;
