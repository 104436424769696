import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiDownload, mdiOpenInNew } from '@mdi/js';
import ScreenTitle from '../../../common-components/ScreenTitle/ScreenTitle';
import {
  Text,
  Card,
  CardHeader,
  DownloadLink,
  PdfObject,
  ErrorContainer,
  Checkbox,
  CardFooter,
  PullRight,
  Button,
  EmptyContainer,
} from './AcceptTerms.styles';
import { acceptNewContract } from '../api';

const CONTRACT_NAME = 'Contrato de Comissão Atualizado - Liber Capital.pdf';
const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=br.com.libercapital.libermobile.android';
const IOS_APP_URL = 'https://apps.apple.com/br/app/liber-capital/id1451104122';

const AcceptTerms = ({ token, adhesionContract }) => {
  const [agreed, setAgreed] = useState(false);

  const handleCheck = ({ target: { checked } }) => setAgreed(checked);
  const handleAcceptContract = () => acceptNewContract(token);

  return (
    <>
      <ScreenTitle>Atualização do Contrato</ScreenTitle>
      <Text>
        A aceitação dos termos do Contrato de Comissão atualizados é necessária para continuar
        acessando o portal web e serviços da Liber Capital. <br />
        Caso não concorde com os termos, será possível apenas acessar o histórico de negociações via
        aplicativo mobile (
        <a href={ANDROID_APP_URL} target="_blank" rel="noopener noreferrer">
          Android <Icon path={mdiOpenInNew} />
        </a>{' '}
        ou{' '}
        <a href={IOS_APP_URL} target="_blank" rel="noopener noreferrer">
          iOS <Icon path={mdiOpenInNew} />
        </a>
        ).
      </Text>
      {adhesionContract ? (
        <>
          <Card>
            <CardHeader>
              Contrato de Comissão
              <DownloadLink download={CONTRACT_NAME} href={adhesionContract}>
                <Icon path={mdiDownload} />
                Baixar
              </DownloadLink>
            </CardHeader>
            <PdfObject data={adhesionContract}>
              <ErrorContainer>
                <Text>
                  Exibição de PDF não suportada. <br />
                  Baixe o contrato no botão acima para visualizá-lo.
                </Text>
              </ErrorContainer>
            </PdfObject>
            <CardFooter>
              <Checkbox id="accept-contract-checkbox" checked={agreed} onChange={handleCheck}>
                Li e concordo com a atualização dos termos do Contrato de Comissão
              </Checkbox>
            </CardFooter>
          </Card>
          <PullRight>
            <Button id="accept-contract-button" disabled={!agreed} onClick={handleAcceptContract}>
              Concluir
            </Button>
          </PullRight>
        </>
      ) : (
        <EmptyContainer>
          <Text>Não foi possível carregar o documento.</Text>
        </EmptyContainer>
      )}
    </>
  );
};

AcceptTerms.propTypes = {
  token: PropTypes.string,
  adhesionContract: PropTypes.string,
};

AcceptTerms.defaultProps = {
  token: null,
  adhesionContract: null,
};

export default AcceptTerms;
