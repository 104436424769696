import styled from 'styled-components';
import { Card as LiberCard, media } from 'liber-components';

export const Page = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Card = styled(LiberCard)`
  margin: auto !important;
  overflow: hidden;
  width: 100%;

  ${media.large`
    width: 80%;
  `};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Helper = styled.div`
  word-wrap: unset !important;
  & > span {
    display: none;
  }
  ${media.medium`
    & > span { display: block; }
  `}
`;

export const Form = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  margin: 0px auto;

  & > * {
    width: auto;
    margin: 8px;
  }

  ${media.small`
    width: 85%;
  `}

  ${media.medium`
    width: 70%;
    & > * {
      width: 100%;
      margin: 16px;
    }
  `}

  ${media.large`
    width: auto;
    flex-direction: row;
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  & > * {
    width: auto;
    margin: 8px 4px;
  }

  ${media.medium`
    margin-top: 56px;
    & > * {
      padding: 0px 64px;
    }
  `}

  ${media.large`
    & > * {
      margin: 16px;
      padding: 0px 64px;
    }
    justify-content: flex-end;
  `}
`;
