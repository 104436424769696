import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToCamelCase, handleError } from '../../vendor/Utils';
import usePagination from '../../hooks/usePagination';
import usePageState from '../../hooks/usePageState';
import {
  VENDORS_GROUPS_NAME_FILTER,
  VENDORS_GROUPS_BUYER_NAME_FILTER,
  VENDORS_GROUPS_REFERENCE_CODE_FILTER,
} from './constants';
import { fetchGroups } from './api';

export const FunderVendorsGroupsContext = createContext({});

const FunderVendorsGroupsProvider = ({ children, token }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    [VENDORS_GROUPS_NAME_FILTER]: null,
    [VENDORS_GROUPS_BUYER_NAME_FILTER]: null,
    [VENDORS_GROUPS_REFERENCE_CODE_FILTER]: null,
  });

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  useEffect(() => {
    setLoading(true);
    fetchGroups(token, { ...filters, page: currentPage, per })
      .then(({ data }) => {
        const response = convertToCamelCase(data);
        setGroups(convertToCamelCase(response.vendorsGroups));
        updatePagination(data?.pagination);
      })
      .catch(() => handleError('Ocorreu um erro ao requisitar os grupos'))
      .finally(() => setLoading(false));
  }, [filters, currentPage, per]);

  const handleChangeFilters = (key, text) => setFilters({ ...filters, [key]: text });

  const handleClearAllFilters = () => {
    setFilters({
      [VENDORS_GROUPS_NAME_FILTER]: null,
      [VENDORS_GROUPS_BUYER_NAME_FILTER]: null,
      [VENDORS_GROUPS_REFERENCE_CODE_FILTER]: null,
    });
  };

  return (
    <FunderVendorsGroupsContext.Provider
      value={{
        groups,
        loading,
        currentPage,
        setPageState,
        setCurrent,
        next,
        previous,
        per,
        count,
        pages,
        onKeyDownPage,
        pagination,
        onSelectListing,
        filters,
        handleChangeFilters,
        handleClearAllFilters,
      }}
    >
      {children}
    </FunderVendorsGroupsContext.Provider>
  );
};

FunderVendorsGroupsProvider.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FunderVendorsGroupsProvider;
