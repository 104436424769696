import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, TabHeader, TooltipBox } from 'liber-components';
import { Card, InfoIconWrap, InfoIcon } from '../styles';
import { CardHeader, LabelsContainer, TooltipContent } from './styles';
import Liquidations from './Liquidations';
import AccumulatedYield from './AccumulatedYield';

class LiquidationsCard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <Card width="calc(100% - 13px)" height="655px">
          <Tabs>
            <TabHeader>
              {labels => (
                <CardHeader padding="0px 25px">
                  <LabelsContainer>{labels}</LabelsContainer>
                  <TooltipBox
                    key="liquidations-tooltip"
                    mount="top"
                    contentPosition="end"
                    fixed
                    content={
                      <TooltipContent>
                        Os gráficos de recebimentos e rendimento acumulado não contemplam os valores
                        de títulos em conciliação e em atraso.
                      </TooltipContent>
                    }
                  >
                    <InfoIconWrap>
                      <InfoIcon />
                    </InfoIconWrap>
                  </TooltipBox>
                </CardHeader>
              )}
            </TabHeader>
            <Tab label="recebimentos">
              <Liquidations token={this.props.token} />
            </Tab>
            <Tab label="Rendimento acumulado">
              <AccumulatedYield token={this.props.token} />
            </Tab>
          </Tabs>
        </Card>
      </>
    );
  }
}

LiquidationsCard.propTypes = {
  token: PropTypes.string.isRequired,
};

export default LiquidationsCard;
