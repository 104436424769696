import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryLink, TooltipBox, PrimaryButton } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast';
import {
  fetchBankAccounts,
  subscribeInvoices,
  fetchInvoicesReport,
  subscribeInvoicesReport,
} from '../../actions/InvoiceSimulationAction';
import { tabType, INVOICE_TABS } from '../../utils';
import InvoiceSimulationTableConnect from './InvoiceSimulationTableContainer';
import {
  SimulationContainer,
  LoadingBlock,
  Loading,
  Global,
  ScreenTitle,
  TitleContainer,
  FileIcon,
  BarContainer,
} from './InvoiceSimulation.styles';
import SimulationDrawer from '../SimulationDrawer/SimulationDrawer';
import LoadingDialog from '../LoadingDialog/LoadingDialog';
import {
  NUMBER_IN,
  CNPJ_EQ,
  EXPIRES_AT_EQ,
  EXPIRES_AT_GTEQ,
  EXPIRES_AT_LTEQ,
  STATUS_EQ,
} from '../constants';
import LoadingButton from '../../../../common-components/Buttons/LoadingButton';

const { AVAILABLE } = INVOICE_TABS;

export class InvoiceSimulation extends PureComponent {
  componentDidMount() {
    const {
      onFetchBankAccounts,
      onSubscribeInvoices,
      token,
      fornecedorId,
      onSubscribeInvoicesReport,
    } = this.props;
    onFetchBankAccounts(token);
    onSubscribeInvoices(fornecedorId);
    onSubscribeInvoicesReport(fornecedorId);
  }

  render() {
    const {
      token,
      loadingAllPage,
      canInputXml,
      inputXmlPath,
      loadingDialog,
      showButton,
      hasClicked,
      onFetchInvoicesReport,
      activeTab,
      filters,
      downloadReportLoading,
    } = this.props;

    const tooltipTextActiveButton =
      'Para gerar relatório de títulos em negociação, acesse os detalhes do status desejado.';
    const tooltipTextDisabledButton = 'A exportação leva em conta os filtros e a aba selecionada.';

    function handleInvoicesDownload(tokens, filterInputs, tab) {
      const filter = { ...filterInputs };
      const cnpjFilter = filter[CNPJ_EQ];
      if (cnpjFilter) filter[CNPJ_EQ] = cnpjFilter.replace(/[^\d]+/g, '');
      onFetchInvoicesReport(tokens, filter, tab);
    }

    return (
      <>
        <Global />
        {loadingAllPage ? <Loading loop /> : null}
        <LoadingDialog open={loadingDialog} />
        <LoadingBlock loading={loadingAllPage}>
          <SimulationContainer>
            <TitleContainer>
              <ScreenTitle>Títulos</ScreenTitle>
              <BarContainer>
                {canInputXml ? (
                  <PrimaryLink size="large" version={2} href={inputXmlPath}>
                    <FileIcon />
                    INSERIR NOTAS FISCAIS
                  </PrimaryLink>
                ) : null}
                <TooltipBox
                  mount="top"
                  fixed
                  content={
                    activeTab === 'Em Negociação'
                      ? tooltipTextActiveButton
                      : tooltipTextDisabledButton
                  }
                >
                  <div>
                    <LoadingButton
                      ButtonComponent={PrimaryButton}
                      size="large"
                      disabled={activeTab === 'Em Negociação'}
                      onClick={() => handleInvoicesDownload(token, filters, activeTab)}
                      loading={downloadReportLoading}
                    >
                      BAIXAR PLANILHA
                    </LoadingButton>
                  </div>
                </TooltipBox>
              </BarContainer>
            </TitleContainer>
            {showButton && !hasClicked}
            <SimulationDrawer token={token} />
            <InvoiceSimulationTableConnect token={token} />
          </SimulationContainer>
        </LoadingBlock>
        <ToastContainer />
      </>
    );
  }
}

InvoiceSimulation.propTypes = {
  token: PropTypes.string.isRequired,
  fornecedorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onFetchBankAccounts: PropTypes.func,
  onSubscribeInvoices: PropTypes.func,
  onSubscribeInvoicesReport: PropTypes.func,
  onFetchInvoicesReport: PropTypes.func,
  loadingAllPage: PropTypes.bool,
  inputXmlPath: PropTypes.string.isRequired,
  canInputXml: PropTypes.bool.isRequired,
  loadingDialog: PropTypes.bool,
  showButton: PropTypes.bool,
  hasClicked: PropTypes.bool,
  activeTab: tabType,
  filters: PropTypes.shape({
    [NUMBER_IN]: PropTypes.arrayOf(PropTypes.string),
    [CNPJ_EQ]: PropTypes.string,
    [EXPIRES_AT_EQ]: PropTypes.string,
    [EXPIRES_AT_GTEQ]: PropTypes.string,
    [EXPIRES_AT_LTEQ]: PropTypes.string,
    [STATUS_EQ]: PropTypes.string,
  }).isRequired,
  downloadReportLoading: PropTypes.bool,
};

InvoiceSimulation.defaultProps = {
  onFetchBankAccounts: () => {},
  onSubscribeInvoices: () => {},
  onFetchInvoicesReport: () => {},
  onSubscribeInvoicesReport: () => {},
  loadingAllPage: false,
  loadingDialog: false,
  showButton: false,
  hasClicked: false,
  activeTab: AVAILABLE,
  downloadReportLoading: false,
};

const mapDispatchToProps = {
  onFetchBankAccounts: fetchBankAccounts,
  onSubscribeInvoices: subscribeInvoices,
  onFetchInvoicesReport: fetchInvoicesReport,
  onSubscribeInvoicesReport: subscribeInvoicesReport,
};

const mapStateToProps = ({
  invoiceSimulation: { loadingAllPage, loadingDialog, activeTab, filters, downloadReportLoading },
}) => ({
  loadingAllPage,
  loadingDialog,
  activeTab,
  filters,
  downloadReportLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSimulation);
