import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner, handleThemeFromObject } from 'liber-components';
import { IconContainer, IconSuccess, IconFailure } from './styles';
import { LoginCard, LoginContent, Title, InfoText, ButtonDiv, ButtonGreen } from '../styles';
import { renderMaskedEmail } from '../../actions/confirmation';

class Confirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locked: props.locked || false,
      delta: props.delta || 0,
    };

    this.timer = 0;
  }

  componentDidMount() {
    if (this.props.locked) this.startTimer();
  }

  componentWillReceiveProps(props) {
    this.setState({ locked: false });
    if (props.success) this.setState({ locked: true, delta: 0 });
    if (props.locked || props.success) this.startTimer();
  }

  startTimer = () => {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    this.setState(prevState => ({ delta: prevState.delta + 1 }));

    if (this.state.delta >= this.props.countdown) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({ locked: false, delta: 0 });
    }
  };

  formatTime = time => `${Math.floor(time / 60)}:${time % 60 < 10 ? '0' : ''}${time % 60}`;

  renderFeedback = () => {
    const { fetched, success, theme } = this.props;

    if (fetched) {
      if (success) {
        return (
          <IconContainer>
            <IconSuccess />
            <div
              style={{
                margin: '10px 0px',
                color: handleThemeFromObject(theme, 'colors.successGreen', '#13ce66'),
              }}
            >
              Email reenviado com sucesso!
            </div>
          </IconContainer>
        );
      }
      return (
        <IconContainer>
          <IconFailure />
          <div
            style={{
              margin: '10px 0px',
              color: handleThemeFromObject(theme, 'colors.dangerRed', '#dc3545'),
            }}
          >
            Erro ao reenviar email, tente novamente!
          </div>
        </IconContainer>
      );
    }

    return null;
  };

  renderButton = () => {
    const { resendEmail, isFetching } = this.props;
    const { locked, delta } = this.state;

    if (isFetching) {
      return <LoadingSpinner style={{ margin: '0px auto' }} />;
    }

    return (
      <div>
        {this.renderFeedback()}
        {this.state.locked && (
          <InfoText>
            Você poderá reenviar o email após&nbsp;
            <strong>{this.formatTime(this.props.countdown - delta)}</strong> minuto(s)
          </InfoText>
        )}
        <ButtonDiv>
          <ButtonGreen
            id="resend_confirmation_button"
            width="100%"
            onClick={resendEmail}
            disabled={locked}
          >
            Reenviar email de confirmação
          </ButtonGreen>
        </ButtonDiv>
      </div>
    );
  };

  render = () => (
    <LoginCard>
      <LoginContent width="467px">
        <Title>Confirmação de Email</Title>
        <div style={{ margin: '20px 0px' }}>
          <InfoText>Enviamos um email de confirmação para o endereço</InfoText>
          <div style={{ margin: '20px 0px' }}>
            <strong> {renderMaskedEmail(this.props.contextEmail)} </strong>
          </div>
          <InfoText>
            Acesse seu email e clique no botão &quot;Confirmar Email&quot; para ganhar acesso a
            nossa plataforma.
          </InfoText>
          <InfoText>
            Caso não encontre o email em sua caixa de entrada, confira a pasta de spam, ou clique no
            botão abaixo.
          </InfoText>
        </div>
        {this.renderButton()}
      </LoginContent>
    </LoginCard>
  );
}

Confirmation.propTypes = {
  contextEmail: PropTypes.string.isRequired,
  resendEmail: PropTypes.func.isRequired,
  fetched: PropTypes.bool,
  isFetching: PropTypes.bool,
  locked: PropTypes.bool,
  success: PropTypes.bool,
  delta: PropTypes.number,
  countdown: PropTypes.number,
  theme: PropTypes.objectOf(PropTypes.any),
};

Confirmation.defaultProps = {
  delta: 0,
  fetched: false,
  isFetching: false,
  locked: false,
  success: false,
  countdown: 60,
  theme: {},
};

export default Confirmation;
