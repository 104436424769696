import Immutable from 'seamless-immutable';

const initialState = Immutable({
  logo: '',
  chromeExtensionImgs: [],
  firefoxExtensionImgs: [],
  chromeDownloadWindows: '',
  chromeDownloadMacos: '',
  chromeDownloadLinux: '',
  firefoxDownloadWindows: '',
  firefoxDownloadMacos: '',
  firefoxDownloadLinux: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};
