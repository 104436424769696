import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CompleteTable } from 'liber-components';
import {
  fetchAdminNegotiations,
  nextPage,
  previousPage,
  setListing,
  togglePageSelection,
  toggleItemSelection,
  setPage,
  updateSorting,
} from '../../actions/AdminNegotiationsActions';
import { LoadingContainer, Bunny } from './Table.styles';
import { getPaginationInterval } from '../../../../vendor/Utils';
import SelectionControl from './SelectionControl';
import Filters from './Filters/Filters';
import ItemActions from './ItemActions';
import usePageState from '../../../../hooks/usePageState';
import useSorting from '../../../../hooks/useSorting';
import { SortingDialog } from '../../../../common-components/SortingDialog';
import { DEFAULT_SORTING } from '../../reducer/AdminNegotiationsReducer';

const MULTI_LABEL_COLUMNS = ['duplicata', 'dates'];
const SUB_LABELS = {
  duplicata: [
    {
      label: 'Título',
      value: 'duplicata_number',
    },
    {
      label: 'Nº Referência',
      value: 'duplicata_reference_number',
    },
  ],
  dates: [
    { label: 'Vencimento', value: 'duplicata_expires_at' },
    { label: 'Liquidação', value: 'duplicata_expected_payment_at' },
    { label: 'Conclusão', value: 'finished_at' },
  ],
};

export const Table = ({
  headers,
  label,
  negotiations,
  loading,
  pagination,
  onNextPage,
  onPreviousPage,
  onSetListing,
  selectedItems,
  isPageSelected,
  onTogglePageSelect,
  onToggleItemSelect,
  isSelectable,
  onSetPage,
  onUpdateSorting,
}) => {
  const { current, pages: pageTotal, count: total, per } = pagination;

  const [showSortingDialog, setShowSortingDialog] = useState(false);
  const [sortingDialogColumns, setSortingDialogColumns] = useState([]);

  const [pageState, setPageState, onKeyDownPage] = usePageState(current, pageTotal, onSetPage);
  const [onSort, sorting, sortedColumns, setSorting] = useSorting(
    [
      'status',
      'duplicata_value',
      'duplicata_agreed_rate',
      'sacado_trade_name',
      'fornecedor_trade_name',
      'investidor_name',
      'duplicata',
      'dates',
    ],
    onUpdateSorting,
    DEFAULT_SORTING,
  );

  const handleSorting = (column, sortInfo = {}) => {
    if (MULTI_LABEL_COLUMNS.includes(column.name)) {
      setSortingDialogColumns(SUB_LABELS[column.name]);
      setShowSortingDialog(true);
    } else {
      onSort(column, sortInfo);
    }
  };

  const onChangePage = ({ target: { value } }) => {
    setPageState(value);
  };

  const selectionProps = isSelectable
    ? {
        isItemSelected: ({ id }) => selectedItems.includes(id),
        allChecked: isPageSelected,
        onSelectAll: onTogglePageSelect,
        onSelect: onToggleItemSelect,
        selectionControl: <SelectionControl label={label} />,
      }
    : {};

  return (
    <>
      <CompleteTable
        items={loading ? [] : negotiations}
        columns={headers}
        filters={<Filters />}
        actions={item => <ItemActions item={item} />}
        filterBorderTop={false}
        emptyState={
          loading ? (
            <LoadingContainer>
              <Bunny />
            </LoadingContainer>
          ) : undefined
        }
        sortingProps={{ onSort: handleSorting, sorting, sortedColumns }}
        paginationProps={{
          currentPage: String(pageState),
          pageTotal: String(pageTotal),
          onChangePage,
          onKeyDownPage,
          onClickNext: onNextPage,
          onClickPrev: onPreviousPage,
          hasListing: true,
          listingProps: {
            listing: String(per),
            total: String(total),
            showInterval: getPaginationInterval(pagination),
            onSelectListing: onSetListing,
          },
        }}
        {...selectionProps}
      />
      <SortingDialog
        open={showSortingDialog}
        onClose={() => setShowSortingDialog(false)}
        sorting={sorting}
        setSorting={setSorting}
        columns={sortingDialogColumns}
      />
    </>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  negotiations: PropTypes.arrayOf(PropTypes.shape({})),
  pagination: PropTypes.shape({
    current: PropTypes.number,
    previous: PropTypes.number,
    next: PropTypes.number,
    per: PropTypes.number,
    pages: PropTypes.number,
    count: PropTypes.number,
  }),
  loading: PropTypes.bool,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onSetListing: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  isPageSelected: PropTypes.bool,
  onTogglePageSelect: PropTypes.func,
  onToggleItemSelect: PropTypes.func,
  isSelectable: PropTypes.bool,
  onSetPage: PropTypes.func,
  onUpdateSorting: PropTypes.func,
  label: PropTypes.string,
};

Table.defaultProps = {
  headers: [],
  negotiations: [],
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 25,
    pages: 1,
    count: 0,
  },
  loading: false,
  onNextPage: () => null,
  onPreviousPage: () => null,
  onSetListing: () => null,
  selectedItems: [],
  isPageSelected: false,
  onToggleItemSelect: () => {},
  onTogglePageSelect: () => {},
  isSelectable: false,
  onSetPage: () => {},
  onUpdateSorting: () => {},
  label: null,
};

const mapStateToProps = ({
  adminNegotiations: { negotiations, pagination, loading, selectedItems, isPageSelected },
}) => ({
  negotiations,
  pagination,
  loading,
  selectedItems,
  isPageSelected,
});

const mapDispatchToProps = {
  onFetchNegotiations: fetchAdminNegotiations,
  onNextPage: nextPage,
  onPreviousPage: previousPage,
  onSetListing: setListing,
  onToggleItemSelect: toggleItemSelection,
  onTogglePageSelect: togglePageSelection,
  onSetPage: setPage,
  onUpdateSorting: updateSorting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
