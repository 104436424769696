import styled from 'styled-components';
import { handleThemeFromObject } from 'liber-components';

export const Header2 = styled.h2`
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  margin: 25px 0px;
`;

export const InfoDiv = styled.div`
  padding: 20px 35px;
  p + p {
    padding-top: 15px;
  }
`;
