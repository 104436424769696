import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast/components/ToastContainer';
import Remittance from './components/Remittance';
import hydrateStore from '../store';

const View = ({ isAdmin, token }) => (
  <Provider store={hydrateStore({ remittance: { isAdmin, token } })}>
    <ThemeProvider theme={LiberV4}>
      <Remittance />
      <ToastContainer />
    </ThemeProvider>
  </Provider>
);
View.propTypes = {
  token: PropTypes.string.isRequired,
  store: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool,
};
View.defaultProps = {
  isAdmin: false,
};
export default View;
