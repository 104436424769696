import React, { useContext } from 'react';
import { BATCH_MESSAGES, ACTIONS } from '../../../constants';
import { ModalContext } from '../../../context';
import * as S from '../../../styles';

const BatchBlockUnblockInfo = () => {
  const { funder, action } = useContext(ModalContext);
  return (
    <>
      <S.ModalTitle>{BATCH_MESSAGES.title[action]}</S.ModalTitle>
      <S.Text>{BATCH_MESSAGES[action].confirmation(funder)}</S.Text>
      <S.Text>{BATCH_MESSAGES[action].info(funder)}</S.Text>
      {action === ACTIONS.BLOCK && <S.Text>{BATCH_MESSAGES[action].observation(funder)}</S.Text>}
    </>
  );
};

export default BatchBlockUnblockInfo;
