import React, { useState, useEffect, useContext } from 'react';
import { CompleteTable } from 'liber-components';

import { ScrollContainer, LimiterContainer, Bunny, Loading } from './styles';
import { getPaginationInterval } from '../../../../vendor/Utils';
import { SORTING_DIALOG_COLUMNS } from '../../constants';
import columns from '../../columns';
import { VendorsGroupDetailsContext } from '../../context';
import useSorting from '../../../../hooks/useSorting';
import Filters from './Filters/Filters';
import SortingDialog from '../../../../common-components/SortingDialog';
import usePageState from '../../../../hooks/usePageState';

const VendorsTable = () => {
  const {
    updateVendors,
    pages,
    current,
    next,
    previous,
    count,
    per,
    setCurrent,
    onSelectListing,
    loading,
    vendors,
    pagination,
    isFunder,
  } = useContext(VendorsGroupDetailsContext);

  const [sortingFilter, setSortingFilter] = useState({});
  const [showSortingDialog, setShowSortingDialog] = useState(false);
  const [filters, setFilters] = useState({});

  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const [onSort, sorting, sortedColumns, setSorting] = useSorting(
    ['vendor', 'email', 'vendorsGroupName'],
    setSortingFilter,
    {},
  );

  const handleSorting = (column, sortInfo = {}) => {
    if (column?.name === 'vendor') {
      setShowSortingDialog(true);
    } else {
      onSort(column, sortInfo);
    }
  };

  useEffect(() => {
    updateVendors({ ...filters, ...sortingFilter });
  }, [per, current, sortingFilter, filters]);

  const handleChangeFilters = (key, text) => {
    setFilters({ ...filters, [key]: text });
  };

  const handleCleanAllFilters = () => {
    setFilters({});
  };

  return (
    <Loading loading={loading ? 1 : 0}>
      {loading ? <Bunny /> : null}
      <ScrollContainer>
        <LimiterContainer>
          <CompleteTable
            columns={columns(isFunder)}
            filters={
              <Filters
                clearFilters={handleCleanAllFilters}
                changeFilters={handleChangeFilters}
                filters={filters}
                isFunder={isFunder}
              />
            }
            items={vendors || []}
            paginationProps={{
              currentPage,
              pageTotal: pages,
              onChangePage: ({ target: { value } }) => setPageState(value),
              onClickNext: () => setCurrent(next),
              onClickPrev: () => setCurrent(previous),
              onKeyDownPage,
              hasListing: true,
              listingProps: {
                listing: String(per),
                total: String(count),
                showInterval: getPaginationInterval(pagination),
                onSelectListing,
                listingLabelSingular: 'fornecedor',
                listingLabelPlural: 'fornecedores',
              },
            }}
            sortingProps={{ onSort: handleSorting, sorting, sortedColumns }}
          />
          <SortingDialog
            open={showSortingDialog}
            onClose={() => setShowSortingDialog(false)}
            sorting={sorting}
            setSorting={setSorting}
            columns={SORTING_DIALOG_COLUMNS}
          />
        </LimiterContainer>
      </ScrollContainer>
    </Loading>
  );
};

export default VendorsTable;
