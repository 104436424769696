import React from 'react';
import { TooltipBox } from 'liber-components';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiBank, mdiLock, mdiLockOpen } from '@mdi/js';
import * as S from './styles';

export const VendorDetailsButton = ({
  blockedByBuyer,
  activeFunders,
  totalFunders,
  disabled,
  isAdmin,
  onClick,
  notificationContent,
}) => {
  const funderStatusMessage = blockedByBuyer ? 'Bloqueado no Sacado' : 'Ativo no Sacado';
  const Wrapper = notificationContent ? S.Badge : React.Fragment;
  const wrapperNotificationProps = notificationContent ? { content: notificationContent } : {};

  return (
    <Wrapper {...wrapperNotificationProps}>
      <S.Button disabled={disabled} onClick={onClick}>
        {isAdmin && (
          <>
            <TooltipBox mount="top" fixed content={funderStatusMessage}>
              <Icon path={blockedByBuyer ? mdiLock : mdiLockOpen} size="14px" />
            </TooltipBox>
            <S.Divisor />
          </>
        )}
        <TooltipBox mount="top" fixed content="Investidores (ativos/cadastrados)">
          <S.Flex>
            <S.Text>
              {activeFunders}/{totalFunders}
            </S.Text>
            <Icon path={mdiBank} size="14px" />
          </S.Flex>
        </TooltipBox>
      </S.Button>
    </Wrapper>
  );
};

VendorDetailsButton.propTypes = {
  blockedByBuyer: PropTypes.bool,
  disabled: PropTypes.bool,
  activeFunders: PropTypes.number.isRequired,
  totalFunders: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  notificationContent: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

VendorDetailsButton.defaultProps = {
  blockedByBuyer: false,
  disabled: false,
  isAdmin: true,
  onClick: () => null,
  notificationContent: 0,
};

export default VendorDetailsButton;
