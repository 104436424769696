import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import usePagination from '../../hooks/usePagination';
import { convertToCamelCase, handleError, handleSuccess } from '../../vendor/Utils';
import { TokenContext } from '../../hocs/withTokenProvider';
import { fetchVendorsGroups, updateIncrementChange } from './api';

const VendorsGroupsContext = createContext({});

const VendorsGroupsStore = ({ children, roomId, exampleFile }) => {
  const [count, setCount] = useState(0);

  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [archiveGroupModalOpen, setArchiveGroupModalOpen] = useState(false);

  const [allowRebateIncrement, setAllowRebateIncrement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [sortingFilter, setSortingFilter] = useState({});

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();

  const { token } = useContext(TokenContext);

  const handleArchive = group => {
    setSelectedGroup(group);
    setArchiveGroupModalOpen(true);
  };

  const updateVendorsGroups = (current = 1, per = 25) => {
    setLoading(true);
    fetchVendorsGroups(token, { page: current, per, ...sortingFilter })
      .then(response => {
        const { data } = response;
        const formattedData = convertToCamelCase(data?.data || {});
        const {
          vendorsGroups: incomingGroups,
          pagination: incomingPagination,
          buyer: incomingBuyer,
        } = formattedData;

        setAllowRebateIncrement(incomingBuyer.allowRebateIncrement);
        setCount(incomingPagination?.count);
        updatePagination({ ...incomingPagination, per });

        setGroups(incomingGroups);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        handleError('Erro!', error?.response?.data?.error);
        setLoading(false);
      });
  };

  const updateOperationIncrement = (id, incrementNumber) => {
    setLoading(true);
    const body = {
      rebate_increment: incrementNumber,
    };
    updateIncrementChange(token, body, id)
      .then(() => {
        setLoading(false);
        handleSuccess('Sucesso!', 'O incremento da operação foi atualizado com sucesso!');

        updateVendorsGroups();
      })
      .catch(error => {
        console.log(error);
        handleError('Erro!', error?.response?.data?.error);
        setLoading(false);
      });
  };

  return (
    <VendorsGroupsContext.Provider
      value={{
        roomId,
        exampleFile,
        selectedGroup,
        handleArchive,
        archiveGroupModalOpen,
        setArchiveGroupModalOpen,
        loading,
        groups,
        allowRebateIncrement,
        sortingFilter,
        setSortingFilter,
        updateVendorsGroups,
        count,
        setCount,
        pagination,
        setCurrent,
        onSelectListing,
        updateOperationIncrement,
      }}
    >
      {children}
    </VendorsGroupsContext.Provider>
  );
};

VendorsGroupsStore.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  roomId: PropTypes.string,
  exampleFile: PropTypes.string,
};

VendorsGroupsStore.defaultProps = {
  roomId: '',
  exampleFile: '',
};

export { VendorsGroupsContext, VendorsGroupsStore };
