import styled, { createGlobalStyle } from 'styled-components';
import { PrimaryButton, PrimaryLink } from 'liber-components';

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: Roboto;
  }
`;

export const Link = styled(PrimaryLink).attrs(() => ({
  version: 2,
  outlined: true,
  size: 'large',
}))`
  text-transform: uppercase;
  min-width: fit-content;
  margin-right: 16px;
`;

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
  min-width: fit-content;
`;

export const OutlinedButton = styled(Button).attrs(() => ({ outlined: true }))`
  margin-right: 16px;
`;

export const TableHeader = styled.h5`
  font-weight: 700;
  font-size: 24px;
  color: #405f71;
  margin-bottom: 16px;
`;
