import styled from 'styled-components';
import { FlatButton } from 'liber-components';
import BannerAdianta from '../../../../../../assets/images/banner-adianta.svg';

export const BackgroundContainer = styled.div`
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 35px;

  background-image: url(${BannerAdianta});
  background-repeat: no-repeat;
  @media (min-width: 576px) {
    flex-direction: column;
    justify-content: center;
    max-width: 540px;
  }
  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: center;
    max-width: 720px;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    max-width: 1140px;
  }
`;

export const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-left: 150px;
`;

export const BannerButtonWrapper = styled.div`
  display: flex;
  justify-self: right;
  margin-left: auto;
  margin-right: 35px;
  @media (min-width: 768px) {
    margin: 5px 0px;
  }
  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: 35px;
  }
  @media (min-width: 1200px) {
    margin-left: auto;
    margin-right: 35px;
  }
`;

export const BannerTitle = styled.span`
  font-family: Sora;
  color: #000;

  font-family: Sora;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
`;

export const BannerSubtitle = styled.span`
  color: #000;

  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const BannerButton = styled(FlatButton)`
  color: #fff;
  background-color: var(--primary-main, #006aeb);
`;
