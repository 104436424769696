import Cookie from 'js.cookie';

export const getCookieState = () => {
  const state = Cookie.get('anticipationState');
  return state || {};
};

export const saveCurrentState = state => {
  const currentState = Cookie.get('anticipationState');
  const newState = {
    ...currentState,
    ...state,
  };
  Cookie.set('anticipationState', newState);
};

export const clearCurrentState = () => Cookie.remove('anticipationState');
