import React, { useState, useMemo } from 'react';
import { Card, Typography, TextField, Button, Alert } from 'liber-salti';
import { useMediaQuery, useTheme } from '@mui/material';

import LoginFooter from '../../../../common-components/LoginFooter';
import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';

import { FUNDER_SUBMIT_EMAIL_TOKEN_PATH } from '../../paths';

import {
  Container,
  Content,
  CardContent,
  Logo,
  LogoWrapper,
  ActionsWrapper,
  InputsWrapper,
  DescriptionWrapper,
} from '../../styles';

const FunderConfirmEmail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const fieldsSize = useMemo(() => (isMobile ? 'large' : 'medium'), [isMobile]);

  const [tokenInput, setTokenInput] = useState('');

  const submitToken = () => {
    window.location.assign(`${FUNDER_SUBMIT_EMAIL_TOKEN_PATH}${tokenInput}`);
  };

  return (
    <Container>
      <Content>
        <Card>
          <CardContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography variant="h2" color="textPrimary">
              Confirmar conta
            </Typography>
            <DescriptionWrapper>
              <Alert
                severity="error"
                description={`Não foi possível recuperar o token de confirmação. 
                    Por favor, insira o token que enviamos por email.`}
              />
            </DescriptionWrapper>
            <InputsWrapper>
              <TextField
                value={tokenInput}
                onChange={e => setTokenInput(e.target.value)}
                label="Token"
                required
                fullWidth
                size={fieldsSize}
                data-testid="TokenInput"
              />
            </InputsWrapper>
            <ActionsWrapper>
              <Button
                onClick={() => submitToken()}
                disabled={tokenInput.length === 0}
                size="large"
                fullWidth
                data-testid="SubmitButton"
              >
                CONFIRMAR
              </Button>
            </ActionsWrapper>
          </CardContent>
        </Card>
      </Content>
      <LoginFooter />
    </Container>
  );
};

const View = () => (
  <SaltiThemeWrapper>
    <FunderConfirmEmail />
  </SaltiThemeWrapper>
);

export default View;
