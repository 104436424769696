import Cookie from 'js.cookie';

import { SIDEBAR_EXPANDED_COOKIE } from './constants';

export const getCookieSidebarExpanded = () => {
  const state = Cookie.get(SIDEBAR_EXPANDED_COOKIE);
  return state ?? true;
};

export const setCookieSidebarExpanded = (state, cookieDomain) => {
  Cookie.set(SIDEBAR_EXPANDED_COOKIE, state, { domain: cookieDomain });
};
