import { validateEmail } from '../../../vendor/validation';
import { errorMsg } from '../messages';

export function changeEmail(value) {
  if (validateEmail(value)) {
    return {
      type: 'OPERATOR_CHANGE_EMAIL',
      payload: value,
    };
  }

  if (value.length === 0) {
    return {
      type: 'OPERATOR_CHANGE_EMAIL_ERROR',
      payload: {
        email: value,
        error: errorMsg.empty,
      },
    };
  }

  return {
    type: 'OPERATOR_CHANGE_EMAIL_ERROR',
    payload: {
      email: value,
      error: errorMsg.invalidFormat,
    },
  };
}

export function changePassword(value) {
  if (value.length === 0) {
    return {
      type: 'OPERATOR_CHANGE_PASSWORD_ERROR',
      payload: {
        password: value,
        error: errorMsg.empty,
      },
    };
  }
  return {
    type: 'OPERATOR_CHANGE_PASSWORD',
    payload: value,
  };
}

export function changeToken(value) {
  return {
    type: 'OPERATOR_CHANGE_TOKEN',
    payload: value,
  };
}

export function toogleRemember() {
  return {
    type: 'OPERATOR_TOOGLE_REMEMBER',
  };
}

export const setFingerprint = data => ({
  type: 'OPERATOR_SET_FINGERPRINT',
  payload: data,
});

export function submitLogin(target, email, password) {
  if (email.length !== 0 && validateEmail(email) && password.length !== 0) {
    target.submit();
    return {
      type: 'OPERATOR_SUCESSFULL_LOGIN',
    };
  }
  let emailError = email.length === 0 ? errorMsg.empty : '';
  emailError = validateEmail(email) || email.length === 0 ? emailError : errorMsg.invalidFormat;
  return {
    type: 'OPERATOR_FAILED_LOGIN',
    payload: {
      emailError,
      passwordError: password.length === 0 ? errorMsg.empty : '',
    },
  };
}
