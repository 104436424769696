import { connect } from 'react-redux';
import Documentacao from './Documentacao';
import { previousStep as onPrevious } from '../../../actions/progressBar';
import {
  changeKind,
  changeNumber,
  changeEmissionDate,
  changeEmissionIssuer,
  changeEmissionState,
  changeMotherName,
  changeFatherName,
  changePep,
  submitForm,
} from '../../../actions/documentacao';
import { getDocumentacao } from '../../../presenters/documentacao';
import { getRg, getCnh } from '../../../presenters/paths';

const mapStateToProps = state => ({
  documentacao: getDocumentacao(state),
  rg: getRg(state),
  cnh: getCnh(state),
});

const mapDispatchToProps = {
  changeKind,
  changeNumber,
  changeEmissionDate,
  changeEmissionIssuer,
  changeEmissionState,
  changeMotherName,
  changeFatherName,
  changePep,
  submitForm,
  onPrevious,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentacao);
