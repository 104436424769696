import styled from 'styled-components';
import { SelectionTableHeader, SelectionTable } from 'liber-components';

export const Header = styled(SelectionTableHeader)`
  border: 1px solid #cdd7df;
  & > tr {
    border-bottom: none;
  }
`;

export const Table = styled(SelectionTable)`
  width: 100%;
`;
