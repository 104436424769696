import styled from 'styled-components';
import ScrollArea from 'react-perfect-scrollbar';
import { Checkbox } from 'liber-components';

export const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.01px;
  text-align: left;
  color: #405f71;
`;

export const Title = styled(Text)`
  width: 100%;
  font-weight: bold;
  line-height: 2.13;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 8px;
`;

export const BoxContainer = styled(ScrollArea)`
  width: 100%;
  height: 295px;
  border: solid 1px #405f71;
  background-color: #ffffff;
  padding: 16px;
  margin-top: 24px;
  overflow-y: auto;

  @media (max-height: 805px) {
    max-height: 20vh;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0px;
  margin-top: -5px;
  & > span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.01px;
    color: #405f71;
  }
`;

export const CheckboxRow = styled.div`
  display: flex;
  margin-top: 22px;
`;
