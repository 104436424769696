import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';
import Content from './components/Content';
import ModalContextProvider from './context';

const HandleFunderStatusModal = ({ show, ...props }) => (
  <ModalContextProvider {...props}>
    <Modal show={show}>
      <Content />
    </Modal>
  </ModalContextProvider>
);

HandleFunderStatusModal.propTypes = {
  action: PropTypes.oneOf(['block', 'unblock']),
  kind: PropTypes.oneOf(['funder', 'buyer']),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  vendor: PropTypes.string,
  funder: PropTypes.string,
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  isBatch: PropTypes.bool,
};

HandleFunderStatusModal.defaultProps = {
  action: 'block',
  kind: 'funder',
  onConfirm: () => null,
  onCancel: () => null,
  vendor: null,
  funder: null,
  show: true,
  isLoading: false,
  isBatch: false,
};

export default HandleFunderStatusModal;
