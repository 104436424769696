import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, getScreenSize } from 'liber-components';
import VMasker from 'vanilla-masker';
import { capitalize, Countries, States } from '../../../../../vendor/Utils';
import { Row, CardContent, Divider, HeaderRow, Info, EditButton, Drawer } from '../../Base.styles';
import ChangePassword from '../../ChangePassword';
import ManageData from '../../ManageData';
import Form from './InvestorAccountForm';
import {
  hidrateForm as hidrate,
  closeModalForm as close,
} from '../../../actions/account/InvestorAccountActions';

export class InvestorAccount extends Component {
  kind = {
    pf: 'Pessoa Física',
    factoring: 'Factoring',
    fidc: 'FIDC',
    securitizadora: 'Securitizadora',
    banco: 'Banco',
    esc: 'Empresa Simples de Crédito',
  };

  constructor() {
    super();
    this.state = {
      screenSize: getScreenSize(window),
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  renderAccountDataPf = account => (
    <>
      <HeaderRow>
        <h1>Dados Pessoais</h1>
        <EditButton
          onClick={() => {
            this.props.hidrateForm(account);
          }}
        >
          Editar
        </EditButton>
      </HeaderRow>
      <Row>
        <Info>
          Tipo <span>{this.kind[account.kind]}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Nome Completo <span>{account.name}</span>
        </Info>
        <Info>
          CPF
          <span>{VMasker.toPattern(account.cnpj, '999.999.999-99')}</span>
        </Info>
      </Row>

      <Row>
        <Info>
          E-mail <span>{account.email}</span>
        </Info>
        <Info>
          Celular <span>{VMasker.toPattern(account.cellphone, '(99) 99999-9999')}</span>
        </Info>
        <Info>
          Telefone <span>{VMasker.toPattern(account.phone, '(99) 9999-9999')}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Gênero <span>{capitalize(account.gender)}</span>
        </Info>
        <Info>
          Data de Nascimento <span>{account.birthdate}</span>
        </Info>
        <Info>
          Estado Civil <span>{capitalize(account.civilStatus)}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          País de Origem{' '}
          <span>
            {Object.keys(Countries).find(key => Countries[key] === account.countryOrigin)}
          </span>
        </Info>
        <Info>
          UF de Nascimento
          <span>
            {(() => {
              const state = Object.keys(States).find(key => States[key] === account.stateOrigin);
              return state || account.stateOrigin;
            })()}
          </span>
        </Info>
        <Info>
          Cidade de Nascimento <span>{account.cityOrigin}</span>
        </Info>
      </Row>
    </>
  );

  renderAccountDataPj = account => (
    <>
      <HeaderRow>
        <h1>Dados Pessoais</h1>
        <EditButton
          onClick={() => {
            this.props.hidrateForm(account);
          }}
        >
          Editar
        </EditButton>
      </HeaderRow>
      <Row>
        <Info>
          Tipo <span>{this.kind[account.kind]}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Razão Social <span>{account.company}</span>
        </Info>
        <Info>
          CNPJ
          <span>{VMasker.toPattern(account.cnpj, '99.999.999/9999-99')}</span>
        </Info>
        <Info>
          Nome Fantasia
          <span>{account.tradeName}</span>
        </Info>
      </Row>

      <Row>
        <Info>
          E-mail <span>{account.email}</span>
        </Info>
        <Info>
          Celular <span>{VMasker.toPattern(account.cellphone, '(99) 99999-9999')}</span>
        </Info>
        <Info>
          Telefone <span>{VMasker.toPattern(account.phone, '(99) 9999-9999')}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Nome para contato <span>{account.name}</span>
        </Info>
      </Row>
    </>
  );

  renderDocumentation = documentation => (
    <>
      <HeaderRow>
        <h1>Documentação</h1>
      </HeaderRow>
      <Row>
        <Info>
          Tipo de documento <span>{documentation.kind}</span>
        </Info>
        <Info>
          Número <span>{VMasker.toPattern(documentation.number, '99.999.999-9')}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Data de Emissão <span>{documentation.emissionDate}</span>
        </Info>
        <Info>
          Órgão Expedidor <span>{documentation.emissionIssuer}</span>
        </Info>
        <Info>
          Estado de Emissão
          <span>
            {(() => {
              const state = Object.keys(States).find(
                key => States[key] === documentation.emissionState,
              );
              return state || documentation.emissionState;
            })()}
          </span>
        </Info>
      </Row>
      <Row>
        <Info>
          Nome da Mãe <span>{documentation.motherName}</span>
        </Info>
        {documentation.fatherName ? (
          <Info>
            Nome do Pai <span>{documentation.fatherName}</span>
          </Info>
        ) : null}
      </Row>
      <Row>
        <Info fullWidth>
          Você é uma pessoa Politicamente Exposta (PEP)? <span>{documentation.pep}</span>
        </Info>
      </Row>
    </>
  );

  render() {
    const { account, documentation, showAccountForm, closeModalForm } = this.props;
    const { screenSize } = this.state;
    const Dialog = screenSize === 'large' || screenSize === 'extraLarge' ? Modal : Drawer;
    return (
      <>
        <Dialog show={showAccountForm} onLeaved={closeModalForm} closeButton>
          <Form />
        </Dialog>
        <CardContent>
          {account.kind === 'pf'
            ? this.renderAccountDataPf(account)
            : this.renderAccountDataPj(account)}
          <Divider />
          {documentation ? (
            <>
              {this.renderDocumentation(documentation)}
              <Divider />
            </>
          ) : null}
          <ChangePassword />
          <ManageData account={account} />
        </CardContent>
      </>
    );
  }
}

InvestorAccount.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
    cnpj: PropTypes.string,
    email: PropTypes.string,
    cellphone: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string,
    birthdate: PropTypes.string,
    civilStatus: PropTypes.string,
    countryOrigin: PropTypes.string,
    stateOrigin: PropTypes.string,
    cityOrigin: PropTypes.string,
    kind: PropTypes.string,
  }).isRequired,
  documentation: PropTypes.shape({
    kind: PropTypes.string,
    number: PropTypes.string,
    emissionDate: PropTypes.string,
    emissionIssuer: PropTypes.string,
    emissionState: PropTypes.string,
    motherName: PropTypes.string,
    fatherName: PropTypes.string,
  }),
  showAccountForm: PropTypes.bool.isRequired,
  hidrateForm: PropTypes.func.isRequired,
  closeModalForm: PropTypes.func.isRequired,
};

InvestorAccount.defaultProps = {
  documentation: null,
};

const parentReducer = 'myAccount';
const tab = 'account';
const type = 'investor';
const reducer = 'data';

const mapStateToProps = state => ({ ...state[parentReducer][tab][type][reducer] });

const mapDispatchToProps = {
  closeModalForm: close,
  hidrateForm: hidrate,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorAccount);
