import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, TextField, LoadingSpinner } from 'liber-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import VMasker from 'vanilla-masker';
import { Countries, States, Cities } from '../../../../../vendor/Utils';
import {
  Row,
  ModalContent,
  ButtonsContainer,
  SubmitButton,
  HeaderRow,
  LoadingBox,
} from '../../Base.styles';
import {
  editField as edit,
  submitForm as submit,
} from '../../../actions/address/InvestorAddressActions';

export const InvestorAddressForm = ({ index, address, errors, loading, editField, submitForm }) => {
  const zipCode = VMasker.toPattern(address.zipCode, '99.999-999');
  return (
    <ModalContent>
      <HeaderRow>
        <h1>Editar Endereço</h1>
      </HeaderRow>
      <Row>
        <LoadingBox>
          <TextField
            label="CEP"
            name={`cep[${index}][address]`}
            id={`cep_${index}_address`}
            value={zipCode}
            errorMessage={errors.zipCode}
            onChange={value => {
              editField('zipCode', value);
            }}
            mask="99.999-999"
            maskChar={null}
            width="calc(100% - 20px)"
          />
          {loading === 'zipCode' ? <LoadingSpinner size="20px" /> : null}
        </LoadingBox>
      </Row>
      <Row>
        <div>
          <TextField
            label="Rua, Av., etc."
            name={`enderecos[${index}][address]`}
            id={`enderecos_${index}_address`}
            value={address.address}
            errorMessage={errors.address}
            onChange={value => {
              editField('address', value);
            }}
            disabled={zipCode.length < 10}
            width="100%"
          />
        </div>
        <div>
          <TextField
            label="Número"
            type="number"
            name={`enderecos[${index}][number]`}
            id={`enderecos_${index}_number`}
            value={address.number}
            errorMessage={errors.number}
            onChange={value => {
              editField('number', value);
            }}
            disabled={zipCode.length < 10}
            width="100%"
          />
        </div>
        <div>
          <TextField
            label="Complemento"
            name={`enderecos[${index}][complement]`}
            id={`enderecos_${index}_complement`}
            value={address.complement}
            errorMessage={errors.complement}
            onChange={value => {
              editField('complement', value);
            }}
            width="100%"
          />
        </div>
      </Row>
      <Row>
        <div>
          <TextField
            label="Bairro"
            name={`enderecos[${index}][district]`}
            id={`enderecos_${index}_district`}
            value={address.district}
            errorMessage={errors.district}
            onChange={value => {
              editField('district', value);
            }}
            disabled={zipCode.length < 10}
            width="100%"
          />
        </div>
        <div>
          <SelectField
            searchable
            label="País"
            name={`enderecos[${index}][country]`}
            id={`enderecos_${index}_country`}
            value={address.country}
            errorMessage={errors.country}
            onChange={value => {
              editField('country', value);
            }}
            disabled={zipCode.length < 10}
            width="100%"
          >
            {Object.keys(Countries).map(cnt => (
              <option key={_.uniqueId()} value={Countries[cnt]}>
                {cnt}
              </option>
            ))}
          </SelectField>
        </div>
        {address.country === 'BR' ? (
          <div>
            <SelectField
              searchable
              label="Estado"
              name={`enderecos[${index}][state]`}
              id={`enderecos_${index}_state`}
              errorMessage={errors.state}
              onChange={value => {
                editField('state', value, address.country);
              }}
              value={address.state}
              disabled={zipCode.length < 10}
              width="100%"
            >
              {Object.keys(States).map(st => (
                <option key={_.uniqueId()} value={States[st]}>
                  {st}
                </option>
              ))}
            </SelectField>
          </div>
        ) : (
          <div>
            <TextField
              label="Estado"
              name={`enderecos[${index}][state]`}
              id={`enderecos_${index}_state`}
              errorMessage={errors.state}
              onChange={value => {
                editField('state', value);
              }}
              value={address.state}
              disabled={zipCode.length < 10}
              width="100%"
            />
          </div>
        )}
      </Row>
      <Row>
        {address.country === 'BR' ? (
          <div>
            <SelectField
              searchable
              label="Cidade"
              name={`enderecos[${index}][city]`}
              id={`enderecos_${index}_city`}
              errorMessage={errors.city}
              onChange={value => {
                editField('city', value);
              }}
              value={address.city}
              disabled={zipCode.length < 10}
              width="100%"
            >
              {Cities[address.state] ? (
                Cities[address.state].map(city => (
                  <option key={_.uniqueId()} value={city}>
                    {city}
                  </option>
                ))
              ) : (
                <option value="">Selecione uma cidade</option>
              )}
            </SelectField>
          </div>
        ) : (
          <div>
            <TextField
              label="Cidade"
              name={`enderecos[${index}][city]`}
              id={`enderecos_${index}_city`}
              errorMessage={errors.city}
              onChange={value => {
                editField('city', value);
              }}
              value={address.city}
              disabled={zipCode.length < 10}
              width="100%"
            />
          </div>
        )}
      </Row>
      <ButtonsContainer>
        <SubmitButton
          loading={loading === 'submit'}
          onClick={() => {
            submitForm(address, index);
          }}
        >
          Enviar
        </SubmitButton>
      </ButtonsContainer>
    </ModalContent>
  );
};

InvestorAddressForm.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    district: PropTypes.string,
    zipCode: PropTypes.string,
    kind: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.string.isRequired,
  editField: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const parentReducer = 'myAccount';
const tab = 'address';
const type = 'investor';
const reducer = 'form';

const mapStateToProps = state => ({ ...state[parentReducer][tab][type][reducer] });
const mapDispatchToProps = {
  editField: edit,
  submitForm: submit,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvestorAddressForm);
