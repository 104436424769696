import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, CardContent, HeaderRow, PDFContainer } from '../../Base.styles';

export const InvestorContract = ({ access, termsOfUse }) => (
  <CardContent>
    <Row>
      <PDFContainer>
        <HeaderRow>
          <h1>Condições de Uso</h1>
        </HeaderRow>
        <object data={termsOfUse} type="application/pdf">
          <p>
            Visualização de PDF não suportada pelo Navegador.
            <br />
            Clique <a href={termsOfUse}>aqui</a> para baixar o contrato.
          </p>
        </object>
      </PDFContainer>
      <PDFContainer>
        <HeaderRow>
          <h1>Adesão</h1>
        </HeaderRow>
        <object data={access} type="application/pdf">
          <p>
            Visualização de PDF não suportada pelo Navegador.
            <br />
            Clique <a href={access}>aqui</a> para baixar o contrato.
          </p>
        </object>
      </PDFContainer>
    </Row>
  </CardContent>
);

InvestorContract.propTypes = {
  access: PropTypes.string.isRequired,
  termsOfUse: PropTypes.string.isRequired,
};

const parentReducer = 'myAccount';
const tab = 'contract';
const type = 'investor';

const mapStateToProps = state => ({ ...state[parentReducer][tab][type] });

export default connect(mapStateToProps)(InvestorContract);
