import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'liber-components';
import {
  handleShowVendorBlockUnblockModal,
  updateHandleIntermediateStatusModal,
} from '../../actions/AdminVendorsActions';
import Content from './components/Content';
import BlockUnblockModalProvider from './context';

export const HandleIntermediateStatusModal = ({
  show,
  handleShowHandleBlockUnblockModal,
  handleSelectedFunderToBlockOrUnblock,
  selectedFunder,
}) => {
  const handleLeave = () => handleShowHandleBlockUnblockModal(false);
  return (
    <Modal show={show} onLeaved={handleLeave}>
      <BlockUnblockModalProvider
        selectedFunder={selectedFunder}
        handleSelectedFunderToBlockOrUnblock={handleSelectedFunderToBlockOrUnblock}
        handleLeave={handleLeave}
      >
        <Content />
      </BlockUnblockModalProvider>
    </Modal>
  );
};

HandleIntermediateStatusModal.propTypes = {
  show: PropTypes.bool,
  handleShowHandleBlockUnblockModal: PropTypes.func,
  selectedFunder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    action: PropTypes.oneOf(['block', 'unblock']),
    target: {
      name: PropTypes.string,
      id: PropTypes.number,
    },
  }).isRequired,
  handleSelectedFunderToBlockOrUnblock: PropTypes.func,
};

HandleIntermediateStatusModal.defaultProps = {
  show: false,
  handleShowHandleBlockUnblockModal: () => null,
  handleSelectedFunderToBlockOrUnblock: () => null,
};

const mapStateToProps = ({
  adminVendors: { showHandleBlockUnblockModal, selectedFunderToBlockOrUnblock },
}) => ({
  show: showHandleBlockUnblockModal,
  selectedFunder: selectedFunderToBlockOrUnblock,
});

const mapDispatchToProps = {
  handleShowHandleBlockUnblockModal: updateHandleIntermediateStatusModal,
  handleSelectedFunderToBlockOrUnblock: handleShowVendorBlockUnblockModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HandleIntermediateStatusModal);
