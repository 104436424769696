import styled, { css } from 'styled-components';

const tooltipCssValue = css`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
`;

export const TooltipContentContainer = styled.div`
  max-width: 296px;
`;

export const TooltipContentText = styled.div`
  text-align: justify;
  color: #6287a7;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.06px;
  color: #6287a7;
`;

export const TooltipContentValues = styled.div`
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid #ecf0f3;
`;

export const TooltipContentNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TooltipContentLabel = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #c6d1d8;
`;

export const TooltipContentFace = styled.div`
  padding-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TooltipContentTitleValue = styled.div`
  ${tooltipCssValue}
  color: #4b6f85;
`;

export const TooltipContentFaceValue = styled.div`
  ${tooltipCssValue}
  color: #13ce66;
`;
