import axios from 'axios';

const apiUrl = SERVICE_API_URL; // eslint-disable-line
export const RENEGOTIATIONS_SUMMARY_URL = `${apiUrl}/api/v2/funder/renegotiations/overview`;
export const RENEGOTIATIONS_URL = `${apiUrl}/api/v2/funder/renegotiations`;
export const RENEGOTIATIONS_DETAILS_URL = `${apiUrl}/api/v2/funder/renegotiations/:slug`;

async function fetchSummary(token = '') {
  return axios.get(RENEGOTIATIONS_SUMMARY_URL, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

async function fetchTable(token = '', params = {}) {
  return axios.get(RENEGOTIATIONS_URL, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
}

async function fetchDetails(token = '', slug) {
  return axios.get(RENEGOTIATIONS_DETAILS_URL.replace(':slug', slug), {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export default { fetchDetails, fetchSummary, fetchTable };
