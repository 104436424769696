import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { setCurrent } from './progressBar';

export const changeMailerSubscription = value => ({
  type: 'WIZARD_INVESTIDOR/CONTRATOS/MAILER_SUBSCRIPTION',
  payload: value,
});

export const setData = data => ({
  type: 'WIZARD_INVESTIDOR/CONTRATOS/SET_DATA',
  payload: data || undefined,
});

export const fetchContratos = callback => dispatch =>
  axios({
    method: 'GET',
    url: '/investidor/cadastro.json',
    responseType: 'json',
  }).then(response => {
    dispatch(setData(response.data.data.steps.contratos));
    if (callback && typeof callback === 'function') callback();
  });

export const acceptContracts = () => dispatch =>
  axios({
    method: 'POST',
    url: '/investidor/contratos/aceitar.json',
    data: {
      authenticity_token: ReactOnRails.authenticityToken(),
    },
    responseType: 'json',
  }).then(() => {
    dispatch(setCurrent(7));
    dispatch({ type: 'WIZARD_INVESTIDOR/CONTRATOS/ACCEPT_CONTRACTS/SUCCESSFULL_SUBMIT' });
  });

export const submitContracts = mailerSubscription => dispatch =>
  axios({
    method: 'PATCH',
    url: '/investidores.json',
    data: {
      investidor: {
        mailer_subscription: mailerSubscription,
      },
      authenticity_token: ReactOnRails.authenticityToken(),
    },
    responseType: 'json',
  }).then(() => {
    dispatch(acceptContracts());
    dispatch({ type: 'WIZARD_INVESTIDOR/CONTRATOS/SUBMIT_CONTRACTS/SUCCESSFULL_SUBMIT' });
  });
