import React from 'react';
import { formatCurrency } from '../utils';

export const INVOICE_FIELD_MAP = [
  {
    label: 'Prazo:',
    getValue: invoice => {
      const { working } = invoice?.term || {};
      return (
        <>
          {working || 0} {working === 1 ? 'dia útil' : 'dias úteis'}
        </>
      );
    },
  },
  {
    label: 'Vencimento:',
    getValue: ({ liquidationDate }) => liquidationDate,
  },
  {
    label: 'Valor a Receber:',
    getValue: ({ toReceive }) => formatCurrency(toReceive),
  },
  {
    label: 'Valor Investido:',
    getValue: ({ invested }) => formatCurrency(invested),
  },
  {
    label: 'Rendimento:',
    getValue: ({ yield: yields }) => formatCurrency(yields),
  },
  {
    label: 'Rentabilidade:',
    getValue: invoice => <>{invoice?.profitability || '0,00%'} a.m.</>,
  },
];

export const INVOICE_TOOLTIP_FIELD_MAP = [
  {
    label: 'Valor a Receber:',
    getValue: ({ toReceive }) => formatCurrency(toReceive),
  },
  {
    label: 'Valor Investido:',
    getValue: ({ invested }) => formatCurrency(invested),
  },
  {
    label: 'Rendimento:',
    getValue: ({ yield: yields }) => formatCurrency(yields),
  },
  {
    label: 'Rentabilidade:',
    getValue: invoice => <>{invoice?.profitability || '0,00%'} a.m.</>,
  },
  {
    label: 'Rentabilidade/CDI:',
    getValue: invoice => <>{invoice?.reference?.cdi || '0,00%'} a.m.</>,
  },
];
