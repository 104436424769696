import { validateCnpj, validateEmail } from '../../../vendor/validation';
import { errorMsg } from '../messages';

export const FORNECEDOR_SIGNUP_TOKEN_RECEIVED = 'FORNECEDOR_SIGNUP_TOKEN_RECEIVED';

export function changeEmail(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_EMAIL',
    payload: value,
  };
}

export function setReCaptchaToken(value) {
  return {
    type: FORNECEDOR_SIGNUP_TOKEN_RECEIVED,
    payload: value,
  };
}

export function changeFullName(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_FULLNAME',
    payload: value,
  };
}
export function changeCompanyName(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_COMPANYNAME',
    payload: value,
  };
}

export function changeTradeName(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_TRADENAME',
    payload: value,
  };
}

export function changeCnpj(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_CNPJ',
    payload: value,
  };
}

export function changePhone(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_PHONE',
    payload: value,
  };
}

export function changeRamal(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_RAMAL',
    payload: value,
  };
}

export function changeCellphone(value) {
  return {
    type: 'FORNECEDOR_SIGNUP_CHANGE_CELLPHONE',
    payload: value,
  };
}

export function submitForm(event, email, fullName, companyName, tradeName, cnpj, phone) {
  event.preventDefault();
  const payload = {};
  if (email.length === 0) {
    payload.emailError = errorMsg.empty;
  } else if (!validateEmail(email)) {
    payload.emailError = errorMsg.invalidFormat;
  }
  if (fullName.length === 0) {
    payload.fullNameError = errorMsg.empty;
  }
  if (companyName.length === 0) {
    payload.companyNameError = errorMsg.empty;
  }
  if (tradeName.length === 0) {
    payload.tradeNameError = errorMsg.empty;
  }
  if (phone.length === 0) {
    payload.phoneError = errorMsg.empty;
  }
  if (cnpj.length === 0) {
    payload.cnpjError = errorMsg.empty;
  } else if (!validateCnpj(cnpj)) {
    payload.cnpjError = errorMsg.invalidFormat;
  }
  if (Object.keys(payload).length === 0) {
    event.target.submit();
    return {
      type: 'FORNECEDOR_SIGNUP_SUCESSFULL_SUBMIT',
    };
  }
  return {
    type: 'FORNECEDOR_SIGNUP_FAILED_SUBMIT',
    payload,
  };
}
