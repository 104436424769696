import axios from 'axios';
import {
  GET_RATES_PREVIEW,
  CONFIRM_RATES,
  SEND_SPREADSHEET_RATES,
  GET_VENDORS_GROUPS_URL,
} from './constants';

export const fetchRatesPreview = (id, token, params) =>
  axios.get(GET_RATES_PREVIEW.replace(':id', id), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const confirmRates = (id, token) =>
  axios.put(
    CONFIRM_RATES.replace(':id', id),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const sendSpreadsheet = async (token, spreadsheet, roomId) => {
  const formData = new FormData();
  formData.append('spreadsheet', spreadsheet);
  formData.append('room_id', roomId);

  return axios.post(SEND_SPREADSHEET_RATES, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    timeout: 15000,
  });
};

export const fetchVendorsGroups = async (token, params) =>
  axios.get(GET_VENDORS_GROUPS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
