import styled from 'styled-components';

import StickyBar from 'liber-components/components/StickyBar';

export const StickyContainer = styled(StickyBar)`
  position: sticky;
  top: 0px;
  z-index: 200;
  background-color: #fff;
`;

export const HeaderContainer = styled.div`
  & > div > div {
    flex-basis: 100%;
  }
`;
