import { connect } from 'react-redux';
import InvestorDashboard from './InvestorDashboard';
import { closeInvoiceList } from '../../actions/InvoicesActions';

const mapStateToProps = ({ investorDashboard: { wallet, invoices } }) => ({
  ...invoices,
  wallet,
});
const mapDispatchToProps = {
  closeInvoiceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorDashboard);
