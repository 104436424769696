import styled from 'styled-components';
import { SelectionTableHeaderColumn, FaCaretUp, FaCaretDown } from 'liber-components';

export const FaCaretUpStyled = styled(FaCaretUp)`
  height: 14px;
  width: 14px;
  fill: #4b6f85;
`;

export const FaCaretDownStyled = styled(FaCaretDown)`
  height: 14px;
  width: 14px;
  fill: #4b6f85;
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;

export const InvoiceSimulationSelectionTableHeaderColumn = styled(SelectionTableHeaderColumn)`
  cursor: pointer;
  border: none;
`;

export const InvoiceSimulationSelectionTableHeaderColumnContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
