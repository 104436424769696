import { connect } from 'react-redux';
import { ProgressBar } from 'liber-components';
import { setCurrent } from '../../actions/progressBar';

const mapStateToProps = state => ({
  steps: state.progressBar.steps,
  current: state.progressBar.current,
  completed: state.progressBar.completed,
});

const mapDispatchToProps = dispatch => ({
  onClick: (label, num) => dispatch(setCurrent(num)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
