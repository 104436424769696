import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import moment from 'moment';
import { mdiDownload, mdiCheckboxMarkedCircle, mdiClock } from '@mdi/js';
import {
  Row,
  Title,
  FlatButton,
  FieldContainer,
  FieldLabel,
  FieldValue,
  StatusIcon,
  Caption,
  Card,
  CardsRow,
  ButtonsContainer,
} from './SummaryCards.styles';
import BankAccount from '../BankAccount/BankAccount';
import ProgressBar from '../../../ProgressBar';

const AUTOMATIC_SIGNATURE_KIND = 'automatic_signature';

export const SummaryCards = ({ summary, status, isSolicitation }) => {
  const {
    buyer: { name: buyerName, cnpj },
    paidValue,
    netValue,
    invoiceCount,
    paidInvoiceCount,
    requestedAt,
    operators,
    bankAccount,
    signatureCertificateUrl,
    invoiceTransferAgreementUrl,
    signatureKind,
    receiptsUrl,
    signatures: { required },
  } = summary;

  const handleOpenDocument = url => {
    window.open(url);
  };

  const handleClickSigningCertificate = () => {
    handleOpenDocument(signatureCertificateUrl);
  };

  const handleClickTransferAgreement = () => {
    handleOpenDocument(invoiceTransferAgreementUrl);
  };

  const needConfirmation = useMemo(
    () => operators.reduce((prev, { userHasSigned }) => prev || !userHasSigned, false),
    [operators],
  );
  const getSubstantives = number => `representante${number > 1 ? 's legais' : ' legal'}`;

  const getPaidValue = value => (value === 'R$ 0,00' ? '***' : value);

  const getAnticipatedCountLabel = useCallback(
    () => `${paidInvoiceCount || 0} de ${invoiceCount} solicitado${invoiceCount === 1 ? '' : 's'}`,
    [paidInvoiceCount, invoiceCount],
  );

  const showConfirmationAndPaymentCards = useMemo(
    () => !['created', 'started'].includes(status),
    [status],
  );

  const hasPaid = useMemo(() => status === 'finished', [status]);

  const renderDownloadReceiptButton = () =>
    receiptsUrl ? (
      <FlatButton blue onClick={() => handleOpenDocument(receiptsUrl)}>
        <Icon path={mdiDownload} />
        Comprovante de Pagamento
      </FlatButton>
    ) : null;

  return (
    <CardsRow>
      <Card>
        <Title>Resumo</Title>
        <FieldContainer>
          <FieldLabel>Sacado</FieldLabel>
          <FieldValue>{buyerName}</FieldValue>
          <Caption>{`CNPJ ${cnpj}`}</Caption>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Valor {needConfirmation ? 'Solicitado' : 'Antecipado'}</FieldLabel>
          <FieldValue>{needConfirmation ? netValue : getPaidValue(paidValue)}</FieldValue>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Títulos Antecipados</FieldLabel>
          <Caption>{getAnticipatedCountLabel()}</Caption>
          <ProgressBar progressed={paidInvoiceCount} total={invoiceCount} />
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Solicitação</FieldLabel>
          <FieldValue>{moment(requestedAt).format('DD/MM/YYYY')}</FieldValue>
        </FieldContainer>
      </Card>

      {showConfirmationAndPaymentCards && !isSolicitation ? (
        <>
          <Card>
            <Title>Confirmação</Title>
            {needConfirmation ? (
              <Caption>
                {`*Esta antecipação exige a confirmação de ${required} ${getSubstantives(
                  required,
                )}`}
              </Caption>
            ) : null}
            <FieldContainer>
              {signatureKind === AUTOMATIC_SIGNATURE_KIND ? (
                <>
                  <FieldLabel>Assinatura Automática</FieldLabel>
                </>
              ) : (
                <>
                  <FieldLabel>Representantes Legais</FieldLabel>
                  {operators.length > 0 ? (
                    <>
                      {operators.map(({ id, name, userHasSigned }) => (
                        <Row key={`operator-${id}`} height="32">
                          <StatusIcon
                            status={userHasSigned ? 'positive' : 'neutral'}
                            path={userHasSigned ? mdiCheckboxMarkedCircle : mdiClock}
                          />
                          <FieldValue>{name}</FieldValue>
                        </Row>
                      ))}
                    </>
                  ) : (
                    <>
                      <FieldValue>Não há representantes legais</FieldValue>
                    </>
                  )}
                </>
              )}
            </FieldContainer>
            <ButtonsContainer>
              {signatureCertificateUrl ? (
                <FlatButton blue onClick={handleClickSigningCertificate}>
                  <Icon path={mdiDownload} />
                  Certificado de Assinatura
                </FlatButton>
              ) : null}
              {invoiceTransferAgreementUrl ? (
                <FlatButton blue onClick={handleClickTransferAgreement}>
                  <Icon path={mdiDownload} />
                  Termo de Cessão
                </FlatButton>
              ) : null}
            </ButtonsContainer>
          </Card>

          <Card>
            <Title>Pagamento</Title>
            <BankAccount {...bankAccount} />
            <ButtonsContainer>
              {hasPaid ? (
                renderDownloadReceiptButton()
              ) : (
                <Caption>
                  O pagamento referente à antecipação será realizado na conta bancária acima em até
                  1 dia útil.
                </Caption>
              )}
            </ButtonsContainer>
          </Card>
        </>
      ) : null}
    </CardsRow>
  );
};

SummaryCards.propTypes = {
  summary: PropTypes.shape({
    buyer: PropTypes.shape({
      name: PropTypes.string,
      cnpj: PropTypes.string,
    }),
    paidValue: PropTypes.string,
    netValue: PropTypes.string,
    invoiceCount: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    paidInvoiceCount: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    requestedAt: PropTypes.string,
    operators: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        userHasSigned: PropTypes.bool,
      }),
    ),
    bankAccount: {
      branch: PropTypes.string,
      branchCd: PropTypes.string,
      account: PropTypes.string,
      accountCd: PropTypes.string,
      bankName: PropTypes.string,
      id: PropTypes.number,
      bankCode: PropTypes.string,
    },
    signatureCertificateUrl: PropTypes.string,
    invoiceTransferAgreementUrl: PropTypes.string,
    signatureKind: PropTypes.bool,
    receiptsUrl: PropTypes.string,
    signatures: PropTypes.shape({
      required: PropTypes.number,
    }),
  }),
  isAdmin: PropTypes.bool,
  status: PropTypes.string,
  isSolicitation: PropTypes.bool,
};

SummaryCards.defaultProps = {
  summary: {
    buyer: '',
    paidValue: '',
    netValue: '',
    invoiceCount: '',
    paidInvoiceCount: '',
    requestedAt: '',
    operators: [],
    bankAccount: {
      branch: '',
      branchCd: '',
      account: '',
      accountCd: '',
      bankName: '',
      id: null,
      bankCode: '',
    },
    signatureCertificateUrl: '',
    invoiceTransferAgreementUrl: '',
    signatureKind: '',
    receiptsUrl: '',
    signatures: {
      required: 1,
    },
  },
  isAdmin: false,
  status: '',
  isSolicitation: false,
};

const mapStateToProps = ({ vendorBorderoShow: { summary, isSolicitation } }) => ({
  summary,
  isSolicitation,
});

export default connect(mapStateToProps)(SummaryCards);
