import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4, ToastContainer } from 'liber-components';
import { SchedulesStore } from './context';
import ScheduledAnticipations from './components/ScheduledAnticipations';

const View = ({ token = 'randon-token' }) => (
  <ThemeProvider theme={LiberV4}>
    <ToastContainer />
    <SchedulesStore token={token}>
      <ScheduledAnticipations token={token} />
    </SchedulesStore>
  </ThemeProvider>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
};

export default View;
