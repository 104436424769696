import React from 'react';
import PropTypes from 'prop-types';
import { LogoContainer, Column, CompanyName, CompanyInfo, Image } from './VendorInfo.styles';

const VendorInfo = ({ vendor }) => {
  const {
    company,
    tradeName,
    cnpj,
    signature: { method, type },
    logoUrl,
  } = vendor;
  return (
    <>
      <LogoContainer>
        <Image src={logoUrl} />
      </LogoContainer>
      <Column>
        <CompanyName>{company}</CompanyName>
        <CompanyInfo>{tradeName}</CompanyInfo>
        <CompanyInfo>{cnpj}</CompanyInfo>
        <CompanyInfo>{`${method} - ${type}`}</CompanyInfo>
      </Column>
    </>
  );
};

VendorInfo.propTypes = {
  vendor: PropTypes.shape({
    company: PropTypes.string,
    tradeName: PropTypes.string,
    cnpj: PropTypes.string,
    logoUrl: PropTypes.string,
    signature: PropTypes.shape({
      method: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
};

VendorInfo.defaultProps = {
  vendor: {},
};

export default VendorInfo;
