import styled, { css } from 'styled-components';

const buttonModifiers = {
  theme: {
    pre_approved: () => css`
      background: #ffffff;
      border: 1px solid #4cbaff;
    `,
    under_review: () => css`
      background: #fabe00;
      border: 1px solid rgba(64, 95, 113, 0.12);
    `,
    blocked: () => css`
      background: #f2b2b8;
      border: 1px solid rgba(64, 95, 113, 0.12);
    `,
    unblocked: () => css`
      background: #a5ecc5;
      border: 1px solid rgba(64, 95, 113, 0.12);
    `,
  },
};

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  ${({ status }) => css`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    width: 140px;
    padding: 5px 16px;
    border-radius: 4px;
    color: #405f71;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none !important;

    ${buttonModifiers.theme[status]()}
  `}
`;

export const Listbox = styled.ul`
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 8px;
  width: 140px;
  border-radius: 8px;
  overflow: auto;
  outline: 0px;
  background: #fff;
  color: #405f71;
  box-shadow: 0px 6px 4px -2px rgba(106, 124, 160, 0.15), 0px 20px 40px 4px rgba(80, 98, 134, 0.3),
    inset 0px 0px 0px 1px rgba(80, 98, 134, 0.25);
  z-index: 999;

  svg {
    margin-right: 10px;
  }
`;

export const Option = styled.div`
  list-style: none;
  padding: 8px;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  background: ${props => (props.disabled ? '#EBEBE4' : 'none')};

  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${props => (props.disabled ? '#EBEBE4' : '#d9e9fc')};
  }
`;
