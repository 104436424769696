import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sidebar, AppBar } from 'liber-salti';
import { useMediaQuery, useTheme } from '@mui/material';

import { dispatchAmplitudeEvent } from '../../vendor/Utils';

import { getCookieSidebarExpanded, setCookieSidebarExpanded } from './utils';

import Item from './components/Item';
import { AppBarContainer, Container, ApplicationWrapperStyles, UserMenuStyles } from './styles.jsx';
import SaltiThemeWrapper from '../SaltiThemeWrapper';

const SaltiSidebar = ({ cookieDomain, logoUrl, name, portalName, sidebarRoutes, userMenu }) => {
  const initialSidebarExpanded = getCookieSidebarExpanded();

  const [open, setOpen] = useState(initialSidebarExpanded);

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const { pathname } = window.location;

  const handleToggle = () => {
    dispatchAmplitudeEvent('buyer_sidebar', { option: open ? 'Comprimir menu' : 'Expandir menu' });
    setOpen(!open);
  };

  const navigate = path => {
    window.location.assign(path);
  };

  useEffect(() => {
    const leftMargin = open ? '259px' : '71px';
    const wrapperComponent = document.getElementById('application-wrapper');
    const footerComponent = document.getElementById('footer-wrapper');

    if (wrapperComponent && footerComponent) {
      if (isMobile) {
        wrapperComponent.style.marginLeft = 0;
        footerComponent.style.marginLeft = 0;
      } else {
        wrapperComponent.style.marginLeft = leftMargin;
        footerComponent.style.marginLeft = leftMargin;
      }
    }
  }, [open, isMobile]);

  useEffect(() => {
    if (isMobile) setOpen(false);
  }, [isMobile]);

  useEffect(() => {
    setCookieSidebarExpanded(open, cookieDomain);
  }, [open]);

  return (
    <SaltiThemeWrapper>
      <Container>
        <UserMenuStyles />
        <ApplicationWrapperStyles open={open} />
        {isMobile && (
          <AppBarContainer>
            <AppBar
              iconName={open ? 'Dismiss' : 'LineHorizontal3'}
              logo={logoUrl}
              title={portalName}
              onClick={handleToggle}
            />
          </AppBarContainer>
        )}
        <Sidebar
          logo={logoUrl}
          smallLogo={logoUrl}
          name={portalName}
          handleToggle={handleToggle}
          open={open}
          userSettings={{
            menu: userMenu,
            avatarIcon: 'Person',
            primaryText: name,
            secondaryText: portalName,
          }}
        >
          {sidebarRoutes.map(({ label, icon, path, subPaths, routePatterns }) => (
            <Item
              key={label}
              open={open}
              pathname={pathname}
              label={label}
              icon={icon}
              path={path}
              routePatterns={routePatterns}
              subPaths={subPaths}
              navigate={navigate}
            />
          ))}
        </Sidebar>
      </Container>
    </SaltiThemeWrapper>
  );
};

SaltiSidebar.propTypes = {
  cookieDomain: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  portalName: PropTypes.string.isRequired,
  sidebarRoutes: PropTypes.arrayOf(PropTypes.any).isRequired,
  userMenu: PropTypes.arrayOf(PropTypes.any).isRequired,
};

SaltiSidebar.defaultProps = {};

export default SaltiSidebar;
