import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress, PrimaryLink, PrimaryButton } from 'liber-components';
import {
  Container,
  Title,
  Description,
  LoadingBunny,
  ProgressContainer,
  ProgressText,
  Image,
} from './AnticipationCancel.styles';
import CheckImage from '../../../../../assets/images/check.svg';
import EmptyImage from '../../../../../assets/images/empty.svg';
import { requestCancel, subscribeCancel } from '../actions/AnticipationCancelActions';

const getMessageText = (isFinished, hasError) => {
  if (isFinished) {
    return (
      <>
        A sua antecipação foi cancelada. <br />
        Os títulos estão disponíveis para antecipação novamente.
      </>
    );
  }

  if (hasError) {
    return (
      <>
        Ocorreu um erro com o cancelamento da antecipação. <br />
        Repita o processo para corrigi-lo.
      </>
    );
  }

  return (
    <>
      Aguarde enquanto cancelamos o seu pedido de antecipação. <br />
      Este processo pode levar alguns minutos.
    </>
  );
};

export const AnticipationCancel = ({
  subscribe,
  requestCancellation,
  cancellationProgress,
  isFinished,
  hasError,
  isConnected,
  isSolicitation,
}) => {
  React.useEffect(() => {
    subscribe();
  }, []);

  React.useEffect(() => {
    if (isConnected) {
      requestCancellation();
    }
  }, [isConnected]);

  return (
    <Container>
      <Title>Cancelar {isSolicitation ? 'Solicitação' : 'Antecipação'}</Title>
      <Description>{getMessageText(isFinished, hasError)}</Description>
      {!isFinished && !hasError && (
        <>
          <LoadingBunny loop />
          <ProgressContainer>
            <LinearProgress height={16} determinate value={cancellationProgress} />
          </ProgressContainer>
          <ProgressText>{`${cancellationProgress.toFixed(0)}%`}</ProgressText>
        </>
      )}
      {hasError && (
        <>
          <Image src={EmptyImage} />
          <PrimaryButton
            version={2}
            onClick={() => {
              requestCancellation();
            }}
          >
            REPETIR
          </PrimaryButton>
        </>
      )}
      {isFinished && (
        <>
          <Image src={CheckImage} />
          <PrimaryLink version={2} href="/fornecedor/duplicatas">
            FINALIZAR
          </PrimaryLink>
        </>
      )}
    </Container>
  );
};

AnticipationCancel.propTypes = {
  subscribe: PropTypes.func,
  requestCancellation: PropTypes.func,
  cancellationProgress: PropTypes.number,
  isFinished: PropTypes.bool,
  hasError: PropTypes.bool,
  isConnected: PropTypes.bool,
  isSolicitation: PropTypes.bool,
};

AnticipationCancel.defaultProps = {
  subscribe: () => {},
  requestCancellation: () => {},
  cancellationProgress: 0,
  isFinished: false,
  hasError: false,
  isConnected: false,
  isSolicitation: false,
};

const mapStateToProps = ({
  anticipationCancel: { cancellationProgress, isFinished, hasError, isConnected, isSolicitation },
}) => ({
  cancellationProgress,
  isFinished,
  hasError,
  isConnected,
  isSolicitation,
});

const mapDispatchToProps = {
  subscribe: subscribeCancel,
  requestCancellation: requestCancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnticipationCancel);
