import axios from 'axios';
import { toast, errors } from 'liber-components';
import { statement } from '../urls';

export const fetchByPeriod = (startDate, endDate, token) => dispatch => {
  dispatch({
    type: 'STATEMENT_PENDING',
  });
  axios
    .get(statement, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    })
    .then(payload => {
      dispatch({
        type: 'STATEMENT_SUCCESS',
        payload,
      });
    })
    .catch(error => {
      const message = errors.http[{ ...error.response }.status || 500];
      toast(message, 'error', 8000);
      dispatch({
        type: 'STATEMENT_REJECTED',
      });
    });
};

export const empty = null;
