import axios from 'axios';
import { handleError, convertToCamelCase } from '../../vendor/Utils';
import { GET_VENDOR_URL } from './urls';

export const fetchVendor = (token, vendorId, setState) =>
  axios
    .get(`${GET_VENDOR_URL}${vendorId}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => {
      const {
        data: { data },
      } = response;
      setState(convertToCamelCase(data));
    })
    .catch(() => handleError());
