import styled, { css } from 'styled-components';
import {
  Accordion as AccordionRaw,
  FaInfoCircle,
  FaExclamationTriangle,
  FaFileTextO as FaFilePdfO,
  FaTimesCircle,
  FaTimes,
  FaCheck,
  FaCheckCircle,
  FaWindows,
  FaChrome,
  FaFirefox,
  FaCloudDownload,
  LoadingBunny,
  PrimaryButton,
  SecondaryButton,
  handleThemeFromObject,
} from 'liber-components';
import {
  Row,
  ModalContainer,
  IconLinkButton,
  LinkButton,
} from 'liber-components/components/GenericStyles';

export const Accordion = styled(AccordionRaw)`
  & > * {
    overflow: unset;
  }
`;

export const ProposeRow = styled.div`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
  line-height: 28px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & span {
    flex-grow: 1;
    opacity: 0.75;
    margin-left: 8px;
  }
`;

export const InfoMessage = styled(Row)`
  line-height: 24px;
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  margin-bottom: 20px;
  align-items: ${props => props.alignItems} & span {
    opacity: 0.75;
  }
  & > * > span {
    opacity: 0.75;
  }
`;

export const LargeInfoMessage = styled(InfoMessage)`
  line-height: 28px !important;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')} !important;
`;

export const InfoIcon = styled(FaInfoCircle)`
  width: 22px;
  height: 22px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e', props.fill)};
  opacity: ${props => (props.fill ? '1' : '0,75')};
  margin-right: 10px;
`;

export const PdfIcon = styled(FaFilePdfO)`
  width: 18px;
  height: 20px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e', props.fill)};
  opacity: ${props => (props.fill ? '1' : '0.75')};
  cursor: pointer;
`;
export const DownloadIcon = styled(FaCloudDownload)`
  width: 24px;
  height: 20px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e', props.fill)};
  opacity: ${props => (props.fill ? '1' : '0.75')};
  cursor: pointer;
`;

export const ExclamationIcon = styled(FaExclamationTriangle)`
  width: 18px;
  height: 20px;
  fill: #e8b962;
  margin-right: 10px;
`;

export const ExclamationIconSmall = styled(ExclamationIcon)`
  width: 15px;
  height: 15px;
`;

export const ErrorCircleIcon = styled(FaTimesCircle)`
  width: 25px;
  height: 25px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.dangerRed', '#dc3545')};
  margin-right: 10px;
`;

export const ErrorCircleIconSmall = styled(ErrorCircleIcon)`
  width: 15px;
  height: 15px;
`;
export const ErrorIcon = styled(FaTimes)`
  width: 16px;
  height: 16px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.dangerRed', '#dc3545')};
  margin-right: 10px;
  position: ${props => (props.fixed ? 'relative' : 'absolute')};
  top: ${props => (props.fixed ? null : 'calc(50% - 8px)')};
  left: ${props => (props.fixed ? null : '16px')};
  opacity: ${props => (props.show ? '1' : '0')};
  transition: opacity 100ms ease-in-out;
`;
export const SucessCircleIcon = styled(FaCheckCircle)`
  width: 25px;
  height: 25px;
  fill: #5cb85c;
  margin-right: 10px;
`;
export const SucessIcon = styled(FaCheck)`
  width: 16px;
  height: 16px;
  fill: #5cb85c;
  margin-right: 10px;
  margin-left: ${props => (props.fixed ? '10px' : null)};
  position: ${props => (props.fixed ? 'relative' : 'absolute')};
  top: ${props => (props.fixed ? null : 'calc(50% - 8px)')};
  left: ${props => (props.fixed ? null : '16px')};
  opacity: ${props => (props.show ? '1' : '0')};
  transition: opacity 100ms ease-in-out;
`;

export const WindowsIcon = styled(FaWindows)`
  width: 15px;
  height: 15px;
`;

export const ChromeIcon = styled(FaChrome)`
  width: 15px;
  height: 15px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  transition: fill 0.2s ease;
`;

export const IconLink = styled.a`
  &:hover > * {
    fill: ${props => props.fill || '#006eb3'};
  }
`;

export const FirefoxIcon = styled(FaFirefox)`
  width: 15px;
  height: 15px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009DFF')};
  transition: fill 0.2s ease;
`;

export const LoadingBox = styled.div`
  max-width: 100%;
  width: 770px;
  height: 283.3px;
  border-left: 7px solid
    ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  border-right: 7px solid
    ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 2px;
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 20px;
  box-sizing: border-box;
  & span {
    text-align: center;
  }
`;

export const BlueBunny = styled(LoadingBunny)`
  width: 112.8px;
  height: 55.6px;
  fill: none;
  stroke: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2.52px;
`;

export const ErrorBox = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 10px 44px 10px 22px;

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const ErrorTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
`;

export const ErrorMsg = styled.div`
  margin-left: 25px;
  margin-bottom: 15px;
`;

export const PdfContainer = styled.object`
  flex-grow: 1;
`;

export const ErrorModalContainer = styled(ModalContainer)`
  height: unset;
  max-height: 80vh;
`;

export const EnumeratedList = styled.ol`
  & > li {
    list-style: decimal;
  }
`;

export const Faded = styled.div`
  opacity: ${props => (props.fade ? '0.2' : '1')};
  transition: opacity 100ms ease-in-out;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > * {
    margin-bottom: 10px;
  }
`;

export const DownloadButton = styled(IconLinkButton)`
  width: 34px;
  &:hover > * {
    fill: #5cb85c;
  }
`;

export const DownloadButtonAllButton = styled(LinkButton)`
  width: 283px;
  margin: 25px 0px 0px 10px;
  background-color: ${props =>
    handleThemeFromObject(props.theme, 'colors.successGreen', '#13ce66')};
  opacity: 1;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberWhite', '#fafafa')} !important;
  &:hover {
    background-color: #009c39;
  }
  & > ${DownloadIcon} {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
    margin-right: 20px;
    opacity: 1;
  }
`;

export const ShowMore = styled.a`
  white-space: nowrap;
  color: #009dff !important;
  transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &:hover {
    color: #006fcb !important;
  }
`;

const BlueButtonProps = css`
  width: 341px;
  height: 49px;
  margin-top: 25px;
  margin-left: 10px;
`;

export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${BlueButtonProps}
`;

export const BlueButton = styled(PrimaryButton)`
  ${BlueButtonProps}
`;

export const WhiteButton = styled(SecondaryButton)`
  width: 233px;
  height: 49px;
  margin-top: 25px;
  margin-left: 10px;
`;

export const InfoList = styled.ul`
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  margin-bottom: 20px;
  & > ul > li {
    list-style: disc;
    & > a {
      color: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
    }
  }
`;
