import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link, Alert } from 'liber-salti';
import { AlertWrapper } from './styles';

function Message({ liberIdUrl, legacyLoginAvailableUntil }) {
  const maximumDateString = moment(legacyLoginAvailableUntil).format('DD/MM');

  const message = (
    <>
      Não fique sem acesso ao seu portal na Liber.
      <br />A partir do dia <b>{maximumDateString}</b> seu acesso à plataforma será migrado para
      novo sistema de identificação, o Liber-ID.
      <br />
      Para não perder o seu acesso é necessário fazer cadastro clicando{' '}
      <Link href={liberIdUrl} target="_blank" referrerPolicy="no-referrer">
        AQUI
      </Link>
    </>
  );

  return (
    <div className="container">
      <AlertWrapper>
        <Alert severity="warning" description={message} title="ATENÇÃO" variant="standard" />
      </AlertWrapper>
    </div>
  );
}

Message.propTypes = {
  liberIdUrl: PropTypes.string.isRequired,
  legacyLoginAvailableUntil: PropTypes.string.isRequired,
};

export default Message;
