import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryButton, Button } from 'liber-components';
import {
  ModalTitle,
  ModalSubTitle,
  ModalContainer,
  ModalFooter,
  ModalContent,
  InputsArea,
  CustomModal,
  FilterModalGlobalStyle,
} from './Filters.styles';
import { FILTERS, FILTERS_DOCUMENT } from './utils';
import { fetchAdminNegotiations, clearFilters } from '../../../actions/AdminNegotiationsActions';
import InputField, { WAIT_INTERVAL } from './InputField';
import useEventListener from '../../../../../hooks/useEventListener';

export const FilterModal = ({ show, onLeaved, onFilter, onClearFilters }) => {
  const handleFilter = () => {
    onFilter();
    onLeaved();
  };

  const handleClear = () => {
    onClearFilters();
    onLeaved();
  };

  const doNothing = () => null;
  const handlePressEnter = ({ key }) => {
    if (String(key) === 'Enter') {
      setTimeout(() => {
        handleFilter();
      }, WAIT_INTERVAL);
    }
  };

  useEventListener('keyup', show ? handlePressEnter : doNothing);

  return (
    <>
      {show && <FilterModalGlobalStyle />}
      <CustomModal show={show}>
        <ModalContainer>
          <ModalTitle>
            Filtros
            <PrimaryButton id="clear-btn" version={2} size="large" outlined onClick={handleClear}>
              LIMPAR
            </PrimaryButton>
          </ModalTitle>
          <ModalContent>
            <InputsArea>
              {FILTERS.map(input => (
                <InputField key={input.label} input={input} />
              ))}
            </InputsArea>
            <ModalSubTitle>Por tipo de anexo</ModalSubTitle>
            <InputsArea>
              {FILTERS_DOCUMENT.map(input => (
                <InputField key={input.label} input={input} />
              ))}
            </InputsArea>
          </ModalContent>
          <ModalFooter>
            <Button id="cancel-btn" version={2} size="large" onClick={onLeaved}>
              CANCELAR
            </Button>
            <PrimaryButton id="filter-btn" version={2} size="large" onClick={handleFilter}>
              FILTRAR
            </PrimaryButton>
          </ModalFooter>
        </ModalContainer>
      </CustomModal>
    </>
  );
};

FilterModal.propTypes = {
  show: PropTypes.bool,
  onLeaved: PropTypes.func,
  onFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
};

FilterModal.defaultProps = {
  show: true,
  onLeaved: () => null,
  onFilter: () => null,
  onClearFilters: () => null,
};

const mapDispatchToProps = {
  onFilter: fetchAdminNegotiations,
  onClearFilters: clearFilters,
};

export default connect(null, mapDispatchToProps)(FilterModal);
