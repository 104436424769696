import { connect } from 'react-redux';
import Fornecedor from './Fornecedor';
import {
  changeEmail,
  changePassword,
  toogleRemember,
  submitLogin,
  setFingerprint,
} from '../../actions/fornecedorActions';

const mapStateToProps = state => ({
  email: state.fornecedor.email,
  emailError: state.fornecedor.emailError,
  password: state.fornecedor.password,
  passwordError: state.fornecedor.passwordError,
  remember: state.fornecedor.remember,
  path: state.fornecedor.path,
  flashes: state.fornecedor.flashes,
  passwordPath: state.fornecedor.passwordPath,
  logoUrl: state.fornecedor.logoUrl,
  sacadoUrl: state.fornecedor.sacadoUrl,
  registrationPath: state.fornecedor.registrationPath,
  fingerprint: state.fornecedor.fingerprint,
  components: state.fornecedor.components,
});

const mapDispatchToProps = {
  changeEmail,
  changePassword,
  toogleRemember,
  submitLogin,
  setFingerprint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fornecedor);
