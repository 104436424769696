import axios from 'axios';
import { SCHEDULES_DELETE_URL, SCHEDULES_LIST_URL } from './constants';

export async function fetchScheduled(token = '', params = {}) {
  return axios.get(SCHEDULES_LIST_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
}

export async function deleteScheduled(token = '', data = {}) {
  return axios.delete(SCHEDULES_DELETE_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}
