import axios from 'axios';
import {
  VENDORS_LIST_URL,
  GROUP_VENDORS_LIST_URL,
  CREATE_GROUP_URL,
  EDIT_GROUP_URL,
  SHOW_GROUP_URL,
  REQUEST_TIMEOUT,
  BATCH_SIZE,
  FUNDERS_LIST_URL,
  BUYER_USER_URL,
} from './constants';
import { arrayFromXtoY } from './utils';

export async function fetchGroup(token = '', groupId) {
  return axios.get(SHOW_GROUP_URL.replace(':id', groupId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchBuyerUser(token = '') {
  return axios.get(BUYER_USER_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const baseFetchVendorsRequest = ({ token, url, per, page = 1 }) =>
  axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: REQUEST_TIMEOUT,
    params: { per, page },
  });

export async function baseFetchVendors({ token, per, url }) {
  if (per < BATCH_SIZE) return baseFetchVendorsRequest({ token, url, per });

  const firstRequest = await baseFetchVendorsRequest({ token, url, per: BATCH_SIZE });
  const {
    data: { data: firstData },
  } = firstRequest;

  const allResults = await Promise.all(
    arrayFromXtoY(2, firstData.pagination.pages).map(page =>
      baseFetchVendorsRequest({ token, url, per: BATCH_SIZE, page }),
    ),
  );

  const data = allResults.reduce(
    (prev, curr) => ({
      vendors: [...prev.vendors, ...(curr.data?.data?.vendors || [])],
      pagination: curr.data?.data?.pagination || prev.pagination,
    }),
    { vendors: firstData.vendors, pagination: firstData.pagination },
  );

  return {
    data: { data },
  };
}

export async function fetchVendors(token = '', per, groupId) {
  return baseFetchVendors({ token, per, url: VENDORS_LIST_URL.replace(':id', groupId) });
}

export async function fetchGroupVendors(token = '', per, groupId) {
  return baseFetchVendors({ token, per, url: GROUP_VENDORS_LIST_URL.replace(':id', groupId) });
}

export async function createGroup(
  token = '',
  { name, reference, vendorIds, funderTargetingMode, selectedFunderId, rebateIncrement },
) {
  return axios.post(
    CREATE_GROUP_URL,
    {
      name,
      reference_code: reference,
      vendor_ids: vendorIds,
      funder_targeting_mode: funderTargetingMode,
      funder_id: selectedFunderId,
      rebate_increment: rebateIncrement,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 80000,
    },
  );
}

export async function editGroup(
  token = '',
  { name, vendorsIdsToAdd, vendorsIdsToRemove, funderTargetingMode, selectedFunderId },
  groupId,
) {
  return axios.put(
    EDIT_GROUP_URL.replace(':id', groupId),
    {
      name,
      vendor_ids_to_add: vendorsIdsToAdd,
      vendor_ids_to_remove: vendorsIdsToRemove,
      funder_targeting_mode: funderTargetingMode,
      funder_id: selectedFunderId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 80000,
    },
  );
}

export async function fetchFunders(token = '') {
  return axios.get(FUNDERS_LIST_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
