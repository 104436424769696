import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import FilterTag from '../../../../common-components/Filters/FilterTag';
import SelectTag from '../../../../common-components/SelectTag/SelectTag';
import {
  SIGNATURE_METHOD_FILTER,
  SIGNATURE_CERTIFICATION_FILTER,
  updateFilters,
} from '../../actions/AdminVendorsActions';
import FilterFooter from '../../../../common-components/Filters/FilterFooter';
import { FlexColumn } from './SignatureFilter.styles';

const CPF = 0;
const CNPJ = 1;
const AUTOMATIC = 3;
const SIGN = 4;
const SIGN_BORDERO = 5;

const LACUNA = 0;
const ASSINAME = 1;

const options = [
  {
    label: 'Automática',
    method: AUTOMATIC,
    certification: null,
  },
  {
    label: 'e-CNPJ/Assiname',
    method: CNPJ,
    certification: ASSINAME,
  },
  {
    label: 'e-CNPJ/Lacuna',
    method: CNPJ,
    certification: LACUNA,
  },
  {
    label: 'e-CPF/Assiname',
    method: CPF,
    certification: ASSINAME,
  },
  {
    label: 'e-CPF/Lacuna',
    method: CPF,
    certification: LACUNA,
  },
  {
    label: 'Liber Sign',
    method: SIGN,
    certification: null,
  },
  {
    label: 'Liber Sign/Borderô',
    method: SIGN_BORDERO,
    certification: null,
  },
];

export const SignatureFilter = ({ certificationFilter, methodFilter, changeFilters }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  React.useEffect(() => {
    if (certificationFilter.length === 0 && methodFilter.length === 0) {
      setSelected([]);
    }
  }, [certificationFilter, methodFilter]);

  const onClear = () => {
    changeFilters({
      [SIGNATURE_CERTIFICATION_FILTER]: [],
      [SIGNATURE_METHOD_FILTER]: [],
    });
    setSelected([]);
  };

  const mapSelectedToFilters = () => {
    const filters = {
      methods: [],
      certifications: [],
    };

    const update = selected.reduce((filter, option) => {
      const { method, certification } = option;
      const { methods, certifications } = filter;

      return {
        methods: [...methods, method],
        certifications:
          certification !== null ? [...certifications, certification] : certifications,
      };
    }, filters);

    return {
      [SIGNATURE_CERTIFICATION_FILTER]: uniq(update.certifications),
      [SIGNATURE_METHOD_FILTER]: uniq(update.methods),
    };
  };

  const formatSelected = list => {
    const [selectedItems] = list;
    return `Assinatura (${selectedItems.length})`;
  };

  return (
    <FilterTag
      show={open}
      onClear={onClear}
      onChange={setOpen}
      label="Assinatura"
      selected={[selected.length && selected]}
      formatSelected={formatSelected}
    >
      <FlexColumn>
        {options.map(option => (
          <SelectTag
            key={option.label}
            selected={selected.includes(option)}
            onChange={() => {
              if (selected.includes(option)) {
                setSelected(selected.filter(op => op !== option));
              } else {
                setSelected([...selected, option]);
              }
            }}
          >
            {option.label}
          </SelectTag>
        ))}
      </FlexColumn>
      <FilterFooter
        disabled={selected.length === 0}
        onClear={onClear}
        onFilter={() => {
          const filtersUpdate = mapSelectedToFilters();

          setOpen(false);
          changeFilters(filtersUpdate);
        }}
      />
    </FilterTag>
  );
};

SignatureFilter.propTypes = {
  methodFilter: PropTypes.arrayOf(PropTypes.number),
  certificationFilter: PropTypes.arrayOf(PropTypes.number),
  changeFilters: PropTypes.func,
};

SignatureFilter.defaultProps = {
  methodFilter: [],
  certificationFilter: [],
  changeFilters: () => null,
};

const mapStateToProps = ({ adminVendors: { filters } }) => ({
  methodFilter: filters[SIGNATURE_METHOD_FILTER],
  certificationFilter: filters[SIGNATURE_CERTIFICATION_FILTER],
});

const mapDispatchToProps = {
  changeFilters: updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignatureFilter);
