import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { DropDown, DropDownOption as Option, getScreenSize } from 'liber-components';
import {
  Column,
  SearchAndSort,
  SearchField,
  SortIcon,
  MonthSeparator,
  LoadingState,
  EmptyState,
  EmptyText,
} from './InvestorStatement-style';
import StatementEntry from '../StatementEntry';
import Fade from './Fade';

export class SortableStatementRaw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: 'desc',
      screenSize: getScreenSize(window),
      search: '',
      loading: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      loading: state.loading || props.loading,
    };
  }

  componentDidMount() {
    this.clearLoading();
    window.addEventListener('resize', this.resize);
  }

  componentDidUpdate() {
    this.clearLoading();
  }

  componentWillUnmount() {
    clearTimeout(this.searchTimeout);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  handleOrderBy = orderBy => {
    this.setState({ orderBy });
  };

  handleSearchChange = event => {
    this.setState({ search: event.target.value, loading: true });
    this.clearLoading();
  };

  clearLoading = () => {
    clearTimeout(this.searchTimeout);
    if (this.state.loading) {
      this.searchTimeout = setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
    }
  };

  renderMonth = (month, orderBy, filter, search) => (
    <div key={_.uniqueId(`${month.month}-${orderBy}`)} style={{ marginBottom: '16px' }}>
      {orderBy === 'desc' ? (
        <>
          <MonthSeparator>
            {month.month}, {month.year}
          </MonthSeparator>
          <StatementEntry
            type="closing-balance"
            entry={{ date: month.to, amount: month.closing_balance }}
            position="start"
          />
          {this.renderDays(month.days, month.month, month.to, orderBy, filter, search)}
          <StatementEntry
            type="opening-balance"
            entry={{ date: month.from, amount: month.opening_balance }}
            position="end"
          />
        </>
      ) : (
        <>
          <MonthSeparator>
            {month.month}, {month.year}
          </MonthSeparator>
          <StatementEntry
            type="opening-balance"
            entry={{ date: month.from, amount: month.opening_balance }}
            position="start"
          />
          {this.renderDays(month.days, month.month, month.from, orderBy, filter, search)}
          <StatementEntry
            type="closing-balance"
            entry={{ date: month.to, amount: month.closing_balance }}
            position="end"
          />
        </>
      )}
    </div>
  );

  renderDays = (days, month, balanceDay, orderBy, filter, search) => {
    let dayList = Object.keys(days).sort();
    if (orderBy === 'desc') dayList = dayList.reverse();
    return dayList.map(day =>
      this.renderDay(day, balanceDay, days, month, orderBy, filter, search),
    );
  };

  renderDay = (day, balanceDay, days, month, orderBy, filter, search) => {
    const mappedEntries = days[day].entries
      .map((entry, index) =>
        this.renderEntry(entry, day, balanceDay, index, orderBy, filter, search),
      )
      .filter(entry => Boolean(entry));
    if (orderBy === 'desc') {
      return (
        <div key={_.uniqueId(`${day}-${month}-${orderBy}-`)}>
          {mappedEntries.length > 0 ? (
            <StatementEntry
              type="day-balance"
              entry={{
                showDate: day !== moment(balanceDay).format('DD/MM'),
                date: day,
                amount: days[day].balance,
              }}
            />
          ) : null}
          {mappedEntries}
        </div>
      );
    }
    return (
      <div key={_.uniqueId(`${day}-${month}-${orderBy}-`)}>
        {mappedEntries}
        {mappedEntries.length > 0 ? (
          <StatementEntry
            type="day-balance"
            entry={{ showDate: false, amount: days[day].balance }}
          />
        ) : null}
      </div>
    );
  };

  filterBySearchEntry = (entry, search) => {
    if (search.length !== 0) {
      const amount = currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' });
      if (entry.invoices) {
        const drawees = Object.keys(entry.invoices);
        return (
          amount.includes(search) ||
          entry.amount.toString().includes(search) ||
          drawees.some(drawee => {
            const invoices = entry.invoices[drawee];
            return invoices.some(invoice => invoice.number.includes(search));
          })
        );
      }
      return amount.includes(search);
    }
    return true;
  };

  renderEntry = (entry, day, balanceDay, index, orderBy, filter, search) => {
    const { _name: name } = entry;
    const showDate =
      index === 0 && orderBy !== 'desc' && day !== moment(balanceDay).format('DD/MM');
    if (filter.length !== 0 && !filter.includes(name)) {
      // eslint-disable-line
      return null;
    }
    if (this.filterBySearchEntry(entry, search)) {
      return (
        <StatementEntry
          key={_.uniqueId(`${entry.date}-${entry.type}-${entry.amount}`)}
          type={name} // eslint-disable-line
          entry={{ ...entry, date: day, showDate }}
        />
      );
    }
    return null;
  };

  renderStatement = (statement, filter) => {
    const { orderBy, search } = this.state;
    let months;
    if (orderBy === 'desc') {
      months = [...statement]
        .reverse()
        .map(month => this.renderMonth(month, orderBy, filter, search));
    } else {
      months = [...statement].map(month => this.renderMonth(month, orderBy, filter, search));
    }
    return months;
  };

  render() {
    const { statement, filter } = this.props;
    const { screenSize, orderBy, loading } = this.state;
    const renderedStatement = this.renderStatement(statement, filter);
    const empty = renderedStatement.every(month => !month) || renderedStatement.length === 0;
    return (
      <>
        <SearchAndSort>
          <SearchField onChange={this.handleSearchChange} />
          {screenSize === 'large' || screenSize === 'extraLarge' ? (
            <DropDown value={orderBy} onSelect={this.handleOrderBy}>
              <Option value="asc">
                <SortIcon />
                Mais antigos primeiro
              </Option>
              <Option value="desc">
                <SortIcon change />
                Mais recentes primeiro
              </Option>
            </DropDown>
          ) : null}
        </SearchAndSort>
        <Fade show={!loading && empty}>
          <Column>
            <EmptyState>
              <EmptyText>Nenhuma movimentação encontrada no período selecionado.</EmptyText>
            </EmptyState>
          </Column>
        </Fade>
        <Fade show={!loading && !empty}>
          <Column>{renderedStatement}</Column>
        </Fade>
        <Fade show={loading}>
          <Column>
            <LoadingState />
          </Column>
        </Fade>
      </>
    );
  }
}

SortableStatementRaw.propTypes = {
  statement: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};
SortableStatementRaw.defaultProps = {
  filter: [],
  loading: false,
};

// export default SortableStatement;

const reducer = 'statement';

const parentReducer = 'investorStatement';

const mapStateToProps = state => ({ ...state[parentReducer][reducer] });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SortableStatementRaw);
