import React, { useContext } from 'react';
import TextFieldFilter from '../../../Filters/TextFieldFilter';
import { NAME_OR_TRADE_NAME_CONT_FILTER, CNPJ_CONT_FILTER } from '../../constants';
import { FundersDrawerContext } from '../../context';
import * as S from './styles';

const FundersTableFilters = () => {
  const { filters, handleChangeFilters, handleClearAllFilters } = useContext(FundersDrawerContext);

  const hasFilters = filtersObject =>
    Object.keys(filtersObject).reduce((flag, key) => flag || Boolean(filters[key]), false);

  return (
    <>
      <S.FilterText>Filtrar por:</S.FilterText>
      <TextFieldFilter
        label="Empresa"
        selectedItems={filters[NAME_OR_TRADE_NAME_CONT_FILTER]}
        onFilter={text => handleChangeFilters(NAME_OR_TRADE_NAME_CONT_FILTER, text)}
        onClear={() => handleChangeFilters(NAME_OR_TRADE_NAME_CONT_FILTER, null)}
      />
      <TextFieldFilter
        label="CNPJ"
        selectedItems={filters[CNPJ_CONT_FILTER]}
        onFilter={text => handleChangeFilters(CNPJ_CONT_FILTER, (text || '').replace(/\D/g, ''))}
        onClear={() => handleChangeFilters(CNPJ_CONT_FILTER, null)}
      />
      {hasFilters(filters) && (
        <S.CleanFiltersButton onClick={handleClearAllFilters}>Limpar Filtros</S.CleanFiltersButton>
      )}
    </>
  );
};

export default FundersTableFilters;
