import React, { createContext } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

export const BatchBlockUnblockModalContext = createContext({});

const BatchBlockUnblockModalProvider = ({
  children,
  token,
  updateShowBatchBlockUnblockModal,
  submitBatchBlockUnblock,
  handleSelectedCnpjs,
  exampleSheetUrl,
  handleIsBatchBlockUnblock,
}) => {
  const handleLeaveModal = () => updateShowBatchBlockUnblockModal(false);
  const roomId = uuid();
  return (
    <BatchBlockUnblockModalContext.Provider
      value={{
        token,
        handleLeaveModal,
        exampleSheetUrl,
        submitBatchBlockUnblock,
        handleSelectedCnpjs,
        roomId,
        handleIsBatchBlockUnblock,
      }}
    >
      {children}
    </BatchBlockUnblockModalContext.Provider>
  );
};

BatchBlockUnblockModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
  updateShowBatchBlockUnblockModal: PropTypes.func.isRequired,
  submitBatchBlockUnblock: PropTypes.func.isRequired,
  handleSelectedCnpjs: PropTypes.func.isRequired,
  exampleSheetUrl: PropTypes.string.isRequired,
  handleIsBatchBlockUnblock: PropTypes.func.isRequired,
};

export default BatchBlockUnblockModalProvider;
