import styled from 'styled-components';
import { PrimaryButton, handleThemeFromObject as getTheme } from 'liber-components';
import { TEXT_COLOR } from '../CommonStyles';

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  width: 240px;
  text-transform: uppercase;
  padding: 0 16px 0 16px;
`;

export const Container = styled.div`
  width: 100%;

  * {
    font-family: Roboto;
  }
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const OutlinedButton = styled(PrimaryButton).attrs(() => ({
  outlined: true,
  version: 2,
  size: 'large',
}))`
  width: 190px;
  margin: 0 12px 0 auto;
  text-transform: uppercase;
  padding: 0 16px 0 16px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: ${TEXT_COLOR};
`;
