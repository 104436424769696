import React, { useContext, useState } from 'react';
import LoadingButton from '../../../../../../common-components/Buttons/LoadingButton';
import { handleError, handleNavigate } from '../../../../../../vendor/Utils';
import { confirmRates } from '../../../../api';
import { formatDate, getPeriod } from '../../../../utils';
import { ConfirmationModalContext } from '../../context';

import Table from '../Table';
import * as S from './styles';

const ModalBody = () => {
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const { id, token, onLeaved, startDate, endDate } = useContext(ConfirmationModalContext);

  const onConfirm = () => {
    setLoadingConfirmation(true);
    confirmRates(id, token)
      .then(() => handleNavigate('/investidor/painel_taxas'))
      .catch(() => {
        handleError('Ocorreu um erro ao confirmar as taxas. Tente novamente mais tarde.');
        setLoadingConfirmation(false);
      });
  };

  return (
    <S.Content>
      <S.Title>Confirmar envio de taxa</S.Title>
      <S.TextInfo>Confirmar envio de taxa para os seguintes grupos?</S.TextInfo>
      <S.Title>Grupos</S.Title>
      <Table />
      {endDate && (
        <S.IntervalInfo>
          <S.Title>Período de validade das taxas</S.Title>
          <S.TextInfo>
            {`${formatDate(startDate)} - ${formatDate(endDate)}`} ({getPeriod(startDate, endDate)}{' '}
            dias)
          </S.TextInfo>
        </S.IntervalInfo>
      )}
      <S.ButtonsRow>
        <S.Button onClick={onLeaved}>CANCELAR</S.Button>
        <LoadingButton
          ButtonComponent={S.Button}
          spinnerColor="#009dff"
          loading={loadingConfirmation}
          onClick={onConfirm}
        >
          CONFIRMAR
        </LoadingButton>
      </S.ButtonsRow>
    </S.Content>
  );
};

export default ModalBody;
