import _ from 'lodash';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';

import errorMsg from './messages';
import { nextStep, setCompleted } from './progressBar';
// eslint-disable-next-line import/no-cycle
import { formatErrorFromBackend } from './wizard';

export const agencyValidate = (bankCode, agency) => {
  switch (bankCode) {
    case '755':
    case '756':
      return agency.length === 4;
    default:
      return agency.length <= 4;
  }
};

export const accountLength = bankCode => {
  switch (bankCode) {
    case '001':
    case '755':
      return 8;
    case '756':
      return 9;
    case '237':
      return 7;
    case '341':
      return 5;
    default:
      return 13;
  }
};

export const getSavingsComplementLabel = bankCode => {
  switch (bankCode) {
    case '001':
      return 'Variação';
    case '104':
      return 'Operação';
    case '341':
      return 'Complemento';
    default:
      return 'Número da poupança';
  }
};

export const changeKind = (event, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/KIND',
  payload: event.target.value,
  index,
});

export const changeBankCode = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/BANK_CODE',
  payload: value,
  index,
});

export const changeAgency = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/AGENCY',
  payload: value,
  index,
});

export const changeAgencyDigit = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/AGENCY_DIGIT',
  payload: value,
  index,
});

export const changeAccount = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/ACCOUNT',
  payload: value,
  index,
});

export const changeAccountDigit = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/ACCOUNT_DIGIT',
  payload: value,
  index,
});

export const changeConjunct = (event, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/CONJUNCT',
  payload: event.target.value,
  index,
});

export const changeSavingsComplement = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SAVINGS_COMPLEMENT',
  payload: value,
  index,
});

const formatData = (data = {}) => ({
  ...data,
  kind: data.kind ? data.kind.toLowerCase() : data.kind,
  agency: data.agency ? changeAgency(data.agency).payload : data.agency,
  agency_digit: data.agency_digit
    ? changeAgencyDigit(data.agency_digit).payload
    : data.agency_digit,
  account: data.account ? changeAccount(data.account).payload : data.account,
  account_digit: data.account_digit
    ? changeAccountDigit(data.account_digit).payload
    : data.account_digit,
});

export const setData = data => {
  const formatted = data
    ? _.sortBy(
        Object.keys(data).reduce(
          (previous, current) => ({
            ...previous,
            [current]: { ...formatData(data[current]), is_patch: Boolean(data) },
          }),
          {},
        ),
        'default',
      ).reverse()
    : undefined;

  return {
    type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SET_DATA',
    payload: formatted,
  };
};

export const handleSuccess = (response = { data: { data: {} } }, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SUCCESSFULL_SUBMIT',
  payload: response.data.data.id,
  index,
});

export const handleFailure = (payload, index) => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/FAILED_SUBMIT',
  payload,
  index,
});

export const addAccount = () => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/ADD_ACCOUNT',
});

export const removeAccountAction = index => ({
  type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/REMOVE_ACCOUNT',
  index,
});

export const removeAccount = (index, id) => dispatch => {
  if (id !== -1) {
    const csrfToken = ReactOnRails.authenticityToken();
    return axios({
      method: 'DELETE',
      url: `/investidor/conta_bancarias/${id}.json`,
      data: {
        id,
        authenticity_token: csrfToken,
      },
      responseType: 'json',
    })
      .then(() => dispatch(removeAccountAction(index)))
      .catch(() => dispatch(handleFailure({ server: 'Erro ao remover no servidor' }, index)));
  }
  return dispatch(removeAccountAction(index));
};

export const sendToBackend =
  (dados = {}, index, last) =>
  dispatch => {
    const { id, isPatch } = dados;
    const data = {
      conta_bancaria: {
        id: id !== -1 ? id : undefined,
        default: dados.default,
        kind: dados.kind,
        bank_code: dados.bankCode,
        agency: dados.agency,
        agency_digit: dados.agencyDigit,
        account: dados.account,
        account_digit: dados.accountDigit,
        conjunct: dados.conjunct,
        savings_complement: dados.savingsComplement,
      },
    };
    const csrfToken = ReactOnRails.authenticityToken();
    if (isPatch) {
      return axios({
        method: 'PATCH',
        url: `/investidor/conta_bancarias/${id}.json`,
        data: {
          ...data,
          authenticity_token: csrfToken,
        },
        responseType: 'json',
      })
        .then(response => {
          dispatch(handleSuccess(response, index));
          if (last) {
            dispatch(nextStep());
            dispatch(setCompleted(4));
          }
        })
        .catch(error => {
          const message = errors.http[{ ...error.response }.status || 500];
          toast(message, 'error', 8000);
          dispatch(handleFailure(formatErrorFromBackend(error), index));
        });
    }
    return axios({
      method: 'POST',
      url: '/investidor/conta_bancarias.json',
      data: {
        ...data,
        authenticity_token: csrfToken,
      },
      responseType: 'json',
    })
      .then(response => {
        dispatch(handleSuccess(response, index));
        if (last) {
          dispatch(nextStep());
          dispatch(setCompleted(4));
        }
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch(handleFailure(formatErrorFromBackend(error), index));
      });
  };

export const validateForm = dados => {
  const { kind, bankCode, agency, account, accountDigit, conjunct, savingsComplement } = dados;
  const payload = {};
  if (kind.length === 0) {
    payload.kind = errorMsg.empty;
  }
  if (bankCode.length === 0) {
    payload.bank_code = errorMsg.empty;
  }
  if (!agencyValidate(bankCode, agency)) {
    payload.agency = errorMsg.invalidFormat;
  }
  if (agency.length === 0) {
    payload.agency = errorMsg.empty;
  }
  if (account.length > accountLength(bankCode) || account.length < 4) {
    payload.account = errorMsg.invalidFormat;
  }
  if (account.length === 0) {
    payload.account = errorMsg.empty;
  }
  if (accountDigit.length === 0) {
    payload.account_digit = errorMsg.empty;
  }
  if (conjunct.length === 0) {
    payload.conjunct = errorMsg.empty;
  }
  if (kind === 'poupanca') {
    if (['001', '104', '341'].includes(bankCode) && savingsComplement.length === 0) {
      payload.savings_complement = errorMsg.empty;
    }
  }

  return payload;
};

export const submitForm = dadosBancarios => dispatch => {
  Object.keys(dadosBancarios).forEach(index => {
    const payload = validateForm(dadosBancarios[index]);

    if (Object.keys(payload).length === 0) {
      dispatch(
        sendToBackend(
          dadosBancarios[index],
          index,
          Number(index) === Object.keys(dadosBancarios).length - 1,
        ),
      );
    } else {
      toast(errorMsg.pendingForms, 'error', 8000);
      dispatch(handleFailure(payload, index));
    }
  });

  return dispatch({
    type: 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SUBMIT_FORMS',
  });
};
