import { connect } from 'react-redux';
import ConfirmationToken from './ConfirmationToken';
import { changeToken, submitForm } from '../../actions/confirmationToken';

const reducer = 'confirmationToken';
const mapStateToProps = state => ({ ...state[reducer] });

const mapDispatchToProps = { changeToken, submitForm };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationToken);
