import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Select, MenuGroup, MenuItem, /* Button, Icon, */ RangeDatePicker } from 'liber-salti';
import { Context } from '../../../../context';
import {
  CARD_FILTER_OPTIONS,
  END_DATE_FILTER,
  SELECT_INTERVAL_VALUE_MAPPER,
  START_DATE_FILTER,
  VENDOR_REPORT_COOKIE_NAME,
} from '../../../../constants';
import { getDateIntervalByDate, getDateIntervalByDays, getFilterMinDate } from '../../../../utils';
import { getCookie } from '../../../../../../vendor/Utils';

const RangePicker = () => {
  const [selectValue, setSelectValue] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [isShowingRangePicker /* setIsShowingRangePicker */] = useState(false);

  const { interval, activity } = CARD_FILTER_OPTIONS;

  const { filters, setFilters, lastLoginDate } = useContext(Context);

  const getLastReportDate = () => getCookie(VENDOR_REPORT_COOKIE_NAME);

  const ACTIVITY_VALUES_MAPPER = {
    last_login: lastLoginDate,
    last_report: getLastReportDate(),
  };

  const handleSelectInterval = selectedInterval => {
    setSelectValue(SELECT_INTERVAL_VALUE_MAPPER[selectedInterval]);
    const dateRange = getDateIntervalByDays(selectedInterval);
    setSelectedDateRange(dateRange);
  };

  const handleSelectActivity = selectedActivity => {
    setSelectValue(SELECT_INTERVAL_VALUE_MAPPER[selectedActivity]);
    const date = ACTIVITY_VALUES_MAPPER[selectedActivity];
    const dateRange = getDateIntervalByDate(date);
    setSelectedDateRange(dateRange);
  };

  useEffect(() => {
    const { startDate, endDate } = selectedDateRange;
    setFilters({
      ...filters,
      [START_DATE_FILTER]: startDate,
      [END_DATE_FILTER]: endDate,
    });
  }, [selectedDateRange]);

  return (
    <>
      {isShowingRangePicker ? (
        <RangeDatePicker
          dialogTitle="Intervalo"
          helperText="Defina as datas inicial e final"
          label="Escolher datas"
          onChange={() => {}}
          value={selectedDateRange}
          minDate={getFilterMinDate()}
          maxDate={moment()}
        />
      ) : (
        <Select helperText="Selecione um periodo predefinido" label="Período" value={[selectValue]}>
          <MenuGroup label={interval.label}>
            {interval.options.map(({ value, label }) => (
              <MenuItem key={value} onClick={() => handleSelectInterval(value)} value={value}>
                {label}
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuGroup label={activity.label}>
            {activity.options.map(({ value, label }) => (
              <MenuItem key={value} onClick={() => handleSelectActivity(value)} value={value}>
                {label}
              </MenuItem>
            ))}
          </MenuGroup>
        </Select>
      )}
      {/* <Button */}
      {/*   variant="text" */}
      {/*   onClick={() => setIsShowingRangePicker(current => !current)} */}
      {/*   startIcon={<Icon name="ArrowSwap" />} */}
      {/* > */}
      {/*   {isShowingRangePicker ? 'PERÍODO PREDEFINIDO' : 'DATAS PERSONALIZADAS'} */}
      {/* </Button> */}
    </>
  );
};

export default RangePicker;
