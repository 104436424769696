import React from 'react';
import PropTypes from 'prop-types';
import SupportErrors from './components/SupportErrors';
import ListErrors from './components/ListErrors';
import WhatToDoError from './components/WhatToDoError';
import ErrorNotification from './components/ErrorNotification';

const ErrorHandler = ({ status, error, invoices }) => {
  if (!status) {
    const { display, supportErrors, failedInvoices, type, label } = error;
    return (
      <>
        <ErrorNotification
          label={label}
          type={type}
          failedInvoices={failedInvoices}
          invoices={invoices}
        />
        {display.map(kind => {
          switch (kind) {
            case 'support':
              return <SupportErrors key={kind} errors={supportErrors} />;
            case 'error-list':
              return <ListErrors key={kind} failedInvoices={failedInvoices} />;
            case 'what-to-do':
              return <WhatToDoError key={kind} type={type} />;
            default:
              return null;
          }
        })}
      </>
    );
  }
  return null;
};

ErrorHandler.propTypes = {
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    display: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string,
    flow: PropTypes.string,
    supportErrors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        critical: PropTypes.bool,
      }),
    ),
    failedInvoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    type: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default ErrorHandler;
