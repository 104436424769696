import styled, { css } from 'styled-components';
import Icon from '@mdi/react';

export const Arrow = styled(Icon)`
  transition: 0.5s;
  ${({ active }) =>
    active &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Cell = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: row nowrap;
  cursor: pointer;
`;

export const IconsContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

export const FirstVendor = styled.div`
  font-size: 16px;
  color: #6287a7;
  width: 80%;
`;

export const Badge = styled.div`
  background-color: #6287a7;
  color: white;
  border-radius: 20%;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-right: 5px;
`;
