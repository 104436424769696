import styled from 'styled-components';
import CollapsibleSection from '../../common-components/CollapsibleSection';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledCollapsibleSection = styled(CollapsibleSection)`
  margin-bottom: 40px;
  margin-top: 40px;
`;
