import React, { useEffect, useCallback } from 'react';
import { PrimaryLink } from 'liber-components/components/Buttons';
import * as S from './styles';
import { handleSuccess } from '../../../../../../vendor/Utils';
import Table from '../Table';

export const ListGroups = () => {
  const checkUrlParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const toastMessage = params.get('toastMessage');
    if (toastMessage) {
      handleSuccess('Sucesso!', toastMessage);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    checkUrlParams();
  }, []);

  return (
    <S.Container>
      <S.HeadingContent>
        <S.PageTitle>Grupo econômico</S.PageTitle>
        <PrimaryLink version={2} size="large" href="/admin/grupos-economicos/new">
          CRIAR NOVO GRUPO
        </PrimaryLink>
      </S.HeadingContent>
      <Table />
    </S.Container>
  );
};

export default ListGroups;
