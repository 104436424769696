import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';
import { TopBorder, Header, Content, List, ListContent, ListContainer } from './styles';
import { ActionsRow, OutlinedButton, Container } from '../CommonStyles';

function ErrorModal({ onLeaved, show, failedCnpjs }) {
  const plural = failedCnpjs.length > 1;

  return (
    <Modal show={show} onLeaved={onLeaved}>
      <TopBorder>
        <Container>
          <Header>Erro na criação do grupo</Header>
          <Content>
            {failedCnpjs.length} CNPJ{plural ? 's não foram encontrados' : ' não foi encontrado'} e
            portanto o grupo não foi criado. Por favor, corrija os valores na planilha e tente
            novamente.
          </Content>
          <ListContainer>
            <ListContent>
              <p>Fornecedores com CNPJ incorreto:</p>
              <List>
                {failedCnpjs.map(cnpj => (
                  <li key={cnpj}>{cnpj}</li>
                ))}
              </List>
            </ListContent>
          </ListContainer>
          <ActionsRow>
            <OutlinedButton onClick={onLeaved}>FECHAR</OutlinedButton>
          </ActionsRow>
        </Container>
      </TopBorder>
    </Modal>
  );
}

ErrorModal.propTypes = {
  onLeaved: PropTypes.func,
  show: PropTypes.bool,
  failedCnpjs: PropTypes.arrayOf(PropTypes.string),
};

ErrorModal.defaultProps = {
  onLeaved: () => {},
  show: true,
  failedCnpjs: [],
};

export default ErrorModal;
