import Immutable from 'seamless-immutable';

const initialState = Immutable({
  documento: '',
  documentoError: '',
  documentoType: 'cpf',
  password: '',
  passwordError: '',
  remember: false,
  path: '',
  flashes: [],
  passwordPath: '',
  registrationPath: '',
  fingerprint: '',
  components: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INVESTIDOR_CHANGE_DOCUMENTO':
      return state.merge({
        documento: action.payload,
        documentoError: '',
      });
    case 'INVESTIDOR_CHANGE_DOCUMENTO_AS_CPF':
      return state.merge({
        documento: action.payload,
        documentoType: 'cpf',
        documentoError: '',
      });
    case 'INVESTIDOR_CHANGE_DOCUMENTO_AS_CNPJ':
      return state.merge({
        documento: action.payload,
        documentoType: 'cnpj',
        documentoError: '',
      });
    case 'INVESTIDOR_CHANGE_DOCUMENTO_ERROR':
      return state.merge({
        documento: action.payload.documento,
        documentoError: action.payload.error,
      });
    case 'INVESTIDOR_CHANGE_DOCUMENTO_TYPE':
      return state.merge({ documentoType: action.payload });
    case 'INVESTIDOR_CHANGE_PASSWORD':
      return state.merge({ password: action.payload, passwordError: '' });
    case 'INVESTIDOR_CHANGE_PASSWORD_ERROR':
      return state.merge({
        password: action.payload.password,
        passwordError: action.payload.error,
      });
    case 'INVESTIDOR_SUCESSFULL_LOGIN':
      return state;
    case 'INVESTIDOR_FAILED_LOGIN':
      return state.merge({
        documentoError: action.payload.documentoError,
        passwordError: action.payload.passwordError,
      });
    case 'INVESTIDOR_TOOGLE_REMEMBER':
      return state.merge({ remember: !state.remember });
    case 'INVESTIDOR_SET_FINGERPRINT':
      return state.merge({
        fingerprint: action.payload.fingerprint,
        components: JSON.stringify(action.payload.components),
      });
    default:
      return state;
  }
};
