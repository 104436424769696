import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { LiberV4 } from 'liber-components';
import CreateOrganizationsGroup from './components/CreateOrganizationsGroup';

const OperadorTransferList = ({ organizations, currentOrganizations }) => (
  <ThemeProvider theme={LiberV4}>
    <CreateOrganizationsGroup
      organizations={organizations}
      currentOrganizations={currentOrganizations}
    />
  </ThemeProvider>
);

OperadorTransferList.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.any),
  currentOrganizations: PropTypes.arrayOf(PropTypes.any),
};

OperadorTransferList.defaultProps = {
  organizations: [],
  currentOrganizations: [],
};
export default OperadorTransferList;
