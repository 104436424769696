import Immutable from 'seamless-immutable';

const initialState = Immutable({
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  remember: false,
  path: '',
  flashes: [],
  passwordPath: '',
  logoUrl: '',
  registrationPath: '',
  fingerprint: '',
  components: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FORNECEDOR_CHANGE_EMAIL':
      return state.merge({ email: action.payload, emailError: '' });
    case 'FORNECEDOR_CHANGE_EMAIL_ERROR':
      return state.merge({ email: action.payload.email, emailError: action.payload.error });
    case 'FORNECEDOR_CHANGE_PASSWORD':
      return state.merge({ password: action.payload, passwordError: '' });
    case 'FORNECEDOR_CHANGE_PASSWORD_ERROR':
      return state.merge({
        password: action.payload.password,
        passwordError: action.payload.error,
      });
    case 'FORNECEDOR_SUCESSFULL_LOGIN':
      return state;
    case 'FORNECEDOR_FAILED_LOGIN':
      return state.merge({
        emailError: action.payload.emailError,
        passwordError: action.payload.passwordError,
      });
    case 'FORNECEDOR_TOOGLE_REMEMBER':
      return state.merge({ remember: !state.remember });
    case 'FORNECEDOR_SET_FINGERPRINT':
      return state.merge({
        fingerprint: action.payload.fingerprint,
        components: JSON.stringify(action.payload.components),
      });
    default:
      return state;
  }
};
