import { validateEmail } from '../../../vendor/validation';
import { errorMsg } from '../messages';

export function changeEmail(value) {
  if (value.length === 0) {
    return {
      type: 'RECOVERY_CHANGE_EMAIL_ERROR',
      payload: {
        email: value,
        error: errorMsg.empty,
      },
    };
  }
  return {
    type: 'RECOVERY_CHANGE_EMAIL',
    payload: value,
  };
}

export function submitRecovery(event, email) {
  event.preventDefault();
  if (email.length !== 0 && validateEmail(email)) {
    event.target.submit();
    return {
      type: 'RECOVERY_SUCESSFULL_SUBMIT',
    };
  }
  let emailError = email.length === 0 ? errorMsg.empty : '';
  emailError = validateEmail(email) || email.length === 0 ? emailError : errorMsg.invalidFormat;
  return {
    type: 'RECOVERY_FAILED_SUBMIT',
    payload: {
      emailError,
    },
  };
}
