import styled from 'styled-components';

export const Header = styled.h2`
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 22px;
  color: #506286;
  margin-bottom: 8px;
`;

export const Flexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  align-items: center;
  margin-bottom: 16px;
`;
