import Immutable from 'seamless-immutable';

export const initialState = Immutable({
  showFixedBox: false,
  funder: '',
  funders: [
    { id: '1', name: 'Saulo Ortez' },
    { id: '2', name: 'Saulo Cortez' },
  ],
  summary: {
    loadingCount: 0,
    count: 0,
    amount: 'R$ 0,00',
    taxed: false,
    negotiations: [],
    months: [],
  },
  skipAcceptance: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SUMMARY_SHOW_FIXED_BOX':
      return state.merge({ showFixedBox: true });
    case 'SUMMARY_HIDE_FIXED_BOX':
      return state.merge({ showFixedBox: false });
    case 'SKIP_OPERATIONS_ACCEPT':
      return state.merge({ skipAcceptance: action.payload });
    case 'PROPOSAL_SELECT_FUNDER_SUCCESS':
      return state.merge({
        funder: action.payload.funder,
        summary:
          action.payload.funder === '' ? initialState.summary : action.payload.summary.summary,
      });
    case 'PROPOSAL_SELECT_NEGOTIATIONS_PENDING':
    case 'PROPOSAL_UNSELECT_NEGOTIATIONS_PENDING':
      return state.merge(
        {
          summary: {
            loadingCount: state.summary.loadingCount + 1,
          },
        },
        { deep: true },
      );
    case 'PROPOSAL_SELECT_NEGOTIATIONS_SUCCESS':
    case 'PROPOSAL_UNSELECT_NEGOTIATIONS_SUCCESS':
      return state.merge(
        {
          summary: { ...action.payload.summary, loadingCount: state.summary.loadingCount - 1 },
        },
        { deep: true },
      );
    default:
      return state;
  }
};
