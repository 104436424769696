import styled, { css } from 'styled-components';
import { handleThemeFromObject, FlatButton } from 'liber-components';

const checkDisabled = ({ disabled }) => {
  if (disabled) {
    return css`
      color: #b6c2c9;
    `;
  }
  return undefined;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  height: fit-content;
  width: 49%;

  @media (max-width: 439px) {
    width: 200px;
    margin-bottom: 16px;
  }

  & * {
    font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
`;

export const Label = styled.div`
  font: Regular 16px/1px Roboto;
  display: flex;
  align-items: center;
  letter-spacing: 0.06px;
  color: #798e9b;
  ${checkDisabled}
`;

export const TooltipContent = styled.div`
  max-width: 300px;
  width: 'auto';
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(FlatButton).attrs(() => ({
  version: 2,
  size: 'small',
  color: 'primary',
}))`
  width: fit-content;
  margin-left: -16px;
`;

export const ValueRow = styled.div`
  display: flex;
  align-items: flex-end;
  color: #405f71;
  ${checkDisabled}
`;

export const Prefix = styled.div`
  font-size: 14px;
  letter-spacing: 0.06px;
  padding-bottom: 4px;
  padding-right: 4px;
`;

export const Value = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
`;
