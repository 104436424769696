import _ from 'lodash';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';

import errorMsg from './messages';
import { nextStep, setCompleted } from './progressBar';
// eslint-disable-next-line import/no-cycle
import { formatErrorFromBackend } from './wizard';

export const changeAddress = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/ADDRESS',
  payload: value,
  index,
});

export const changeNumber = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/NUMBER',
  payload: value.replace(/\D/, ''),
  index,
});

export const changeComplement = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/COMPLEMENT',
  payload: value,
  index,
});

export const changeCity = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/CITY',
  payload: value,
  index,
});

export const changeState = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/STATE',
  payload: value,
  index,
});

export const changeCountry = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/COUNTRY',
  payload: value,
  index,
});

export const changeDistrict = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/DISTRICT',
  payload: value,
  index,
});

export const changeZipCode = (value, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/ZIP_CODE',
  payload: value,
  index,
});

export const toggleFetch = index => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/TOGGLE_FETCH',
  index,
});

export const setIsLoaded = (index, payload) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/SET_IS_LOADED',
  index,
  payload,
});

const formatData = (data = {}) => ({
  ...data,
  kind: data.kind ? data.kind.toLowerCase() : data.kind,
  country: data.country ? data.country.toUpperCase() : data.country,
  state: data.state ? data.state.toUpperCase() : data.country,
  zip_code: data.zip_code ? changeZipCode(data.zip_code).payload : data.zip_code,
});

export const setData = data => {
  const formatted = data
    ? _.sortBy(
        Object.keys(data).reduce(
          (previous, current) => ({
            ...previous,
            [current]: {
              ...formatData(data[current]),
              is_patch: Boolean(data),
              is_fetching: false,
              is_loaded: true,
            },
          }),
          {},
        ),
        'kind',
      )
    : undefined;

  return {
    type: 'WIZARD_INVESTIDOR/ENDERECOS/SET_DATA',
    payload: formatted,
  };
};

export const handleSuccess = (response = { data: { data: {} } }, index) => {
  if (response.data.errors) {
    return {
      type: 'WIZARD_INVESTIDOR/ENDERECOS/FAILED_SUBMIT',
      payload: response.data.errors,
      index,
    };
  }

  return {
    type: 'WIZARD_INVESTIDOR/ENDERECOS/SUCCESSFULL_SUBMIT',
    payload: response.data.data.id,
    index,
  };
};

export const handleFailure = (payload, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/FAILED_SUBMIT',
  payload,
  index,
});

export const addAddress = () => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/ADD_ADDRESS',
});

export const removeAddressAction = index => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/REMOVE_ADDRESS',
  index,
});

export const removeAddress = (index, id) => dispatch => {
  if (id !== -1) {
    const csrfToken = ReactOnRails.authenticityToken();
    return axios({
      method: 'DELETE',
      url: `/investidor/enderecos/${id}.json`,
      data: {
        id,
        authenticity_token: csrfToken,
      },
      responseType: 'json',
    })
      .then(() => dispatch(removeAddressAction(index)))
      .catch(() => dispatch(handleFailure({ server: 'Erro ao remover' }, index)));
  }
  return dispatch(removeAddressAction(index));
};

export const setDataFromZipCode = (response = { data: {} }, index) => ({
  type: 'WIZARD_INVESTIDOR/ENDERECOS/SET_DATA_FROM_ZIP_CODE',
  payload: { ...response.data, complement: '', country: 'BR' },
  index,
});

export const getAddressFromZipCode = (zipcode, index) => dispatch => {
  dispatch(toggleFetch(index));
  return axios({
    method: 'GET',
    url: '/busca_cep',
    params: { zipcode: zipcode.replace('-', '') },
    responseType: 'json',
  })
    .then(response => {
      dispatch(setDataFromZipCode(response, index));
      dispatch(toggleFetch(index));
    })
    .catch(error => {
      const wrongZip = error.response.status === 422;
      dispatch(
        handleFailure({ zip_code: wrongZip ? errorMsg.wrongZipCode : errorMsg.zipApiError }, index),
      );
      dispatch(setIsLoaded(index, !wrongZip));
      dispatch(toggleFetch(index));
    });
};

export const changeZipCodeListener = (value, index) => dispatch => {
  if (value.length === 9) {
    dispatch(changeZipCode(value, index));
    return dispatch(getAddressFromZipCode(value, index));
  }
  return dispatch(changeZipCode(value, index));
};

export const sendToBackend =
  (endereco = {}, index, last) =>
  dispatch => {
    const { id, isPatch } = endereco;
    const data = {
      endereco: {
        id: id !== -1 ? id : undefined,
        address: endereco.address,
        number: endereco.number,
        complement: endereco.complement,
        city: endereco.city,
        state: endereco.state,
        country: endereco.country,
        district: endereco.district,
        zip_code: endereco.zipCode,
        kind: endereco.kind,
      },
    };
    const csrfToken = ReactOnRails.authenticityToken();
    if (isPatch) {
      return axios({
        method: 'PATCH',
        url: `/investidor/enderecos/${id}.json`,
        data: {
          ...data,
          authenticity_token: csrfToken,
        },
        responseType: 'json',
      })
        .then(response => {
          dispatch(handleSuccess(response, index));
          if (last) {
            dispatch(nextStep());
            dispatch(setCompleted(3));
          }
        })
        .catch(error => {
          const message = errors.http[{ ...error.response }.status || 500];
          toast(message, 'error', 8000);
          dispatch(handleFailure(formatErrorFromBackend(error), index));
        });
    }
    return axios({
      method: 'POST',
      url: '/investidor/enderecos.json',
      data: {
        ...data,
        authenticity_token: csrfToken,
      },
      responseType: 'json',
    })
      .then(response => {
        dispatch(handleSuccess(response, index));
        if (last) {
          dispatch(nextStep());
          dispatch(setCompleted(3));
        }
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch(handleFailure(formatErrorFromBackend(error), index));
      });
  };

const validateForm = endereco => {
  const { address, number, city, state, country, district, zipCode } = endereco;
  const payload = {};
  if (address.length === 0) {
    payload.address = errorMsg.empty;
  }
  if (number.length === 0) {
    payload.number = errorMsg.empty;
  }
  if (city.length === 0) {
    payload.city = errorMsg.empty;
  }
  if (state.length === 0) {
    payload.state = errorMsg.empty;
  }
  if (country.length === 0) {
    payload.country = errorMsg.empty;
  }
  if (district.length === 0) {
    payload.district = errorMsg.empty;
  }
  if (zipCode.length === 0) {
    payload.zip_code = errorMsg.empty;
  }
  return payload;
};

export const submitForm = enderecos => dispatch => {
  Object.keys(enderecos).forEach(index => {
    const payload = validateForm(enderecos[index]);

    if (Object.keys(payload).length === 0) {
      dispatch(
        sendToBackend(enderecos[index], index, Number(index) === Object.keys(enderecos).length - 1),
      );
    } else {
      toast(errorMsg.pendingForms, 'error', 8000);
      dispatch(handleFailure(payload, index));
    }
  });
  return dispatch({
    type: 'WIZARD_INVESTIDOR/ENDERECOS/SUBMIT_FORMS',
  });
};
