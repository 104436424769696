import styled from 'styled-components';
import { handleThemeFromObject as getTheme, LoadingBunny as Bunny } from 'liber-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: center;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  margin-bottom: 32px;
`;

export const Description = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: center;
  color: #6287a7;
  margin-bottom: 64px;
`;

export const LoadingBunny = styled(Bunny)`
  width: 161px;
  height: 128px;
`;

export const ProgressContainer = styled.div`
  width: 480px;
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ProgressText = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #009dff;
  margin-bottom: 42px;
`;

export const Image = styled.img`
  width: 128px;
  height: 128px;
  margin-bottom: 48px;
`;
