import React, { useContext, useMemo } from 'react';
import { TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';

import ScreenTitle from '../../../../common-components/ScreenTitle';
import OperationModeCard from '../../../../common-components/OperationModeCard';
import {
  Container,
  Row,
  Button,
  IconButton,
  ReferenceLabel,
  ReferenceValue,
  EditButton,
  SectionTitle,
} from './styles';
import {
  EDIT_OP_MODE_URL_PARAM,
  EDIT_VENDORS_GROUP_PATH,
  VENDORS_GROUPS_PATH,
  VENDORS_GROUP_HISTORY_PATH,
} from '../../constants';
import { VendorsGroupDetailsContext } from '../../context';
import { handleNavigate } from '../../../../vendor/Utils';
import VendorsTable from '../VendorsTable';
import EditNameDialog from '../EditNameDialog';

export default function VendorsGroupDetails() {
  const {
    groupId,
    name,
    referenceCode,
    isDefault,
    funderTargetingMode,
    funderId,
    isFunder,
    pagination,
    editNameDialog: { open },
    funders,
  } = useContext(VendorsGroupDetailsContext);

  const funder = useMemo(() => funders.find(({ id }) => id === funderId), [funders, funderId]);

  const handleBack = () => {
    if (isFunder) window.history.back();
    else handleNavigate(VENDORS_GROUPS_PATH);
  };

  return (
    <Container>
      <Row>
        <Row $wrap>
          <TooltipBox mount="top" fixed content="Voltar">
            <IconButton data-testid="group-details-back" onClick={handleBack}>
              <Icon path={mdiArrowLeft} />
            </IconButton>
          </TooltipBox>
          <ScreenTitle>Grupo {name}</ScreenTitle>
          {!isDefault && !isFunder && (
            <EditButton onClick={() => open()}>
              <Icon path={mdiPencil} />
              editar
            </EditButton>
          )}
        </Row>
        {!isFunder && (
          <Button
            onClick={() => handleNavigate(VENDORS_GROUP_HISTORY_PATH.replace(':id', groupId))}
          >
            HISTÓRICO
          </Button>
        )}
      </Row>
      <Row $wrap>
        <ReferenceLabel>Código de referência:</ReferenceLabel>
        <ReferenceValue>{referenceCode}</ReferenceValue>
      </Row>
      {!isFunder && Boolean(funders.length) && (
        <>
          <SectionTitle>
            Modo de operação
            <EditButton
              onClick={() => {
                handleNavigate(
                  `${EDIT_VENDORS_GROUP_PATH.replace(
                    ':id',
                    groupId,
                  )}?${EDIT_OP_MODE_URL_PARAM}=true`,
                );
              }}
            >
              <Icon path={mdiPencil} />
              editar
            </EditButton>
          </SectionTitle>
          <OperationModeCard
            funderTargetingMode={funderTargetingMode}
            funder={funder}
            vendorsGroupId={groupId}
          />
        </>
      )}
      <SectionTitle>
        Fornecedores neste grupo ({pagination.count})
        {!isDefault && !isFunder && (
          <EditButton
            onClick={() => handleNavigate(EDIT_VENDORS_GROUP_PATH.replace(':id', groupId))}
          >
            <Icon path={mdiPencil} />
            editar
          </EditButton>
        )}
      </SectionTitle>
      <VendorsTable />
      <EditNameDialog />
    </Container>
  );
}
