import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mdiClose } from '@mdi/js';
import VirtualizedList from '../../../../common-components/VirtualizedList';
import ListItem from '../ListItem';

import {
  Container,
  TextInput,
  HelperText,
  SelectAllRow,
  Separator,
  BoldText,
  Loading,
  Bunny,
  TextInputWrapper,
  RemoveIcon,
  CenterItem,
} from './styles';

import { SelectCheckbox, Text } from '../CommonStyles';

const filterFields = ['cnpj', 'tradeName', 'company'];

const vendorFilter = (vendor, searchString) =>
  Object.keys(vendor).some(k => {
    if (typeof vendor[k] === 'string' && filterFields.includes(k)) {
      return vendor[k]
        .toString()
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '')
        .includes(searchString.toLowerCase().replace(/[^a-z0-9]/g, ''));
    }
    return false;
  });

const TransferList = ({
  loading,
  firstLoading,
  vendors,
  selectedVendorsIds,
  vendorsCount,
  handleSelect,
}) => {
  const [filter, setFilter] = useState('');
  const [selectedAll, setSelectedAll] = useState(false);

  const handleFilter = string => {
    setFilter(string);
  };

  const filteredVendors = useMemo(
    () => vendors.filter(vendor => vendorFilter(vendor, filter)),
    [vendors, filter],
  );

  const filteredVendorsSelectedCount = useMemo(
    () =>
      filteredVendors.reduce(
        (count, vendor) => (selectedVendorsIds.includes(vendor.id) ? count + 1 : count),
        0,
      ),
    [filteredVendors, selectedVendorsIds],
  );

  const onSelect = vendorId => {
    handleSelect([vendorId]);
  };

  const onSelectAll = e => {
    e?.preventDefault();
    if (loading) return setSelectedAll(true);

    return handleSelect(
      filteredVendors.map(({ id }) => id),
      true,
      filteredVendorsSelectedCount === filteredVendors.length,
    );
  };

  const handleClearFilter = () => {
    setFilter('');
  };

  const isLoading = useMemo(() => {
    if (firstLoading) return true;
    return loading && (filter || selectedAll);
  }, [loading, filter, selectedAll, firstLoading]);

  useEffect(() => {
    if (!loading && selectedAll) onSelectAll();
  }, [loading]);

  const isEmpty = useMemo(
    () => !(loading || firstLoading || vendorsCount > 0),
    [loading, firstLoading, vendorsCount],
  );

  return (
    <Container>
      <TextInputWrapper>
        <TextInput
          value={filter}
          onChange={handleFilter}
          label="Buscar fornecedores"
          prefix={filter.length > 0 && <RemoveIcon onClick={handleClearFilter} path={mdiClose} />}
        />
      </TextInputWrapper>
      {isLoading && (
        <Loading full={firstLoading}>
          <Bunny />
        </Loading>
      )}
      <HelperText>Pesquise por Nome ou CNPJ</HelperText>
      <SelectAllRow onClick={onSelectAll}>
        <SelectCheckbox
          checked={
            filteredVendorsSelectedCount === filteredVendors.length &&
            filteredVendorsSelectedCount > 0
          }
        />
        <div>
          <BoldText>Todos os fornecedores</BoldText>
          <Text>
            {selectedVendorsIds.length}/{vendorsCount} Selecionado
            {selectedVendorsIds.length !== 1 && 's'}
          </Text>
        </div>
      </SelectAllRow>
      <Separator />
      {!isEmpty ? (
        <VirtualizedList
          rowRenderer={({ index, key, style }) => {
            const { id, tradeName, cnpj } = filteredVendors[index] || {};
            return (
              <div key={key} style={style}>
                <ListItem
                  selected={selectedVendorsIds.includes(id)}
                  handleSelect={() => onSelect(id)}
                  tradeName={tradeName}
                  cnpj={cnpj}
                />
              </div>
            );
          }}
          rowCount={loading && !firstLoading ? filteredVendors.length + 1 : filteredVendors.length}
        />
      ) : (
        <CenterItem>
          Ainda não há fornecedores selecionados.
          <br />
          Selecione-os na lista ao lado para adicionar.
        </CenterItem>
      )}
    </Container>
  );
};

TransferList.propTypes = {
  loading: PropTypes.bool,
  firstLoading: PropTypes.bool,
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedVendorsIds: PropTypes.arrayOf(PropTypes.number),
  vendorsCount: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

TransferList.defaultProps = {
  loading: false,
  firstLoading: false,
  selectedVendorsIds: [],
};

export default TransferList;
