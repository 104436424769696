import PropTypes from 'prop-types';
import React from 'react';
import { LiberV4, ToastContainer } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import * as S from './styles';
import BuyerOrganizationGroupForm from './components/BuyerOrganizationGroupForm';
import PageHeader from './components/PageHeader';
import { BuyerOrganizationGroupsProvider } from './context';

const CreateBuyerOrganizationGroup = ({ token, editGroupId }) => (
  <ThemeProvider theme={LiberV4}>
    <BuyerOrganizationGroupsProvider token={token} editGroupId={editGroupId}>
      <ToastContainer />
      <S.Container>
        <PageHeader />
        <BuyerOrganizationGroupForm token={token} />
      </S.Container>
    </BuyerOrganizationGroupsProvider>
  </ThemeProvider>
);

CreateBuyerOrganizationGroup.propTypes = {
  token: PropTypes.string.isRequired,
  editGroupId: PropTypes.string,
};

CreateBuyerOrganizationGroup.defaultProps = {
  editGroupId: undefined,
};

export default CreateBuyerOrganizationGroup;
