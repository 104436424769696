/* eslint-disable no-restricted-properties */
import React from 'react';
import ActionsCell from '../ActionsCell';

function truncateToFixed(num, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.trunc(num * multiplier) / multiplier;
}

export const buildColumns = allowRebateIncrement => {
  const columns = [
    {
      name: 'referenceCode',
      label: 'Código de referência',
      width: 20,
    },
    {
      name: 'name',
      label: 'Nome',
      width: 15,
    },
    {
      name: 'vendorsCount',
      label: 'Total de fornecedores',
      width: 25,
    },
    {
      name: 'active',
      label: 'Status',
      width: 20,
      getCellValue: ({ active }) => (active ? 'Ativo' : 'Arquivado'),
    },
  ];

  if (allowRebateIncrement) {
    columns.push({
      name: 'rebateIncrement',
      label: 'Incremento do rebate',
      width: 25,
      getCellValue: ({ rebateIncrement }) => `${truncateToFixed(rebateIncrement * 100, 2)}%`,
    });
  }

  columns.push({
    name: 'actions',
    label: 'Ações',
    width: 20,
    getCellValue: group => (
      <ActionsCell group={group} allowRebateIncrement={allowRebateIncrement} />
    ),
  });

  return columns;
};
