/* eslint-disable react/prop-types */
import React from 'react';
import { formatMoney } from '../../views/withdraw/moneyHandler';

// eslint-disable-next-line no-undef
export const auctioneerBankCode = AUCTIONEER_BANK_CODE;

export const TARGETING_MODE_INTEGRATION = 'integration';
export const TARGETING_MODE_PLATFORM = 'platform';
export const TARGETING_MODES = [
  {
    name: 'integração',
    key: TARGETING_MODE_INTEGRATION,
    helper: 'O sistema priorizará o que for enviado via integração',
  },
  {
    name: 'platforma',
    key: TARGETING_MODE_PLATFORM,
    helper:
      'O sistema direcionará para o investidor indicado na plataforma, ' +
      'caso nenhum investidor seja indicado pela integração',
  },
];

export const fundersColumns = [
  {
    name: 'company',
    label: <>&nbsp;&nbsp;&nbsp;Investidor</>,
    width: 30,
    align: 'left',
    getCellValue: ({ company }) => <>&nbsp;&nbsp;&nbsp;{company}</>,
  },
  {
    name: 'bankCode',
    label: 'Cód.',
    width: 15,
    align: 'left',
  },
  {
    name: 'limit',
    label: <>Limite com o investidor&nbsp;&nbsp;&nbsp;</>,
    width: 30,
    align: 'right',
    getCellValue: ({ limit }) => <>{limit ? formatMoney(limit, true) : '-'}&nbsp;&nbsp;&nbsp;</>,
  },
];
