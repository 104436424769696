import styled, { css } from 'styled-components';
import { SelectField as BaseSelectField } from 'liber-components';

import { Card } from '../PendingAdvanceCard/styles';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const AdvancesContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: auto;
  padding: 3px 0px 16px 3px;

  & > ${Card} {
    margin-right: 20px;
  }
`;

export const FiltersContainer = styled.div`
  border: 1px solid #b6c2c9;
  border-radius: 4px 4px 0 0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const FiltersLabel = styled.span`
  font-family: 'Roboto';
  color: #405f71;
  font-size: 16px;
  font-weight: 400;
  margin: auto 10px auto 0;
`;

export const SelectField = styled(BaseSelectField).attrs(() => ({
  version: 2,
}))`
  & > div > div:first-child > div {
    max-height: 36px;
    border: 1px solid #cdd7df;
    padding: 0 68px 0 12px;
  }
  & input {
    background-color: unset;
    color: #405f71;
  }
  margin-left: auto;
  min-width: unset;
  max-width: 200px;
  cursor: pointer;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;

  ${({ $hideOnMobile }) =>
    $hideOnMobile &&
    css`
      @media (max-width: 991px) {
        display: none;
      }
    `}
`;

export const EmptyState = styled.div`
  width: 100%;
  text-align: center;
  padding: 48px;
  color: #405f71;
  font-size: 16px;
  font-weight: 400;
`;
