import { useMemo } from 'react';

export const BLOCKED_INVOICE_MAPPED_STATUS = 'blocked';
export const UNAVAILABLE_INVOICE_MAPPED_STATUS = 'unavailable';
export const NOT_ELIGIBLE_INVOICE_MAPPED_STATUS = 'not_eligible_for_anticipation';
export const ELIGIBLE_INVOICE_MAPPED_STATUS = 'eligible_for_anticipation';

export const INSERTED_INVOICE_STATUS = 'inserido';
export const CONFIRMED_INVOICE_STATUS = 'confirmado';
export const TRADING_INVOICE_STATUS = 'negociacao';
export const SOLD_INVOICE_STATUS = 'vendido';
export const PENDING_INVOICE_STATUS = 'pendente';
export const EXPIRED_INVOICE_STATUS = 'vencido';

export const EXPIRED_INVOICE_BLOCKED_REASON = 'titulo_expirado';
export const INVALID_NF_INVOICE_BLOCKED_REASON = 'nota_fiscal_invalida';
export const NO_INTERESTED_INVOICE_BLOCKED_REASON = 'titulo_sem_interessados';
export const INVALID_INFO_INVOICE_BLOCKED_REASON = 'informacoes_invalidas';
export const NOT_INFORMED_INVOICE_BLOCKED_REASON = 'outro';
export const CHARGEBACK_INVOICE_BLOCKED_REASON = 'estorno';
export const LOW_VALUE_INVOICE_BLOCKED_REASON = 'valor_baixo';

export const WITHOUT_RATE_INVOICE_NOT_ELIGIBLE_REASON = 'without_agreed_rate';
export const WITHOUT_MIN_VALUE_INVOICE_NOT_ELIGIBLE_REASON = 'without_minimum_value';
export const NOT_PUBLISHED_INVOICE_NOT_ELIGIBLE_REASON = 'not_published';
export const EXPIRED_INVOICE_NOT_ELIGIBLE_REASON = 'expired';
export const NOT_CONCILIATED_INVOICE_NOT_ELIGIBLE_REASON = 'not_conciliated';
export const VENDOR_BLOCKED_BY_FUNDER_NOT_ELIGIBLE_REASON = 'vendor_blocked_by_funder';

export const MAPPED_STATUSES = {
  [BLOCKED_INVOICE_MAPPED_STATUS]: 'Bloqueado',
  [UNAVAILABLE_INVOICE_MAPPED_STATUS]: 'Indisponível',
  [NOT_ELIGIBLE_INVOICE_MAPPED_STATUS]: 'Não elegível',
  [ELIGIBLE_INVOICE_MAPPED_STATUS]: 'Disponível',
};

export const BLOCKED_REASONS = {
  [EXPIRED_INVOICE_BLOCKED_REASON]: 'O título já está vencido.',
  [INVALID_NF_INVOICE_BLOCKED_REASON]: 'Nota Fiscal inválida.',
  [NO_INTERESTED_INVOICE_BLOCKED_REASON]: 'O título não possui investidores interessados.',
  [INVALID_INFO_INVOICE_BLOCKED_REASON]: 'Título com informações inválidas.',
  [NOT_INFORMED_INVOICE_BLOCKED_REASON]: 'Não informado.',
  [CHARGEBACK_INVOICE_BLOCKED_REASON]: 'O título foi estornado.',
  [LOW_VALUE_INVOICE_BLOCKED_REASON]: 'Título com valor abaixo do mínimo permitido.',
};

export const NOT_ELIGIBLE_REASONS = {
  [WITHOUT_RATE_INVOICE_NOT_ELIGIBLE_REASON]: 'Sem taxa definida para o título.',
  [WITHOUT_MIN_VALUE_INVOICE_NOT_ELIGIBLE_REASON]: 'Título com valor abaixo do mínimo permitido.',
  [NOT_PUBLISHED_INVOICE_NOT_ELIGIBLE_REASON]:
    'Os demais títulos deste lote ainda estão sendo disponibilizados, ' +
    'em breve todos ficarão disponíveis para antecipação.',
  [EXPIRED_INVOICE_NOT_ELIGIBLE_REASON]: 'Título com prazo menor que o mínimo permitido.',
  [NOT_CONCILIATED_INVOICE_NOT_ELIGIBLE_REASON]:
    'O título está pendente de conciliação entre o fornecedor e sacado para que a operação aconteça.',
  [VENDOR_BLOCKED_BY_FUNDER_NOT_ELIGIBLE_REASON]: 'O fornecedor está bloqueado pelo investidor.',
};

export const UNAVAILABLE_BY_STATUS_REASONS = {
  [INSERTED_INVOICE_STATUS]:
    'O título foi inserido, aguarde até que fique disponível para antecipação.',
  [CONFIRMED_INVOICE_STATUS]: 'Aguardando validação dos documentos cadastrais do fornecedor.',
  [TRADING_INVOICE_STATUS]: 'O título já está em negociação.',
  [SOLD_INVOICE_STATUS]: 'O título já foi vendido e por isso não pode mais ser antecipado.',
  [PENDING_INVOICE_STATUS]:
    'O título possui alguma pendência, aguarde até que essa pendência seja resolvida.',
  [EXPIRED_INVOICE_STATUS]: 'O título já está vencido.',
};

export function getInvoiceNotAvailableReason(invoice) {
  const { blockReason, notEligibleForAnticipationReason, mappedStatus, status } = invoice || {};

  switch (mappedStatus) {
    case BLOCKED_INVOICE_MAPPED_STATUS:
      return BLOCKED_REASONS[blockReason];
    case UNAVAILABLE_INVOICE_MAPPED_STATUS:
      return UNAVAILABLE_BY_STATUS_REASONS[status];
    case NOT_ELIGIBLE_INVOICE_MAPPED_STATUS:
      return NOT_ELIGIBLE_REASONS[notEligibleForAnticipationReason];
    case ELIGIBLE_INVOICE_MAPPED_STATUS:
    default:
      return '';
  }
}

export default function useInvoiceUnavailableReason(invoice) {
  const unavailableReason = useMemo(() => getInvoiceNotAvailableReason(invoice), [invoice]);

  return { unavailableReason };
}
