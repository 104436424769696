import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import hydrateStore from '../../views/store';
import VendorBorderoShow from './components/VendorBorderoShow';

const VendorBorderoShowComponent = ({
  token,
  fornecedorId,
  operator,
  anticipationId,
  isAdmin,
  isSolicitation,
}) => (
  <>
    <Provider
      store={hydrateStore({
        vendorBorderoShow: {
          token,
          fornecedorId,
          operator,
          anticipationId,
          isAdmin,
          isSolicitation,
        },
        invoiceDialog: { isAdmin },
      })}
    >
      <VendorBorderoShow />
    </Provider>
  </>
);

VendorBorderoShowComponent.propTypes = {
  token: PropTypes.string.isRequired,
  fornecedorId: PropTypes.number.isRequired,
  operator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cpf: PropTypes.string,
  }).isRequired,
  anticipationId: PropTypes.number,
  isSolicitation: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

VendorBorderoShowComponent.defaultProps = {
  isAdmin: false,
  anticipationId: null,
  isSolicitation: false,
};

export default VendorBorderoShowComponent;
