import React, { useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { mdiClose } from '@mdi/js';

import VirtualizedList from '../../../../common-components/VirtualizedList';
import ListItem from '../ListItem';
import { CreateVendorsGroupContext } from '../../context';

import {
  Container,
  TextInput,
  HelperText,
  SelectAllRow,
  Separator,
  BoldText,
  Loading,
  Bunny,
  TextInputWrapper,
  RemoveIcon,
  CenterItem,
  Spinner,
} from './styles';

import { SelectCheckbox, Text } from '../CommonStyles';
import { sortByTradeName } from '../../utils';

const filterFields = ['cnpj', 'tradeName', 'company', 'vendorsGroupName'];

const vendorFilter = (vendor, searchString) =>
  Object.keys(vendor).some(k => {
    if (typeof vendor[k] === 'string' && filterFields.includes(k)) {
      return vendor[k]
        .toString()
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '')
        .includes(searchString.toLowerCase().replace(/[^a-z0-9]/g, ''));
    }
    return false;
  });

const TransferList = ({
  loading,
  firstLoading,
  vendors,
  selectedVendorsIds,
  vendorsCount,
  handleSelect,
  isVendorsGroupList,
}) => {
  const { rebateIncrement, setRebateIncrement, allowRebateIncrement, isEditing } =
    useContext(CreateVendorsGroupContext);

  const [filter, setFilter] = useState('');
  const [selectedAll, setSelectedAll] = useState(false);

  const handleFilter = string => {
    setFilter(string);
  };

  const filteredVendors = useMemo(
    () => vendors.filter(vendor => vendorFilter(vendor, filter)).sort(sortByTradeName),
    [vendors, filter],
  );

  const filteredVendorsSelectedCount = useMemo(
    () =>
      filteredVendors.reduce(
        (count, vendor) => (selectedVendorsIds.includes(vendor.id) ? count + 1 : count),
        0,
      ),
    [filteredVendors, selectedVendorsIds],
  );

  const onSelect = vendorId => {
    handleSelect([vendorId]);
  };

  const onSelectAll = e => {
    e?.preventDefault();
    if (loading) return setSelectedAll(true);

    return handleSelect(
      filteredVendors.map(({ id }) => id),
      true,
      filteredVendorsSelectedCount === filteredVendors.length,
    );
  };

  const handleClearFilter = () => {
    setFilter('');
  };

  const isLoading = useMemo(() => {
    if (firstLoading) return true;
    return loading && (filter || selectedAll);
  }, [loading, filter, selectedAll, firstLoading]);

  useEffect(() => {
    if (!loading && selectedAll) onSelectAll();
  }, [loading]);

  const isEmpty = useMemo(
    () => !(loading || firstLoading || vendorsCount > 0),
    [loading, firstLoading, vendorsCount],
  );

  const handleIncrementChange = inputValue => {
    const regex = /^(\d{1,3}([.,]\d{0,4})?)?$/;

    if (regex.test(inputValue)) {
      setRebateIncrement(inputValue);
    }
  };

  return (
    <Container>
      <TextInputWrapper>
        <TextInput
          value={filter}
          onChange={text => handleFilter(text)}
          label="Buscar Fornecedor"
          prefix={
            filter.length > 0 && <RemoveIcon onClick={() => handleClearFilter()} path={mdiClose} />
          }
        />
      </TextInputWrapper>
      {isLoading && (
        <Loading full={firstLoading}>
          <Bunny />
        </Loading>
      )}
      <HelperText>Pesquise por Nome ou CNPJ</HelperText>
      {!isEditing && allowRebateIncrement && isVendorsGroupList && (
        <>
          <TextInputWrapper>
            <TextInput
              label="Incremento da operação"
              suffixText="% a.m."
              required
              value={rebateIncrement}
              onChange={handleIncrementChange}
            />
          </TextInputWrapper>
          <HelperText>% da taxa que será acrescentada no custo banco</HelperText>
        </>
      )}
      <SelectAllRow onClick={e => onSelectAll(e)}>
        <SelectCheckbox
          checked={
            filteredVendorsSelectedCount === filteredVendors.length &&
            filteredVendorsSelectedCount > 0
          }
        />
        <div>
          <BoldText>{`Selecionar todos os fornecedores${
            isVendorsGroupList ? ' do grupo' : ''
          }`}</BoldText>
          <Text>
            {selectedVendorsIds.length}/{vendorsCount} Selecionado
            {selectedVendorsIds.length !== 1 && 's'}
          </Text>
        </div>
      </SelectAllRow>
      <Separator />
      {!isEmpty ? (
        <VirtualizedList
          rowRenderer={({ index, key, style }) => {
            const { id, tradeName, vendorsGroupName, cnpj } = filteredVendors[index] || {};
            return (
              <div key={key} style={style}>
                {id ? (
                  <ListItem
                    selected={selectedVendorsIds.includes(id)}
                    handleSelect={() => onSelect(id)}
                    tradeName={tradeName}
                    groupName={vendorsGroupName}
                    cnpj={cnpj}
                  />
                ) : (
                  <CenterItem>
                    {filteredVendors.length > 5 && (
                      <>
                        <Spinner />
                        Carregando mais fornecedores...
                      </>
                    )}
                  </CenterItem>
                )}
              </div>
            );
          }}
          rowCount={loading && !firstLoading ? filteredVendors.length + 1 : filteredVendors.length}
        />
      ) : (
        <CenterItem>
          Ainda não há fornecedores no grupo.
          <br />
          Selecione-os na lista ao lado para adicionar.
        </CenterItem>
      )}
    </Container>
  );
};

TransferList.propTypes = {
  loading: PropTypes.bool,
  firstLoading: PropTypes.bool,
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedVendorsIds: PropTypes.arrayOf(PropTypes.number),
  vendorsCount: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isVendorsGroupList: PropTypes.bool,
};

TransferList.defaultProps = {
  loading: false,
  firstLoading: false,
  selectedVendorsIds: [],
  isVendorsGroupList: false,
};

export default TransferList;
