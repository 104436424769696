import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker, Checkbox } from 'liber-components';
import moment from 'moment';
import { TextField, SelectField, CheckboxLabel, DateContainer } from './Filters.styles';
import { updateFilters } from '../../../actions/AdminNegotiationsActions';
import { STATUS_EQ } from '../../../utils';

export const WAIT_INTERVAL = 100;

export const InputField = ({ input, filters, setFilters, datesRangeLimit }) => {
  const { label, type, options, filterKey, filterKeys, position } = input;

  const [inputValue, setInputValue] = useState(type === 'checkbox' ? false : '');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [labelError, setLabelError] = useState('');

  const isNullOrEmpty = str => str === null || str === undefined || str === '';

  const validateDateInterval = (start, end) => {
    if (!start || !end) return true;
    const diff = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss'));
    const days = moment.duration(diff).asDays();
    return days < datesRangeLimit;
  };

  const handleDateFilterChange = () => {
    const dates = inputValue.split(' a ');
    const [startingKey, endingKey] = filterKeys;

    if (!isNullOrEmpty(inputValue)) {
      const [start, end] = dates;

      const newFilters = { ...filters };
      const isUniqueDay = dates.length === 1;
      if (validateDateInterval(start, end)) {
        setFilters({ ...newFilters, [startingKey]: start, [endingKey]: isUniqueDay ? start : end });
        setLabelError('');
      } else {
        setLabelError('O intervalo máximo é de 90 dias');
      }
    } else {
      const newFilters = { ...filters };

      delete newFilters[startingKey];
      delete newFilters[endingKey];

      setFilters({ ...newFilters });
    }
  };

  const handleCheckboxFilterChange = () => {
    if (inputValue) {
      setFilters({ ...filters, [filterKey]: inputValue });
    } else {
      const newFilters = { ...filters };
      delete newFilters[filterKey];
      setFilters({ ...newFilters });
    }
  };

  const handleFilterChange = () => {
    if (type === 'date') {
      handleDateFilterChange();
    } else if (type === 'checkbox') {
      handleCheckboxFilterChange();
    } else if (!isNullOrEmpty(inputValue)) {
      setFilters({ ...filters, [filterKey]: inputValue });
    } else {
      const newFilters = { ...filters };
      if (filterKey === STATUS_EQ) {
        newFilters[filterKey] = null;
      } else {
        delete newFilters[filterKey];
      }
      setFilters({ ...newFilters });
    }
  };

  const updateDateInputValue = () => {
    const [start, end, equal] = filterKeys;
    if (
      !Object.keys(filters).includes(start) &&
      !Object.keys(filters).includes(end) &&
      !Object.keys(filters).includes(equal)
    ) {
      setInputValue('');
    }
  };

  useEffect(() => {
    const filterTimeout = setTimeout(!isFirstLoad ? handleFilterChange : () => null, WAIT_INTERVAL);
    setIsFirstLoad(false);
    return () => clearTimeout(filterTimeout);
  }, [inputValue]);

  useEffect(() => {
    if (type === 'date') {
      updateDateInputValue();
    } else {
      const value = filters[filterKey];
      if (value !== undefined && value !== null) {
        setInputValue(String(filters[filterKey]));
      } else {
        setInputValue('');
      }
    }
  }, [filters]);

  switch (type) {
    case 'date':
      return (
        <DateContainer>
          <DateRangePicker
            label={label}
            openLabel
            value={inputValue}
            onChange={setInputValue}
            closeDelay={0}
            position={position}
            autoPosition={false}
            errorMessage={labelError}
          />
        </DateContainer>
      );
    case 'checkbox':
      return (
        <div>
          <Checkbox checked={inputValue} onChange={event => setInputValue(event.target.checked)}>
            <CheckboxLabel>{label}</CheckboxLabel>
          </Checkbox>
        </div>
      );
    case 'select':
      return (
        <SelectField label={label} value={inputValue} onChange={setInputValue}>
          {options.map(({ name, value }) => (
            <option key={value} value={String(value)}>
              {name}
            </option>
          ))}
        </SelectField>
      );
    case 'text':
    default:
      return <TextField label={label} value={inputValue} onChange={setInputValue} />;
  }
};

InputField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'date', 'select', 'checkbox']),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    filterKey: PropTypes.string,
    filterKeys: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
  }),
  filters: PropTypes.shape({}),
  setFilters: PropTypes.func,
  datesRangeLimit: PropTypes.number,
};

InputField.defaultProps = {
  input: {},
  filters: {},
  setFilters: () => {},
  datesRangeLimit: 0,
};

const mapStateToProps = ({ adminNegotiations: { filters, datesRangeLimit } }) => ({
  filters,
  datesRangeLimit,
});

const mapDispatchToProps = {
  setFilters: updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputField);
