import styled, { keyframes } from 'styled-components';
import { handleThemeFromObject } from 'liber-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  animation: ${spin} 3s linear infinite;
  &,
  & span,
  & span::before {
    display: block;
    height: 32px;
    width: 32px;
  }
  & span,
  & span::before,
  & span::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  & span {
    clip: rect(16px, 32px, 32px, 0);
    animation: ${spin} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  & span::before {
    content: '';
    border: 3px solid transparent;
    border-top: 3px solid #fff;
    border-radius: 50%;
    animation: ${spin} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }

  & span::after {
    content: '';
    border: 3px solid ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
    border-radius: 50%;
  }
`;
