import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const Label = styled.div`
  font-family: Roboto;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #405f71;
  margin-right: 16px;
`;

export const ClearFilters = styled(PrimaryButton).attrs(() => ({ version: '2', outlined: true }))``;
