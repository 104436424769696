export const statuses = {
  PENDING_ACCOUNT: 'pending_account',
  GENERATING_INVOICE_TRANSFER_AGREEMENT: 'generating_invoice_transfer_agreement',
  PENDING_SIGNATURE: 'pending_signature',
  PENDING_PAYMENT: 'pending_payment',
  FINISHED: 'finished',
  CANCELED: 'canceled',
};

export const statusReference = {
  [statuses.PENDING_ACCOUNT]: 'Aguardando seleção de conta',
  [statuses.GENERATING_INVOICE_TRANSFER_AGREEMENT]: 'Gerando termo de cessão',
  [statuses.PENDING_SIGNATURE]: 'Assinaturas pendentes',
  [statuses.PENDING_PAYMENT]: 'Aguardando pagamento',
  [statuses.FINISHED]: 'Concluída',
  [statuses.CANCELED]: 'Erro na antecipação',
  [null]: 'Aguardando proposta',
};

export const statusColors = {
  [statuses.PENDING_ACCOUNT]: { bg: '#7B8FB7', color: '#FAFDFF' },
  [statuses.GENERATING_INVOICE_TRANSFER_AGREEMENT]: { bg: '#CDD4DF', color: '#405F71' },
  [statuses.PENDING_SIGNATURE]: { bg: '#FFA11D', color: '#2B354A' },
  [statuses.PENDING_PAYMENT]: { bg: '#009DFF', color: '#2B354A' },
  [statuses.FINISHED]: { bg: '#59DC93', color: '#2B354A' },
  [statuses.CANCELED]: { bg: '#DC3545', color: '#FAFDFF' },
  [null]: { bg: '#009DFF', color: '#2B354A' },
};

export const actionsConfig = ({ status, timeCut }) => {
  const config = {
    [statuses.PENDING_ACCOUNT]: {
      disabled: {
        signatures: false,
        transferAgreements: true,
        cancel: false,
      },
      caption: timeCut && `Evite o cancelamento desta antecipação assinando antes das ${timeCut}`,
      showUnavailableSignatures: false,
      showCancelReason: false,
    },
    [statuses.GENERATING_INVOICE_TRANSFER_AGREEMENT]: {
      disabled: {
        signatures: true,
        transferAgreements: true,
        cancel: false,
      },
      caption: timeCut && `Evite o cancelamento desta antecipação assinando antes das ${timeCut}`,
      showUnavailableSignatures: true,
      showCancelReason: false,
    },
    [statuses.PENDING_SIGNATURE]: {
      disabled: {
        signatures: false,
        transferAgreements: true,
        cancel: false,
      },
      caption: timeCut && `Evite o cancelamento desta antecipação assinando antes das ${timeCut}`,
      showUnavailableSignatures: false,
      showCancelReason: false,
    },
    [statuses.PENDING_PAYMENT]: {
      disabled: {
        signatures: false,
        transferAgreements: false,
        cancel: true,
      },
      caption: 'Tudo certo! Agora é só aguardar o pagamento',
      showUnavailableSignatures: false,
      showCancelReason: false,
    },
    [statuses.FINISHED]: {
      disabled: {
        signatures: false,
        transferAgreements: false,
        cancel: true,
      },
      caption: 'Antecipação concluída com sucesso',
      showUnavailableSignatures: false,
      showCancelReason: false,
    },
    [statuses.CANCELED]: {
      disabled: {
        signatures: true,
        transferAgreements: true,
        cancel: true,
      },
      caption: 'Algo deu errado com a antecipação',
      showUnavailableSignatures: false,
      showCancelReason: true,
    },
    [null]: {
      disabled: {
        signatures: false,
        transferAgreements: true,
        cancel: false,
      },
      caption: timeCut && `Aguardando proposta`,
      showUnavailableSignatures: false,
      showCancelReason: false,
    },
  };

  return config[status];
};

export const TRACKING_EVENTS_NAMES = {
  seeDetailsPendingAnticipation: 'antecipacoesSolicitacoesEmAndamentoShowDoBordero',
};
