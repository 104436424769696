import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../hooks/useForm';
import { createOrganizationGroup, editOrganizationGroup } from '../services/requests';
import { handleError, handleNavigate } from '../../../../../vendor/Utils';
import { NOT_DEFINED_OPTION_VALUE } from '../interfaces';
import { useGetGroupInfo } from '../hooks/useGetGroupInfo';

export const CreateBuyerOrganizationGroupContext = createContext({});

const BuyerOrganizationGroupsProvider = ({ children, token, editGroupId }) => {
  const isEditing = !!editGroupId;
  const { formData, onChangeForm, setFormData } = useForm();
  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { buyerOrganizationGroup, isLoadingGroup } = useGetGroupInfo({
    token,
    groupId: editGroupId,
  });

  useEffect(() => {
    if (buyerOrganizationGroup) {
      setFormData({
        groupName: buyerOrganizationGroup.groupName,
        kind:
          buyerOrganizationGroup.kind === 'other'
            ? NOT_DEFINED_OPTION_VALUE
            : buyerOrganizationGroup.kind,
        category: buyerOrganizationGroup.buyerGroupCategory.id || NOT_DEFINED_OPTION_VALUE,
        traderName: buyerOrganizationGroup.traderName,
        traderEmail: buyerOrganizationGroup.traderEmail,
        commercialName: buyerOrganizationGroup.commercialName,
        commercialEmail: buyerOrganizationGroup.commercialEmail,
        allowVendorReplication: buyerOrganizationGroup.allowVendorReplication,
      });
    }
  }, [buyerOrganizationGroup]);

  const getIsLoading = useCallback(() => isLoading || isLoadingGroup, [isLoading, isLoadingGroup]);

  const validateForm = () => {
    const requiredFields = { ...formData };
    delete requiredFields.allowVendorReplication;

    const errors = Object.keys(requiredFields).filter(key => !formData[key]);
    if (errors.length) {
      setFormErrors(errors);
    } else {
      setFormErrors([]);
    }
    return !errors.length;
  };

  const formatDataForSubmit = () => ({
    allow_vendor_replication: formData.allowVendorReplication,
    commercial_name: formData.commercialName,
    commercial_email: formData.commercialEmail,
    group_name: formData.groupName,
    kind: formData.kind === NOT_DEFINED_OPTION_VALUE ? undefined : formData.kind,
    trader_name: formData.traderName,
    trader_email: formData.traderEmail,
    buyer_group_category_id:
      formData.category === NOT_DEFINED_OPTION_VALUE ? undefined : formData.category,
  });

  const submitForm = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        if (isEditing) {
          await editOrganizationGroup(token, formatDataForSubmit(), editGroupId);
        } else {
          await createOrganizationGroup(token, formatDataForSubmit());
        }
        handleNavigate(
          `/admin/grupos-economicos?toastMessage=${encodeURI(
            `Grupo econômico ${isEditing ? 'editado' : 'criado'} com sucesso!`,
          )}`,
        );
      } catch (error) {
        handleError('Ocorreu um erro ao criar um novo grupo econômico');
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <CreateBuyerOrganizationGroupContext.Provider
      value={{
        formData,
        onChangeForm,
        submitForm,
        formErrors,
        isLoading: getIsLoading(),
        isEditing,
      }}
    >
      {children}
    </CreateBuyerOrganizationGroupContext.Provider>
  );
};

BuyerOrganizationGroupsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  token: PropTypes.string.isRequired,
  editGroupId: PropTypes.string,
};

BuyerOrganizationGroupsProvider.defaultProps = {
  editGroupId: undefined,
};

const useCreateOrganizationGroup = () => {
  const context = useContext(CreateBuyerOrganizationGroupContext);

  return context;
};

export { BuyerOrganizationGroupsProvider, useCreateOrganizationGroup };
