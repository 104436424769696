import { Header3, Hr } from 'liber-components/components/GenericStyles/GenericStyles';
import React from 'react';
import PropTypes from 'prop-types';
import Confirmation from '../Confirmation';
import ConfirmationToken from '../ConfirmationToken';
import Fornecedor from '../Fornecedor';
import FornecedorSignUp from '../FornecedorSignUp';
import Investidor from '../Investidor';
import InvestidorSignUp from '../InvestidorSignUp';
import Operator from '../Operator';
import { PasswordRecovery, PayeePasswordRecovery } from '../PasswordRecovery';
import PasswordResetForm from '../PasswordResetForm';
import Sacado from '../Sacado';
import { InfoText, LoginCard, LoginContent } from './styles';
import { Image } from '../styles';
import { getSubdomain } from '../../../../vendor/Utils';

const Form = ({ type, config }) => {
  const loginAsBuyer = config !== undefined ? config.sacadoUrl : undefined;

  switch (type) {
    case 'fornecedor':
      return <Fornecedor loginAsBuyer={loginAsBuyer} />;
    case 'sacado':
      return <Sacado />;
    case 'investidor':
      return <Investidor />;
    case 'operator':
      return <Operator />;
    case 'confirmation':
      return (
        <Confirmation
          contextEmail={config.contextEmail}
          locked={config.locked}
          delta={config.delta}
          countdown={config.countdown}
        />
      );
    case 'confirmationToken':
      return <ConfirmationToken confirmationPath={config.confirmationPath} />;
    case 'recovery':
      return <PasswordRecovery />;
    case 'recovery-payee':
      return <PayeePasswordRecovery />;
    case 'reset':
      return <PasswordResetForm />;
    case 'investidor-signup':
      return <InvestidorSignUp />;
    case 'fornecedor-signup':
      return <FornecedorSignUp />;
    case 'waiting':
      return (
        <LoginCard padding="24px" maxWidth="400px">
          <LoginContent width="350px">
            <Image alt={`Logo de ${getSubdomain()}`} src={config.logoUrl} />
            <Hr />
            <Header3>SOLICITAÇÃO DE CADASTRO ENVIADA</Header3>
            <InfoText>Sua Solicitação de Cadastro foi enviada com sucesso.</InfoText>
            <InfoText>
              Assim que seu cadastro for aprovado pela plataforma enviaremos um email para você com
              instruções para finalização do cadastro.
            </InfoText>
          </LoginContent>
        </LoginCard>
      );
    default:
      return <div>Wrong Turn, empty Road!</div>;
  }
};

Form.propTypes = {
  type: PropTypes.string,
  config: PropTypes.shape({
    contextEmail: PropTypes.string,
    locked: PropTypes.bool,
    delta: PropTypes.number,
    countdown: PropTypes.number,
    logoUrl: PropTypes.string,
    confirmationPath: PropTypes.string,
    sacadoUrl: PropTypes.string,
  }),
};

Form.defaultProps = {
  type: '',
  config: {},
};

export default Form;
