import React from 'react';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import styled from 'styled-components';
import { Button, TooltipBox } from 'liber-components';

export const DASHBOARD_PATH = '/investidor/dashboard';

const IconButton = styled(Button).attrs(() => ({ version: 2 }))`
  padding: 4px;
  border-radius: 50%;
  margin: -6px 0;
  & > svg {
    width: 20px !important;
    max-width: 20px !important;
    height: 20px !important;
    max-height: 20px !important;
  }
`;

const PaddingLeft = styled.div`
  padding-left: 16px;
`;

export const COLUMNS = (openModal = () => {}) => [
  {
    name: 'buyer',
    label: <PaddingLeft>Sacado</PaddingLeft>,
    width: 23,
    getCellValue: item => <PaddingLeft>{item?.buyer}</PaddingLeft>,
  },
  {
    name: 'number',
    label: 'Título',
    width: 20,
    align: 'right',
  },
  {
    name: 'amount',
    label: 'Valor Investido (R$)',
    width: 18,
    align: 'right',
  },
  {
    name: 'expectedPaymentAt',
    label: 'Vencimento Original',
    width: 18,
    align: 'right',
  },
  {
    name: 'actions',
    label: 'Ações',
    width: 8,
    align: 'center',
    getCellValue: item => (
      <TooltipBox mount="top" fixed content="Detalhes">
        <IconButton
          data-testid={`${item?.slug}-renegotiations-details`}
          onClick={() => openModal(item?.slug)}
        >
          <Icon path={mdiInformation} />
        </IconButton>
      </TooltipBox>
    ),
  },
];

export const MOCKED_SUMMARY = {
  total: 92,
  accepted: {
    quantity: 79,
    amount: 'R$ 18.123,00',
  },
  paid: {
    quantity: 13,
    amount: 'R$ 3.600,00',
  },
};

export const MOCK_RENEGOTIATIONS = [
  {
    slug: 1,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 2,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 3,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 4,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 5,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 6,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 7,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 8,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 9,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
  {
    slug: 10,
    buyer: 'Copobras S.A.',
    number: '000003665-20-001',
    amount: '1245,00',
    expected_payment_at: '12/06/2020',
  },
];

export const MOCKED_DETAILS = {
  number: '0000036652000188',
  buyer: {
    trade_name: 'Copobras S.A.',
    document: '06194174000119',
  },
  amount: 'R$ 1.200,00',
  expected_payment_at: '12/06/2020',
  renegotiation_details: `Foi acordado que o pagamento se dará da seguinte forma:
    Entrada de R$ 2.000,00 no dia 05/07/2020.
    Mais 4x parcelas de R$ 250,00.`,
};

export const monetizationIconPath =
  // eslint-disable-next-line max-len
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z';
