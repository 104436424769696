import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CompleteTable } from 'liber-components';

import usePageState from '../../../hooks/usePageState';
import { getPaginationInterval, convertToCamelCase, handleError } from '../../../vendor/Utils';
import { COLUMNS } from '../utils';
import { Bunny, Loading, ScrollContainer, LimiterContainer } from './RenegotiationsTable.styles';
import { TokenContext } from '../../../hocs/withTokenProvider';
import InvoiceModal from './InvoiceModal';
import Api from '../api';
import usePagination from '../../../hooks/usePagination';

const PAID_FILTER_KEY = 'q[duplicata_settled_at_not_null]';

const RenegotiationsTable = ({ showPaidOnly }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedItemSlug, setSelectedItemSlug] = useState(null);

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, page =>
    setCurrent(page),
  );

  const handleOpenModal = useCallback(
    slug => {
      setSelectedItemSlug(slug);
      setShowModal(true);
    },
    [setSelectedItemSlug, setShowModal],
  );

  const columns = useMemo(() => COLUMNS(handleOpenModal), [handleOpenModal]);

  const { token } = useContext(TokenContext);

  useEffect(() => {
    setLoading(true);
    Api.fetchTable(token, { per, page: current, [PAID_FILTER_KEY]: showPaidOnly })
      .then(response => {
        const { invoices, pagination: newPagination } = response?.data?.data || {};
        updatePagination({ ...newPagination, current, per });
        setItems(convertToCamelCase(invoices || {}));
        setLoading(false);
      })
      .catch(() => {
        handleError();
        setLoading(false);
      });
  }, [current, per, showPaidOnly]);

  return (
    <>
      <Loading loading={loading ? 1 : 0}>
        {loading ? <Bunny /> : null}
        <ScrollContainer>
          <LimiterContainer>
            <CompleteTable
              columns={columns}
              items={items}
              paginationProps={{
                currentPage,
                pageTotal: pages,
                onChangePage: ({ target: { value } }) => setPageState(value),
                onClickNext: () => setCurrent(next),
                onClickPrev: () => setCurrent(previous),
                onKeyDownPage,
                hasListing: true,
                listingProps: {
                  listing: String(per),
                  total: String(count),
                  showInterval: getPaginationInterval(pagination),
                  onSelectListing,
                  listingLabelSingular: 'título',
                  listingLabelPlural: 'títulos',
                },
              }}
            />
          </LimiterContainer>
        </ScrollContainer>
      </Loading>
      <InvoiceModal
        show={showModal}
        onClose={() => setShowModal(false)}
        invoiceSlug={selectedItemSlug}
      />
    </>
  );
};

RenegotiationsTable.propTypes = {
  showPaidOnly: PropTypes.bool,
};

RenegotiationsTable.defaultProps = {
  showPaidOnly: false,
};

export default RenegotiationsTable;
