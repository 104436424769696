import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'liber-components';

import { Container, ActionsRow, Button, Content, Header, Row } from './styles';
import { closeModal } from '../../actions/VendorReportsActions';

export const WarningModal = ({ show, title, message, handleCloseModal }) => (
  <Modal show={show} onLeaved={handleCloseModal}>
    <Container>
      <Row>
        <Header>{title}</Header>
      </Row>
      <Content>{message}</Content>
      <ActionsRow>
        <Button onClick={handleCloseModal}>FECHAR</Button>
      </ActionsRow>
    </Container>
  </Modal>
);

WarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func,
};

WarningModal.defaultProps = {
  handleCloseModal: () => {},
};

const mapStateToProps = ({
  vendorReports: {
    modal: { show, title, message },
  },
}) => ({
  show,
  title,
  message,
});

const mapDispatchToProps = {
  handleCloseModal: closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);
