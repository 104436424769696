import styled from 'styled-components';
import { FlatButton as RawFlatButton, PrimaryButton } from 'liber-components';

import { TEXT_COLOR } from '../CommonStyles';

export const FlatButton = styled(RawFlatButton).attrs(() => ({ size: 'large' }))``;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))``;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin-bottom: 24px;
  color: ${TEXT_COLOR};
`;

export const Header = styled.div`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  color: ${TEXT_COLOR};
  margin-bottom: 24px;
`;
