import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const FilterText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: #405f71;
  margin-right: 16px;
`;

export const ClearFilters = styled(PrimaryButton).attrs(() => ({ outlined: true, version: 2 }))``;
