export const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const ADMIN_NEGOTIATIONS_URL = `${apiUrl}/api/v1/admin/trades`;
export const ADMIN_OPEN_NEGOTIATIONS_URL = `${apiUrl}/api/v1/admin/trades/open`;
export const ADMIN_CLOSE_NEGOTIATIONS_URL = `${apiUrl}/api/v1/admin/trades/cancel`;
export const ADMIN_DOWNLOAD_DOCUMENTS_URL = `${apiUrl}/api/v1/admin/trades/download_documents`;
export const ADMIN_EXPORT_TERMS_URL = `${apiUrl}/api/v1/admin/trades/invoice_transfer_agreement_report`;
export const ADMIN_NEGOTIATIONS_REPORT_URL = `${apiUrl}/api/v1/admin/reports/trades`;
export const REPORT_ACTION_CABLE_CHANNEL = 'AdminRelatorioChannel';
export const REPORT_ACTION_CABLE_ROOM = 'admin_relatorio_channel_';
export const INVESTOR_ACCOUNT = `${apiUrl}/api/v1/admin/investidores/:investidor_id/conta_bancarias`;
