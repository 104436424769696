import axios from 'axios';
import { DEMONSTRATE_INTEREST_URL } from './constants';

export async function sendInterestContact(token, { cnpj, phone, name, email }) {
  return axios.post(
    DEMONSTRATE_INTEREST_URL,
    {
      phone,
      name,
      cnpj,
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
