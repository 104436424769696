import styled from 'styled-components';

export const FlexColumn = styled.div`
  width: 320px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;
