import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '../../../../common-components/Dialog/Dialog';
import { SectionText, Column, SpaceEvenly, Line } from './SortingDialog.styles';
import SelectTag from '../../../../common-components/SelectTag/SelectTag';
import { updateSorting } from '../../actions/AdminVendorsActions';

const columns = [
  { label: 'Razão Social', value: 'company' },
  { label: 'Nome Fantasia', value: 'tradeName' },
  { label: 'CNPJ', value: 'cnpj' },
];

const columnsValues = ['company', 'tradeName', 'cnpj'];

const directions = [
  { label: 'Ascendente', value: 'asc' },
  { label: 'Descendente', value: 'desc' },
];

export const SortingDialog = ({ open, onClose, sorting, changeSorting }) => {
  const [selected, setSelected] = useState(null);
  const [direction, setDirection] = useState(null);
  const [disabled, setDisabled] = useState(false);

  React.useEffect(() => {
    const [sortedColumn = {}] = sorting.filter(({ columnName }) =>
      columnsValues.includes(columnName),
    );
    const { columnName, direction: selectedDirection } = sortedColumn;

    setSelected(columnName);
    setDirection(selectedDirection);
  }, [sorting]);

  React.useEffect(() => {
    setDisabled(!selected || !direction);
  }, [selected, direction]);

  const onConfirm = () => {
    changeSorting({ columnName: selected, direction });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Ordenar Fornecedor"
      confirmationButtonProps={{ label: 'ORDENAR', onConfirm, disabled }}
    >
      <Column>
        <SectionText>Ordenar por</SectionText>
        <SpaceEvenly>
          {columns.map(({ label, value }) => (
            <SelectTag
              key={value}
              selected={selected === value}
              onChange={() => setSelected(value)}
            >
              {label}
            </SelectTag>
          ))}
        </SpaceEvenly>
        <SectionText>Ordem</SectionText>
        <Line>
          {directions.map(({ label, value }) => (
            <SelectTag
              key={value}
              selected={direction === value}
              onChange={() => setDirection(value)}
            >
              {label}
            </SelectTag>
          ))}
        </Line>
      </Column>
    </Dialog>
  );
};

SortingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sorting: PropTypes.arrayOf(PropTypes.object),
  changeSorting: PropTypes.func,
};

SortingDialog.defaultProps = {
  open: false,
  onClose: () => null,
  sorting: [],
  changeSorting: () => null,
};

const mapStateToProps = ({ adminVendors: { sorting } }) => ({ sorting });

const mapDispatchToProps = {
  changeSorting: updateSorting,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortingDialog);
