import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Header2, InfoDiv } from './styles';
import Accordion from '../Accordion';

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: props.open === 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open === 0) {
      this.setState({ dark: true });
    } else {
      this.setState({ dark: false });
    }
  }

  render = () => (
    <div>
      <Header2>
        Obrigações de Recolhimento de Imposto de Renda <br /> pelo Investidor (Pessoa Física)
      </Header2>

      <Accordion
        label="1. Sou investidor pessoa física. Devo pagar imposto de renda sobre os meus ganhos na plataforma?"
        dark={this.state.dark}
      >
        <InfoDiv>
          <p>
            Agora que seu cadastro foi aprovado, aproveitamos para te deixar por dentro das
            obrigações de recolhimento de imposto de renda em relação aos títulos da Liber. O
            conteúdo deste email também estará disponível no Portal para{' '}
            <strong>visualização e download em PDF</strong> através do link abaixo:
          </p>
        </InfoDiv>
      </Accordion>

      <Accordion
        label="2. Qual é a base do cálculo do IRPF de ganhos de capital no caso da Plataforma?"
        dark={this.state.dark}
      >
        <InfoDiv>
          <p>A base de cálculo é o “Rendimento” do Investidor.</p>
          <p>
            Em termos práticos, no caso de pessoa física, o Rendimento do investidor será sempre{' '}
            <b>(a)</b> o valor recebido pelo investidor na data de liquidação do título, menos
            <b>(b)</b> o valor desembolsado pelo investidor no momento da compra do título.
          </p>
        </InfoDiv>
      </Accordion>

      <Accordion
        label="3. Qual é a alíquota do IRPF sobre ganhos de capital?"
        dark={this.state.dark}
      >
        <InfoDiv>
          <p>
            Conforme o art. 21 da Lei Federal no. 8.981/95, a alíquota aplicável varia conforme o
            valor da base de cálculo:
          </p>
          <table className="irpf-table">
            <thead>
              <tr>
                <th>Alíquota</th>
                <th>Base de Cálculo (Rendimento)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>15%</td>
                <td>Até R$5 milhões</td>
              </tr>
              <tr>
                <td>17.5%</td>
                <td>Entre R$5mi e R$10mi</td>
              </tr>
              <tr>
                <td>20%</td>
                <td>Entre R$10mi e R$30mi</td>
              </tr>
              <tr>
                <td>22.5%</td>
                <td>Mais de R$30mi</td>
              </tr>
            </tbody>
          </table>
          <p>
            Assim, se você investiu R$ 6.000.000,00 em títulos na Plataforma, e recebeu na sua conta
            R$ 6.500.000,00 na liquidação dos títulos, terá que recolher o imposto sobre os R$
            500.000,00 que constituem o seu Rendimento.
          </p>
          <p>
            Como a alíquota é de 15% nesse caso, o valor que terá de ser recolhido nesse caso é de
            15% de R$500.000,00, ou R$75.000,00.
          </p>
        </InfoDiv>
      </Accordion>

      <Accordion label="4. Existe alguma isenção dependendo de valor?" dark={this.state.dark}>
        <InfoDiv>
          <p>
            Sim, segundo a regra do artigo 22, II, da Lei Federal No. 9.250/95, não será devido o
            imposto nos meses em que valor total dos títulos liquidados no mês for igual ou menor
            que R$35.000,00 (note-se que o valor relevante para apurar se é caso de isenção não é o
            Rendimento, como no item anterior, mas sim o valor do título).
          </p>
          <p>
            Para conferir se esse limite foi atingido, devem ser considerados todos os títulos
            adquiridos pelo investidor com data de liquidação no mesmo mês.
          </p>
          <p>Veja-se alguns exemplos práticos:</p>
          <p>
            <b>(a) Título superior a R$ 35 mil:</b> se o investidor adquire um título com valor de
            face de R$ 100.000,00 com liquidação em um único mês, terá de recolher imposto, pois o
            valor de face do título é superior ao limite mensal de R$ 35.000,00;
          </p>
          <p>
            <b>(b) Dois ou mais títulos que somam mais de R$ 35 mil:</b> se um investidor adquiriu
            um título com valor de face de R$ 34.000,00 e outro de R$ 2.000,00, e ambos liquidarem
            no mesmo mês, terá de recolher imposto; e
          </p>
          <p>
            <b>(c) Títulos que somam menos de R$ 35 mil:</b> se todos os títulos adquiridos pelo
            investidor que tiverem liquidação em um mesmo mês somarem, em conjunto, menos do que R$
            35 mil, ocorrerá a isenção de imposto sobre os ganhos obtidos com esses títulos.
          </p>
        </InfoDiv>
      </Accordion>

      <Accordion label="5. Como e quando deve ser recolhido o imposto?" dark={this.state.dark}>
        <InfoDiv>
          <p>
            Se devido, o IRPF sobre ganhos de capital deve ser pago até o último dia do mês
            subsequente ao mês do ganho. Assim, se os títulos de um investidor foram pagos a ele no
            mês de agosto, ele terá de pagar o imposto até o dia 30 de setembro.
          </p>
          <p>
            O valor deve ser recolhido mediante emissão e pagamento de DARF, sob código 4.600, em
            que o “custo de aquisição” é o valor do investimento desembolsado pelo investidor, e o
            “valor de alienação” é o valor recebido pelo investidor quando da liquidação do título.
          </p>
          <p>
            Para maior conveniência dos investidores, a LIBER CAPITAL avisará os investidores nos
            meses em que for devido imposto, emitirá as DARF e encaminhará a eles para que possam
            realizar o pagamento.
          </p>
        </InfoDiv>
      </Accordion>

      <Accordion label="6. Existe limite mínimo para a isenção do DARF?" dark={this.state.dark}>
        <InfoDiv>
          <p>
            A legislação federal determina que não pode ser emitida DARF para pagamento de imposto
            menor do que R$10,00. Se o imposto devido em um dado mês é menor do que R$10,00, ele
            deve acumular até o próximo imposto que for devido, até que os valores somem mais do que
            R$10,00.
          </p>
          <p>
            Na prática, é difícil que isso ocorra se considerada a isenção tratada na pergunta 4,
            pois sempre que o limite da isenção for superado, o imposto devido será provavelmente
            superior a R$10,00.
          </p>
        </InfoDiv>
      </Accordion>

      <Accordion
        label="7. Existe alguma outra obrigação tributária, além da emissão e pagamento das DARF?"
        dark={this.state.dark}
      >
        <InfoDiv>
          <p>
            Sim, é necessário declarar os ganhos na Declaração de Ajuste Anual (DAA), sejam ou não
            isentos
          </p>
          <p>
            A forma recomendada de se declarar os ganhos de capital na Declaração de Ajuste Anual de
            Pessoa Física é por meio do uso do Programa de Apuração dos Ganhos de Capital - GCAP,
            fornecido pela Receita Federal.
          </p>
          <p>
            Após o preenchimento dos ganhos no GCAP, é possível importar os dados para a Declaração
            de Ajuste Anual.
          </p>
          <p>
            A recomendação da Liber Capital é que o GCAP seja preenchido todo mês pelo investidor ou
            por seu contador, haja ou não rendimentos isentos. Isto pois, mesmo que a transmissão
            dos dados para a Receita seja feita somente em abril (por ocasião do preenchimento da
            DAA), o preenchimento mensal do programa ajudará a evitar confusões.
          </p>
          <p>
            Caso não seja possível preencher o GCAP mês-a-mês, ainda assim se recomenda que o
            programa seja usado para gerar os dados que serão inseridos na Declaração de Ajuste
            Anual.
          </p>
          <p>
            <b>Observação:</b> no momento de preenchimento, o contribuinte deverá indicar <b>(a)</b>{' '}
            a data de aquisição do Título pelo investidor como “Data de Aquisição”; <b>(b)</b> a
            data de liquidação do Título como “Data de Alienação”; <b>(c)</b> os Emolumentos pagos à
            Liber como “Custo de Corretagem”; <b>(d)</b> o valor do Adiantamento desembolsado pelo
            investidor como “Custo de Aquisição”, e <b>(e)</b> o Valor do Título como “Valor de
            Alienação”.
          </p>
        </InfoDiv>
      </Accordion>
    </div>
  );
}

Info.propTypes = {
  open: PropTypes.number,
};

Info.defaultProps = {
  open: 0,
};

export default Info;
