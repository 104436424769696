import styled from 'styled-components';

export const InvoiceSimulationNegotiationListContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

export const InvoiceSimulationNegotiationCardList = styled.div`
  justify-content: center;
  display: flex;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const InvoiceSimulationNegotiationListAllNegotiations = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #6287a7;
  text-align: center;
  margin-top: 14px;
`;

export const InvoiceSimulationNegotiationListAllLink = styled.a`
  padding-left: 6px;
  color: #6287a7;
  text-decoration: underline !important;
`;
