import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  #OperatorKyc-react-component {
    height: 70vh;
  }

  .MuiScopedCssBaseline-root {
    background-color: transparent !important;
    height: 100%;
  }

  .MuiToolbar-root {
    display: none !important;
  }

  .MuiDrawer-root {
    display: none !important;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > p {
    margin: 8px 0;
  }

  & > h1 {
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const TextBold = styled.span`
  font-weight: bold;
`;

export const TextUnderline = styled.span`
  text-decoration: underline;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0;

  & > button:last-child {
    margin-left: 16px;
  }
`;
