import styled from 'styled-components';
import { MdCheckAll, MdBroom } from 'liber-components';

export const SimulationSelectControl = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const SimulationLinkSelectAllIcon = styled.div`
  margin-left: 32px;
  margin-bottom: 4px;
`;

export const CheckAllIcon = styled(MdCheckAll)`
  width: 16px;
  height: 16px;
  opacity: ${props => (props.active ? 'unset' : '0.38')};
  fill: #009dff;
`;

export const BroomIcon = styled(MdBroom)`
  width: 16px;
  height: 16px;
  opacity: ${props => (props.active ? 'unset' : '0.38')};
  fill: #009dff;
`;

export const SimulationLinkSelectAll = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #009dff;
  cursor: pointer;
  margin-left: 8px;
  text-decoration: none;
`;

export const SelectedItemsText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 500 : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: #405f71;
`;
