import { connect } from 'react-redux';
import Operator from './Operator';
import {
  changeEmail,
  changePassword,
  changeToken,
  toogleRemember,
  submitLogin,
  setFingerprint,
} from '../../actions/operatorActions';

const mapStateToProps = state => ({
  type: state.operator.type,
  path: state.operator.path,
  flashes: state.operator.flashes,
  passwordPath: state.operator.passwordPath,
  email: state.operator.email,
  emailError: state.operator.emailError,
  password: state.operator.password,
  passwordError: state.operator.passwordError,
  token: state.operator.token,
  tokenError: state.operator.tokenError,
  remember: state.operator.remember,
  logoUrl: state.operator.logoUrl,
  fingerprint: state.operator.fingerprint,
  components: state.operator.components,
});

const mapDispatchToProps = {
  changeEmail,
  changePassword,
  changeToken,
  toogleRemember,
  submitLogin,
  setFingerprint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Operator);
