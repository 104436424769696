import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { DisplayRow, SimpleDisplay } from 'liber-components';
import { TooltipBox } from 'liber-components/components/Helpers';

import {
  LoadingBlock,
  Card,
  CardHeader,
  InfoIconWrap,
  InfoIcon,
  BigTooltipContent,
  FlexRow,
} from '../styles';
import { EmptyState } from './styles';

const NextForecast = ({ loading, data }) => {
  const { date = '', amount = 0 } = data.summary.forecast;
  return (
    <>
      <Card width="600px" height="165px">
        <CardHeader>
          <FlexRow>
            PRÓXIMA LIQUIDAÇÃO PREVISTA
            <TooltipBox
              key="next-liquidation-tooltip"
              mount="left"
              fixed
              content={
                <BigTooltipContent width="326px" mobileWidth="192px">
                  A data de liquidação e a data de vencimento normalmente coincidem, porém em alguns
                  casos as datas podem divergir. O valor aqui informado não considera os títulos em
                  conciliação e em atraso.
                </BigTooltipContent>
              }
            >
              <InfoIconWrap>
                <InfoIcon />
              </InfoIconWrap>
            </TooltipBox>
          </FlexRow>
        </CardHeader>
        <LoadingBlock loading={loading}>
          {date.length === 0 ? (
            <EmptyState>Você não tem nenhum título a liquidar no momento</EmptyState>
          ) : (
            <DisplayRow>
              <SimpleDisplay divider={false}>
                {moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')}
              </SimpleDisplay>
              <SimpleDisplay divider={false}>
                {currencyFormatter.format(amount, { code: 'BRL', format: '%s %v' })}
              </SimpleDisplay>
            </DisplayRow>
          )}
        </LoadingBlock>
      </Card>
    </>
  );
};

NextForecast.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NextForecast;
