import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import VendorStatusOnBuyer from './VendorStatusOnBuyer';
import withTokenProvider from '../../hocs/withTokenProvider';

function VendorStatusOnBuyerWrapper(props) {
  return (
    <ThemeProvider theme={Theme}>
      <VendorStatusOnBuyer {...props} />
      <ToastContainer />
    </ThemeProvider>
  );
}

VendorStatusOnBuyerWrapper.propTypes = {
  initialStatus: PropTypes.string,
  initialBlockReason: PropTypes.string,
  buyerId: PropTypes.number,
};

VendorStatusOnBuyerWrapper.defaultProps = {
  initialStatus: '',
  initialBlockReason: '',
  buyerId: 0,
};

export default withTokenProvider(VendorStatusOnBuyerWrapper, 'buyer_jwt');
