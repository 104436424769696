import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import Header from './Header';
import FunderTradesHeaderContext from './context';

function View({ token, filters, hasTrades, tradesCount }) {
  return (
    <ThemeProvider theme={Theme}>
      <FunderTradesHeaderContext.Provider value={{ token, hasTrades, tradesCount, filters }}>
        <ToastContainer />
        <Header />
      </FunderTradesHeaderContext.Provider>
    </ThemeProvider>
  );
}

View.propTypes = {
  token: PropTypes.string,
  filters: PropTypes.shape({}),
  hasTrades: PropTypes.bool,
  tradesCount: PropTypes.number.isRequired,
};

View.defaultProps = {
  token: '',
  filters: {},
  hasTrades: true,
};

export default View;
