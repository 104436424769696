/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import { FormControlLabel, Checkbox, CardContent, Divider } from 'liber-salti';
import { Container, Wrapper } from './styles';
import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';
import RangePicker from './components/RangePicker';
import { Context } from '../../context';
import { ONLY_REGISTRATION_UPDATES_FILTER } from '../../constants';
import CollapsibleSection from '../../../../common-components/CollapsibleSection';

const CardFilter = () => {
  const { handleChangeFilters } = useContext(Context);

  const handleToggleCheckbox = (_, checked) => {
    handleChangeFilters(ONLY_REGISTRATION_UPDATES_FILTER, checked);
  };

  return (
    <SaltiThemeWrapper>
      <Wrapper>
        <CollapsibleSection title="Atualizações cadastrais">
          <Container>
            <CardContent>
              <RangePicker />
              <Divider />
              <FormControlLabel
                control={<Checkbox size="medium" />}
                label="Exibir apenas atualizações cadastrais"
                onChange={handleToggleCheckbox}
              />
            </CardContent>
          </Container>
        </CollapsibleSection>
      </Wrapper>
    </SaltiThemeWrapper>
  );
};

export default CardFilter;
