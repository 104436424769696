import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import VendorsGroupDetails from './components/VendorsGroupDetails';
import { VendorsGroupDetailsStore } from './context';
import withTokenProvider from '../../hocs/withTokenProvider';

function View({
  groupId,
  name,
  referenceCode,
  isDefault,
  funderTargetingMode,
  funderId,
  isFunder,
  funderToken,
}) {
  return (
    <ThemeProvider theme={Theme}>
      <VendorsGroupDetailsStore
        groupId={groupId}
        name={name}
        referenceCode={referenceCode}
        isDefault={isDefault}
        funderTargetingMode={funderTargetingMode}
        funderId={funderId}
        isFunder={isFunder}
        funderToken={funderToken}
      >
        <VendorsGroupDetails />
        <ToastContainer />
      </VendorsGroupDetailsStore>
    </ThemeProvider>
  );
}

View.propTypes = {
  groupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  referenceCode: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  funderTargetingMode: PropTypes.string.isRequired,
  funderId: PropTypes.number,
  funderToken: PropTypes.string,
  isFunder: PropTypes.bool,
};

View.defaultProps = {
  funderId: undefined,
  funderToken: null,
  isFunder: false,
};

export default withTokenProvider(View, 'buyer_jwt');
