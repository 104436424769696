import React, { useContext } from 'react';
import { PrimaryButton, CompleteTable } from 'liber-components';
import { LimitsContext } from '../../context';
import { toBrl } from '../../utils';

const LimitsTable = () => {
  const { limits, setSelectedBuyer, setShowLimitsModal } = useContext(LimitsContext);

  const handleModal = buyer => {
    setSelectedBuyer(buyer);
    setShowLimitsModal(true);
  };

  const columns = [
    {
      name: 'buyers',
      label: 'Grupo de Sacados',
      width: 30,
      groupedColumns: ['company', 'tradeName', 'cnpj'],
      getCellValue: limit => {
        const { buyerOrganizationGroupName } = limit;
        return buyerOrganizationGroupName;
      },
    },
    {
      name: 'total_limit',
      label: 'Limite Configurado',
      width: 40,
      getCellValue: ({ currentValue }) => (currentValue ? toBrl(currentValue) : 'Não definido'),
    },
    {
      name: 'limit_used',
      label: 'Limite Usado',
      width: 20,
      getCellValue: ({ currentTakenValue }) => toBrl(currentTakenValue),
    },
    {
      name: 'available',
      label: 'Limite Disponível',
      width: 20,
      getCellValue: ({ available }) => toBrl(available),
    },
    {
      name: 'actions',
      label: 'Ações',
      width: 30,
      align: 'center',
      getCellValue: vendor => (
        <PrimaryButton onClick={() => handleModal(vendor)} version={2}>
          Atualizar limite
        </PrimaryButton>
      ),
    },
  ];

  return <CompleteTable columns={columns} items={limits} />;
};

export default LimitsTable;
