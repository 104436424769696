const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const VENDORS_URL = `${apiUrl}/api/v1/admin/vendors`;
export const RESET_PASSWORD_URL = `${VENDORS_URL}/reset_password`;
export const NEW_VENDOR_PATH = '/admin/fornecedores/new';
export const SCHEDULES_PATH = '/admin/schedules';
export const SCHEDULE_VENDOR_DATA_SEND_PATH = `${apiUrl}/api/v1/admin/schedule_vendor_data_sends`;
export const BANKS_URL = `${apiUrl}/api/v1/banks.json`;
export const BLOCK_VENDOR_URL = `${apiUrl}/api/v1/admin/vendors/block_organization_on_buyer`;
export const UNBLOCK_VENDOR_URL = `${apiUrl}/api/v1/admin/vendors/unblock_organization_on_buyer`;
export const GET_FUNDERS_URL = `${apiUrl}/api/v1/admin/funders`;
export const BLOCK_UNBLOCK_URL = `${apiUrl}/api/v1/admin/organizations/blocks`;
export const BLOCK_UNBLOCK_BUYER_URL = {
  block: `${apiUrl}/api/v1/admin/vendors/block_organization_on_buyer`,
  unblock: `${apiUrl}/api/v1/admin/vendors/unblock_organization_on_buyer`,
};
export const BATCH_BLOCK_UNBLOCK_URL = `${apiUrl}/api/v1/admin/organizations/batch_blocks`;
export const EXTRACT_CNPJS_URL = `${BATCH_BLOCK_UNBLOCK_URL}/extract_cnpjs`;
