import axios from 'axios';

import {
  CANCEL_ACTION_CABLE_CHANNEL,
  CANCEL_ACTION_CABLE_ROOM,
  CANCEL_URL,
  NEGOTIATION_CANCEL_URL,
  SOLICITATION_CANCEL_URL,
} from '../urls';
import { handleError } from '../../../vendor/Utils';

export const SUBSCRIBE_CANCEL = 'SUBSCRIBE_CANCEL';
export const CANCEL_BORDERO = 'CANCEL_BORDERO';
export const BORDERO_CANCELLATION_PROGRESS = 'BORDERO_CANCELLATION_PROGRESS';
export const BORDERO_CANCELLATION_SUCCESS = 'BORDERO_CANCELLATION_SUCCESS';
export const BORDERO_CANCELLATION_ERROR = 'BORDERO_CANCELLATION_ERROR';

const getSolicitationCancelUrl = id => {
  if (Number.isNaN(id)) return SOLICITATION_CANCEL_URL;

  return NEGOTIATION_CANCEL_URL.replace(':id', id);
};

export function requestCancel() {
  return (dispatch, getState) => {
    dispatch({ type: CANCEL_BORDERO });

    const {
      anticipationCancel: { token, isSolicitation },
    } = getState();

    const urlParams = new URLSearchParams(window.location.search);
    const id = parseInt(urlParams.get('id'), 10);

    const catchError = () => {
      dispatch({ type: BORDERO_CANCELLATION_ERROR });
      handleError();
    };

    const handleSuccess = () => dispatch({ type: 'BORDERO_CANCELLATION_SUCCESS' });

    if (isSolicitation) {
      return axios
        .delete(getSolicitationCancelUrl(id), { headers: { Authorization: `Bearer ${token}` } }, {})
        .then(handleSuccess)
        .catch(catchError);
    }

    return axios
      .post(
        CANCEL_URL.replace(':anticipation_id', id),
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .catch(catchError);
  };
}

export function subscribeCancel() {
  return (dispatch, getState) => {
    const {
      anticipationCancel: {
        operator: { id },
      },
    } = getState();

    dispatch({
      type: SUBSCRIBE_CANCEL,
      channel: CANCEL_ACTION_CABLE_CHANNEL,
      room: CANCEL_ACTION_CABLE_ROOM + id,
    });
  };
}
