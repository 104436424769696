import axios from 'axios';
import moment from 'moment';
import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';
import { validateCpf, validateEmail } from '../../../vendor/validation';

import errorMsg from './messages';
import { nextStep, setCompleted } from './progressBar';
// eslint-disable-next-line import/no-cycle
import { formatErrorFromBackend } from './wizard';

export const changeName = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/NAME',
  payload: value,
});

export const changeCpf = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CPF',
  payload: value,
});

export const changeEmail = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/EMAIL',
  payload: value,
});

export const changeCellphone = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CELLPHONE',
  payload: value,
});

export const changePhone = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/PHONE',
  payload: value,
});

export const changeGender = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/GENDER',
  payload: value,
});

export const changeBirthDate = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/BIRTH_DATE',
  payload: value,
});

export const changeCivilStatus = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CIVIL_STATUS',
  payload: value,
});

export const changeCountryOrigin = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/COUNTRY_ORIGIN',
  payload: value,
});

export const changeStateOrigin = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/STATE_ORIGIN',
  payload: value,
});

export const changeCityOrigin = value => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CITY_ORIGIN',
  payload: value,
});

const formatData = (data = {}) => ({
  ...data,
  cpf: data.cpf ? changeCpf(data.cpf).payload : data.cpf,
  cellphone: data.cellphone ? changeCellphone(data.cellphone).payload : data.cellphone,
  phone: data.cellphone ? changePhone(data.phone).payload : data.phone,
  birthdate: data.birthdate ? changeBirthDate(data.birthdate).payload : data.birthdate,
  gender: data.gender ? data.gender.toLowerCase() : data.gender,
  civil_status: data.civil_status ? data.civil_status.toLowerCase() : data.civil_status,
  country_origin: data.country_origin ? data.country_origin.toUpperCase() : data.country_origin,
  state_origin: data.state_origin ? data.state_origin.toUpperCase() : data.state_origin,
});

export const setData = data => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/SET_DATA',
  payload: data ? formatData(data) : undefined,
});

export const handleSuccess = (response = { data: {} }) => {
  if (response.data.errors) {
    return {
      type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/FAILED_SUBMIT',
      payload: response.data.errors,
    };
  }

  return {
    type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/SUCCESSFULL_SUBMIT',
  };
};

export const handleFailure = payload => ({
  type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/FAILED_SUBMIT',
  payload,
});

export const sendToBackend =
  (dadosPessoais = {}) =>
  dispatch => {
    const data = {
      investidor: {
        name: dadosPessoais.name,
        cnpj: dadosPessoais.cpf,
        email: dadosPessoais.email,
        cellphone: dadosPessoais.cellphone,
        phone: dadosPessoais.phone,
        gender: dadosPessoais.gender,
        birthdate: dadosPessoais.birthDate,
        civil_status: dadosPessoais.civilStatus,
        country_origin: dadosPessoais.countryOrigin,
        state_origin: dadosPessoais.stateOrigin.toUpperCase(),
        city_origin: dadosPessoais.cityOrigin,
        password: '',
        password_confirmation: '',
        current_password: '',
      },
    };
    const csrfToken = ReactOnRails.authenticityToken();
    return axios({
      method: 'PATCH',
      url: '/investidores.json',
      data: {
        ...data,
        authenticity_token: csrfToken,
      },
      responseType: 'json',
    })
      .then(response => {
        dispatch(handleSuccess(response));
        dispatch(nextStep());
        dispatch(setCompleted(1));
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch(handleFailure(formatErrorFromBackend(error)));
      });
  };

export const validateForm = dadosPessoais => {
  const {
    name,
    cpf,
    email,
    cellphone,
    gender,
    birthDate,
    civilStatus,
    countryOrigin,
    stateOrigin,
    cityOrigin,
  } = dadosPessoais;
  const payload = {};
  const birthdate = moment(birthDate, 'DD/MM/YYYY', true);
  const currentDate = moment();

  if (name.trim().split(' ').length === 1) {
    payload.name = errorMsg.name;
  }
  if (name.length === 0) {
    payload.name = errorMsg.empty;
  }
  if (cpf.length === 0) {
    payload.cpf = errorMsg.empty;
  } else if (!validateCpf(cpf)) {
    payload.cpf = errorMsg.invalidFormat;
  }
  if (email.length === 0) {
    payload.email = errorMsg.empty;
  } else if (!validateEmail(email)) {
    payload.email = errorMsg.invalidFormat;
  }
  if (cellphone.length < 15) {
    payload.cellphone = errorMsg.invalidFormat;
  }
  if (cellphone.length === 0) {
    payload.cellphone = errorMsg.empty;
  }
  if (gender.length === 0) {
    payload.gender = errorMsg.empty;
  }
  if (!birthdate.isValid()) {
    payload.birthdate = errorMsg.invalidFormat;
  }
  if (birthdate.startOf('day').diff(currentDate.startOf('day'), 'years') > -18) {
    payload.birthdate = errorMsg.underage;
  }
  if (birthdate.startOf('day').diff(currentDate.startOf('day'), 'years') > 0) {
    payload.birthdate = errorMsg.invalidDate;
  }
  if (birthDate.length === 0) {
    payload.birthdate = errorMsg.empty;
  }
  if (civilStatus.length === 0) {
    payload.civil_status = errorMsg.empty;
  }
  if (countryOrigin.length === 0) {
    payload.country_origin = errorMsg.empty;
  }
  if (stateOrigin.length === 0) {
    payload.state_origin = errorMsg.empty;
  }
  if (cityOrigin.length === 0) {
    payload.city_origin = errorMsg.empty;
  }

  return payload;
};

export const submitForm = (dadosPessoais, absoluteSuccess) => dispatch => {
  let failure = false;
  const payload = validateForm(dadosPessoais);

  if (Object.keys(payload).length === 0) {
    dispatch(sendToBackend(dadosPessoais));
  } else {
    failure = true;
    toast(errorMsg.pendingForms, 'error', 8000);
    dispatch(handleFailure(payload));
  }
  if (!failure) {
    if (typeof absoluteSuccess === 'function') {
      absoluteSuccess();
    }
  }

  return dispatch({
    type: 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/SUBMIT_FORMS',
  });
};
