import React, { useContext } from 'react';
import { CompleteTable } from 'liber-components';
import Filters from '../Filters';
import { getPaginationInterval } from '../../../../../../vendor/Utils';
import { DetailsDrawerContext } from '../../context';
import * as S from './styles';

const COLUMNS = [
  {
    name: 'name',
    label: 'Sacado',
    // eslint-disable-next-line react/prop-types
    getCellValue: ({ name, cnpj }) => (
      <>
        {name}
        <br />
        {cnpj}
      </>
    ),
  },
  {
    name: 'vendors_group',
    label: 'Grupo',
    width: 60,
    getCellValue: ({ vendorsGroup }) => vendorsGroup.name,
  },
];

const Table = () => {
  const {
    loading,
    currentPage,
    pages,
    setCurrent,
    setPageState,
    next,
    previous,
    onKeyDownPage,
    per,
    count,
    onSelectListing,
    pagination,
    buyersList,
  } = useContext(DetailsDrawerContext);
  return (
    <S.Loading loading={loading}>
      {loading && <S.Bunny loop />}
      <CompleteTable
        columns={COLUMNS}
        items={buyersList}
        filters={<Filters />}
        paginationProps={{
          currentPage,
          pageTotal: pages,
          onChangePage: ({ target: { value } }) => setPageState(value),
          onClickNext: () => setCurrent(next),
          onClickPrev: () => setCurrent(previous),
          onKeyDownPage,
          hasListing: true,
          listingProps: {
            listing: String(per),
            total: String(count),
            showInterval: getPaginationInterval(pagination),
            onSelectListing,
            listingLabelSingular: 'investidor',
            listingLabelPlural: 'investidores',
          },
        }}
      />
    </S.Loading>
  );
};

export default Table;
