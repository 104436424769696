const patterns = {
  dashboard: [/\/dashboard\//, /\/dashboard\b/],
  dashboards: [/\/dashboards\//, /\/dashboards\b/],
  invoices: [/\/negociacoes\//, /\/negociacoes\b/],
  statement: [/\/extrato\//, /\/extrato\b/],
  limits: [/\/limites\//, /\/limites\b/],
  irpf: [/\/informe_rendimentos\//, /\/informe_rendimentos\b/],
  spreadsheetRates: [/\/painel_taxas\//, /\/painel_taxas\b/],
  ratesComplement: [/\/ganhos\//, /\/ganhos\b/],
  remmitances: [/\/remessas\//, /\/remessas\b/],
  proposes: [/\/ofertas\//, /\/ofertas\b/],
  account: [/\/conta\//, /\/conta\b/],
  signatures: [/\/assinaturas\//, /\/assinaturas\b/],
};

export const routes = (paths, funder) => {
  const {
    dashboard,
    invoices,
    statement,
    limits,
    irpf,
    spreadsheetRates,
    ratesComplement,
    remittances,
    signatures,
    dashboards,
  } = paths;

  const {
    isPf,
    isPj,
    kind,
    canCreateRemittances,
    hasFunderAgreements,
    isAllowedToIrpf,
    isAllowedToSpreadsheetRates,
    canAddEarningToRates,
    isAllowedToSignBordereaux,
  } = funder;

  const sidebarRoutes = [];

  if (isPf) {
    sidebarRoutes.push({
      label: 'Dashboard',
      icon: 'Board',
      path: dashboard,
      routePatterns: patterns.dashboard,
    });
  }

  if (kind === 'banco') {
    sidebarRoutes.push({
      label: 'Dashboard',
      icon: 'Board',
      path: dashboards,
      routePatterns: patterns.dashboards,
    });
  }

  sidebarRoutes.push({
    label: 'Negociações',
    icon: 'Money',
    path: invoices,
    routePatterns: patterns.invoices,
  });

  if (isAllowedToSpreadsheetRates) {
    sidebarRoutes.push({
      label: 'Painel de Taxas',
      icon: 'DataTrending',
      path: spreadsheetRates,
      routePatterns: patterns.spreadsheetRates,
    });
  }

  if (isPf) {
    sidebarRoutes.push({
      label: 'Extrato',
      icon: 'CalendarAgenda',
      path: statement,
      routePatterns: patterns.statement,
    });
  }

  if (hasFunderAgreements) {
    sidebarRoutes.push({
      label: 'Configurar Limites',
      icon: 'Options',
      path: limits,
      routePatterns: patterns.limits,
    });
  }

  if (canAddEarningToRates) {
    sidebarRoutes.push({
      label: 'Ganhos',
      icon: 'DataBarVerticalAdd',
      path: ratesComplement,
      routePatterns: patterns.ratesComplement,
    });
  }

  if (isPj && canCreateRemittances) {
    sidebarRoutes.push({
      label: 'Remessas',
      icon: 'ArrowSwap',
      path: remittances,
      routePatterns: patterns.remmitances,
    });
  }

  if (isAllowedToIrpf) {
    sidebarRoutes.push({
      label: 'IRPF',
      icon: 'BuildingBank',
      path: irpf,
      routePatterns: patterns.irpf,
    });
  }

  if (isAllowedToSignBordereaux) {
    sidebarRoutes.push({
      label: 'Assinaturas',
      icon: 'Signature',
      path: signatures,
      routePatterns: patterns.signatures,
    });
  }

  return sidebarRoutes;
};

export const userActions = (pathname, userPaths, navigate) => {
  const { proposes, account, signOut } = userPaths;

  const actions = [
    { label: 'Propostas', path: proposes, routePatterns: patterns.proposes },
    { label: 'Minha Conta', path: account, routePatterns: patterns.account },
  ];

  actions.push({
    label: 'Sair',
    path: signOut,
    iconName: 'SignOut',
  });

  return actions.map(({ label, path, iconName = undefined, routePatterns = [] }) => {
    if (path === signOut) {
      return {
        href: path,
        component: 'a',
        label,
        iconName,
        divider: true,
        'data-method': 'delete',
      };
    }

    return {
      onClick: () => navigate(path, label),
      label,
      iconName,
      selected: routePatterns.some(pattern => pattern.test(pathname)),
    };
  });
};

export const SIDEBAR_EXPANDED_COOKIE = 'buyer_sidebar_expanded';
