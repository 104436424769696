export const getAnalise = state => state.paths.analise || '';
export const getRg = state => (state.paths ? state.paths.rg || {} : {});
export const getCnh = state => (state.paths ? state.paths.cnh || {} : {});
export const getRoot = state => state.paths.root || '';
export const getPaths = state => ({
  analise: getAnalise(state),
  rg: getRg(state),
  cnh: getCnh(state),
  root: getRoot(state),
});
