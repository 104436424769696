import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Card, Typography, Radio, TextField, Checkbox, Button, Alert } from 'liber-salti';
import ReactOnRails from 'react-on-rails';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { getFingerprint } from '../../../../vendor/client';

import LoginFooter from '../../../../common-components/LoginFooter';
import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';

import {
  FUNDER_FORGOT_PASSWORD_PATH,
  FUNDER_REGISTRATION_PATH,
  FUNDER_LOGIN_PATH,
} from '../../paths';

import {
  Container,
  Content,
  CardContent,
  Logo,
  LogoWrapper,
  RadioContainer,
  CheckboxWrapper,
  ActionsWrapper,
  InputsWrapper,
  AlertWrapper,
  HiddenForm,
} from '../../styles';

const CPF_DOCUMENT = 'cpf';
const CNPJ_DOCUMENT = 'cnpj';

const FunderLogin = ({ alerts }) => {
  const formRef = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const [documentType, setDocumentType] = useState(CPF_DOCUMENT);
  const [documentNumber, setDocumentNumber] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [fingerprint, setFingerprint] = useState();
  const [fingerprintComponents, setFingerprintComponents] = useState();

  const csrfToken = ReactOnRails.authenticityToken();

  const fieldsSize = useMemo(() => (isMobile ? 'large' : 'medium'), [isMobile]);

  const loginButtonEnabled = useMemo(() => {
    const expectedDocumentLength = documentType === CPF_DOCUMENT ? 14 : 18;

    return password.length > 0 && documentNumber.length === expectedDocumentLength;
  }, [documentNumber, password]);

  const [alertMessage, alertSeverity] = useMemo(() => {
    if (!(Array.isArray(alerts) && alerts.length > 0)) return [];

    const [alert] = alerts;
    const [severity, message] = alert;

    let sanitizedSeverity = 'warning';
    if (severity === 'notice') sanitizedSeverity = 'success';

    return [message, sanitizedSeverity];
  }, [alerts]);

  const hasError = useMemo(() => alertSeverity === 'error', [alertSeverity]);

  const handleChangeDocumentType = type => {
    setDocumentType(type);
    setDocumentNumber('');
  };

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const handleRegister = () => {
    window.location.assign(FUNDER_REGISTRATION_PATH);
  };

  const handleForgotPassword = () => {
    window.location.assign(FUNDER_FORGOT_PASSWORD_PATH);
  };

  const setupFingerprint = () => {
    getFingerprint().then(({ fingerprint: fingerprintId, components }) => {
      setFingerprintComponents(components);
      setFingerprint(fingerprintId);
    });
  };

  useEffect(() => {
    setupFingerprint();
  }, []);

  return (
    <Container>
      <Content>
        <Card>
          <CardContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography variant="h2" color="textPrimary">
              Entrar como investidor
            </Typography>
            {alertMessage && (
              <AlertWrapper>
                <Alert severity={alertSeverity} description={alertMessage} />
              </AlertWrapper>
            )}
            <RadioContainer>
              <Radio
                label="CPF"
                checked={documentType === CPF_DOCUMENT}
                onClick={() => handleChangeDocumentType(CPF_DOCUMENT)}
              />
              <Radio
                label="CNPJ"
                checked={documentType === CNPJ_DOCUMENT}
                onClick={() => handleChangeDocumentType(CNPJ_DOCUMENT)}
              />
            </RadioContainer>
            <InputsWrapper>
              <TextField
                value={documentNumber}
                onChange={e => setDocumentNumber(e.target.value)}
                label={documentType.toUpperCase()}
                helperText="Somente números"
                required
                fullWidth
                maskOption={documentType}
                error={hasError}
                size={fieldsSize}
                data-testid="DocumentInput"
              />
              <TextField
                value={password}
                onChange={e => setPassword(e.target.value)}
                label="Senha"
                type="password"
                required
                fullWidth
                error={hasError}
                size={fieldsSize}
                data-testid="PasswordInput"
              />
            </InputsWrapper>
            <CheckboxWrapper>
              <Checkbox checked={rememberMe} onClick={() => setRememberMe(!rememberMe)} />
              <Typography variant="body1" color="textPrimary">
                Manter-me conectado
              </Typography>
            </CheckboxWrapper>
            <ActionsWrapper>
              <Button
                onClick={() => handleSubmit()}
                disabled={!loginButtonEnabled}
                size="large"
                fullWidth
                data-testid="SubmitButton"
              >
                ENTRAR
              </Button>
              <Button onClick={() => handleRegister()} size="large" variant="outlined" fullWidth>
                CADASTRAR
              </Button>
              <Button onClick={() => handleForgotPassword()} size="large" variant="text" fullWidth>
                ESQUECI A SENHA
              </Button>
            </ActionsWrapper>
          </CardContent>
        </Card>
      </Content>
      <LoginFooter />
      <HiddenForm data-testid="Form" ref={formRef} method="POST" action={FUNDER_LOGIN_PATH}>
        <input value="INVESTIDOR/LOGIN" name="investidor[breadcrumb]" readOnly />
        <input value={documentType.toLowerCase()} name="radio_investidor[cnpj]" readOnly />
        <input value={documentNumber} name="investidor[cnpj]" readOnly />
        <input value={password} name="investidor[password]" readOnly />
        <input value={rememberMe} name="investidor[remember_me]" readOnly />
        <input value={fingerprint} name="investidor[fingerprint]" readOnly />
        <input value={csrfToken} name="authenticity_token" readOnly />
        <input
          value={JSON.stringify(fingerprintComponents)}
          name="investidor[components]"
          readOnly
        />
        <input type="submit" />
      </HiddenForm>
    </Container>
  );
};

const View = ({ funder: { alerts } }) => (
  <SaltiThemeWrapper>
    <FunderLogin alerts={alerts} />
  </SaltiThemeWrapper>
);

FunderLogin.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

FunderLogin.defaultProps = {
  alerts: [],
};

View.propTypes = {
  funder: {
    alerts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  },
};

View.defaultProps = {
  funder: {
    alerts: [],
  },
};

export default View;
