export const showFixedBox = () => ({
  type: 'SUMMARY_SHOW_FIXED_BOX',
});

export const hideFixedBox = () => ({
  type: 'SUMMARY_HIDE_FIXED_BOX',
});

export const selectFunder = funder => ({
  type: 'SUMMARY_SELECT_FUNDER',
  payload: funder,
});
