import React from 'react';
import PropTypes from 'prop-types';
import { Content, Image, ImageContainer } from './styles';
import { Layout } from '../styles';
import { NavigationContainer, Next } from '../../Navigation';

const SolicitacaoEnviada = ({ analise, root, redirectTo }) => (
  <Layout>
    <h2>Solicitação de Cadastro em Análise</h2>
    <ImageContainer>
      <Image src={analise} alt="liber-analise" />
    </ImageContainer>
    <Content>
      <p>
        <span>Sua solicitação de cadastro foi enviada com sucesso! </span>
        <span className="hidden">
          Sua documentação será avaliada por nossa equipe de suporte, e você receberá um email de
          confirmação assim que seu cadastro for liberado.
        </span>
        <span>
          {' '}
          Não se preocupe, a análise e aprovação
          <strong> não deve levar mais do que dois dias úteis!</strong>
        </span>
      </p>
      <p>
        <span>
          Enviamos um link de confirmação para o endereço de email cadastrado,
          <strong>
            {' '}
            acesse seu email e clique no botão de confirmação para ganhar acesso ao nosso portal!{' '}
          </strong>
        </span>
      </p>
      <p>
        <span>
          Lembramos que é necessário
          <strong>
            {
              ' cadastrar a Liber Capital como Conta Recebedora de Crédito e ajustar o Limite de Transferência da'
            }
            {' sua conta bancária'}
          </strong>
          , de forma a facilitar operações futuras.
        </span>
      </p>
    </Content>
    <NavigationContainer style={{ padding: '25px 0px' }}>
      <Next onClick={() => redirectTo(root)} label="Ir para o portal" style={{ float: 'right' }} />
    </NavigationContainer>
  </Layout>
);

SolicitacaoEnviada.propTypes = {
  analise: PropTypes.string.isRequired,
  root: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
};

export default SolicitacaoEnviada;
