import { connect } from 'react-redux';
import MyWallet from './MyWallet';
import { getMyWalletData } from '../../actions/MyWalletActions';
import { getInvoicesData } from '../../actions/InvoicesActions';
import { getDialogContent } from '../InvoicesDialog/redux';

const reducer = 'wallet';

const parentReducer = 'investorDashboard';

const mapStateToProps = state => ({ ...state[parentReducer][reducer] });
const mapDispatchToProps = {
  getMyWalletData,
  getInvoicesData,
  getDialogContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyWallet);
