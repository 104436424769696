export const DOWNLOAD_MESSAGE = {
  term: 'prazo',
  date: 'data',
};

export const MAIL_LINK =
  // eslint-disable-next-line max-len
  'mailto:success@libercapital.com.br?subject=Dúvidas no preenchimento da planilha de taxas&cc=atlantis@libercapital.com.br';

// eslint-disable-next-line no-undef
export const SEND_SPREADSHEET_RATES = `${SERVICE_API_URL}/api/v1/investidor/spreadsheet_rates`;
