import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import {
  DisplayRow,
  DropDown,
  DropDownOption as Option,
  BarChart,
  getScreenSize,
  toast,
  TooltipBox,
  handleThemeFromObject,
} from 'liber-components';
import {
  Cell,
  LoadingBlock,
  TooltipContent,
  TooltipTitle,
  TooltipValue,
  InfoIcon,
  InfoIconWrap,
  PaddingContainer,
  FlexRow,
} from '../../styles';
import {
  Title,
  Row,
  Column,
  SimpleDisplay,
  Color,
  CardContent,
  EmptyChart,
  EmptyIcon,
  EmptyMainText,
} from '../styles';
import { convertToCamelCase, fixPercent } from '../../../../../vendor/Utils';

const VERY_LARGE_SCREEN_WIDTH = 1268;

class Liquidations extends Component {
  constructor() {
    super();
    const today = moment().startOf('day');
    this.state = {
      period: 'all-next',
      screenSize: 'small',
      chartWidth: 0,
      first: today.format('YYYY-MM-DD'),
      last: null,
      tooltipPosition: 'top',
    };
  }

  componentDidMount() {
    const { getCalendarData, token } = this.props;
    const { first, last } = this.state;
    this.setScreeSize();
    window.addEventListener('resize', this.setScreeSize);
    getCalendarData(token, first, last);
  }

  componentWillReceiveProps(nextProps) {
    const { status, error } = nextProps;
    if (!status) {
      toast(error ? error.message : 'Ocorreu um erro!', 'error', 8000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreeSize);
  }

  setScreeSize = () => {
    this.setState({
      screenSize: getScreenSize(window),
      chartWidth: this.content ? this.content.offsetWidth - 46 : 0,
    });

    if (window.innerWidth > VERY_LARGE_SCREEN_WIDTH) {
      this.setState({ tooltipPosition: 'top' });
    }
  };

  getParsedDateDomain = (determinedFirst, determinedLast, data) => {
    const first = moment(determinedFirst);
    let diff;
    let last = (() => {
      const dataLast =
        data.length > 0 ? moment(data[data.length - 1].date, 'DD/MM/YYYY') : moment();

      diff = moment(determinedLast || moment(dataLast)).diff(moment(determinedFirst), 'days');
      if (diff < 7) {
        return moment(determinedFirst).add(6, 'days');
      }
      return moment(determinedLast || moment(dataLast));
    })();

    last = !determinedLast ? moment(last).add(1, 'day') : last;

    return { first, last, diff };
  };

  getXDomain = data => {
    const { period, screenSize } = this.state;
    const { first, last, diff } = this.getParsedDateDomain(this.state.first, this.state.last, data);
    return {
      scale: 'time',
      timeSeparator: 'month',
      past: period === '7-last' || period === '15-last' || period === '30-last',
      first,
      last,
      offset: Math.ceil(diff / 30),
      skip: screenSize === 'small' || screenSize === 'medium' || diff > 15 ? 1 : 0,
      count: 0,
      padding: 15,
    };
  };

  getYDomain = data => {
    let maxToReceive = data.reduce((prev, next) => {
      if (next.summary.to_receive > prev) {
        return next.summary.to_receive;
      }
      return prev;
    }, 0);
    maxToReceive = maxToReceive === 0 ? 100 : maxToReceive;
    const maxOrderOfMagnitude = Math.floor(Math.log(maxToReceive * 1.25) / Math.LN10 + 0.000000001);
    return {
      format: num =>
        num > 999 ? `R$ ${(num / 1000).toFixed(num % 1000 !== 0 ? 1 : 0)}k` : `R$ ${num}`,
      first: 0,
      last: Math.ceil(maxToReceive / 10 ** maxOrderOfMagnitude) * 10 ** maxOrderOfMagnitude,
      count: 3,
    };
  };

  getCurrentLabel = period => {
    switch (period) {
      case 'all-next':
      case '7-next':
      case '15-next':
      case '30-next':
        return {
          toReceiveLabel: 'Valor a Liquidar',
          invoiceCountLabel: 'Títulos a Liquidar',
          color: handleThemeFromObject(this.props.theme, 'colors.lightGreenGraph', '#2DE8BF'),
        };
      case '7-last':
      case '15-last':
      case '30-last':
        return {
          toReceiveLabel: 'Valor Liquidado',
          invoiceCountLabel: 'Títulos Liquidados',
          color: '#0ccde8',
        };
      default:
        return {
          toReceiveLabel: 'Valor Liquidado',
          invoiceCountLabel: 'Títulos Liquidados',
          color: handleThemeFromObject(this.props.theme, 'colors.darkGrey', '#717171'),
        };
    }
  };

  handleBarClick = (proxy, data) => {
    const { getInvoices, token } = this.props;

    getInvoices(token, data.datum.x);
  };

  handlePeriodSelection = period => {
    const { getCalendarData, token } = this.props;

    const today = moment().startOf('day');
    const yesterday = moment(today).subtract(1, 'day');

    let definedPeriod = period;
    let first;
    let last;

    switch (definedPeriod) {
      case '7-next':
        first = today.format('YYYY-MM-DD');
        last = moment(today).add(6, 'days').format('YYYY-MM-DD');
        break;
      case '15-next':
        first = today.format('YYYY-MM-DD');
        last = moment(today).add(14, 'days').format('YYYY-MM-DD');
        break;
      case '30-next':
        first = today.format('YYYY-MM-DD');
        last = moment(today).add(30, 'days').format('YYYY-MM-DD');
        break;
      case '7-last':
        first = moment(yesterday).subtract(6, 'days').format('YYYY-MM-DD');
        last = yesterday.format('YYYY-MM-DD');
        break;
      case '15-last':
        first = moment(yesterday).subtract(14, 'days').format('YYYY-MM-DD');
        last = yesterday.format('YYYY-MM-DD');
        break;
      case '30-last':
        first = moment(yesterday).subtract(30, 'days').format('YYYY-MM-DD');
        last = yesterday.format('YYYY-MM-DD');
        break;
      case 'all-next':
      default:
        first = today.format('YYYY-MM-DD');
        last = null;
        definedPeriod = 'all-next';
        break;
    }
    this.setState({ period: definedPeriod, first, last }, () => {
      getCalendarData(token, first, last);
    });
  };

  getPaddingByMaxValue = maxValue => {
    const basePadding = 70;
    if (maxValue > 1000) {
      const valueInMillion = parseInt(maxValue / 1000000, 10);
      return basePadding + String(valueInMillion).length * 10;
    }
    return basePadding;
  };

  formatDataForChart = data => {
    const allValues = [];
    const formattedData = data.asMutable().map(item => {
      const { period, tooltipPosition } = this.state;
      const { toReceiveLabel, invoiceCountLabel } = this.getCurrentLabel(period);
      const {
        invoiceCount,
        toReceive,
        yield: summaryYield,
        profitability,
        reference: { cdi },
      } = convertToCamelCase(item.summary);

      const tooltipContent = (
        <TooltipContent>
          <Row>
            <TooltipTitle>{invoiceCountLabel}:</TooltipTitle>
            <TooltipValue>{invoiceCount}</TooltipValue>
          </Row>
          <Row>
            <TooltipTitle>{toReceiveLabel}:</TooltipTitle>
            <TooltipValue>
              {currencyFormatter.format(toReceive, { code: 'BRL', format: '%s %v' })}
            </TooltipValue>
          </Row>
          <Row>
            <TooltipTitle>Rendimento:</TooltipTitle>
            <TooltipValue>
              {currencyFormatter.format(summaryYield, { code: 'BRL', format: '%s %v' })}
            </TooltipValue>
          </Row>
          <Row>
            <TooltipTitle>Rentabilidade média:</TooltipTitle>
            <TooltipValue>{fixPercent(profitability)}% a.m</TooltipValue>
          </Row>
          <Row>
            <TooltipTitle>Rentabilidade/CDI:</TooltipTitle>
            <TooltipValue>{fixPercent(cdi)}%</TooltipValue>
          </Row>
        </TooltipContent>
      );

      allValues.push(toReceive);
      return {
        x: moment(item.date, 'DD/MM/YYYY'),
        y: toReceive,
        tooltipContent,
        mount: tooltipPosition,
      };
    });

    const max = Math.max(...allValues);
    const labelPadding = this.getPaddingByMaxValue(max);

    return { labelPadding, formattedData };
  };

  renderEmptyState = loading => (
    <EmptyChart loading={loading} type="bar">
      {!loading ? (
        <>
          <div>
            <EmptyIcon />
          </div>
          <Column>
            <EmptyMainText>
              Você não tem nenhum Título Liquidado ou A Liquidar no perído selecionado
            </EmptyMainText>
          </Column>
        </>
      ) : null}
    </EmptyChart>
  );

  debounce = (callback, delay) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(callback, delay);
  };

  onMouseMove = ({ clientX }) => {
    const screenWidth = window.innerWidth;
    if (screenWidth < VERY_LARGE_SCREEN_WIDTH) {
      const isLeftSide = clientX < screenWidth / 2;
      const tooltipPosition = isLeftSide ? 'right' : 'left';
      this.debounce(() => this.setState({ tooltipPosition }), 50);
    }
  };

  renderCard = (data, total, loading) => {
    const { screenSize, chartWidth, period } = this.state;
    const { first, last } = this.getParsedDateDomain(this.state.first, this.state.last, data);
    const { toReceive, invoiceCount, yield: yieldValue } = convertToCamelCase(total);
    const { theme } = this.props;

    const { toReceiveLabel, invoiceCountLabel, color } = this.getCurrentLabel(period);
    const { labelPadding, formattedData } = this.formatDataForChart(data);

    return (
      <>
        <Row>
          <Cell>
            <Column>
              <Title>
                {screenSize === 'small' || screenSize === 'medium' || screenSize === 'large'
                  ? `Período de ${first.format('DD/MM')}
                      à ${last.format('DD/MM')}`
                  : `Vencimentos no Período de
                    ${first.format('DD/MM')} a
                    ${last.format('DD/MM')}`}
              </Title>
              <DropDown defaultValue={this.state.period} onSelect={this.handlePeriodSelection}>
                <Option value="all-next">Valor a Receber</Option>
                <Option value="7-next">Próximos 7 dias</Option>
                <Option value="15-next">Próximos 15 dias</Option>
                <Option value="30-next">Próximos 30 dias</Option>
                <Option value="7-last">Últimos 7 dias</Option>
                <Option value="15-last">Últimos 15 dias</Option>
                <Option value="30-last">Últimos 30 dias</Option>
              </DropDown>
            </Column>
          </Cell>
          {screenSize === 'large' || screenSize === 'extraLarge' ? (
            <Cell>
              <DisplayRow>
                <SimpleDisplay
                  colorBar={
                    toReceive > 0
                      ? color
                      : handleThemeFromObject(theme, 'colors.darkGrey', '#717171')
                  }
                  divider
                  label={toReceiveLabel}
                  prefix="R$"
                >
                  <Color
                    color={
                      toReceive > 0
                        ? color
                        : handleThemeFromObject(theme, 'colors.darkGrey', '#717171')
                    }
                  >
                    <FlexRow>
                      {currencyFormatter.format(toReceive, {
                        code: 'BRL',
                        symbol: '',
                        format: '%v',
                      })}
                      <TooltipBox
                        key="a"
                        mount="top"
                        fixed
                        content={
                          <div>
                            <PaddingContainer>
                              {`${first.locale('pt-br').format('DD [de] MMMM')}
                            a ${last.locale('pt-br').format('DD [de] MMMM')}`}
                            </PaddingContainer>
                            <PaddingContainer>
                              <b>
                                Rendimento:{' '}
                                {currencyFormatter.format(yieldValue, {
                                  code: 'BRL',
                                  format: '%s %v',
                                })}
                              </b>
                            </PaddingContainer>
                          </div>
                        }
                      >
                        <InfoIconWrap marginLeft={8}>
                          <InfoIcon />
                        </InfoIconWrap>
                      </TooltipBox>
                    </FlexRow>
                  </Color>
                </SimpleDisplay>
                <SimpleDisplay divider={false} label={invoiceCountLabel}>
                  <Color
                    color={
                      toReceive > 0
                        ? color
                        : handleThemeFromObject(theme, 'colors.darkGrey', '#717171')
                    }
                  >
                    {invoiceCount}
                  </Color>
                </SimpleDisplay>
              </DisplayRow>
            </Cell>
          ) : null}
        </Row>
        <Row>
          {!loading && data.length !== 0 ? (
            <BarChart
              data={formattedData}
              onClick={this.handleBarClick}
              width={chartWidth}
              padding={{
                top: 50,
                bottom: 60,
                left: labelPadding,
                right: screenSize === 'small' || screenSize === 'medium' ? 10 : 50,
              }}
              xDomain={this.getXDomain(data)}
              yDomain={this.getYDomain(data)}
              hoverEvents={screenSize !== 'small' && screenSize !== 'medium'}
              barColors={{
                pastColor: '#0ccde8',
                futureColor: handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2DE8BF'),
              }}
            />
          ) : (
            this.renderEmptyState(loading)
          )}
        </Row>
        {screenSize === 'small' || screenSize === 'medium' ? (
          <Row>
            <Cell>
              <DisplayRow>
                <SimpleDisplay divider label={toReceiveLabel} prefix="R$">
                  <Color color={handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2DE8BF')}>
                    {currencyFormatter.format(toReceive, { code: 'BRL', symbol: '', format: '%v' })}
                  </Color>
                </SimpleDisplay>
                <SimpleDisplay divider={false} label={invoiceCountLabel}>
                  <Color color={handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2DE8BF')}>
                    {invoiceCount}
                  </Color>
                </SimpleDisplay>
              </DisplayRow>
            </Cell>
          </Row>
        ) : null}
        <Column />
      </>
    );
  };

  render() {
    const { loading, data } = this.props;
    const { calendar, total } = data;
    return (
      <>
        <LoadingBlock loading={loading} opacity="0.5">
          <CardContent
            ref={element => {
              this.content = element;
            }}
            onMouseMove={this.onMouseMove}
          >
            {this.renderCard(calendar, total, loading)}
          </CardContent>
        </LoadingBlock>
      </>
    );
  }
}

Liquidations.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    total: PropTypes.shape({
      yield: PropTypes.number,
      invoice_count: PropTypes.number,
      to_receive: PropTypes.number,
    }),
    calendar: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  getCalendarData: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

Liquidations.defaultProps = {
  theme: {},
};

export default Liquidations;
