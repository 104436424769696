import Immutable from 'seamless-immutable';
import { toast } from 'liber-components/components/Toast';
import {
  normalizeAccounts,
  INVOICE_TABS,
  calculateAvarageDiscount,
  getDiscountValue,
  hasFilters,
} from '../utils';
import { handleSuccess, handleError } from '../../../vendor/Utils';
import {
  NUMBER_IN,
  CNPJ_EQ,
  EXPIRES_AT_EQ,
  EXPIRES_AT_GTEQ,
  EXPIRES_AT_LTEQ,
  STATUS_EQ,
} from '../components/constants';

const { AVAILABLE } = INVOICE_TABS;

export const initialState = Immutable({
  accounts: [],
  activeTab: AVAILABLE,
  bankAccountId: null,
  error: '',
  faceValue: '0',
  avarageDiscountRate: 0,
  filters: {
    [NUMBER_IN]: null,
    [CNPJ_EQ]: null,
    [EXPIRES_AT_EQ]: null,
    [EXPIRES_AT_GTEQ]: null,
    [EXPIRES_AT_LTEQ]: null,
    [STATUS_EQ]: null,
  },
  invoices: [],
  rateByPeriod: false,
  invoicesPerPage: [],
  loading: false,
  loadingDialog: false,
  pagination: {},
  quote: {},
  quoteAll: {},
  selectedInvoices: [],
  selectStatus: 'empty', // 'empty', 'single, 'multiple'
  unselectedInvoices: [],
  loadingAllPage: false,
  invoiceSubscribeReceived: false,
  invoiceSubscribeReportReceived: false,
  invoiceSubscribeStatus: false,
  invoiceSubscribeUrl: '',
  invoiceSubscribeReport: null,
  negotiations: [],
  emptyInvoices: false,
  orderedColumn: '',
  sortAsc: false,
  sortDesc: false,
  isAllOfPageAreSelected: false,
  isFiltered: false,
  advanceProgress: 0,
  advanceProgressAggregate: 0,
  advanceAction: 'invoice',
  requestedInvoicesCount: 0,
  downloadReportLoading: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INVOICE_SIMULATION_FETCH_INVOICES':
      return state.merge({
        error: '',
        loading: true,
        faceValue: '0',
        avarageDiscountRate: 0,
        invoiceSubscribeReceived: false,
        invoiceSubscribeStatus: false,
        negotiations: [],
        emptyInvoices: false,
      });
    case 'INVOICE_SIMULATION_FETCH_INVOICES_SUCCESS': {
      let newInvoicesPerPage = state.invoicesPerPage;
      let emptyInvoices;

      const {
        payload: { invoices, rateByPeriod, pagination, faceValue },
      } = action;

      invoices.forEach(invoice => {
        if (!newInvoicesPerPage.some(invoicePerPage => invoicePerPage.id === invoice.id)) {
          newInvoicesPerPage = [...newInvoicesPerPage, invoice];
        }
      });
      if (!invoices.length) {
        emptyInvoices = true;
      }

      const avarageDiscountRate = calculateAvarageDiscount(invoices, rateByPeriod);

      const invoicesId = invoices.map(invoice => invoice.id);
      const isAllOfPageAreSelected =
        invoicesId.every(id => state.selectedInvoices.includes(id)) &&
        state.selectedInvoices.length > 0;
      return state.merge({
        error: '',
        loading: false,
        invoices,
        rateByPeriod,
        pagination,
        faceValue,
        invoicesPerPage: newInvoicesPerPage,
        emptyInvoices,
        isAllOfPageAreSelected,
        avarageDiscountRate,
      });
    }
    case 'INVOICE_SIMULATION_FETCH_INVOICES_REJECTED': {
      const { message, status } = action.payload || {};
      toast(
        {
          message: 'Erro!',
          info: `Não foi possível listar ${
            status ? `os títulos ${status.toLowerCase()}.` : 'todos os títulos.'
          }`,
        },
        'error',
        5000,
      );
      return state.merge({
        error: message,
        invoices: [],
        emptyInvoices: true,
        loading: false,
      });
    }
    case 'ADVANCE_PROGRESS': {
      const {
        payload: { progress: advanceProgress, action: advanceAction },
      } = action;
      return state.merge({
        advanceAction,
        advanceProgress,
      });
    }
    case 'INVOICE_SIMULATION_UPDATE_PAGINATION':
      return state.merge({
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      });
    case 'INVOICE_SIMULATION_FILTER':
      return state.merge({
        filters: {
          ...action.payload.filters,
        },
        isFiltered: hasFilters(action),
      });
    case 'INVOICE_SIMULATION_CHANGE_TAB':
      return state.merge({
        activeTab: action.payload.tab,
        invoicesPerPage: [],
        orderedColumn: '',
        sortAsc: false,
        sortDesc: false,
        emptyInvoices: false,
      });
    case 'INVOICE_SIMULATION_QUOTE_ALL_INVOICES':
      return state.merge({
        error: '',
        loading: true,
      });
    case 'INVOICE_SIMULATION_QUOTE_ALL_SUCCESS': {
      const {
        payload: { quote, invoice_id: selectedInvoices },
      } = action;

      return state.merge({
        error: '',
        loading: false,
        selectedInvoices,
        unselectedInvoices: [],
        quoteAll: quote,
        quote: { ...quote, discount_value: getDiscountValue(action) },
        selectStatus: 'multiple',
        isAllOfPageAreSelected: true,
      });
    }
    case 'INVOICE_SIMULATION_QUOTE_ALL_REJECTED':
      return state.merge({
        error: action.payload.message,
        loading: false,
        selectStatus: 'empty',
      });
    case 'INVOICE_SIMULATION_QUOTE_ALL_ERROR': {
      const {
        payload: { message },
      } = action;

      toast({ message: 'Erro!', info: message }, 'error', 5000);

      return state.merge({
        error: message,
        loading: false,
        selectStatus: 'empty',
      });
    }
    case 'INVOICE_SIMULATION_DESELECT_ALL_INVOICES':
      return state.merge({
        selectedInvoices: [],
        unselectedInvoices: [],
        quote: {},
        quoteAll: {},
        selectStatus: 'empty',
        isAllOfPageAreSelected: false,
      });
    case 'INVOICE_SIMULATION_UPDATE_SELECTED_INVOICES': {
      let newStatus = 'single';
      if (state.selectStatus === 'multiple') {
        newStatus = 'multiple';
      }
      if (!action.payload.selectedInvoices.length) {
        newStatus = 'empty';
      }
      const invoicesId = state.invoices.map(invoice => invoice.id);
      const isAllOfPageAreSelected = invoicesId.every(id =>
        action.payload.selectedInvoices.includes(id),
      );
      return state.merge({
        selectedInvoices: action.payload.selectedInvoices,
        unselectedInvoices: action.payload.unselectedInvoices,
        quote: action.payload.quote,
        selectStatus: newStatus,
        isAllOfPageAreSelected,
      });
    }
    case 'INVOICE_SIMULATION_FETCH_BANK_ACCOUNTS':
      return state.merge({
        accounts: [],
      });
    case 'INVOICE_SIMULATION_FETCH_BANK_ACCOUNTS_SUCCESS': {
      const normalizedAccounts = normalizeAccounts(action.payload.accounts);
      const defaultAccount =
        normalizedAccounts.find(account => account.default === true) || normalizedAccounts[0];

      return state.merge({
        accounts: normalizedAccounts,
        bankAccountId: defaultAccount?.id,
      });
    }
    case 'INVOICE_SIMULATION_FETCH_BANK_ACCOUNTS_REJECTED':
      return state.merge({
        accounts: [],
        error: action.payload.message,
      });
    case 'INVOICE_SIMULATION_REQUEST_ANTICIPATION':
      return state.merge({
        loadingDialog: true,
        invoiceSubscribeUrl: '',
      });
    case 'INVOICE_SIMULATION_REQUEST_ANTICIPATION_SUCCESS': {
      const { selectedInvoices: requestedInvoices } = state;
      return state.merge({
        error: '',
        quote: {},
        quoteAll: {},
        requestedInvoicesCount: requestedInvoices.length,
        selectedInvoices: [],
        selectStatus: 'empty', // 'empty', 'single, 'multiple'
        unselectedInvoices: [],
      });
    }
    case 'INVOICE_SIMULATION_BEGIN_ANTICIPATION': {
      return state;
    }
    case 'INVOICE_SIMULATION_REQUEST_ANTICIPATION_REJECTED':
      return state.merge({
        error: action.payload.message,
      });
    case 'INVOICE_SIMULATION_REQUEST_DOWNLOAD':
      return state.merge({
        loadingAllPage: true,
        invoiceSubscribeReport: null,
        invoiceSubscribeReceived: false,
        invoiceSubscribeReportReceived: false,
        invoiceSubscribeStatus: false,
      });
    case 'INVOICE_SIMULATION_REQUEST_DOWNLOAD_SUCCESS':
      return state.merge({
        error: '',
      });
    case 'INVOICE_SIMULATION_REQUEST_DOWNLOAD_REJECTED':
      return state.merge({
        loadingAllPage: false,
        error: action.payload.message,
      });
    case 'INVOICE_SIMULATION_SELECT_BANK_ACCOUNT':
      return state.merge({
        bankAccountId: action.payload.account.id,
      });
    case 'INVOICE_SIMULATION_SUBSCRIBE_RECEIVED':
      return state.merge({
        invoiceSubscribeReceived: true,
        loadingDialog: false,
        invoiceSubscribeStatus: action.payload.status,
        invoiceSubscribeUrl: action.payload.url,
      });
    case 'INVOICE_REPORT_SIMULATION_SUBSCRIBE_RECEIVED':
      return state.merge({
        invoiceSubscribeReportReceived: true,
        loadingAllPage: false,
        invoiceSubscribeStatus: action.payload.status,
        invoiceSubscribeReport: action.payload.url,
      });
    case 'INVOICE_SIMULATION_FETCH_NEGOTIATIONS':
      return state.merge({
        error: '',
        loading: true,
        invoices: [],
        pagination: {},
        faceValue: '',
        invoicesPerPage: [],
      });
    case 'INVOICE_SIMULATION_FETCH_NEGOTIATIONS_SUCCESS': {
      return state.merge({
        error: '',
        loading: false,
        negotiations: action.payload.negotiations,
      });
    }
    case 'INVOICE_SIMULATION_FETCH_NEGOTIATIONS_REJECTED':
      return state.merge({
        error: action.payload.message,
        loading: false,
        negotiations: [],
      });
    case 'INVOICE_SIMULATION_SORT_COLUMN':
      return state.merge({
        orderedColumn: action.payload.column,
        sortAsc: action.payload.type === 'asc',
        sortDesc: action.payload.type === 'desc',
      });
    case 'VENDOR_INVOICES_REPORT_RECEIVED': {
      const { status, url, error_msg: errorMsg } = action.payload;
      if (status) {
        handleSuccess('Relatório gerado com sucesso', 'O download será iniciado automaticamente');
        window.location.assign(url);
      } else {
        handleError('Erro ao baixar o relatório de títulos', errorMsg);
      }
      return state.merge({
        downloadReportLoading: false,
      });
    }
    case 'ADMIN_REPORT_LOADING': {
      const { loading } = action.payload;
      return state.merge({
        downloadReportLoading: loading,
      });
    }
    default:
      return state;
  }
};
