import axios from 'axios';

// eslint-disable-next-line no-undef
const BASE_URL = SERVICE_API_URL;

export async function fetchCategories(token = '') {
  return axios.get(`${BASE_URL}/api/v1/admin/buyer_group_categories`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrganizationGroup(token = '', data) {
  return axios.post(`${BASE_URL}/api/v1/admin/buyer_organization_groups`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function editOrganizationGroup(token = '', data, id) {
  return axios.put(`${BASE_URL}/api/v1/admin/buyer_organization_groups/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchBuyerOrganizationGroup(token = '', id) {
  return axios.get(`${BASE_URL}/api/v1/admin/buyer_organization_groups/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
