import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  TextField,
  Typography,
  Icon,
} from 'liber-salti';

import SaltiThemeWrapper from '../../../SaltiThemeWrapper';
import * as S from './styles';

const BlockModal = ({ isOpen, onCloseModal, onBlockConfirm }) => {
  const [blockedReason, setBlockedReason] = useState('');

  const handleBlockSubmit = useCallback(() => {
    onBlockConfirm(blockedReason);
    onCloseModal();
  }, [blockedReason, onBlockConfirm]);

  return (
    <SaltiThemeWrapper>
      <Dialog open={isOpen} onClose={onCloseModal}>
        <DialogHeader onClose={onCloseModal} title="Bloquear fornecedor" />
        <DialogContent>
          <Typography color="textPrimary" gutterBottom>
            Escreva o motivo abaixo:
          </Typography>

          <TextField
            helperText="Obrigatório"
            label="Escreva um comentário"
            placeholder="Escreva um comentário"
            fullWidth
            size="large"
            data-testid="block-reason-input"
            required
            onChange={e => setBlockedReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="text" onClick={onCloseModal} data-testid="cancel-button">
            Cancelar
          </Button>
          <S.BlockButtonWrapper>
            <Button
              color="warning"
              variant="contained"
              endIcon={<Icon name="LockClosed" />}
              onClick={handleBlockSubmit}
              disabled={!blockedReason}
              data-testid="submit-button"
            >
              Bloquear fornecedor
            </Button>
          </S.BlockButtonWrapper>
        </DialogActions>
      </Dialog>
    </SaltiThemeWrapper>
  );
};

export default memo(BlockModal);

BlockModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onBlockConfirm: PropTypes.func,
};

BlockModal.defaultProps = {
  isOpen: false,
  onCloseModal: () => {},
  onBlockConfirm: () => {},
};
