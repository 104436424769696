import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'liber-components';
import { Container, FlashAlert } from './styles';

const messages = {
  default: `As operações de antecipação ocorrem das <strong>9h às 15h</strong> (horário de Brasília) de
            <strong>segunda a sexta</strong>. Caso seja aprovada a solicitação até as
            <strong>15h</strong> o recebimento dos valores ocorre no mesmo dia; entre as
            <strong>15h e 18h</strong>, o recebimento ocorre no próximo dia útil.`,

  brf: `As operações de antecipação ocorrem das <strong>13h às 16h30</strong> (horário de Brasília) nas
        <strong>terças e quintas</strong>. Caso seja aprovada a solicitação até as
        <strong>16h30</strong> o valor estará disponível em sua conta no próximo dia útil.`,

  hercosul: `As operações de antecipação ocorrem das <strong>13h às 16h30</strong> (horário de Brasília) nas
            <strong>terças e quintas</strong>. Caso seja aprovada a solicitação até as
            <strong>16h30</strong> o valor estará disponível em sua conta no próximo dia útil.`,

  oba_hortifruti: `As operações de antecipação ocorrem das <strong>9h às 14h30</strong> (horário de Brasília) de
                   <strong>segunda a sexta</strong>. Caso seja aprovada a solicitação até as
                   <strong>14h30</strong> o recebimento dos valores ocorre no mesmo dia; entre
                   <strong>14h30 e 18h</strong> o recebimento ocorre no próximo dia útil.`,

  daimler: `As operações de antecipação ocorrem das <strong>9h às 15h</strong> (horário de Brasília) de
            <strong>segunda a sexta</strong>. Caso seja aprovada a solicitação até as
            <strong>15h</strong> o recebimento dos valores ocorre no mesmo dia.`,
};

function ComunicationCard({ identifier, customMessage }) {
  const message = customMessage ?? messages[identifier] ?? messages.default;

  return (
    <Container>
      <FlashAlert>
        <Text large dangerouslySetInnerHTML={{ __html: `<b>Atenção:</b> ${message}` }} />
      </FlashAlert>
    </Container>
  );
}

ComunicationCard.propTypes = {
  identifier: PropTypes.string.isRequired,
  customMessage: PropTypes.string,
};

ComunicationCard.defaultProps = {
  customMessage: null,
};

export default ComunicationCard;
