import Immutable from 'seamless-immutable';

const initialState = Immutable({
  address: {
    address: '',
    number: '',
    complement: '',
    district: '',
    zipCode: '',
    kind: '',
    city: '',
    state: '',
    country: '',
    id: null,
  },
  index: null,
  errors: {},
  loading: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_HIDRATE_FORM':
      return state.merge(action.payload, { deep: true });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_CLOSE_MODAL_FORM':
      return initialState;
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_PENDING':
      return state.merge({ loading: 'submit', errors: {} });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_ZIPCODE_SUCCESS':
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_ZIPCODE_REJECT':
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_SUCCESS':
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_ERROR':
      return state.merge({ loading: '' });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_EDIT_FIELD':
      return state.merge({ ...action.payload, errors: {} }, { deep: true });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_VALIDATION_ERROR':
      return state.merge({
        errors: action.payload,
      });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_ZIPCODE_PENDING':
      return state.merge({ loading: 'zipCode', errors: {} });
    default:
      return state;
  }
};
