import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import { handleThemeFromObject } from 'liber-components';
import {
  Date,
  MarkContainer,
  SummaryMarkLine as MarkLine,
  SummaryContent as Content,
  SummaryContainer as Container,
  SummaryHeader as Header,
  AccordionLabel as Label,
  SummaryValue as Value,
  AccordionInfo as Info,
  PlusIcon,
  MinusIcon,
  DotButton,
  EntryBackground,
  ActionOverlay,
} from './StatementEntry-style';

class SummaryAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleButtonClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { label, value, plus, minus, children, theme } = this.props;
    const { open } = this.state;
    const childrenArray = React.Children.toArray(children);
    let color;
    if (plus) color = handleThemeFromObject(theme, 'colors.actionBlue', '#4CBAFF');
    if (minus) color = '#FF6566';
    return (
      <>
        <EntryBackground />
        <Date isSummary />
        <MarkContainer isSummary>
          <MarkLine top />
          <DotButton open={open} empty={childrenArray.length === 0} />
          <MarkLine type={childrenArray.length > 0 ? 'dotted' : 'solid'} open={open} />
        </MarkContainer>
        <Container separator={false}>
          <Header>
            <Info>
              <Label>{label}</Label>
              <Value color={color}>
                {plus ? <PlusIcon /> : null}
                {minus ? <MinusIcon /> : null}
                {currencyFormatter.format(value, { code: 'BRL', format: '%s%v' })}
              </Value>
            </Info>
          </Header>
          {childrenArray.length > 0 ? <Content open={open}>{children}</Content> : null}
        </Container>
        {childrenArray.length > 0 ? <ActionOverlay onClick={this.handleButtonClick} /> : null}
      </>
    );
  }
}

SummaryAccordion.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  plus: PropTypes.bool,
  minus: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.any]),
  theme: PropTypes.objectOf(PropTypes.any),
};

SummaryAccordion.defaultProps = {
  label: '',
  value: 0,
  plus: false,
  minus: false,
  children: [],
  theme: {},
};

export default SummaryAccordion;
