import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  Modal,
  SuccessButton,
  ModalDangerButton,
  TooltipBox,
} from 'liber-components';
import { connect } from 'react-redux';

import {
  submitProposal,
  hideConfirmation,
  updateSkipOperations,
} from '../../actions/proposalAction';
import {
  DialogContent,
  DialogHeader,
  DialogBody,
  Desc,
  FunderTag,
  Info,
  TriangleNotice,
  TableHeaderColumn,
  TableRowColumn,
  Total,
  ButtonContainer,
  TableContainer,
  TooltipContentCustom,
  CheckboxCustom,
  ButtonNewProposta,
  ToggleButton,
  CollapseContent,
  Input,
  RadioContainer,
  RadioLabel,
} from './Dialogs.styles';
import {
  TooltipContent,
  TooltipItemValue,
  TooltipItemMonth,
  TooltipBar,
  TooltipRow,
} from '../Summary/Summary.styles';

const mapTaxedMonths = months => (
  <TooltipRow key={months.amount} selected>
    <TooltipItemMonth>{months.month}</TooltipItemMonth>
    <TooltipBar />
    <TooltipItemValue>{months.amount}</TooltipItemValue>
  </TooltipRow>
);

const mapNegotiations = negotiation => (
  <TableRow key={negotiation.id} selected>
    <TableRowColumn>{negotiation.number}</TableRowColumn>
    <TableRowColumn>{negotiation.monthly_profitability}</TableRowColumn>
    <TableRowColumn>{negotiation.advance}</TableRowColumn>
    <TableRowColumn>{negotiation.deadline}</TableRowColumn>
  </TableRow>
);
const handleSubmit =
  (negotiations, funderId, submit, skipAcceptance, specialRate, specialRateType) => () => {
    const isValidSpecialRate = specialRate && specialRate !== '0.0';
    const rateToSubmit = isValidSpecialRate ? specialRate : undefined;
    const rateTypeToSubmit = isValidSpecialRate ? specialRateType : undefined;

    submit(
      funderId,
      negotiations.map(negotiation => negotiation.id),
      skipAcceptance,
      rateToSubmit,
      rateTypeToSubmit,
    );
  };
export const Confirmation = ({
  show,
  close,
  amount,
  negotiations,
  submit,
  funder: funderId,
  funders,
  taxed,
  months,
  operationsUpdateSkip,
  skipAcceptance,
  specialRate: initialSpecialRate,
  specialRateType: initialSpecialRateType,
}) => {
  const [specialRate, setSpecialRate] = useState(initialSpecialRate || '');
  const [specialRateType, setSpecialRateType] = useState(initialSpecialRateType || '');

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSpecialRateChange = value => {
    setSpecialRate(value);
  };

  const handleSpecialRateTypeChange = type => {
    setSpecialRateType(type);
  };

  const funder = funders.find(item => item.id === funderId);
  const handleCheck = () => {
    operationsUpdateSkip(!skipAcceptance);
  };

  let info;
  if (taxed) {
    info = (
      <TooltipBox
        fixed
        mount="top"
        label="Tributação Mensal"
        content={<TooltipContent>{months.map(mapTaxedMonths)}</TooltipContent>}
      >
        <Info>
          <TriangleNotice />
          Ao realizar esta proposta, o investidor estará sujeito ao pagamento do DARF.
        </Info>
      </TooltipBox>
    );
  }
  return (
    <Modal show={show} onLeaved={close}>
      <DialogContent>
        <DialogHeader>Confirmar propostas?</DialogHeader>
        <DialogBody>
          <Desc>Você está prestes a realizar as seguintes propostas pelo investidor</Desc>
          <FunderTag>{funder ? funder.name : ''}</FunderTag>
          <CheckboxCustom checked={skipAcceptance} onChange={handleCheck}>
            <TooltipContentCustom>
              Fazer propostas com aceite automático do fornecedor
            </TooltipContentCustom>
          </CheckboxCustom>
          {info}
          <TableContainer>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderColumn>Nº do Título</TableHeaderColumn>
                  <TableHeaderColumn>Rentabilidade</TableHeaderColumn>
                  <TableHeaderColumn>Investimento</TableHeaderColumn>
                  <TableHeaderColumn>Vencimento</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>{negotiations.map(mapNegotiations)}</TableBody>
            </Table>
          </TableContainer>

          <div className="col-12 col-md-4">
            <ButtonNewProposta>
              <ToggleButton
                onClick={handleToggle}
                role="button"
                aria-expanded={isOpen}
                aria-controls="collapseExample"
              >
                Taxa especial <i className="fa fa-chevron-down" />
              </ToggleButton>
              <CollapseContent isOpen={isOpen} id="tentabilidade_mensal">
                <RadioContainer>
                  <RadioLabel>
                    <input
                      type="radio"
                      value="taxa_rendimento"
                      checked={specialRateType === 'taxa_rendimento'}
                      onChange={() => handleSpecialRateTypeChange('taxa_rendimento')}
                    />
                    Taxa de Rendimento
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      value="rentabilidade_mensal"
                      checked={specialRateType === 'rentabilidade_mensal'}
                      onChange={() => handleSpecialRateTypeChange('rentabilidade_mensal')}
                    />
                    Rentabilidade Mensal
                  </RadioLabel>
                </RadioContainer>
                <Input
                  type="text"
                  className="form-control mask-percentage"
                  placeholder="Taxa Especial"
                  value={specialRate}
                  onChange={e => handleSpecialRateChange(e.target.value)}
                />
              </CollapseContent>
            </ButtonNewProposta>
          </div>
          <Total>
            Valor Total: <span>{amount}</span>
          </Total>
          <ButtonContainer>
            <ModalDangerButton onClick={close}>Cancelar</ModalDangerButton>
            <SuccessButton
              onClick={handleSubmit(
                negotiations,
                funderId,
                submit,
                skipAcceptance,
                specialRate,
                specialRateType,
              )}
            >
              Confirmar Propostas
            </SuccessButton>
          </ButtonContainer>
        </DialogBody>
      </DialogContent>
    </Modal>
  );
};

Confirmation.propTypes = {
  funder: PropTypes.string.isRequired,
  taxed: PropTypes.bool.isRequired,
  skipAcceptance: PropTypes.bool.isRequired,
  specialRate: PropTypes.string.isRequired,
  specialRateType: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      month: PropTypes.string,
      amout: PropTypes.string,
    }),
  ).isRequired,
  amount: PropTypes.string.isRequired,
  negotiations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      monthly_profitability: PropTypes.string,
      advance: PropTypes.string,
      deadline: PropTypes.string,
      actions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
      number: PropTypes.string,
      amount: PropTypes.string,
      rate: PropTypes.string,
      expires_at: PropTypes.string,
    }),
  ).isRequired,
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  show: PropTypes.bool,
  close: PropTypes.func,
  submit: PropTypes.func,
  operationsUpdateSkip: PropTypes.func,
};

Confirmation.defaultProps = {
  show: false,
  close: () => {},
  submit: () => {},
  operationsUpdateSkip: () => {},
};

const mapStateToProps = state => ({
  ...state.multiProposal.summary.summary,
  show: state.multiProposal.proposals.showConfirmation,
  funder: state.multiProposal.summary.funder,
  funders: state.multiProposal.summary.funders,
  skipAcceptance: state.multiProposal.summary.skipAcceptance,
  specialRate: state.multiProposal.summary.specialRate,
  specialRateType: state.multiProposal.summary.specialRateType,
});

const mapDispatchToProps = {
  submit: submitProposal,
  close: hideConfirmation,
  operationsUpdateSkip: updateSkipOperations,
};
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
