import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { PrimaryButton } from 'liber-components';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import * as S from './styles';
import { ACTIONS_LABEL, ACTIONS_TYPE, KIND } from '../../constants';
import FileUploadInput from '../../../../../../common-components/FileUploadInput';
import LoadingButton from '../../../../../../common-components/Buttons/LoadingButton';
import { fetchFunders, uploadBatchBlockUnblockFile } from '../../../../api';
import { convertToCamelCase, handleError } from '../../../../../../vendor/Utils';
import { BatchBlockUnblockModalContext } from '../../context';

const Content = () => {
  const [fundersOptions, setFundersOptions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(ACTIONS_TYPE.BLOCK.value);
  const [selectedFunderId, setSelectedFunderId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  const {
    token,
    handleLeaveModal,
    exampleSheetUrl,
    submitBatchBlockUnblock,
    handleSelectedCnpjs,
    roomId,
    handleIsBatchBlockUnblock,
  } = useContext(BatchBlockUnblockModalContext);

  const getFunders = async () => {
    try {
      const { data } = await fetchFunders(token);

      setFundersOptions(convertToCamelCase(data.data));
    } catch {
      handleError('Ocorreu um erro ao requisitar os investidores.');
    }
  };

  useEffect(() => {
    getFunders();
  }, []);

  const handleUploadedFile = event => {
    const [file] = event?.target?.files;
    setSelectedFile(file);
  };

  const handleInputClick = event => {
    event.stopPropagation();
    inputRef.current.click();
  };

  const getSelectedFunder = id => fundersOptions.find(funder => funder.id === id);

  const buildPayload = () => {
    const selectedFunder = getSelectedFunder(selectedFunderId);
    return {
      action: selectedAction,
      target: {
        id: selectedFunder.id,
        name: selectedFunder.tradeName,
        kind: KIND.FUNDER,
      },
    };
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const { data } = await uploadBatchBlockUnblockFile(token, selectedFile, roomId);

      handleIsBatchBlockUnblock(true);
      handleSelectedCnpjs(data.data);
      submitBatchBlockUnblock(buildPayload());
    } catch (error) {
      handleError('Ocorreu um erro ao enviar o arquivo. Tente novamente mais tarde.');
    } finally {
      setIsLoading(false);
    }
  };

  const isConfirmationDisabled = useMemo(() => {
    const isFormValid = selectedAction && selectedFile && selectedFunderId;
    return !isFormValid;
  }, [selectedAction, selectedFile, selectedFunderId]);

  return (
    <S.Wrapper>
      <S.Title>Bloqueio/desbloqueio em lote</S.Title>
      <div>
        <S.SubTitle>Selecione o tipo de ação</S.SubTitle>
        {Object.values(ACTIONS_TYPE).map(({ value, label }) => (
          <S.Radio
            value={value}
            checked={value === selectedAction}
            onChange={event => setSelectedAction(event.target.value)}
            id={value}
            key={value}
          >
            {label}
          </S.Radio>
        ))}
      </div>
      <div>
        <S.SubTitle>Selecione o investidor</S.SubTitle>
        <S.Select
          hintText="Selecione o investidor"
          value={selectedFunderId}
          onChange={setSelectedFunderId}
          searchable
        >
          {fundersOptions.map(({ id, tradeName }) => (
            <select key={id} value={id}>
              {tradeName}
            </select>
          ))}
        </S.Select>
      </div>
      <FileUploadInput
        onClick={handleInputClick}
        ref={inputRef}
        accept=".xlsx"
        fileName={selectedFile?.name}
        handleUploadedFile={handleUploadedFile}
      />
      <S.ExampleFileRow>
        <S.DownloadLink href={exampleSheetUrl}>
          <Icon path={mdiDownload} color="#009DFF" size={0.5} />
          baixar modelo de planilha
        </S.DownloadLink>
      </S.ExampleFileRow>
      <S.ButtonsRow>
        <S.SecondaryButton onClick={() => handleLeaveModal(false)}>Cancelar</S.SecondaryButton>
        <LoadingButton
          ButtonComponent={PrimaryButton}
          size="large"
          disabled={isConfirmationDisabled}
          loading={isLoading}
          onClick={handleConfirm}
        >
          {ACTIONS_LABEL[selectedAction]}
        </LoadingButton>
      </S.ButtonsRow>
    </S.Wrapper>
  );
};

export default Content;
