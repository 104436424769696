import Immutable from 'seamless-immutable';

const initialState = Immutable({
  token: '',
  tokenError: '',
  flashes: [],
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOGIN/CONFIRMATION_TOKEN/CHANGE_TOKEN':
      return state.merge({ token: action.payload });
    case 'LOGIN/CONFIRMATION_TOKEN/SUBMIT_FORM/FAILURE':
      return state.merge({ tokenError: action.payload });
    default:
      return state;
  }
};
