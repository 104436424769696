import styled, { css } from 'styled-components';
import {
  PrimaryButton as RawPrimaryButton,
  Tabs as RawTabs,
  handleThemeFromObject,
} from 'liber-components';

const getTheme = (key, fallback) =>
  css`
    ${({ theme }) => handleThemeFromObject(theme, key, fallback)}
  `;

export const ScreenTitleRow = styled.div`
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.13px;
  color: ${getTheme('colors.primary', '#009dff')};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 32px;
`;

export const Subtitle = styled.div`
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.06px;
  color: #798e9b;

  ${({ strong }) =>
    strong &&
    css`
      font-weight: 500;
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`;

export const PrimaryButton = styled(RawPrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`;

export const Tabs = styled(RawTabs).attrs(() => ({ version: 2, strongBorder: true }))``;

export const DisabledBootstrapBorders = styled.div`
  div > div > div > div > div {
    box-sizing: content-box !important;
  }
`;

export const AlignRightNoWrap = styled.div`
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ResetSvgMargin = styled.div`
  & ~ svg {
    margin-left: 0px !important;
  }
`;
