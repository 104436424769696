import styled from 'styled-components';
import {
  Card as CardRaw,
  CardHeader as CardHeaderRaw,
  SuccessLink,
} from 'liber-components/components/Card';
import { media } from 'liber-components/components/Util';

export const CardHeader = styled(CardHeaderRaw)`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: unset;
  ${media.large`
    height: 48px;
  `}
`;

export const CardFullWidth = styled(CardRaw)`
  width: 100%;
  padding: 20px;
  margin-bottom: 25px;
  flex-grow: 1;
  overflow: unset;
  & ${SuccessLink} {
    color: #fff !important;
  }
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const GraphHeader = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const GraphTitle = styled.div`
  color: #263238;
  font-size: 18px;
  font-weight: 700;
`;

export const LinkButton = styled.button`
  background-color: transparent;
  color: #009dff;
  text-decoration: underline;
  border: none;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled(Flex)`
  flex-direction: column;
  gap: 15px;
  align-items: start;
`;

export const GraphFilter = styled(Flex)`
  gap: 20px;
`;
