import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, DropdownMenu } from 'reactstrap';

import {
  DropdownToggle,
  Button,
  UncontrolledButtonDropdown,
  DropdownItem,
} from './MultiProposal.styles';

const DropDownMenu = ({ disableButton, actions }) => {
  const links = {};
  if (!actions) return null;
  if (actions.offer && actions.offer.path) {
    links.offer = {
      href: actions.offer.path,
      'data-method': actions.offer.method,
    };
  }
  if (actions.view && actions.view.path) {
    links.view = {
      href: actions.view.path,
      'data-method': actions.view.method,
    };
  }
  if (actions.open_negotiation && actions.open_negotiation.path) {
    links.open_negotiation = {
      href: actions.open_negotiation.path,
      'data-method': actions.open_negotiation.method,
    };
  }
  if (actions.edit && actions.edit.path) {
    links.edit = {
      href: actions.edit.path,
      'data-method': actions.edit.method,
    };
  }
  if (actions.cancel && actions.cancel.path) {
    links.cancel = {
      href: actions.cancel.path,
      'data-method': actions.cancel.method,
    };
  }
  return (
    <ButtonGroup size="sm">
      {links.offer ? (
        <Button color="success" disabled={disableButton} {...links.offer}>
          $ Fazer proposta
        </Button>
      ) : null}

      <UncontrolledButtonDropdown size="sm" direction="down">
        <DropdownToggle caret outline color="primary">
          ☰{'  '}
        </DropdownToggle>
        <DropdownMenu>
          {links.view ? (
            <DropdownItem className="dropdown-item btn btn-primary" {...links.view}>
              <i className="fa fa-eye" />
              Exibir
            </DropdownItem>
          ) : null}
          {links.open_negotiation ? (
            <DropdownItem className="dropdown-item btn btn-primary" {...links.open_negotiation}>
              <i className="fa fa-calendar" />
              Abrir Negociação
            </DropdownItem>
          ) : null}
          {links.edit ? (
            <DropdownItem className="dropdown-item  btn btn-primary" {...links.edit}>
              <i className="fa fa-edit" />
              Editar
            </DropdownItem>
          ) : null}
          {links.cancel ? (
            <DropdownItem className="dropdown-item  btn btn-danger" {...links.cancel}>
              <i className="fa fa-times" />
              Cancelar
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </ButtonGroup>
  );
};

DropDownMenu.propTypes = {
  disableButton: PropTypes.bool,
  actions: PropTypes.shape({
    view: PropTypes.shape({
      path: PropTypes.string,
      method: PropTypes.string,
    }),
    cancel: PropTypes.shape({
      path: PropTypes.string,
      method: PropTypes.string,
    }),
    edit: PropTypes.shape({
      path: PropTypes.string,
      method: PropTypes.string,
    }),
    offer: PropTypes.shape({
      path: PropTypes.string,
      method: PropTypes.string,
    }),
    open_negotiation: PropTypes.shape({
      path: PropTypes.string,
      method: PropTypes.string,
    }),
  }),
};

DropDownMenu.defaultProps = {
  disableButton: false,
  actions: null,
};

export default DropDownMenu;
