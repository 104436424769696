import React from 'react';
import PropTypes from 'prop-types';
import { ShowBuyerOrganizationGroup } from '../../features/buyer-organization-groups';

const BuyerOrganizationGroup = ({ token, buyerOrganizationGroupId }) => (
  <ShowBuyerOrganizationGroup token={token} buyerOrganizationGroupId={buyerOrganizationGroupId} />
);

BuyerOrganizationGroup.propTypes = {
  token: PropTypes.string.isRequired,
  buyerOrganizationGroupId: PropTypes.number.isRequired,
};

export default BuyerOrganizationGroup;
