import React from 'react';
import { InvestorAccount } from '../Account';
import { InvestorAddress } from '../Address';
import { InvestorContract } from '../Contract';
import { InvestorBankAccount } from '../BankAccount';

const options = {
  investor: {
    cadastro: { label: 'Cadastro', content: <InvestorAccount />, pos: 0 },
    enderecos: { label: 'Endereços', content: <InvestorAddress />, pos: 1 },
    contas: { label: 'Contas', content: <InvestorBankAccount />, pos: 2 },
    contratos: { label: 'Contratos', content: <InvestorContract />, pos: 3 },
  },
};

export default options;
