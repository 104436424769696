import React, { useState, useEffect } from 'react';
import { TooltipBox } from 'liber-components';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { uniq } from 'lodash';
import TransferList from '../TransferList';
import { Container, TransferListsContainer, Button, CenterContainer } from './styles';
import { convertToCamelCase } from '../../../../vendor/Utils';

const CreateOrganizationsGroup = ({ organizations, currentOrganizations }) => {
  const [selectedAvailableOrgsIds, setSelectedAvailableOrgsIds] = useState([]);
  const [availableOrganizations, setAvailableOrganizations] = useState(
    convertToCamelCase(organizations),
  );
  const [operatorOrganizations, setOperatorOrganizations] = useState(
    convertToCamelCase(currentOrganizations) || [],
  );
  const [selectedOperatorOrganizationsIds, setSelectedOperatorOrganizationsIds] = useState([]);

  const moveLeftToRight = () => {
    const arrayBuffer = selectedAvailableOrgsIds.map(organizationId =>
      availableOrganizations.find(i => i.id === organizationId),
    );
    setOperatorOrganizations(item => [...arrayBuffer, ...item]);

    const newSelected = [];
    availableOrganizations.forEach(element => {
      if (arrayBuffer.indexOf(element) === -1) newSelected.push(element);
    });

    setAvailableOrganizations(newSelected);
    setSelectedAvailableOrgsIds([]);
  };

  const moveRightToLeft = () => {
    const arrayBuffer = selectedOperatorOrganizationsIds.map(OperatorOrganizationId =>
      operatorOrganizations.find(i => i.id === OperatorOrganizationId),
    );
    setAvailableOrganizations(item => [...arrayBuffer, ...item]);

    const newSelected = [];
    operatorOrganizations.forEach(element => {
      if (arrayBuffer.indexOf(element) === -1) newSelected.push(element);
    });

    setOperatorOrganizations(newSelected);
    setSelectedOperatorOrganizationsIds([]);
  };

  const handleSelectAvailableOrganizations = (e, allSelected, batchAction) => {
    let arrayBuffer = [...selectedAvailableOrgsIds];
    if (batchAction) {
      selectedAvailableOrgsIds.forEach(id => {
        const index = arrayBuffer.indexOf(id);
        if (allSelected) {
          arrayBuffer.splice(index, 1);
        } else if (index === -1) arrayBuffer = [...arrayBuffer, id];
      });
    } else {
      const index = arrayBuffer.indexOf(e[0]);
      if (index !== -1) {
        arrayBuffer.splice(index, 1);
      } else {
        arrayBuffer = [...arrayBuffer, ...e];
      }
    }
    arrayBuffer = uniq(arrayBuffer);
    setSelectedAvailableOrgsIds(arrayBuffer);
  };

  const handleSelectOperatorOrganizations = (e, allSelected, batchAction) => {
    let arrayBuffer = [...selectedOperatorOrganizationsIds];
    if (batchAction) {
      selectedOperatorOrganizationsIds.forEach(id => {
        const index = arrayBuffer.indexOf(id);
        if (allSelected) {
          arrayBuffer.splice(index, 1);
        } else if (index === -1) arrayBuffer = [...arrayBuffer, id];
      });
    } else {
      const index = arrayBuffer.indexOf(e[0]);
      if (index !== -1) {
        arrayBuffer.splice(index, 1);
      } else {
        arrayBuffer = [...arrayBuffer, ...e];
      }
    }
    arrayBuffer = uniq(arrayBuffer);
    setSelectedOperatorOrganizationsIds(arrayBuffer);
  };

  useEffect(() => {
    const organizationIdsInput = document.querySelector('.organization-ids-input');
    organizationIdsInput.value = operatorOrganizations.map(({ id }) => id).join(',');
  }, [operatorOrganizations]);

  return (
    <>
      <Container>
        <TransferListsContainer>
          <TransferList
            organizations={availableOrganizations}
            selectedOrganizationsIds={selectedAvailableOrgsIds}
            organizationsCount={availableOrganizations.length}
            handleSelect={handleSelectAvailableOrganizations}
          />
          <CenterContainer>
            <TooltipBox mount="top" fixed content="Remover do grupo">
              <Button onClick={moveRightToLeft} active>
                <Icon path={mdiChevronLeft} />
              </Button>
            </TooltipBox>
            <TooltipBox mount="bottom" fixed content="Adicionar ao grupo">
              <Button data-testid="transferButton" onClick={moveLeftToRight} active>
                <Icon path={mdiChevronRight} />
              </Button>
            </TooltipBox>
          </CenterContainer>
          <TransferList
            organizations={operatorOrganizations}
            selectedOrganizationsIds={selectedOperatorOrganizationsIds}
            organizationsCount={operatorOrganizations.length}
            handleSelect={handleSelectOperatorOrganizations}
          />
        </TransferListsContainer>
      </Container>
    </>
  );
};

CreateOrganizationsGroup.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
  currentOrganizations: PropTypes.arrayOf(PropTypes.shape({})),
};

CreateOrganizationsGroup.defaultProps = {
  organizations: [],
  currentOrganizations: [],
};

export default CreateOrganizationsGroup;
