import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'liber-components';
import Icon from '@mdi/react';
import { mdiClose, mdiDownload } from '@mdi/js';
import moment from 'moment';
import {
  Header,
  Title,
  IconButton,
  Content,
  ValuesContainer,
  LoadingBunny,
  LoadingContainer,
  SpacingContainer,
  DisableBootstrap,
  DownloadLink,
} from './InvoiceDialog.styles';
import ValueDisplay from './components/ValueDisplay';
import { fetchInvoice } from './actions/InvoiceDialogActions';
import { formatMoney } from '../withdraw/moneyHandler';

const STATUS_MAP = {
  verificado: {
    type: 'primary',
    valueLabel: null,
    label: 'Disponível',
  },
  cancelada: {
    type: 'negative',
    valueLabel: 'Antecipado',
    label: 'Não Antecipado',
  },
  concluida: {
    type: 'positive',
    valueLabel: 'Antecipado',
    label: 'Antecipado',
  },
  aguardando_formalizacao: {
    type: 'neutral',
    valueLabel: 'Solicitado',
    label: 'Solicitado',
  },
  em_processamento: {
    type: 'neutral',
    valueLabel: 'Solicitado',
    label: 'Solicitado',
  },
  bloqueado: {
    type: 'negative',
    valueLabel: 'Bloqueado',
    label: 'Bloqueado',
  },
};

const STATUS_STANDARD = {
  label: 'Disponível',
  type: 'primary',
};

const renderInvoiceContent = invoice => {
  const {
    faceValue,
    status,
    agreedRate,
    expiresAt,
    deadline,
    taxDocumentUrl,
    emitterCnpj,
    number,
    netValue,
    funder,
    visualRepresentationFileUrl,
    auctionedAndNegotiable,
  } = invoice;

  const discountRate = (agreedRate * 100).toFixed(4).replace('.', ',');
  const { valueLabel } = STATUS_MAP[status] || STATUS_STANDARD;

  return (
    <>
      <DisableBootstrap />
      <SpacingContainer>
        <div>
          <DownloadLink
            disabled={!taxDocumentUrl}
            download={`Titulo-${number}`}
            href={taxDocumentUrl || '#'}
          >
            <Icon path={mdiDownload} />
            Nota Fiscal
          </DownloadLink>
          {visualRepresentationFileUrl && (
            <DownloadLink download={`Duplicata-${number}`} href={visualRepresentationFileUrl}>
              <Icon path={mdiDownload} />
              Duplicata
            </DownloadLink>
          )}
        </div>
      </SpacingContainer>
      {funder && (
        <ValueDisplay label="Investidor" subValue={`CNPJ ${funder.cnpj}`}>
          {funder.name}
        </ValueDisplay>
      )}
      <ValueDisplay label="CNPJ Emissor">{emitterCnpj}</ValueDisplay>
      <ValuesContainer>
        <ValueDisplay label="Valor Bruto">{`R$ ${formatMoney(faceValue)}`}</ValueDisplay>
        <ValueDisplay label="Taxa de Desconto">
          {auctionedAndNegotiable ? 'A negociar' : `${discountRate}% a.m.`}
        </ValueDisplay>
        <ValueDisplay label="Vencimento">{moment(expiresAt).format('DD/MM/YYYY')}</ValueDisplay>
        <ValueDisplay label="Prazo">{`${deadline} dias`}</ValueDisplay>
        {valueLabel && (
          <ValueDisplay label={`Valor ${valueLabel}`}>
            {netValue ? `R$ ${formatMoney(netValue)}` : '***'}
          </ValueDisplay>
        )}
      </ValuesContainer>
    </>
  );
};

const getInvoiceText = invoice => (invoice ? `- ${invoice.number}` : '');

export const InvoiceDialog = ({
  open,
  onClose,
  invoiceId,
  borderoId,
  invoice,
  loading,
  token,
  getInvoice,
  isAdmin,
  vendorId,
  operatorInfo,
}) => {
  React.useEffect(() => {
    if (invoiceId) {
      getInvoice(invoiceId, token, borderoId, isAdmin, vendorId);
    }
  }, [invoiceId, operatorInfo]);

  return (
    <Modal show={open} onLeaved={onClose}>
      {loading && (
        <LoadingContainer>
          <LoadingBunny />
        </LoadingContainer>
      )}
      {!loading && invoice ? (
        <>
          <Header>
            <Title>{`Título ${getInvoiceText(invoice)}`}</Title>
            <IconButton onClick={onClose}>
              <Icon path={mdiClose} fill="#4b6f85" />
            </IconButton>
          </Header>
          <Content>{renderInvoiceContent(invoice, isAdmin)}</Content>
        </>
      ) : null}
    </Modal>
  );
};

InvoiceDialog.propTypes = {
  invoiceId: PropTypes.number,
  borderoId: PropTypes.number,
  invoice: PropTypes.shape({
    number: PropTypes.string,
    status: PropTypes.string,
    funder: PropTypes.shape({
      name: PropTypes.string,
      cnpj: PropTypes.string,
    }),
    mappedStatus: PropTypes.string,
    faceValue: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    netValue: PropTypes.number,
    agreedRate: PropTypes.number,
    deadline: PropTypes.string,
    taxDocumentUrl: PropTypes.string,
    visualRepresentationFileUrl: PropTypes.string,
  }),
  selectedInvoice: PropTypes.shape({
    mappedStatus: PropTypes.string,
    status: PropTypes.string,
    blockReason: PropTypes.string,
    notEligibleForAnticipationReason: PropTypes.string,
  }),
  onClose: PropTypes.func,
  getInvoice: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  token: PropTypes.string,
  vendorId: PropTypes.number,
  operatorInfo: PropTypes.object,
  isAdmin: PropTypes.bool,
};

InvoiceDialog.defaultProps = {
  invoiceId: null,
  borderoId: null,
  invoice: null,
  selectedInvoice: null,
  onClose: () => {},
  getInvoice: () => {},
  open: false,
  loading: false,
  token: '',
  vendorId: null,
  operatorInfo: {},
  isAdmin: false,
};

const mapStateToProps = ({ invoiceDialog: { loading, invoice } }) => ({ invoice, loading });

const mapDispatchToProps = {
  getInvoice: fetchInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDialog);
