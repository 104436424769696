import styled from 'styled-components';
import { handleThemeFromObject as getTheme, PrimaryButton, LoadingBunny } from 'liber-components';

export const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  * {
    font-family: Roboto;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button:not(:last-child) {
    margin-right: 12px;
  }
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
  color: #6287a7;
`;

export const TimelineContainer = styled.div`
  width: 64px;
  display: flex;
`;

export const Timeline = styled.div`
  width: 4px;
  background-color: #405f711f;
  border-radius: 2px;
  margin-left: 22px;
  margin-bottom: 32px;
`;

export const List = styled.div`
  width: 100%;
  position: relative;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  width: 167px;
  text-transform: uppercase;
  padding: 0 16px 0 16px;
`;
