import axios from 'axios';
import moment from 'moment';
import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';

import errorMsg from './messages';
import { nextStep, setCompleted } from './progressBar';
// eslint-disable-next-line import/no-cycle
import { formatErrorFromBackend } from './wizard';

export const changeKind = event => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/KIND',
  payload: event.target.value,
});

export const changeNumber = value => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/NUMBER',
  payload: value.replace(/\D/, ''),
});

export const changeEmissionDate = value => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/EMISSION_DATE',
  payload: value,
});

export const changeEmissionIssuer = value => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/EMISSION_ISSUER',
  payload: value,
});

export const changeEmissionState = value => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/EMISSION_STATE',
  payload: value,
});

export const changeMotherName = value => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/MOTHER_NAME',
  payload: value,
});

export const changeFatherName = value => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/FATHER_NAME',
  payload: value,
});

export const changePep = event => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/PEP',
  payload: event.target.value,
});

const formatKind = kind => {
  if (!kind) return undefined;
  if (kind.toLowerCase().includes('rg')) return 'rg';
  if (kind.toLowerCase().includes('cnh')) return 'cnh';
  if (kind.toLowerCase().includes('rne')) return 'rne';
  return '';
};

const formatData = (data = {}) => ({
  ...data,
  kind: formatKind(data.kind),
  emission_date: data.emission_date
    ? changeEmissionDate(data.emission_date).payload
    : data.emission_date,
  emission_state: data.emission_state ? data.emission_state.toUpperCase() : data.emission_state,
});

export const setData = data => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/SET_DATA',
  payload: data ? formatData(data) : undefined,
});

export const handleSuccess = (response = { data: {} }) => {
  if (response.data.errors) {
    return {
      type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/FAILED_SUBMIT',
      payload: response.data.errors,
    };
  }

  return {
    type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/SUCCESSFULL_SUBMIT',
  };
};

export const handleFailure = payload => ({
  type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/FAILED_SUBMIT',
  payload,
});

export const sendToBackend =
  (documentacao = {}) =>
  dispatch => {
    const { isPatch } = documentacao;
    const data = {
      documentacao: {
        kind: documentacao.kind,
        number: documentacao.number,
        emission_date: documentacao.emissionDate,
        emission_issuer: documentacao.emissionIssuer,
        emission_state: documentacao.emissionState,
        mother_name: documentacao.motherName,
        father_name: documentacao.fatherName,
        pep: documentacao.pep,
      },
    };
    const csrfToken = ReactOnRails.authenticityToken();
    if (isPatch) {
      return axios({
        method: 'PATCH',
        url: '/investidor/documentacao.json',
        data: {
          ...data,
          authenticity_token: csrfToken,
        },
        responseType: 'json',
      })
        .then(response => {
          dispatch(handleSuccess(response));
          dispatch(nextStep());
          dispatch(setCompleted(2));
        })
        .catch(() => {
          toast(errorMsg.serverError, 'error', 8000);
          dispatch(handleFailure({ kind: errorMsg.serverError }));
        });
    }
    return axios({
      method: 'POST',
      url: '/investidor/documentacao.json',
      data: {
        ...data,
        authenticity_token: csrfToken,
      },
      responseType: 'json',
    })
      .then(response => {
        dispatch(handleSuccess(response));
        dispatch(nextStep());
        dispatch(setCompleted(2));
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch(handleFailure(formatErrorFromBackend(error)));
      });
  };

export const validateForm = documentacao => {
  const { kind, number, emissionDate, emissionIssuer, emissionState, motherName, pep } =
    documentacao;
  const payload = {};
  const emissiondate = moment(emissionDate, 'DD/MM/YYYY', true);
  const currentDate = moment(new Date(), 'DD/MM/YYYY', true);

  if (kind.length === 0) {
    payload.kind = errorMsg.empty;
  }
  if (number.length === 0) {
    payload.number = errorMsg.empty;
  }
  if (!emissiondate.isValid()) {
    payload.emission_date = errorMsg.invalidFormat;
  }
  if (emissiondate.diff(currentDate, 'years') > 0) {
    payload.emission_date = errorMsg.invalidDate;
  }
  if (emissionDate.length === 0) {
    payload.emission_date = errorMsg.empty;
  }
  if (emissionIssuer.length === 0) {
    payload.emission_issuer = errorMsg.empty;
  }
  if (emissionState.length === 0) {
    payload.emission_state = errorMsg.empty;
  }
  if (motherName.trim().split(' ').length === 1) {
    payload.mother_name = errorMsg.name;
  }
  if (motherName.length === 0) {
    payload.mother_name = errorMsg.empty;
  }
  if (pep.length === 0) {
    payload.pep = errorMsg.empty;
  }

  return payload;
};

export const submitForm = (documentacao, absoluteSuccess) => dispatch => {
  let failure = false;
  const payload = validateForm(documentacao);

  if (Object.keys(payload).length === 0) {
    dispatch(sendToBackend(documentacao));
  } else {
    failure = true;
    toast(errorMsg.pendingForms, 'error', 8000);
    dispatch(handleFailure(payload));
  }
  if (!failure) {
    if (typeof absoluteSuccess === 'function') {
      absoluteSuccess();
    }
  }

  return dispatch({
    type: 'WIZARD_INVESTIDOR/DOCUMENTACAO/SUBMIT_FORMS',
  });
};
