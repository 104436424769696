import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CompleteTable, ToastContainer } from 'liber-components';
import {
  TitleContainer,
  ScreenTitle,
  LoadingBlock,
  Loading,
  DisableBootstrap,
  Container,
  SubTitle,
} from './VendorAdvances.styles';
import { updatePagination, fetchAdvances, fetchVendor } from '../actions/VendorAdvancesActions';
import { getColumns } from '../utils';
import PendingAdvances from './PendingAdvances/PendingAdvances';
import Filters from './Filters/Filters';
import usePageState from '../../../hooks/usePageState';
import HideFromAdmin from './HideFromAdmin/HideFromAdmin';

export const VendorAdvances = ({
  concludedAdvances,
  pendingAdvances,
  pagination,
  onUpdatePagination,
  onFetchAdvances,
  onFetchVendor,
  loading,
  filters,
  isAdmin,
  operator,
  onDetailsClick,
  politicaOfertaTitulos,
}) => {
  const { current, per, pages, count } = pagination;

  const goToPage = selectedPage => {
    onUpdatePagination({ per, page: selectedPage });
  };

  const [pageState, setPageState, onKeyDownPage] = usePageState(current, pages, goToPage);

  React.useEffect(() => {
    onFetchAdvances('pending');
    onFetchVendor('pending');
  }, []);

  React.useEffect(() => {
    onFetchAdvances('concluded');
  }, [current, per, filters]);

  const listingStart = () => String((current - 1) * per + 1);
  const listingEnd = () => {
    const end = (current - 1) * per + per;
    if (count > end) return String(end);
    return String(count);
  };

  const handleChangePagination = paginationParams => {
    onUpdatePagination(paginationParams);
  };

  const handlePageInputChange = ({ target: { value } }) => {
    setPageState(value);
  };

  return (
    <>
      <DisableBootstrap />
      <LoadingBlock loading={loading}>
        {loading ? <Loading loop /> : null}
        <Container>
          <HideFromAdmin>
            <TitleContainer>
              <ScreenTitle>Antecipações</ScreenTitle>
            </TitleContainer>
          </HideFromAdmin>
          <PendingAdvances
            operator={operator}
            onDetailsClick={onDetailsClick}
            isAdmin={isAdmin}
            politicaOfertaTitulos={politicaOfertaTitulos}
          />
          {pendingAdvances.length > 0 ? <SubTitle>Histórico de antecipações</SubTitle> : null}
          <CompleteTable
            items={concludedAdvances}
            columns={getColumns(isAdmin, onDetailsClick)}
            filters={<Filters />}
            paginationProps={{
              currentPage: String(pageState),
              pageTotal: String(pages),
              onChangePage: handlePageInputChange,
              onKeyDownPage,
              onClickNext: () => handleChangePagination({ per, page: current + 1 }),
              onClickPrev: () => handleChangePagination({ per, page: current - 1 }),
              hasListing: true,
              listingProps: {
                onSelectListing: selectedListing =>
                  handleChangePagination({
                    per: parseInt(selectedListing, 10),
                    page: 1,
                  }),
                listing: String(per),
                total: String(count),
                listingLabelSingular: 'adiantamento',
                listingLabelPlural: 'adiantamentos',
                showInterval: {
                  start: listingStart(),
                  end: listingEnd(),
                },
              },
            }}
          />
        </Container>
      </LoadingBlock>

      <ToastContainer />
    </>
  );
};

VendorAdvances.propTypes = {
  pagination: PropTypes.shape({
    count: PropTypes.number,
    current: PropTypes.number,
    next: PropTypes.number,
    pages: PropTypes.number,
    per: PropTypes.number,
    previous: PropTypes.number,
  }).isRequired,
  onUpdatePagination: PropTypes.func,
  concludedAdvances: PropTypes.arrayOf(PropTypes.object),
  pendingAdvances: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.shape({}),
  politicaOfertaTitulos: PropTypes.string,
  onFetchAdvances: PropTypes.func.isRequired,
  onFetchVendor: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  operator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cpf: PropTypes.string,
    legal_representative: PropTypes.bool,
  }),
  onDetailsClick: PropTypes.func,
};

VendorAdvances.defaultProps = {
  onUpdatePagination: () => null,
  onDetailsClick: () => null,
  onFetchVendor: () => null,
  concludedAdvances: [],
  pendingAdvances: [],
  filters: {},
  operator: {},
  politicaOfertaTitulos: null,
};

const mapStateToProps = ({
  vendorAdvances: {
    pagination,
    concludedAdvances,
    pendingAdvances,
    loading,
    filters,
    operator,
    vendor: {
      sacado: { politicaOfertaTitulos },
    },
    isAdmin,
  },
}) => ({
  pagination,
  concludedAdvances,
  pendingAdvances,
  loading,
  filters,
  politicaOfertaTitulos,
  isAdmin,
  operator,
});

const mapDispatchToProps = {
  onUpdatePagination: updatePagination,
  onFetchAdvances: fetchAdvances,
  onFetchVendor: fetchVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAdvances);
