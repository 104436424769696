import { Grid, Typography } from 'liber-salti';
import React, { useContext } from 'react';
import { formatValueToBRL } from '../../../../vendor/Utils';
import { FunderTradesSettlementContext } from '../../context';
import SummaryCard from '../SummaryCard';

const Summary = () => {
  const { summary } = useContext(FunderTradesSettlementContext);

  const expiredTradesAction = {
    onClick: () => null,
    label: 'Gerenciar Vencidos',
  };

  const delayedTradesAction = {
    onClick: () => null,
    label: 'Gerenciar Atrasados',
  };

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2} alignItems="center">
        <Grid item container md={4} sm={6} xs={12}>
          <SummaryCard icon="Block">
            <Typography color="textPrimary" variant="h5">
              {formatValueToBRL(summary.toReceive)}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              A receber
            </Typography>
          </SummaryCard>
        </Grid>
      </Grid>
      <Grid item container spacing={2} alignItems="flex-start">
        <Grid item md={4} sm={6} xs={12}>
          <SummaryCard icon="Money" action={expiredTradesAction}>
            <Typography color="textPrimary" variant="h5">
              {formatValueToBRL(summary.waitingSettlement)}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Títulos A Vencer
            </Typography>
          </SummaryCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SummaryCard icon="Checkmark">
            <Typography color="textPrimary" variant="h5">
              {summary.settled}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Títulos Liquidados
            </Typography>
          </SummaryCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SummaryCard icon="Clock" action={delayedTradesAction}>
            <Typography color="textPrimary" variant="h5">
              {summary.delayed}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Títulos Atrasados
            </Typography>
          </SummaryCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
