import React, { useState, useContext, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@mui/icons-material/Warning';
import { PrimaryButton, TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import SelectStatus from '../SelectStatus';
import { handleError, handleSuccess } from '../../vendor/Utils';
import { TokenContext } from '../../hocs/withTokenProvider';

import * as S from './styles';
import { fetchChangeStatusOnBuyer, resendEmail } from './api';

const VendorStatusOnBuyer = ({ initialStatus, initialBlockReason, vendorId }) => {
  const [status, setStatus] = useState(initialStatus);
  const [blockReason, setBlockReason] = useState(initialBlockReason);
  const { token } = useContext(TokenContext);

  const handleChangeStatus = useCallback(
    async (selectedStatus, message) => {
      try {
        await fetchChangeStatusOnBuyer(token, vendorId, {
          operation_permission: selectedStatus,
          operation_permission_change_reason: message,
        });
        setStatus(selectedStatus);
        setBlockReason(message);
      } catch (error) {
        handleError('Erro!', error?.response?.data?.error);
      }
    },
    [token, vendorId],
  );

  const handleResendConfirmationEmail = useCallback(async () => {
    try {
      await resendEmail(token, vendorId);

      handleSuccess('Enviado!', 'E-mail enviado com sucesso!');
    } catch (error) {
      handleError('Erro!', error?.response?.data?.error?.message);
    }
  }, [token, vendorId]);

  return (
    <S.Container>
      <S.Content>
        <S.ButtonRow>
          <span>Aprovação cadastral:</span>
          <SelectStatus initialStatus={status} onChangeStatus={handleChangeStatus} />
        </S.ButtonRow>

        {status !== 'unblocked' && status !== 'blocked' && (
          <S.ResendButtonWrapper>
            <TooltipBox
              mount="top"
              fixed
              content="Você pode reenviar o e-mail de aprovação para o comprador caso esse email não tenha chegado."
            >
              <S.InformationButton>
                <Icon path={mdiInformation} />
              </S.InformationButton>
            </TooltipBox>
            <PrimaryButton outlined version={2} onClick={handleResendConfirmationEmail}>
              REENVIAR EMAIL DE APROVAÇÃO
            </PrimaryButton>
          </S.ResendButtonWrapper>
        )}
      </S.Content>
      {status !== 'unblocked' && blockReason && (
        <S.BlockReasonMessageWrapper>
          <WarningIcon fontSize="20" />
          <span>Motivo do bloqueio: {blockReason}</span>
        </S.BlockReasonMessageWrapper>
      )}
    </S.Container>
  );
};

export default memo(VendorStatusOnBuyer);

VendorStatusOnBuyer.propTypes = {
  initialStatus: PropTypes.string,
  initialBlockReason: PropTypes.string,
  vendorId: PropTypes.string,
};

VendorStatusOnBuyer.defaultProps = {
  initialStatus: '',
  initialBlockReason: '',
  vendorId: '',
};
