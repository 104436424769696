export default code => {
  // eslint-disable-next-line no-bitwise
  if ((code & 0x1000) === 0x1000) {
    return { type: 'sdk', flow: 'blocker' };
  }
  // eslint-disable-next-line no-bitwise
  if ((code & 0x2000) === 0x2000) {
    return { type: 'extension', flow: 'blocker' };
  }
  // eslint-disable-next-line no-bitwise
  if ((code & 0x4000) === 0x4000) {
    return { type: 'native-app', flow: 'non-blocking' };
  }
  // eslint-disable-next-line no-bitwise
  if ((code & 0x8000) === 0x8000) {
    return { type: 'api', flow: 'blocker' };
  }
  return { type: 'unknown', flow: 'blocker' };
};
