import axios from 'axios';
import { INVOICE_REPORT_CHANNEL, INVOICE_REPORT_ROOM, INVOICE_REPORT_URL } from '../urls';
import { handleError } from '../../../vendor/Utils';
import { NUMBER_IN, STATUS_IN } from '../constants';
import { splitNumbers } from '../utils';

export const SUBSCRIBE_REPORTS = 'SUBSCRIBE_REPORTS';
export const UPDATE_DATE_FILTERS = 'UPDATE_DATE_FILTERS';
export const GET_INVOICES_REPORT = 'GET_INVOICES_REPORT';
export const INVOICES_REPORT_SUBSCRIBE_RECEIVED = 'INVOICES_REPORT_SUBSCRIBE_RECEIVED';
export const INVOICE_REPORT_ERROR = 'INVOICE_REPORT_ERROR';
export const CLOSE_WARNING_MODAL = 'CLOSE_WARNING_MODAL';

export const subscribeReports = () => (dispatch, getState) => {
  const {
    vendorReports: { vendorId },
  } = getState();
  dispatch({
    type: SUBSCRIBE_REPORTS,
    channel: INVOICE_REPORT_CHANNEL,
    room: INVOICE_REPORT_ROOM + vendorId,
  });
};

export const updateDateFilters = ({ key, value }) => ({
  type: UPDATE_DATE_FILTERS,
  payload: { key, value },
});

export const closeModal = () => ({ type: CLOSE_WARNING_MODAL });

export const getReport =
  ({ status, number }) =>
  async (dispatch, getState) => {
    const {
      vendorReports: { token, dateFilters },
    } = getState();

    dispatch({ type: GET_INVOICES_REPORT });

    return axios
      .get(INVOICE_REPORT_URL, {
        params: {
          ...dateFilters,
          [STATUS_IN]: status,
          [NUMBER_IN]: splitNumbers(number),
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch(() => {
        dispatch({ type: INVOICE_REPORT_ERROR });
        handleError();
      });
  };
