import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import { PrimaryButton, FlatButton } from 'liber-components';
import { clearFilters, updateFilters, fetchTrades } from '../../actions/remittanceActions';
import { getLabelByFilterKey, STATUS_TYPE_MAP, STATUS_TYPE_LABEL } from '../../utils';
import { Container, Flex, OverrideBootstrap, Tag, TagContent } from './Filters.styles';
import FilterModal from './FilterModal';

export const Filters = ({ filters, onClearFilters, setFilters, onFilter }) => {
  const [showModal, setShowModal] = useState(false);
  const filterKeysWithoutTags = key => !key.endsWith('_lteq]');

  const onCloseModal = () => {
    setShowModal(false);
  };

  const hasFilterApplied = () => Object.keys(filters).filter(filterKeysWithoutTags).length > 0;
  const isStartingDateKey = key => key.endsWith('_gteq]');

  const handleClearFilter = filterKey => {
    const currentFilters = { ...filters };
    delete currentFilters[filterKey];
    if (isStartingDateKey(filterKey)) {
      const correspondingEndingDateKey = filterKey.replace('gteq', 'lteq');
      delete currentFilters[correspondingEndingDateKey];
    }
    setFilters(currentFilters);
    onFilter();
  };

  const handleClearAllFilters = () => {
    onClearFilters();
  };
  const renderFilterTags = () => {
    const filterKeys = Object.keys(filters).filter(filterKeysWithoutTags);
    const filterKeysToShow = filterKeys.slice(0, 2);
    const remainingFiltersCount = filterKeys.length - 2;

    return (
      <>
        {filterKeysToShow.map(key => {
          let value = filters[key];
          const label = getLabelByFilterKey(key);
          const correspondingEndingDate = filters[key.replace('gteq', 'lteq')];
          if (isStartingDateKey(key) && value !== correspondingEndingDate) {
            value += ` a ${correspondingEndingDate}`;
          } else if (label === STATUS_TYPE_LABEL) {
            value = STATUS_TYPE_MAP[value];
          }

          return (
            <Tag closable onClose={() => handleClearFilter(key)}>
              <TagContent>
                <div>{value}</div>
                <div>{label}</div>
              </TagContent>
            </Tag>
          );
        })}
        {remainingFiltersCount > 0 && (
          <Tag>
            {`+ ${remainingFiltersCount} filtro${
              remainingFiltersCount > 1 ? 's aplicados' : ' aplicado'
            }`}
          </Tag>
        )}
      </>
    );
  };

  return (
    <>
      <OverrideBootstrap />
      <Container>
        <Flex>
          <PrimaryButton version={2} outlined onClick={() => setShowModal(true)}>
            <Icon path={mdiFilter} />
            Filtrar
          </PrimaryButton>
          {renderFilterTags()}
          {hasFilterApplied() && (
            <FlatButton id="clear-all-btn" onClick={handleClearAllFilters}>
              Limpar filtros
            </FlatButton>
          )}
        </Flex>
      </Container>
      <FilterModal show={showModal} onLeaved={onCloseModal} />
    </>
  );
};

Filters.propTypes = {
  onFilter: PropTypes.func,
  filters: PropTypes.shape({}),
  setFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
};

Filters.defaultProps = {
  onFilter: () => null,
  filters: {},
  setFilters: () => {},
  onClearFilters: () => {},
};

const mapStateToProps = ({ remittance: { filters } }) => ({
  filters,
});

const mapDispatchToProps = {
  setFilters: updateFilters,
  onClearFilters: clearFilters,
  onFilter: fetchTrades,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
