import contratos from './contratos';
import dadosBancarios from './dadosBancarios';
import dadosPessoais from './dadosPessoais';
import documentacao from './documentacao';
import enderecos from './enderecos';
import paths from './paths';
import progressBar from './progressBar';

export default {
  contratos,
  dadosBancarios,
  dadosPessoais,
  documentacao,
  enderecos,
  paths,
  progressBar,
};
