import styled, { css } from 'styled-components';
import { Card as CardRaw, LoadingBunny } from 'liber-components';

export const FullWidth = styled.div`
  width: 100%;
`;

export const GraphHeader = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const GraphHeaderContainer = styled.div`
  width: 100%;
  padding-left: 80px;
`;

export const GraphTitle = styled.div`
  color: #263238;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
`;

export const GraphSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

export const UpdatedText = styled.div`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #506286;
`;

export const LegendCircle = styled.div`
  background-color: ${({ color = '#009dff' }) => color};
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;
`;

export const LegendText = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 500;
`;

export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

export const LegendContainer = styled.div`
  margin: 0 10px 0 auto;
  display: flex;
  flex-direction: row;
`;

export const CardFullWidth = styled(CardRaw)`
  width: 100%;
  padding: 20px;
  margin-bottom: 25px;
  flex-grow: 1;
  overflow: unset;
  ${({ noShadow }) =>
    noShadow &&
    css`
      box-shadow: unset;
    `}
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  background-color: white;
  transition: opacity 150ms ease-in-out;
  z-index: 201;
  position: relative;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;
