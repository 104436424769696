import {
  CardHeader as CardHeaderRaw,
  media,
  SimpleDisplay as SimpleDisplayRaw,
  MagnifyChartIcon,
  handleThemeFromObject,
} from 'liber-components';
import { CardContent as CardContentRaw } from 'liber-components/components/Card';
import styled from 'styled-components';

export const CardHeader = styled(CardHeaderRaw)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0px;
  padding-right: 16px;
`;

export const LabelsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: inline-block;
  & label {
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  }
  ${media.large`
    width: 60%;
    & label{
      font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
    }
  `}
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const Title = styled.h3`
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  margin: 10px 0px 0px 0px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  ${media.large`
    margin: 0px;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
    color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  ${media.large`
    flex-direction: row;
  `}
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  ${media.large`
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

export const Color = styled.div`
  white-space: nowrap;
  color: ${props =>
    handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)} !important;
`;

export const SimpleDisplay = styled(SimpleDisplayRaw)`
  margin-top: 15px;
  padding: 10px 20px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};

  ${media.large`
    margin-top: 0px;
  `}

  &:hover {
    background-color: transparent;
  }
  & div:before,
  &:hover > div:before {
    color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  }
  & div:last-child,
  &:hover > div:last-child {
    color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  }
`;

export const CardContent = styled(CardContentRaw)`
  padding: 7px;
  ${media.large`
    padding: 23px;
  `}
`;

export const EmptyChart = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 300px;
  margin: 50px 50px 0px 60px;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  & > div {
    text-align: center;
    width: 100%;
  }
  ${media.large`
    flex-direction: row;
    & > div{
      text-align: center;
      width: unset;
      flex-basis: 60%;
    }

    & > div:first-child{
      flex-basis: 40%;
    }
  `}
`;

export const EmptyIcon = styled(MagnifyChartIcon)`
  width: 150px;
  height: 150px;
  ${media.large`
    width: 255px;
    height: 255px;
  `}
`;

export const EmptyMainText = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  margin: 0px 10px 25px 10px;
  align-self: center;
  ${media.large`
    margin: 0px 0px 25px 0px;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
  `}
`;

export const EmptySecondaryText = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  display: none;
  ${media.large`
    display:block;
  `}
`;

export const TooltipContent = styled.div`
  width: 270px;
`;
