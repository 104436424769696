import React from 'react';
import PropTypes from 'prop-types';
import { Text, UpperText, Container } from './AccountChangeDialog.styles';
import Dialog from '../../../../common-components/Dialog/Dialog';

const AccountChangeDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    title="Alterar Conta Bancária"
    open={open}
    onClose={onClose}
    confirmationButtonProps={{ onConfirm }}
  >
    <Container>
      <UpperText>
        A antecipação será atualizada com as informações da nova conta selecionada. Este processo
        pode ser demorado.
      </UpperText>
      <Text>Deseja continuar com a alteração?</Text>
    </Container>
  </Dialog>
);

AccountChangeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

AccountChangeDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default AccountChangeDialog;
