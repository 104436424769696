import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext } from 'react';
import { ACTIONS } from '../../../../constants';
import { HandleBlockUnblockModalContext } from '../../context';
import * as S from './styles';

const Content = () => {
  const { selectedFunder, handleSelectedFunderToBlockOrUnblock, handleLeave } = useContext(
    HandleBlockUnblockModalContext,
  );

  const handleAction = action => {
    handleSelectedFunderToBlockOrUnblock({ ...selectedFunder, action });
  };

  return (
    <S.Wrapper>
      <S.Row>
        <S.Title>Fim da analise de cadastro</S.Title>
        <S.CloseButton onClick={handleLeave}>
          <Icon path={mdiClose} />
        </S.CloseButton>
      </S.Row>
      <S.TextInfo>Qual ação deseja realizar com esse investidor?</S.TextInfo>
      <S.ButtonsRow>
        <S.BlockButton onClick={() => handleAction(ACTIONS.BLOCK)}>Bloquear</S.BlockButton>
        <S.UnblockButton onClick={() => handleAction(ACTIONS.UNBLOCK)}>Desbloquear</S.UnblockButton>
      </S.ButtonsRow>
    </S.Wrapper>
  );
};

export default Content;
