import React, { useContext } from 'react';
import { getPaginationInterval } from '../../../../../../vendor/Utils';
import { ConfirmationModalContext } from '../../context';
import Filters from '../Filters';
import * as S from './styles';

const COLUMNS = [
  {
    name: 'buyer',
    label: 'Sacado',
    width: 40,
    getCellValue: ({ buyer }) => {
      const { name } = buyer;
      return name;
    },
  },
  {
    name: 'name',
    label: 'Grupo',
    width: 50,
  },
];

const Table = () => {
  const {
    currentPage,
    pages,
    setCurrent,
    setPageState,
    next,
    previous,
    onKeyDownPage,
    per,
    count,
    onSelectListing,
    pagination,
    groups,
    loading,
  } = useContext(ConfirmationModalContext);

  return (
    <S.Loading loading={loading}>
      {loading && <S.Bunny />}
      <S.Table
        filters={<Filters />}
        columns={COLUMNS}
        items={groups}
        paginationProps={{
          currentPage,
          pageTotal: pages,
          onChangePage: ({ target: { value } }) => setPageState(value),
          onClickNext: () => setCurrent(next),
          onClickPrev: () => setCurrent(previous),
          onKeyDownPage,
          hasListing: true,
          listingProps: {
            listing: String(per),
            total: String(count),
            showInterval: getPaginationInterval(pagination),
            onSelectListing,
            listingLabelSingular: 'investidor',
            listingLabelPlural: 'investidores',
          },
        }}
      />
    </S.Loading>
  );
};

export default Table;
