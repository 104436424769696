import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';
import * as S from './styles';
import { TRANSFER_AGREEMENTS_LIMIT } from '../constants';
import FunderTradesHeaderContext from '../context';

const TransferAgreementsModal = ({ show, onConfirm, onLeaved, loading }) => {
  const { tradesCount } = useContext(FunderTradesHeaderContext);
  const numberOfFiles = Math.ceil(tradesCount / TRANSFER_AGREEMENTS_LIMIT);

  return (
    <Modal show={show} onLeaved={onLeaved}>
      <S.Container>
        <S.Header>Download dos termos de cessão</S.Header>
        <S.Content>
          <p>Você receberá todos os termos de cessão via e-mail.</p>
          <S.List>
            <S.ListItem>
              {numberOfFiles === 1
                ? 'Será enviado 1 arquivo .zip'
                : `Serão enviados ${numberOfFiles} arquivos .zip`}
            </S.ListItem>
            <S.ListItem>O máximo de termos em um arquivo é {TRANSFER_AGREEMENTS_LIMIT}.</S.ListItem>
          </S.List>
          <p>Tem certeza que deseja continuar?</p>
        </S.Content>
        <S.ActionsRow>
          <S.Button onClick={onLeaved}>Fechar</S.Button>
          <S.Button loading={loading} onClick={onConfirm}>
            Confirmar
          </S.Button>
        </S.ActionsRow>
      </S.Container>
    </Modal>
  );
};

TransferAgreementsModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onLeaved: PropTypes.func,
  loading: PropTypes.bool,
};

TransferAgreementsModal.defaultProps = {
  show: false,
  onConfirm: () => null,
  onLeaved: () => null,
  loading: false,
};

export default TransferAgreementsModal;
