import React from 'react';
import PropTypes from 'prop-types';
import SaltiThemeWrapper from '../../common-components/SaltiThemeWrapper';
import { convertToCamelCase } from '../../vendor/Utils';
import OperatorKyc from './componenets/OperatorKyc';

const View = props => {
  const { status, operator, redirectUrl } = convertToCamelCase(props);

  return (
    <SaltiThemeWrapper>
      <OperatorKyc status={status} redirectUrl={redirectUrl} operator={operator} />
    </SaltiThemeWrapper>
  );
};

View.propTypes = PropTypes.shape({
  operator: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  status: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
});

View.defaultProps = {};

export default View;
