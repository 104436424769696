export const accountLength = bankCode => {
  switch (bankCode) {
    case '001':
    case '755':
    case '756':
      return 8;
    case '237':
      return 7;
    case '341':
      return 5;
    default:
      return 13;
  }
};

export const getSavingsComplementLabel = bankCode => {
  switch (bankCode) {
    case '001':
      return 'Variação';
    case '104':
      return 'Operação';
    case '341':
      return 'Complemento';
    default:
      return 'Número da poupança';
  }
};
