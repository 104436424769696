import { connect } from 'react-redux';
import Investidor from './Investidor';
import {
  changeDocumento,
  changeDocumentoType,
  changePassword,
  toogleRemember,
  submitLogin,
  setFingerprint,
} from '../../actions/investidorActions';

const mapStateToProps = state => ({
  documento: state.investidor.documento,
  documentoError: state.investidor.documentoError,
  documentoType: state.investidor.documentoType,
  password: state.investidor.password,
  passwordError: state.investidor.passwordError,
  remember: state.investidor.remember,
  path: state.investidor.path,
  flashes: state.investidor.flashes,
  passwordPath: state.investidor.passwordPath,
  registrationPath: state.investidor.registrationPath,
  fingerprint: state.investidor.fingerprint,
  components: state.investidor.components,
});

const mapDispatchToProps = {
  changeDocumento,
  changeDocumentoType,
  changePassword,
  toogleRemember,
  submitLogin,
  setFingerprint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Investidor);
