import React from 'react';
import PropTypes from 'prop-types';
import { ErrorCircleIcon, SucessCircleIcon, LargeInfoMessage, InfoMessage } from '../../../styles';

const selector = (type, label, failedInvoices = [], invoices = []) => {
  switch (type) {
    case 'server-gen-soft':
      return (
        <LargeInfoMessage>
          <SucessCircleIcon />
          {invoices.length - failedInvoices.length > 1 ? (
            <span>
              A preparação de {invoices.length - failedInvoices.length} de {invoices.length}{' '}
              documentos foi realizada com sucesso.
            </span>
          ) : (
            <span>
              A preparação de 1 de {invoices.length} documentos foi realizada com sucesso.
            </span>
          )}
        </LargeInfoMessage>
      );
    case 'server-sign-soft':
      return (
        <>
          <LargeInfoMessage>
            <div style={{ alignSelf: 'flex-start' }}>
              <SucessCircleIcon />
            </div>
            {invoices.length - failedInvoices.length > 1 ? (
              <span>
                A assinatura de {invoices.length - failedInvoices.length} de {invoices.length}{' '}
                documentos foi realizada, mas não conseguimos assinar todos.
                <InfoMessage alignItems="flex-start">
                  <span>
                    Clique em <b>Assinar Documentos</b> para assinar os que faltam.
                  </span>
                </InfoMessage>
              </span>
            ) : (
              <span>
                A assinatura de 1 de {invoices.length} documentos foi realizada com sucesso. Porém
                não conseguimos assinar todos.
                <InfoMessage alignItems="flex-start">
                  <span>
                    Clique em <b>Assinar Documentos</b> para assinar os que faltam.
                  </span>
                </InfoMessage>
              </span>
            )}
          </LargeInfoMessage>
        </>
      );
    case 'server-gen-timeout':
    case 'server-sign-timeout':
    case 'server-gen':
    case 'server-sign':
    case 'sdk':
    case 'native-app':
    case 'extension':
    case 'api':
    case 'unknown':
      return (
        <LargeInfoMessage>
          {' '}
          <ErrorCircleIcon /> <span>{label}</span>{' '}
        </LargeInfoMessage>
      );
    default:
      return null;
  }
};

const ErrorNotification = ({ type, label, failedInvoices, invoices }) => (
  <>{selector(type, label, failedInvoices, invoices)}</>
);

ErrorNotification.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  failedInvoices: PropTypes.arrayOf(PropTypes.any),
  invoices: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ErrorNotification.defaultProps = {
  failedInvoices: [],
  label: '',
};

export default ErrorNotification;
