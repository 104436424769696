import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlatButton } from 'liber-components';
import { Flex, Label, ScheduleModalButton, SpaceBetween } from './VendorFilters.styles';
import { TextFieldFilter } from '../../../../common-components/Filters';
import {
  updateFilters,
  VENDOR_FILTER,
  BUYER_FILTER,
  SIGNATURE_CERTIFICATION_FILTER,
  SIGNATURE_METHOD_FILTER,
  changeShowSchedulesModal,
} from '../../actions/AdminVendorsActions';
import SignatureFilter from '../SignatureFilter/SignatureFilter';
import { formatTaxNumber } from '../../../../vendor/Utils';

export const VendorFilters = ({
  changeFilters,
  filters,
  isAdmin,
  selectedVendors,
  updateShowSchedulesModal,
}) => {
  const updateFilter = key => value => {
    changeFilters({ [key]: formatTaxNumber(value) }, isAdmin);
  };

  const clearFilter = key => () => {
    changeFilters({ [key]: null }, isAdmin);
  };

  const clearAllFilters = () => {
    changeFilters(
      {
        [VENDOR_FILTER]: null,
        [BUYER_FILTER]: null,
        [SIGNATURE_CERTIFICATION_FILTER]: [],
        [SIGNATURE_METHOD_FILTER]: [],
      },
      isAdmin,
    );
  };

  const hasFilters = () =>
    Object.keys(filters).reduce((flag, key) => {
      const hasValue = Boolean(filters[key]);

      if (hasValue && filters[key] instanceof Array) {
        return flag || filters[key].length > 0;
      }

      return flag || hasValue;
    }, false);

  return (
    <SpaceBetween>
      <Flex>
        <Label>Filtrar por:</Label>
        <TextFieldFilter
          label="Fornecedor"
          hintText="R. Social/N. Fant./CNPJ/CPF/E-mail"
          selectedItems={filters[VENDOR_FILTER]}
          onFilter={updateFilter(VENDOR_FILTER)}
          onClear={clearFilter(VENDOR_FILTER)}
        />
        <SignatureFilter />
        <TextFieldFilter
          label="Sacado"
          hintText="R. Social/Nome Fant./CNPJ"
          selectedItems={filters[BUYER_FILTER]}
          onFilter={updateFilter(BUYER_FILTER)}
          onClear={clearFilter(BUYER_FILTER)}
        />
        {hasFilters() && <FlatButton onClick={clearAllFilters}>Limpar Filtros</FlatButton>}
      </Flex>
      <div>
        {Object.keys(selectedVendors).length > 0 && (
          <ScheduleModalButton onClick={() => updateShowSchedulesModal(true)}>
            Agendar envio
          </ScheduleModalButton>
        )}
      </div>
    </SpaceBetween>
  );
};

VendorFilters.propTypes = {
  filters: PropTypes.shape({
    [VENDOR_FILTER]: PropTypes.string,
    [BUYER_FILTER]: PropTypes.string,
  }),
  changeFilters: PropTypes.func,
  isAdmin: PropTypes.bool,
  selectedVendors: PropTypes.arrayOf(PropTypes.any),
  updateShowSchedulesModal: PropTypes.func,
};

VendorFilters.defaultProps = {
  filters: {},
  changeFilters: () => null,
  isAdmin: false,
  selectedVendors: [],
  updateShowSchedulesModal: () => null,
};

const mapStateToProps = ({ adminVendors: { filters, isAdmin, selectedVendors } }) => ({
  filters,
  isAdmin,
  selectedVendors,
});

const mapDispatchToProps = {
  changeFilters: updateFilters,
  updateShowSchedulesModal: changeShowSchedulesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorFilters);
