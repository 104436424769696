import Immutable from 'seamless-immutable';
import {
  calculateQuote,
  handleError,
  REQUESTED_LT,
  REQUESTED_GT,
  STATUS_IN,
  DUPLICATA_NUMBER_IN,
  EXPIRES_AT_LT,
  EXPIRES_AT_GT,
} from '../utils';
import {
  VENDOR_ADVANCES_CHANGE_TAB,
  VENDOR_ADVANCES_UPDATE_PAGINATION,
  VENDOR_ADVANCES_FETCH_NEGOTIATIONS_SUCCESS,
  VENDOR_ADVANCES_FETCH_NEGOTIATIONS_REJECTED,
  VENDOR_ADVANCES_FETCH_NEGOTIATIONS,
  UPDATE_ADVANCES,
  TOGGLE_SELECT_ALL,
  UPDATE_SELECT_ADVANCES,
  SET_MODAL_OPEN,
  READY_TO_SIGN,
  HANDLE_REQUEST_ERROR,
  UPDATE_NEGOTIATIONS_FILTERS,
  VENDOR_ADVANCES_FETCH_ADVANCES,
  VENDOR_FETCH,
  VENDOR_FETCH_SUCCESS,
  UPDATE_FILTERS,
  SELECTED_ADVANCE_UPDATE_PAGINATION,
  SELECTED_ADVANCE_UPDATE_ID,
  SELECTED_ADVANCE_UPDATE_FILTERS,
  SELECTED_ADVANCE_UPDATE_SORTING,
  SELECTED_ADVANCE_UPDATE_INVOICES,
  SELECTED_ADVANCE_UPDATE_LOADING,
  SELECTED_ADVANCE_UPDATE_DETAILS,
  SELECTED_ADVANCE_UPDATE_DETAILS_LOADING,
} from '../actions/VendorAdvancesActions';
import { SORT_KEY } from '../../../hooks/useSorting';

export const DEFAULT_SORTING = { columnName: 'created_at', direction: 'desc' };
export const DEFAULT_DETAILS_SORTING = { columnName: 'number', direction: 'asc' };

export const initialState = Immutable({
  token: null,
  vendorId: null,
  operator: {
    id: null,
    cpf: '',
    name: '',
  },
  error: '',
  pendingAdvances: [],
  concludedAdvances: [],
  timeCut: null,
  filters: {
    [REQUESTED_LT]: null,
    [REQUESTED_GT]: null,
    [STATUS_IN]: null,
  },
  sorting: {
    [SORT_KEY]: `${DEFAULT_SORTING.columnName} ${DEFAULT_SORTING.direction}`,
  },
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 25,
    pages: 1,
    count: 0,
  },
  loading: false,
  isAdmin: false,
  vendor: { sacado: { politicaOfertaTitulos: null } },
  details: {
    sorting: {
      [SORT_KEY]: `${DEFAULT_DETAILS_SORTING.columnName} ${DEFAULT_DETAILS_SORTING.direction}`,
    },
    pagination: {
      current: 1,
      previous: null,
      next: null,
      per: 10,
      pages: 1,
      count: 0,
    },
    selectedId: null,
    filters: {
      [DUPLICATA_NUMBER_IN]: null,
      [EXPIRES_AT_LT]: null,
      [EXPIRES_AT_GT]: null,
    },
    invoices: [],
    loading: false,
    detailsLoading: false,
  },
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case VENDOR_ADVANCES_CHANGE_TAB:
      return state.merge({
        activeTab: payload.tab,
      });

    case VENDOR_ADVANCES_UPDATE_PAGINATION: {
      const {
        payload: { pagination: newPagination },
      } = action;
      const { pagination } = state;

      return state.merge({
        pagination: { ...pagination, ...newPagination },
      });
    }

    case VENDOR_ADVANCES_FETCH_NEGOTIATIONS_SUCCESS: {
      const { negotiations, pagination } = payload;
      return state.merge({
        loading: false,
        negotiations,
        pagination,
      });
    }

    case VENDOR_ADVANCES_FETCH_NEGOTIATIONS_REJECTED:
      return state.merge({
        error: payload.message,
        loading: false,
        negotiations: [],
      });

    case VENDOR_ADVANCES_FETCH_NEGOTIATIONS:
      return state.merge({
        error: '',
        loading: true,
        negotiations: [],
      });

    case VENDOR_FETCH:
      return state.merge({
        error: '',
        loading: true,
      });

    case VENDOR_FETCH_SUCCESS: {
      const { vendor } = payload;
      return state.merge({
        error: '',
        loading: false,
        vendor,
      });
    }

    case SET_MODAL_OPEN: {
      const {
        payload: { modalOpen },
      } = action;
      return state.merge({ modalOpen });
    }

    case READY_TO_SIGN: {
      return state.merge({ buttonLoading: false, modalOpen: true });
    }

    case VENDOR_ADVANCES_FETCH_ADVANCES:
      return state.merge({ loading: true });

    case UPDATE_ADVANCES: {
      return state.merge({
        loading: false,
        ...payload,
      });
    }

    case UPDATE_FILTERS: {
      const {
        payload: { filters },
      } = action;
      const { pagination } = state;

      return state.merge({
        filters: { ...state.filters, ...filters },
        pagination: { ...pagination, current: 1 },
      });
    }

    case HANDLE_REQUEST_ERROR: {
      const {
        payload: { error },
      } = action;

      handleError(error);

      return state.merge({
        buttonLoading: false,
        loading: false,
        isSigningDocuments: false,
      });
    }

    case TOGGLE_SELECT_ALL: {
      const { advances, isPageFullySelected } = state;

      const selectedAdvances = isPageFullySelected
        ? []
        : advances.filter(({ userHasSigned }) => !userHasSigned);

      const quote = calculateQuote(selectedAdvances);

      return state.merge({ selectedAdvances, isPageFullySelected: !isPageFullySelected, quote });
    }

    case UPDATE_SELECT_ADVANCES: {
      const { selectedAdvances, selectableAdvancesCount } = state;
      const {
        payload: { advance },
      } = action;

      let updatedSelectedAdvances;

      if (selectedAdvances.includes(advance)) {
        updatedSelectedAdvances = selectedAdvances.filter(({ id }) => id !== advance.id);
      } else {
        updatedSelectedAdvances = selectedAdvances.concat([advance]);
      }

      const quote = calculateQuote(updatedSelectedAdvances);

      return state.merge({
        selectedAdvances: updatedSelectedAdvances,
        quote,
        isPageFullySelected: updatedSelectedAdvances.length === selectableAdvancesCount,
      });
    }

    case UPDATE_NEGOTIATIONS_FILTERS:
      return state.merge({ negotiationsFilters: payload });

    case SELECTED_ADVANCE_UPDATE_ID: {
      const { payload: selectedId } = action;

      const { details } = state;

      return state.merge({
        details: { ...details, selectedId },
      });
    }

    case SELECTED_ADVANCE_UPDATE_PAGINATION: {
      const { payload: newPagination } = action;
      const { details } = state;

      const { pagination } = details;

      return state.merge({
        details: { ...details, pagination: { ...pagination, ...newPagination } },
      });
    }

    case SELECTED_ADVANCE_UPDATE_FILTERS: {
      const { details } = state;

      return state.merge({ details: { ...details, filters: payload } });
    }

    case SELECTED_ADVANCE_UPDATE_SORTING: {
      const { details } = state;

      return state.merge({ details: { ...details, sorting: payload } });
    }

    case SELECTED_ADVANCE_UPDATE_INVOICES: {
      const { details } = state;

      return state.merge({ details: { ...details, invoices: payload } });
    }

    case SELECTED_ADVANCE_UPDATE_LOADING: {
      const { details } = state;

      return state.merge({ details: { ...details, loading: payload } });
    }

    case SELECTED_ADVANCE_UPDATE_DETAILS: {
      const { details } = state;
      const { funderName, bankAccount, signatureCertificateUrl, invoiceTransferAgreementUrl } =
        payload;

      return state.merge({
        details: {
          ...details,
          funderName,
          bankAccount,
          signatureCertificateUrl,
          invoiceTransferAgreementUrl,
        },
      });
    }

    case SELECTED_ADVANCE_UPDATE_DETAILS_LOADING: {
      const { details } = state;

      return state.merge({ details: { ...details, detailsLoading: payload } });
    }

    default:
      return state;
  }
};
