import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import { toast } from 'liber-components';

import messages from '../messages';

export const changeCurrentPassword = event => ({
  type: 'MY_ACCOUNT_CHANGE_PASSWORD_CURRENT_PASSWORD',
  payload: event,
});

export const changeNewPassword = event => ({
  type: 'MY_ACCOUNT_CHANGE_PASSWORD_NEW_PASSWORD',
  payload: event,
});

export const changePasswordConfirmation = event => ({
  type: 'MY_ACCOUNT_CHANGE_PASSWORD_PASSWORD_CONFIRMATION',
  payload: event,
});

export const handleSuccess = () => ({
  type: 'MY_ACCOUNT_CHANGE_PASSWORD_SUCCESSFULL_SUBMIT',
});

export const handleFailure = payload => ({
  type: 'MY_ACCOUNT_CHANGE_PASSWORD_FAILED_SUBMIT',
  payload,
});

export const validateForm = (currentPassword, newPassword, passwordConfirmation) => {
  const payload = {};

  if (currentPassword.length === 0) {
    payload.currentPassword = messages.empty;
  }
  if (passwordConfirmation !== newPassword) {
    payload.newPassword = messages.match;
  }
  if (newPassword.length < 6) {
    payload.newPassword = messages.small;
  }
  if (newPassword.length === 0) {
    payload.newPassword = messages.empty;
  }
  if (passwordConfirmation.length === 0) {
    payload.passwordConfirmation = messages.empty;
  }

  return payload;
};

export const sendToBackend = (currentPassword, newPassword, passwordConfirmation) => dispatch => {
  dispatch({
    type: 'MY_ACCOUNT_CHANGE_PASSWORD_PENDING_SUBMIT',
  });
  return axios({
    method: 'PATCH',
    url: '/investidor/passwords/update_change.json',
    data: {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: passwordConfirmation,
      authenticity_token: ReactOnRails.authenticityToken(),
    },
    responseType: 'json',
  })
    .then(() => {
      toast('Senha atualizada com sucesso', 'success', 8000);
      dispatch(handleSuccess());
    })
    .catch(error => {
      const { message, reason } = error.response.data.error;
      toast(`${message}: ${reason}`, 'error', 8000);
      dispatch(handleFailure({}));
    });
};

export const submitForm = (currentPassword, newPassword, passwordConfirmation) => dispatch => {
  const payload = validateForm(currentPassword, newPassword, passwordConfirmation);
  if (Object.keys(payload).length === 0) {
    sendToBackend(currentPassword, newPassword, passwordConfirmation)(dispatch);
  } else {
    dispatch(handleFailure(payload));
  }

  return dispatch({
    type: 'MY_ACCOUNT_CHANGE_PASSWORD_SUBMIT_FORM',
  });
};
