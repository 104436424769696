/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableHeader, Modal, SuccessButton } from 'liber-components';
import { connect } from 'react-redux';
import { closeDone } from '../../actions/proposalAction';
import {
  DialogContent,
  DialogHeader,
  DialogBody,
  Desc,
  FunderTag,
  TableHeaderColumn,
  TableRowColumn,
  Total,
  ButtonContainer,
  Check,
  Times,
  TableContainer,
  TotalContainer,
  TableRow,
} from './Dialogs.styles';

const formatRate = rate => {
  const formattedRate = rate.toFixed(4);
  return `${formattedRate}% a.m.`;
};

const mapNegotiations = (failure, specialRate, specialRateType) => negotiation =>
  (
    <TableRow key={negotiation.id} selected={!failure} error={failure}>
      <TableRowColumn>{negotiation.number}</TableRowColumn>
      <TableRowColumn>
        {specialRate &&
        (specialRateType === 'taxa_rendimento' || specialRateType === 'rentabilidade_mensal')
          ? specialRate
          : negotiation.monthly_profitability}
      </TableRowColumn>
      <TableRowColumn>{negotiation.advance}</TableRowColumn>
      <TableRowColumn>{negotiation.deadline}</TableRowColumn>
    </TableRow>
  );

const renderMessage = (type, count, total) =>
  ({
    success: (
      <Desc>
        <Check />
        As seguintes propostas foram enviadas pelo investidor:
      </Desc>
    ),
    failure: (
      <Desc>
        <Times />
        As seguintes propostas tiveram problemas:
      </Desc>
    ),
    mix: (
      <Desc>
        <Check /> {count}/{total} Propostas foram enviadas pelo investidor:
      </Desc>
    ),
  }[type] || null);

const renderTables = (
  type,
  success,
  failures,
  successAmount,
  failureAmount,
  specialRate,
  specialRateType,
) => {
  const header = (
    <TableHeader>
      <TableRow>
        <TableHeaderColumn>Nº do Título</TableHeaderColumn>
        <TableHeaderColumn>Rentabilidade</TableHeaderColumn>
        <TableHeaderColumn>Investimento</TableHeaderColumn>
        <TableHeaderColumn>Vencimento</TableHeaderColumn>
      </TableRow>
    </TableHeader>
  );

  return (
    {
      success: (
        <>
          <TableContainer>
            <Table>
              {header}
              <TableBody>
                {success.map(mapNegotiations(false, specialRate, specialRateType))}
              </TableBody>
            </Table>
          </TableContainer>
          <Total>
            Valor total: <span>{successAmount}</span>
          </Total>
        </>
      ),
      failure: (
        <>
          <TableContainer>
            <Table>
              {header}
              <TableBody>
                {failures.map(mapNegotiations(true, specialRate, specialRateType))}
              </TableBody>
            </Table>
          </TableContainer>
          <Total>
            Valor total: <span>{failureAmount}</span>
          </Total>
        </>
      ),
      mix: (
        <>
          <TableContainer>
            <Table>
              {header}
              <TableBody>
                {success.map(mapNegotiations(false, specialRate, specialRateType))}
                {failures.map(mapNegotiations(true, specialRate, specialRateType))}
              </TableBody>
            </Table>
          </TableContainer>
          <TotalContainer>
            <Total>
              Valor com problemas: <br />
              <span>{failureAmount}</span>
            </Total>
            <Total>
              Valor total enviado: <br />
              <span>{successAmount}</span>
            </Total>
          </TotalContainer>
        </>
      ),
    }[type] || null
  );
};

export const Done = ({ show, successes, failures, close, funders, funder: funderId, ...props }) => {
  const specialRateValue = (props.options && props.options.special_rate) || '';
  const specialRateTypeValue = (props.options && props.options.special_rate_type) || '';
  const formattedSpecialRate = specialRateValue ? formatRate(specialRateValue) : '';
  const { count: successCount, amount: successAmount } = successes;
  const { count: failureCount, amount: failureAmount } = failures;
  const funder = funders.find(item => item.id === funderId);
  let type = 'mix';
  if (successCount !== 0 && failureCount === 0) {
    type = 'success';
  } else if (successCount === 0 && failureCount !== 0) {
    type = 'failure';
  }

  return (
    <Modal show={show} onLeaved={close}>
      <DialogContent>
        <DialogHeader>Propostas enviadas com sucesso</DialogHeader>
        <DialogBody>
          {renderMessage(type, successCount, successCount + failureCount)}
          <FunderTag>{funder ? funder.name : ''}</FunderTag>
          {renderTables(
            type,
            successes.negotiations,
            failures.negotiations,
            successAmount,
            failureAmount,
            formattedSpecialRate,
            specialRateTypeValue,
          )}
          <ButtonContainer>
            <SuccessButton onClick={close}>Sair</SuccessButton>
          </ButtonContainer>
        </DialogBody>
      </DialogContent>
    </Modal>
  );
};

Done.propTypes = {
  show: PropTypes.bool.isRequired,
  successes: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string,
    negotiations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        slug: PropTypes.string,
        monthly_profitability: PropTypes.string,
        advance: PropTypes.string,
        deadline: PropTypes.string,
        vendor: PropTypes.string,
        payer: PropTypes.string,
        actions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        number: PropTypes.string,
        amount: PropTypes.string,
        rate: PropTypes.string,
        expires_at: PropTypes.string,
      }),
    ),
  }).isRequired,
  failures: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string,
    negotiations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        slug: PropTypes.string,
        monthly_profitability: PropTypes.string,
        advance: PropTypes.string,
        deadline: PropTypes.string,
        vendor: PropTypes.string,
        payer: PropTypes.string,
        actions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        number: PropTypes.string,
        amount: PropTypes.string,
        rate: PropTypes.string,
        expires_at: PropTypes.string,
      }),
    ),
  }).isRequired,
  funder: PropTypes.string.isRequired,
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  close: PropTypes.func,
};

Done.defaultProps = {
  close: () => {},
};

const mapStateToProps = state => ({
  ...state.multiProposal.proposals.summaryDone,
  funders: state.multiProposal.summary.funders,
});

const mapDispatchToProps = {
  close: closeDone,
};

export default connect(mapStateToProps, mapDispatchToProps)(Done);
