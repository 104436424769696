import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'liber-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateFilters } from '../../actions/remittanceActions';
import { TextField, SelectField } from './Filters.styles';

export const WAIT_INTERVAL = 100;

export const InputField = ({ input, setFilters, filters }) => {
  const { label, type, filterKey, filterKeys } = input;
  const [remittanceStatusIn, setRemittanceStatusIn] = useState('Pendente');
  const [inputValue, setInputValue] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const isNullOrEmpty = str => str === null || str === undefined || str === '';

  const handleSelectRemittanceStatusIn = text => {
    setRemittanceStatusIn(text);
    setInputValue(text);
  };
  const handleDateFilterChange = () => {
    const dates = inputValue.split(' a ');
    const [startingKey, endingKey] = filterKeys;

    if (!isNullOrEmpty(inputValue)) {
      const [start, end] = dates;

      const newFilters = { ...filters };
      const isUniqueDay = dates.length === 1;
      setFilters({ ...newFilters, [startingKey]: start, [endingKey]: isUniqueDay ? start : end });
    } else {
      const newFilters = { ...filters };

      delete newFilters[startingKey];
      delete newFilters[endingKey];
      setFilters({ ...newFilters });
    }
  };

  const handleFilterChange = () => {
    if (type === 'date') {
      handleDateFilterChange();
    } else if (!isNullOrEmpty(inputValue)) {
      setFilters({ ...filters, [filterKey]: inputValue });
    } else {
      const newFilters = { ...filters };
      setFilters({ ...newFilters });
    }
  };

  const updateDateInputValue = () => {
    const [start, end, equal] = filterKeys;
    if (
      !Object.keys(filters).includes(start) &&
      !Object.keys(filters).includes(end) &&
      !Object.keys(filters).includes(equal)
    ) {
      setInputValue('');
    }
  };

  useEffect(() => {
    const filterTimeout = setTimeout(!isFirstLoad ? handleFilterChange : () => null, WAIT_INTERVAL);
    setIsFirstLoad(false);
    return () => clearTimeout(filterTimeout);
  }, [inputValue]);

  useEffect(() => {
    if (type === 'date') {
      updateDateInputValue();
    } else {
      const value = filters[filterKey];
      if (value !== undefined && value !== null) {
        setInputValue(String(filters[filterKey]));
      } else {
        setInputValue('');
      }
    }
  }, [filters]);

  switch (type) {
    case 'date':
      return (
        <div>
          <DateRangePicker
            label={label}
            openLabel
            value={inputValue}
            onChange={setInputValue}
            closeDelay={0}
            autoPosition={false}
          />
        </div>
      );
    case 'select':
      return (
        <SelectField
          version="2"
          label="Status"
          value={remittanceStatusIn}
          onChange={handleSelectRemittanceStatusIn}
        >
          <option value="0">Pendente</option>
          <option value="1">Baixada</option>
        </SelectField>
      );
    default:
      return <TextField version={2} label={label} value={inputValue} onChange={setInputValue} />;
  }
};

InputField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'date', 'select', 'checkbox']),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    filterKey: PropTypes.string,
    filterKeys: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
  }),
  filters: PropTypes.shape({}),
  setFilters: PropTypes.func,
};

InputField.defaultProps = {
  input: {},
  filters: {},
  setFilters: () => {},
};

const mapStateToProps = ({ remittance: { filters } }) => ({ filters });

const mapDispatchToProps = {
  setFilters: updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputField);
