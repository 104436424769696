import styled from 'styled-components';
import { FlatButton, PrimaryButton, Button } from 'liber-components';

export const Wrapper = styled.div`
  padding: 20px 24px;
  width: 480px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.darkText70};
`;

export const CloseButton = styled(Button).attrs(() => ({
  version: 2,
  size: 'large',
  circle: true,
}))``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextInfo = styled.p`
  margin: 24px 0px;
  color: #798e9b;
`;

export const ButtonsRow = styled(Row)`
  justify-content: flex-end;
  gap: 4px;
`;

export const BlockButton = styled(FlatButton).attrs(() => ({ color: 'danger', size: 'large' }))``;

export const UnblockButton = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))``;
