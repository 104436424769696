import moment from 'moment';

export const formatDate = label => moment(label).format('DD/MM/YYYY');

export const getPeriod = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  return endDate.diff(startDate, 'days') + 1;
};

export const getVendorsGroupsId = buyers =>
  buyers.map(({ vendorsGroups }) => vendorsGroups.map(({ id }) => id)).flat();
