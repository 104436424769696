import React from 'react';
import PropTypes from 'prop-types';
import { CreateBuyerOrganizationGroup } from '../../features/buyer-organization-groups';

const CreateBuyerOrganizationGroupView = ({ token, id }) => (
  <CreateBuyerOrganizationGroup token={token} editGroupId={id} />
);

CreateBuyerOrganizationGroupView.propTypes = {
  token: PropTypes.string.isRequired,
  id: PropTypes.string,
};

CreateBuyerOrganizationGroupView.defaultProps = {
  id: undefined,
};

export default CreateBuyerOrganizationGroupView;
