import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';
import { Analytics } from '../../../../libs/googleAnalytics/index.js';

import {
  Container,
  ModalContainer,
  Header,
  Content,
  ActionsRow,
  Button,
  TextField,
} from './styles';

import { sendInterestContact } from '../../api';
import { dispatchAmplitudeEvent, handleSuccess, handleError } from '../../../../vendor/Utils';
import { Banner } from '../Banner';

const VendorInterest = ({ vendor, token }) => {
  const [modal, setModal] = useState(false);

  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState([]);

  const { name: vendorName, company, phone, email: vendorEmail } = vendor;

  useEffect(() => {
    dispatchAmplitudeEvent('banner-interesse-showed', { vendorName, company });
  }, []);

  const showModal = () => {
    setModal(true);
    Analytics.emitCustomEvent('adiante:begin_request');
    dispatchAmplitudeEvent('btn-interesse', { vendorName, company, phone, vendorEmail });
  };

  const clearNumber = text => text.replace(/\D/g, '');

  const validateForm = () => {
    const newErrors = {};
    if (cnpj.trim().length === 0) {
      newErrors.cnpj = 'CNPJ é obrigatório';
    }
    if (name.trim().length === 0) {
      newErrors.name = 'Razão social é obrigatório';
    }
    if (cellphone.trim().length === 0) {
      newErrors.cellphone = 'Telefone obrigatório';
    }
    if (email.trim().length === 0) {
      newErrors.email = 'Email é obrigatório';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitInterest = () => {
    if (!validateForm()) {
      return;
    }
    sendInterestContact(token, {
      phone: clearNumber(cellphone),
      cnpj: clearNumber(cnpj),
      email,
      name,
    })
      .then(() => {
        handleSuccess('Sucesso!', 'Obrigado por demonstrar interesse!');
        Analytics.emitCustomEvent('adiante:requested');
      })
      .catch(() => {
        handleError('Não foi possível enviar seu dados no momento. Tente novamente mais tarde.');
      });

    dispatchAmplitudeEvent('btn-submit-interesse', { vendorName, company, phone, vendorEmail });
    setModal(false);
  };

  return (
    <>
      <Modal show={modal}>
        <ModalContainer>
          <Header>Deixe seu Contato</Header>
          <Content>
            Preencha seus dados e retornaremos em breve com mais informações
            <TextField
              onChange={value => setCnpj(value)}
              value={cnpj}
              label="CNPJ"
              error={errors?.cnpj}
              errorMessage={errors?.cnpj}
              mask="99.999.999/9999-99"
              hintText="Digite o CNPJ"
            />
            <TextField
              onChange={value => setName(value)}
              value={name}
              label="Razão Social"
              hintText="Digite a razão Social"
              error={errors?.name}
              errorMessage={errors?.name}
            />
            <TextField
              onChange={value => setCellphone(value)}
              value={cellphone}
              label="Telefone com DDD"
              hintText="Digite o telefone com DDD"
              mask="(99) 99999-9999"
              error={errors?.cellphone}
              errorMessage={errors?.cellphone}
            />
            <TextField
              onChange={value => setEmail(value)}
              value={email}
              label="Email"
              hintText="Digite o seu email"
              error={errors?.email}
              errorMessage={errors?.email}
            />
          </Content>
          <ActionsRow>
            <Button onClick={submitInterest}>ENVIAR</Button>
          </ActionsRow>
        </ModalContainer>
      </Modal>
      <Container>
        <Banner onClick={() => showModal()} />
      </Container>
    </>
  );
};

VendorInterest.propTypes = {
  vendor: PropTypes.objectOf(PropTypes.any).isRequired,
  token: PropTypes.string.isRequired,
};

export default VendorInterest;
