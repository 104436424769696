import React from 'react';
import PropTypes from 'prop-types';

import { Container, ContentBlock, Text, Title } from './ManageData.styles';
import { formatUpdateHRef, formatRemoveHRef } from './utils';

const ManageData = ({ account: { kind, tradeName, name, cnpj } }) => {
  const updateHRef = formatUpdateHRef(kind === 'pf' ? name : tradeName, cnpj);
  const removeHRef = formatRemoveHRef(kind === 'pf' ? name : tradeName, cnpj);

  return (
    <Container>
      <ContentBlock>
        <Title>Atualizar dados</Title>
        <Text>
          Caso deseje atualizar seus dados, <a href={updateHRef}>clique aqui</a> ou envie um e-mail
          para <a href={updateHRef}>cadastro@libercapital.com.br</a> com a sua solicitação.
        </Text>
      </ContentBlock>
      <ContentBlock>
        <Title>Excluir dados</Title>
        <Text>
          Caso deseje excluir seu cadastro, <a href={removeHRef}>clique aqui</a> ou envie um e-mail
          para <a href={removeHRef}>cadastro@libercapital.com.br</a> com a sua solicitação.
        </Text>
      </ContentBlock>
    </Container>
  );
};

ManageData.propTypes = {
  account: PropTypes.shape({
    kind: PropTypes.string,
    tradeName: PropTypes.string,
    name: PropTypes.string,
    cnpj: PropTypes.string,
  }).isRequired,
};

export default ManageData;
