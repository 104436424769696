import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import {
  SummaryRow,
  BlueDot,
  MoneyIcon,
  SummaryInfo,
  SummaryLabel,
  SummaryValue,
  PlusIcon,
  WalletIcon,
  RedDot,
  GreyDot,
  MinusIcon,
} from './StatementEntry-style';

const SummaryEntry = ({ entry, type }) => {
  const baseType = entry['_name']; // eslint-disable-line
  switch (baseType) {
    case 'deposit':
      return (
        <SummaryRow>
          <BlueDot size="28px">
            <MoneyIcon size="13px" />
          </BlueDot>
          <SummaryInfo>
            <SummaryLabel>{entry.name}</SummaryLabel>
            <SummaryValue>
              <PlusIcon />
              {currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' })}
            </SummaryValue>
          </SummaryInfo>
        </SummaryRow>
      );
    case 'receipt':
      return (
        <SummaryRow>
          <BlueDot size="28px">
            <WalletIcon size="13px" />
          </BlueDot>
          <SummaryInfo>
            <SummaryLabel>{entry.name}</SummaryLabel>
            <SummaryValue>
              <PlusIcon />
              {currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' })}
            </SummaryValue>
          </SummaryInfo>
        </SummaryRow>
      );
    case 'purchase':
      return (
        <SummaryRow>
          <RedDot size="28px">
            <WalletIcon size="13px" />
          </RedDot>
          <SummaryInfo>
            <SummaryLabel>{entry.name}</SummaryLabel>
            <SummaryValue>
              <MinusIcon />
              {currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' })}
            </SummaryValue>
          </SummaryInfo>
        </SummaryRow>
      );
    case 'fee':
      return (
        <SummaryRow>
          <RedDot size="28px">%</RedDot>
          <SummaryInfo>
            <SummaryLabel>{entry.name}</SummaryLabel>
            <SummaryValue>
              <MinusIcon />
              {currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' })}
            </SummaryValue>
          </SummaryInfo>
        </SummaryRow>
      );
    case 'withdraw':
      return (
        <SummaryRow>
          <RedDot size="28px">
            <MoneyIcon size="13px" />
          </RedDot>
          <SummaryInfo>
            <SummaryLabel>{entry.name}</SummaryLabel>
            <SummaryValue>
              <MinusIcon />
              {currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' })}
            </SummaryValue>
          </SummaryInfo>
        </SummaryRow>
      );
    default:
      return (
        <SummaryRow>
          <GreyDot size="summary" />
          <SummaryInfo>
            <SummaryLabel>{entry.name}</SummaryLabel>
            <SummaryValue>
              {type === 'debit' ? <MinusIcon /> : null}
              {type === 'credit' ? <PlusIcon /> : null}
              {currencyFormatter.format(entry.amount, { code: 'BRL', format: '%s%v' })}
            </SummaryValue>
          </SummaryInfo>
        </SummaryRow>
      );
  }
};

SummaryEntry.propTypes = {
  entry: PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
  }),
  type: PropTypes.string,
};

SummaryEntry.defaultProps = {
  entry: {
    name: '',
    amount: 0,
  },
  type: '',
};

export default SummaryEntry;
