import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { Container, DatePicker, DatePickerWrapper, FieldLabel, PickersRow } from './styles';
import { updateDateFilters } from '../../actions/VendorReportsActions';

const stringToDate = string => (string ? moment(string, 'DD/MM/YYYY') : undefined);

export function RangeSelect({ id, label, updateFilters }) {
  const [startPeriod, setStartPeriod] = useState(null);

  const handleChange = period => value => {
    if (period === 'start') {
      setStartPeriod(stringToDate(value));
      updateFilters({ key: `q[${id}_gteq]`, value });
    } else if (period === 'end') {
      updateFilters({ key: `q[${id}_lteq]`, value });
    }
  };

  const handleClear = period => () => {
    handleChange(period)(null);
  };

  useEffect(() => {
    // it's not possible to pass a func as prop to DatePicker `clear` icon
    const startClearIcon = document.querySelector(
      `#${id} > div > div:nth-child(1) > div > div > svg:nth-child(3)`,
    );
    const endClearIcon = document.querySelector(
      `#${id} > div > div:nth-child(2) > div > div > svg:nth-child(3)`,
    );
    startClearIcon.addEventListener('click', handleClear('start'));
    endClearIcon.addEventListener('click', handleClear('end'));
  }, []);

  return (
    <Container id={id}>
      <FieldLabel>{label}</FieldLabel>
      <PickersRow>
        <DatePickerWrapper>
          <DatePicker
            data-testid="start-picker"
            label="Início do período"
            onChange={handleChange('start')}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePicker
            data-testid="end-picker"
            label="Fim do período"
            onChange={handleChange('end')}
            minDate={startPeriod}
          />
        </DatePickerWrapper>
      </PickersRow>
    </Container>
  );
}

RangeSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  updateFilters: PropTypes.func,
};

RangeSelect.defaultProps = {
  updateFilters: () => {},
};

const mapDispatchToProps = {
  updateFilters: updateDateFilters,
};

export default connect(null, mapDispatchToProps)(RangeSelect);
