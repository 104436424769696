import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { getFingerprint } from 'client';
import { Checkbox, Form, RadioButton, TextField, handleThemeFromObject } from 'liber-components';
import Flash from '../Flash';
import {
  LoginCard,
  LoginContent,
  BlueLink,
  Title,
  LoginSeparator,
  ButtonGreen,
  ButtonBlue,
  ButtonDiv,
  RadioDiv,
  Logo,
} from '../styles';

const Investidor = ({
  path,
  fingerprint,
  components,
  flashes,
  passwordPath,
  registrationPath,
  documento,
  documentoType,
  documentoError,
  password,
  passwordError,
  remember,
  changeDocumento,
  changeDocumentoType,
  changePassword,
  toogleRemember,
  setFingerprint,
  submitLogin,
  theme,
}) => (
  <LoginCard>
    <LoginContent>
      <Title>INVESTIDOR</Title>
      <Flash flashes={flashes} />
      <Form
        action={path}
        method="POST"
        onSubmit={event => {
          const { target } = event;
          event.preventDefault();
          getFingerprint().then(data => {
            setFingerprint(data);
            submitLogin(target, documento, password);
          });
        }}
      >
        <input
          value={components}
          type="hidden"
          name="investidor[components]"
          id="investidor_components"
        />
        <input
          value={fingerprint}
          type="hidden"
          name="investidor[fingerprint]"
          id="investidor_fingerprint"
        />
        <input
          value="INVESTIDOR/LOGIN"
          type="hidden"
          name="investidor[breadcrumb]"
          id="investidor_breadcrumb"
        />
        <RadioDiv>
          <RadioButton
            labelColor={handleThemeFromObject(theme, 'colors.liberBlue', '#009dff')}
            id="selectCPF"
            name="radio_investidor[cnpj]"
            value="cpf"
            onChange={event => changeDocumentoType(event.target.value)}
            checked={documentoType === 'cpf'}
          >
            CPF
          </RadioButton>
          <RadioButton
            labelColor={handleThemeFromObject(theme, 'colors.liberBlue', '#009dff')}
            id="selectCNPJ"
            name="radio_investidor[cnpj]"
            value="cnpj"
            onChange={event => changeDocumentoType(event.target.value)}
            checked={documentoType === 'cnpj'}
          >
            CNPJ
          </RadioButton>
        </RadioDiv>
        <TextField
          fullWidth
          type="text"
          label={documentoType.toUpperCase()}
          errorMessage={documentoError}
          value={documento}
          onChange={value => changeDocumento(value)}
          name="investidor[cnpj]"
          id="investidor_cnpj"
          mask={documentoType === 'cnpj' ? '99.999.999/9999-99' : '999.999.999-99'}
          maskChar={null}
          openLabel
        />
        <TextField
          fullWidth
          type="password"
          label="Senha"
          errorMessage={passwordError}
          value={password}
          onChange={value => changePassword(value)}
          name="investidor[password]"
          id="investidor_password"
          openLabel
        />
        <div>
          <RadioDiv>
            <Checkbox
              name="investidor[remember_me]"
              id="investidor_remember_me"
              onChange={toogleRemember}
              checked={remember}
            >
              Lembrar
            </Checkbox>
          </RadioDiv>
        </div>
        <ButtonDiv>
          <ButtonGreen width="168px">Entrar</ButtonGreen>
          <ButtonBlue href={registrationPath} width="168px">
            Cadastrar
          </ButtonBlue>
        </ButtonDiv>
        <BlueLink href={passwordPath}>Esqueci minha senha</BlueLink>
      </Form>
    </LoginContent>
    <LoginSeparator />
    <Logo />
  </LoginCard>
);

Investidor.propTypes = {
  path: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  documento: PropTypes.string.isRequired,
  documentoType: PropTypes.string.isRequired,
  documentoError: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  remember: PropTypes.bool.isRequired,
  changeDocumento: PropTypes.func.isRequired,
  changeDocumentoType: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  toogleRemember: PropTypes.func.isRequired,
  submitLogin: PropTypes.func.isRequired,
  registrationPath: PropTypes.string.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
  setFingerprint: PropTypes.func.isRequired,
  fingerprint: PropTypes.string.isRequired,
  components: PropTypes.string.isRequired,
};

Investidor.defaultProps = {
  theme: {},
};

export default Investidor;
