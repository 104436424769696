import styled from 'styled-components';
import { FlatButton } from 'liber-components';

const TEXT_COLOR = '#6287a7';

export const ButtonsRow = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin-top: 32px;
  margin-bottom: 18px;
  bottom: 0;
  position: absolute;
  background-color: white;

  @media (max-height: 600px) {
    z-index: 999;
    width: 95%;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
`;

export const Button = styled(FlatButton).attrs(() => ({ size: 'large' }))``;

export const Content = styled.div`
  padding: 24px;
  max-width: 740px;
  height: 500px;
  overflow-y: scroll;

  @media (max-height: 600px) {
    height: 300px;
    padding-bottom: 80px;
  }
`;

export const TextInfo = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${TEXT_COLOR};
`;

export const Title = styled.h6`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: ${TEXT_COLOR};
`;

export const IntervalInfo = styled.div`
  margin-top: 8px;
`;
