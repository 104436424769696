import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer } from 'liber-components';
import hydrateStore from '../store';
import MultiSignFornecedorView from './components/MultiSignFornecedorView';

const View = ({ store }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <MultiSignFornecedorView />
    </Provider>
    <ToastContainer />
  </>
);

View.propTypes = {
  store: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
  ).isRequired,
};

export default View;
