import React from 'react';
import PropTypes from 'prop-types';
import { sizes } from 'liber-components/components/Util';
import { InfoLabel, SelectField, TextField, LabeledContainer, RadioButton } from 'liber-components';
import { Layout, Form, Row, RadioContainer } from '../styles';
import { Remove, NavigationContainer, Next, Previous } from '../../Navigation';
import { accountLength, getSavingsComplementLabel } from '../../../actions/dadosBancarios';
import { fetchBanks } from '../../../../../vendor/Utils';

class DadosBancarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      small: false,
      banks: {},
    };
  }

  componentDidMount() {
    this.Banks();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ small: window.innerWidth < Number(sizes.small.replace('px', '')) });
  };

  async Banks() {
    const result = await fetchBanks();

    this.setState({
      banks: result.data,
    });
  }

  render() {
    const {
      changeKind,
      changeBankCode,
      changeAgency,
      changeAgencyDigit,
      changeAccount,
      changeAccountDigit,
      changeConjunct,
      changeSavingsComplement,
      dadosBancarios,
      // addAccount,
      removeAccount,
      submitForm,
      onPrevious,
    } = this.props;
    const { small } = this.state;

    return (
      <Layout>
        <h2>Dados Bancários</h2>
        <InfoLabel
          label="A Liber Capital não cobra nenhuma taxa para inscrição de investidores. Pedimos suas informações
          bancárias apenas para que possamos transferir o resgate de seus investimentos."
        />
        {Object.keys(dadosBancarios).map(index => (
          <Form key={index}>
            {dadosBancarios[index].default ? <p>Esta é sua conta principal</p> : null}
            <Row style={{ alignItems: 'end' }}>
              <SelectField
                label="Nome do Banco"
                searchable
                name={`conta_bancaria[${index}][bank_code]`}
                id={`conta_bancaria_${index}_bank_code`}
                errorMessage={dadosBancarios[index].errors.bank_code}
                onChange={event => changeBankCode(event, index)}
                value={dadosBancarios[index].bankCode}
              >
                {this.state.banks.length > 0 &&
                  this.state.banks.map(bank => (
                    <option key={bank.code} value={String(bank.code)}>
                      {`${bank.code} - ${bank.full_name}`}
                    </option>
                  ))}
              </SelectField>
              <LabeledContainer
                label="Tipo de conta"
                errorMessage={dadosBancarios[index].errors.kind}
              >
                <RadioContainer style={{ padding: 0, marginBottom: small ? 0 : 12 }}>
                  <RadioButton
                    name={`conta_bancaria[${index}][kind]`}
                    id={`conta_bancaria_${index}_kind_corrente`}
                    onChange={event => changeKind(event, index)}
                    value="corrente"
                    checked={dadosBancarios[index].kind === 'corrente'}
                  >
                    Conta corrente
                  </RadioButton>
                  <RadioButton
                    name={`conta_bancaria[${index}][kind]`}
                    id={`conta_bancaria_${index}_kind_poupanca`}
                    onChange={event => changeKind(event, index)}
                    value="poupanca"
                    checked={['poupanca', 'poupança'].includes(dadosBancarios[index].kind)}
                  >
                    Conta poupança
                  </RadioButton>
                </RadioContainer>
              </LabeledContainer>
            </Row>
            <Row>
              <TextField
                label="Agência"
                name={`conta_bancaria[${index}][agency]`}
                id={`conta_bancaria_${index}_agency`}
                errorMessage={dadosBancarios[index].errors.agency}
                onChange={event => changeAgency(event, index)}
                value={dadosBancarios[index].agency}
                mask="9999"
                maskChar={null}
              />
              <TextField
                label="Dígito (Opcional)"
                name={`conta_bancaria[${index}][agency_digit]`}
                id={`conta_bancaria_${index}_agency_digit`}
                errorMessage={dadosBancarios[index].errors.agency_digit}
                onChange={event => changeAgencyDigit(event, index)}
                value={dadosBancarios[index].agencyDigit}
                helper="Troque X por 0 quando houver"
                mask="9"
                maskChar={null}
                formatChars={{ 9: '[0-9xX]' }}
              />
              <TextField
                label="Conta"
                name={`conta_bancaria[${index}][account]`}
                id={`conta_bancaria_${index}_account`}
                errorMessage={dadosBancarios[index].errors.account}
                onChange={event => changeAccount(event, index)}
                value={dadosBancarios[index].account}
                mask={'9'.repeat(accountLength(dadosBancarios[index].bankCode))}
                maskChar={null}
              />
              <TextField
                label="Dígito"
                name={`conta_bancaria[${index}][account_digit]`}
                id={`conta_bancaria_${index}_account_digit`}
                errorMessage={dadosBancarios[index].errors.account_digit}
                onChange={event => changeAccountDigit(event, index)}
                value={dadosBancarios[index].accountDigit}
                mask="9"
                maskChar={null}
              />
            </Row>
            <Row>
              {['poupanca', 'poupança'].includes(dadosBancarios[index].kind) &&
              ['001', '104', '341'].includes(dadosBancarios[index].bankCode) ? (
                <TextField
                  label={getSavingsComplementLabel(dadosBancarios[index].bankCode)}
                  name={`conta_bancaria[${index}][savings_complement]`}
                  id={`conta_bancaria_${index}_savings_complement`}
                  errorMessage={dadosBancarios[index].errors.savings_complement}
                  onChange={event => changeSavingsComplement(event, index)}
                  value={dadosBancarios[index].savingsComplement}
                  mask="999"
                  maskChar={null}
                />
              ) : null}
              <LabeledContainer
                label="Conta conjunta"
                errorMessage={dadosBancarios[index].errors.conjunct}
              >
                <RadioContainer style={{ justifyContent: 'center', padding: 0 }}>
                  <RadioButton
                    name={`conta_bancaria[${index}][conjunct]`}
                    id={`conta_bancaria_${index}_conjunct_yes`}
                    onChange={event => changeConjunct(event, index)}
                    value="true"
                    checked={dadosBancarios[index].conjunct}
                  >
                    Sim
                  </RadioButton>
                  <RadioButton
                    name={`conta_bancaria[${index}][conjunct]`}
                    id={`conta_bancaria_${index}_conjunct_no`}
                    onChange={event => changeConjunct(event, index)}
                    value="false"
                    checked={!dadosBancarios[index].conjunct}
                  >
                    Não
                  </RadioButton>
                </RadioContainer>
              </LabeledContainer>
            </Row>
            {!dadosBancarios[index].default ? (
              <Remove
                label="Remover conta"
                onClick={() => removeAccount(index, dadosBancarios[index].id)}
              />
            ) : null}
          </Form>
        ))}
        {/* <Add label="Adicionar outra conta" onClick={addAccount} /> */}
        <NavigationContainer>
          <Previous onClick={onPrevious} />
          <Next onClick={() => submitForm(dadosBancarios)} style={{ float: 'right' }} />
        </NavigationContainer>
      </Layout>
    );
  }
}

DadosBancarios.propTypes = {
  changeKind: PropTypes.func.isRequired,
  changeBankCode: PropTypes.func.isRequired,
  changeAgency: PropTypes.func.isRequired,
  changeAgencyDigit: PropTypes.func.isRequired,
  changeAccount: PropTypes.func.isRequired,
  changeAccountDigit: PropTypes.func.isRequired,
  changeConjunct: PropTypes.func.isRequired,
  changeSavingsComplement: PropTypes.func.isRequired,
  dadosBancarios: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      default: PropTypes.bool,
      kind: PropTypes.string,
      bankCode: PropTypes.string,
      agency: PropTypes.string,
      agencyDigit: PropTypes.string,
      account: PropTypes.string,
      accountDigit: PropTypes.string,
      conjunct: PropTypes.bool,
      savingsComplement: PropTypes.string,
      errors: PropTypes.objectOf(PropTypes.string),
      isPatch: PropTypes.bool,
    }),
  ).isRequired,
  // addAccount: PropTypes.func.isRequired,
  removeAccount: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default DadosBancarios;
