import styled from 'styled-components';
import { Grid, Card as RawCard } from 'liber-salti';

export const CardGrid = styled(Grid)`
  column-gap: 14px;
`;

export const Card = styled(RawCard)`
  width: 100%;
`;
