import React, { useEffect, useState } from 'react';
import { Button, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Container, TextBold, TextUnderline, ContainerButton, GlobalStyle } from './styles.jsx';
import { createSubscription } from '../../../../vendor/action-cable.js';

const KYC_URL = 'https://cadastro.io/?token=f515ac3ef677986889b922f3c98bbacc';

const OperatorKyc = ({ status: initialStatus, redirectUrl, operator: { id: operatorId } }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(initialStatus);

  const successfulKyc = url => {
    setStatus('approved');
    setLoading(false);
    window.location.href = url;
  };

  const failureKyc = url => {
    setStatus('reproved');
    setLoading(false);
    window.location.href = url;
  };

  const transactionAction = {
    KYC_TRANSACTION_SUCCESS: successfulKyc,
    KYC_TRANSACTION_FAILURE: failureKyc,
  };

  useEffect(() => {
    createSubscription(`operation_auth_kyc_channel${operatorId}`, '', {
      onReceived: data => {
        const { type, redirect_url: url } = data;
        const action = transactionAction[type];
        if (!action) {
          return;
        }
        action(url);
      },
    });
  }, []);

  const handleStartKyc = () => {
    setLoading(true);
    window.open(KYC_URL, '_blank').focus();
  };

  const handleFinishKyc = () => {
    window.location.href = redirectUrl;
  };

  return (
    <>
      <GlobalStyle />
      <Container show={status !== 'reproved'}>
        <Typography variant="h1">Complete sua verificação de identidade (KYC)</Typography>
        <Typography>
          <TextBold>
            Parece que você ainda não completou a verificação de identidade em nossa plataforma.
          </TextBold>
        </Typography>
        <Typography>
          <TextBold>
            Para garantir sua segurança e evitar fraudes, precisaremos realizar a verificação de
            seus documentos e prova de vida.
          </TextBold>
        </Typography>
        <Typography>
          Você precisará fornecer seu <TextBold>RG</TextBold> ou <TextBold>CNH</TextBold> e tirar
          uma selfie em um ambiente claro. O processo leva, em média, <TextBold>5 minutos</TextBold>
          .
        </Typography>
        <Typography>
          Ao concluir a verificação, você poderá prosseguir em nossa plataforma.
        </Typography>
        <ContainerButton>
          <Button
            variant="contained"
            onClick={handleStartKyc}
            startIcon={<CameraAltOutlinedIcon fontSize="24px" />}
          >
            Verificar identidade
          </Button>
          <Button
            variant="outlined"
            onClick={handleFinishKyc}
            startIcon={
              loading ? (
                <CircularProgress size="24px" />
              ) : (
                <CheckCircleOutlineIcon fontSize="24px" />
              )
            }
          >
            {status !== 'approved' && 'Já fiz a verificação'}
            {!loading && status === 'approved' && 'Validado'}
          </Button>
        </ContainerButton>
        <Typography>
          Precisa de ajuda? Acesse nosso <TextUnderline>chat</TextUnderline>
        </Typography>
      </Container>
      <Container show={status === 'reproved'}>
        <Typography variant="h1">Verificação de identidade requer atenção</Typography>
        <Typography>
          <TextBold>
            Parece que sua verificação de identidade na plataforma ainda não foi concluída com
            sucesso.
          </TextBold>
        </Typography>
        <Typography>
          Para garantir sua segurança e evitar fraudes, precisaremos realizar a verificação de seus
          documentos e prova de vida.
        </Typography>
        <Typography>
          Se a verificação ainda apresentar pendências, entre em contato com nosso time de suporte
          através do <TextUnderline>chat</TextUnderline> ou pelo e-mail{' '}
          <TextUnderline>suporte@libercapital.com.br</TextUnderline>.
        </Typography>
      </Container>
    </>
  );
};

OperatorKyc.propTypes = PropTypes.shape({
  operator: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
}).isRequired;

OperatorKyc.defaultProps = {};

export default OperatorKyc;
