import { useEffect, useState } from 'react';
import { convertToCamelCase } from '../../../../../vendor/Utils';
import { fetchVendorGroups } from '../../../api';

export const useVendorGroups = token => {
  const [vendorGroupsList, setVendorGroupsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getVendorGroups = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchVendorGroups(token);
      const parsedData = convertToCamelCase(data);
      setVendorGroupsList(
        parsedData.data.vendorsGroups.map(({ id, name, default: isDefaultGroup }) => ({
          value: id,
          option: name,
          isDefaultGroup,
        })),
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVendorGroups();
  }, []);

  return { vendorGroupsList, isLoading };
};
