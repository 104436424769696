import styled, { createGlobalStyle } from 'styled-components';
import {
  Button,
  LoadingBunny as Bunny,
  FlatLink,
  handleThemeFromObject as getTheme,
} from 'liber-components';

export const DisableBootstrap = createGlobalStyle`
  a:hover {
    color: var(--button-text-color)
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #ecf0f3;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.08px;
  text-align: left;
  color: #4b6f85;
`;

export const IconButton = styled(Button).attrs(() => ({ circle: true, version: 2, size: 'large' }))`
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  min-width: 36px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 32px;
  width: 488px;
`;

export const ValuesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingBunny = styled(Bunny).attrs(() => ({ loop: true }))`
  width: 161px;
  height: 128px;
`;

export const SpacingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-left: auto;
`;

export const DownloadLink = styled(FlatLink).attrs(() => ({ target: '_blank' }))`
  svg {
    fill: ${getTheme('colors.primary', '#009dff')};
  }
`;
