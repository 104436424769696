import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import VendorBorderoShow from '../../common-components/VendorBorderoShow/index';

const View = props => (
  <>
    <ThemeProvider theme={LiberV4}>
      <VendorBorderoShow {...props} />
    </ThemeProvider>
  </>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  fornecedorId: PropTypes.number.isRequired,
  operator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cpf: PropTypes.string,
  }).isRequired,
  borderoIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default View;
