import { useEffect } from 'react';

export function useOnClickOutside(ref, callbackHandler) {
  useEffect(() => {
    const listener = event => {
      const el = ref?.current;
      if (!el || el.contains(event.target)) {
        return;
      }
      callbackHandler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, callbackHandler]);
}
