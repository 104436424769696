import React from 'react';
import PropTypes from 'prop-types';
import { TooltipBox } from 'liber-components';

export function UnavailableStatusTooltip({ children, unavailableReason }) {
  return unavailableReason ? (
    <TooltipBox mount="top" fixed content={unavailableReason}>
      <div>{children}</div>
    </TooltipBox>
  ) : (
    children
  );
}

UnavailableStatusTooltip.propTypes = {
  children: PropTypes.node,
  unavailableReason: PropTypes.string,
};

UnavailableStatusTooltip.defaultProps = {
  children: <div />,
  unavailableReason: '',
};

export default UnavailableStatusTooltip;
