import Immutable from 'seamless-immutable';
import { SAVE_INVOICE, FETCH_INVOICE } from '../actions/InvoiceDialogActions';

export const initialState = Immutable({
  invoice: null,
  loading: true,
});

export default (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case SAVE_INVOICE: {
      const {
        payload: { invoice },
      } = action;
      return state.merge({
        loading: false,
        invoice,
      });
    }

    case FETCH_INVOICE:
      return state.merge({ loading: true });

    default:
      return state;
  }
};
