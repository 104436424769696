import React from 'react';
import PropTypes from 'prop-types';
import SaltiThemeWrapper from '../SaltiThemeWrapper';

import { routes, userActions } from './constants';

import SaltiSidebar from '../SaltiSidebar';
import { dispatchAmplitudeEvent } from '../../vendor/Utils';

const FunderSidebar = ({ cookieDomain, logoUrl, name, portalName, paths, userPaths, funder }) => {
  const navigate = (path, option) => {
    dispatchAmplitudeEvent('funder_sidebar', { option });
    window.location.assign(path);
  };

  const { pathname } = window.location;

  const userMenu = userActions(pathname, userPaths, navigate);

  const sidebarRoutes = routes(paths, funder);

  return (
    <SaltiThemeWrapper>
      <SaltiSidebar
        cookieDomain={cookieDomain}
        logoUrl={logoUrl}
        name={name}
        portalName={portalName}
        sidebarRoutes={sidebarRoutes}
        userMenu={userMenu}
      />
    </SaltiThemeWrapper>
  );
};

FunderSidebar.propTypes = {
  cookieDomain: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  portalName: PropTypes.string.isRequired,
  paths: PropTypes.objectOf(PropTypes.any).isRequired,
  userPaths: PropTypes.objectOf(PropTypes.any).isRequired,
  funder: PropTypes.objectOf(
    PropTypes.shape({
      isPf: PropTypes.bool,
      isPj: PropTypes.bool,
      isPedingSignUp: PropTypes.bool,
      kind: PropTypes.string,
      hasFunderAgreements: PropTypes.bool,
      isAllowedToIrpf: PropTypes.bool,
      isAllowedToSpreadsheetRates: PropTypes.bool,
      canAddEarningToRates: PropTypes.bool,
      canCreateRemittances: PropTypes.bool,
      finishedSignUp: PropTypes.bool,
      approvedAt: PropTypes.string,
      isAllowedToSignBordereaux: PropTypes.bool,
    }),
  ).isRequired,
};

FunderSidebar.defaultProps = {};

export default FunderSidebar;
