import React from 'react';
import { TooltipBox } from 'liber-components/components/Helpers';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import { PaddingLeft, ShowIconButton } from './components/CommonStyles';
import { VENDORS_PATH } from './constants';

const commonColumn = (isFunder = false) => [
  {
    name: 'vendor',
    groupedColumns: ['company', 'tradeName', 'cnpj'],
    label: <>&nbsp;&nbsp;&nbsp;Empresa</>,
    width: isFunder ? 25 : 50,
    getCellValue: vendor => {
      const { tradeName, company, cnpj } = vendor;
      return (
        <PaddingLeft>
          {tradeName}
          <br />
          {company}
          <br />
          {cnpj}
        </PaddingLeft>
      );
    },
  },
];

const VENDORS_CELLS = [
  {
    name: 'email',
    label: 'E-mail',
    width: 20,
  },
  {
    name: 'actions',
    label: 'Ações',
    width: 8,
    align: 'center',
    getCellValue: vendor => (
      <TooltipBox mount="top" fixed content="Ver detalhes">
        <ShowIconButton href={`${VENDORS_PATH}/${vendor.id}`}>
          <Icon path={mdiInformation} />
        </ShowIconButton>
      </TooltipBox>
    ),
  },
];

const columns = (isFunder = false) =>
  isFunder ? commonColumn(isFunder) : [...commonColumn(), ...VENDORS_CELLS];

export default columns;
