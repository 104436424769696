import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LabeledContainer } from 'liber-components';
import { Countries, capitalize, fetchBanks } from '../../../../../vendor/Utils';
import { Button, Icon, Label, StepContainer } from './styles';
import { Layout, Row } from '../styles';
import { NavigationContainer, Next, Previous } from '../../Navigation';

const Confirmacao = ({
  dadosBancarios,
  dadosPessoais,
  documentacao,
  enderecos,
  setCurrent,
  fetchContratos,
  onPrevious,
  onNext,
}) => {
  const [banks, setBanks] = useState([]);

  const banksDataList = useMemo(
    () => Object.keys(dadosBancarios || {}).map(index => dadosBancarios[index]),
    [dadosBancarios],
  );

  useEffect(() => {
    fetchBanks().then(({ data }) => setBanks(data));
  }, []);

  return (
    <Layout>
      <h2>Confirmação</h2>
      <p>
        Quase lá! Agora é só confirmar se os dados fornecidos estão corretos e enviaremos seu
        cadastro para análise.
      </p>

      <StepContainer>
        <Label>
          Dados Pessoais
          <Button onClick={() => setCurrent(1)}>
            <Icon />
          </Button>
        </Label>
        <Row>
          <LabeledContainer label="Nome" width="256px">
            <div>{dadosPessoais.name || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="CPF" width="256px">
            <div>{dadosPessoais.cpf || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="" />
        </Row>
        <Row>
          <LabeledContainer label="Email" width="256px">
            <div>{dadosPessoais.email || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Celular" width="256px">
            <div>{dadosPessoais.cellphone || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Telefone (opcional)" width="256px">
            <div>{dadosPessoais.phone || 'Não informado'}</div>
          </LabeledContainer>
        </Row>
        <Row>
          <LabeledContainer label="Gênero" width="256px">
            <div>{dadosPessoais.gender ? capitalize(dadosPessoais.gender) : 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Data de Nascimento" width="256px">
            <div>{dadosPessoais.birthDate || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Estado Civil" width="256px">
            <div>
              {dadosPessoais.civilStatus ? capitalize(dadosPessoais.civilStatus) : 'Não informado'}
            </div>
          </LabeledContainer>
        </Row>
        <Row>
          <LabeledContainer label="País de Origem" width="256px">
            <div>
              {dadosPessoais.countryOrigin
                ? Object.keys(Countries).find(key => Countries[key] === dadosPessoais.countryOrigin)
                : 'Não informado'}
            </div>
          </LabeledContainer>
          <LabeledContainer label="UF de Nascimento" width="256px">
            <div>{dadosPessoais.stateOrigin || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Cidade de Nascimento" width="256px">
            <div>{dadosPessoais.cityOrigin || 'Não informado'}</div>
          </LabeledContainer>
        </Row>
      </StepContainer>

      <StepContainer>
        <Label>
          Documentação
          <Button onClick={() => setCurrent(2)}>
            <Icon />
          </Button>
        </Label>
        <Row>
          <LabeledContainer label="Tipo de documento" width="256px">
            <div>{documentacao.kind ? documentacao.kind.toUpperCase() : 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Número" width="256px">
            <div>{documentacao.number || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="" />
        </Row>
        <Row>
          <LabeledContainer label="Data de Emissão" width="256px">
            <div>{documentacao.emissionDate || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Órgão Expedidor" width="256px">
            <div>{documentacao.emissionIssuer || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Estado de Emissão" width="256px">
            <div>{documentacao.emissionState || 'Não informado'}</div>
          </LabeledContainer>
        </Row>
        <Row>
          <LabeledContainer label="Nome da Mãe" width="256px">
            <div>{documentacao.motherName || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="Nome do Pai (opcional)" width="256px">
            <div>{documentacao.fatherName || 'Não informado'}</div>
          </LabeledContainer>
          <LabeledContainer label="" />
        </Row>
        <Row>
          <LabeledContainer label="Você é uma pessoa Politicamente Exposta (PEP)?" width="256px">
            <div>{documentacao.pep ? 'Sim' : 'Não'}</div>
          </LabeledContainer>
        </Row>
      </StepContainer>

      <StepContainer>
        <Label>
          Endereços
          <Button onClick={() => setCurrent(3)}>
            <Icon />
          </Button>
        </Label>
        {Object.keys(enderecos).map(index => (
          <div key={_.uniqueId(index)} style={{ marginBottom: 20 }}>
            {/* {
                enderecos[index].kind !== null
                  ? <p>Este é seu endereço principal</p>
                  : null
              } */}
            <Row>
              <LabeledContainer label="Rua, Av., etc." width="256px">
                <div>{enderecos[index].address || 'Não informado'}</div>
              </LabeledContainer>
            </Row>
            <Row>
              <LabeledContainer label="Número" width="256px">
                <div>{enderecos[index].number || 'Não informado'}</div>
              </LabeledContainer>
              <LabeledContainer label="Complemento" width="256px">
                <div>{enderecos[index].complement || 'Não informado'}</div>
              </LabeledContainer>
              <LabeledContainer label="CEP" width="256px">
                <div>{enderecos[index].zipCode || 'Não informado'}</div>
              </LabeledContainer>
            </Row>
            <Row>
              <LabeledContainer label="Bairro" width="256px">
                <div>{enderecos[index].district || 'Não informado'}</div>
              </LabeledContainer>
              <LabeledContainer label="Cidade" width="256px">
                <div>{enderecos[index].city || 'Não informado'}</div>
              </LabeledContainer>
              <LabeledContainer label="Estado" width="256px">
                <div>{enderecos[index].state || 'Não informado'}</div>
              </LabeledContainer>
            </Row>
            <Row>
              <LabeledContainer label="País" width="256px">
                <div>
                  {enderecos[index].country
                    ? Object.keys(Countries).find(
                        key => Countries[key] === enderecos[index].country,
                      )
                    : 'Não informado'}
                </div>
              </LabeledContainer>
            </Row>
          </div>
        ))}
      </StepContainer>

      <StepContainer>
        <Label>
          Dados Bancários
          <Button onClick={() => setCurrent(4)}>
            <Icon />
          </Button>
        </Label>
        {banksDataList.map(
          ({
            default: isDefault,
            bankCode,
            kind,
            conjunct,
            agency,
            agencyDigit,
            account,
            accountDigit,
            savingsComplement,
          }) => (
            <div key={bankCode} style={{ marginBottom: 20 }}>
              {isDefault ? <p>Esta é sua conta principal</p> : null}
              <Row>
                <LabeledContainer label="Nome do Banco" width="256px">
                  <div>
                    {banks.find(({ code }) => code === bankCode)?.full_name || 'Não informado'}
                  </div>
                </LabeledContainer>
                <LabeledContainer label="Tipo de conta" width="256px">
                  <div>{kind === 'corrente' ? 'Conta corrente' : 'Conta poupança'}</div>
                </LabeledContainer>
                <LabeledContainer label="Conta conjunta" width="256px">
                  <div>{conjunct ? 'Sim' : 'Não'}</div>
                </LabeledContainer>
              </Row>
              <Row>
                <LabeledContainer label="Agência" width="256px">
                  <div>
                    {`${agency || 'Não informado'}
                    ${agencyDigit && `- ${agencyDigit}`}`}
                  </div>
                </LabeledContainer>
                <LabeledContainer label="Conta" width="256px">
                  <div>
                    {`${account || 'Não informado'} -
                      ${accountDigit || 'Não informado'}`}
                  </div>
                </LabeledContainer>
                {['poupanca', 'poupança'].includes(kind) &&
                ['001', '104', '341'].includes(bankCode) ? (
                  <LabeledContainer label="Número da poupança" width="256px">
                    <div>{savingsComplement || 'Não informado'}</div>
                  </LabeledContainer>
                ) : (
                  <LabeledContainer label="" />
                )}
              </Row>
            </div>
          ),
        )}
      </StepContainer>

      <NavigationContainer>
        <Previous onClick={onPrevious} />
        <Next
          label="Confirmar!"
          onClick={() => fetchContratos(onNext)}
          style={{ float: 'right' }}
        />
      </NavigationContainer>
    </Layout>
  );
};

Confirmacao.propTypes = {
  dadosBancarios: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      default: PropTypes.bool,
      kind: PropTypes.string,
      bankCode: PropTypes.string,
      agency: PropTypes.string,
      agencyDigit: PropTypes.string,
      account: PropTypes.string,
      accountDigit: PropTypes.string,
      conjunct: PropTypes.bool,
      errors: PropTypes.objectOf(PropTypes.string),
      isPatch: PropTypes.bool,
      savingsComplement: PropTypes.string,
    }),
  ).isRequired,
  dadosPessoais: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.objectOf(PropTypes.string)]),
  ).isRequired,
  documentacao: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.objectOf(PropTypes.string)]),
  ).isRequired,
  enderecos: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string,
      number: PropTypes.string,
      complement: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      district: PropTypes.string,
      zipCode: PropTypes.string,
      errors: PropTypes.objectOf(PropTypes.string),
      isPatch: PropTypes.bool,
    }),
  ).isRequired,
  setCurrent: PropTypes.func.isRequired,
  fetchContratos: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Confirmacao;
