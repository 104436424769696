import React from 'react';
import { PrimaryButton } from 'liber-components/components/Buttons/Buttons';
import * as S from './styles';
import { useCreateOrganizationGroup } from '../../context';
import { handleNavigate } from '../../../../../../vendor/Utils';

const FormFooter = () => {
  const { submitForm, isEditing } = useCreateOrganizationGroup();
  const createButtonText = `${isEditing ? 'EDITAR' : 'CRIAR'} GRUPO`;
  return (
    <S.ButtonsGroup>
      <PrimaryButton
        outlined
        version={2}
        onClick={() => handleNavigate('/admin/grupos-economicos')}
      >
        CANCELAR
      </PrimaryButton>
      <PrimaryButton version={2} onClick={submitForm}>
        {createButtonText}
      </PrimaryButton>
    </S.ButtonsGroup>
  );
};

export default FormFooter;
