import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import {
  getScreenSize,
  Modal,
  Drawer,
  TableHeader,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  Accordion,
  toast,
  TooltipBox,
  handleThemeFromObject,
} from 'liber-components';
import MyWallet from '../MyWallet';
import WalletMean from '../WalletMean';
import NextForecast from '../NextForecast';
import Distribution from '../Distribution';
import LiquidationsCard from '../LiquidationsCard';
import {
  Row,
  Cell,
  Container,
  LoadingBlock,
  TooltipContent,
  TooltipTitle,
  TooltipValue,
  TooltipRow,
  InfoIcon,
} from '../styles';
import {
  ModalContainer,
  DrawerContainer,
  Badge,
  Table,
  DraweeTitle,
  AccordionLabel,
  DraweeAmount,
  Loading,
  Close,
  CardHeader,
  CloseButton,
  InvoicesList,
  InvoiceListItem,
  InvoiceTop,
  InvoiceBottom,
  Divider,
  InvoiceModalList,
  InvoiceModalHeader,
  ModalRow,
  InvoiceModalContainer,
  InvoiceModalItemValue,
  InvoiceModalItemTitle,
  ToolTipInfo,
  ModalClose,
  ModalTitle,
  SummaryTitleRow,
  SummaryRow,
  SummaryInfo,
  SummaryValue,
} from './styles';
import { fixPercent } from '../../../../vendor/Utils';
import ScreenTitle from '../../../../common-components/ScreenTitle/ScreenTitle';
import InvoicesDialog from '../InvoicesDialog';
import AlertsContainer from './AlertsContainer';

class InvestorDashboard extends Component {
  constructor() {
    super();
    this.state = {
      screenSize: getScreenSize(window),
      modalInvoice: false,
      modalContent: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  componentWillReceiveProps(nextProps) {
    const { status, error } = nextProps;
    if (!status && status !== this.props.status) {
      toast(error ? error.message : 'Ocorreu um erro!', 'error', 8000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  getPercent = number => `${fixPercent(number)}%`;

  fixPercent = num => parseFloat((num * 100).toPrecision(12)).toFixed(2);

  resize = () => {
    this.setState({ screenSize: getScreenSize(window), modalInvoice: false, modalContent: null });
  };

  showInvoiceModal = (invoice, drawee) => {
    const { theme } = this.props;
    this.setState({
      modalInvoice: true,
      modalContent: (
        <InvoiceModalContainer>
          <InvoiceModalHeader>
            <TooltipTitle
              color={handleThemeFromObject(this.props.theme, 'colors.liberBlue', '#009dff')}
              style={{ marginBottom: '0px' }}
            >
              Título {invoice.number}
            </TooltipTitle>
            <ModalClose
              onClick={() => {
                this.setState({ modalInvoice: false });
              }}
            >
              <Close />
            </ModalClose>
          </InvoiceModalHeader>
          <InvoiceModalHeader>
            <InvoiceModalItemTitle>Sacado:</InvoiceModalItemTitle>
            <InvoiceModalItemValue>{drawee.drawee.trade_name}</InvoiceModalItemValue>
            <InvoiceModalItemTitle>Fornecedor:</InvoiceModalItemTitle>
            <InvoiceModalItemValue>{invoice.supplier.trade_name}</InvoiceModalItemValue>
          </InvoiceModalHeader>
          <InvoiceModalList>
            <ModalRow>
              <InvoiceModalItemTitle>Prazo:</InvoiceModalItemTitle>
              <Divider />
              <InvoiceModalItemValue>
                {invoice.term.working} {invoice.term.working === 1 ? 'dia útil' : 'dias úteis'}
              </InvoiceModalItemValue>
            </ModalRow>
            <ModalRow>
              <InvoiceModalItemTitle>Vencimento:</InvoiceModalItemTitle>
              <Divider />
              <InvoiceModalItemValue>{invoice.liquidation_date}</InvoiceModalItemValue>
            </ModalRow>
            <ModalRow>
              <InvoiceModalItemTitle>Valor a Receber:</InvoiceModalItemTitle>
              <Divider />
              <InvoiceModalItemValue>
                {currencyFormatter.format(invoice.to_receive, { code: 'BRL', format: '%s%v' })}
              </InvoiceModalItemValue>
            </ModalRow>
            <ModalRow>
              <InvoiceModalItemTitle>Valor Investido:</InvoiceModalItemTitle>
              <Divider />
              <InvoiceModalItemValue>
                {currencyFormatter.format(invoice.invested, { code: 'BRL', format: '%s%v' })}
              </InvoiceModalItemValue>
            </ModalRow>
            <ModalRow>
              <InvoiceModalItemTitle
                color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}
              >
                Rendimento:
              </InvoiceModalItemTitle>
              <Divider />
              <InvoiceModalItemValue
                color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}
              >
                {currencyFormatter.format(invoice.yield, { code: 'BRL', format: '%s%v' })}
              </InvoiceModalItemValue>
            </ModalRow>
            <ModalRow>
              <InvoiceModalItemTitle
                color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}
              >
                Rentabilidade.:
              </InvoiceModalItemTitle>
              <Divider />
              <InvoiceModalItemValue
                color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}
              >
                {this.getPercent(invoice.profitability)} a.m.
              </InvoiceModalItemValue>
            </ModalRow>
          </InvoiceModalList>
        </InvoiceModalContainer>
      ),
    });
  };

  mapInvoicesToListItem = (drawee, invoice) => (
    <InvoiceListItem
      key={invoice.slug}
      onClick={() => {
        this.showInvoiceModal(invoice, drawee);
      }}
    >
      <InvoiceTop>
        <span>{invoice.number}</span>
        <span>{invoice.term.working} dias</span>
      </InvoiceTop>
      <InvoiceBottom>
        <span>{currencyFormatter.format(invoice.to_receive, { code: 'BRL', format: '%s%v' })}</span>
        <Divider />
        <span>{invoice.liquidation_date}</span>
      </InvoiceBottom>
    </InvoiceListItem>
  );

  mapInvoicesToTableRow = invoice => {
    const { theme } = this.props;
    const tooltipContent = (
      <TooltipContent>
        <TooltipRow>
          <TooltipTitle>Valor a Receber:</TooltipTitle>
          <TooltipValue>
            {currencyFormatter.format(invoice.to_receive, { code: 'BRL', format: '%s%v' })}
          </TooltipValue>
        </TooltipRow>
        <TooltipRow>
          <TooltipTitle>Valor Investido:</TooltipTitle>
          <TooltipValue>
            {currencyFormatter.format(invoice.invested, { code: 'BRL', format: '%s%v' })}
          </TooltipValue>
        </TooltipRow>
        <TooltipRow>
          <TooltipTitle color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}>
            Rendimento:
          </TooltipTitle>
          <TooltipValue color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}>
            {currencyFormatter.format(invoice.yield, { code: 'BRL', format: '%s%v' })}
          </TooltipValue>
        </TooltipRow>
        <TooltipRow>
          <TooltipTitle color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}>
            Rentabilidade:
          </TooltipTitle>
          <TooltipValue color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}>
            {this.getPercent(invoice.profitability)} a.m.
          </TooltipValue>
        </TooltipRow>
        <TooltipRow>
          <TooltipTitle color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}>
            Rentabilidade/CDI:
          </TooltipTitle>
          <TooltipValue color={handleThemeFromObject(theme, 'colors.successGreen', '#13ce66')}>
            {this.getPercent(invoice.reference.cdi)}
          </TooltipValue>
        </TooltipRow>
      </TooltipContent>
    );
    return (
      <TableRow key={invoice.slug}>
        <TableRowColumn>{invoice.number}</TableRowColumn>
        <TableRowColumn>{invoice.supplier.trade_name}</TableRowColumn>
        <TableRowColumn>{invoice.liquidation_date}</TableRowColumn>
        <TableRowColumn>
          {invoice.term.working} {invoice.term.working === 1 ? 'dia útil' : 'dias úteis'}
        </TableRowColumn>

        <TableRowColumn>
          {currencyFormatter.format(invoice.to_receive, { code: 'BRL', format: '%s%v' })}
        </TableRowColumn>

        <TableRowColumn>
          {this.getPercent(invoice.profitability)} a.m.
          <TooltipBox
            key={invoice.slug}
            label={`Título ${invoice.number}`}
            mount="top"
            content={tooltipContent}
            fixed
          >
            <ToolTipInfo>
              <InfoIcon />
            </ToolTipInfo>
          </TooltipBox>
        </TableRowColumn>
      </TableRow>
    );
  };

  mapDraweesToList = drawee => (
    <Accordion
      key={drawee.drawee.slug}
      headerColor={handleThemeFromObject(this.props.theme, 'colors.liberWhite', '#fafafa')}
      open
      label={
        <AccordionLabel>
          <Badge>{drawee.invoices.length}</Badge>
          <DraweeTitle>{drawee.drawee.trade_name}</DraweeTitle>
          <DraweeAmount>
            {currencyFormatter.format(
              drawee.invoices.reduce((prev, next) => prev + parseFloat(next.to_receive), 0),
              { code: 'BRL', format: '%s %v' },
            )}
          </DraweeAmount>
        </AccordionLabel>
      }
    >
      <InvoicesList>
        {drawee.invoices.map((...args) => this.mapInvoicesToListItem(drawee, ...args))}
      </InvoicesList>
    </Accordion>
  );

  mapDraweesToTable = drawee => (
    <Accordion
      key={drawee.drawee.slug}
      open
      label={
        <AccordionLabel>
          <Badge>{drawee.invoices.length}</Badge>
          <DraweeTitle>{drawee.drawee.trade_name}</DraweeTitle>
          <DraweeAmount>
            {currencyFormatter.format(
              drawee.invoices.reduce((prev, next) => prev + next.to_receive, 0),
              { code: 'BRL', format: '%s %v' },
            )}
          </DraweeAmount>
        </AccordionLabel>
      }
    >
      <Table key={drawee.drawee.slug}>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn>Número</TableHeaderColumn>
            <TableHeaderColumn>Fornecedor</TableHeaderColumn>
            <TableHeaderColumn>Liquidação</TableHeaderColumn>
            <TableHeaderColumn>Prazo</TableHeaderColumn>
            <TableHeaderColumn>Valor a Receber</TableHeaderColumn>
            <TableHeaderColumn>Rentabilidade</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>{drawee.invoices.map(this.mapInvoicesToTableRow)}</TableBody>
      </Table>
    </Accordion>
  );

  invoices = () => {
    const { screenSize, modalInvoice, modalContent } = this.state;
    if (screenSize === 'small' || screenSize === 'medium') {
      return (
        <>
          {this.drawerInvoices()}
          <Modal
            show={modalInvoice}
            onLeaved={() => {
              this.setState({ modalInvoice: false, modalContent: null });
            }}
          >
            {modalContent}
          </Modal>
        </>
      );
    }
    return <>{this.modalInvoices()}</>;
  };

  drawerInvoices = () => {
    const { show, data, loading, closeInvoiceList, modalTitle } = this.props;
    return (
      <Drawer show={show} onLeaved={closeInvoiceList} fitScreen>
        <CardHeader>
          <ModalTitle>{modalTitle}</ModalTitle>
          <CloseButton onClick={closeInvoiceList}>
            <Close />
          </CloseButton>
        </CardHeader>
        <DrawerContainer>
          {loading ? <Loading loop /> : null}
          <LoadingBlock loading={loading}>{data.map(this.mapDraweesToList)}</LoadingBlock>
        </DrawerContainer>
      </Drawer>
    );
  };

  renderModalSummary = () => {
    const { modalTitle } = this.props;
    const wallet = this.props.wallet.data || {};

    const {
      invested = 0,
      to_settle: toSettle = 0,
      payment_overdue: paymentOverdue = 0,
      payment_conciliation: paymentConciliation = 0,
      yield: yieldValue = 0,
    } = wallet;

    if (modalTitle.toUpperCase().includes('EM CONCILIAÇÃO')) {
      return (
        <>
          <SummaryTitleRow>Resumo</SummaryTitleRow>
          <SummaryRow>
            <SummaryInfo>
              Total em conciliação:
              <SummaryValue>
                {currencyFormatter.format(paymentConciliation, { code: 'BRL', format: '%s%v' })}
              </SummaryValue>
            </SummaryInfo>
          </SummaryRow>
        </>
      );
    }
    if (modalTitle.toUpperCase().includes('EM ATRASO')) {
      return (
        <>
          <SummaryTitleRow>Resumo</SummaryTitleRow>
          <SummaryRow>
            <SummaryInfo>
              Total em atraso:
              <SummaryValue>
                {currencyFormatter.format(paymentOverdue, { code: 'BRL', format: '%s%v' })}
              </SummaryValue>
            </SummaryInfo>
          </SummaryRow>
        </>
      );
    }
    if (modalTitle.toUpperCase().includes('A RECEBER')) {
      return (
        <>
          <SummaryTitleRow>Resumo</SummaryTitleRow>
          <SummaryRow>
            <SummaryInfo>
              Total a liquidar:
              <SummaryValue>
                {currencyFormatter.format(toSettle, { code: 'BRL', format: '%s%v' })}
              </SummaryValue>
            </SummaryInfo>
            <SummaryInfo>
              Total investido:
              <SummaryValue>
                {currencyFormatter.format(invested, { code: 'BRL', format: '%s%v' })}
              </SummaryValue>
            </SummaryInfo>
            <SummaryInfo>
              Rendimento:
              <SummaryValue>
                {currencyFormatter.format(yieldValue, { code: 'BRL', format: '%s%v' })}
              </SummaryValue>
            </SummaryInfo>
          </SummaryRow>
        </>
      );
    }
    return undefined;
  };

  modalInvoices = () => {
    const { show, data, loading, closeInvoiceList, modalTitle } = this.props;
    const summary = this.renderModalSummary();
    return (
      <Modal show={show} onLeaved={closeInvoiceList}>
        <CardHeader style={{ position: 'relative' }}>
          <ModalTitle>{modalTitle}</ModalTitle>
          <ModalClose onClick={closeInvoiceList}>
            <Close />
          </ModalClose>
        </CardHeader>
        {summary}
        <ModalContainer hasSummary={Boolean(summary)}>
          {loading ? <Loading loop /> : null}
          <LoadingBlock loading={loading}>{data.map(this.mapDraweesToTable)}</LoadingBlock>
        </ModalContainer>
      </Modal>
    );
  };

  render() {
    const { token, title } = this.props;
    return (
      <>
        <InvoicesDialog />
        <this.invoices />
        <Container>
          {title?.length > 0 ? <ScreenTitle>Meu Painel</ScreenTitle> : null}
          <AlertsContainer token={token} />
          <Row>
            <Cell>
              <MyWallet token={token} />
            </Cell>
            <Cell>
              <Distribution token={token} />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <WalletMean />
            </Cell>
            <Cell>
              <NextForecast />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <LiquidationsCard token={token} />
            </Cell>
          </Row>
        </Container>
      </>
    );
  }
}

InvestorDashboard.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  closeInvoiceList: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  theme: PropTypes.objectOf(PropTypes.any),
  wallet: PropTypes.objectOf(PropTypes.any),
};

InvestorDashboard.defaultProps = {
  title: '',
  theme: {},
  wallet: {},
};

export default InvestorDashboard;
