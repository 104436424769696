import React from 'react';
import {
  mdiFormatListBulleted,
  mdiPen,
  mdiFile,
  mdiCloseCircle,
  mdiDotsVertical,
  mdiReceipt,
} from '@mdi/js';
import { TooltipBox } from 'liber-components';

import { SecondaryButton, ButtonIcon } from './styles';

export const InvoicesButton = props => (
  <TooltipBox key="InvoicesButton-tooltip" mount="top" fixed content="Títulos e detalhes">
    <SecondaryButton onlyIcon ml={4} {...props}>
      <ButtonIcon path={mdiFormatListBulleted} />
    </SecondaryButton>
  </TooltipBox>
);

export const SignaturesButton = props => (
  <TooltipBox key="SignaturesButton-tooltip" mount="top" fixed content="Assinaturas">
    <SecondaryButton onlyIcon ml={16} {...props}>
      <ButtonIcon path={mdiPen} />
    </SecondaryButton>
  </TooltipBox>
);

export const TransferAgreementsButton = props => (
  <TooltipBox
    key="TransferAgreementsButton-tooltip"
    mount="top"
    fixed
    content="Baixar termos de cessão"
  >
    <SecondaryButton onlyIcon ml={16} {...props}>
      <ButtonIcon path={mdiFile} />
    </SecondaryButton>
  </TooltipBox>
);

export const CancelButton = props => (
  <TooltipBox key="CancelButton-tooltip" mount="top" fixed content="Desistir da antecipação">
    <SecondaryButton onlyIcon ml={16} {...props}>
      <ButtonIcon path={mdiCloseCircle} />
    </SecondaryButton>
  </TooltipBox>
);

export const TaxDocumentButton = props => (
  <TooltipBox key="TaxDocumentButton-tooltip" mount="top" fixed content="Nota fiscal">
    <SecondaryButton onlyIcon ml={4} {...props}>
      <ButtonIcon path={mdiReceipt} />
    </SecondaryButton>
  </TooltipBox>
);

export const MoreOptionsButton = props => (
  <TooltipBox key="MoreOptionsButton-tooltip" mount="top" fixed content="Mais opções">
    <SecondaryButton onlyIcon ml={16} {...props}>
      <ButtonIcon path={mdiDotsVertical} />
    </SecondaryButton>
  </TooltipBox>
);

// for testing purposes
export const all = [
  InvoicesButton,
  SignaturesButton,
  TransferAgreementsButton,
  CancelButton,
  MoreOptionsButton,
  TaxDocumentButton,
];
