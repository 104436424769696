import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  TabHeader,
  DropDown,
  DropDownOption as Option,
  getScreenSize,
} from 'liber-components';
import options from './user-type-options';
import { Card, TabHead, SelectHead } from '../Base.styles';

class MyAccount extends Component {
  constructor(props) {
    super(props);
    const { tabs, current } = this.registerTabs(
      props.userType,
      props.acceptedAdhesionTermExternally,
    );
    this.state = {
      tabs,
      current,
      screenSize: getScreenSize(window),
    };
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChange, false);
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange, false);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  handleHashChange = () => {
    const { userType } = this.props;
    const anchor = window.location.hash.substr(1);
    const tabs = options[userType];
    this.setState({
      current: tabs[anchor] ? tabs[anchor].pos || 0 : 0,
    });
  };

  registerTabs = (userType, acceptedAdhesionTermExternally) => {
    const tabs = this.filterTabs(options[userType], acceptedAdhesionTermExternally);
    const anchor = window.location.hash.substr(1);

    return {
      tabs: Object.keys(tabs)
        .map(tab => ({ ...tabs[tab], anchor: tab }))
        .sort(this.sortByPos),
      current: tabs[anchor] ? tabs[anchor].pos || 0 : 0,
    };
  };

  filterTabs = (tabs, acceptedAdhesionTermExternally) => {
    const filteredTabs = tabs;
    if (acceptedAdhesionTermExternally) {
      delete filteredTabs.contratos;
    }

    return filteredTabs;
  };

  sortByPos = (a, b) => {
    if (a.pos < b.pos) {
      return -1;
    }
    if (a.pos > b.pos) {
      return 1;
    }
    return 0;
  };

  handleClones = (label, index) => {
    const { tabs } = this.state;
    return React.cloneElement(label, {
      id: `tab_${tabs[index].anchor}`,
      onClick: () => {
        window.location.hash = tabs[index].anchor;
      },
    });
  };

  handleSelectClick = anchor => {
    window.location.hash = anchor;
  };

  renderTab = tab => (
    <Tab label={tab.label} key={tab.anchor}>
      {tab.content}
    </Tab>
  );

  renderInlineHeader = (children, labels) => {
    const clones = React.Children.toArray(children).map(this.handleClones);
    const clonedLabels = React.cloneElement(labels, {
      children: [clones, labels.props.children[1]],
    });
    return <TabHead>{clonedLabels}</TabHead>;
  };

  renderSelectHeader = (tabs, current, children) => (
    <SelectHead>
      <DropDown
        value={tabs[current].anchor}
        defaultValue={tabs[current].anchor}
        onSelect={this.handleSelectClick}
        position="top-right"
      >
        {React.Children.toArray(children).map((option, index) => (
          <Option value={tabs[index].anchor} key={tabs[index].anchor}>
            {option.props['data-value']}
          </Option>
        ))}
      </DropDown>
    </SelectHead>
  );

  renderLabels = labels => {
    const { screenSize, current, tabs } = this.state;
    const children = labels.props.children[0];
    const inlineHeader = this.renderInlineHeader(children, labels);
    const selectHeader = this.renderSelectHeader(tabs, current, children);
    const renderOptions = {
      small: selectHeader,
      medium: selectHeader,
      large: inlineHeader,
      extraLarge: inlineHeader,
    };
    return renderOptions[screenSize] || inlineHeader;
  };

  render() {
    const { current, tabs } = this.state;
    return (
      <>
        <Card>
          <Tabs active={current}>
            <TabHeader id="tab_header">{this.renderLabels}</TabHeader>
            {tabs.map(this.renderTab)}
          </Tabs>
        </Card>
      </>
    );
  }
}

MyAccount.propTypes = {
  userType: PropTypes.string.isRequired,
  acceptedAdhesionTermExternally: PropTypes.bool.isRequired,
};

export default MyAccount;
