import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4, ToastContainer } from 'liber-components';
import VendorInvoicesComponent from '../../common-components/VendorInvoices';

const View = ({
  token,
  vendorId,
  canInputXml,
  inputXmlPath,
  beforeOperatingStartTime,
  beforeOperatingStartTimeMessage,
  ...rest
}) => (
  <>
    <ThemeProvider theme={LiberV4}>
      <ToastContainer />
      <VendorInvoicesComponent
        token={token}
        vendorId={vendorId}
        canInputXml={canInputXml}
        inputXmlPath={inputXmlPath}
        beforeOperatingStartTime={beforeOperatingStartTime}
        beforeOperatingStartTimeMessage={beforeOperatingStartTimeMessage}
        {...rest}
      />
    </ThemeProvider>
  </>
);

View.propTypes = {
  beforeOperatingStartTime: PropTypes.bool,
  beforeOperatingStartTimeMessage: PropTypes.string,
  token: PropTypes.string.isRequired,
  vendorId: PropTypes.string.isRequired,
  inputXmlPath: PropTypes.string,
  canInputXml: PropTypes.bool,
};

View.defaultProps = {
  beforeOperatingStartTime: false,
  beforeOperatingStartTimeMessage: null,
  inputXmlPath: '',
  canInputXml: false,
};

export default View;
