import styled, { css, keyframes } from 'styled-components';
import { MdFile } from 'liber-components';

export const SimulationContentControl = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: ${({ dense }) => (dense ? '40px' : '24px')};
`;

export const FileIcon = styled(MdFile)`
--dimension: ${({ large }) => (large ? '45px' : '32px')}
  fill: white;
  width: var(--dimension);
  height: var(--dimension);
`;

export const IconContainer = styled.div`
--dimension: ${({ large }) => (large ? '64px' : '45px')}
  width: var(--dimension);
  height: var(--dimension);
  border-radius: 50%;
  background-color: #405f71;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-left: ${({ inDialog }) => (inDialog ? '16px' : 0)};
`;

export const Grid = styled.div`
  display: flex;
  flex-basis: ${({ columnSize = 12 }) => `${(columnSize / 12) * 100}%`};
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ spacing = 0 }) => css`
    margin: ${`-${spacing}px`};
    width: calc(100% - ${2 * spacing}px);
    & > ${Grid} {
      padding: ${`${spacing}px`};
    }
  `}
`;

const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0; 
  }
`;

const shimmerColorLight = 'rgba(75, 111, 133, 0.05)';
const shimmerColorDark = 'rgba(75, 111, 133, 0.08)';

export const ShimmeringField = styled.div`
  width: 124px;
  height: 18px;
  margin: 4px 0;
  border-radius: 4px;

  background: ${shimmerColorLight};
  background-image: linear-gradient(
    to right,
    ${shimmerColorLight} 0%,
    ${shimmerColorDark} 20%,
    ${shimmerColorLight} 40%,
    ${shimmerColorLight} 100%
  );
  background-repeat: no-repeat;
  background-size: 400px 100px;
  display: inline-block;
  position: relative;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
`;
