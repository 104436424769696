import React from 'react';
import PropTypes from 'prop-types';
import {
  InvoiceSimulationNegotiationListContent,
  InvoiceSimulationNegotiationCardList,
  InvoiceSimulationNegotiationListAllNegotiations,
  InvoiceSimulationNegotiationListAllLink,
} from './InvoiceSimulationNegotiationList.styles';
import InvoiceSimulationNegotiationCard from './InvoiceSimulationNegotiationCard';

const InvoiceSimulationNegotiationList = ({ negotiations }) => (
  <InvoiceSimulationNegotiationListContent>
    <InvoiceSimulationNegotiationCardList>
      {negotiations.map(negotiation => (
        <InvoiceSimulationNegotiationCard
          status={negotiation.status}
          invoicesCount={negotiation.count}
          value={negotiation.total}
          detailsUrl={negotiation.page}
          disabled={!negotiation.count}
        />
      ))}
    </InvoiceSimulationNegotiationCardList>
    <InvoiceSimulationNegotiationListAllNegotiations>
      Para ver todos os títulos em negociação,
      <InvoiceSimulationNegotiationListAllLink href="negociacoes">
        clique aqui
      </InvoiceSimulationNegotiationListAllLink>
    </InvoiceSimulationNegotiationListAllNegotiations>
  </InvoiceSimulationNegotiationListContent>
);

InvoiceSimulationNegotiationList.propTypes = {
  negotiations: PropTypes.arrayOf(PropTypes.shape({})),
};

InvoiceSimulationNegotiationList.defaultProps = {
  negotiations: [],
};

export default InvoiceSimulationNegotiationList;
