import { connect } from 'react-redux';
import PasswordResetForm from './PasswordResetForm';
import {
  changeToken,
  changePassword,
  changeConfirmation,
  submitToken,
  submitForm,
} from '../../actions/passwordResetFormActions';

const reducer = 'reset';
const mapStateToProps = state => ({ ...state[reducer] });

const mapDispatchToProps = {
  changeToken,
  changePassword,
  changeConfirmation,
  submitToken,
  submitForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
