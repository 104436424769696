/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { CompleteTable } from 'liber-components';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';

import { getPaginationInterval } from '../../../../vendor/Utils';
import { SpreadsheetRatesContext } from '../../context';
import DetailsDrawer from '../DetailsDrawer';
import Filters from '../Filters';
import * as S from './styles';

function Table() {
  const {
    loading,
    spreadsheets,
    pagination,
    currentPage,
    pages,
    setCurrent,
    next,
    previous,
    setPageState,
    onKeyDownPage,
    per,
    count,
    onSelectListing,
    setShowDetailsModal,
    showDetailsModal,
    setSelectedSpreadsheetId,
    selectedSpreadsheetId,
    token,
  } = useContext(SpreadsheetRatesContext);

  const handleShowDetails = id => {
    setShowDetailsModal(true);
    setSelectedSpreadsheetId(id);
  };

  const COLUMNS = [
    {
      name: 'buyer',
      label: <>&ensp;&ensp;Sacado</>,
      width: 20,
      getCellValue: ({ buyer }) => {
        const { name, cnpj } = buyer;

        return (
          <>
            {name}
            <br />
            {cnpj}
          </>
        );
      },
    },
    {
      name: 'groupName',
      label: 'Grupo',
      width: 20,
      getCellValue: ({ vendorsGroup }) => vendorsGroup?.name,
    },
    {
      name: 'createdAt',
      label: 'Envio',
      width: 20,
      getCellValue: ({ createdAt, uploadMethod }) => (
        <S.FlexColumn>
          {moment.parseZone(createdAt).format('DD/MM/YYYY')} às{' '}
          {moment.parseZone(createdAt).format('HH:mm')}h
          <S.UploadMethod>Via {uploadMethod}</S.UploadMethod>
        </S.FlexColumn>
      ),
    },
    {
      name: 'interval',
      label: 'Periodo de validade',
      width: 20,
      getCellValue: ({ interval }) => {
        const { start, finish } = interval;

        if (finish) {
          return (
            <>
              <span>{`${start} - ${finish}`}</span>
            </>
          );
        }
        return <span>{start}</span>;
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      width: 10,
      getCellValue: ({ id }) => (
        <S.SecondaryButton onClick={() => handleShowDetails(id)}>
          <Icon path={mdiDotsVertical} />
        </S.SecondaryButton>
      ),
    },
  ];

  return (
    <S.Loading loading={loading}>
      {loading && <S.Bunny />}
      <S.ScrollContainer>
        <S.LimiterContainer>
          <CompleteTable
            columns={COLUMNS}
            items={spreadsheets || []}
            filters={<Filters />}
            paginationProps={{
              currentPage,
              pageTotal: pages,
              onChangePage: ({ target: { value } }) => setPageState(value),
              onClickNext: () => setCurrent(next),
              onClickPrev: () => setCurrent(previous),
              onKeyDownPage,
              hasListing: true,
              listingProps: {
                listing: String(per),
                total: String(count),
                showInterval: getPaginationInterval(pagination),
                onSelectListing,
                listingLabelSingular: 'item',
                listingLabelPlural: 'itens',
              },
            }}
          />
        </S.LimiterContainer>
      </S.ScrollContainer>
      <DetailsDrawer
        show={showDetailsModal}
        onLeave={() => setShowDetailsModal(false)}
        token={token}
        spreadsheetId={selectedSpreadsheetId}
      />
    </S.Loading>
  );
}

export default Table;
