import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from 'liber-salti';

import Menu from './Menu';

const Item = ({ label, icon, path, pathname, open, subPaths, navigate, routePatterns }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const itemRef = useRef();

  const secondaryIconName = useMemo(() => (menuOpen ? 'ChevronLeft' : 'ChevronRight'), [menuOpen]);

  const selected = useMemo(() => routePatterns.some(pattern => pattern.test(pathname)), [pathname]);

  const hasSubpaths = useMemo(() => Boolean(subPaths?.length > 0), [subPaths]);

  return (
    <>
      <ListItem
        key={label}
        variant="icon"
        iconName={icon}
        primaryText={label}
        collapsed={!open}
        onClick={() => (hasSubpaths ? setMenuOpen(true) : navigate(path, label))}
        selected={selected}
        active={menuOpen}
        ref={itemRef}
        secondaryIconName={hasSubpaths ? secondaryIconName : undefined}
      />
      {hasSubpaths && (
        <Menu
          subPaths={subPaths}
          open={menuOpen}
          setOpen={setMenuOpen}
          anchorEl={itemRef.current}
          pathname={pathname}
          navigate={navigate}
        />
      )}
    </>
  );
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  subPaths: PropTypes.arrayOf(PropTypes.any),
  navigate: PropTypes.func.isRequired,
  routePatterns: PropTypes.arrayOf(PropTypes.any),
};

Item.defaultProps = {
  subPaths: [],
  routePatterns: [],
};

export default Item;
