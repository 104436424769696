import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const UploadContext = createContext({});

const UploadContextProvider = ({ token, exampleFiles, children }) => (
  <UploadContext.Provider value={{ token, exampleFiles }}>{children}</UploadContext.Provider>
);

UploadContextProvider.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  exampleFiles: PropTypes.shape({
    term: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default UploadContextProvider;
