import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { LiberV4 } from 'liber-components';
import VendorList from './components/VendorList';

const VendorTransferList = ({ vendors, currentVendors }) => (
  <ThemeProvider theme={LiberV4}>
    <VendorList vendors={vendors} currentVendors={currentVendors} />
  </ThemeProvider>
);

VendorTransferList.propTypes = {
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.string,
      cnpj: PropTypes.string,
      trade_name: PropTypes.string,
    }),
  ),
  currentVendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.string,
      cnpj: PropTypes.string,
      trade_name: PropTypes.string,
    }),
  ),
};

VendorTransferList.defaultProps = {
  vendors: [],
  currentVendors: [],
};
export default VendorTransferList;
