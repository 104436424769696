import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, getScreenSize, SecondaryButton } from 'liber-components';
import VMasker from 'vanilla-masker';
import { Countries, States } from '../../../../../vendor/Utils';
import {
  Row,
  CardContent,
  Divider,
  HeaderRow,
  Info,
  EditButton,
  EraseButton,
  ButtonContainer,
  AddButton,
  DefaultButton,
  ButtonsContainer,
  Drawer,
  ConfirmationContent,
  ConfirmationText,
  ConfirmationButtons,
  SubmitButton,
  StarIcon,
} from '../../Base.styles';
import Form from './InvestorAddressForm';
import {
  closeModalForm as close,
  hidrateForm as hidrate,
  removeAddress as remove,
  setDefaultAddress as setDefault,
} from '../../../actions/address/InvestorAddressActions';

export class InvestorAddress extends Component {
  constructor() {
    super();
    this.state = {
      screenSize: getScreenSize(window),
      confirmationModal: false,
      erase: {},
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  handleEdit = (address, index) => {
    const { hidrateForm } = this.props;
    hidrateForm(address, index);
  };

  handleEraseConfirmation = (id, index) => {
    this.setState({
      confirmationModal: true,
      erase: { id, index },
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      confirmationModal: false,
      erase: {},
    });
  };

  handleErase = () => {
    const { id, index } = this.state.erase;
    const { removeAddress } = this.props;
    this.setState(
      {
        confirmationModal: false,
        erase: {},
      },
      () => {
        removeAddress(id, index);
      },
    );
  };

  handleNewAddress = () => {
    const { hidrateForm } = this.props;
    hidrateForm({}, -1);
  };

  handleCobranca = id => {
    const { setDefaultAddress } = this.props;
    setDefaultAddress(id);
  };

  isCobranca = kind => kind && ['cobranca', 'cobrança'].includes(kind.toLowerCase());

  renderAddress = (address, index, adresses, loading) => (
    <div key={_.uniqueId(address.id)}>
      <HeaderRow>
        <h2>
          {this.isCobranca(address.kind) ? <StarIcon /> : null}
          Endereço {index + 1}
          {this.isCobranca(address.kind) ? <small>Cobrança</small> : null}
        </h2>
        <ButtonContainer>
          {!address.kind || !['cobranca', 'cobrança'].includes(address.kind.toLowerCase()) ? (
            <>
              <DefaultButton
                loading={loading === 'default'}
                onClick={() => {
                  this.handleCobranca(address.id);
                }}
              >
                Marcar como Cobrança
              </DefaultButton>
              <EraseButton
                loading={loading === 'delete'}
                onClick={() => {
                  this.handleEraseConfirmation(address.id, index);
                }}
              >
                Apagar
              </EraseButton>
            </>
          ) : null}
          <EditButton
            onClick={() => {
              this.handleEdit(address, index);
            }}
          >
            Editar
          </EditButton>
        </ButtonContainer>
      </HeaderRow>
      <Row>
        <Info>
          Rua, Av., etc. <span>{address.address}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Número <span>{address.number}</span>
        </Info>
        <Info>
          Complemento: <span>{address.complement}</span>
        </Info>
        <Info>
          CEP: <span>{VMasker.toPattern(address.zipCode, '99.999-999')}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Bairro: <span>{address.district}</span>
        </Info>
        <Info>
          Cidade <span>{address.city}</span>
        </Info>
        <Info>
          Estado <span>{Object.keys(States).find(key => States[key] === address.state)}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          País:{' '}
          <span>{Object.keys(Countries).find(key => Countries[key] === address.country)}</span>
        </Info>
      </Row>
      <Divider last={index === adresses.length - 1} />
    </div>
  );

  render() {
    const { adresses, showAddressForm, closeModalForm, loading } = this.props;
    const { screenSize, confirmationModal } = this.state;
    const Dialog = screenSize === 'large' || screenSize === 'extraLarge' ? Modal : Drawer;
    return (
      <>
        <Dialog show={showAddressForm} onLeaved={closeModalForm} closeButton>
          <Form />
        </Dialog>
        <Modal show={confirmationModal} onLeaved={this.handleCloseConfirmation} closeButton>
          <ConfirmationContent>
            <ConfirmationText>Tem certeza que deseja apagar esse endereço?</ConfirmationText>
            <ConfirmationButtons>
              <SecondaryButton onClick={this.handleCloseConfirmation}>Não</SecondaryButton>
              <SubmitButton onClick={this.handleErase}>Sim</SubmitButton>
            </ConfirmationButtons>
          </ConfirmationContent>
        </Modal>
        <CardContent>
          <HeaderRow>
            <h1>Endereços</h1>
          </HeaderRow>
          {adresses.map((...args) => this.renderAddress(...[...args, loading]))}

          <ButtonsContainer>
            <AddButton onClick={this.handleNewAddress}>Novo endereço</AddButton>
          </ButtonsContainer>
        </CardContent>
      </>
    );
  }
}

InvestorAddress.propTypes = {
  adresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      number: PropTypes.string,
      complement: PropTypes.string,
      district: PropTypes.string,
      zipCode: PropTypes.string,
      kind: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
  ).isRequired,
  showAddressForm: PropTypes.bool.isRequired,
  loading: PropTypes.string.isRequired,
  closeModalForm: PropTypes.func.isRequired,
  hidrateForm: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
  setDefaultAddress: PropTypes.func.isRequired,
};

const parentReducer = 'myAccount';
const tab = 'address';
const type = 'investor';
const reducer = 'data';

const mapStateToProps = state => ({ ...state[parentReducer][tab][type][reducer] });
const mapDispatchToProps = {
  closeModalForm: close,
  hidrateForm: hidrate,
  removeAddress: remove,
  setDefaultAddress: setDefault,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvestorAddress);
