import React from 'react';
import * as S from './styles';
import { useBuyerOrganizationGroup } from '../../context';

const GroupInfo = () => {
  const { buyerGroup } = useBuyerOrganizationGroup();

  return (
    <div>
      <S.Title>Dados do grupo econômico</S.Title>
      <S.Row>
        <S.Label>Nome do grupo econômico: </S.Label>
        <S.Value>{buyerGroup.groupName}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Trader responsável: </S.Label>
        <S.Value>{buyerGroup.traderName}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Email do trader responsável: </S.Label>
        <S.Value>{buyerGroup.traderEmail}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Comercial: </S.Label>
        <S.Value>{buyerGroup.commercialName}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Email do comercial: </S.Label>
        <S.Value>{buyerGroup.commercialEmail}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Segmento: </S.Label>
        <S.Value>{buyerGroup.buyerGroupCategory && buyerGroup.buyerGroupCategory.name}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Porte: </S.Label>
        <S.Value>{buyerGroup.kind}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>ID do grupo: </S.Label>
        <S.Value>{buyerGroup.id}</S.Value>
      </S.Row>
      <S.Row>
        <S.Label>Replicar cadastro dos fornecedores: </S.Label>
        <S.Value>{buyerGroup.allowVendorReplication === 'true' ? 'Sim' : 'Não'}</S.Value>
      </S.Row>
    </div>
  );
};

export default GroupInfo;
