import styled from 'styled-components';

export const MutedText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 350px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  cursor: pointer;
`;

export const RolesText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #6287a7;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 350px;
`;
