import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';
import { v4 as uuid } from 'uuid';

import VendorsGroups from './components/VendorsGroups';
import withTokenProvider from '../../hocs/withTokenProvider';
import { VendorsGroupsStore } from './context';

function View({ exampleFile }) {
  return (
    <ThemeProvider theme={Theme}>
      <VendorsGroupsStore roomId={uuid()} exampleFile={exampleFile}>
        <VendorsGroups />
        <ToastContainer />
      </VendorsGroupsStore>
    </ThemeProvider>
  );
}

View.propTypes = {
  exampleFile: PropTypes.string,
};

View.defaultProps = {
  exampleFile: '',
};

export default withTokenProvider(View, 'buyer_jwt');
