import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const StatusTag = ({ color, fullWidth, children, notificationContent }) => {
  const Wrapper = notificationContent ? S.Badge : Fragment;
  return (
    <Wrapper content={notificationContent}>
      <S.Tag color={color} fullWidth={fullWidth}>
        {children}
      </S.Tag>
    </Wrapper>
  );
};

StatusTag.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'error',
    'waiting',
    'success',
    'available',
    'unavailable',
    'blocked',
  ]),
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  notificationContent: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

StatusTag.defaultProps = {
  color: 'primary',
  fullWidth: false,
  notificationContent: null,
};

export default StatusTag;
