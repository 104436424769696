import styled from 'styled-components';
import {
  MoreVert,
  AttachMoney,
  Description,
  Button,
  SelectionTableHeaderColumn as TableHeaderColumn,
  SelectionTableRow,
  CheckboxTable as Checkbox,
} from 'liber-components';

export const CheckboxTable = styled(Checkbox)`
  & > span {
    padding: 0;
  }
`;

export const TableRow = styled(SelectionTableRow)`
  &:first-of-type {
    border-top: none;
  }
`;

export const SelectionTableHeaderColumn = styled(TableHeaderColumn)`
  height: 48px;
  border: none;
`;

export const MoreVertIcon = styled(MoreVert)`
  width: 16px;
  height: 16px;
`;

export const DropDownButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 8px;
  background-color: #ecf0f3;
`;

export const DropDownContent = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
`;

export const DropDownContentRow = styled.a`
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  margin-top: 3px;
  color: inherit;
`;

export const DropDownContentRowIcon = styled.div`
  margin-right: 8px;
`;

export const MoneyIcon = styled(AttachMoney)`
  width: 16px;
  height: 16px;
  fill: #4b6f85;
`;

export const DescriptionIcon = styled(Description)`
  width: 16px;
  height: 16px;
  fill: #4b6f85;
`;

export const DropDownContentRelative = styled.div`
  position: relative;
`;

export const ActionHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-right: 17px;
`;
