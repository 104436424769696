import PropTypes from 'prop-types';

export const AnticipationPropTypes = PropTypes.shape({
  averageRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  discount: PropTypes.string,
  id: PropTypes.number,
  invoiceCount: PropTypes.number,
  legalAgents: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      userHasSigned: PropTypes.bool,
      userSignedAt: PropTypes.string,
    }),
  ),
  mappedStatus: PropTypes.string,
  netValue: PropTypes.string,
  paidInvoiceCount: PropTypes.number,
  recovered: PropTypes.bool,
  requestedAt: PropTypes.string,
  requestedBy: PropTypes.string,
  signatures: PropTypes.shape({
    done: PropTypes.number,
    required: PropTypes.number,
  }),
  status: PropTypes.string,
  userHasSigned: PropTypes.bool,
});

export const AnticipationDefaultPropTypes = {
  averageRate: '0.0',
  discount: 'R$ 0,00',
  id: -1,
  invoiceCount: 0,
  legalAgents: [],
  mappedStatus: 'pending_signature',
  netValue: 'R$ 0,00',
  paidInvoiceCount: 0,
  recovered: false,
  requestedAt: '01/01/2000 às 00:00h',
  requestedBy: null,
  signatures: { done: 0, required: 0 },
  status: 'started',
  userHasSigned: false,
};
