import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  CardContainer,
  CardContent,
  Header,
  Card,
  List,
  ListHeader,
  ListItem,
  Footer,
  Global,
} from './Selection.styles';
import {
  Separator,
  BunnyBox,
  BunnyText,
  BunnyGrow,
  WhiteLink,
  Envelope,
  Phone,
  Bunny,
} from '../styles';

const Payers = ({ payers }) => (
  <List>
    <ListHeader>Comprador</ListHeader>
    {payers.map(payer => {
      const { organization, path } = payer;
      const { cnpj, trade_name: tradeName } = organization;
      return (
        <ListItem key={_.uniqueId(`${cnpj}_`)}>
          <a href={path}>{tradeName}</a>
        </ListItem>
      );
    })}
  </List>
);

Payers.propTypes = {
  payers: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      role: PropTypes.string,
      target: PropTypes.objectOf(PropTypes.string),
      organization: PropTypes.shape({
        trade_name: PropTypes.string,
        cnpj: PropTypes.string,
      }),
    }),
  ).isRequired,
};

const Vendors = ({ vendors }) => (
  <List>
    <ListHeader>Fornecedor</ListHeader>
    {vendors.map(vendor => {
      const { organization, path, target } = vendor;
      const { cnpj, trade_name: tradeName } = organization;
      const { trade_name: targetName } = target;
      return (
        <ListItem key={cnpj}>
          <a href={path}>
            {tradeName} <span>Fornecedor da {targetName}</span>
          </a>
        </ListItem>
      );
    })}
  </List>
);

Vendors.propTypes = {
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      role: PropTypes.string,
      target: PropTypes.objectOf(PropTypes.string),
      organization: PropTypes.shape({
        trade_name: PropTypes.string,
        cnpj: PropTypes.string,
      }),
    }),
  ).isRequired,
};

const Funders = ({ funders }) => (
  <List>
    <ListHeader>Investidor</ListHeader>
    {funders.map(funder => {
      const { organization, path } = funder;
      const { cnpj, trade_name: tradeName } = organization;
      return (
        <ListItem key={_.uniqueId(`${cnpj}_`)}>
          <a href={path}>{tradeName}</a>
        </ListItem>
      );
    })}
  </List>
);

Funders.propTypes = {
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      role: PropTypes.string,
      target: PropTypes.objectOf(PropTypes.string),
      organization: PropTypes.shape({
        trade_name: PropTypes.string,
        cnpj: PropTypes.string,
      }),
    }),
  ).isRequired,
};

const Selection = ({ operator, organizations }) => {
  const { name, logout } = operator;
  const { payer: payers, vendor: vendors, funder: funders } = organizations;

  return (
    <>
      <Global />
      <CardContainer>
        <Card>
          <Header>
            <h1>
              Olá, <b>{name}</b>
            </h1>
            <p>Qual empresa você gostaria de acessar?</p>
          </Header>
          <CardContent>
            {payers.length > 0 ? <Payers payers={payers} /> : null}
            {vendors.length > 0 ? <Vendors vendors={vendors} /> : null}
            {funders.length > 0 ? <Funders funders={funders} /> : null}
          </CardContent>
          <Footer>
            <a href={logout} data-method="delete">
              Sair
            </a>
          </Footer>
        </Card>
      </CardContainer>
      <Separator />
      <BunnyBox>
        <BunnyText />
        <BunnyGrow>
          <Bunny />
        </BunnyGrow>
        <BunnyText>
          <li>
            <Envelope />
            <WhiteLink href="mailto:contato@libercapital.com.br">
              contato@libercapital.com.br
            </WhiteLink>
          </li>
          <li>
            <Phone />
            +55 (11) 3534-7808
          </li>
        </BunnyText>
      </BunnyBox>
    </>
  );
};

Selection.propTypes = {
  operator: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    cpf: PropTypes.string,
    logout: PropTypes.string,
  }).isRequired,
  organizations: PropTypes.shape({
    count: PropTypes.number,
    main_access_path: PropTypes.string,
    payer: Payers.propTypes.payers,
    vendor: Vendors.propTypes.vendors,
    funder: Funders.propTypes.funders,
  }).isRequired,
};

export default Selection;
