import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableHeader, TableRow } from 'liber-components';
import { connect } from 'react-redux';
import {
  selectNegotiations,
  unselectNegotiations,
  addCheckedFromSummary,
} from '../../actions/proposalAction';
import {
  TableHeaderColumn,
  TableRowColumn,
  Checkbox,
  CheckboxColumn,
  Spinner,
} from './MultiProposal.styles';

import { Confirmation, Done, Loading } from '../Dialogs';
import DropdownMenu from './DropdownMenu';
import { Summary, SummaryBox } from '../Summary';

export class MultiProposal extends PureComponent {
  componentWillMount() {
    const { checkProposals } = this.props;
    checkProposals();
  }

  handleCheckAll = ({ target }) => {
    const { setCheckbox, unsetCheckbox, funder, negotiations } = this.props;
    const { checked } = target;
    const selected = negotiations.reduce(
      (agg, current, index) => {
        if (checked !== current.checked) {
          return {
            ids: [...agg.ids, current.id.toString()],
            indexes: [...agg.indexes, index],
          };
        }
        return agg;
      },
      { ids: [], indexes: [] },
    );
    const { ids, indexes } = selected;
    if (checked) setCheckbox(ids, indexes, funder);
    else unsetCheckbox(ids, indexes, funder);
  };

  handleCheck = ({ target }) => {
    const { setCheckbox, unsetCheckbox, funder } = this.props;
    const { checked, dataset } = target;
    const { id, index } = dataset;
    if (checked) setCheckbox([id.toString()], [index], funder);
    else unsetCheckbox([id.toString()], [index], funder);
  };

  mapNegotiations = (funder, loading) => (negotiation, index) =>
    (
      <TableRow key={negotiation.id} selected={negotiation.checked}>
        <CheckboxColumn>
          {loading ? (
            <Spinner />
          ) : (
            <Checkbox
              onChange={this.handleCheck}
              show={funder !== ''}
              checked={negotiation.checked || false}
              data-id={negotiation.id}
              data-index={index}
            />
          )}
        </CheckboxColumn>
        <TableRowColumn>{negotiation.number}</TableRowColumn>
        <TableRowColumn>{negotiation.monthly_profitability}</TableRowColumn>
        <TableRowColumn>{negotiation.advance}</TableRowColumn>
        <TableRowColumn>
          {negotiation.deadline}
          <br />
          {negotiation.expected_payment_at}
        </TableRowColumn>
        <TableRowColumn>{negotiation.vendor}</TableRowColumn>
        <TableRowColumn>{negotiation.payer}</TableRowColumn>
        <TableRowColumn>
          <DropdownMenu disableButton={funder !== ''} actions={negotiation.actions} />
        </TableRowColumn>
      </TableRow>
    );

  render() {
    const { funder, negotiations, loading } = this.props;
    const checked =
      negotiations.length !== 0 && negotiations.every(negotiation => negotiation.checked);
    return (
      <>
        <Confirmation />
        <Done />
        <Summary />
        <SummaryBox />
        <Loading />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn width="80px">
                {loading ? (
                  <Spinner />
                ) : (
                  <Checkbox show={funder !== ''} onChange={this.handleCheckAll} checked={checked} />
                )}
              </TableHeaderColumn>
              <TableHeaderColumn width="80px">Nº</TableHeaderColumn>
              <TableHeaderColumn>Rentabilidade</TableHeaderColumn>
              <TableHeaderColumn>Investimento</TableHeaderColumn>
              <TableHeaderColumn>Vencimento / Liquidação</TableHeaderColumn>
              <TableHeaderColumn>Fornecedor</TableHeaderColumn>
              <TableHeaderColumn>Sacado</TableHeaderColumn>
              <TableHeaderColumn width="220px" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {negotiations.length !== 0 ? (
              negotiations.map(this.mapNegotiations(funder, loading))
            ) : (
              <TableRow>
                <TableRowColumn />
                <TableRowColumn colSpan="5" style={{ textAlign: 'left' }}>
                  Nenhum título encontrado
                </TableRowColumn>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  }
}
MultiProposal.propTypes = {
  funder: PropTypes.string.isRequired,
  negotiations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      monthly_profitability: PropTypes.string,
      advance: PropTypes.string,
      deadline: PropTypes.string,
      expected_payment_at: PropTypes.string,
      vendor: PropTypes.string,
      payer: PropTypes.string,
      actions: PropTypes.shape({
        view: PropTypes.shape({
          path: PropTypes.string,
          method: PropTypes.string,
        }),
        cancel: PropTypes.shape({
          path: PropTypes.string,
          method: PropTypes.string,
        }),
        edit: PropTypes.shape({
          path: PropTypes.string,
          method: PropTypes.string,
        }),
        offer: PropTypes.shape({
          path: PropTypes.string,
          method: PropTypes.string,
        }),
        open_negotiation: PropTypes.shape({
          path: PropTypes.string,
          method: PropTypes.string,
        }),
      }),
      number: PropTypes.string,
      amount: PropTypes.string,
      rate: PropTypes.string,
      expires_at: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  setCheckbox: PropTypes.func,
  unsetCheckbox: PropTypes.func,
  checkProposals: PropTypes.func,
};

MultiProposal.defaultProps = {
  setCheckbox: () => {},
  unsetCheckbox: () => {},
  checkProposals: () => {},
};

const mapStateToProps = state => ({
  ...state.multiProposal.proposals,
  funder: state.multiProposal.summary.funder,
  loading: state.multiProposal.summary.summary.loadingCount > 0,
});
const mapDispatchToProps = {
  setCheckbox: selectNegotiations,
  unsetCheckbox: unselectNegotiations,
  checkProposals: addCheckedFromSummary,
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiProposal);
