import styled, { css } from 'styled-components';
import { Card as RawCard, Button } from 'liber-components';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';

const colorReference = {
  positive: '#008a5e',
  negative: '#ff5b56',
  neutral: '#6287a7',
};

export const Card = styled(RawCard)`
  width: calc(calc(100% - 36px) / 3);
  min-width: 298px;
  height: 414px;
  padding: 32px;
  border: solid 1px #cdd7df;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0;
  margin-bottom: 18px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.08px;
  text-align: left;
  color: #4b6f85;
`;

export const FieldLabel = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.06px;
  text-align: left;
  color: #4b6f85;
`;

export const FieldValue = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.84;
  letter-spacing: 0.06px;
  text-align: left;
  color: #6287a7;
`;

export const FlatButton = styled(Button).attrs(() => ({ version: 2 }))`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.02px;
  text-align: right;
  color: ${({ blue }) => (blue ? '#009dff' : '#798e9b')};
  padding: 0;

  & > svg {
    fill: ${({ blue }) => (blue ? '#009dff' : '#798e9b')};
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export const FieldContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const StatusIcon = styled(Icon).attrs({ path: mdiAccount })`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 2px;
  color: ${({ status = 'neutral' }) => colorReference[status]};
`;

export const Caption = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.05px;
  color: #6287a7;
`;

export const CardsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  div:not(:last-child) {
    margin-right: 18px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 16px;
`;
