import styled, { createGlobalStyle, css } from 'styled-components';
import {
  Button,
  TextField as BaseTextField,
  SelectField as BaseSelectField,
  Tag as BaseTag,
  FlatButton as BaseFlatButton,
} from 'liber-components';

export const OverrideBootstrap = createGlobalStyle`
  label {
    font-family: Roboto;
    font-weight: normal;
    color: #8c9fa9 !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Tag = styled(BaseTag).attrs(() => ({ height: 36 }))`
  margin-left: 14px;
  margin-right: 0px;
  overflow-y: hidden;
`;

export const TagContent = styled.div`
  padding-top: 32px;
  text-align: center;
  transition: transform 0.3s ease-out;
  div {
    margin-bottom: 4px;
  }
  :hover {
    transform: translate(0, -26px);
  }
`;

export const ModalContainer = styled.div`
  height: calc(100% - 38px);
  max-width: 1120px;
  margin: 38px auto;
  display: flex;
  flex-flow: column;
`;

export const ModalTitle = styled.div`
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: left;
  color: #009dff;
  padding: 17px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #e8ebee;
`;

export const ModalSubTitle = styled.div`
  font-family: Roboto;
  font-size: 20px;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: left;
  color: #798e9b;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalContent = styled.div`
  width: 100%;
  flex: 1;
  padding: 24px 8px;
`;

export const InputsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc((100% - 66px) / 3);
    margin-bottom: 32px;
  }

  & > div > input {
    max-width: calc(100% - 22px);
  }
`;

export const CheckboxLabel = styled.div`
  color: #97a8b5;
  font-size: 16px;
`;

export const TextField = styled(BaseTextField).attrs(() => ({ version: 2, openLabel: true }))``;

export const SelectField = styled(BaseSelectField).attrs(() => ({
  version: 2,
  openLabel: true,
  searchable: true,
}))`
  & div {
    max-height: unset;
  }
  margin-top: 10px;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px #e8ebee;
  padding: 20px 8px;
  margin-bottom: 12px;

  ${Button} {
    margin-right: 24px;
  }
`;

export const FlatButton = styled(BaseFlatButton)`
  margin-left: 14px;
`;

export const Counter = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.06px;
  text-align: right;
  color: #405f71;
`;

export const FilterModalGlobalStyle = createGlobalStyle`
  html {
    overflow-y: hidden;
  }
  input {
    box-sizing: content-box !important;
  }
`;

export const CustomModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 300;
  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;

  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const DateContainer = styled.div`
  padding: 8px;
`;
