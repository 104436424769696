import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTradeSettlements } from './api';
import { convertToCamelCase, handleError } from '../../vendor/Utils';
import usePagination from '../../hooks/usePagination';
import usePageState from '../../hooks/usePageState';
import { EMPTY_FILTERS_STATE } from './constants';

export const FunderTradesSettlementContext = createContext();

export const FunderTradeSettlementProvider = ({ children, token }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tradeSettlements, setTradeSettlements] = useState([]);
  const [summary, setSummary] = useState({});
  const [filters, setFilters] = useState(EMPTY_FILTERS_STATE);

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const updateFilter = key => value => {
    setFilters({ ...filters, [key]: value });
  };

  const clearFilters = () => {
    setFilters(EMPTY_FILTERS_STATE);
  };

  const fetchTradeSettlements = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await getTradeSettlements(token, { page: current, per, ...filters });
      const formattedData = convertToCamelCase(data);
      setTradeSettlements(formattedData.tradeSettlements);
      setSummary(formattedData.summary);
      if (formattedData.pagination) {
        updatePagination({ ...formattedData.pagination, per });
      }
    } catch (error) {
      handleError('Ocorreu um erro ao requisitar os títulos vendidos.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTradeSettlements();
  }, [per, current]);

  return (
    <FunderTradesSettlementContext.Provider
      value={{
        token,
        isLoading,
        tradeSettlements,
        clearFilters,
        updateFilter,
        onSelectListing,
        currentPage,
        setPageState,
        onKeyDownPage,
        count,
        per,
        setCurrent,
        summary,
        fetchTradeSettlements,
      }}
    >
      {children}
    </FunderTradesSettlementContext.Provider>
  );
};

FunderTradeSettlementProvider.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
};
