import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const Label = styled.div`
  font-family: Roboto;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #405f71;
  margin-right: 16px;
`;

export const ScheduleModalButton = styled(PrimaryButton).attrs(() => ({
  version: 2,
  size: 'large',
}))`
  text-transform: uppercase;
  margin-left: 10px;
  justify-self: end;
  min-width: 160px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const SpaceBetween = styled(Flex)`
  justify-content: space-between;
`;
