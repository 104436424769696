import styled from 'styled-components';
import { handleThemeFromObject } from 'liber-components';

export const AccordionBox = styled.div`
  overflow: hidden;
  width: 100%;
  transition: all 400ms ${props => (props.open ? 'ease-in' : 'ease-out')};
  border-bottom: solid 2px rgba(150, 150, 150, 0.18);
`;

export const ShowBox = styled.div`
  max-height: ${props => (props.open ? '999px' : '0px')};
  transition: max-height 400ms ${props => (props.open ? 'ease-in' : 'ease-out')};
  overflow: hidden;
`;

const AccordionColor = props => {
  if (props.open) {
    return handleThemeFromObject(props.theme, 'colors.liberBlue', '#009DFF');
  }
  if (props.dark) {
    return handleThemeFromObject(props.theme, 'colors.liberBlack', '#4E4E4E');
  }
  return '#828282';
};

export const AccordionAction = styled.a`
  background-color: ${props =>
    props.open
      ? handleThemeFromObject(props.theme, 'colors.lightGrey', '#f7f7f7')
      : handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  padding: 20px 30px;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  transition: padding 400ms ${props => (props.open ? 'ease-in' : 'ease-out')};
  position: relative;

  span,
  &:after {
    color: ${props => AccordionColor(props)};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
    transition: color 400ms ${props => (props.open ? 'ease-in' : 'ease-out')};
  }

  &:after {
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid ${props => AccordionColor(props)};
    pointer-events: none;
    transform: ${props => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: all 400ms ${props => (props.open ? 'ease-in' : 'ease-out')};
  }

  &:hover {
    span {
      color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009DFF')};
    }
    &:after {
      border-top: 8px solid
        ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009DFF')};
    }
    background-color: ${props =>
      props.open
        ? handleThemeFromObject(props.theme, 'colors.lightGrey2', '#f3f3f3')
        : handleThemeFromObject(props.theme, 'colors.lightGrey', '#f7f7f7')};
  }
`;
