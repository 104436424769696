import React from 'react';
import PropTypes from 'prop-types';
import {
  BackgroundContainer,
  BannerButton,
  BannerButtonWrapper,
  BannerSubtitle,
  BannerTextWrapper,
  BannerTitle,
} from './style';

export const Banner = ({ onClick }) => (
  <BackgroundContainer>
    <BannerTextWrapper>
      <BannerTitle>Gostaria de antecipar títulos de outros clientes?</BannerTitle>
      <BannerSubtitle>Nós temos a solução</BannerSubtitle>
    </BannerTextWrapper>
    <BannerButtonWrapper>
      <BannerButton size="small" onClick={onClick} iconName="ArrowForward">
        Saiba mais
      </BannerButton>
    </BannerButtonWrapper>
  </BackgroundContainer>
);

Banner.propTypes = {
  onClick: PropTypes.func.isRequired,
};
