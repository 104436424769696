import React, { memo, useState, useCallback, useRef } from 'react';
import MuiPopper from '@mui/base/PopperUnstyled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PropTypes from 'prop-types';

import { useOnClickOutside } from '../../hooks/useOutsideClick';
import { BlockModal } from './components';

import * as S from './styles';

const VENDOR_STATUS_MAP = {
  pre_approved: 'Pendente',
  unblocked: 'Aprovado',
  blocked: 'Bloqueado',
  under_review: 'Em análise',
};

const SelectStatus = ({ initialStatus = 'unblocked', onChangeStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [blockedMessage, setBlockedMessage] = useState('');
  const [showBlockModal, setShowBlockModal] = useState(false);
  const popperId = isPopperOpen ? 'change-status-popper' : undefined;
  const popperRef = useRef(null);

  const handleButtonClick = useCallback(event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsPopperOpen(prevState => !prevState);
  }, []);

  const handleSelectStatus = useCallback(
    (selectedStatus, message) => {
      onChangeStatus(selectedStatus, message);
      setIsPopperOpen(false);
      setShowBlockModal(false);
      setAnchorEl(null);
      setBlockedMessage('');
    },
    [onChangeStatus],
  );

  const handleBlockConfirm = useCallback(message => {
    setBlockedMessage(message);
    handleSelectStatus('blocked', message);
    setShowBlockModal(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowBlockModal(false);
    setBlockedMessage(null);
  }, []);

  useOnClickOutside(popperRef, () => {
    setIsPopperOpen(false);
    setAnchorEl(null);
  });

  return (
    <S.Container>
      <S.Button
        status={initialStatus}
        aria-describedby={popperId}
        type="button"
        data-testid="select-status-button"
        onClick={handleButtonClick}
      >
        {VENDOR_STATUS_MAP[initialStatus]}
        {isPopperOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </S.Button>
      <MuiPopper
        id={popperId}
        open={isPopperOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 999 }}
        ref={popperRef}
        data-testid="dropdown-content"
      >
        <S.Listbox>
          <S.Option
            disabled={initialStatus === 'unblocked'}
            onClick={() => {
              if (initialStatus !== 'unblocked') {
                handleSelectStatus('unblocked', blockedMessage);
              }
            }}
            data-testid="unblocked-option"
          >
            Aprovar
          </S.Option>
          <S.Option
            disabled={initialStatus === 'blocked'}
            onClick={() => {
              if (initialStatus !== 'blocked') {
                setShowBlockModal(true);
              }
            }}
            data-testid="block-option"
          >
            Bloquear
          </S.Option>
        </S.Listbox>
      </MuiPopper>

      <BlockModal
        isOpen={showBlockModal}
        onCloseModal={handleCloseModal}
        onBlockConfirm={handleBlockConfirm}
      />
    </S.Container>
  );
};

export default memo(SelectStatus);

SelectStatus.propTypes = {
  initialStatus: PropTypes.string,
  onChangeStatus: PropTypes.func,
};

SelectStatus.defaultProps = {
  initialStatus: '',
  onChangeStatus: () => {},
};
