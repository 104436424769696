import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';

import { toast, MdInformation, TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { Card, LoadingBlock, CardHeader, BigTooltipContent } from '../styles';

import {
  DisplayRow,
  Divider,
  MainValueLabel,
  MainValueRow,
  MainValuePrefix,
  MainValue,
  UpdateInfo,
  Flex,
} from './MyWallet.styles';
import Display from './Display';
import { TooltipWrapper } from './Display.styles';

class MyWallet extends Component {
  componentDidMount = () => {
    const { getMyWalletData, token } = this.props;
    getMyWalletData(token);
  };

  componentWillReceiveProps(nextProps) {
    const { status, error } = nextProps;
    if (!status) {
      toast(error ? error.message : 'Ocorreu um erro!', 'error', 8000);
    }
  }

  handleDetailsClick = type => {
    const { getInvoicesData, token } = this.props;
    getInvoicesData(token, {}, '', type);
  };

  formatCurrency = value =>
    currencyFormatter.format(value, { code: 'BRL', format: '%s%v' }).replace('R$', '');

  render() {
    const { loading, getDialogContent } = this.props;
    const {
      balance = 0,
      to_settle: toSettle = 0,
      payment_overdue: paymentOverdue = 0,
      payment_conciliation: paymentConciliation = 0,
    } = this.props.data;

    const balanceAvailability = this.props.data.balance !== null;

    return (
      <>
        <Card width="460px" height="390px">
          <CardHeader>
            MINHA CARTEIRA
            <Flex>
              <Icon path={mdiInformation} size="16px" />
              <UpdateInfo>Os valores são atualizados todos os dias, ao meio-dia.</UpdateInfo>
            </Flex>
          </CardHeader>
          <LoadingBlock loading={loading}>
            <MainValueLabel>
              Patrimônio
              <TooltipBox
                key="my-wallet-tooltip"
                mount="top"
                fixed
                content={
                  <BigTooltipContent width="281px" mobileWidth="191px">
                    Soma dos valores a receber, pagamentos em conciliação, em atraso e o saldo
                    disponível
                  </BigTooltipContent>
                }
              >
                <TooltipWrapper>
                  <MdInformation />
                </TooltipWrapper>
              </TooltipBox>
            </MainValueLabel>
            <MainValueRow>
              <MainValuePrefix>R$</MainValuePrefix>
              <MainValue>
                {this.formatCurrency(
                  (balance > 0 ? balance : 0) + toSettle + paymentConciliation + paymentOverdue,
                )}
              </MainValue>
            </MainValueRow>
            <DisplayRow />
            <DisplayRow justify="space-between">
              <Display
                prefix="R$"
                label="Valor a Receber"
                detailsAction={() => getDialogContent({}, 'Carteira de títulos a receber')}
                disabled={!toSettle}
              >
                {this.formatCurrency(toSettle)}
              </Display>
              <Divider />
              <Display
                prefix="R$"
                label="Em Conciliação"
                tooltipContent="Valor dos títulos cujo pagamento ainda não foi confirmado pelo setor Financeiro da
                Liber."
                detailsAction={() => this.handleDetailsClick('conciliation')}
                disabled={!paymentConciliation}
              >
                {this.formatCurrency(paymentConciliation)}
              </Display>
            </DisplayRow>
            <DisplayRow justify="space-between">
              <Display
                prefix="R$"
                label="Valor em Atraso"
                detailsAction={() => this.handleDetailsClick('overdue')}
                disabled={!paymentOverdue}
              >
                {this.formatCurrency(paymentOverdue)}
              </Display>
              <Divider />
              <Display prefix="R$" label="Saldo Disponível" disabled={!balance || balance <= 0}>
                {balanceAvailability || loading
                  ? this.formatCurrency(balance > 0 ? balance : 0)
                  : '-'}
              </Display>
            </DisplayRow>
          </LoadingBlock>
        </Card>
      </>
    );
  }
}

MyWallet.propTypes = {
  loading: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  getMyWalletData: PropTypes.func.isRequired,
  getInvoicesData: PropTypes.func.isRequired,
  getDialogContent: PropTypes.func,
};

MyWallet.defaultProps = {
  getDialogContent: () => {},
};

export default MyWallet;
