import styled from 'styled-components';
import { SecondaryButton } from 'liber-components';

export const Row = styled.div`
  display: flex;
  gap: 8px;
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #6287a7;
`;

export const Info = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #6287a7;
`;

export const Content = styled.div`
  padding: 0 16px 0 16px;
`;

export const BlockButton = styled(SecondaryButton).attrs(() => ({
  version: 2,
  size: 'small',
}))``;
