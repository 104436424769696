import Immutable from 'seamless-immutable';
import _ from 'lodash';

const initialState = Immutable({
  0: {
    id: -1,
    default: true,
    kind: '',
    bank_code: '',
    agency: '',
    agency_digit: '',
    account: '',
    account_digit: '',
    conjunct: false,
    savings_complement: '',
    is_patch: false,
    errors: {},
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/KIND':
      return state
        .setIn([action.index, 'kind'], action.payload)
        .setIn([action.index, 'errors', 'kind'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/BANK_CODE':
      return state
        .setIn([action.index, 'bank_code'], action.payload)
        .setIn([action.index, 'errors', 'bank_code'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/AGENCY':
      return state
        .setIn([action.index, 'agency'], action.payload)
        .setIn([action.index, 'errors', 'agency'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/AGENCY_DIGIT':
      return state
        .setIn([action.index, 'agency_digit'], action.payload)
        .setIn([action.index, 'errors', 'agency_digit'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/ACCOUNT':
      return state
        .setIn([action.index, 'account'], action.payload)
        .setIn([action.index, 'errors', 'account'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/ACCOUNT_DIGIT':
      return state
        .setIn([action.index, 'account_digit'], action.payload)
        .setIn([action.index, 'errors', 'account_digit'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/CONJUNCT':
      return state
        .setIn([action.index, 'conjunct'], action.payload === 'true')
        .setIn([action.index, 'errors', 'conjunct'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SAVINGS_COMPLEMENT':
      return state
        .setIn([action.index, 'savings_complement'], action.payload)
        .setIn([action.index, 'errors', 'savings_complement'], '');
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SET_DATA':
      return state.merge({ ...action.payload });
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/SUCCESSFULL_SUBMIT':
      return state.setIn([action.index], {
        ...state[action.index],
        id: action.payload,
        errors: {},
        is_patch: true,
      });
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/FAILED_SUBMIT':
      return state.setIn([action.index, 'errors'], action.payload);
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/ADD_ACCOUNT':
      return state.merge({
        [Object.keys(state).length]: initialState[0].merge({ default: false }),
      });
    case 'WIZARD_INVESTIDOR/DADOS_BANCARIOS/REMOVE_ACCOUNT':
      return Immutable(_.omit(state, action.index));
    default:
      return state;
  }
};
