import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const Container = styled.div`
  width: 100%;

  * {
    font-family: Roboto;
  }
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
  width: max-content;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const ButtonsRow = styled(Row)`
  justify-content: flex-end;
`;
