import React from 'react';
import PropTypes from 'prop-types';
import { Container, MutedText } from './styles';
import { SelectCheckbox, Text } from '../CommonStyles';
import { formatCnpjOrCpf } from '../../../../vendor/Utils';

const ListItem = ({ selected, tradeName, cnpj, handleSelect }) => {
  const onClick = e => {
    e.preventDefault();
    handleSelect();
  };
  return (
    <Container onClick={onClick}>
      <SelectCheckbox checked={selected} />
      <div>
        <Text>{tradeName}</Text>
        <MutedText>{formatCnpjOrCpf(cnpj)}</MutedText>
      </div>
    </Container>
  );
};

ListItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  tradeName: PropTypes.string.isRequired,
  cnpj: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default ListItem;
