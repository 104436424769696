import axios from 'axios';
import { toast } from 'liber-components/components/Toast';
import { handleError, handleInfo } from '../../../vendor/Utils';
import {
  INVOICES_URL,
  INVOICES_QUOTE_ALL_URL,
  INVOICES_BANK_ACCOUNTS_URL,
  INVOICES_REQUEST_ANTICIPATION_URL,
  INVOICES_REQUEST_DOWNLOAD_URL,
  INVOICES_ACTION_CABLE_CHANNEL,
  INVOICES_ACTION_CABLE_ROOM,
  INVOICES_NEGOTIATIONS_URL,
  ADMIN_DOWNLOAD_PROVIDERS_INVOICES,
  VENDOR_EXTERNAL_ACTION_CABLE_CHANNEL,
  VENDOR_EXTERNAL_ACTION_CABLE_ROOM,
  ADMIN_REPORT_LOADING,
} from '../urls';
import { MAP_INVOICE_STATUS, INVOICE_TABS, TABS } from '../utils';
import { STATUS_EQ } from '../components/constants';

const { NEGOTIATION } = INVOICE_TABS;
const cleanFilters = raw => {
  let clean = raw;
  if (raw['q[emitter_cnpj_eq]']) {
    clean = {
      ...clean,
      'q[emitter_cnpj_eq]': clean['q[emitter_cnpj_eq]']
        .split('.')
        .join('')
        .split('/')
        .join('')
        .split('-')
        .join(''),
    };
  }
  return clean;
};

export const setDownloadReportLoading = loading => ({
  type: ADMIN_REPORT_LOADING,
  payload: { loading },
});

export const fetchInvoicesSuccess = payload => ({
  type: 'INVOICE_SIMULATION_FETCH_INVOICES_SUCCESS',
  payload: {
    invoices: payload.data.invoices,
    pagination: payload.data.pagination,
    faceValue: payload.data.face_value,
    rateByPeriod: payload.data.rateByPeriod,
  },
});

export const fetchInvoicesRejected = (error, status) => ({
  type: 'INVOICE_SIMULATION_FETCH_INVOICES_REJECTED',
  payload: {
    message: error.message,
    status: TABS[status],
  },
});

export function fetchInvoices(token, params = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INVOICE_SIMULATION_FETCH_INVOICES',
    });

    const {
      invoiceSimulation: { activeTab },
    } = getState();

    axios
      .get(INVOICES_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(payload => {
        dispatch(fetchInvoicesSuccess(payload.data));
      })
      .catch(error => {
        dispatch(fetchInvoicesRejected(error, MAP_INVOICE_STATUS[activeTab]));
      });
  };
}

export const fetchNegotiationsSuccess = negotiations => ({
  type: 'INVOICE_SIMULATION_FETCH_NEGOTIATIONS_SUCCESS',
  payload: {
    negotiations,
  },
});

export const fetchNegotiationsRejected = e => ({
  type: 'INVOICE_SIMULATION_FETCH_NEGOTIATIONS_REJECTED',
  payload: {
    message: e.message,
  },
});

export function fetchNegotiations(token, params = {}) {
  return dispatch => {
    dispatch({
      type: 'INVOICE_SIMULATION_FETCH_NEGOTIATIONS',
    });
    axios
      .get(INVOICES_NEGOTIATIONS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(payload => {
        dispatch(fetchNegotiationsSuccess(payload.data.data));
      })
      .catch(e => {
        dispatch(fetchNegotiationsRejected(e));
      });
  };
}

export const quoteAllInvoicesRejected = e => ({
  type: 'INVOICE_SIMULATION_QUOTE_ALL_REJECTED',
  payload: {
    message: e.message,
  },
});

export function quoteAllInvoices(token) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INVOICE_SIMULATION_QUOTE_ALL_INVOICES',
    });
    const { filters } = getState().invoiceSimulation;
    axios
      .get(INVOICES_QUOTE_ALL_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { ...cleanFilters(filters) },
      })
      .catch(e => {
        dispatch(quoteAllInvoicesRejected(e));
      });
  };
}

export const deselectAllInvoices = () => ({
  type: 'INVOICE_SIMULATION_DESELECT_ALL_INVOICES',
});

export function updatePagination(token, params) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INVOICE_SIMULATION_UPDATE_PAGINATION',
      payload: {
        pagination: {
          current: params.current,
          per: params.per,
        },
      },
    });
    const state = getState().invoiceSimulation;
    const { filters } = state;
    const column = state.orderedColumn;
    const type = state.sortAsc ? 'ASC' : 'DESC';
    const sortedBy = column ? `${column} ${type}` : null;
    dispatch(
      fetchInvoices(token, {
        ...cleanFilters(filters),
        page: params.current,
        per: params.per,
        'q[s]': sortedBy,
        'q[status_eq]': MAP_INVOICE_STATUS[getState().invoiceSimulation.activeTab],
      }),
    );
  };
}

export function setFilter(token, filters = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INVOICE_SIMULATION_FILTER',
      payload: {
        filters,
      },
    });
    dispatch(
      fetchInvoices(token, {
        ...cleanFilters(filters),
        'q[status_eq]': MAP_INVOICE_STATUS[getState().invoiceSimulation.activeTab],
      }),
    );
  };
}

export function changeTab(token, tab) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INVOICE_SIMULATION_CHANGE_TAB',
      payload: {
        tab,
      },
    });
    if (tab === NEGOTIATION) {
      // Em negociação
      dispatch(
        fetchNegotiations(token, {
          'q[status_eq]': MAP_INVOICE_STATUS[tab],
        }),
      );
    } else {
      const { filters } = getState().invoiceSimulation;

      dispatch(
        fetchInvoices(token, {
          ...cleanFilters(filters),
          'q[status_eq]': MAP_INVOICE_STATUS[tab],
        }),
      );
    }
  };
}

export const updateSelectedInvoices = (selectedInvoices, unselectedInvoices, quote) => ({
  type: 'INVOICE_SIMULATION_UPDATE_SELECTED_INVOICES',
  payload: {
    selectedInvoices,
    unselectedInvoices,
    quote,
  },
});

export const fetchBankAccountsSuccess = payload => ({
  type: 'INVOICE_SIMULATION_FETCH_BANK_ACCOUNTS_SUCCESS',
  payload: {
    accounts: [...payload.data],
  },
});

export const fetchBankAccountsRejected = e => ({
  type: 'INVOICE_SIMULATION_FETCH_BANK_ACCOUNTS_REJECTED',
  payload: {
    message: e.message,
  },
});

export function fetchBankAccounts(token) {
  return dispatch => {
    dispatch({
      type: 'INVOICE_SIMULATION_FETCH_BANK_ACCOUNTS',
    });
    axios
      .get(INVOICES_BANK_ACCOUNTS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(payload => {
        dispatch(fetchBankAccountsSuccess(payload.data));
      })
      .catch(e => {
        dispatch(fetchBankAccountsRejected(e));
      });
  };
}

export const requestAnticipationSuccess = () => ({
  type: 'INVOICE_SIMULATION_REQUEST_ANTICIPATION_SUCCESS',
});

export const requestAnticipationRejected = e => ({
  type: 'INVOICE_SIMULATION_REQUEST_ANTICIPATION_REJECTED',
  payload: {
    message: e.message,
  },
});

export const beginAnticipation = () => ({
  type: 'INVOICE_SIMULATION_BEGIN_ANTICIPATION',
});

export function requestAnticipation(token, params) {
  return dispatch => {
    dispatch({
      type: 'INVOICE_SIMULATION_REQUEST_ANTICIPATION',
    });
    return axios
      .post(INVOICES_REQUEST_ANTICIPATION_URL, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(requestAnticipationSuccess());
      })
      .catch(e => {
        toast(
          {
            message: 'Adiantamento solicitado',
            info: 'Ocorreu um erro, por favor tente novamente',
          },
          'error',
          5000,
        );
        dispatch(requestAnticipationRejected(e));
      });
  };
}

export const requestDownloadSimulationSuccess = () => ({
  type: 'INVOICE_SIMULATION_REQUEST_DOWNLOAD_SUCCESS',
});

export const requestDownloadSimulationRejected = e => ({
  type: 'INVOICE_SIMULATION_REQUEST_DOWNLOAD_REJECTED',
  payload: {
    message: e.message,
  },
});

export function requestDownloadSimulation(token, params) {
  return dispatch => {
    dispatch({
      type: 'INVOICE_SIMULATION_REQUEST_DOWNLOAD',
    });

    axios
      .post(INVOICES_REQUEST_DOWNLOAD_URL, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast(
          {
            message: 'Relatório de Simulação solicitado',
            info: `Um relatório com os ${params.ids.length} títulos será baixado`,
          },
          'info',
          5000,
        );
        dispatch(requestDownloadSimulationSuccess());
      })
      .catch(e => {
        console.log(e);
        toast(
          {
            message: 'Relatório de Simulação solicitado',
            info: 'Ocorreu um erro, por favor tente novamente',
          },
          'error',
          5000,
        );
        dispatch(requestDownloadSimulationRejected(e));
      });
  };
}

export const selectBankAccount = account => ({
  type: 'INVOICE_SIMULATION_SELECT_BANK_ACCOUNT',
  payload: {
    account,
  },
});

export const subscribeInvoices = fornecedorId => dispatch => {
  dispatch({
    type: 'INVOICE_SIMULATION_SUBSCRIBE',
    channel: INVOICES_ACTION_CABLE_CHANNEL,
    room: INVOICES_ACTION_CABLE_ROOM + fornecedorId,
  });
};

export const subscribeInvoicesReport = fornecedorId => dispatch => {
  dispatch({
    channel: VENDOR_EXTERNAL_ACTION_CABLE_CHANNEL,
    room: `${VENDOR_EXTERNAL_ACTION_CABLE_ROOM}${fornecedorId}`,
  });
};

export function sortByColumn(token, column, type) {
  return (dispatch, getState) => {
    dispatch({
      type: 'INVOICE_SIMULATION_SORT_COLUMN',
      payload: {
        column,
        type,
      },
    });
    const state = getState().invoiceSimulation;
    const { filters } = state;
    const { current, per } = state.pagination;
    dispatch(
      fetchInvoices(token, {
        ...cleanFilters(filters),
        page: current,
        per,
        'q[s]': `${column} ${type}`,
        'q[status_eq]': MAP_INVOICE_STATUS[getState().invoiceSimulation.activeTab],
      }),
    );
  };
}

export function fetchInvoicesReport(token, filters, activeTab) {
  const params = { ...filters, file_format: 'xlsx', [STATUS_EQ]: MAP_INVOICE_STATUS[activeTab] };
  return dispatch => {
    dispatch(setDownloadReportLoading(true));

    axios
      .post(ADMIN_DOWNLOAD_PROVIDERS_INVOICES, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(() => {
        handleInfo('Relatório solicitado', 'O relatório está sendo gerado, por favor aguarde');
      })
      .catch(() => {
        handleError('Erro', 'Ocorreu um erro, por favor tente novamente');
        dispatch(setDownloadReportLoading(false));
      });
  };
}
