import styled from 'styled-components';
import { handleThemeFromObject as getTheme, SelectField, TextField } from 'liber-components';

import bunnyReports from '../../../../../assets/images/bunny-reports.svg';

export const Title = styled.span`
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: left;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  margin-bottom: 36px;
  margin-top: 24px;
`;

export const Subtitle = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: #6287a7;
  margin-bottom: 40px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    margin-bottom: 24px;
  }

  label {
    font-weight: bold;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StatusSelect = styled(SelectField).attrs(() => ({
  version: '2',
  label: 'Status',
}))`
  width: 545px;

  & > div:nth-child(3) > div > div {
    max-height: unset;
  }
`;

export const InvoiceNumberField = styled(TextField).attrs(() => ({
  version: 2,
  label: 'Número(s) do(s) título(s)',
  openLabel: true,
}))`
  width: 545px;

  label {
    font-weight: bold;
  }
  div {
    font-weight: bold;
    background-color: blue;
  }
`;

export const Image = styled.img.attrs(() => ({ src: bunnyReports, alt: '' }))`
  width: 500px;
  height: auto;
`;
