import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4, ToastContainer } from 'liber-components';
import VendorReports from './components/VendorReports';
import hydrateStore from '../store';

const View = ({ token, vendorId }) => (
  <>
    <Provider store={hydrateStore({ vendorReports: { token, vendorId } })}>
      <ThemeProvider theme={LiberV4}>
        <ToastContainer />
        <VendorReports token={token} />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
  vendorId: PropTypes.number.isRequired,
};

export default View;
