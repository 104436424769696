import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, LinearProgress } from 'liber-components';
import {
  DialogContent,
  TitleText,
  DescriptionText,
  LoadingBunny,
  ProgressContainer,
  ProgressText,
} from './LoadingDialog.styles';

export const LoadingDialog = ({ open, advanceProgress, advanceAction }) => {
  let text = 'Estamos processando as suas propostas';
  if (advanceAction === 'documents') {
    text = 'Estamos gerando o termo de cessão para assinatura';
  }
  return (
    <Modal show={open} closable={false}>
      <DialogContent>
        <LoadingBunny loop />
        <TitleText>Por favor, aguarde...</TitleText>
        <DescriptionText>{text}</DescriptionText>
        <ProgressContainer>
          <LinearProgress height={16} determinate value={advanceProgress} />
        </ProgressContainer>
        <ProgressText>{`${advanceProgress}%`}</ProgressText>
      </DialogContent>
    </Modal>
  );
};

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  advanceAction: PropTypes.string,
  advanceProgress: PropTypes.number,
};

LoadingDialog.defaultProps = {
  open: false,
  advanceProgress: 0,
  advanceAction: 'invoice',
};

const mapStateToProps = ({ invoiceSimulation: { advanceAction, advanceProgress } }) => ({
  advanceProgress,
  advanceAction,
});

export default connect(mapStateToProps)(LoadingDialog);
