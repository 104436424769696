import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, PrimaryButton } from 'liber-components';
import {
  DialogContent,
  DialogHeader,
  Title,
  DialogActions,
  Button,
  CloseIcon,
  IconButton,
} from './SimulationDialog.styles';

export const SimulationDialog = ({
  title,
  open,
  onClose,
  confirmationButtonProps,
  maxWidth,
  children,
}) => {
  const {
    component = PrimaryButton,
    text = 'CONFIRMAR',
    onConfirmation,
    disabled = false,
  } = confirmationButtonProps;
  const ConfirmationButton = component;

  return (
    <Modal show={open} onLeaved={onClose}>
      <DialogHeader>
        <Title>{title}</Title>
        <IconButton size="large" circle onClick={onClose} version={2}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>
      <DialogContent maxWidth={maxWidth}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} version={2}>
          CANCELAR
        </Button>
        <ConfirmationButton onClick={onConfirmation} disabled={disabled} version={2}>
          {text.toUpperCase()}
        </ConfirmationButton>
      </DialogActions>
    </Modal>
  );
};

SimulationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  children: PropTypes.node,
  confirmationButtonProps: PropTypes.shape({
    component: PropTypes.string,
    text: PropTypes.string,
    onConfirmation: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }),
};

SimulationDialog.defaultProps = {
  confirmationButtonProps: {
    component: PrimaryButton,
    text: 'CONFIRMAR',
    onConfirmation: () => null,
    disabled: false,
  },
  maxWidth: null,
  children: null,
};

const mapStateToProps = ({ invoiceSimulation: { accounts, bankAccountId } }) => ({
  accounts,
  bankAccountId,
});

export default connect(mapStateToProps)(SimulationDialog);
