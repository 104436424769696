import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';
import axios from 'axios';
import moment from 'moment';
import messages from '../messages';

export const hidrateForm = account => ({
  type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_HIDRATE_FORM',
  payload: account,
});

export const closeModalForm = () => ({
  type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_CLOSE_MODAL_FORM',
});

const fieldPayload = (field, value, agregate) => {
  if (field === 'countryOrigin') {
    return {
      countryOrigin: value,
      stateOrigin: '',
      cityOrigin: '',
    };
  }
  if (field === 'stateOrigin' && agregate === 'BR') {
    return {
      stateOrigin: value,
      cityOrigin: '',
    };
  }
  return {
    [field]: value,
  };
};

export const editField = (field, value, agregate = null) => ({
  type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_EDIT_FIELD',
  payload: fieldPayload(field, value, agregate),
});

export const accountPresenter = payload => ({
  kind: payload.kind,
  name: payload.name,
  cnpj: payload.cnpj,
  email: payload.email,
  cellphone: payload.cellphone,
  phone: payload.phone,
  gender: payload.gender,
  birthdate: moment(payload.birthdate).format('DD/MM/YYYY'),
  civilStatus: payload.civil_status,
  countryOrigin: payload.country_origin,
  stateOrigin: payload.state_origin,
  cityOrigin: payload.city_origin,
  company: payload.company,
  tradeName: payload.trade_name,
});

export const validateAccount = account => {
  const keys = Object.keys(account);
  const validationErrors = {};
  const isValid =
    keys.length !== 0
      ? keys.reduce((before, key) => {
          if (account.kind === 'pf') {
            if (key === 'phone' || key === 'company' || key === 'tradeName') {
              return true && before;
            }
            if (account[key].length === 0) {
              validationErrors[key] = messages.empty;
              return false && before;
            }
          } else {
            if (
              key === 'phone' ||
              key === 'gender' ||
              key === 'birthdate' ||
              key === 'civilStatus' ||
              key === 'countryOrigin' ||
              key === 'stateOrigin' ||
              key === 'cityOrigin'
            ) {
              return true && before;
            }
            if (account[key].length === 0) {
              validationErrors[key] = messages.empty;
              return false && before;
            }
          }

          return true && before;
        }, true)
      : false;
  return {
    validate: isValid,
    validationErrors,
  };
};

export const submitForm =
  (account = {}) =>
  dispatch => {
    const { validate, validationErrors } = validateAccount(account);
    if (validate) {
      let obj;
      if (account.kind === 'pf') {
        obj = {
          investidor: {
            name: account.name,
            email: account.email,
            cellphone: account.cellphone,
            phone: account.phone,
            gender: account.gender.toLowerCase(),
            birthdate: moment(account.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            civil_status: account.civilStatus.toLowerCase(),
            country_origin: account.countryOrigin,
            state_origin: account.stateOrigin,
            city_origin: account.cityOrigin,
          },
        };
      } else {
        obj = {
          investidor: {
            name: account.name,
            email: account.email,
            cellphone: account.cellphone,
            phone: account.phone,
            company: account.company,
            trade_name: account.tradeName,
          },
        };
      }
      const csrfToken = ReactOnRails.authenticityToken();
      dispatch({
        type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_PENDING',
      });
      return axios({
        method: 'PATCH',
        url: '/investidores.json',
        data: {
          ...obj,
          authenticity_token: csrfToken,
        },
        responseType: 'json',
      })
        .then(({ data }) => {
          const { message, id, ...payload } = data.data;
          dispatch({
            type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_SUCCESS',
            payload: accountPresenter(payload),
          });
          toast(message, 'success', 8000);
        })
        .catch(error => {
          const message = errors.http[{ ...error.response }.status || 500];
          toast(message, 'error', 8000);
          dispatch({
            type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_REJECT',
          });
        });
    }
    return dispatch({
      type: 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_VALIDATION_ERROR',
      payload: validationErrors,
    });
  };

export const empty = null;
