import axios from 'axios';
import {
  VENDORS_LIST_URL,
  UPLOAD_URL,
  REMOVE_LIMIT_URL,
  EXPORT_VENDOR_LIST_URL,
  USER_INFO_URL,
  UPDATE_STATUS_URL,
} from './urls';
import { convertToCamelCase } from '../../vendor/Utils';

export async function fetchVendors(token = '', params) {
  return axios.get(VENDORS_LIST_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
}

export async function sendSpreadsheet(token = '', spreadsheet) {
  const formData = new FormData();
  formData.append('spreadsheet', spreadsheet);

  return axios.post(UPLOAD_URL, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function removeLimit(token = '', id) {
  return axios.delete(`${REMOVE_LIMIT_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchVendorList(token = '', params = {}) {
  const response = await axios.get(EXPORT_VENDOR_LIST_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 120000,
    params,
  });
  return convertToCamelCase(response.data);
}

export async function fetchUserInfo(token = '') {
  return axios.get(USER_INFO_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeBuyerStatus(token = '', vendorId, body) {
  return axios.post(`${UPDATE_STATUS_URL.replace(':vendor_id', vendorId)}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
