import styled from 'styled-components';
import { Card as LiberCard, FaCheck, media, handleThemeFromObject } from 'liber-components';

export const Page = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Card = styled(LiberCard)`
  margin: auto !important;
  overflow: hidden;
  width: 100%;

  ${media.large`
    width: 80%;
  `};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border-left: 3px solid
    ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  h1 {
    margin: 15px auto;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
    color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  }

  ${media.medium`
    h1 {
      font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h3', '32px')};
    }
    padding: 30px 60px;
  `}
`;

export const Helper = styled.div`
  word-wrap: unset !important;
  & > span {
    display: none;
  }
  ${media.medium`
    & > span { display: block; }
  `}
`;

export const Form = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  margin: 20px auto;

  & > * {
    width: auto;
    margin: 8px;
  }

  ${media.small`
    width: 85%;
  `}

  ${media.medium`
    width: 70%;
    & > * {
      width: 100%;
      margin: 16px;
    }
  `}

  ${media.large`
    width: auto;
    flex-direction: row;
  `}
`;

export const CheckIcon = styled(FaCheck)`
  display: block;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  & > * {
    width: auto;
    margin: 8px 4px;
  }

  ${media.medium`
    margin-top: 56px;
    & > * {
      padding: 0px 64px;
    }
  `}

  ${media.large`
    & > * {
      margin: 16px;
      padding: 0px 64px;
    }
    justify-content: flex-end;
  `}
`;
