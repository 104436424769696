import axios from 'axios';
import { distribution } from '../url';

export function getDistributionData(token) {
  return {
    type: 'INVESTOR_DASHBOARD_GET_DISTRIBUTION_DATA',
    payload: axios.get(distribution, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  };
}

export const empty = null;
