import styled from 'styled-components';
import { FlatButton } from 'liber-components';

export const Container = styled.div`
  padding: 24px 24px 12px 24px;
  width: auto;
  max-width: 480px;
`;

export const Header = styled.h2`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  color: #6287a7;
  margin-bottom: 0;
  text-transform: capitalize;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin-bottom: 24px;
  color: #6287a7;
  margin-bottom: 24px;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

export const List = styled.ul`
  padding: 0;
  margin-top: 10px;
  margin-left: 24px;
`;

export const ListItem = styled.li`
  list-style: circle;
`;

export const Button = styled(FlatButton).attrs(() => ({ size: 'large' }))`
  text-transform: uppercase;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    margin-bottom: 0;
  }
`;
