import styled, { css } from 'styled-components';

const colorReference = {
  positive: '#008a5e',
  negative: '#ff5b56',
  neutral: '#6287a7',
};

const getBarWidth = ({ width }) =>
  width
    ? css`
        width: ${String(width).replace('px', '')}px;
      `
    : '200px';

const getInnerBarWidth = ({ progressed, total }) => {
  const percent = (parseInt(progressed, 10) / parseInt(total, 10)) * 100;
  return css`
    width: ${percent}%;
  `;
};

const getBarColor = ({ progressed }) =>
  progressed ? colorReference.negative : colorReference.neutral;

export const Bar = styled.div`
  ${getBarWidth}
  background-color: ${getBarColor};
  height: 4px;
  border-radius: 4px;

  & > div {
    ${getInnerBarWidth}
    background-color: ${colorReference.positive};
    height: 4px;
    border-radius: 4px;
    transition: width 1s ease-in-out;
  }
`;
