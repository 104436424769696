import React from 'react';
import PropTypes from 'prop-types';
import {
  TooltipContentContainer,
  TooltipContentText,
} from './InvoiceSimulationTableTooltipContent.styles';

const InvoiceSimulationTableTooltipContent = ({ text }) => (
  <TooltipContentContainer>
    <TooltipContentText>{text}</TooltipContentText>
  </TooltipContentContainer>
);

InvoiceSimulationTableTooltipContent.propTypes = {
  text: PropTypes.string,
};

InvoiceSimulationTableTooltipContent.defaultProps = {
  text: '',
};

export default InvoiceSimulationTableTooltipContent;
