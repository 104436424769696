import styled from 'styled-components';
import { media, handleThemeFromObject } from 'liber-components';

export const ImageContainer = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  display: block;
  padding: 20px;
  margin: auto;
  margin-top: 20px;
  height: 150px;
  width: 150px;

  ${media.medium`
    height: 250px;
    width: 250px;
  `}
`;

export const Content = styled.div`
  p {
    color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.regular', '400')};
  }
  p,
  strong {
    margin-top: 25px;
  }
  .hidden {
    display: none;
  }
  text-align: center;
  ${media.medium`
    .hidden {
      display: inline;
    }
    text-align: justify;
  `}
`;
