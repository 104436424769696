import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  Tooltip,
} from 'liber-components';
import { Badge, IconButton, Row } from 'liber-components/components/GenericStyles';
import currencyFormatter from 'currency-formatter';
import {
  Accordion,
  ProposeRow,
  PdfIcon,
  DownloadIcon,
  ErrorIcon,
  SucessIcon,
  InfoMessage,
  InfoIcon,
  Faded,
  DownloadButton,
  DownloadButtonAllButton,
  SucessCircleIcon,
  WhiteButton,
} from '../styles';

const pad = n => (n > 0 && n < 10 ? '0' : '') + n;

class InvoiceTable extends Component {
  calcAdvanceValue = invoices =>
    invoices.reduce((old, curr) => {
      const advance = parseFloat(curr.advance, 10);
      return old + advance;
    }, 0);

  renderLabelAssinados = (count, value) => (
    <ProposeRow>
      <Badge>{count}</Badge>
      Documentos Assinados -
      <span>{currencyFormatter.format(value, { code: 'BRL', format: '%s%v' })}</span>
    </ProposeRow>
  );

  renderLabelPendentes = (count, value, afterSign) => (
    <ProposeRow>
      <Badge>{count}</Badge>
      {afterSign ? 'Documentos com assinaturas pendentes -' : 'Propostas -'}
      <span>{currencyFormatter.format(value, { code: 'BRL', format: '%s%v' })}</span>
    </ProposeRow>
  );

  renderHeader = () => {
    const { jointSignature, ecnpjSignature } = this.props;
    const isSignColumnRendered = jointSignature && ecnpjSignature === false;
    return (
      <TableHeader>
        <TableRow>
          <TableHeaderColumn width={isSignColumnRendered ? '156px' : '256px'}>
            Número
          </TableHeaderColumn>
          <TableHeaderColumn>Taxa</TableHeaderColumn>
          <TableHeaderColumn>Adiantamento</TableHeaderColumn>
          <TableHeaderColumn>Vencimento</TableHeaderColumn>
          {isSignColumnRendered ? (
            <TableHeaderColumn width="136px">Assinaturas</TableHeaderColumn>
          ) : null}
          <TableHeaderColumn width={isSignColumnRendered ? '110px' : null} />
        </TableRow>
      </TableHeader>
    );
  };

  renderInvoiceTable = () => {
    const {
      invoices,
      showPdf,
      status,
      error,
      selectedPartner,
      afterSign,
      jointSignature,
      ecnpjSignature,
      signaturesNeeded,
    } = this.props;
    const { failedInvoices = [] } = error;
    let filteredInvoices;
    const showError = !status && failedInvoices.length === invoices.length;
    if (showError) {
      filteredInvoices = invoices;
    } else {
      filteredInvoices = invoices.filter(
        invoice =>
          invoice.jobStatus !== 'failed' && (!invoice.finished || invoice.finished === 'false'),
      );
    }
    if (filteredInvoices.length > 0) {
      const mappedInvoices = filteredInvoices.map(invoice => {
        const showSelected = !showError && invoice.signedIds.includes(selectedPartner);
        return (
          <TableRow key={invoice.id}>
            <TableRowColumn>
              <ErrorIcon show={showError} />
              <SucessIcon show={showSelected} />
              <Faded fade={showSelected}>{invoice.invoiceNumber}</Faded>
            </TableRowColumn>
            <TableRowColumn>
              <Faded fade={showSelected}>{invoice.agreedRate} a.m.</Faded>
            </TableRowColumn>
            <TableRowColumn>
              <Faded fade={showSelected}>
                {currencyFormatter.format(parseFloat(invoice.advance, 10), {
                  code: 'BRL',
                  format: '%s%v',
                })}
              </Faded>
            </TableRowColumn>
            <TableRowColumn>
              <Faded fade={showSelected} style={{ display: 'flex', justifyContent: 'center' }}>
                {invoice.dueDate}
                <Tooltip label="Prazo de Vencimento" hoverable style={{ marginLeft: 8 }}>
                  <div style={{ width: 128, fontSize: 16 }}>{invoice.deadline} dias corridos</div>
                </Tooltip>
              </Faded>
            </TableRowColumn>
            {jointSignature && ecnpjSignature === false ? (
              <TableRowColumn>
                <Faded fade={showSelected}>
                  {invoice.signedIds.length}/{signaturesNeeded}
                </Faded>
              </TableRowColumn>
            ) : null}
            <TableRowColumn>
              {showError ? null : (
                <IconButton
                  onClick={() => {
                    showPdf(invoice.pdfFile || '');
                  }}
                >
                  <PdfIcon />
                </IconButton>
              )}
            </TableRowColumn>
          </TableRow>
        );
      });
      return (
        <Accordion
          open
          label={this.renderLabelPendentes(
            mappedInvoices.length,
            this.calcAdvanceValue(filteredInvoices),
            afterSign,
          )}
        >
          {!afterSign ? (
            <InfoMessage>
              <InfoIcon />
              <span>
                O pagamento será realizado em até 01 dia útil após a assinatura dos documentos.
              </span>
            </InfoMessage>
          ) : null}
          <Table>
            {this.renderHeader()}
            <TableBody>{mappedInvoices}</TableBody>
          </Table>
        </Accordion>
      );
    }
    return null;
  };

  renderFinishedTable = () => {
    const {
      invoices,
      invoicesDone,
      downloadAllDone,
      jointSignature,
      ecnpjSignature,
      signaturesNeeded,
    } = this.props;
    const filteredInvoices = invoices.filter(
      invoice =>
        invoice.jobStatus !== 'failed' && !invoice.invoiceError && invoice.finished === 'true',
    );
    const mappedInvoices = filteredInvoices.map(invoice => (
      <TableRow key={invoice.id}>
        <TableRowColumn>
          <SucessIcon show />
          {invoice.invoiceNumber}
        </TableRowColumn>
        <TableRowColumn>{invoice.agreedRate}</TableRowColumn>
        <TableRowColumn>
          {currencyFormatter.format(parseFloat(invoice.advance, 10), {
            code: 'BRL',
            format: '%s%v',
          })}
        </TableRowColumn>
        <TableRowColumn style={{ display: 'flex' }}>
          {invoice.dueDate}
          <Tooltip label="Prazo de Vencimento" hoverable style={{ marginLeft: 8 }}>
            <div style={{ width: 128, fontSize: 16 }}>{invoice.deadline} dias úteis</div>
          </Tooltip>
        </TableRowColumn>
        {jointSignature && ecnpjSignature === false ? (
          <TableRowColumn>
            {invoice.signedIds.length}/{jointSignature ? signaturesNeeded : '1'}
          </TableRowColumn>
        ) : null}
        <TableRowColumn>
          <DownloadButton href={invoice.pdfFile} download target="_blank">
            <DownloadIcon />
          </DownloadButton>
        </TableRowColumn>
      </TableRow>
    ));
    if (filteredInvoices.length === 0) {
      return null;
    }
    return (
      <Accordion
        open={invoicesDone}
        label={this.renderLabelAssinados(
          mappedInvoices.length,
          this.calcAdvanceValue(filteredInvoices),
        )}
      >
        <Table>
          {this.renderHeader()}
          <TableBody>{mappedInvoices}</TableBody>
        </Table>
        <Row justifyContent="flex-end">
          {invoicesDone ? <WhiteButton onClick={this.props.onBack}>Voltar</WhiteButton> : null}
          <DownloadButtonAllButton href={downloadAllDone} download="todos_titulos">
            <DownloadIcon /> Baixar Todos
          </DownloadButtonAllButton>
        </Row>
      </Accordion>
    );
  };

  renderFinishedMessage = () => {
    const { invoices, partners } = this.props;
    const filteredInvoices = invoices.filter(invoice => invoice.status !== 'failed');
    const finishedPartners = filteredInvoices.reduce((prev, next) => {
      if (next.finished === 'false') {
        return next.signedIds[next.signedIds.length - 1];
      }
      return prev;
    }, -1);
    const lastSignedPartner = partners.reduce((prev, next) => {
      if (finishedPartners === next.id) {
        return next.name;
      }
      return prev;
    }, '');
    const finished = filteredInvoices.reduce((prev, prox) => {
      if (prox.finished === 'true') {
        return prev + 1;
      }
      return prev;
    }, 0);
    return (
      <>
        <InfoMessage>
          <SucessCircleIcon />
          {filteredInvoices.length === 1 ? (
            <>
              {lastSignedPartner.length > 0
                ? `Documento(s) assinado(s) com sucesso por ${lastSignedPartner}. `
                : null}
              {pad(finished)} de {filteredInvoices.length}&nbsp;documento finalizado.
            </>
          ) : (
            <>
              {lastSignedPartner.length > 0
                ? `Documento(s) assinado(s) com sucesso por ${lastSignedPartner}. `
                : null}
              {pad(finished)} de {filteredInvoices.length}&nbsp;documentos finalizados.
            </>
          )}
        </InfoMessage>
        <InfoMessage>
          <InfoIcon />{' '}
          <span>
            O pagamento será realizado em até 01 dia útil após a assinatura dos documentos.
          </span>
        </InfoMessage>
      </>
    );
  };

  render() {
    const { afterSign, invoicesDone, status } = this.props;
    return (
      <>
        {afterSign && status ? this.renderFinishedMessage() : null}
        {afterSign ? this.renderFinishedTable() : null}
        {!invoicesDone ? this.renderInvoiceTable() : null}
      </>
    );
  }
}

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  status: PropTypes.bool,
  partners: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedPartner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  jointSignature: PropTypes.bool.isRequired,
  ecnpjSignature: PropTypes.bool.isRequired,
  afterSign: PropTypes.bool.isRequired,
  invoicesDone: PropTypes.bool.isRequired,
  downloadAllDone: PropTypes.string.isRequired,
  signaturesNeeded: PropTypes.number.isRequired,
  showPdf: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  error: PropTypes.shape({
    display: PropTypes.arrayOf(PropTypes.string),
    message: PropTypes.string,
    flow: PropTypes.string,
    supportErrors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        critical: PropTypes.bool,
      }),
    ),
    failedInvoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    type: PropTypes.string,
    label: PropTypes.string,
    toasts: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};

InvoiceTable.defaultProps = {
  status: true,
};

export default InvoiceTable;
