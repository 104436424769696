import { mdiFile } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import * as S from './styles';

const FileUploadInput = forwardRef(({ accept, handleUploadedFile, fileName, onClick }, ref) => (
  <S.Wrapper onClick={onClick}>
    <Icon path={mdiFile} color="#009DFF" size={3} />
    <S.Text>{fileName || 'Clique aqui para selecionar o arquivo'}</S.Text>
    <S.Input type="file" ref={ref} onChange={handleUploadedFile} accept={accept} />
  </S.Wrapper>
));

FileUploadInput.propTypes = {
  accept: PropTypes.string,
  handleUploadedFile: PropTypes.func,
  fileName: PropTypes.string,
  onClick: PropTypes.func,
};

FileUploadInput.defaultProps = {
  accept: 'xlsx',
  handleUploadedFile: () => null,
  fileName: null,
  onClick: () => null,
};

export default FileUploadInput;
