import axios from 'axios';
import {
  ADMIN_FUNDERS_URL,
  ADMIN_FUNDER_SHOW_URL,
  BUYER_FUNDER_SHOW_URL,
  BUYER_FUNDERS_URL,
} from './constants';

export const fetchFunders = (vendorId, token, params, isAdmin) => {
  const fetchUrl = isAdmin ? ADMIN_FUNDERS_URL : BUYER_FUNDERS_URL;
  return axios.get(fetchUrl.replace(':vendor_id', vendorId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const fetchVendorInfo = (vendorId, token, isAdmin) => {
  const fetchUrl = isAdmin ? ADMIN_FUNDER_SHOW_URL : BUYER_FUNDER_SHOW_URL;
  return axios.get(fetchUrl.replace(':id', vendorId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
