import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Title,
  BankDisplay,
  BankName,
  BankInfo,
  Options,
  Option,
  DropDownContainer,
  DropDownValue,
  Icon,
  Dropdown,
} from './BankAccountSelect.styles';
import { selectBankAccount } from '../../../../actions/InvoiceSimulationAction';

const toggleOptions = (setOpen, open) => () => {
  setOpen(!open);
};

const getDigit = digit => (digit !== '' ? `-${digit}` : '');

const renderAccount = account => {
  const { bankName, account: accountNumber, accountDigit, agency, agencyDigit, kind } = account;
  return (
    <BankDisplay>
      <BankName>{bankName}</BankName>
      <BankInfo>{`AG: ${agency}${getDigit(
        agencyDigit,
      )} / ${kind.toUpperCase()}: ${accountNumber}${getDigit(accountDigit)}`}</BankInfo>
    </BankDisplay>
  );
};

const onSelect = (account, onSelectBankAccount, setOpen) => () => {
  setOpen(false);
  onSelectBankAccount(account);
};

const renderOptions = (accounts, bankAccountId, onSelectBankAccount, setOpen) =>
  accounts.map(account => (
    <Option
      onClick={onSelect(account, onSelectBankAccount, setOpen)}
      key={`account-${account.id}`}
      selected={account.id === bankAccountId}
    >
      {renderAccount(account)}
    </Option>
  ));

export const BankAccountSelect = ({ accounts, bankAccountId, onSelectBankAccount }) => {
  const selectedBank = accounts.find(({ id }) => bankAccountId === id);
  const [open, setOpen] = useState(false);

  return (
    <DropDownContainer>
      <Title>Conta Bancária para Recebimento</Title>
      <Dropdown
        open={open}
        customButton={
          <DropDownValue onClick={toggleOptions(setOpen, open)}>
            {renderAccount(selectedBank)}
            <Icon />
          </DropDownValue>
        }
      >
        <Options>{renderOptions(accounts, bankAccountId, onSelectBankAccount, setOpen)}</Options>
      </Dropdown>
    </DropDownContainer>
  );
};

BankAccountSelect.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      bankName: PropTypes.string,
      account: PropTypes.string,
      accountDigit: PropTypes.string,
      agency: PropTypes.string,
      agencyDigit: PropTypes.string,
    }).isRequired,
  ).isRequired,
  bankAccountId: PropTypes.number.isRequired,
  onSelectBankAccount: PropTypes.func.isRequired,
};

const mapStateToProps = ({ invoiceSimulation: { accounts, bankAccountId } }) => ({
  bankAccountId,
  accounts,
});

const mapDispatchToProps = {
  onSelectBankAccount: selectBankAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountSelect);
