import styled, { css } from 'styled-components';
import { Badge as RawBadge, Tag as RawTag } from 'liber-components';

const tagModifiers = {
  colors: {
    primary: theme => css`
      background-color: ${theme.colors.darkBlue};
    `,
    error: theme => css`
      background-color: ${theme.colors.red};
    `,
    waiting: theme => css`
      background-color: ${theme.colors.yellow25};
      color: ${theme.colors.darkText};
    `,
    success: theme => css`
      background-color: ${theme.colors.rippleSuccess};
    `,
    available: theme => css`
      background-color: ${theme.colors.success38};
      color: ${theme.colors.darkText};
    `,
    unavailable: theme => css`
      background-color: ${theme.colors.darkText38};
      color: ${theme.colors.darkText};
    `,
    blocked: theme => css`
      background-color: ${theme.colors.error38};
      color: ${theme.colors.darkText};
    `,
  },
  fullWidth: () => css`
    width: 100%;
  `,
};

export const Tag = styled(RawTag).attrs(() => ({ variant: 'rounded' }))`
  ${({ theme, color, fullWidth }) => css`
    border: none;
    padding: 7px 10px 7px 10px;
    color: white;
    font-size: 14px;
    margin: 0;

    ${!!color && tagModifiers.colors[color](theme)}
    ${fullWidth && tagModifiers.fullWidth()}
  `}
`;

export const Badge = styled(RawBadge).attrs(() => ({
  position: { vertical: 'top', horizontal: 'right' },
  color: '#006AEB',
}))``;
