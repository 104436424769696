import Immutable from 'seamless-immutable';

const initialState = Immutable({
  email: '',
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
  errors: {},
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'CHANGE_PASSWORD/CURRENT_PASSWORD':
      return state.merge({
        currentPassword: action.payload,
        errors: { ...state.errors, currentPassword: '' },
      });
    case 'CHANGE_PASSWORD/NEW_PASSWORD':
      return state.merge({
        newPassword: action.payload,
        errors: { ...state.errors, newPassword: '' },
      });
    case 'CHANGE_PASSWORD/PASSWORD_CONFIRMATION':
      return state.merge({
        passwordConfirmation: action.payload,
        errors: { ...state.errors, passwordConfirmation: '' },
      });
    case 'CHANGE_PASSWORD/SUCCESSFULL_SUBMIT':
      return state.setIn(['errors'], {});
    case 'CHANGE_PASSWORD/FAILED_SUBMIT':
      return state.setIn(['errors'], action.payload);
    default:
      return state;
  }
};
