import styled from 'styled-components';
import { LoadingBunny, Button as ButtonBase, PrimaryButton } from 'liber-components';

export const TEXT_COLOR = '#6287a7';

export const ActionIconButton = styled(ButtonBase).attrs(() => ({
  version: 2,
  as: 'a',
}))`
  padding: 4px;
  border-radius: 50%;
  color: ${TEXT_COLOR};
  cursor: pointer;

  :hover {
    color: ${TEXT_COLOR};
  }

  svg {
    fill: ${TEXT_COLOR} !important;
    width: 20px;
    height: 20px;
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  & > *:not(:last-child) {
    margin-right: 12px;
  }
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;

export const Container = styled.div`
  width: 480px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px 24px 12px 24px;

  * {
    font-family: Roboto;
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  background-color: white;
  transition: opacity 150ms ease-in-out;
  z-index: 201;
  position: relative;
`;

export const OutlinedButton = styled(PrimaryButton).attrs(() => ({
  outlined: true,
  version: 2,
  size: 'large',
}))``;
