import styled from 'styled-components';
import {
  RadioButton as RawRadioButton,
  PrimaryButton,
  handleThemeFromObject as getTheme,
} from 'liber-components';

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const SectionTitle = styled.div`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  color: #6287a7;
  margin-bottom: 8px;
`;

export const SectionTitleHelper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #405F71;

  svg {
    margin-right: 8px
    margin-top: -2px;
    width: 16px;
    height: 16px;
    color: #6287A7;
  }
`;

export const Option = styled.div`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const OptionHelper = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;
  margin-bottom: 24px;
`;

export const Observation = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #009dff;
  margin: 40px 0 48px 0;
`;

export const RadioButton = styled(RawRadioButton)`
  display: inline;
  margin-bottom: 0px;

  & > div {
    padding: 0 14px;

    :before {
      top: 4px;
    }
    :after {
      top: 9px;
    }
  }
`;

export const FooterActions = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
`;

export const OutlineButton = styled(PrimaryButton).attrs(() => ({
  outlined: true,
  version: 2,
  size: 'large',
}))`
  text-transform: uppercase;
  margin-left: auto;
  margin-right: 8px;
`;
