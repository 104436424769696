const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const ADMIN_FUNDERS_URL = `${apiUrl}/api/v1/admin/vendors/:vendor_id/funders`;
export const ADMIN_FUNDER_SHOW_URL = `${apiUrl}/api/v1/admin/vendors/:id`;

export const BUYER_FUNDERS_URL = `${apiUrl}/api/v1/buyer/vendors/:vendor_id/funders`;
export const BUYER_FUNDER_SHOW_URL = `${apiUrl}/api/v1/buyer/vendors/:id`;

export const NAME_OR_TRADE_NAME_CONT_FILTER = 'q[name_or_trade_name_cont]';
export const CNPJ_CONT_FILTER = 'q[cnpj_cont]';

export const ACTIONS = {
  BLOCK: 'block',
  UNBLOCK: 'unblock',
};

export const KIND = {
  FUNDER: 'funder',
  BUYER: 'buyer',
};

export const STATUSES = {
  BLOCKED: 'blocked',
  UNBLOCKED: 'unblocked',
  UNDER_REVIEW: 'under_review',
  PRE_APPROVED: 'pre_approved',
};

export const STATUSES_MAPPER = {
  blocked: { color: 'error', label: 'Bloqueado' },
  unblocked: { color: 'primary', label: 'Desbloqueado' },
  under_review: { color: 'waiting', label: 'Em análise' },
  pre_approved: { color: 'success', label: 'Pré-aprovado' },
};
