import styled, { css, createGlobalStyle } from 'styled-components';
import { handleThemeFromObject } from 'liber-components';

const WHITE = '#f9fafa';

const getPrimaryColor = ({ theme }) => handleThemeFromObject(theme, 'colors.primary', '#009dff');

export const GlobalStyle = createGlobalStyle`
  body {
    min-width: calc(100vw - 15px);
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const CardsRow = styled.div`
  margin: 56px 0;
  display: flex;

  & > div {
    margin-right: 26px;
  }
`;

export const SelectableCard = styled.div`
  width: 200px;
  height: 96px;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    width: 18px;
    height: 18px;
    margin: 0 8px 4px 0;
  }

  ${({ selected }) =>
    selected
      ? css`
          border: solid 1px ${getPrimaryColor};
          background-color: ${getPrimaryColor};
          * {
            color: ${WHITE};
          }
          svg {
            fill: ${WHITE};
          }
        `
      : css`
          cursor: pointer;
          border: solid 1px rgba(35, 39, 47, 0.3);
          svg {
            fill: rgba(35, 39, 47, 0.64);
          }
        `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  ${({ end }) =>
    end &&
    css`
      justify-content: flex-end;
    `}
`;
