import styled from 'styled-components';
import { FlatButton, TextField as RawTextField } from 'liber-components';

export const TextField = styled(RawTextField).attrs(({ label, hintText }) => ({
  version: 2,
  label: `${label}`,
  hintText: `${hintText}`,
  fullWidth: true,
}))``;

export const Container = styled.div`
  width: 100%;
  padding: 16px 0px 0px 0px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const LinkButton = styled(FlatButton)`
  text-transform: uppercase;
  color: white;
  padding: 3px;
  border-radius: 0px;
  border-bottom: 2px solid white;
  &:hover {
    font-weight: bolder;
  }
`;

export const FlashAlert = styled.div`
  min-height: 64px;
  border-radius: 4px;
  margin: 0 0px 20px 0px;
  padding: 12px 12px 12px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: rgba(35, 39, 47, 0.64);
  border: #009dff solid 1px;
  width: 100%;
  background: #3b9eff; // TODO: esse azul não é o da Liber, porque ele foi escolhido?
`;

export const ModalContainer = styled.div`
  padding: 24px 24px 12px 24px;
  width: 608px;
  border-left: 4px solid #009dff;
  * {
    font-family: Roboto;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  color: #009dff;
  margin-bottom: 24px;
  text-align: center;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin-bottom: 24px;
  margin-bottom: 24px;
  width: 100%;
`;

export const AlertContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  color: white;
  font-size: 20px;
  p {
    padding-left: 20px;
  }
`;

export const ButtonRow = styled(AlertContent)`
  text-align: right;
  justify-content: flex-end;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(FlatButton).attrs(() => ({ size: 'large' }))``;
