import React from 'react';
import PropTypes from 'prop-types';
import { MdChevronRight, MdInformation, TooltipBox } from 'liber-components';
import {
  Container,
  Label,
  Row,
  ValueRow,
  Prefix,
  Value,
  Button,
  TooltipContent,
} from './Display.styles';
import { TooltipWrapper } from './MyWallet.styles';

const Display = ({ prefix, children, label, tooltipContent, detailsAction, disabled }) => (
  <Container>
    <Label disabled={disabled}>
      {label}
      {tooltipContent && (
        <TooltipBox
          key="conciliation-tooltip"
          mount="top"
          fixed
          content={<TooltipContent>{tooltipContent}</TooltipContent>}
        >
          <TooltipWrapper>
            <MdInformation />
          </TooltipWrapper>
        </TooltipBox>
      )}
    </Label>
    <Row>
      <ValueRow disabled={disabled}>
        {prefix && <Prefix>{prefix}</Prefix>}
        <Value>{children}</Value>
      </ValueRow>
    </Row>
    {detailsAction && (
      <Button onClick={detailsAction} disabled={disabled}>
        Ver detalhes
        <MdChevronRight />
      </Button>
    )}
  </Container>
);

Display.propTypes = {
  prefix: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.node,
  tooltipContent: PropTypes.string,
  detailsAction: PropTypes.func,
  disabled: PropTypes.bool,
};

Display.defaultProps = {
  prefix: null,
  children: '0,00',
  label: 'Valor',
  tooltipContent: null,
  detailsAction: undefined,
  disabled: false,
};

export default Display;
