import React from 'react';
import PropTypes from 'prop-types';
import {
  InfoLabel,
  TextField,
  SuccessButton,
  SecondaryButton,
  handleThemeFromObject,
} from 'liber-components';

import { Page, Card, Container, Helper, Form, CheckIcon, ButtonsContainer } from './styles';

const ChangePassword = ({
  email,
  currentPassword,
  newPassword,
  errors,
  passwordConfirmation,
  changeCurrentPassword,
  changeNewPassword,
  changePasswordConfirmation,
  submitForm,
  theme,
}) => (
  <Page>
    <Card width="auto" height="auto">
      <Container>
        <h1>Alterar Minha Senha</h1>
        <InfoLabel color={handleThemeFromObject(theme, 'colors.darkGrey', '#717171')}>
          <Helper>
            <span>
              Para alterar sua senha, informe sua senha atual e a nova senha que deseja cadastrar.
            </span>
            Assim que clicar em “Confirmar”, enviaremos um email de confirmação para&nbsp;
            <strong style={{ color: handleThemeFromObject(theme, 'colors.darkGrey', '#717171') }}>
              {email}
            </strong>
          </Helper>
        </InfoLabel>
        <Form>
          <TextField
            fullWidth
            id="investidor[current_password]"
            name="investidor[current_password]"
            label="Senha Atual"
            type="password"
            value={currentPassword}
            onChange={changeCurrentPassword}
            errorMessage={errors.currentPassword}
          />
          <TextField
            fullWidth
            id="investidor[password]"
            name="investidor[password]"
            label="Nova Senha"
            type="password"
            value={newPassword}
            onChange={changeNewPassword}
            errorMessage={errors.newPassword}
          />
          <TextField
            fullWidth
            id="investidor[password_confirmation]"
            name="investidor[password_confirmation]"
            label="Confirmar Nova Senha"
            type="password"
            value={passwordConfirmation}
            onChange={changePasswordConfirmation}
            errorMessage={errors.passwordConfirmation}
          />
        </Form>
        <ButtonsContainer>
          <SecondaryButton>Voltar</SecondaryButton>
          <SuccessButton
            onClick={() => submitForm(currentPassword, newPassword, passwordConfirmation)}
          >
            <CheckIcon />
            Confirmar
          </SuccessButton>
        </ButtonsContainer>
      </Container>
    </Card>
  </Page>
);

ChangePassword.propTypes = {
  email: PropTypes.string.isRequired,
  currentPassword: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  changeCurrentPassword: PropTypes.func.isRequired,
  changeNewPassword: PropTypes.func.isRequired,
  changePasswordConfirmation: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

ChangePassword.defaultProps = {
  theme: {},
};

export default ChangePassword;
