import axios from 'axios';
import { calendar } from '../url';

export function getCalendarData(token, from, to) {
  return {
    type: 'INVESTOR_DASHBOARD_GET_CALENDAR_DATA',
    payload: axios.get(calendar, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        liquidation_date_from: from,
        liquidation_date_to: to,
      },
    }),
  };
}

export const empty = null;
