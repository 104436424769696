import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdFinance, SuccessButton } from 'liber-components';
import SimulationInfo from './components/SimulationInfo/SimulationInfo';
import SimulationTerms from './components/SimulationTerms/SimulationTerms';
import BankAccountSelect from './components/BankAccountSelect/BankAccountSelect';
import {
  SimulationTitle,
  SelectedNumber,
  ContentContainer,
  StyledDrawer,
  DrawerHeaderContainer,
  StyledLink,
} from './SimulationDrawer.styles';
import {
  selectBankAccount,
  requestAnticipation,
  requestDownloadSimulation,
  beginAnticipation,
} from '../../actions/InvoiceSimulationAction';
import SimulationDialog from './components/SimulationDialog/SimulationDialog';
import { INVOICE_TABS, tabType } from '../../utils';

const { AVAILABLE } = INVOICE_TABS;

const onAnticipation = (onRequestAnticipation, selectedInvoices, bankAccountId, token) => () => {
  const params = {
    ids: selectedInvoices,
    conta_bancaria_id: bankAccountId,
  };
  onRequestAnticipation(token, params);
};

const onDownloadSimulation = (onRequestDownloadSimulation, selectedInvoices, token) => () => {
  const params = {
    file_format: 'xlsx',
    ids: selectedInvoices,
  };
  onRequestDownloadSimulation(token, params);
};

const openDialog = setOpen => () => {
  setOpen(true);
};

const closeDialog = setOpen => () => {
  setOpen(false);
};

export const SimulationDrawer = ({
  selectedInvoices,
  onRequestAnticipation,
  onRequestDownloadSimulation,
  onBeginAnticipation,
  bankAccountId,
  token,
  activeTab,
  loadingAllPage,
}) => {
  const [open, setOpen] = useState(false);
  const [simulationTermsOpen, setSimulationTermsOpen] = useState(false);
  const [canDownload, setCanDownload] = useState(false);

  useEffect(() => {
    if (!simulationTermsOpen) setCanDownload(false);
  }, [simulationTermsOpen]);

  useEffect(() => {
    setSimulationTermsOpen(false);
  }, [loadingAllPage]);

  return (
    <StyledDrawer
      blockNavigation={false}
      position="bottom"
      drawerSize={1120}
      size={128}
      show={activeTab === AVAILABLE && selectedInvoices.length > 0}
    >
      <ContentContainer>
        <DrawerHeaderContainer>
          <SimulationTitle>
            Simulação de Adiantamento{' '}
            <SelectedNumber>{`(${selectedInvoices.length} títulos)`}</SelectedNumber>
          </SimulationTitle>
          <StyledLink
            href="#"
            version={2}
            size="small"
            onClick={openDialog(setSimulationTermsOpen)}
          >
            <MdFinance />
            Baixar Relatório da Simulação (Excel)
          </StyledLink>
        </DrawerHeaderContainer>
        <SimulationInfo
          onBeginAnticipation={onBeginAnticipation}
          onRequestAnticipation={openDialog(setOpen)}
          selectedInvoices={selectedInvoices}
        />
      </ContentContainer>
      <SimulationDialog
        title="Confirmar Solicitação"
        open={open}
        onClose={closeDialog(setOpen)}
        confirmationButtonProps={{
          component: SuccessButton,
          onConfirmation: onAnticipation(
            onRequestAnticipation,
            selectedInvoices,
            bankAccountId,
            token,
          ),
        }}
      >
        <BankAccountSelect />
        <SimulationInfo inDialog selectedInvoices={selectedInvoices} />
      </SimulationDialog>
      <SimulationDialog
        title="Termos do Relatório de Simulação"
        open={simulationTermsOpen}
        onClose={closeDialog(setSimulationTermsOpen)}
        maxWidth={592}
        confirmationButtonProps={{
          text: 'CONTINUAR',
          onConfirmation: onDownloadSimulation(
            onRequestDownloadSimulation,
            selectedInvoices,
            token,
          ),
          disabled: !canDownload,
        }}
      >
        <SimulationTerms checked={canDownload} onChange={() => setCanDownload(!canDownload)} />
      </SimulationDialog>
    </StyledDrawer>
  );
};

SimulationDrawer.propTypes = {
  selectedInvoices: PropTypes.arrayOf(PropTypes.number),
  onRequestAnticipation: PropTypes.func.isRequired,
  onRequestDownloadSimulation: PropTypes.func.isRequired,
  onBeginAnticipation: PropTypes.func.isRequired,
  bankAccountId: PropTypes.number,
  token: PropTypes.string.isRequired,
  activeTab: tabType.isRequired,
  loadingAllPage: PropTypes.bool,
};

SimulationDrawer.defaultProps = {
  selectedInvoices: [],
  bankAccountId: null,
  loadingAllPage: false,
};

const mapStateToProps = ({
  invoiceSimulation: { selectedInvoices, bankAccountId, activeTab, loadingAllPage },
}) => ({
  bankAccountId,
  selectedInvoices,
  activeTab,
  loadingAllPage,
});

const mapDispatchToProps = {
  onRequestAnticipation: requestAnticipation,
  onRequestDownloadSimulation: requestDownloadSimulation,
  onSelectBankAccount: selectBankAccount,
  onBeginAnticipation: beginAnticipation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationDrawer);
