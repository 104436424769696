import styled, { createGlobalStyle } from 'styled-components';
import { handleThemeFromObject } from 'liber-components';
import { Liber } from 'liber-components/components/Themes';

const pattern = `
  <svg width="120" height="120" viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg" version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <pattern id="pattern" patternUnits="userSpaceOnUse" width="4" height="4">
        <path d="M3,-1 l2,2 M0,0 l4,4  M-1,3 l2,2" style="stroke:%23fafafa; stroke-width:0.2;opacity:0.5" />
      </pattern>
    </defs>
    <rect x="0" y="0" width="100%" height="100%" fill="url(%23pattern)"/>
  </svg>
`;

export const Global = createGlobalStyle`
  .waiting_approval_container{
    margin-top: -15px;
    min-height: 100vh;
    overflow:auto;
    background: url('data:image/svg+xml;utf8,${pattern}'), linear-gradient(311deg, ${Liber.colors.lightBlue}, #63d0f8);
    box-sizing:border-box;
  }
`;

export const Background = styled.div`
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;

  @media (min-width: 576px) {
    justify-content: flex-start;
    padding: 25px;
  }
  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 25px;
  }
  @media (min-width: 992px) {
    justify-content: flex-end;
    padding: 0 50px;
  }
  @media (min-width: 1200px) {
    justify-content: flex-end;
    padding: 0 50px;
  }
`;

export const Card = styled.div`
  border-radius: 2px;
  background-color: ${props => handleThemeFromObject(props.theme, 'colors.liberWhite', '#fafafa')};
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 16px;

  @media (min-width: 576px) {
    padding: 24px;
    width: 476px;
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    padding: 24px;
    width: 476px;
    margin-bottom: 16px;
  }
  @media (min-width: 992px) {
    padding: 48px;
    width: unset;
    margin-bottom: 50px;
  }
  @media (min-width: 1200px) {
    padding: 48px;
    width: 800px;
    margin-bottom: 50px;
  }
`;

export const CenterCard = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CardTitle = styled.h2`
  color: #45c0fe;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-transform: uppercase;
  font-family: inherit;
  font-size: 2em;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 992px) {
    margin: 20px 0;
  }
  @media (max-width: 576px) {
    font-size: 1.5em;
  }
`;

export const CardParagraph = styled.p`
  font-size: 1em;
`;

export const BankAccounts = styled.div`
  margin-top: 15px;
  display: flex;
  flex: 1;
  justify-content: space-around;
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

export const BankAccount = styled.div`
  text-align: left;
  margin-top: 15px;
`;
