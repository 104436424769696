import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  TextField,
  Typography,
  Icon,
} from 'liber-salti';

import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';
import { handleError } from '../../../../vendor/Utils';
import * as S from './styles';

const IncrementChangeModal = ({ isOpen, onCloseModal, onSave, rebateIncrement }) => {
  const [incrementNumber, setIncrementNumber] = useState('');

  useEffect(() => {
    setIncrementNumber((rebateIncrement * 100).toFixed(4));
  }, []);

  const handleSaveSubmit = useCallback(() => {
    if (+incrementNumber > 99.9999) {
      return handleError('Erro!', 'O valor do incremento deve ser menor que 100%');
    }

    onSave((incrementNumber.replace(',', '.') / 100).toFixed(6));
    return onCloseModal();
  }, [incrementNumber, onSave]);

  const handleInputChange = event => {
    const inputValue = event.target.value;

    const regex = /^(\d{1,3}([.,]\d{0,4})?)?$/;

    if (regex.test(inputValue)) {
      setIncrementNumber(inputValue);
    }
  };

  return (
    <SaltiThemeWrapper>
      <Dialog open={isOpen} onClose={onCloseModal}>
        <DialogHeader onClose={onCloseModal} title="Editar incremento da operação" />
        <DialogContent>
          <Typography color="textPrimary" gutterBottom>
            Dados do fornecedor:
          </Typography>
          <TextField
            label="Incremento da operação"
            placeholder="Incremento da operação"
            fullWidth
            size="large"
            data-testid="increment-input"
            suffixText="% a.m."
            required
            value={incrementNumber}
            onChange={e => handleInputChange(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="text" onClick={onCloseModal} data-testid="cancel-button">
            Cancelar
          </Button>
          <S.BlockButtonWrapper>
            <Button
              color="primary"
              variant="contained"
              endIcon={<Icon name="SaveEdit" />}
              onClick={handleSaveSubmit}
              disabled={!incrementNumber}
              data-testid="submit-button"
            >
              Salvar edição
            </Button>
          </S.BlockButtonWrapper>
        </DialogActions>
      </Dialog>
    </SaltiThemeWrapper>
  );
};

export default memo(IncrementChangeModal);

IncrementChangeModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSave: PropTypes.func,
  rebateIncrement: PropTypes.string,
};

IncrementChangeModal.defaultProps = {
  isOpen: false,
  onCloseModal: () => {},
  onSave: () => {},
  rebateIncrement: '0',
};
