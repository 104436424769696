import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';
import axios from 'axios';
import messages from '../messages';

const makeRequest = (type, data) => {
  switch (type) {
    case 'post':
      return axios({
        method: 'POST',
        url: '/investidor/enderecos.json',
        data,
        responseType: 'json',
      });
    case 'patch':
      return axios({
        method: 'PATCH',
        url: `/investidor/enderecos/${data.endereco.id}.json`,
        data,
        responseType: 'json',
      });
    case 'delete':
      return axios({
        method: 'DELETE',
        url: `/investidor/enderecos/${data.id}.json`,
        data,
        responseType: 'json',
      });
    case 'default':
      return axios({
        method: 'POST',
        url: `/investidor/enderecos/${data.endereco.id}/cobranca_padrao.json`,
        data,
        responseType: 'json',
      });
    case 'zipCode':
      return axios({
        method: 'GET',
        url: '/busca_cep',
        params: { zipcode: data.replace('.', '') },
        responseType: 'json',
      });
    default:
      return null;
  }
};

export const hidrateForm = (address, index) => ({
  type: 'MY_ACCOUNT_ADDRESS_INVESTOR_HIDRATE_FORM',
  payload: {
    address,
    index,
  },
});

export const closeModalForm = () => ({
  type: 'MY_ACCOUNT_ADDRESS_INVESTOR_CLOSE_MODAL_FORM',
});

const fieldPayload = (field, value, agregate) => {
  if (field === 'country') {
    return {
      address: {
        country: value,
        state: '',
        city: '',
      },
    };
  }
  if (field === 'state' && agregate === 'BR') {
    return {
      address: {
        state: value,
        city: '',
      },
    };
  }
  return {
    address: { [field]: value },
  };
};

export const fetchZipCode = zipCode => dispatch => {
  const request = makeRequest('zipCode', zipCode);
  dispatch({
    type: 'MY_ACCOUNT_ADDRESS_INVESTOR_EDIT_FIELD',
    payload: { address: { zipCode } },
  });
  dispatch({
    type: 'MY_ACCOUNT_ADDRESS_INVESTOR_ZIPCODE_PENDING',
  });
  if (request) {
    return request
      .then(({ data }) => {
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_EDIT_FIELD',
          payload: { address: { ...data, country: 'BR' } },
        });
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_ZIPCODE_SUCCESS',
        });
      })
      .catch(error => {
        const wrongZip = error.response.status === 422;
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_ZIPCODE_REJECT',
        });
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_VALIDATION_ERROR',
          payload: { zipCode: wrongZip ? messages.wrongZipCode : messages.zipApiError },
        });
      });
  }
  toast('Metodo não suportado', 'error', 8000);
  return null;
};

export const editField = (field, value, agregate = null) => {
  if (field === 'zipCode' && value.length === 10) {
    return fetchZipCode(value);
  }
  return {
    type: 'MY_ACCOUNT_ADDRESS_INVESTOR_EDIT_FIELD',
    payload: fieldPayload(field, value, agregate),
  };
};

export const addressPresenter = payload => ({
  address: payload.address,
  number: payload.number,
  complement: payload.complement,
  city: payload.city,
  state: payload.state,
  country: payload.country,
  district: payload.district,
  zipCode: payload.zip_code,
  kind: payload.kind,
  id: payload.id || undefined,
});

export const validateAddress = address => {
  const keys = Object.keys(address);
  const validationErrors = {};
  const isValid =
    keys.length !== 0
      ? keys.reduce((before, key) => {
          if (key === 'complement' || key === 'id' || key === 'kind') {
            return true && before;
          }
          if (address[key].length === 0) {
            validationErrors[key] = messages.empty;
            return false && before;
          }
          return true && before;
        }, true)
      : false;
  return {
    validate: isValid,
    validationErrors,
  };
};

export const removeAddress = (id, index) => dispatch => {
  const csrfToken = ReactOnRails.authenticityToken();
  const request = makeRequest('delete', {
    id,
    authenticity_token: csrfToken,
  });
  if (request) {
    dispatch({
      type: 'MY_ACCOUNT_ADDRESS_INVESTOR_DELETE_PENDING',
    });
    return request
      .then(({ data }) => {
        const { message } = data.data;
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_DELETE_SUCCESS',
          payload: index,
        });
        toast(message, 'success', 8000);
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_DELETE_REJECT',
        });
      });
  }
  return toast('Metodo não suportado', 'error', 8000);
};

export const setDefaultAddress = id => dispatch => {
  const obj = {
    endereco: { id },
  };
  const csrfToken = ReactOnRails.authenticityToken();
  const request = makeRequest('default', {
    ...obj,
    authenticity_token: csrfToken,
  });
  if (request) {
    dispatch({
      type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SET_DEFAULT_PENDING',
    });
    return request
      .then(({ data }) => {
        const { message } = data.data;
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SET_DEFAULT_SUCCESS',
          payload: id,
        });
        toast(message, 'success', 8000);
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SET_DEFAULT_REJECT',
        });
      });
  }
  return toast('Metodo não suportado', 'error', 8000);
};

export const submitForm =
  (address = {}, index) =>
  dispatch => {
    const { validate, validationErrors } = validateAddress(address);
    if (validate) {
      const csrfToken = ReactOnRails.authenticityToken();
      const obj = {
        endereco: {
          id: address.id || undefined,
          address: address.address,
          number: address.number,
          complement: address.complement,
          city: address.city,
          state: address.state,
          country: address.country,
          district: address.district,
          zip_code: address.zipCode,
          kind: address.kind,
        },
      };
      const request = makeRequest(address.id ? 'patch' : 'post', {
        ...obj,
        authenticity_token: csrfToken,
      });
      if (request) {
        dispatch({
          type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_PENDING',
        });
        return request
          .then(({ data }) => {
            const { message, ...payload } = data.data;
            dispatch({
              type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_SUCCESS',
              payload: {
                address: addressPresenter(payload),
                index,
              },
            });
            toast(message, 'success', 8000);
          })
          .catch(error => {
            const message = errors.http[{ ...error.response }.status || 500];
            toast(message, 'error', 8000);
            dispatch({
              type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_REJECT',
            });
          });
      }
      return toast('Metodo não suportado', 'error', 8000);
    }
    return dispatch({
      type: 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_VALIDATION_ERROR',
      payload: validationErrors,
    });
  };
