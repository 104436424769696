import styled from 'styled-components';
import {
  PrimaryLink,
  PrimaryButton,
  SecondaryButton as RawSecondaryButton,
} from 'liber-components';

export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  margin-bottom: 32px;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddLink = styled(PrimaryLink).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
  margin-left: 10px;
`;

export const OutlinedLink = styled(AddLink).attrs(() => ({ outlined: true }))``;

export const Title = styled.span`
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: left;
  color: #009dff;
`;

export const DropDownContent = styled.div`
  padding: 14px;
  background-color: blue;
`;

export const DropDownContentRow = styled.a`
  margin-bottom: 3px;
  background-color: blue;
  margin-top: 3px;
  color: inherit;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const ScheduleModalButton = styled(PrimaryButton).attrs(() => ({
  version: 2,
  size: 'large',
}))`
  text-transform: uppercase;
  margin-left: 10px;
`;

export const OutlinedButton = styled(ScheduleModalButton).attrs(() => ({
  outlined: true,
}))``;

export const SecondaryButton = styled(RawSecondaryButton).attrs(() => ({
  version: 2,
  size: 'small',
}))``;
