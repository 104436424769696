import axios from 'axios';
import { GET_TRADE_SETTLEMENT_URL } from './constants';

export function getTradeSettlements(token, params = {}) {
  return axios.get(GET_TRADE_SETTLEMENT_URL, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
