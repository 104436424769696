import styled, { css } from 'styled-components';
import { SecondaryButton as GreyButton } from 'liber-components';
import Icon from '@mdi/react';
import { mdiEye, mdiCheck } from '@mdi/js';

import { statusColors } from './constants';

const marginProps = ({ mb, mt, ml, mr }) => {
  let styles = '';

  if (mb) styles += `margin-bottom: ${mb}px;`;
  if (mt) styles += `margin-top: ${mt}px;`;
  if (ml) styles += `margin-left: ${ml}px;`;
  if (mr) styles += `margin-right: ${mr}px;`;

  return styles;
};

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  min-width: 545px;
  box-shadow: 0px 2px 8px rgba(64, 95, 113, 0.12);
  border-radius: 4px;

  ${marginProps}
`;

export const CardHeader = styled.div`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #405f71;
  padding: 12px;
  background: rgba(64, 95, 113, 0.03);
  width: 100%;

  ${marginProps}
`;

export const CardContent = styled.div`
  padding: 26px;
  width: 100%;

  ${marginProps}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  ${marginProps}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 84px;
  margin: 0 auto;

  ${marginProps}
`;

export const Label = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;

  ${marginProps}
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #405f71;

  ${marginProps}
`;

export const SecondaryButton = styled(GreyButton).attrs(() => ({ version: 2, size: 'small' }))`
  ${({ onlyIcon }) =>
    onlyIcon &&
    css`
      padding: 0;
    `}

  ${marginProps}
`;

export const ButtonIcon = styled(Icon).attrs(({ path }) => ({
  size: '31px',
  color: '#506286',
  path,
}))`
  cursor: pointer;

  ${marginProps}
`;

export const SignedBy = styled.div`
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #2b354a;

  ${marginProps}
`;

export const SignedIcon = styled(Icon).attrs(() => ({
  size: '24px',
  color: '#13CE66',
  path: mdiCheck,
}))`
  margin-top: -5px;

  ${marginProps}
`;

export const ContentCaption = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #6287a7;

  ${marginProps}
`;

export const VerticalDivider = styled.div`
  width: 0px;
  height: 50px;
  border: 1px solid rgba(64, 95, 113, 0.12);
  margin: 0px 24px;

  ${marginProps}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #cdd4df;
  margin: 16px 0px;

  ${marginProps}
`;

export const StatusLabel = styled.div`
  padding: 7px 10px;
  background: ${({ status }) => statusColors[status].bg};
  border-radius: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${({ status }) => statusColors[status].color};

  ${marginProps}
`;

export const Spacer = styled.div`
  display: flex;
  flex-grow: 1;

  ${marginProps}
`;

export const EyeIcon = styled(Icon).attrs(() => ({
  size: '20px',
  color: '#4b6f85',
  path: mdiEye,
}))`
  cursor: pointer;

  ${marginProps}
`;
