import admin from './adminReducer';
import fornecedor from './fornecedorReducer';
import sacado from './sacadoReducer';
import investidor from './investidorReducer';
import operator from './operatorReducer';
import confirmation from './confirmation';
import confirmationToken from './confirmationToken';
import recovery from './passwordRecoveryReducer';
import reset from './passwordResetFormReducer';
import investidorSignUp from './investidorSignUpReducer';
import fornecedorSignUp from './fornecedorSignUpReducer';
import operatorSelection from './operatorSelectionReducer';

export default {
  admin,
  fornecedor,
  sacado,
  investidor,
  operator,
  confirmation,
  confirmationToken,
  recovery,
  reset,
  investidorSignUp,
  fornecedorSignUp,
  operatorSelection,
};
