import styled, { css } from 'styled-components';
import { PrimaryButton as BasePrimaryButton, LoadingBunny } from 'liber-components';
import {
  Step as BaseStep,
  Separator as BaseSeparator,
} from 'liber-components/components/ProgressBar';

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled(LoadingBunny)`
  width: 200px;
  padding: 32px;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.13px;
  text-align: center;
  color: #009dff;
  margin: 32px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: #6287a7;
  width: 100%;
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

export const SteperContainer = styled.div`
  width: 528px;
  height: 100%;
  margin: 32px calc((100% - 528px) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Step = styled(BaseStep)`
  --size: 24px;
  cursor: default;
`;

export const Separator = styled(BaseSeparator)`
  height: 4px;
  ::after {
    height: 4px;
  }
`;

export const ButtonsRow = styled.div`
  width: 100%;
  min-height: 96px;
  padding-top: 48px;
  display: flex;
  justify-content: center;
`;

export const PrimaryButton = styled(BasePrimaryButton).attrs(() => ({
  version: '2',
  size: 'large',
}))`
  margin: 8px;
  margin-bottom: 0px;
  text-transform: uppercase;
`;

export const SecondaryButton = styled(BasePrimaryButton).attrs(() => ({
  outlined: true,
  version: '2',
  size: 'large',
}))`
  margin: 8px;
  margin-bottom: 1px;
  text-transform: uppercase;
`;

export const InfoContainer = styled.div`
  width: 640px;
  margin: 0px calc((100% - 640px) / 2);
  margin-bottom: 48px;
`;

export const StepTitle = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: 0.1px;
  text-align: left;
  color: #6287a7;
  margin-top: 4px;
  margin-bottom: 12px;
`;

export const StepCount = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 0.05px;
  text-align: left;
  color: #6287a7;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const Image = styled.img`
  width: 128px;
  height: 128px;
`;
