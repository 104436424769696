import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { Tab, TooltipBox, DropDownTab } from 'liber-components';

import {
  ScreenTitleRow,
  Subtitle,
  DisabledBootstrapBorders,
  Tabs,
  ButtonsRow,
  PrimaryButton,
} from './AdminNegotiations.styles';
import Table from './Table/Table';
import { TABS, SUBTITLE_INDEX_MAP } from '../utils';
import {
  fetchAdminNegotiations,
  resetSelection,
  updateActiveTab,
  downloadReport,
  subscribeActionCable,
} from '../actions/AdminNegotiationsActions';
import LoadingButton from '../../../common-components/Buttons/LoadingButton';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';

const TOOLTIP_TEXT = 'A exportação leva em conta os filtros e a aba selecionada';
const TOOLTIP_DISABLED_BUTTON = 'É necessário aplicar um filtro de datas para habilitar essa opção';

export const AdminNegotiations = ({
  onFetchNegotiations,
  pagination,
  onResetSelection,
  activeTab,
  setActiveTab,
  onDownloadReport,
  onSubscribeActionCable,
  downloadReportLoading,
  sorting,
  disabledButtonFile,
}) => {
  const { current, per } = pagination;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showExportButton, setShowExportButton] = React.useState(false);

  React.useEffect(() => {
    onSubscribeActionCable();
  }, []);

  React.useEffect(() => {
    onFetchNegotiations();
  }, [current, per, activeTab, sorting]);

  React.useEffect(() => {
    onResetSelection();
  }, [activeTab]);

  const getActiveTabProperties = () => {
    let currentTab = 0;
    let tabIndex;
    let optionIndex;
    TABS.forEach(({ options }, currentTabIndex) => {
      options.forEach((option, currentOptionIndex) => {
        if (currentTab === activeTab) {
          tabIndex = currentTabIndex;
          optionIndex = currentOptionIndex;
        }
        currentTab += 1;
      });
    });
    return TABS[tabIndex].options[optionIndex];
  };
  React.useEffect(() => {
    const { label } = getActiveTabProperties();
    setShowExportButton(['Concluídas', 'Sem pendências'].includes(label));
  }, [activeTab]);

  const renderTable = () => {
    const { headers, isSelectable, label } = getActiveTabProperties();
    return <Table headers={headers} isSelectable={isSelectable} label={label} />;
  };

  const renderPageSubtitle = () => {
    if (SUBTITLE_INDEX_MAP[activeTab]) {
      const { subtitle, description } = SUBTITLE_INDEX_MAP[activeTab];
      return (
        <>
          <Subtitle marginTop={-8} strong>
            {subtitle}
          </Subtitle>
          <Subtitle marginBottom={32}>{description}</Subtitle>
        </>
      );
    }
    return undefined;
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      />
      <ScreenTitleRow>
        Negociações
        <ButtonsRow>
          {showExportButton && (
            <TooltipBox mount="top" fixed content={TOOLTIP_TEXT}>
              <div>
                <PrimaryButton
                  id="export-terms-btn"
                  onClick={() => setShowConfirmationModal(true)}
                  outlined
                >
                  Exportar termos
                </PrimaryButton>
              </div>
            </TooltipBox>
          )}
          <TooltipBox
            mount="top"
            fixed
            content={disabledButtonFile ? TOOLTIP_DISABLED_BUTTON : TOOLTIP_TEXT}
          >
            <div>
              <LoadingButton
                id="download-report-btn"
                ButtonComponent={PrimaryButton}
                onClick={onDownloadReport}
                loading={downloadReportLoading}
                disabled={disabledButtonFile}
              >
                Baixar planilha
              </LoadingButton>
            </div>
          </TooltipBox>
        </ButtonsRow>
      </ScreenTitleRow>
      {renderPageSubtitle()}
      <DisabledBootstrapBorders>
        <Tabs onChange={setActiveTab} activeTab={activeTab}>
          {TABS.map(({ label: dropdownLabel, options }) => {
            if (options.length === 1) {
              const { label } = options[0];
              return <Tab version="2" label={label} key={`tab-${uniqueId()}`} strongBorder />;
            }
            const tabs = options.map(({ label }) => (
              <Tab version="2" label={label} key={`dropdown-tab-${uniqueId()}`} strongBorder />
            ));
            return (
              <DropDownTab
                key={`tab-${uniqueId()}`}
                minWidth={260}
                strongBorder
                label={dropdownLabel}
              >
                {tabs}
              </DropDownTab>
            );
          })}
        </Tabs>
      </DisabledBootstrapBorders>
      {renderTable()}
    </>
  );
};

AdminNegotiations.propTypes = {
  onFetchNegotiations: PropTypes.func,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    previous: PropTypes.number,
    next: PropTypes.number,
    per: PropTypes.number,
    pages: PropTypes.number,
    count: PropTypes.number,
  }),
  onResetSelection: PropTypes.func,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  onDownloadReport: PropTypes.func,
  onSubscribeActionCable: PropTypes.func,
  downloadReportLoading: PropTypes.bool,
  sorting: PropTypes.shape({}),
  disabledButtonFile: PropTypes.bool,
};

AdminNegotiations.defaultProps = {
  onFetchNegotiations: () => {},
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 25,
    pages: 1,
    count: 0,
  },
  onResetSelection: () => null,
  activeTab: 0,
  setActiveTab: () => null,
  onDownloadReport: () => null,
  onSubscribeActionCable: () => null,
  downloadReportLoading: false,
  sorting: {},
  disabledButtonFile: true,
};

const mapStateToProps = ({
  adminNegotiations: { pagination, activeTab, downloadReportLoading, sorting, disabledButtonFile },
}) => ({
  pagination,
  activeTab,
  downloadReportLoading,
  sorting,
  disabledButtonFile,
});

const mapDispatchToProps = {
  onFetchNegotiations: fetchAdminNegotiations,
  onResetSelection: resetSelection,
  setActiveTab: updateActiveTab,
  onDownloadReport: downloadReport,
  onSubscribeActionCable: subscribeActionCable,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNegotiations);
