import React from 'react';
import PropTypes from 'prop-types';

import { routes, userActions } from './constants';

import SaltiSidebar from '../SaltiSidebar';
import { dispatchAmplitudeEvent } from '../../vendor/Utils';

const Component = ({
  cookieDomain,
  logoUrl,
  anticipationLimitsAvailable,
  usesDigitalSignature,
  loggedAsOperator,
  name,
  portalName,
  paths,
  userPaths,
}) => {
  const navigate = (path, option) => {
    dispatchAmplitudeEvent('buyer_sidebar', { option });
    window.location.assign(path);
  };

  const { pathname } = window.location;

  const userMenu = userActions(
    pathname,
    usesDigitalSignature,
    loggedAsOperator,
    userPaths,
    navigate,
  );

  const sidebarRoutes = routes(paths, anticipationLimitsAvailable);

  return (
    <SaltiSidebar
      cookieDomain={cookieDomain}
      logoUrl={logoUrl}
      name={name}
      portalName={portalName}
      sidebarRoutes={sidebarRoutes}
      userMenu={userMenu}
    />
  );
};

Component.propTypes = {
  cookieDomain: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  anticipationLimitsAvailable: PropTypes.bool.isRequired,
  usesDigitalSignature: PropTypes.bool.isRequired,
  loggedAsOperator: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  portalName: PropTypes.string.isRequired,
  paths: PropTypes.objectOf(PropTypes.any).isRequired,
  userPaths: PropTypes.objectOf(PropTypes.any).isRequired,
};

Component.defaultProps = {};

export default Component;
