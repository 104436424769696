import styled, { css, keyframes } from 'styled-components';
import {
  handleThemeFromObject as getTheme,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaWindowMinimize,
  FaWindowMaximize,
  hexToRgb,
  media,
} from 'liber-components';

export const Action = styled.div`
  margin-left: auto;
  --size: 12px;
  min-width: var(--size);
  max-width: var(--size);
  cursor: pointer;
`;

export const MaxIcon = styled(FaWindowMaximize)`
  --size: 12px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
`;
export const MinIcon = styled(FaWindowMinimize)`
  --size: 12px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
`;

export const SummaryContainer = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${props => getTheme(props.theme, 'colors.v2.liberWhite', '#FAFDFF')};
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const FunderSelection = styled.div`
  flex-basis: 100%;
  display: flex;
`;

const move = keyframes`
  0%{
      background-position: -671px 0
  }
  100%{
      background-position: 671px 0
  }
`;

export const LoadingValue = styled.div`
  width: 50px;
  max-width: 100%;
  height: 20px;
  margin-top: 3px;
  background-color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  border-radius: 4px;
  position: relative;
  opacity: 0.2;
  box-sizing: border-box;
  margin-top: 8px;

  & :after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${move};
    animation-timing-function: linear;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7) 8%,
      rgba(255, 255, 255, 0.3) 33%,
      rgba(255, 255, 255, 0.7) 75%
    );

    animation-duration: 30s;
  }
`;

export const Values = styled.div`
  color: ${props => getTheme(props.theme, 'colors.v2.gray3', '#c6d1d8')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.regular', '400')};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 72px;
  min-width: 50px;

  & span {
    font-size: ${props => getTheme(props.theme, 'fontSizes.h5', '20px')};
    font-weight: ${props => getTheme(props.theme, 'fontWeights.medium', '500')};
    margin-top: 8px;
    color: ${props =>
      props.selected ? getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85') : null};
  }
`;

export const Info = styled.div`
  color: ${props =>
    !props.selected
      ? getTheme(props.theme, 'colors.v2.gray4', '#6287a7')
      : getTheme(props.theme, 'colors.v2.gray3', '#C6D1D8')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.regular', '400')};
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
`;

const notice = css`
  --size: 13px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  margin-right: 8px;
`;

export const CircleNotice = styled(FaExclamationCircle)`
  ${notice}
  fill: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009DFF')};
`;
export const TriangleNotice = styled(FaExclamationTriangle)`
  ${notice}
  fill: ${props => getTheme(props.theme, 'colors.v2.yellow', '#fabe00')};
`;

const bottom = pos =>
  ({
    open: css`
      bottom: 0px;
    `,
    closed: css`
      bottom: -214px;
    `,
    hide: css`
      bottom: -265px;
    `,
  }[pos] ||
  css`
    bottom: -265px;
  `);

export const FixedBox = styled.div`
  position: fixed;
  ${props => bottom(props.pos)}
  right: 12vw;
  width: 386px;
  height: 254px;
  border-radius: 8px 8px 0px 0px;
  line-height: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 9999;
  background-color: ${props => getTheme(props.theme, 'colors.v2.white', '#ffffff')};
  box-shadow: -2px -5px 10px 0px ${props => hexToRgb(getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85'), 0.25)};
  transition: bottom 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  & ${Values} {
    font-size: ${props => getTheme(props.theme, 'fontSizes.p', '16px')};
    flex-direction: row;
    align-items: baseline;
    margin: 0px;
    margin-bottom: 8px;
  }

  & ${Values} span {
    margin-left: 8px;
    margin-top: 0px;
  }

  & ${Values} ${LoadingValue} {
    margin-left: 8px;
    margin-top: 3px;
  }

  & ${ButtonContainer} {
    margin-top: 8px;
  }

  & ${Info} span {
    width: 19px;
    display: inline-block;
  }
`;

export const BoxHead = styled.div`
  min-height: 40px;
  background-color: ${props => getTheme(props.theme, 'colors.v2.gray1', '#eaf7ff')};
  color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.p', '16px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.medium', '500')};
  padding: 0px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const BoxBody = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const EmptyInfo = styled.span`
  height: 42px;
  width: 100%;
`;

export const TooltipContent = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-width: ${props => props.width || '200px'};
`;

export const TooltipItem = styled.span`
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.bold', '700')};
  color: ${props => getTheme(props.theme, 'colors.darkGrey', '#717171', props.color)};
  white-space: nowrap;
  flex-basis: 60%;
  margin-bottom: 7px;
`;

export const TooltipItemMonth = styled(TooltipItem)`
  text-align: left;
  color: ${props => getTheme(props.theme, 'colors.darkGrey', '#717171', props.color)};
`;

export const TooltipItemValue = styled(TooltipItem)`
  text-align: right;
  color: ${props => getTheme(props.theme, 'colors.darkGrey', '#717171', props.color)};
`;

export const TooltipBar = styled(TooltipItem)`
  margin-right: 5px;
  margin-left: 5px;
  border-bottom: 2px solid;
  color: ${props => getTheme(props.theme, 'colors.darkGrey', '#717171', props.color)};
`;

export const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  ${media.large`
  flex-direction: row;
  `}
`;

export const ContainerChecked = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  color: #6287a7;
`;
