import React from 'react';
import PropTypes from 'prop-types';
import { Button, CardContent, Divider, Grid, Icon } from 'liber-salti';
import * as S from './styles';

const SummaryCard = ({ children, icon, action }) => {
  const hasAction = action.onClick && action.label;

  return (
    <S.Card>
      <CardContent>
        <S.CardGrid item container alignItems="center">
          <Icon name={icon} size="large" />
          <Grid item direction="column">
            {children}
          </Grid>
        </S.CardGrid>
        {hasAction && (
          <>
            <Divider />
            <Button size="medium" variant="outlined" onClick={action.onClick}>
              {action.label}
            </Button>
          </>
        )}
      </CardContent>
    </S.Card>
  );
};

SummaryCard.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  action: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
};

SummaryCard.defaultProps = {
  action: {},
};

export default SummaryCard;
