import axios from 'axios';
import { handleError } from '../../vendor/Utils';

const apiUrl = SERVICE_API_URL; // eslint-disable-line
const ACCEPT_NEW_CONTRACT_ENDPOINT = '/api/v1/investidor/contracts/accept_membership_contract';
const REDIRECT_URL = '/';

const api = axios.create({ baseURL: apiUrl });

export const acceptNewContract = token => {
  api
    .post(
      ACCEPT_NEW_CONTRACT_ENDPOINT,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(() => {
      window.location.href = REDIRECT_URL;
    })
    .catch(() => handleError());
};
