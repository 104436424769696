import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import ConfirmationModalProvider from './context';
import ModalBody from './components/ModalBody';

const ConfirmationModal = ({ show, onLeaved }) => (
  <Modal show={show} onLeaved={onLeaved}>
    <ConfirmationModalProvider onLeaved={onLeaved}>
      <ModalBody />
    </ConfirmationModalProvider>
  </Modal>
);

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onLeaved: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  show: false,
  onLeaved: () => null,
};

export default ConfirmationModal;
