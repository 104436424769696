import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import * as S from './styles';
import DateFieldFilter from '../../../../common-components/Filters/DateFieldFilter/DateFieldFilter';
import { Text } from '../CommonStyles';
import { RatesPreviewContext } from '../../context';
import { formatDate } from '../../utils';

const graphOptions = {
  scales: {
    xAxes: [
      {
        ticks: {
          padding: 10,
          maxTicksLimit: 20,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 0.5,
          padding: 10,
          maxTicksLimit: 4,
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: '#ffffff',
    mode: 'x',
    titleFontColor: '#000000',
    bodyFontColor: '#000000',
    displayColors: false,
    borderColor: '#CCC',
    borderWidth: 1,
    position: 'nearest',
  },
  legend: {
    display: false,
  },
  elements: {
    point: { radius: 3 },
  },
};

const parseGraphData = ratesData => {
  const lines = Object.values(ratesData).map(Number);

  const datasets = [
    {
      lineTension: 0.3,
      data: lines,
      fill: false,
      backgroundColor: '#32baea',
      borderColor: '#32baea',
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
    },
  ];

  return {
    labels: Object.keys(ratesData).map(formatDate),
    datasets,
  };
};

const RatesGraph = () => {
  const {
    dateInterval,
    setDateInterval,
    ratesPreview: { rates, startDate, endDate },
  } = useContext(RatesPreviewContext);

  const handleDateInterval = selectedInterval => setDateInterval(selectedInterval);

  const clearFilters = () => setDateInterval({ start: null, end: null });

  return (
    <S.FullWidth>
      <S.CardFullWidth>
        <S.GraphHeader>
          <S.GraphTitle>Prévia das taxas</S.GraphTitle>
          <S.Column>
            {endDate && (
              <Text>
                Período - {formatDate(startDate)} - {formatDate(endDate)}
              </Text>
            )}
            <S.GraphFilter>
              <Text>Filtrar:</Text>{' '}
              <DateFieldFilter
                label="Período"
                selectedItems={[dateInterval.start, dateInterval.end]}
                onFilter={handleDateInterval}
                onClear={clearFilters}
              />
            </S.GraphFilter>
          </S.Column>
        </S.GraphHeader>
        <Line options={graphOptions} height={70} data={parseGraphData(rates)} />
      </S.CardFullWidth>
    </S.FullWidth>
  );
};

export default RatesGraph;
