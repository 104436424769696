import styled from 'styled-components';
import { Drawer, Link } from 'liber-components';

export const SimulationTitle = styled.span`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #4b6f85;
`;

export const StyledDrawer = styled(Drawer)`
  pointer-events: all;
`;

export const SelectedNumber = styled(SimulationTitle)`
  font-weight: normal;
`;

export const ContentContainer = styled.div`
  padding: 16px;
`;

export const DrawerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #798e9b;
  padding: 0px;
  :hover {
    color: #4b6f85;
    & > svg {
      fill: #4b6f85;
    }
  }
  & > svg {
    fill: #798e9b;
  }
`;
