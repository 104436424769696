import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import currencyFormatter from 'currency-formatter';
import {
  DropDown,
  DropDownOption as Option,
  AreaChart,
  getScreenSize,
  toast,
  TooltipBox,
  handleThemeFromObject,
} from 'liber-components';
import {
  Cell,
  LoadingBlock,
  TooltipContent,
  TooltipTitle,
  TooltipValue,
  InfoIcon,
  InfoBox,
  InfoIconWrap,
  FlexRow,
} from '../../styles';
import {
  Title,
  Row,
  Column,
  SimpleDisplay,
  Color,
  CardContent,
  EmptyChart,
  EmptyIcon,
  EmptyMainText,
  EmptySecondaryText,
  Capitalize,
} from '../styles';

const moment = extendMoment(Moment);

class AccumulatedYield extends Component {
  constructor() {
    super();
    const today = moment().startOf('day');
    this.state = {
      period: 'last-6-months',
      screenSize: getScreenSize(window),
      chartWidth: 0,
      first: moment(today).subtract(6, 'months').startOf('month').format('YYYY-MM-DD'),
      last: today.format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    const { getYieldData, token } = this.props;
    const { first, last } = this.state;
    this.setScreeSize();
    window.addEventListener('resize', this.setScreeSize);
    getYieldData(token, first, last);
  }

  componentWillReceiveProps(nextProps) {
    const { status, error } = nextProps;
    if (!status) {
      toast(error ? error.message : 'Ocorreu um erro!', 'error', 8000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreeSize);
  }

  setScreeSize = () => {
    this.setState({
      screenSize: getScreenSize(window),
      chartWidth: this.content ? this.content.offsetWidth - 46 : 0,
    });
  };

  getParsedDateDomain = (first, last, data) => {
    const f = (() => {
      const dataFirst = data.length > 0 ? moment(data[0].date, 'DD/MM/YYYY') : null;

      if (first) return moment(first);
      if (dataFirst) return moment(dataFirst);
      return null;
    })();
    let diff;
    const l = (() => {
      const dataLast =
        data.length > 0 ? moment(data[data.length - 1].date, 'DD/MM/YYYY') : moment();

      diff = moment(last || moment(dataLast)).diff(moment(first), 'days');
      if (diff < 7 && f) {
        return moment(first).add(6, 'days');
      }
      return moment(last || moment(dataLast));
    })();
    return {
      first: f,
      last: l,
      diff,
    };
  };

  getXDomain = data => {
    const { first, last } = this.getParsedDateDomain(this.state.first, this.state.last, data);
    return {
      scale: 'time',
      timeSeparator: null,
      past: true,
      first,
      last,
      offset: 1,
      skip: 0,
      count: 0,
      padding: [0, 15],
      offsetBy: 'month',
      currMarker: 'day',
    };
  };

  getYDomain = (data, total) => {
    const max = total === 0 ? 100 : total;
    const maxOrderOfMagnitude = Math.floor(Math.log(max) / Math.LN10 + 0.000000001);
    return {
      format: num =>
        num > 999 ? `R$ ${(num / 1000).toFixed(num % 1000 !== 0 ? 1 : 0)}k` : `R$ ${num}`,
      first: 0,
      last: Math.ceil(max / 10 ** maxOrderOfMagnitude) * 10 ** maxOrderOfMagnitude,
      count: 3,
    };
  };

  handlePeriodSelection = period => {
    const { getYieldData, token } = this.props;
    const today = moment().startOf('day');
    let definedPeriod = period;
    let first;
    let last;
    switch (definedPeriod) {
      case 'since-beginning':
        first = null;
        last = today.format('YYYY-MM-DD');
        break;
      case 'last-12-months':
        first = moment(today).subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
        last = today.format('YYYY-MM-DD');
        break;
      case 'last-6-months':
      default:
        first = moment(today).subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
        last = today.format('YYYY-MM-DD');
        definedPeriod = 'last-6-months';
        break;
    }
    this.setState({ period: definedPeriod, first, last }, () => {
      getYieldData(token, first, last);
    });
  };

  renderTooltip = accumulated => (
    <TooltipContent width="224px">
      <Row style={{ justifyContent: 'center' }}>
        <TooltipTitle>Rendimento acumulado:</TooltipTitle>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <TooltipValue>
          {currencyFormatter.format(accumulated, { code: 'BRL', format: '%s %v' })}
        </TooltipValue>
      </Row>
    </TooltipContent>
  );

  formatDataForChart = (calendar, prev, first, last) => {
    let accumulated = prev;
    const totalRange = moment.range(first, last);
    let calendarCount = 0;

    const rangeArray = Array.from(totalRange.by('days')).map(date => {
      const calendarData = calendar[calendarCount];
      if (calendarData && date.isSame(moment(calendarData.date, 'DD/MM/YYYY'))) {
        const { amount } = calendar[calendarCount];
        calendarCount += 1;
        accumulated += amount;
        return {
          x: date,
          y: accumulated,
          tooltipContent: this.renderTooltip(accumulated),
        };
      }
      return {
        x: date,
        y: accumulated,
        tooltipContent: this.renderTooltip(accumulated),
      };
    });
    return rangeArray;
  };

  renderEmptyState = loading => (
    <EmptyChart loading={loading} type="area">
      {!loading ? (
        <>
          <div>
            <EmptyIcon />
          </div>
          <Column>
            <EmptyMainText>Nenhum rendimento encontrado</EmptyMainText>
            <EmptySecondaryText>
              Não pudemos calcular o seu rendimento acumulado pois não houveram liquidações no
              período selecionado.
            </EmptySecondaryText>
          </Column>
        </>
      ) : null}
    </EmptyChart>
  );

  renderCard = (calendar, accumulated, loading) => {
    const {
      // screenSize,
      screenSize,
      chartWidth,
    } = this.state;
    const { theme } = this.props;
    const { first, last } = this.getParsedDateDomain(this.state.first, this.state.last, calendar);
    const accumulatedTotal = calendar.reduce((prev, next) => prev + next.amount, accumulated);
    return (
      <>
        <Row>
          <Cell>
            <Column>
              <Title>
                <Capitalize>
                  {first ? first.locale('pt-br').format('MMMM/YYYY') : 'Até '}
                </Capitalize>
                {first ? <>&nbsp;a&nbsp;</> : null}
                <Capitalize>{last.locale('pt-br').format('MMMM/YYYY')}</Capitalize>
              </Title>
              <DropDown defaultValue={this.state.period} onSelect={this.handlePeriodSelection}>
                <Option value="last-6-months">Últimos 6 meses</Option>
                <Option value="last-12-months">Últimos 12 meses</Option>
                <Option value="since-beginning">Desde o Início</Option>
              </DropDown>
            </Column>
          </Cell>
          {screenSize === 'large' || screenSize === 'extraLarge' ? (
            <Cell>
              <SimpleDisplay
                style={{ maxWidth: 'unset', float: 'right' }}
                label="Rendimento Acumulado Total"
                prefix="R$"
              >
                <Color
                  color={
                    accumulatedTotal > 0
                      ? handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2DE8BF')
                      : handleThemeFromObject(theme, 'colors.darkGrey', '#717171')
                  }
                >
                  <FlexRow>
                    {currencyFormatter.format(accumulatedTotal, {
                      code: 'BRL',
                      symbol: '',
                      format: '%v',
                    })}
                    <TooltipBox
                      key="a"
                      mount="top"
                      fixed
                      content={
                        <InfoBox>Valor acumulado do rendimento dos títulos já liquidados.</InfoBox>
                      }
                    >
                      <InfoIconWrap marginLeft={8}>
                        <InfoIcon />
                      </InfoIconWrap>
                    </TooltipBox>
                  </FlexRow>
                </Color>
              </SimpleDisplay>
            </Cell>
          ) : null}
        </Row>
        <Row>
          {!loading && calendar.length !== 0 && accumulatedTotal !== 0 ? (
            <AreaChart
              data={this.formatDataForChart(calendar, accumulated, first, last)}
              width={chartWidth}
              xDomain={this.getXDomain(calendar)}
              yDomain={this.getYDomain(calendar, accumulatedTotal)}
              padding={{
                top: 50,
                bottom: 60,
                left: 88,
                right: screenSize === 'small' || screenSize === 'medium' ? 10 : 50,
              }}
              hoverEvents={screenSize !== 'small' && screenSize !== 'medium'}
            />
          ) : (
            this.renderEmptyState(loading)
          )}
        </Row>
        {screenSize === 'small' || screenSize === 'medium' ? (
          <Row>
            <Cell>
              <SimpleDisplay label="Rendimento Acumulado Total" prefix="R$">
                <Color
                  color={
                    accumulatedTotal > 0
                      ? handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2DE8BF')
                      : handleThemeFromObject(theme, 'colors.darkGrey', '#717171')
                  }
                >
                  {currencyFormatter.format(accumulatedTotal, {
                    code: 'BRL',
                    symbol: '',
                    format: '%v',
                  })}
                </Color>
              </SimpleDisplay>
            </Cell>
          </Row>
        ) : null}
      </>
    );
  };

  render() {
    const { loading, data } = this.props;
    const { calendar, accumulated } = data;
    return (
      <>
        <LoadingBlock loading={loading} opacity="0.5">
          <CardContent
            ref={element => {
              this.content = element;
            }}
          >
            {this.renderCard(calendar, accumulated, loading)}
          </CardContent>
        </LoadingBlock>
      </>
    );
  }
}

AccumulatedYield.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    accumulated: PropTypes.number,
    calendar: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  getYieldData: PropTypes.func.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

AccumulatedYield.defaultProps = {
  theme: {},
};

export default AccumulatedYield;
