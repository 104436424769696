import axios from 'axios';

const apiUrl = SERVICE_API_URL; // eslint-disable-line

export async function fetchChangeStatusOnBuyer(token, vendorId, body) {
  return axios.post(`${apiUrl}/api/v1/buyer/vendors/${vendorId}/change_status`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function resendEmail(token, vendorId) {
  return axios.post(
    `${apiUrl}/api/v1/buyer/vendors/${vendorId}/resend_email_approval`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
