import React from 'react';
import PropTypes from 'prop-types';
import { InfoLabel, TextField } from 'liber-components';

import { Container, Helper, Form } from './ChangePassword.styles';

import { HeaderRow, SubmitButton, ButtonsContainer, Row } from '../Base.styles';

const ChangePassword = ({
  email,
  currentPassword,
  newPassword,
  errors,
  passwordConfirmation,
  changeCurrentPassword,
  changeNewPassword,
  changePasswordConfirmation,
  submitForm,
  loading,
}) => (
  <>
    <Container>
      <HeaderRow>
        <h1>Senha</h1>
      </HeaderRow>
      <Row>
        <InfoLabel color="#717171">
          <Helper>
            <span>
              Para alterar sua senha, informe sua senha atual e a nova senha que deseja cadastrar.
            </span>
            Assim que clicar em “Confirmar”, enviaremos um email de confirmação para&nbsp;
            <strong style={{ color: '#717171' }}>{email}</strong>
          </Helper>
        </InfoLabel>
      </Row>
      <Row>
        <Form>
          <TextField
            fullWidth
            id="investidor[current_password]"
            name="investidor[current_password]"
            label="Senha Atual"
            type="password"
            value={currentPassword}
            onChange={changeCurrentPassword}
            errorMessage={errors.currentPassword}
          />
          <TextField
            fullWidth
            id="investidor[password]"
            name="investidor[password]"
            label="Nova Senha"
            type="password"
            value={newPassword}
            onChange={changeNewPassword}
            errorMessage={errors.newPassword}
          />
          <TextField
            fullWidth
            id="investidor[password_confirmation]"
            name="investidor[password_confirmation]"
            label="Confirmar Nova Senha"
            type="password"
            value={passwordConfirmation}
            onChange={changePasswordConfirmation}
            errorMessage={errors.passwordConfirmation}
          />
        </Form>
      </Row>
      <ButtonsContainer>
        <SubmitButton
          loading={loading}
          onClick={() => submitForm(currentPassword, newPassword, passwordConfirmation)}
        >
          Alterar Senha
        </SubmitButton>
      </ButtonsContainer>
    </Container>
  </>
);

ChangePassword.propTypes = {
  email: PropTypes.string.isRequired,
  currentPassword: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  changeCurrentPassword: PropTypes.func.isRequired,
  changeNewPassword: PropTypes.func.isRequired,
  changePasswordConfirmation: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ChangePassword;
