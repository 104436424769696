import styled from 'styled-components';
import { LoadingBunny, PrimaryButton } from 'liber-components';

export const LoadingContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
`;

export const DropDownContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropDownContentRow = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0px;
  color: inherit;
  cursor: pointer;

  & > svg {
    fill: #6287a7;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
  :hover {
    color: #009dff;
    & > svg {
      fill: #009dff;
    }
  }
`;

export const ActionButton = styled(PrimaryButton).attrs(() => ({
  version: 2,
  outlined: true,
}))`
  text-transform: uppercase;
`;

export const ConfirmationContent = styled.div`
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: #6287a7;
  width: 408px;
`;

export const Sim = styled.div`
  background-color: #008000;
  width: 30px;
  height: 30px;
  border-radius: 20px;
`;

export const Nao = styled.div`
  background-color: #ff0000;
  width: 30px;
  height: 30px;
  border-radius: 20px;
`;
