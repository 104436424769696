import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import VendorsGroupHistory from './components/VendorsGroupHistory';
import withTokenProvider from '../../hocs/withTokenProvider';
import { VendorsGroupHistoryStore } from './context';

function View({ groupId, name, referenceCode, isDefault }) {
  return (
    <ThemeProvider theme={Theme}>
      <VendorsGroupHistoryStore
        groupId={groupId}
        name={name}
        referenceCode={referenceCode}
        isDefault={isDefault}
      >
        <VendorsGroupHistory />
      </VendorsGroupHistoryStore>
      <ToastContainer />
    </ThemeProvider>
  );
}

View.propTypes = {
  groupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  referenceCode: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

export default withTokenProvider(View, 'buyer_jwt');
