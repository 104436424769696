import React from 'react';
import { WhiteTag, SuccessTag } from 'liber-components';
import { AlignRightNoWrap } from './components/styles';

export const STATUS_EQ = 'q[sacado_kind_payment_in]';
export const STATUS_TYPE_LABEL = 'Status';

export const STATUS_TYPE_MAP = {
  0: 'Pendente',
  1: 'Baixada',
};

export const getCleanUrl = () => new URL(window.location.toString().split('?')[0]);

export const COLUMNS = [
  {
    width: 20,
    name: 'number',
    label: 'Número',
    // eslint-disable-next-line react/prop-types
    getCellValue: ({ number }) => <AlignRightNoWrap>{number}</AlignRightNoWrap>,
  },
  {
    width: 25,
    name: 'value',
    label: 'Valor',
    align: 'right',
  },
  {
    width: 20,
    name: 'vendor',
    label: 'Fornecedor',
    align: 'center',
  },
  {
    width: 20,
    name: 'buyer',
    label: 'Sacado',
    align: 'left',
  },
  {
    width: 20,
    name: 'tradedAt',
    label: 'Negociado em',
    align: 'right',
  },
  {
    width: 20,
    name: 'expiresAt',
    label: 'Vencimento	',
    align: 'center',
  },
  {
    width: 20,
    name: 'downloaded',
    label: 'Remessa baixada',
    align: 'center',
    // eslint-disable-next-line react/prop-types
    getCellValue: ({ downloaded }) =>
      downloaded === 'Sim' ? <SuccessTag>Sim</SuccessTag> : <WhiteTag>Não</WhiteTag>,
  },
];
export const REMITTANCE_STATUS = 'q[remessa_status_in]';
export const CANCELEDATLTED = 'q[canceled_at_lteq]';
export const FILTERS = [
  {
    type: 'text',
    filterKey: 'q[duplicata_number_cont]',
    label: 'Número do título:',
  },
  {
    type: 'text',
    filterKey: 'q[fornecedor_name_or_fornecedor_company_or_fornecedor_trade_name_cont]',
    label: 'Fornecedor:',
  },

  {
    type: 'text',
    filterKey: 'q[sacado_name_or_sacado_company_or_sacado_trade_name_cont]',
    label: 'Sacado:',
  },

  {
    type: 'date',
    filterKey: 'duplicata_expires_at_gteq',
    filterKeys: ['q[duplicata_expires_at_gteq]', 'q[duplicata_expires_at_lteq]'],
    label: 'Vencimento:',
    position: 'bottom-left-down-right',
  },
  {
    type: 'date',
    filterKey: 'finished_at_gteq',
    filterKeys: ['q[finished_at_gteq]', 'q[finished_at_lteq]'],
    label: 'Negociados em:',
    position: 'bottom-left-down-right',
  },
  {
    type: 'select',
    filterKey: 'q[remessa_status_in]',
    label: 'Status',
    options: [
      { name: 'Pendente', value: '0' },
      { name: 'Baixada', value: '1' },
    ],
  },
];

export const getLabelByFilterKey = key => {
  let desiredLabel = 'Filtro';
  FILTERS.forEach(filter => {
    const { filterKey, filterKeys, label } = filter;
    if ((filterKeys && filterKeys.includes(key)) || filterKey === key) {
      desiredLabel = label;
    }
  });
  return desiredLabel;
};

export const typesRemittance = [
  {
    id: '0',
    value: 'Cobrança',
  },
  {
    id: '1',
    value: 'Pagamento',
  },
  {
    id: '0,1',
    value: 'Todas',
  },
];

// eslint-disable-next-line no-undef
export const showRemittanceKindFilter = () => SHOW_REMITTANCE_KIND_FILTER === 'true';
