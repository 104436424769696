export const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const NEW_VENDOR_PATH = '/sacado/fornecedores/new';
export const IMPORT_VENDORS_PATH = '/sacado/fornecedores/batch_upload';
export const VENDORS_GROUP_PATH = '/sacado/fornecedores/grupos';
export const UPLOAD_URL = `${apiUrl}/api/v1/sacado/vendor_anticipation_limits`;
export const VENDORS_LIST_URL = `${apiUrl}/api/v1/buyer/vendors`;
export const REMOVE_LIMIT_URL = `${apiUrl}/api/v1/sacado/vendor_anticipation_limits`;
export const EXPORT_VENDOR_LIST_URL = `${apiUrl}/api/v1/sacado/vendor_anticipation_limits/vendor_list_export`;
export const USER_INFO_URL = `${apiUrl}/api/v1/buyer/user`;
export const UPDATE_STATUS_URL = `${apiUrl}/api/v1/buyer/vendors/:vendor_id/change_status`;
