/* eslint-disable no-unused-vars */
import React from 'react';

import { LiberV4, ToastContainer } from 'liber-components';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { BuyerOrganizationGroupsProvider } from './context';
import Table from './components/Table';
import ListGroups from './components/ListGroups';

const ListBuyerOrganizationGroups = ({ token }) => (
  <ThemeProvider theme={LiberV4}>
    <BuyerOrganizationGroupsProvider token={token}>
      <ToastContainer />
      <ListGroups />
    </BuyerOrganizationGroupsProvider>
  </ThemeProvider>
);

ListBuyerOrganizationGroups.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ListBuyerOrganizationGroups;
