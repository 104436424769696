import styled, { css } from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: grey;
  border-radius: 50%;
  padding: 4px;
  font-size: 8px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: wait;
    `}
`;

export const ModalContainer = styled.div`
  width: 700px;
  padding: 0px 20px;
`;

export const ModalTitle = styled.h1`
  display: block;
  font-size: 1.1rem;
  color: #009dff;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  margin: 15px;
`;

export const Info = styled.div`
  display: flex;
  width: 100%;
  align-content: space-around;
  margin-bottom: 25px;
  border-bottom: 1px solid #cdd7df;
  border-top: 1px solid #cdd7df;
`;

export const InfoItem = styled.div`
  margin: 10px;
  flex: 1;
`;

export const InfoItemTitle = styled.h2`
  display: block;
  font-size: 0.8rem;
  color: #6287a7;
  font-weight: bolder;
`;

export const InfoItemContent = styled.div`
  color: #6287a7;
`;

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterButton = styled(PrimaryButton).attrs(() => ({
  version: 2,
}))`
  margin: 10px 0px 10px 10px;
`;
