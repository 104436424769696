import styled, { createGlobalStyle } from 'styled-components';

import MarcaLiber from '../../../../assets/images/salti_logo.svg';

export const Container = styled.div`
  min-height: 100vh;
  padding: 40px;
`;

export const Content = styled.div`
  margin: 0 auto 48px auto;
  max-width: 424px;
`;

export const CardContent = styled.div`
  padding: 56px 24px;
`;

export const Logo = styled.img.attrs({ alt: 'liber logo', src: MarcaLiber })`
  width: 98px;
`;

export const LogoWrapper = styled.div`
  margin: 24px auto 48px auto;
  width: fit-content;
`;

export const ActionsWrapper = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 32px;
`;

export const InputsWrapper = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 16px;
`;

export const HiddenForm = styled.form`
  display: none;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 20px;
`;

export const AlertWrapper = styled.div`
  margin-top: 16px;
`;

export const SelectStyle = createGlobalStyle`
  .MuiSelect-outlined:first-of-type {
    margin: ${({ isMobile }) => (isMobile ? '-12px 0 -12px -8px' : '-8px 0 -8px -8px')};
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 44px 0 16px 0;

  p {
    margin-top: 4px;
  }
`;
