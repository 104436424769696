import Immutable from 'seamless-immutable';
import errorMessages from './errorMessages';

const initialState = Immutable({
  loading: true,
  status: true,
  error: {
    code: null,
    message: '',
  },
  data: {
    total: {
      yield: 0,
      invoice_count: 0,
      to_receive: 0,
    },
    calendar: [],
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INVESTOR_DASHBOARD_GET_CALENDAR_DATA_FULFILLED':
      return state.merge(
        {
          loading: false,
          // data: action.payload.data.data ? action.payload.data.data : [],
          data: {
            calendar: action.payload.data.data ? action.payload.data.data.calendar : [],
            total: {
              yield: action.payload.data.data ? action.payload.data.data.total.yield : 0,
              invoice_count: action.payload.data.data
                ? action.payload.data.data.total.invoice_count
                : 0,
              to_receive: action.payload.data.data ? action.payload.data.data.total.to_receive : 0,
            },
          },
          status: action.payload.data.status,
          error: {
            message: action.payload.data.error ? action.payload.data.error.message : '',
            code: action.payload.data.error ? action.payload.data.error.code : null,
          },
        },
        { deep: true },
      );
    case 'INVESTOR_DASHBOARD_GET_CALENDAR_DATA_PENDING':
      return state.merge({ loading: true }, { deep: true });
    case 'INVESTOR_DASHBOARD_GET_CALENDAR_DATA_REJECTED':
      if (
        action.payload.response &&
        (typeof action.payload.response.data === 'string' ||
          action.payload.response.data instanceof String)
      ) {
        return state.merge(
          {
            error: action.payload.response.data.error,
            status: false,
          },
          { deep: true },
        );
      }
      return state.merge(
        {
          error: {
            message: errorMessages[action.payload.response.status] || action.payload.message,
          },
          status: false,
        },
        { deep: true },
      );
    default:
      return state;
  }
};
