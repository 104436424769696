import styled from 'styled-components';

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 50px;
`;
