import React from 'react';
import PropTypes from 'prop-types';
import { Liber } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import VendorInterest from './components/VendorInterest/index.jsx';

const View = ({ token, vendor }) => (
  <>
    <ThemeProvider theme={Liber}>
      <VendorInterest token={token} vendor={vendor} />
    </ThemeProvider>
  </>
);

View.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default View;
