import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import _ from 'lodash';
import moment from 'moment';
import {
  EntryLine,
  Date,
  Mark,
  WalletIcon,
  MoneyIcon,
  InvoiceBox,
  InvoiceDetails,
  PriceLabel,
  FeeLabel,
  ReceiptLabel,
  InvoiceRow as Row,
  BalanceLine,
  DraweeLabel,
  DraweeList,
  DraweeBox,
  EntryBackground,
} from './StatementEntry-style';
import InvoiceAccordion from './InvoiceAccordion';
import SummaryAccordion from './SummaryAccordion';
import SummaryEntry from './SummaryEntry';

const round = (value, decimals = 2) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

const formatedDate = (value, format) => {
  if (moment.isMoment(value)) {
    return value.format(format);
  }
  if (value === null || value === '') {
    return '-';
  }
  return moment(value).format(format);
};

const StatementEntry = ({ type, entry, position }) => {
  switch (type) {
    case 'day-balance':
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark type="grey" size="small" balance />
          <BalanceLine
            amount={currencyFormatter.format(round(entry.amount), { code: 'BRL', format: '%s%v' })}
          />
        </EntryLine>
      );
    case 'summary-opening-balance':
      return (
        <EntryLine>
          <EntryBackground />
          <Date isSummary>{formatedDate(entry.date, 'DD/MM')}</Date>
          <Mark type="grey" start summary size="large" />
          <InvoiceAccordion label="Saldo Inicial" value={round(entry.amount)} separator={false} />
        </EntryLine>
      );
    case 'summary-closing-balance':
      return (
        <EntryLine>
          <EntryBackground />
          <Date isSummary>{formatedDate(entry.date, 'DD/MM')}</Date>
          <Mark type="grey" end summary size="large" />
          <InvoiceAccordion label="Saldo Final" value={round(entry.amount)} separator={false} />
        </EntryLine>
      );
    case 'summary-credited':
      return (
        <EntryLine>
          <SummaryAccordion plus label="Total Creditado" value={round(entry.amount)}>
            {entry.subtotals.map(subtotal => (
              <SummaryEntry
                entry={subtotal}
                type="credit"
                key={`${subtotal.name}_${subtotal.amount}`}
              />
            ))}
          </SummaryAccordion>
        </EntryLine>
      );
    case 'summary-debited':
      return (
        <EntryLine>
          <SummaryAccordion minus label="Total Debitado" value={round(entry.amount)}>
            {entry.subtotals.map(subtotal => (
              <SummaryEntry
                entry={subtotal}
                type="debit"
                key={`${subtotal.name}_${subtotal.amount}`}
              />
            ))}
          </SummaryAccordion>
        </EntryLine>
      );
    case 'opening-balance':
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{formatedDate(entry.date, 'DD/MM')}</Date>
          <Mark type="grey" start={position === 'start'} end={position === 'end'} size="medium" />
          <InvoiceAccordion label="Saldo Inicial" value={round(entry.amount)} />
        </EntryLine>
      );
    case 'closing-balance':
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{formatedDate(entry.date, 'DD/MM')}</Date>
          <Mark type="grey" start={position === 'start'} end={position === 'end'} size="medium" />
          <InvoiceAccordion label="Saldo Final" value={round(entry.amount)} separator={false} />
        </EntryLine>
      );
    case 'deposit':
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark type="blue">
            <MoneyIcon />
          </Mark>
          <InvoiceAccordion label={entry.name} value={round(entry.amount)} plus />
        </EntryLine>
      );
    case 'purchase':
      return (
        <EntryLine>
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark type="red" dotted>
            <WalletIcon />
          </Mark>
          <InvoiceAccordion
            label={entry.name}
            value={round(entry.amount)}
            minus
            count={Object.keys(entry.invoices).reduce(
              (curr, next) => curr + entry.invoices[next].length,
              0,
            )}
          >
            {Object.keys(entry.invoices).map(drawee => {
              const invoices = entry.invoices[drawee];
              return (
                <DraweeBox key={_.uniqueId(`${entry.date}-${drawee}`)}>
                  <DraweeLabel>{drawee}</DraweeLabel>
                  <DraweeList>
                    {invoices.map(invoice => (
                      <InvoiceBox
                        key={invoice.number}
                        label={`Título ${invoice.number}`}
                        value={currencyFormatter.format(invoice.amount, {
                          code: 'BRL',
                          format: '%s%v',
                        })}
                        content={
                          <InvoiceDetails>
                            <Row>
                              <PriceLabel>Valor Investido</PriceLabel>
                              {currencyFormatter.format(round(invoice.price), {
                                code: 'BRL',
                                format: '%s%v',
                              })}
                            </Row>
                            <Row>
                              <ReceiptLabel>Receb. Líquido</ReceiptLabel>
                              {currencyFormatter.format(round(invoice.amount - invoice.fee), {
                                code: 'BRL',
                                format: '%s%v',
                              })}
                            </Row>
                            <Row>
                              <FeeLabel>Emolumentos</FeeLabel>
                              {currencyFormatter.format(round(invoice.fee), {
                                code: 'BRL',
                                format: '%s%v',
                              })}
                            </Row>
                          </InvoiceDetails>
                        }
                      />
                    ))}
                  </DraweeList>
                </DraweeBox>
              );
            })}
          </InvoiceAccordion>
        </EntryLine>
      );
    case 'withdraw':
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark type="red">
            <MoneyIcon />
          </Mark>
          <InvoiceAccordion label={entry.name} value={round(entry.amount)} minus />
        </EntryLine>
      );
    case 'fee':
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark type="red" size="small">
            %
          </Mark>
          <InvoiceAccordion label={entry.name} value={round(entry.amount)} minus />
        </EntryLine>
      );
    case 'receipt':
      return (
        <EntryLine>
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark type="blue" dotted={!!entry.invoices}>
            <WalletIcon />
          </Mark>
          <InvoiceAccordion
            label={entry.name}
            value={round(entry.amount)}
            plus
            count={
              entry.invoices
                ? Object.keys(entry.invoices).reduce(
                    (curr, next) => curr + entry.invoices[next].length,
                    0,
                  )
                : 0
            }
          >
            {entry.invoices
              ? Object.keys(entry.invoices).map(drawee => {
                  const invoices = entry.invoices[drawee];
                  return (
                    <DraweeBox key={_.uniqueId(`${entry.date}-${drawee}`)}>
                      <DraweeLabel>{drawee}</DraweeLabel>
                      <DraweeList>
                        {invoices.map(invoice => (
                          <InvoiceBox
                            key={invoice.number}
                            label={`Título ${invoice.number}`}
                            value={currencyFormatter.format(round(invoice.amount), {
                              code: 'BRL',
                              format: '%s%v',
                            })}
                            content={
                              <InvoiceDetails>
                                <Row>
                                  <PriceLabel>Valor Investido</PriceLabel>
                                  {currencyFormatter.format(round(invoice.price), {
                                    code: 'BRL',
                                    format: '%s%v',
                                  })}
                                </Row>
                                <Row>
                                  <ReceiptLabel>Receb. Líquido</ReceiptLabel>
                                  {currencyFormatter.format(round(invoice.amount - invoice.fee), {
                                    code: 'BRL',
                                    format: '%s%v',
                                  })}
                                </Row>
                                <Row>
                                  <FeeLabel>Emolumentos</FeeLabel>
                                  {currencyFormatter.format(round(invoice.fee), {
                                    code: 'BRL',
                                    format: '%s%v',
                                  })}
                                </Row>
                              </InvoiceDetails>
                            }
                          />
                        ))}
                      </DraweeList>
                    </DraweeBox>
                  );
                })
              : null}
          </InvoiceAccordion>
        </EntryLine>
      );
    default:
      return (
        <EntryLine>
          <EntryBackground />
          <Date>{entry.showDate ? entry.date : null}</Date>
          <Mark
            type={(() => {
              if (entry.type === 'debit') return 'red';
              if (entry.type === 'credit') return 'blue';
              return 'grey';
            })()}
            size="extraLarge"
          />
          <InvoiceAccordion
            label={entry.name}
            value={round(entry.amount)}
            minus={entry.type === 'debit'}
            plus={entry.type === 'credit'}
          />
        </EntryLine>
      );
  }
};

StatementEntry.propTypes = {
  type: PropTypes.string,
  entry: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(PropTypes.any)]),
  position: PropTypes.string,
};

StatementEntry.defaultProps = {
  type: '',
  entry: {},
  position: '',
};

export default StatementEntry;
