/* eslint-disable import/no-cycle */
import axios from 'axios';
import { setData as setDadosPessoais } from './dadosPessoais';
import { setData as setDocumentacao } from './documentacao';
import { setData as setEnderecos } from './enderecos';
import { setData as setDadosBancarios } from './dadosBancarios';
import { setData as setContratos } from './contratos';
import { setCurrentString, setCompletedString } from './progressBar';
import { steps } from '../reducers/progressBar';

export const smoothScroll = (x = 0, y = 0) => {
  window.scroll({
    top: x,
    left: y,
    behavior: 'smooth',
  });
};

export const redirectTo = path => {
  window.location = path;
  return { type: 'WIZARD_INVESTIDOR/REDIRECT_TO', payload: path };
};

export const setWindowHash = step => {
  window.location.hash = Object.keys(steps)[step - 1];
  return { type: 'WIZARD_INVESTIDOR/SET_WINDOW_HASH', payload: Object.keys(steps)[step] };
};

export const openInNewTab = path => {
  window.open(path, '_blank');
  return { type: 'WIZARD_INVESTIDOR/OPEN_IN_NEW_TAB', payload: path };
};

export const formatErrorFromBackend = error => {
  if (!error.response) return {};
  if (!error.response.data) return {};

  const errorsFromResponse = error.response.data.error ? error.response.data.error.reason : {};
  Object.keys(errorsFromResponse).map(index => {
    errorsFromResponse[index] = errorsFromResponse[index].toString();
    return index;
  });
  return errorsFromResponse;
};

export const handleSuccess =
  (response = { data: { data: { steps: {} } } }) =>
  dispatch => {
    dispatch(setCurrentString(response.data.data.current_step));
    dispatch(setCompletedString(response.data.data.current_step));
    dispatch(setDadosPessoais(response.data.data.steps.dados_pessoais));
    dispatch(setDocumentacao(response.data.data.steps.documentacao));
    dispatch(setEnderecos(response.data.data.steps.enderecos));
    dispatch(setDadosBancarios(response.data.data.steps.conta_bancarias));
    dispatch(setContratos(response.data.data.steps.contratos));
  };

export const fetchData = () => dispatch =>
  axios({
    method: 'GET',
    url: '/investidor/cadastro.json',
  }).then(response => dispatch(handleSuccess(response)));
