import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import * as S from './styles';
import Table from './components/Table';
import DetailsDrawerProvider from './context';
import SpreadsheetDetails from './components/SpreadsheetDetails';

const DetailsDrawer = ({ onLeave, show, token, spreadsheetId }) => (
  <S.Drawer show={show} onLeaved={onLeave}>
    <DetailsDrawerProvider token={token} spreadsheetId={spreadsheetId}>
      <S.Header>
        <S.Title>Detalhes do Envio</S.Title>
        <S.CloseButton onClick={onLeave}>
          <Icon path={mdiClose} size={2} />
        </S.CloseButton>
      </S.Header>
      <S.Content>
        <SpreadsheetDetails />
        <Table />
      </S.Content>
      <S.Footer>
        <S.Button onClick={onLeave}>Fechar</S.Button>
      </S.Footer>
    </DetailsDrawerProvider>
  </S.Drawer>
);

DetailsDrawer.propTypes = {
  onLeave: PropTypes.func,
  show: PropTypes.bool,
  token: PropTypes.string.isRequired,
  spreadsheetId: PropTypes.string.isRequired,
};

DetailsDrawer.defaultProps = {
  onLeave: () => null,
  show: false,
};

export default DetailsDrawer;
