import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Header4, Row } from 'liber-components/components/GenericStyles';
import { RadioButton, SelectField, handleThemeFromObject } from 'liber-components';
import VMasker from 'vanilla-masker';
import { InfoMessage, InfoIcon, InfoList, ExclamationIcon, SucessIcon, Column } from '../styles';
import { checkIsEcnpj } from '../../actions/MultiSignFornecedorViewActions';

class DocumentSignForm extends Component {
  componentDidMount() {
    const { partners } = this.props;
    this.selectFirst(partners);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPartner < 0) {
      this.selectFirst(nextProps.partners);
    }
  }

  certificateOptionText = certificate => {
    const cnpjMask = '99.999.999/9999-99';
    const cpfMask = '999.999.999-99';
    const { name } = certificate;
    const expired = certificate.usage && certificate.usage.expired ? '; Expirado' : '';
    const issuer =
      certificate.issuer && certificate.issuer.CN && certificate.issuer.CN !== ''
        ? ` (emitido por ${certificate.issuer.CN})`
        : '';
    let doc;
    switch (certificate.type || '') {
      case 'pf':
        doc = `: ${VMasker.toPattern(certificate.cpf, cpfMask)}`;
        break;
      case 'pj':
        doc = `: ${VMasker.toPattern(certificate.cnpj, cnpjMask)}`;
        break;
      default:
        doc = '';
    }
    return `${name}${doc}${expired}${issuer}`;
  };

  selectFirst(partners) {
    const { setSelectedPartner } = this.props;
    partners.some(partner => {
      if (!partner.signedAll) {
        setSelectedPartner(partner.id);
        return true;
      }
      return false;
    });
  }

  renderecnpjSignatureSignature = () => {
    const {
      jointSignature,
      partners,
      selectedPartner,
      signaturesNeeded,
      setSelectedPartner,
      theme,
    } = this.props;

    const showMarkedMessage = partners.some(partner => partner.showSignatureFlag);
    const filteredPartners = partners.filter(partner => !partner.signedAll);
    const mappedPartners = filteredPartners.map(partner => (
      <RadioButton
        labelColor={handleThemeFromObject(theme, 'colors.liberBlack', '#4e4e4e')}
        id={_.uniqueId(partner.id)}
        key={_.uniqueId(partner.id)}
        name="fornecedor"
        value="Andrea Fornecedor"
        checked={partner.id === selectedPartner}
        onChange={() => {
          setSelectedPartner(partner.id);
        }}
      >
        {partner.name}
      </RadioButton>
    ));

    if (jointSignature) {
      return (
        <>
          <InfoMessage alignItems="flex-start">
            <InfoIcon fill={handleThemeFromObject(theme, 'colors.liberBlue', '#009dff')} />
            <Column>
              <div>
                <b>Assinatura Conjunta</b>: {signaturesNeeded} representantes legais precisam
                assinar.
              </div>
              {showMarkedMessage ? (
                <span>
                  Títulos marcados com <SucessIcon show fixed /> já foram assinados pelos
                  representantes legais selecionado e não serão assinados novamente.
                </span>
              ) : null}
            </Column>
          </InfoMessage>
          <InfoMessage>{mappedPartners}</InfoMessage>
        </>
      );
    }
    return <InfoMessage>{mappedPartners}</InfoMessage>;
  };

  render() {
    const { certificates, ecnpjSignature, selectedCertificate, setSelectedCertificate } =
      this.props;
    const mappedCertificates = certificates.map(certificate => (
      <option
        value={certificate.fingerprint}
        key={_.uniqueId(certificate)}
        disabled={!!(certificate.usage && !certificate.usage.valid && certificate.usage.expired)}
      >
        {this.certificateOptionText(certificate)}
      </option>
    ));
    return (
      <>
        <Header4>Assinar Documentos</Header4>

        {!ecnpjSignature ? this.renderecnpjSignatureSignature() : null}
        <Row>
          <SelectField
            fullWidth
            label="Escolher Certificado"
            labelOpened="Escolha um Certificado"
            name="certificado"
            id="certificado"
            value={selectedCertificate}
            onChange={value =>
              setSelectedCertificate(value, checkIsEcnpj(certificates, value), ecnpjSignature)
            }
          >
            {mappedCertificates}
          </SelectField>
        </Row>
        {certificates.length === 1 && certificates[0].type === 'empty' ? (
          <>
            <InfoMessage style={{ marginBottom: 10 }}>
              <ExclamationIcon />
              Não encontramos nenhum certificado na sua máquina
            </InfoMessage>
            <InfoList>
              Certifique-se de que o cartão está bem inserido ou que o arquivo está instalado em seu
              computador
              <br />
              Se você ainda não possui certificado digital, informe-se em:
              <ul>
                <li>
                  <strong>Certisign:&nbsp;</strong>
                  <a href="https://www.certisign.com.br/certificado-digital/">
                    https://www.certisign.com.br/certificado-digital/
                  </a>
                </li>
                <li>
                  <strong>Serasa:&nbsp;</strong>
                  <a href="https://serasa.certificadodigital.com.br/">
                    https://serasa.certificadodigital.com.br/
                  </a>
                </li>
              </ul>
            </InfoList>
          </>
        ) : null}
      </>
    );
  }
}

DocumentSignForm.propTypes = {
  certificates: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  jointSignature: PropTypes.bool.isRequired,
  selectedPartner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedCertificate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  signaturesNeeded: PropTypes.number.isRequired,
  setSelectedCertificate: PropTypes.func.isRequired,
  ecnpjSignature: PropTypes.bool.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

DocumentSignForm.defaultProps = {
  theme: {},
};

export default DocumentSignForm;
