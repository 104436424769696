import { useCallback, useState } from 'react';
import usePageState from '../../../../../hooks/usePageState';
import { IFILTER_TYPES } from '../interfaces';
import usePagination from '../../../../../hooks/usePagination';

const INITIAL_FILTERS_STATE = {
  [IFILTER_TYPES.GROUP_NAME]: undefined,
  [IFILTER_TYPES.BUYER_NAME]: undefined,
};

export const useBuyerOrganizationGroupFilters = () => {
  const { pagination, updatePagination } = usePagination();
  const [filters, setFilters] = useState(INITIAL_FILTERS_STATE);

  const buildFilters = () => {
    const filtersFormatted = {};

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filtersFormatted[key] = filters[key];
      }
    });

    return filtersFormatted;
  };

  const onChangeFilter = useCallback(
    (filter, value) => {
      setFilters({ ...filters, [filter]: value });
    },
    [filters],
  );

  const clearFilter = filter => {
    setFilters({ ...filters, [filter]: undefined });
  };

  const clearFilters = () => {
    setFilters(INITIAL_FILTERS_STATE);
  };

  const setCurrentPage = page => {
    updatePagination({ current: page });
  };

  const [currentPage, setPageState, onKeyDownPage] = usePageState(
    pagination.current,
    2,
    setCurrentPage,
  );

  return {
    currentPage,
    setPageState,
    setCurrentPage,
    onKeyDownPage,
    pagination,
    updatePagination,
    filters,
    onChangeFilter,
    clearFilter,
    clearFilters,
    buildFilters,
  };
};
