import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import BuyerVendors from './components/BuyerVendors';
import withTokenProvider from '../../hocs/withTokenProvider';
import BuyerVendorProvider from './context';

function View({ limitsAvailable, groupsAvailable }) {
  return (
    <>
      <BuyerVendorProvider>
        <ThemeProvider theme={Theme}>
          <BuyerVendors limitsAvailable={limitsAvailable} groupsAvailable={groupsAvailable} />
          <ToastContainer />
        </ThemeProvider>
      </BuyerVendorProvider>
    </>
  );
}

View.propTypes = {
  limitsAvailable: PropTypes.bool.isRequired,
  groupsAvailable: PropTypes.bool.isRequired,
};

export default withTokenProvider(View, 'buyer_jwt');
