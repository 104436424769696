import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import { getScreenSize } from 'liber-components';
import {
  AccordionContent as Content,
  AccordionContainer as Container,
  AccordionHeader as Header,
  AccordionLabel as Label,
  AccordionValue as Value,
  AccordionInfo as Info,
  InvoiceCounter as Counter,
  TooltipIconButton,
  Chevron,
  PlusIcon,
  MinusIcon,
  EntryBackground,
  ActionOverlay,
} from './StatementEntry-style';

class InvoiceAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      screenSize: getScreenSize(window),
      overlayHover: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  handleButtonClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  render() {
    const { label, value, plus, minus, children, separator, count } = this.props;
    const { open, screenSize, overlayHover } = this.state;
    return (
      <Container separator={separator}>
        <EntryBackground highlight={overlayHover} />
        <Header>
          <Info>
            <Label>
              {label}
              {count > 0 ? <Counter>{count}</Counter> : null}
            </Label>
            <Value>
              {plus ? <PlusIcon /> : null}
              {minus ? <MinusIcon /> : null}
              {currencyFormatter.format(value, { code: 'BRL', format: '%s%v' })}
            </Value>
          </Info>
          {count > 0 ? (
            <>
              {screenSize === 'large' || screenSize === 'extraLarge' ? (
                <TooltipIconButton
                  content="Ver detalhes da operação"
                  onClick={this.handleButtonClick}
                >
                  <Chevron open={open} />
                </TooltipIconButton>
              ) : (
                <Chevron open={open} />
              )}
            </>
          ) : null}
        </Header>
        {count > 0 ? (
          <Content open={open} count={count}>
            {children}
          </Content>
        ) : null}
        {count && count !== 0 ? (
          <ActionOverlay
            onMouseEnter={() => this.setState({ overlayHover: true })}
            onMouseLeave={() => this.setState({ overlayHover: false })}
            onClick={this.handleButtonClick}
          />
        ) : null}
      </Container>
    );
  }
}

InvoiceAccordion.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  plus: PropTypes.bool,
  minus: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.any]),
  separator: PropTypes.bool,
  count: PropTypes.number,
};

InvoiceAccordion.defaultProps = {
  label: '',
  value: 0,
  plus: false,
  minus: false,
  children: [],
  separator: true,
  count: 0,
};

export default InvoiceAccordion;
