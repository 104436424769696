import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import hydrateStore from '../store';
import Login from './components/Login';
import { Global } from './components/styles';

export { default as OperatorSelection } from './components/OperatorSelection';

export const LoginView = ({ type, store, config, reCaptchaSecret }) => (
  <>
    <Global />
    <Provider store={hydrateStore(store)}>
      <Login type={type} config={config} reCaptchaSecret={reCaptchaSecret} />
    </Provider>
  </>
);

LoginView.propTypes = {
  type: PropTypes.string.isRequired,
  reCaptchaSecret: PropTypes.string.isRequired,
  store: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
  ).isRequired,
  config: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
  ),
};

LoginView.defaultProps = {
  config: {},
};

export default LoginView;
