import TextField from 'liber-components/components/Inputs/v2/TextField';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
`;

export const FormTitle = styled.h5`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  color: #6287a7;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
`;

export const FormCol = styled.div`
  display: flex;
  flex-grow: 1;
  & > div {
    width: 100% !important;
  }
`;

export const CheckboxLabel = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #506286;
`;

export const FormInput = styled(TextField).attrs(() => ({
  version: '2',
}))`
  width: 100% !important;
`;
