import styled from 'styled-components';
import { Button as ButtonBase } from 'liber-components';

export const TEXT_COLOR = '#6287a7';

export const PaddingLeft = styled.div`
  padding-left: 16px;
  width: fit-content;
`;

export const DownloadIconButton = styled(ButtonBase).attrs(() => ({
  version: 2,
  as: 'a',
}))`
  padding: 4px;
  border-radius: 50%;
  color: ${TEXT_COLOR};

  :hover {
    color: ${TEXT_COLOR};
  }

  svg {
    fill: ${TEXT_COLOR} !important;
    width: 20px;
    height: 20px;
  }
`;

export const CellWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
