import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';

export const Container = styled.div`
  margin-top: 44px;
  ${({ open }) => open && 'margin-bottom: 44px;'}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const collapseStyles = {
  root: {
    marginTop: 20,
    padding: '0px !important',
  },
};

export const CollapseContainer = withStyles(collapseStyles)(Collapse);
