import React, { useContext, useState } from 'react';
import { Modal } from 'liber-components';
import { LimitsContext } from '../../context';
import * as S from './styles';
import { updateLimits } from '../../api';
import { handleError, handleSuccess } from '../../../../vendor/Utils';
import { toBrl } from '../../utils';

const AllBuyersLimitsModal = () => {
  const { showLimitsModal, onLeaveLimitsModal, token, selectedBuyer, getLimits } =
    useContext(LimitsContext);
  const [limit, setLimit] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const id = selectedBuyer?.buyerOrganizationGroupId || 'all';
    setLoading(true);
    updateLimits(token, limit, id)
      .then(() => {
        onLeaveLimitsModal();
        getLimits();
        handleSuccess('Limites atualizados com sucesso');
      })
      .catch(() => handleError('Ocorreu um erro ao atualizar os limites.'))
      .finally(() => setLoading(false));
  };

  return (
    <Modal elementId="modal" show={showLimitsModal} onLeaved={onLeaveLimitsModal}>
      <S.Container>
        {selectedBuyer ? (
          <>
            <S.Header>
              Digite o novo valor de limite para {selectedBuyer.buyerOrganizationGroupName}.
            </S.Header>
            <S.List>
              <p>
                <b>Limite Atual:</b> {toBrl(selectedBuyer.currentValue)}
              </p>
              <p>
                <b>Limite Disponível:</b> {toBrl(selectedBuyer.available)}
              </p>
            </S.List>
          </>
        ) : (
          <S.Wrapper>
            <S.Header>Digite o novo valor de limite Global</S.Header>
            <p>
              O limite global considera todas operações no portal e será aplicado para todos os
              sacados. Ao excedê-lo, nenhuma outra operação será permitida no mês.
            </p>
            <p>Digite o novo valor de limite global.</p>
          </S.Wrapper>
        )}

        <S.InputText
          version={2}
          prefix="R$"
          label="Novo Limite"
          value={limit}
          onChange={setLimit}
          data-testid="update-limit-input"
        />
        <S.Footer>
          <S.CancelButton onClick={() => onLeaveLimitsModal(false)}>Cancelar</S.CancelButton>
          <S.SubmitButton disabled={loading} onClick={() => handleSubmit()}>
            Enviar
          </S.SubmitButton>
        </S.Footer>
      </S.Container>
    </Modal>
  );
};

export default AllBuyersLimitsModal;
