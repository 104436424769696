import React from 'react';
import * as S from './styles';
import { STATUS, STATUS_EQUALS } from '../../../../constants';
import { SchedulesContext } from '../../../../context';

const StatusCheckboxes = () => {
  const { filters, setFilters } = React.useContext(SchedulesContext);

  const handleFilter = value => {
    setFilters({
      ...filters,
      [STATUS_EQUALS]: value,
    });
  };

  return (
    <S.Container>
      {Object.keys(STATUS).map(item => (
        <>
          <S.Input
            type="radio"
            id={item}
            name="filter"
            onChange={event => handleFilter(event.target.value)}
            value={item}
            defaultChecked={item === 'scheduled'}
          />
          <S.Label for={item}>{STATUS[item]}</S.Label>
        </>
      ))}
    </S.Container>
  );
};

export default StatusCheckboxes;
