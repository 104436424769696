import { PAYMENT_CONCILIATION_URL, INVOICES_URL } from '../../url';

export const INVOICES_MODAL_MAP = {
  all: { title: 'Carteira de títulos', filters: {}, url: INVOICES_URL },
  toSettle: { title: 'Carteira de títulos a receber', filters: {}, url: INVOICES_URL },
  overdue: {
    title: 'Carteira de títulos em atraso',
    filters: { payment_overdue_eq: true },
    url: INVOICES_URL,
  },
  conciliation: {
    title: 'Carteira de títulos em conciliação',
    filters: {},
    url: PAYMENT_CONCILIATION_URL,
  },
};
