import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, getScreenSize, SecondaryButton } from 'liber-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { capitalize, fetchBanks } from '../../../../../vendor/Utils';
import {
  Row,
  CardContent,
  Divider,
  HeaderRow,
  Info,
  StarIcon,
  EmptyStarIcon,
  EraseButton,
  DefaultButton,
  ButtonContainer,
  AddButton,
  ButtonsContainer,
  Drawer,
  ConfirmationContent,
  ConfirmationText,
  ConfirmationButtons,
  SubmitButton,
} from '../../Base.styles';
import Form from './InvestorBankAccountForm';
import { getSavingsComplementLabel } from './auxFunc';
import {
  closeModalForm as close,
  hidrateForm as hidrate,
  removeBankAccount as remove,
  setDefaultAccount as setDefault,
} from '../../../actions/bankAccount/InvestorBankAccountActions';

export class InvestorBankAccount extends Component {
  constructor() {
    super();
    this.state = {
      screenSize: getScreenSize(window),
      confirmationModal: false,
      erase: {},
      banks: null,
    };
  }

  componentWillMount() {
    this.Banks();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ screenSize: getScreenSize(window) });
  };

  handleEraseConfirmation = (id, index) => {
    this.setState({
      confirmationModal: true,
      erase: { id, index },
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      confirmationModal: false,
      erase: {},
    });
  };

  handleEdit = (account, index) => {
    const { hidrateForm } = this.props;
    hidrateForm(account, index);
  };

  handleErase = () => {
    const { id, index } = this.state.erase;
    const { removeBankAccount } = this.props;
    this.setState(
      {
        confirmationModal: false,
        erase: {},
      },
      () => {
        removeBankAccount(id, index);
      },
    );
  };

  handleNewAccount = () => {
    const { hidrateForm, userKind } = this.props;
    hidrateForm({}, -1, userKind);
  };

  handleDefault = id => {
    const { setDefaultAccount } = this.props;
    setDefaultAccount(id);
  };

  async Banks() {
    const result = await fetchBanks();

    this.setState({
      banks: result.data,
    });
  }

  renderPjData = account => (
    <>
      <Row>
        <Info>
          Número do Contrato: <span>{account.contractNumber}</span>
        </Info>
        <Info>
          Carteira: <span>{account.wallet}</span>
        </Info>
        <Info>
          Variação da Carteira: <span>{account.walletVariation}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Banco Emissor: <span>{account.emmiterBank}</span>
        </Info>
        <Info>
          1° instrução: <span>{account.firstInstruction}</span>
        </Info>
        <Info>
          2° instrução: <span>{account.secondInstruction}</span>
        </Info>
      </Row>
      <Row>
        <Info>
          Multa: <span>{account.finePercentage}</span>
        </Info>
        <Info>
          Mora: <span>{account.arrearsPercentage}</span>
        </Info>
        <Info>
          Dias para protestar: <span>{account.daysToProtest}</span>
        </Info>
      </Row>
    </>
  );

  renderBankAccount = (account, index, bankAccounts, userKind, loading) => (
    <div key={_.uniqueId(account.account)}>
      <HeaderRow>
        <h2>
          <span>
            {account.default ? <StarIcon /> : <EmptyStarIcon />}
            {account.bankCode} -{' '}
            {this.state.banks &&
              this.state.banks.find(item => item.code === account.bankCode).full_name}
          </span>
          <small>{capitalize(account.kind)}</small>
        </h2>
        <ButtonContainer>
          {!account.default ? (
            <>
              <DefaultButton
                loading={loading === 'default'}
                onClick={() => {
                  this.handleDefault(account.id);
                }}
              >
                Marcar como Padrão
              </DefaultButton>
              <EraseButton
                loading={loading === 'delete'}
                onClick={() => {
                  this.handleEraseConfirmation(account.id, index);
                }}
              >
                Apagar
              </EraseButton>
            </>
          ) : null}
        </ButtonContainer>
      </HeaderRow>
      {['poupanca', 'poupança'].includes(account.kind.toLowerCase()) &&
      ['001', '104', '341'].includes(account.bankCode) ? (
        <>
          <Row>
            <Info>
              Agência:{' '}
              <span>
                {account.agency}-{account.agencyDigit}
              </span>
            </Info>
            <Info>
              Conta{' '}
              <span>
                {account.account}-{account.accountDigit}
              </span>
            </Info>
            <Info>
              {getSavingsComplementLabel(account.bankCode)} <span>{account.savingsComplement}</span>
            </Info>
          </Row>
          <Row>
            <Info>
              Conjunta <span>{capitalize(account.conjunct)}</span>
            </Info>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Info>
              Agência:{' '}
              <span>
                {account.agency}-{account.agencyDigit}
              </span>
            </Info>
            <Info>
              Conta{' '}
              <span>
                {account.account}-{account.accountDigit}
              </span>
            </Info>
            <Info>
              Conjunta <span>{account.conjunct}</span>
            </Info>
          </Row>
        </>
      )}
      <Row>
        <Info>
          Razão Social <span>{account.company}</span>
        </Info>
        <Info>
          Documento <span>{account.document}</span>
        </Info>
      </Row>
      {userKind !== 'pf' ? this.renderPjData(account) : null}
      <Divider last={index === bankAccounts.length - 1} />
    </div>
  );

  render() {
    const { bankAccounts, showBankAccountForm, closeModalForm, loading, userKind } = this.props;
    const { screenSize, confirmationModal } = this.state;
    const Dialog = screenSize === 'large' || screenSize === 'extraLarge' ? Modal : Drawer;
    return (
      <>
        <Dialog show={showBankAccountForm} onLeaved={closeModalForm} closeButton>
          <Form />
        </Dialog>
        <Modal show={confirmationModal} onLeaved={this.handleCloseConfirmation} closeButton>
          <ConfirmationContent>
            <ConfirmationText>Tem certeza que deseja apagar essa conta bancária?</ConfirmationText>
            <ConfirmationButtons>
              <SecondaryButton onClick={this.handleCloseConfirmation}>Não</SecondaryButton>
              <SubmitButton onClick={this.handleErase}>Sim</SubmitButton>
            </ConfirmationButtons>
          </ConfirmationContent>
        </Modal>
        <CardContent>
          <HeaderRow>
            <h1>Contas Bancárias</h1>
          </HeaderRow>
          {bankAccounts.map((...args) => this.renderBankAccount(...[...args, userKind, loading]))}
          <ButtonsContainer>
            <AddButton onClick={this.handleNewAccount}>Nova Conta</AddButton>
          </ButtonsContainer>
        </CardContent>
      </>
    );
  }
}
InvestorBankAccount.propTypes = {
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool,
      kind: PropTypes.string,
      agency: PropTypes.string,
      agencyDigit: PropTypes.string,
      account: PropTypes.string,
      accountDigit: PropTypes.string,
      company: PropTypes.string,
      conjunct: PropTypes.string,
      document: PropTypes.string,
      bankCode: PropTypes.string,
      savingsComplement: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  userKind: PropTypes.string.isRequired,
  showBankAccountForm: PropTypes.bool.isRequired,
  loading: PropTypes.string.isRequired,
  closeModalForm: PropTypes.func.isRequired,
  hidrateForm: PropTypes.func.isRequired,
  removeBankAccount: PropTypes.func.isRequired,
  setDefaultAccount: PropTypes.func.isRequired,
};

const parentReducer = 'myAccount';
const tab = 'bankAccount';
const type = 'investor';
const reducer = 'data';

const mapStateToProps = state => ({
  ...state[parentReducer][tab][type][reducer],
  userKind: state[parentReducer].account.investor.data.account.kind,
});
const mapDispatchToProps = {
  closeModalForm: close,
  hidrateForm: hidrate,
  removeBankAccount: remove,
  setDefaultAccount: setDefault,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvestorBankAccount);
