import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, FlatLink } from 'liber-components';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Container,
  Header,
  Content,
  Input,
  InputFile,
  ActionsRow,
  Button,
  Row,
  List,
  ListItem,
} from './styles';
import { handleError, handleNavigate } from '../../vendor/Utils';
import LoadingButton from '../Buttons/LoadingButton';
import { UPLOAD_ERROR_STATUSES } from './constants';

function SpreadsheetRatesUploadModal({
  onLeaved,
  show,
  sendSpreadsheet,
  token,
  roomId,
  exampleFile,
}) {
  const inputRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setSelectedFile(null);
      setLoading(false);
    }
  }, [show]);

  const handleInputClick = event => {
    event.stopPropagation();
    inputRef.current.click();
  };

  const handleFileUpload = event => {
    const [file] = event?.target?.files;
    setSelectedFile(file);
  };

  const handleSendFile = () => {
    setLoading(true);
    sendSpreadsheet(token, selectedFile, roomId)
      .then(({ data: { id } }) => handleNavigate(`/investidor/painel_taxas/confirmar_taxas/${id}`))
      .catch(error => {
        const errorType = error?.response?.data?.error?.message;
        handleError(
          UPLOAD_ERROR_STATUSES[errorType]?.title,
          UPLOAD_ERROR_STATUSES[errorType]?.message,
        );
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onLeaved={onLeaved}>
      <Container>
        <Row>
          <Header>Atualizar Taxas</Header>
          <FlatLink size="large" href={exampleFile}>
            <Icon path={mdiDownload} />
            Planilha Modelo
          </FlatLink>
        </Row>
        <Content>
          Insira uma planilha de taxas do seu computador e envie para o processamento das novas
          taxas do sistema.
          <List>
            <ListItem>
              A planilha <b>não</b> deve conter fórmulas
            </ListItem>
            <ListItem>
              As datas devem estar no formato <b>DD/MM/AAAA</b>
            </ListItem>
            <ListItem>As informações devem estar na primeira aba da planilha</ListItem>
          </List>
          A atualização pode levar alguns minutos.
        </Content>

        <Input data-testid="spreadsheet-upload-input" onChange={handleFileUpload} ref={inputRef} />
        <InputFile onClick={handleInputClick} value={selectedFile?.name} />

        <ActionsRow>
          <Button onClick={onLeaved}>FECHAR</Button>
          <LoadingButton
            ButtonComponent={Button}
            spinnerColor="#009dff"
            loading={loading}
            onClick={handleSendFile}
            disabled={!selectedFile}
          >
            ENVIAR
          </LoadingButton>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

SpreadsheetRatesUploadModal.propTypes = {
  onLeaved: PropTypes.func,
  show: PropTypes.bool,
  sendSpreadsheet: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  exampleFile: PropTypes.string.isRequired,
};

SpreadsheetRatesUploadModal.defaultProps = {
  onLeaved: () => {},
  show: true,
};

export default SpreadsheetRatesUploadModal;
