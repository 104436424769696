// eslint-disable-next-line no-undef
export const BASE_URL = SERVICE_API_URL;
// eslint-disable-next-line no-undef
export const INVOICES_ACTION_CABLE_URL = WEBSOCKET_URL;
export const INVOICES_ACTION_CABLE_CHANNEL = 'FornecedorScheduleBatchInvoicesChannel';
export const INVOICES_ACTION_CABLE_ROOM = 'fornecedor_schedule_batch_invoices_channel_';

export const INVOICES_URL = `${BASE_URL}/api/v1/fornecedor/titulos`;
export const INVOICES_QUOTE_ALL_URL = `${BASE_URL}/api/v1/fornecedor/titulos/quote_all`;
export const INVOICES_QUOTE_URL = `${BASE_URL}/api/v1/fornecedor/titulos/quote`;
export const INVOICES_BANK_ACCOUNTS_URL = `${BASE_URL}/api/v1/fornecedor/contas_bancarias`;
export const INVOICES_REQUEST_ANTICIPATION_URL = `${BASE_URL}/api/v1/fornecedor/anticipations`;
export const INVOICES_REQUEST_DOWNLOAD_URL = `${BASE_URL}/api/v1/fornecedor/reports/invoices_simulation`;
export const INVOICES_NEGOTIATIONS_URL = `${BASE_URL}/api/v1/fornecedor/titulos/negotiations`;
export const ADMIN_INVOICES_URL = `${BASE_URL}/api/v1/admin/vendors/:id/invoices`;
export const VENDOR_ANTICIPATION_LIMITS_URL = `${BASE_URL}/api/v1/fornecedor/anticipation_limits`;
