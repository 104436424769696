import styled from 'styled-components';
import { SecondaryButton as RawSecondaryButton, LoadingBunny } from 'liber-components';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UploadMethod = styled.p`
  font-size: 12px;
  display: inline-block;
`;

export const SecondaryButton = styled(RawSecondaryButton).attrs(() => ({
  version: 2,
  size: 'small',
}))``;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  background-color: white;
  transition: opacity 150ms ease-in-out;
  z-index: 201;
  position: relative;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 160px;
  margin-bottom: -80px;
`;

export const LimiterContainer = styled.div`
  width: 100%;
  min-width: 930px;
`;
