import { validateEmail } from '../../../vendor/validation';
import { errorMsg } from '../messages';

export function changeEmail(value) {
  if (value.length === 0) {
    return {
      type: 'FORNECEDOR_CHANGE_EMAIL_ERROR',
      payload: {
        email: value,
        error: errorMsg.empty,
      },
    };
  }
  return {
    type: 'FORNECEDOR_CHANGE_EMAIL',
    payload: value,
  };
}

export function changePassword(value) {
  if (value.length === 0) {
    return {
      type: 'FORNECEDOR_CHANGE_PASSWORD_ERROR',
      payload: {
        password: value,
        error: errorMsg.empty,
      },
    };
  }
  return {
    type: 'FORNECEDOR_CHANGE_PASSWORD',
    payload: value,
  };
}

export function toogleRemember() {
  return {
    type: 'FORNECEDOR_TOOGLE_REMEMBER',
  };
}

export const setFingerprint = data => ({
  type: 'FORNECEDOR_SET_FINGERPRINT',
  payload: data,
});

export const submitLogin = (target, email, password) => dispatch => {
  if (email.length !== 0 && validateEmail(email) && password.length !== 0) {
    target.submit();
    return dispatch({ type: 'FORNECEDOR_SUCESSFULL_LOGIN' });
  }
  let emailError = email.length === 0 ? errorMsg.empty : '';
  emailError = validateEmail(email) || email.length === 0 ? emailError : errorMsg.invalidFormat;
  return dispatch({
    type: 'FORNECEDOR_FAILED_LOGIN',
    payload: {
      emailError,
      passwordError: password.length === 0 ? errorMsg.empty : '',
    },
  });
};
