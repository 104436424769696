import styled from 'styled-components';
import { TextField, FlatButton } from 'liber-components';

export const Header = styled.h2`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  margin-bottom: 15px;
  color: #0095f2;
`;

export const Container = styled.div`
  padding: 20px 20px 0 20px;
  > div {
    width: 100%;
  }
  label {
    background: white;
  }
`;

export const Wrapper = styled.div`
  max-width: 400px;
  p + p {
    margin-bottom: 30px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #aab0bf;
  }
`;

export const SubmitButton = styled(FlatButton).attrs(() => ({ size: 'large' }))``;

export const CancelButton = styled(FlatButton).attrs(() => ({ size: 'large', color: 'danger' }))``;

export const InputText = styled(TextField).attrs(() => ({
  version: 2,
  openLabel: true,
}))`
  margin-bottom: 15px;
`;
