import styled from 'styled-components';
import { PrimaryLink } from 'liber-components';

export const Subtitle = styled.h6`
  color: #405f71;
  font-size: 20px;
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
`;

export const Label = styled.p`
  color: #6287a7;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Info = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #405f71;
`;

export const DownloadLink = styled(PrimaryLink).attrs(() => ({ version: 2, outlined: true }))`
  height: 28px;
  margin-bottom: 14px;
`;

const StatusBadgeColors = {
  Processada: '#13CE66',
  Pendente: '#009DFF',
  Confirmada: '#13CE66',
  'Erro ao processar': '#DC3545',
  Cancelada: '#DC3545',
};

export const StatusBadge = styled.div`
  color: white;
  font-size: 12px;
  padding: 4px 16px 4px 16px;
  border-radius: 16px;
  background-color: ${({ status }) => StatusBadgeColors[status]};
`;
