import styled from 'styled-components';
import MarcaLiber from '../../resources/marca_liber_horizontal.svg';

export const Container = styled.div`
  width: fit-content;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, auto);

  @media (max-width: 991px) {
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const TextWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  margin-left: 32px;
  height: fit-content;

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

export const Logo = styled.img.attrs({ alt: 'liber logo', src: MarcaLiber })`
  margin: auto 0;

  @media (max-width: 600px) {
    margin: 0 0 32px 0;
  }
`;
