import { connect } from 'react-redux';

import ChangePassword from './ChangePassword';
import {
  changeCurrentPassword,
  changeNewPassword,
  changePasswordConfirmation,
  submitForm,
} from '../../actions/changePassword';

const mapStateToProps = state => ({ ...state.changePassword });

const mapDispatchToProps = {
  changeCurrentPassword,
  changeNewPassword,
  changePasswordConfirmation,
  submitForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
