import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FaCaretUpStyled,
  FaCaretDownStyled,
  ArrowsContainer,
  InvoiceSimulationSelectionTableHeaderColumn,
  InvoiceSimulationSelectionTableHeaderColumnContent,
} from './InvoiceSimulationTableHeaderColumn.styles';

class InvoiceSimulationTableHeaderColumn extends Component {
  handleSort = () => {
    const { column, onSort, sortAsc, orderedColumn } = this.props;
    if (orderedColumn === column && sortAsc) {
      onSort(column, 'desc');
    } else {
      onSort(column, 'asc');
    }
  };

  render() {
    const { sortAsc, sortDesc, children, orderedColumn, column, width } = this.props;
    return (
      <InvoiceSimulationSelectionTableHeaderColumn width={width} onClick={this.handleSort}>
        <InvoiceSimulationSelectionTableHeaderColumnContent>
          {children}
          <ArrowsContainer>
            {orderedColumn === column && sortAsc && <FaCaretUpStyled />}
            {orderedColumn === column && sortDesc && <FaCaretDownStyled />}
          </ArrowsContainer>
        </InvoiceSimulationSelectionTableHeaderColumnContent>
      </InvoiceSimulationSelectionTableHeaderColumn>
    );
  }
}

InvoiceSimulationTableHeaderColumn.propTypes = {
  column: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  sortAsc: PropTypes.bool,
  sortDesc: PropTypes.bool,
  orderedColumn: PropTypes.string,
  width: PropTypes.string.isRequired,
};

InvoiceSimulationTableHeaderColumn.defaultProps = {
  sortAsc: false,
  sortDesc: false,
  orderedColumn: '',
};

export default InvoiceSimulationTableHeaderColumn;
