import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

export const SpreadsheetRatesContext = createContext({});

export const SpreadsheetRatesContextProvider = ({ children, token, exampleFile }) => (
  <SpreadsheetRatesContext.Provider
    value={{
      token,
      roomId: uuid(),
      exampleFile,
    }}
  >
    {children}
  </SpreadsheetRatesContext.Provider>
);

SpreadsheetRatesContextProvider.propTypes = {
  children: PropTypes.node,
  token: PropTypes.string,
  exampleFile: PropTypes.string,
};

SpreadsheetRatesContextProvider.defaultProps = {
  children: null,
  token: '',
  exampleFile: '',
};
