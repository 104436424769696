import Immutable from 'seamless-immutable';

import { handleError, handleSuccess, handleWarning } from '../../../vendor/Utils';
import {
  UPDATE_DATE_FILTERS,
  GET_INVOICES_REPORT,
  INVOICES_REPORT_SUBSCRIBE_RECEIVED,
  INVOICE_REPORT_ERROR,
  CLOSE_WARNING_MODAL,
} from '../actions/VendorReportsActions';
import {
  ANTICIPATED_AT_GT,
  ANTICIPATED_AT_LT,
  INSERTED_AT_GT,
  INSERTED_AT_LT,
  ISSUED_AT_GT,
  ISSUED_AT_LT,
  EXPIRES_AT_GT,
  EXPIRES_AT_LT,
} from '../constants';

export const initialState = Immutable({
  token: null,
  modal: {
    show: false,
    title: '',
    message: '',
  },
  dateFilters: {
    [ANTICIPATED_AT_GT]: null,
    [ANTICIPATED_AT_LT]: null,
    [INSERTED_AT_GT]: null,
    [INSERTED_AT_LT]: null,
    [ISSUED_AT_GT]: null,
    [ISSUED_AT_LT]: null,
    [EXPIRES_AT_GT]: null,
    [EXPIRES_AT_LT]: null,
  },
  loading: false,
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_DATE_FILTERS: {
      const { dateFilters } = state;
      const { key, value } = payload;

      return state.merge({ dateFilters: { ...dateFilters, [key]: value } });
    }

    case GET_INVOICES_REPORT:
      return state.merge({ loading: true });

    case INVOICE_REPORT_ERROR:
      return state.merge({ loading: false });

    case INVOICES_REPORT_SUBSCRIBE_RECEIVED: {
      const { modal: outdatedModal } = state;
      const { report: url, status, title, message } = payload;
      let modal = outdatedModal;

      switch (status) {
        case 'success':
          handleSuccess(title, message);
          break;
        case 'warning':
          if (message.includes('nenhum título')) {
            handleWarning(title, message);
          } else {
            modal = {
              show: true,
              title,
              message,
            };
          }
          break;
        case 'error':
          handleError(title, message);
          break;
        default:
          handleError();
          break;
      }

      if (url) window.location.assign(url);

      return state.merge({ loading: false, modal });
    }

    case CLOSE_WARNING_MODAL:
      return state.merge({ modal: { ...state.modal, show: false } });

    default:
      return state;
  }
};
