import styled from 'styled-components';
import { TextField, LoadingBunny, LoadingSpinner } from 'liber-components';
import Icon from '@mdi/react';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(64, 95, 113, 0.12);
  padding: 14px 24px 24px;
  height: 595px;
  width: 488px;
`;

export const TextInput = styled(TextField).attrs(() => ({ version: 2 }))`
  width: 440px;
  padding-left: 12px;
  padding-right: 48px;
`;

export const HelperText = styled.span`
  font-size: 12px;
  color: #6287a7;
`;

export const SelectAllRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  width: 440px;
  cursor: pointer;
`;

export const Separator = styled.div`
  background-color: rgba(64, 95, 113, 0.12);
  width: calc(100% + 16px);
  height: 1px;
  margin: 24px 0 16px -8px;
`;

export const BoldText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
`;

export const Loading = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  width: 470px;
  height: ${({ full }) => (full ? '580' : '470')}px;
  z-index: 1000;
  margin-top: ${({ full }) => (full ? '-60' : '48')}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
`;

export const TextInputWrapper = styled.div`
  div {
    overflow: visible;
  }
`;

export const RemoveIcon = styled(Icon)`
  position: absolute;
  left: 408px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  pointer-events: all;
  color: #6287a7;
`;

export const CenterItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  font-family: Roboto;
  color: #6287a7;
  text-align: center;
`;

export const Spinner = styled(LoadingSpinner).attrs(() => ({
  size: '20px',
  color: '#6287a7',
  thickness: '2px',
}))`
  margin-right: 16px;
`;
