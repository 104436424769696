import Immutable from 'seamless-immutable';

const initialState = Immutable({
  kind: 'rg',
  number: '',
  emission_date: '',
  emission_issuer: '',
  emission_state: '',
  mother_name: '',
  father_name: '',
  pep: false,
  is_patch: false,
  errors: {},
});

const getEmissionIssuer = kind => {
  if (kind === 'cnh') return 'DETRAN';
  if (kind === 'rne') return 'DPF';
  return '';
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/KIND':
      return state.merge({
        kind: action.payload,
        emission_issuer: getEmissionIssuer(action.payload),
        errors: { ...state.errors, kind: '' },
      });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/NUMBER':
      return state.merge({ number: action.payload, errors: { ...state.errors, number: '' } });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/EMISSION_DATE':
      return state.merge({
        emission_date: action.payload,
        errors: { ...state.errors, emission_date: '' },
      });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/EMISSION_ISSUER':
      return state.merge({
        emission_issuer: action.payload,
        errors: { ...state.errors, emission_issuer: '' },
      });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/EMISSION_STATE':
      return state.merge({
        emission_state: action.payload,
        errors: { ...state.errors, emission_state: '' },
      });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/MOTHER_NAME':
      return state.merge({
        mother_name: action.payload,
        errors: { ...state.errors, mother_name: '' },
      });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/FATHER_NAME':
      return state.merge({
        father_name: action.payload,
        errors: { ...state.errors, father_name: '' },
      });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/PEP':
      return state.merge({ pep: action.payload === 'true', errors: { ...state.errors, pep: '' } });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/SET_DATA':
      return state.merge({ ...action.payload, is_patch: Boolean(action.payload) });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/SUCCESSFULL_SUBMIT':
      return state.merge({ errors: {}, is_patch: true });
    case 'WIZARD_INVESTIDOR/DOCUMENTACAO/FAILED_SUBMIT':
      return state.merge({ errors: action.payload });
    default:
      return state;
  }
};
