export const ACTIONS_TYPE = {
  BLOCK: {
    value: 'block',
    label: 'Bloquear',
  },
  UNBLOCK: {
    value: 'unblock',
    label: 'Desbloquear',
  },
};

export const ACTIONS_LABEL = {
  block: 'Bloquear',
  unblock: 'Desbloquear',
};

export const KIND = {
  FUNDER: 'funder',
  BUYER: 'buyer',
};
