import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer } from 'liber-components';
import hydrateStore from '../store';
import Wizard from './components/Wizard';

const View = ({ store }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <Wizard />
    </Provider>
    <ToastContainer />
  </>
);

View.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
