import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleDropDown, Checkbox } from 'liber-components';
import { mdiCalendar, mdiCancel, mdiDownload } from '@mdi/js';
import { SelectionControl as BaseControl } from '../../../../common-components/SelectionControl/SelectionControl';
import {
  resetSelection,
  selectAll as selectAllAction,
  openNegotiations,
  cancelNegotiations,
  downloadDocuments,
  showInvestorAccount as showInvestorAccountProp,
} from '../../actions/AdminNegotiationsActions';
import { ActionButton, ConfirmationContent } from './Table.styles';
import Dialog from '../../../../common-components/Dialog/Dialog';
import {
  DropDownContent,
  DropDownContentRow,
  DropDownContentRowIcon,
  DescriptionIcon,
  DropDownContentText,
  DocumentText,
  CheckboxLabel,
  ContainerRelative,
  SelectBoxContainer,
  SelectBox,
  Options,
  CnabContainer,
  WanterText,
} from './SelectionControl.styles';

const SelectionControl = ({
  onOpenNegotiations,
  onCancelNegotiations,
  onDownloadDocuments,
  showInvestorAccount,
  ...props
}) => {
  const {
    selectedItems,
    hasSelectedAllItems,
    itemsCount,
    label,
    selectedFunderIds,
    investorAccounts,
  } = props;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentSelectedAction, setCurrentSelectedAction] = useState('');
  const [terms, setTerms] = useState(true);
  const [idCountInvestor, setIdCountInvestor] = useState('');
  const [cnab, setCnab] = useState(false);

  const handleCnabChecked = event => {
    setCnab(event.target.checked);
    if (event.target.checked === true && selectedFunderIds.length === 1) {
      showInvestorAccount(selectedFunderIds);
    }
  };
  useEffect(() => {
    if (selectedFunderIds.length !== 1) {
      setIdCountInvestor('');
    }
  }, [selectedFunderIds.length]);

  const currentAction = useMemo(() => {
    switch (currentSelectedAction) {
      case 'cancel':
        return {
          name: 'cancelar',
          modalTitle: 'Cancelar Negociações',
          onConfirm: onCancelNegotiations,
        };
      case 'open':
        return {
          name: 'abrir',
          modalTitle: 'Abrir Negociações',
          onConfirm: onOpenNegotiations,
        };
      case 'documents':
        return {
          name: 'documentação',
          modalTitle: 'Baixar documentação',
          onConfirm: () => onDownloadDocuments(terms, cnab, idCountInvestor),
        };
      default:
        return {
          name: '',
          modalTitle: '',
          onConfirm: () => {},
        };
    }
  }, [currentSelectedAction, terms, cnab, idCountInvestor]);

  const selectedCount = hasSelectedAllItems ? itemsCount : selectedItems.length;
  const confirmationMessage =
    currentSelectedAction !== 'documents' ? (
      `Deseja ${currentAction.name} ${
        selectedCount > 1
          ? `as ${selectedCount} negociações selecionadas?`
          : 'a negociação selecionada?'
      }`
    ) : (
      <>
        <DocumentText>
          Selecione quais documentos deseja baixar das negociações selecionadas:
        </DocumentText>
        <DocumentText>
          <Checkbox checked={terms} onChange={event => setTerms(event.target.checked)}>
            <CheckboxLabel>Termo de cessão</CheckboxLabel>
          </Checkbox>
          <CnabContainer visible={selectedFunderIds.length}>
            <Checkbox disabled checked={cnab} onChange={handleCnabChecked}>
              <CheckboxLabel>CNAB do investidor</CheckboxLabel>
            </Checkbox>
            Selecione a conta bancária:
            <SelectBoxContainer visible={cnab}>
              <SelectBox value={idCountInvestor} onChange={setIdCountInvestor}>
                <Options value="0">Selecione</Options>
                {investorAccounts?.map(({ id, presentation }) => (
                  <Options key={id} value={id}>
                    {presentation}
                  </Options>
                ))}
              </SelectBox>
            </SelectBoxContainer>
          </CnabContainer>
          <WanterText visible={selectedFunderIds.length}>
            Não é possível selecionar CNAB’s de investidores diferentes
          </WanterText>
        </DocumentText>
        <DocumentText>
          Os termos de cessão serão agrupados por fornecedor e conta bancária. O CNAB estará
          disponível na raiz e será referente ao investidor das negociações selecionadas.
        </DocumentText>
      </>
    );

  const handleActionClick = action => {
    setCurrentSelectedAction(action);
    setConfirmationOpen(true);
  };

  const handleCloseModal = () => {
    setConfirmationOpen(false);
    setTerms(true);
    setCnab(false);
  };

  const confirmationDialogProps = {
    open: confirmationOpen,
    onClose: () => handleCloseModal(),
    confirmationButtonProps: {
      onConfirm: () => {
        setConfirmationOpen(false);
        currentAction.onConfirm();
      },
      label: currentSelectedAction === 'documents' ? 'BAIXAR' : 'CONTINUAR',
      color: 'primary',
      disabled: !terms && !cnab,
    },
    title: currentAction.modalTitle,
    children: <ConfirmationContent>{confirmationMessage}</ConfirmationContent>,
  };

  const actions = (
    <ContainerRelative>
      <SimpleDropDown
        label="Ações em Lote"
        customButton={<ActionButton>Ações em lote</ActionButton>}
      >
        <DropDownContent>
          {label === 'Agendadas' && (
            <DropDownContentRow onClick={() => handleActionClick('open')}>
              <DropDownContentRowIcon>
                <DescriptionIcon path={mdiCalendar} />
              </DropDownContentRowIcon>
              <DropDownContentText>Abrir Negociações</DropDownContentText>
            </DropDownContentRow>
          )}

          <DropDownContentRow onClick={() => handleActionClick('cancel')}>
            <DropDownContentRowIcon>
              <DescriptionIcon path={mdiCancel} />
            </DropDownContentRowIcon>
            <DropDownContentText>Cancelar Negociações</DropDownContentText>
          </DropDownContentRow>

          {label === 'Concluídas' && (
            <DropDownContentRow onClick={() => handleActionClick('documents')}>
              <DropDownContentRowIcon>
                <DescriptionIcon path={mdiDownload} />
              </DropDownContentRowIcon>
              <DropDownContentText>Gerar Documentação</DropDownContentText>
            </DropDownContentRow>
          )}
        </DropDownContent>
      </SimpleDropDown>
    </ContainerRelative>
  );

  return (
    <>
      <BaseControl {...props} actions={actions} />
      <Dialog {...confirmationDialogProps} />
    </>
  );
};

SelectionControl.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  investorAccounts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  hasSelectedAllItems: PropTypes.bool,
  isPageSelected: PropTypes.bool,
  itemsCount: PropTypes.number,
  selectedFunderIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  selectAll: PropTypes.func,
  clearSelection: PropTypes.func,
  onOpenNegotiations: PropTypes.func,
  onCancelNegotiations: PropTypes.func,
  onDownloadDocuments: PropTypes.func,
  showInvestorAccount: PropTypes.func,
  label: PropTypes.string,
};

SelectionControl.defaultProps = {
  selectedItems: [],
  investorAccounts: [],
  hasSelectedAllItems: false,
  isPageSelected: false,
  itemsCount: 0,
  selectedFunderIds: [],
  selectAll: () => {},
  clearSelection: () => {},
  onOpenNegotiations: () => null,
  onCancelNegotiations: () => null,
  onDownloadDocuments: () => null,
  showInvestorAccount: () => null,
  label: null,
};

const mapStateToProps = ({
  adminNegotiations: {
    pagination: { count: itemsCount },
    hasSelectedAllItems,
    selectedItems,
    selectedFunderIds,
    investorAccounts,
    isPageSelected,
    filters,
  },
}) => ({
  itemsCount,
  selectedItems,
  hasSelectedAllItems,
  selectedFunderIds,
  investorAccounts,
  isPageSelected,
  filters,
});

const mapDispatchToProps = {
  selectAll: selectAllAction,
  clearSelection: resetSelection,
  onOpenNegotiations: openNegotiations,
  onCancelNegotiations: cancelNegotiations,
  onDownloadDocuments: downloadDocuments,
  showInvestorAccount: showInvestorAccountProp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionControl);
