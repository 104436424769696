import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';
import Icon from '@mdi/react';

import { chipColors } from './constants';

export const Container = styled.div`
  padding: 16px;
  width: 640px;
  max-width: 100vw;
  margin-left: auto;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DialogContent = styled.div`
  margin: 48px 16px;
  overflow-y: auto;
  flex: auto;
`;

export const DialogFooter = styled.div`
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 16px;
  width: fit-content;
`;

export const Title = styled.div`
  color: #405f71;
  font-size: 28px;
  font-weight: 700;
  margin: auto 0;
`;

export const IconButton = styled.div`
  padding: 8px;
  color: #506286;
  & > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 50%;
`;

export const SignButton = styled(PrimaryButton).attrs({ version: '2', size: 'large' })``;

export const CloseButton = styled(PrimaryButton).attrs({
  version: '2',
  size: 'large',
  outlined: true,
})``;

export const BodyText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;
`;

export const DescriptionText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
`;

export const CountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Spacer = styled.div`
  height: 67px;
  width: 1px;
  background-color: #405f711f;
  margin: 0 52px;
`;

export const RepresentantsContainer = styled.div`
  margin-top: 64px;
`;

export const RepresentantsList = styled.div`
  border-left: 1px solid #009dff;
  padding-left: 12px;
  margin-top: 16px;
`;

export const RepresentantRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  color: #405f71;
  font-weight: 500;
`;

export const MutedText = styled.div`
  font-size: 16px;
  color: #b6c2c9;
  margin-left: 16px;
`;

export const DescriptionContainer = styled.div`
  display: grid;
  margin-top: 32px;
  gap: 22px;
`;

export const ChipIconWrapper = styled.div`
  & > svg {
    width: 18px;
    height: 18px;
  }
  margin-right: 8px;
`;

export const SignedChip = styled.div`
  padding: ${({ signed }) => (signed ? '7px 10px' : '7px 10px 7px 7px')};
  border-radius: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  background: ${({ signed }) => chipColors[signed ? 'signed' : 'notSigned'].bg};
  color: ${({ signed }) => chipColors[signed ? 'signed' : 'notSigned'].color};
`;

export const SignedCheckmark = styled(Icon)`
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: #006aeb;
  border-radius: 50%;
  color: #ffffff;
  margin-top: -4px;
  margin-left: 4px;
`;
