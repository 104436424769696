import styled, { css } from 'styled-components';

export const Card = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(64, 95, 113, 0.12);
  border-radius: 4px;
`;

export const Header = styled.div`
  width: 100%;
  height: 46px;
  padding: 12px;
  background: rgba(64, 95, 113, 0.03);

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
`;

export const Content = styled.div`
  padding: 26px;
  display: flex;
`;

const SplitContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TargetingModeContent = styled(SplitContent)`
  min-width: 335px;
  justify-content: center;
  padding-right: 25px;
  border-right: 2px solid rgba(64, 95, 113, 0.12);
`;

export const FunderContent = styled(SplitContent)`
  width: 100%;
  padding-left: 25px;
  overflow: hidden;

  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      min-height: 413px;
    `}
`;

export const ContentLabel = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;
`;

export const ContentValue = styled.div`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  color: #405f71;
`;

export const ContentInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #405f71;
`;

export const InfoWrapper = styled.div`
  display: flex;

  svg {
    margin: -6px 8px 0 0;
    color: #009dff;
    width: 24px;
    height: 24px;
  }
`;

export const IconWrapper = styled.div`
  min-width: 32px;
  min-height: 24px;
`;

export const GraphWrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

export const GraphEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      margin: 155px 0;
    `}
`;
