import Immutable from 'seamless-immutable';
import errorMessages from './errorMessages';
import {
  INVESTOR_DASH_UPDATE_INVOICES,
  INVESTOR_DASH_FETCH_INVOICES,
  INVESTOR_DASH_INVOICES_FAIL,
  INVESTOR_DASH_CLOSE_INVOICES,
} from '../actions/InvoicesActions';

export const initialState = Immutable({
  loading: true,
  show: false,
  modalTitle: 'Carteira de títulos',
  status: true,
  error: {
    code: null,
    message: '',
  },
  data: [],
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case INVESTOR_DASH_UPDATE_INVOICES: {
      const { data } = payload;
      const { status, error, data: responseData } = data || {};
      const invoicesPerBuyer = responseData || [];
      const invoicesCount = invoicesPerBuyer.reduce(
        (total, { invoices }) => total + (invoices ? invoices.length : 0),
        0,
      );
      const { modalTitle } = state;
      return state.merge(
        {
          loading: false,
          data: invoicesPerBuyer,
          status,
          error: {
            message: error ? error.message : '',
            code: error ? error.code : null,
          },
          modalTitle: `${modalTitle} (${invoicesCount})`,
        },
        { deep: true },
      );
    }

    case INVESTOR_DASH_FETCH_INVOICES:
      return state.merge({ loading: true, show: true, modalTitle: payload }, { deep: true });

    case INVESTOR_DASH_INVOICES_FAIL: {
      const { response } = payload;
      const { status, message: responseMessage, data } = response || {};
      const { error } = data || {};
      const message = error || errorMessages[status] || responseMessage || errorMessages[500];
      return state.merge(
        {
          error: { message },
          status: false,
          loading: false,
        },
        { deep: true },
      );
    }

    case INVESTOR_DASH_CLOSE_INVOICES:
      return state.merge({ show: false, data: [] }, { deep: true });

    default:
      return state;
  }
};
