import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { Modal, Text } from 'liber-components';
import { uniqueId } from 'lodash';

import {
  Container,
  Header,
  IconButton,
  Content,
  Title,
  Row,
  InfoBlock,
  InfoLabel,
  TextBox,
} from './InvoiceModal.styles';
import StatusLabel from '../../../common-components/VendorBorderoShow/components/StatusLabel';
import { Loading, Bunny } from './RenegotiationsTable.styles';
import Api from '../api';
import { TokenContext } from '../../../hocs/withTokenProvider';
import { convertToCamelCase, handleError, formatCnpjOrCpf } from '../../../vendor/Utils';

const STRONG_COLOR = 'rgba(35, 39, 47, 0.87)';

const initialValues = {
  number: '***',
  buyer: {
    tradeName: '***',
    document: '***',
  },
  amount: '***',
  expectedPaymentAt: '***',
  renegotiationDetails: '***',
};

const InvoiceModal = ({ show, onClose, invoiceSlug }) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(initialValues);

  const { token } = useContext(TokenContext);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setDetails(initialValues);
      Api.fetchDetails(token, invoiceSlug)
        .then(response => {
          const { invoice } = response?.data?.data || {};
          setDetails(convertToCamelCase(invoice) || initialValues);
          setLoading(false);
        })
        .catch(() => {
          handleError();
          setLoading(false);
        });
    }
  }, [show]);

  const { number, buyer, amount, expectedPaymentAt, renegotiationDetails } = details;

  const renderDetailsWithBreaks = text =>
    text
      ? String(text)
          .split('\n')
          .map(textBlock => (
            <Fragment key={uniqueId('details-text-block-')}>
              {textBlock}
              <br />
            </Fragment>
          ))
      : '***';

  return (
    <>
      <Modal show={show} onLeaved={onClose}>
        <Container>
          <Loading loading={loading ? 1 : 0}>
            {loading && <Bunny />}
            <Header>
              <Title>Título — {number}</Title>
              <IconButton data-testid="close-renegotiation-details" onClick={onClose} black={1}>
                <Icon path={mdiClose} />
              </IconButton>
            </Header>
            <Content>
              <Row>
                <StatusLabel color={STRONG_COLOR}>Renegociado</StatusLabel>
              </Row>
              <Row marginTop shouldChangeDirection>
                <InfoBlock>
                  <InfoLabel>Sacado</InfoLabel>
                  <Text large>{buyer?.tradeName}</Text>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>CNPJ</InfoLabel>
                  <Text large>{formatCnpjOrCpf(buyer?.document)}</Text>
                </InfoBlock>
              </Row>
              <Row shouldChangeDirection>
                <InfoBlock>
                  <InfoLabel>Valor Investido</InfoLabel>
                  <Text large>{amount}</Text>
                </InfoBlock>
                <InfoBlock>
                  <InfoLabel>Vencimento Original</InfoLabel>
                  <Text large>{expectedPaymentAt}</Text>
                </InfoBlock>
              </Row>
              <Row>
                <InfoLabel>Termos Acordados</InfoLabel>
              </Row>
              <Row>
                <TextBox>
                  <Text large>{renderDetailsWithBreaks(renegotiationDetails)}</Text>
                </TextBox>
              </Row>
            </Content>
          </Loading>
        </Container>
      </Modal>
    </>
  );
};

InvoiceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  invoiceSlug: PropTypes.string,
};

InvoiceModal.defaultProps = {
  show: false,
  onClose: () => {},
  invoiceSlug: '',
};

export default InvoiceModal;
