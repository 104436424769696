import React from 'react';
import PropTypes from 'prop-types';
import { mdiArrowDown } from '@mdi/js';
import VendorsList from './components/VendorList';
import * as S from './styles';

const VendorsAccordion = ({ vendors }) => {
  const [listVisible, setListVisible] = React.useState(false);

  return (
    <S.Cell>
      <S.Container onClick={() => setListVisible(!listVisible)}>
        <S.FirstVendor>{vendors[0].name}</S.FirstVendor>
        {Object.keys(vendors).length > 1 && (
          <S.IconsContainer>
            <S.Badge>+{vendors.length - 1}</S.Badge>
            <S.Arrow path={mdiArrowDown} size={0.9} active={listVisible} />
          </S.IconsContainer>
        )}
      </S.Container>
      {listVisible && <VendorsList vendors={vendors} />}
    </S.Cell>
  );
};

export default VendorsAccordion;

VendorsAccordion.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
};
