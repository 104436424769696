import React from 'react';
import { ToastContainer } from 'liber-components';

import InvestorRenegotiations from './components/InvestorRenegotiations';
import withTokenProvider from '../../hocs/withTokenProvider';

const View = () => (
  <>
    <InvestorRenegotiations />
    <ToastContainer />
  </>
);

export default withTokenProvider(View);
