import React from 'react';
import { LoadingSpinner } from 'liber-components';
import * as S from './styles';

const Loading = () => (
  <S.LoadingContainer>
    <LoadingSpinner />
  </S.LoadingContainer>
);

export default Loading;
