import styled from 'styled-components';
import { FlatButton } from 'liber-components';

export const Container = styled.div`
  width: 480px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px 24px 12px 24px;

  * {
    font-family: Roboto;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Header = styled.h2`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  color: #6287a7;
  margin-bottom: 0;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin-bottom: 24px;
  color: #6287a7;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(FlatButton).attrs(() => ({ size: 'large' }))``;
