export const getName = state => state.dadosPessoais.name || '';
export const getCpf = state => state.dadosPessoais.cnpj || '';
export const getEmail = state => state.dadosPessoais.email || '';
export const getCellphone = state => state.dadosPessoais.cellphone || '';
export const getPhone = state => state.dadosPessoais.phone || '';
export const getGender = state => state.dadosPessoais.gender || '';
export const getBirthDate = state => state.dadosPessoais.birthdate || '';
export const getCivilStatus = state => state.dadosPessoais.civil_status || '';
export const getCountryOrigin = state => state.dadosPessoais.country_origin || '';
export const getStateOrigin = state => state.dadosPessoais.state_origin || '';
export const getCityOrigin = state => state.dadosPessoais.city_origin || '';
export const getErrors = state => state.dadosPessoais.errors || {};
export const getDadosPessoais = state => ({
  name: getName(state),
  cpf: getCpf(state),
  email: getEmail(state),
  cellphone: getCellphone(state),
  phone: getPhone(state),
  gender: getGender(state),
  birthDate: getBirthDate(state),
  civilStatus: getCivilStatus(state),
  countryOrigin: getCountryOrigin(state),
  stateOrigin: getStateOrigin(state),
  cityOrigin: getCityOrigin(state),
  errors: getErrors(state),
});
