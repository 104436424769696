import Immutable from 'seamless-immutable';

const initialState = Immutable({
  name: '',
  cnpj: '',
  email: '',
  cellphone: '',
  phone: '',
  gender: '',
  birthdate: '',
  civil_status: '',
  country_origin: 'BR',
  state_origin: '',
  city_origin: '',
  errors: {},
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/NAME':
      return state.merge({ name: action.payload, errors: { ...state.errors, name: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CPF':
      return state.merge({ cnpj: action.payload, errors: { ...state.errors, cnpj: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/EMAIL':
      return state.merge({ email: action.payload, errors: { ...state.errors, email: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CELLPHONE':
      return state.merge({ cellphone: action.payload, errors: { ...state.errors, cellphone: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/PHONE':
      return state.merge({ phone: action.payload, errors: { ...state.errors, phone: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/GENDER':
      return state.merge({ gender: action.payload, errors: { ...state.errors, gender: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/BIRTH_DATE':
      return state.merge({ birthdate: action.payload, errors: { ...state.errors, birthdate: '' } });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CIVIL_STATUS':
      return state.merge({
        civil_status: action.payload,
        errors: { ...state.errors, civil_status: '' },
      });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/COUNTRY_ORIGIN':
      return state.merge({
        country_origin: action.payload,
        errors: { ...state.errors, country_origin: '' },
      });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/STATE_ORIGIN':
      return state.merge({
        state_origin: action.payload,
        errors: { ...state.errors, state_origin: '' },
      });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/CITY_ORIGIN':
      return state.merge({
        city_origin: action.payload,
        errors: { ...state.errors, city_origin: '' },
      });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/SET_DATA':
      return state.merge({
        ...action.payload,
        country_origin: action.payload.country_origin || state.country_origin,
      });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/SUCCESSFULL_SUBMIT':
      return state.merge({ errors: {} });
    case 'WIZARD_INVESTIDOR/DADOS_PESSOAIS/FAILED_SUBMIT':
      return state.merge({ errors: action.payload });
    default:
      return state;
  }
};
