import Immutable from 'seamless-immutable';

const initialState = Immutable({
  email: '',
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
  errors: {},
  loading: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'MY_ACCOUNT_CHANGE_PASSWORD_CURRENT_PASSWORD':
      return state.merge({
        currentPassword: action.payload,
        errors: { ...state.errors, currentPassword: '' },
      });
    case 'MY_ACCOUNT_CHANGE_PASSWORD_NEW_PASSWORD':
      return state.merge({
        newPassword: action.payload,
        errors: { ...state.errors, newPassword: '' },
      });
    case 'MY_ACCOUNT_CHANGE_PASSWORD_PASSWORD_CONFIRMATION':
      return state.merge({
        passwordConfirmation: action.payload,
        errors: { ...state.errors, passwordConfirmation: '' },
      });
    case 'MY_ACCOUNT_CHANGE_PASSWORD_PENDING_SUBMIT':
      return state.merge({
        loading: true,
      });
    case 'MY_ACCOUNT_CHANGE_PASSWORD_SUCCESSFULL_SUBMIT':
      return initialState;
    case 'MY_ACCOUNT_CHANGE_PASSWORD_FAILED_SUBMIT':
      return state.merge({
        errors: action.payload,
        loading: false,
      });
    default:
      return state;
  }
};
