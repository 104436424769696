import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ChartLabel, PieChart, toast, handleThemeFromObject } from 'liber-components';
import { getScreenSize } from 'liber-components/components/Util';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { Card, LoadingBlock, CardHeader } from '../styles';
import {
  Container,
  Labels,
  LabelTitle,
  LabelColorPercent,
  LabelColumn,
  LabelValue,
  LabelFooter,
  LabelContainer,
  LabelContainerSmall,
  Total,
  ChippedText,
  Row,
  EmptyStateContainer,
  EmptyStateInfoBox,
  EmptyStatePrimaryText,
  SmallInfoBox,
  SmallInfoTitle,
  Donut,
  SmallInfoValueDisplayer,
  SmallDetailLink,
  SmallTitleName,
  SmallTitleSep,
  SmallTitleValue,
  LabelBox,
  FlexContainer,
} from './styles';

class Distribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hightlight: false,
      index: null,
      eventKey: null,
      size: 230,
      padding: 25,
      radius: 46,
      blink: false,
    };
    this.indexMap = {
      0: {
        index: '1',
        color: '#18d8ee',
      },
      1: {
        index: '3',
        color: '#537e86',
      },
      2: {
        index: '2',
        color: handleThemeFromObject(props.theme, 'colors.lightGreenGraph', '#2de8bf'),
      },
      3: {
        index: '0',
        color: '#40b7c9',
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeChart);
    const { getDistributionData, token } = this.props;
    getDistributionData(token);
  }

  componentWillReceiveProps(nextProps) {
    const { data, status, error } = nextProps;
    if (data && data.length > 0 && !_.isEqual(data, this.props.data)) {
      setTimeout(this.resizeChart, 0);
    }
    if (!status) {
      toast(error ? error.message : 'Ocorreu um erro!', 'error', 8000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeChart);
  }

  getPercent = number => `${Math.round(number * 10000) / 100}%`;

  resizeChart = () => {
    const screenSize = getScreenSize(window);
    if (screenSize === 'small' || screenSize === 'medium') {
      console.log(this.container);
      const parentSize = this.container.offsetWidth;
      const size = parentSize / 2;
      const proportion = size / 230;
      const padding = 25 * proportion;
      const radius = 46 * proportion;
      this.setState({
        size,
        padding,
        radius,
        blink: true,
      });
    } else {
      this.setState({
        size: 230,
        padding: 25,
        radius: 46,
        blink: true,
      });
    }
    this.setState({
      blink: false,
    });
  };

  processToChart = list => {
    if (list && list.length > 0) {
      return Object.keys(this.indexMap).map(item => {
        const drawee = list[this.indexMap[item].index];
        return {
          x: drawee ? drawee.drawee.slug : undefined,
          y: drawee ? drawee.to_settle : undefined,
        };
      });
    }
    return [];
  };

  preprocessData = rawData => {
    const data = JSON.parse(JSON.stringify(rawData));
    if (data && data.length > 0) {
      const sorted = data.sort((a, b) => b.to_settle - a.to_settle);
      const bigThree = sorted.splice(0, 3);
      let other;
      if (sorted.length === 0) {
        other = null;
      } else if (sorted.length === 1) {
        [other] = sorted;
      } else {
        other =
          sorted.length === 0
            ? null
            : sorted.reduce(
                (curr, next) => {
                  const prev = curr;
                  prev.drawee.slug.push(next.drawee.slug);
                  prev.to_settle += parseFloat(next.to_settle);
                  prev.invoices += next.invoices;
                  prev.quota += parseFloat(next.quota);
                  return prev;
                },
                {
                  drawee: {
                    slug: [],
                    trade_name: 'Outros',
                  },
                  to_settle: 0,
                  invoices: 0,
                  quota: 0,
                },
              );
      }
      let list;
      if (other) {
        list = [...bigThree, other];
      } else {
        list = bigThree;
      }
      return {
        list,
        invoices: list.reduce((prev, next) => {
          let curr = prev;
          curr += next.invoices;
          return curr;
        }, 0),
      };
    }
    return {
      list: [],
      invoices: 0,
    };
  };

  handleHightlightStart = eventKey => {
    this.setState({ hightlight: true, index: this.indexMap[eventKey].index, eventKey });
  };

  handleHightlightEnd = () => {
    this.setState({ hightlight: false, index: null, eventKey: null });
  };

  handleDetailClick = (eventKey, list) => {
    const { getDialogContent } = this.props;
    if (eventKey) {
      const draweeInfo = list[this.indexMap[eventKey].index];
      getDialogContent({
        drawee: draweeInfo.drawee.slug,
        liquidation_date_from: moment().startOf('day').format('YYYY-MM-DD'),
      });
    } else {
      getDialogContent();
    }
  };

  renderLabel = (index, list) => (
    <>
      <LabelTitle>
        {this.getPercent(list[index].quota)} {list[index].drawee.trade_name}
      </LabelTitle>
      <LabelValue>
        {currencyFormatter.format(list[index].to_settle, { code: 'BRL', format: '%s%v' })}
      </LabelValue>
      <LabelFooter>
        {list[index].invoices} titulo{list[index].invoices > 1 ? 's' : ''}
      </LabelFooter>
    </>
  );

  renderLabelSmall = (index, list) => (
    <>
      <LabelColorPercent>{this.getPercent(list[index].quota)}</LabelColorPercent>
      <LabelColumn>
        <LabelTitle>{list[index].drawee.trade_name}</LabelTitle>
        <LabelValue>
          {currencyFormatter.format(list[index].to_settle, { code: 'BRL', format: '%s%v' })}
        </LabelValue>
      </LabelColumn>
      <LabelFooter>
        {list[index].invoices} titulo{list[index].invoices > 1 ? 's' : ''}
      </LabelFooter>
    </>
  );

  renderChart = (list, invoices, data) => {
    const screenSize = getScreenSize(window);
    const { theme } = this.props;
    return (
      <Container
        onHighlightStart={this.handleHightlightStart}
        onHighlightEnd={this.handleHightlightEnd}
        event={screenSize === 'small' || screenSize === 'medium' ? 'click' : 'hover'}
      >
        {!this.state.blink ? (
          <PieChart
            ref={element => {
              this.chart = element;
            }}
            height={this.state.size}
            width={this.state.size}
            padding={this.state.padding}
            innerRadius={this.state.radius}
            data={this.processToChart(list)}
            onClick={
              screenSize === 'small' || screenSize === 'medium'
                ? null
                : (proxy, ChartData, eventKey) => {
                    this.handleDetailClick(eventKey, list);
                  }
            }
          />
        ) : (
          <div />
        )}

        <SmallInfoBox>
          <SmallInfoTitle
            color={this.state.index ? this.indexMap[this.state.eventKey].color : null}
          >
            {this.state.index ? (
              <>
                <SmallTitleName>{list[this.state.index].drawee.trade_name}</SmallTitleName>
                <SmallTitleSep>-</SmallTitleSep>
                <SmallTitleValue>{this.getPercent(list[this.state.index].quota)}</SmallTitleValue>
              </>
            ) : (
              'Total - 100%'
            )}
          </SmallInfoTitle>
          <SmallInfoValueDisplayer>
            <div>
              Valor a receber
              <span>
                {this.state.index
                  ? currencyFormatter.format(list[this.state.index].to_settle, {
                      code: 'BRL',
                      format: '%s%v',
                    })
                  : currencyFormatter.format(
                      list.reduce((prev, next) => next.to_settle + prev, 0),
                      { code: 'BRL', format: '%s%v' },
                    )}
              </span>
            </div>
            <div>
              {this.state.index ? (
                <>
                  titulo{list[this.state.index].invoices > 1 ? 's' : ''}
                  <span>{list[this.state.index].invoices}</span>
                </>
              ) : (
                <>
                  titulo{invoices > 1 ? 's' : ''}
                  <span>{invoices}</span>
                </>
              )}
            </div>
          </SmallInfoValueDisplayer>
          <SmallDetailLink
            color={this.state.index ? this.indexMap[this.state.eventKey].color : null}
            onClick={() => {
              this.handleDetailClick(this.state.eventKey, list);
            }}
          >
            Ver Detalhes
          </SmallDetailLink>
        </SmallInfoBox>
        <Labels>
          <Row>
            <LabelBox single={list.length === 1}>
              {list[0] ? (
                <ChartLabel eventkey="3" color="#40b7c9">
                  <LabelContainer
                    color="#40b7c9"
                    onClick={() => {
                      this.handleDetailClick('3', list);
                    }}
                  >
                    {this.renderLabel(0, list)}
                  </LabelContainer>
                  <LabelContainerSmall color="#40b7c9">
                    {this.renderLabelSmall(0, list)}
                  </LabelContainerSmall>
                </ChartLabel>
              ) : (
                <div />
              )}
            </LabelBox>
            <LabelBox>
              {list[1] ? (
                <ChartLabel eventkey="0" color="#18d8ee">
                  <LabelContainer
                    color="#18d8ee"
                    onClick={() => {
                      this.handleDetailClick('0', list);
                    }}
                  >
                    {this.renderLabel(1, list)}
                  </LabelContainer>
                  <LabelContainerSmall color="#18d8ee">
                    {this.renderLabelSmall(1, list)}
                  </LabelContainerSmall>
                </ChartLabel>
              ) : (
                <div />
              )}
            </LabelBox>
          </Row>
          <Row>
            <LabelBox single={list.length === 3}>
              {list[2] ? (
                <ChartLabel
                  eventkey="2"
                  color={handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2de8bf')}
                >
                  <LabelContainer
                    color={handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2de8bf')}
                    onClick={() => {
                      this.handleDetailClick('2', list);
                    }}
                  >
                    {this.renderLabel(2, list)}
                  </LabelContainer>
                  <LabelContainerSmall
                    color={handleThemeFromObject(theme, 'colors.lightGreenGraph', '#2de8bf')}
                  >
                    {this.renderLabelSmall(2, list)}
                  </LabelContainerSmall>
                </ChartLabel>
              ) : (
                <div />
              )}
            </LabelBox>
            <LabelBox>
              {list[3] ? (
                <ChartLabel eventkey="1" color="#537e86">
                  <LabelContainer
                    color="#537e86"
                    onClick={() => {
                      this.handleDetailClick('1', list);
                    }}
                  >
                    {this.renderLabel(3, list)}
                  </LabelContainer>
                  <LabelContainerSmall color="#537e86">
                    {this.renderLabelSmall(3, list)}
                  </LabelContainerSmall>
                </ChartLabel>
              ) : (
                <div />
              )}
            </LabelBox>
          </Row>
          <Total fade={this.state.hightlight}>
            Total:
            <ChippedText value={invoices}>Títulos</ChippedText>
            <ChippedText value={data.length}>Sacado{data.length > 1 ? 's' : ''}</ChippedText>
          </Total>
        </Labels>
      </Container>
    );
  };

  renderEmptyState = () => (
    <EmptyStateContainer>
      <Donut />
      <EmptyStateInfoBox>
        <EmptyStatePrimaryText>
          Você não possui nenhum título em carteira no momento
        </EmptyStatePrimaryText>
      </EmptyStateInfoBox>
    </EmptyStateContainer>
  );

  render() {
    const { loading, data } = this.props;
    const { list, invoices } = this.preprocessData(data);
    return (
      <>
        <Card
          ref={element => {
            this.container = element;
          }}
          width="600px"
          height="390px"
        >
          <CardHeader>DIVERSIFICAÇÃO</CardHeader>
          <FlexContainer>
            <LoadingBlock loading={loading}>
              {data && data.length > 0
                ? this.renderChart(list, invoices, data)
                : this.renderEmptyState()}
            </LoadingBlock>
          </FlexContainer>
        </Card>
      </>
    );
  }
}

Distribution.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  getDistributionData: PropTypes.func.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
  getDialogContent: PropTypes.func,
};

Distribution.defaultProps = {
  theme: {},
  getDialogContent: () => {},
};

export default Distribution;
