import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { PrimaryButton } from 'liber-components';

import {
  FlexColumn,
  Title,
  Subtitle,
  StatusSelect,
  SpaceBetween,
  Image,
  InvoiceNumberField,
} from './VendorReports.styles';
import { subscribeReports, getReport } from '../actions/VendorReportsActions';
import LoadingButton from '../../../common-components/Buttons/LoadingButton';
import RangeSelect from './RangeSelect';
import { INVOICE_STATUSES, DATE_FIELDS } from '../constants';
import WarningModal from './WarningModal';

moment.locale('pt-BR');

export const VendorReports = ({ subscribe, generateReport, loading }) => {
  React.useEffect(() => {
    subscribe();
  }, []);

  const [status, setStatus] = useState(INVOICE_STATUSES[0].value);
  const [number, setNumber] = useState('');

  return (
    <FlexColumn>
      <Title>Relatórios</Title>
      <Subtitle>
        Procurando informações detalhadas sobre os seus títulos? <br />
        Use os filtros abaixo para baixar planilhas de relatórios.
      </Subtitle>
      <SpaceBetween>
        <FlexColumn>
          <InvoiceNumberField value={number} onChange={setNumber} />
          <StatusSelect value={status} onChange={setStatus}>
            {INVOICE_STATUSES.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </StatusSelect>
          {DATE_FIELDS.map(({ filterKey, label }) => (
            <RangeSelect key={filterKey} id={filterKey} label={label} />
          ))}
          <LoadingButton
            ButtonComponent={PrimaryButton}
            size="large"
            loading={loading}
            onClick={() => generateReport({ status, number })}
          >
            BAIXAR
          </LoadingButton>
        </FlexColumn>
        <Image />
      </SpaceBetween>
      <WarningModal />
    </FlexColumn>
  );
};

VendorReports.propTypes = {
  generateReport: PropTypes.func,
  subscribe: PropTypes.func,

  loading: PropTypes.bool,
};

VendorReports.defaultProps = {
  generateReport: () => {},
  subscribe: () => {},
  loading: false,
};

const mapStateToProps = ({ vendorReports: { loading } }) => ({
  loading,
});

const mapDispatchToProps = {
  subscribe: subscribeReports,
  generateReport: getReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorReports);
