import styled from 'styled-components';

export const InvoiceSimulationEmptyStateContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 220px;
  background-size: 100% 100%;
  background-image: url('${props => props.backgroundPath}');
  margin-top: 16px;
`;

export const InvoiceSimulationEmptyStateLeftImage = styled.img`
  min-height: 225px;
  max-height: 225px;
`;

export const InvoiceSimulationEmptyStateRightImage = styled.img`
  margin-bottom: -20px;
  min-height: 225px;
  max-height: 225px;
`;

export const InvoicesimulationEmptyText = styled.div`
  text-align: center;
  flex-grow: 1;
  margin: 0 60px 30px 60px;
`;

export const InvoicesimulationEmptyHeader = styled.h1`
  font-size: 24px;
  color: #4b6f85;
  font-family: Roboto;
  font-weight: 700;
`;

export const InvoicesimulationEmptyParagraph = styled.div`
  font-size: 16px;
  color: #607280;
  font-family: Roboto;
`;
