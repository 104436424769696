import styled, { css, createGlobalStyle } from 'styled-components';
import {
  media,
  FaEnvelope,
  FaLock,
  FaPhone,
  FaUser,
  FaExclamationCircle,
  FaIdCard,
  SimpleLogo,
  handleThemeFromObject,
} from 'liber-components';
import {
  Header2,
  Button,
  LinkButton,
  Link as RawLink,
} from 'liber-components/components/GenericStyles';

import MarcaLiber from '../resources/marca_liber.svg';

// eslint-disable-next-line
export const pattern = 'data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%22120%22 height=%22120%22 viewBox=%220 0 60 60%22%3E%3Cdefs%3E%3Cpattern id=%22pattern%22 patternUnits=%22userSpaceOnUse%22 width=%224%22 height=%224%22%3E%3Cpath d=%22M3,-1 l2,2 M0,0 l4,4 M-1,3 l2,2%22 style=%22stroke:%23ffffff; stroke-width:0.2;opacity:0.5%22 /%3E%3C/pattern%3E%3C/defs%3E%3Crect x=%220%22 y=%220%22 width=%22100%25%22 height=%22100%25%22 fill=%22url%28%23pattern%29%22/%3E%3C/svg%3E';

export const Global = createGlobalStyle`
  .login_react_container {
    width: 100vw;
    min-height: 100vh;
    overflow: auto;
    background: #EEF1F7B2;
    box-sizing: border-box;
  }
  .ease-in-enter {
    opacity: 0.01;
  }

  .ease-in-enter.ease-in-enter-active {
    opacity: 1;
    transition: opacity 300s ease-in;
  }

  .ease-in-exit {
    opacity: 1;
  }

  .ease-in-exit.ease-in-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

export const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;

  @media (min-width: 576px) {
    justify-content: flex-start;
    padding: 25px;
  }
  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 25px;
  }
  @media (min-width: 992px) {
    justify-content: center;
    padding: 0 50px;
  }
  @media (min-width: 1200px) {
    justify-content: center;
    padding: 0 50px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 0px;
  opacity: 0.7;
  border: solid 2px ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  border-bottom: 0px;
  margin-bottom: 16px;
`;

export const KycLogin = styled.div`
  width: 100%;

  ${media.medium`
    width: 380px;
  `}
`;

export const KycIcon = styled.div`
  margin: auto;
  width: 60px;
  height: 60px;
  background-color: rgba(164, 164, 164, 0.3);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IdCard = styled(FaIdCard)`
  width: 35px;
  height: 35px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
`;

export const Lock = styled(FaLock)`
  width: 25px;
  height: 25px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const SignUpIdBox = styled.div`
  margin: auto;
  background-color: rgba(230, 230, 230, 0.4);
  padding: 15px 5px;
  text-align: left;

  ${media.medium`
    padding: 15px;
  `}
`;

export const SignUpSafeBox = styled.div`
  margin: auto;
  position: relative;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  text-align: center;
  margin: 30px 25px;

  & > div {
    margin-left: 35px;
  }
`;

export const LoginCard = styled.div`
  border-radius: 2px;
  background-color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  flex-flow: column-reverse;
  margin-top: 50px;
  margin-bottom: 16px;
  overflow: hidden;

  @media (min-width: 576px) {
    flex-flow: column-reverse;
    padding: 24px;
    width: 476px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    flex-flow: column-reverse;
    padding: 24px;
    width: 476px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
    margin-bottom: 16px;
  }
  @media (min-width: 992px) {
    flex-flow: row;
    padding: ${props => (props.padding ? props.padding : '48px')};
    width: unset;
    margin-bottom: 50px;
  }
  @media (min-width: 1200px) {
    flex-flow: row;
    padding: ${props => (props.padding ? props.padding : '48px')};
    width: unset;
    margin-bottom: 50px;
  }
`;

export const Title = styled(Header2)`
  margin: 0px !important;
  line-height: 25px;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')} !important;
  @media (min-width: 576px) {
    line-height: 35px;
  }
  @media (min-width: 768px) {
    line-height: 35px;
  }
  @media (min-width: 992px) {
    line-height: 35px;
  }
  @media (min-width: 1200px) {
    line-height: 35px;
  }
`;

export const LoginContent = styled.div`
  width: 100%;
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: ${props => (props.width ? props.width : '351.8px')};
  }
  @media (min-width: 1200px) {
    width: ${props => (props.width ? props.width : '351.8px')};
  }
`;

export const LoginLastContent = styled(LoginContent)`
  display: flex;
`;

export const LoginSeparator = styled.div`
  width: unset;
  height: 0px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  border-left: 0px;
  margin: 24px 0px;
  align-self: stretch;
  @media (min-width: 576px) {
    width: unset;
    height: 0px;
    margin: 24px 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    border-left: 0px;
  }
  @media (min-width: 768px) {
    width: unset;
    height: 0px;
    margin: 24px 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    border-left: 0px;
  }
  @media (min-width: 992px) {
    width: 0px;
    height: unset;
    margin: 0px 48px;
    border-bottom: 0px;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 1200px) {
    width: 0px;
    height: unset;
    margin: 0px 48px;
    border-bottom: 0px;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
  }
`;

export const BunnyBox = styled.div`
  width: 100%;
  height: 162px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Bunny = styled(SimpleLogo)`
  display: inline-block;

  margin: 0px 0px 20px 0px;

  & > svg {
    width: 72.9px;
    height: 58.8px;

    @media (min-width: 576px) {
      width: 72.9px;
      height: 58.8px;
    }
    @media (min-width: 768px) {
      width: 72.9px;
      height: 58.8px;
    }
    @media (min-width: 992px) {
      width: 109.3px;
      height: 88.2px;
    }
    @media (min-width: 1200px) {
      width: 109.3px;
      height: 88.2px;
    }
  }

  & .cls-1 {
    stroke: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  }
  & .cls-2 {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  }
`;

export const Logo = styled.img.attrs({ alt: 'liber logo', src: MarcaLiber })`
  margin: 48px;
  width: 96px;
`;

export const BunnyLogo = styled(SimpleLogo)`
  display: inline-block;
  background-color: #fff;
  margin: 0px 0px 20px 0px;

  & > svg {
    width: 350px;
    height: 120px;

    ${media.medium`
      width: 350px;
      height: 250px;
    `}
  }

  & .cls-1 {
    stroke: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
    fill: #fff;
  }
  & .cls-2 {
    stroke: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
    fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  }
`;

export const BunnyGrow = styled.div`
  flex-grow: 1;
`;

export const BunnyText = styled.ul`
  list-style: none;
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  min-width: 100%;
  padding: 0px;
  margin: 0px 0px 20px 0px;

  @media (min-width: 576px) {
    text-align: center;
    min-width: 100%;
    flex-grow: 1;
  }
  @media (min-width: 768px) {
    text-align: center;
    min-width: 100%;
    flex-grow: 1;
  }
  @media (min-width: 992px) {
    text-align: left;
    min-width: 220.69px;
    flex-grow: 0;
  }
  @media (min-width: 1200px) {
    text-align: left;
    min-width: 220.69px;
    flex-grow: 0;
  }

  & > li {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {
      justify-content: center;
    }
    @media (min-width: 768px) {
      justify-content: center;
    }
    @media (min-width: 992px) {
      justify-content: flex-start;
    }
    @media (min-width: 1200px) {
      justify-content: flex-start;
    }
  }
`;

const icon = css`
  width: 16px;
  height: 16px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  margin-right: 10px;
`;

export const Envelope = styled(FaEnvelope)`
  ${icon}
`;

export const Phone = styled(FaPhone)`
  ${icon}
`;

export const User = styled(FaUser)`
  ${icon}
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
`;

export const Exclamation = styled(FaExclamationCircle)`
  ${icon}
  fill: ${props => handleThemeFromObject(props.theme, 'colors.dangerRed', '#dc3545', props.color)};
  flex-basis: 10%;
`;

export const Link = styled(RawLink)`
  text-align: center;
  display: block;
`;

export const BlueLink = styled(Link)`
  margin-top: 15px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
`;

export const WhiteLink = styled(Link)`
  color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 16px;
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
    margin: 7.5px;
  }
  @media (min-width: 576px) {
    flex-wrap: wrap;
  }
  @media (min-width: 768px) {
    flex-wrap: wrap;
  }
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

export const ButtonGreen = styled(Button)`
  background-color: ${props =>
    handleThemeFromObject(props.theme, 'colors.successGreen', '#13ce66')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  &:hover {
    background-color: #009c39;
  }

  &:disabled {
    background: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
    cursor: not-allowed;
  }
`;
export const ButtonBlue = styled(LinkButton)`
  background-color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')} !important;
  border: 2px solid ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  &:hover {
    background-color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
    color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')} !important;
    border: 0px;
  }
`;

export const RadioDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InfoText = styled.p`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
`;

export const ObsText = styled.p`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  margin-top: 25px;
`;

export const ErrorText = styled.div`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.dangerRed', '#dc3545')};
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 15px 0px;
`;

export const NoticeText = styled.div`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SignUpCard = styled(LoginCard)`
  overflow: ${props => (props.grow ? 'hidden' : 'unset')};
`;

export const FieldDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;

  & span {
    display: none;
    width: 18px;
    @media (min-width: 576px) {
      display: none;
    }
    @media (min-width: 768px) {
      display: none;
    }
    @media (min-width: 992px) {
      display: inline;
    }
    @media (min-width: 1200px) {
      display: inline;
    }
  }

  @media (min-width: 576px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 992px) {
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const CenterCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
