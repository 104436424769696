import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, TextField } from 'liber-components';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Hr } from 'liber-components/components/GenericStyles';
import Flash from '../Flash';
import {
  Title,
  ButtonGreen,
  LoginContent,
  SignUpCard,
  BlueLink,
  ButtonDiv,
  FieldDiv,
  Image,
} from '../styles';
import { HiddenInput } from '../Login/Login.styles';
import { getSubdomain } from '../../../../vendor/Utils';

const FornecedorSignUp = ({
  email,
  fullName,
  companyName,
  tradeName,
  cnpj,
  phone,
  ramal,
  cellphone,
  emailError,
  fullNameError,
  companyNameError,
  tradeNameError,
  cnpjError,
  phoneError,
  changeEmail,
  changeFullName,
  changeCompanyName,
  changeTradeName,
  changeCnpj,
  changePhone,
  changeRamal,
  changeCellphone,
  submitForm,
  flashes,
  path,
  originPath,
  logoUrl,
  portalId,
  reCaptchaToken,
  setReCaptchaToken,
}) => {
  const updateCaptcha = useCallback(setReCaptchaToken, [reCaptchaToken]);
  return (
    <SignUpCard>
      <LoginContent width="380px">
        <Image alt={`Logo de ${getSubdomain()}`} src={logoUrl} />
        <Hr />
        <Title>FORNECEDOR</Title>
        <Flash flashes={flashes} />
        <Form
          action={path}
          method="POST"
          onSubmit={event => {
            submitForm(event, email, fullName, companyName, tradeName, cnpj, phone);
          }}
        >
          <input
            value={portalId}
            type="hidden"
            name="fornecedor[portal_id]"
            id="fornecedor_portal_id"
          />
          <TextField
            fullWidth
            label="CNPJ"
            type="text"
            value={cnpj}
            errorMessage={cnpjError}
            onChange={value => changeCnpj(value)}
            name="fornecedor[cnpj]"
            id="fornecedor_cnpj"
            mask="99.999.999/9999-99"
            maskChar={null}
          />
          <TextField
            fullWidth
            type="text"
            label="Email"
            value={email}
            errorMessage={emailError}
            onChange={value => changeEmail(value)}
            name="fornecedor[email]"
            id="fornecedor_email"
          />
          <TextField
            fullWidth
            type="text"
            label="Nome para Contato"
            value={fullName}
            errorMessage={fullNameError}
            onChange={value => changeFullName(value)}
            name="fornecedor[name]"
            id="fornecedor_name"
          />
          <TextField
            fullWidth
            type="text"
            label="Razão Social"
            value={companyName}
            errorMessage={companyNameError}
            onChange={value => changeCompanyName(value)}
            name="fornecedor[company]"
            id="fornecedor_company"
          />
          <TextField
            fullWidth
            type="text"
            label="Nome Fantasia"
            value={tradeName}
            errorMessage={tradeNameError}
            onChange={value => changeTradeName(value)}
            name="fornecedor[trade_name]"
            id="fornecedor_trade_name"
          />
          <FieldDiv>
            <TextField
              fullWidth
              type="text"
              label="Telefone"
              value={phone}
              errorMessage={phoneError}
              onChange={value => changePhone(value)}
              name="fornecedor[phone]"
              id="fornecedor_phone"
              mask={phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
              maskChar={null}
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            />
            <span />
            <TextField
              fullWidth
              type="number"
              label="Ramal"
              value={ramal}
              onChange={value => changeRamal(value)}
              name="fornecedor[branch_line]"
              id="fornecedor_branch_line"
            />
          </FieldDiv>
          <TextField
            fullWidth
            type="text"
            label="Celular"
            value={cellphone}
            onChange={value => changeCellphone(value)}
            name="fornecedor[cellphone]"
            id="fornecedor_cellphone"
            mask={cellphone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
            maskChar={null}
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
          />
          <GoogleReCaptcha onVerify={updateCaptcha} />
          <HiddenInput
            value={reCaptchaToken}
            readOnly
            id="g_recaptcha_response"
            name="fornecedor[g_recaptcha_response]"
          />
          <ButtonDiv>
            <ButtonGreen disabled={!reCaptchaToken || reCaptchaToken.length === 0} width="100%">
              Cadastrar
            </ButtonGreen>
          </ButtonDiv>
          <BlueLink href={originPath}>Voltar para o Login</BlueLink>
        </Form>
      </LoginContent>
    </SignUpCard>
  );
};

FornecedorSignUp.propTypes = {
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  tradeName: PropTypes.string.isRequired,
  cnpj: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  cellphone: PropTypes.string.isRequired,
  ramal: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  fullNameError: PropTypes.string.isRequired,
  companyNameError: PropTypes.string.isRequired,
  tradeNameError: PropTypes.string.isRequired,
  cnpjError: PropTypes.string.isRequired,
  phoneError: PropTypes.string.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changeFullName: PropTypes.func.isRequired,
  changeCompanyName: PropTypes.func.isRequired,
  changeTradeName: PropTypes.func.isRequired,
  changeCnpj: PropTypes.func.isRequired,
  changePhone: PropTypes.func.isRequired,
  changeRamal: PropTypes.func.isRequired,
  changeCellphone: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  path: PropTypes.string.isRequired,
  originPath: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  portalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setReCaptchaToken: PropTypes.func.isRequired,
  reCaptchaToken: PropTypes.string.isRequired,
};

export default FornecedorSignUp;
