import React from 'react';
import { Typography, Theme } from 'liber-salti';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Container, TextWrapper, Logo } from './styles';

const Footer = () => {
  const muiTheme = createTheme(Theme);

  return (
    <ThemeProvider theme={muiTheme}>
      <Container>
        <Logo />
        <TextWrapper>
          <Typography variant="overline" color="textSecondary">
            CONTATO
          </Typography>
          <Typography variant="body2" color="textSecondary">
            contato@libercapital.com.br
          </Typography>
          <Typography variant="body2" color="textSecondary">
            +55 (11) 3534-7808
          </Typography>
        </TextWrapper>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;
