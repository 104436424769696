import PropTypes from 'prop-types';
import React from 'react';
import CreateBuyerOrganizationGroup from '../create';

const EditBuyerOrganizationGroup = ({ token, editGroupId }) => (
  <CreateBuyerOrganizationGroup token={token} editGroupId={editGroupId} />
);

EditBuyerOrganizationGroup.propTypes = {
  token: PropTypes.string.isRequired,
  editGroupId: PropTypes.string,
};

EditBuyerOrganizationGroup.defaultProps = {
  editGroupId: undefined,
};

export default EditBuyerOrganizationGroup;
