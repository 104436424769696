import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const HandleBlockUnblockModalContext = createContext({});

const BlockUnblockModalProvider = ({
  children,
  selectedFunder,
  handleSelectedFunderToBlockOrUnblock,
  handleLeave,
}) => (
  <HandleBlockUnblockModalContext.Provider
    value={{ selectedFunder, handleSelectedFunderToBlockOrUnblock, handleLeave }}
  >
    {children}
  </HandleBlockUnblockModalContext.Provider>
);

BlockUnblockModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  selectedFunder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    action: PropTypes.oneOf(['block', 'unblock']),
    target: {
      name: PropTypes.string,
      id: PropTypes.number,
    },
  }).isRequired,
  handleSelectedFunderToBlockOrUnblock: PropTypes.func.isRequired,
  handleLeave: PropTypes.func.isRequired,
};

export default BlockUnblockModalProvider;
