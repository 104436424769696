import React, { useContext } from 'react';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import * as S from './styles';
import { DetailsDrawerContext } from '../../context';

const SpreadsheetDetails = () => {
  const {
    spreadsheet: {
      uploaderName,
      uploadMethod,
      status,
      buyersCount,
      vendorsGroupsCount,
      fileUrl,
      createdAt,
    },
  } = useContext(DetailsDrawerContext);

  const date = new Date(createdAt);
  const formattedDate = date.toLocaleDateString('pt-BR');
  const hoursAndMinutes = `${date.getHours()}:${date.getMinutes()}`;

  return (
    <>
      <S.Subtitle>Informações do envio</S.Subtitle>
      <S.Row>
        <div>
          <S.Label>Enviado por</S.Label>
          <S.Info>{uploaderName}</S.Info>
        </div>
        <div>
          <S.Label>Data</S.Label>
          <S.Info>{formattedDate}</S.Info>
        </div>
        <div>
          <S.Label>Horário</S.Label>
          <S.Info>{hoursAndMinutes}</S.Info>
        </div>
        <div>
          <S.Label>Forma de envio</S.Label>
          <S.Info>{uploadMethod}</S.Info>
        </div>
        <div>
          <S.Label>Status</S.Label>
          <S.StatusBadge status={status}>{status}</S.StatusBadge>
        </div>
      </S.Row>
      <S.Subtitle>Enviado para</S.Subtitle>
      <S.Row>
        <div>
          <S.Label>Sacados</S.Label>
          <S.Info>{buyersCount}</S.Info>
        </div>
        <div>
          <S.Label>Grupos</S.Label>
          <S.Info>{vendorsGroupsCount}</S.Info>
        </div>
        <div>
          <S.Label>Arquivo</S.Label>
          <S.DownloadLink href={fileUrl}>
            <Icon path={mdiDownload} /> Baixar planilha com taxas
          </S.DownloadLink>
        </div>
      </S.Row>
    </>
  );
};

export default SpreadsheetDetails;
