export const apiUrl = SERVICE_API_URL; // eslint-disable-line
const { pathname } = new URL(window.location.href);

const userPersona = pathname !== `/admin/remessas/new_collection` ? 'investidor' : 'admin';
const backAccountsRoute =
  pathname !== '/admin/remessas/new_collection' ? 'bank_accounts' : 'conta_bancarias/liber';

export const ADMIN_FETCH_TRADES = `${apiUrl}/api/v1/${userPersona}/remittances`;
export const ADMIN_FETCH_ACCOUNT = `${apiUrl}/api/v1/${userPersona}/${backAccountsRoute}`;
export const FETCH_REMITTANCE = `${apiUrl}/api/v1/${userPersona}/remittances`;
