import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, TextField } from 'liber-components';
import { connect } from 'react-redux';
import {
  changeEmail as changeEmailAction,
  submitRecovery as submitRecoveryAction,
} from '../../actions/passwordRecoveryActions';
import Flash from '../Flash';
import {
  BlueLink,
  Title,
  ButtonGreen,
  InfoText,
  ButtonDiv,
  LoginContent,
  LoginCard,
} from '../styles';

export class PasswordRecovery extends Component {
  componentDidMount() {
    const { contextEmail, changeEmail } = this.props;
    if (contextEmail) {
      changeEmail(contextEmail);
    }
  }

  render() {
    const { origin, path, flashes, passwordPath, email, emailError, changeEmail, submitRecovery } =
      this.props;
    const recovery = (
      <LoginCard>
        <LoginContent width="467px">
          <Title>ESQUECEU SUA SENHA?</Title>
          <InfoText>
            Sem problemas! Preencha seu email no espaço abaixo e nós enviaremos as instruções para
            alteração da senha.
          </InfoText>
          <Flash flashes={flashes} />
          <Form
            action={passwordPath}
            method="POST"
            onSubmit={event => {
              submitRecovery(event, email);
            }}
          >
            <TextField
              fullWidth
              label="Email"
              errorMessage={emailError}
              value={email}
              onChange={value => changeEmail(value)}
              name={`${origin}[email]`}
              id={`${origin}_email`}
            />
            <ButtonDiv>
              <ButtonGreen width="100%">Recuperar Senha</ButtonGreen>
            </ButtonDiv>
            <BlueLink href={path}>Voltar para o Login</BlueLink>
          </Form>
        </LoginContent>
      </LoginCard>
    );
    return recovery;
  }
}

PasswordRecovery.propTypes = {
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  changeEmail: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  origin: PropTypes.string.isRequired,
  submitRecovery: PropTypes.func.isRequired,
  contextEmail: PropTypes.string,
};

PasswordRecovery.defaultProps = {
  contextEmail: '',
};

const mapStateToProps = state => ({
  email: state.recovery.email,
  emailError: state.recovery.emailError,
  sended: state.recovery.sended,
  path: state.recovery.path,
  flashes: state.recovery.flashes,
  passwordPath: state.recovery.passwordPath,
  origin: state.recovery.origin,
  contextEmail: state.recovery.contextEmail,
});

const mapDispatchToProps = {
  changeEmail: changeEmailAction,
  submitRecovery: submitRecoveryAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
