import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from './styles';

function ProgressBar({ progressed, total, width }) {
  const [targetProgress, setTargetProgress] = useState(0);

  useEffect(() => {
    setTargetProgress(progressed);
  }, []);

  return (
    <Bar progressed={targetProgress} total={total} width={width}>
      <div />
    </Bar>
  );
}

ProgressBar.propTypes = {
  progressed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProgressBar.defaultProps = {
  progressed: 0,
  total: 0,
  width: 200,
};

export default ProgressBar;
