import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash';
import { MdCheck as Check } from 'liber-components';
import CheckImage from '../../../../../assets/images/check.svg';
import {
  ButtonsRow,
  PrimaryButton,
  SteperContainer,
  Step,
  Separator,
  Title,
  Text,
  InfoContainer,
  StepTitle,
  StepCount,
  Image,
  ImageContainer,
} from './AnticipationCheckout.styles';
import {
  setCurrentStep,
  setIsDocumentReady,
  subscribeAnticipation,
  subscribeToInvoices,
  generateAnticipation,
  stepBackButton,
  cleanAnticipationUrl,
  subscribeToLimits,
} from '../actions/AnticipationCheckoutActions';
import { getSteps, STEPS } from '../steps';
import { clearCurrentState } from '../cookieUtils';
import { paymentDateIsGreaterThanToday } from '../../../vendor/Utils';
import UnavailableLimitDialog from '../../../common-components/UnavailableLimitDialog';
import { ANTICIPATIONS_PAGE_URL } from '../urls';
import {
  anticipateInvoices,
  toggleInvoiceSelection,
} from '../../../common-components/VendorInvoices/actions/VendorInvoicesActions';

export const AnticipationCheckout = ({
  currentStep,
  onSetCurrentStep,
  isDocumentReady,
  onSubscribeAnticipation,
  showSummary,
  wasDocumentGenerated,
  subscribeInvoices,
  anticipationHasFailed,
  startAnticipationGeneration,
  onStepBackButton,
  cleanUrl,
  selectedBankAccountId,
  sentBankAccountId,
  setDocumentReady,
  isAdmin,
  onSubscribeToLimits,
  showLimitError,
  handleCloseLimitDialog,
  anticipation,
  vendorOtpRequired,
  displayBankAccountsCreationInstructions,
  vendor,
  operator,
  netValue,
  buyerIdentifier,
  isBuyerAutomaticOffer,
  toggleInvoice,
  requestAnticipation,
}) => {
  const [steps, setSteps] = React.useState([]);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

  const { payUpAt } = anticipation;

  const paymentNextBusinessDay = useMemo(() => paymentDateIsGreaterThanToday(payUpAt), [payUpAt]);

  const toggleInvoicesUrl = () => {
    const url = new URLSearchParams(window.location.search);
    const invoices = url
      .get('ids')
      .split(',')
      .map(id => ({ id }));
    invoices.forEach(toggleInvoice);
  };

  useEffect(() => {
    onSubscribeAnticipation();
    onSubscribeToLimits();
    subscribeInvoices();
    if (!isBuyerAutomaticOffer) {
      toggleInvoicesUrl();
    }
    cleanUrl(isBuyerAutomaticOffer);
  }, []);
  useEffect(() => {
    if (!isBuyerAutomaticOffer) {
      setSteps([STEPS[1]]);
      return;
    }
    setSteps(
      getSteps(
        showSummary,
        wasDocumentGenerated,
        vendorOtpRequired,
        displayBankAccountsCreationInstructions,
      ),
    );
  }, [
    showSummary,
    wasDocumentGenerated,
    vendorOtpRequired,
    displayBankAccountsCreationInstructions,
  ]);

  useEffect(() => {
    if (steps.length > 0) {
      const index = steps.findIndex(({ name }) => currentStep === name);
      const finalIndex = index < 0 ? 0 : index;

      setCurrentStepIndex(finalIndex);
      if (currentStep === '') {
        onSetCurrentStep(steps[finalIndex].name);
      }
    }
  }, [steps, currentStep]);

  useEffect(() => {
    if (isDocumentReady) {
      window.onbeforeunload = undefined;
    }
  }, [isDocumentReady]);

  const gaParams = useMemo(
    () => ({
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    }),
    [isAdmin, vendor, operator],
  );

  const handleBack = () => {
    if (currentStepIndex === 0) {
      setTimeout(() => {
        window.history.back();
      }, 200);
    } else {
      const step = currentStepIndex - 1;
      onStepBackButton();
      onSetCurrentStep(steps[step].name);
    }
  };

  const handleNext = () => {
    if (!isBuyerAutomaticOffer) {
      requestAnticipation();
      setCurrentStepIndex(currentStepIndex + 1);
      return;
    }

    if (currentStep !== 'Assinatura') {
      const step = currentStepIndex + 1;

      if (steps[currentStepIndex].name === 'Conta Bancária') {
        if (sentBankAccountId && sentBankAccountId === selectedBankAccountId) {
          setDocumentReady(true);
        } else {
          startAnticipationGeneration();
        }
      }

      onSetCurrentStep(steps[step].name);
    } else {
      setCurrentStepIndex(currentStepIndex + 1);
      clearCurrentState();
    }
  };

  const handleFinalize = useCallback(() => {
    window.location.href = ANTICIPATIONS_PAGE_URL;
  }, [gaParams]);

  const renderContent = () => {
    if (currentStepIndex !== -1) {
      const props = {
        handleBack,
        handleNext,
      };

      if (currentStepIndex === 0) props.backButtonLabel = 'VOLTAR PARA TÍTULOS';

      const Component = steps[currentStepIndex].content;

      return (
        <>
          <InfoContainer>
            <StepCount>{`ETAPA ${currentStepIndex + 1} DE ${steps.length}`}</StepCount>
            <StepTitle>{steps[currentStepIndex].name}</StepTitle>
            {steps[currentStepIndex].description(
              isDocumentReady,
              anticipationHasFailed,
              vendorOtpRequired,
              displayBankAccountsCreationInstructions,
            )}
          </InfoContainer>
          <Component {...props} />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <UnavailableLimitDialog
        show={showLimitError}
        onLeaved={handleCloseLimitDialog}
        redirectUrl={ANTICIPATIONS_PAGE_URL}
      />
      <Title>
        {currentStepIndex === steps.length
          ? 'Antecipação solicitada'
          : steps[currentStepIndex].title}
      </Title>
      {currentStepIndex === steps.length ? (
        <>
          <Text center>
            Sua solicitação de antecipação foi enviada para análise do financiador. <br />
            <strong>
              Caso aprovada, você receberá o valor de {netValue} antecipado <br />
              na sua conta{' '}
              {paymentNextBusinessDay || buyerIdentifier === 'brf'
                ? 'no próximo dia útil.'
                : 'ainda hoje.'}
            </strong>{' '}
            <br />
            Em caso de dúvidas entre em contato com a gente <br />
            contato@libercapital.com.br
          </Text>
          <ImageContainer>
            <Image src={CheckImage} />
          </ImageContainer>
          <ButtonsRow>
            <PrimaryButton onClick={handleFinalize}>FECHAR</PrimaryButton>
          </ButtonsRow>
        </>
      ) : (
        <>
          <Text center>
            A sua antecipação ainda não foi concluída. <br />
            Complete as etapas pendentes para que ela seja realizada.
          </Text>
          {steps.length > 1 ? (
            <SteperContainer>
              {steps.map((step, index) => (
                <Fragment key={`step-${uniqueId()}`}>
                  <Step type={currentStepIndex >= index ? 'checked' : 'basic'}>
                    {currentStepIndex >= index + 1 && <Check />}
                  </Step>
                  {index !== steps.length - 1 && <Separator full={currentStepIndex >= index + 1} />}
                </Fragment>
              ))}
            </SteperContainer>
          ) : (
            <>
              <br />
              <br />
            </>
          )}
          {renderContent()}
        </>
      )}
    </>
  );
};

AnticipationCheckout.propTypes = {
  currentStep: PropTypes.string,
  onSetCurrentStep: PropTypes.func,
  isDocumentReady: PropTypes.bool,
  onSubscribeAnticipation: PropTypes.func,
  showSummary: PropTypes.bool,
  wasDocumentGenerated: PropTypes.bool,
  subscribeInvoices: PropTypes.func,
  anticipationHasFailed: PropTypes.bool,
  startAnticipationGeneration: PropTypes.func,
  onStepBackButton: PropTypes.func,
  cleanUrl: PropTypes.func,
  setDocumentReady: PropTypes.func,
  selectedBankAccountId: PropTypes.number,
  sentBankAccountId: PropTypes.number,
  isAdmin: PropTypes.bool,
  onSubscribeToLimits: PropTypes.func,
  showLimitError: PropTypes.bool,
  handleCloseLimitDialog: PropTypes.func,
  vendorOtpRequired: PropTypes.bool,
  displayBankAccountsCreationInstructions: PropTypes.bool,
  anticipation: PropTypes.shape({
    payUpAt: PropTypes.string,
  }),
  vendor: PropTypes.object,
  operator: PropTypes.object,
  netValue: 'R$ 0,00',
  buyerIdentifier: PropTypes.string,
  isBuyerAutomaticOffer: PropTypes.bool,
  toggleInvoice: PropTypes.func,
  requestAnticipation: PropTypes.func,
};

AnticipationCheckout.defaultProps = {
  currentStep: '',
  onSetCurrentStep: () => null,
  isDocumentReady: true,
  onSubscribeAnticipation: () => null,
  showSummary: false,
  wasDocumentGenerated: false,
  subscribeInvoices: () => null,
  anticipationHasFailed: false,
  vendorOtpRequired: false,
  displayBankAccountsCreationInstructions: true,
  startAnticipationGeneration: () => null,
  onStepBackButton: () => null,
  cleanUrl: () => null,
  setDocumentReady: () => {},
  selectedBankAccountId: null,
  sentBankAccountId: null,
  isAdmin: false,
  onSubscribeToLimits: () => {},
  showLimitError: false,
  handleCloseLimitDialog: () => {},
  anticipation: {
    payUpAt: '',
  },
  vendor: {},
  operator: {},
  netValue: 'R$ 0,00',
  buyerIdentifier: 'default',
  isBuyerAutomaticOffer: true,
  toggleInvoice: () => null,
  requestAnticipation: () => null,
};

const mapStateToProps = ({
  anticipationCheckout: {
    currentStep,
    isDocumentReady,
    showSummary,
    wasDocumentGenerated,
    anticipationHasFailed,
    selectedBankAccountId,
    sentBankAccountId,
    isAdmin,
    showLimitError,
    anticipation,
    vendorOtpRequired,
    displayBankAccountsCreationInstructions,
    buyerIdentifier,
    isBuyerAutomaticOffer,
  },
  vendorAdvances: { vendor, operator },
  anticipationCheckout: {
    anticipation: {
      total: { netValue },
    },
  },
}) => ({
  currentStep,
  isDocumentReady,
  showSummary,
  wasDocumentGenerated,
  anticipationHasFailed,
  selectedBankAccountId,
  sentBankAccountId,
  isAdmin,
  showLimitError,
  anticipation,
  vendorOtpRequired,
  displayBankAccountsCreationInstructions,
  vendor,
  operator,
  netValue,
  buyerIdentifier,
  isBuyerAutomaticOffer,
});

const mapDispatchesToProps = {
  onSetCurrentStep: setCurrentStep,
  onSetDocumentReady: setIsDocumentReady,
  onStepBackButton: stepBackButton,
  onSubscribeAnticipation: subscribeAnticipation,
  subscribeInvoices: subscribeToInvoices,
  startAnticipationGeneration: generateAnticipation,
  cleanUrl: cleanAnticipationUrl,
  setDocumentReady: setIsDocumentReady,
  onSubscribeToLimits: subscribeToLimits,
  toggleInvoice: toggleInvoiceSelection,
  requestAnticipation: anticipateInvoices,
};

export default connect(mapStateToProps, mapDispatchesToProps)(AnticipationCheckout);
