import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import Cookie from 'js.cookie';
import hydrateStore from '../store';
import ConfirmationHistory from './components/ConfirmationHistory';

const View = ({ sacadoId }) => (
  <>
    <Provider store={hydrateStore({})}>
      <ThemeProvider theme={LiberV4}>
        <ConfirmationHistory token={Cookie.get('buyer_jwt')} sacadoId={sacadoId} />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  sacadoId: PropTypes.number.isRequired,
};

export default View;
