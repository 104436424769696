import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { ButtonsRow, SecondaryButton, PrimaryButton } from '../AnticipationCheckout.styles';
import Logo from '../../../../../../assets/images/logo.png';
import {
  Card,
  LogoRow,
  ImageLogo,
  ContainerRow,
  Row,
  LeftColumn,
  RightColumn,
  Container,
} from './Summary.styles';
import { fetchBorderosQuote } from '../../actions/AnticipationCheckoutActions';

export const Summary = ({
  backButtonLabel,
  handleBack,
  handleNext,
  operatorName,
  quote,
  onFetchBorderosQuote,
  createdAt,
}) => {
  useEffect(() => {
    onFetchBorderosQuote();
  }, []);

  const { invoiceCount, faceValue, agreedRate, discountValue, netValue } = quote;

  return (
    <>
      <Container>
        <Card>
          <LogoRow>
            <ImageLogo src={Logo} />
          </LogoRow>
          <ContainerRow>
            <Row>
              <LeftColumn>Solicitante</LeftColumn>
              <RightColumn>{operatorName}</RightColumn>
            </Row>
            <Row>
              <LeftColumn>Solicitado em</LeftColumn>
              <RightColumn>
                {createdAt ? moment(createdAt).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}
              </RightColumn>
            </Row>
          </ContainerRow>
          <ContainerRow>
            <Row>
              <LeftColumn>Total de Títulos</LeftColumn>
              <RightColumn>{invoiceCount}</RightColumn>
            </Row>
            <Row>
              <LeftColumn>Valor Bruto</LeftColumn>
              <RightColumn>{faceValue}</RightColumn>
            </Row>
          </ContainerRow>
          <ContainerRow>
            <Row>
              <LeftColumn>Taxa Média</LeftColumn>
              <RightColumn>{agreedRate}</RightColumn>
            </Row>
            <Row>
              <LeftColumn>Desconto</LeftColumn>
              <RightColumn>{discountValue}</RightColumn>
            </Row>
          </ContainerRow>
          <ContainerRow>
            <Row>
              <LeftColumn>Valor Líquido</LeftColumn>
              <RightColumn green>{netValue}</RightColumn>
            </Row>
          </ContainerRow>
        </Card>
      </Container>
      <ButtonsRow>
        <SecondaryButton onClick={handleBack}>{backButtonLabel}</SecondaryButton>
        <PrimaryButton onClick={handleNext}>CONTINUAR</PrimaryButton>
      </ButtonsRow>
    </>
  );
};

Summary.propTypes = {
  backButtonLabel: PropTypes.string,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  operatorName: PropTypes.string,
  quote: PropTypes.shape({
    invoiceCount: PropTypes.string,
    faceValue: PropTypes.string,
    agreedRate: PropTypes.string,
    discountValue: PropTypes.string,
    netValue: PropTypes.string,
  }),
  onFetchBorderosQuote: PropTypes.func,
  createdAt: PropTypes.string,
};

Summary.defaultProps = {
  backButtonLabel: 'VOLTAR',
  handleBack: () => null,
  handleNext: () => null,
  operatorName: '',
  quote: {
    invoiceCount: '0',
    faceValue: 'R$ 0,00',
    agreedRate: '0,00% a.m.',
    discountValue: 'R$ 0,00',
    netValue: 'R$ 0,00',
  },
  onFetchBorderosQuote: () => null,
  createdAt: null,
};

const mapStateToProps = ({
  anticipationCheckout: {
    operator: { name },
    quote,
    anticipation,
  },
}) => ({
  operatorName: name,
  quote,
  createdAt: anticipation?.createdAt || null,
});

const mapDispatchesToProps = {
  onFetchBorderosQuote: fetchBorderosQuote,
};

export default connect(mapStateToProps, mapDispatchesToProps)(Summary);
