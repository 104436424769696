import React from 'react';
import PropTypes from 'prop-types';
import { ListBuyerOrganizationGroups } from '../../features/buyer-organization-groups';

const BuyerOrganizationGroups = ({ token }) => <ListBuyerOrganizationGroups token={token} />;

BuyerOrganizationGroups.propTypes = {
  token: PropTypes.string.isRequired,
};

export default BuyerOrganizationGroups;
