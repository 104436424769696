import styled, { css } from 'styled-components';
import {
  RadioButton,
  SelectField,
  FlatButton,
  PrimaryButton as RawPrimaryButton,
  FlatLink,
} from 'liber-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 28px 24px;
  width: 572px;
`;

export const Title = styled.h6`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.darkText70};
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.p};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.darkText};
  `}
`;

export const Radio = styled(RadioButton)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.p};
  `}
`;

export const Select = styled(SelectField).attrs(() => ({ version: 2 }))``;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const SecondaryButton = styled(FlatButton).attrs(() => ({ size: 'large' }))``;

export const PrimaryButton = styled(RawPrimaryButton).attrs(() => ({
  version: 2,
  size: 'large',
}))``;

export const ExampleFileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DownloadLink = styled(FlatLink).attrs(() => ({ version: 2, size: 'small' }))``;
