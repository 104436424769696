import Immutable from 'seamless-immutable';

const initialState = Immutable({
  password: '',
  passwordError: '',
  confirmation: '',
  passwordPath: '',
  flashes: [],
  origin: '',
  tokenManual: '',
  tokenError: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'RESET_CHANGE_TOKEN':
      return state.merge({ tokenManual: action.payload });
    case 'RESET_CHANGE_PASSWORD':
      return state.merge({ password: action.payload });
    case 'RESET_CHANGE_CONFIRMATION_PASSWORD':
      return state.merge({ confirmation: action.payload });
    case 'RESET_SUCESSFULL_SUBMIT':
      return state;
    case 'RESET_FAILED_SUBMIT':
      return state.merge({ ...action.payload });
    default:
      return state;
  }
};
