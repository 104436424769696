import axios from 'axios';
import ActionCable from 'actioncable';
import { toast } from 'liber-components';

// eslint-disable-next-line no-undef
export const websocketUrl = WEBSOCKET_URL;

if (process.env.NODE_ENV === 'test') {
  axios.defaults.headers.common = {
    'X-CSRF-TOKEN': 'CSRF-TOKEN',
  };
} else {
  axios.defaults.headers.common = {
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  };
}

export const closeDone = () => ({
  type: 'PROPOSAL_CLOSE_DONE',
});

export const showConfirmation = () => ({
  type: 'PROPOSAL_SHOW_CONFIRMATION',
});

export const hideConfirmation = () => ({
  type: 'PROPOSAL_HIDE_CONFIRMATION',
});

export const addCheckedFromSummary = () => (dispatch, getState) => {
  dispatch({
    type: 'PROPOSAL_ADD_CHECK_FROM_SUMMARY',
    payload: getState().multiProposal.summary.summary.negotiations,
  });
};

export const updateSkipOperations = skipOparations => dispatch => {
  dispatch({ type: 'SKIP_OPERATIONS_ACCEPT', payload: skipOparations });
};

export const funderSelection = funder => (dispatch, getState, endpoints) => {
  dispatch({
    type: 'PROPOSAL_SELECT_FUNDER_PENDING',
    payload: 'Buscando negociações do investidor',
  });
  return axios
    .post(endpoints.funder.path, {
      funder_id: funder,
    })
    .then(({ data }) => {
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_SUCCESS',
        payload: {
          ...data,
          funder,
        },
      });
    })
    .catch(error => {
      const { status, data } = error.response;
      if (status === 404) {
        toast('Investidor não encontrado.', 'error', 8000);
      } else {
        toast(data, 'error', 8000);
      }
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_ERROR',
      });
    });
};

export const funderUpdate = (oldFunder, newFunder) => (dispatch, getState, endpoints) => {
  dispatch({
    type: 'PROPOSAL_SELECT_FUNDER_PENDING',
    payload: 'Buscando negociações do investidor',
  });
  return axios
    .put(`${endpoints.funder.path}/${oldFunder}`, {
      funder_id: newFunder,
    })
    .then(({ data }) => {
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_SUCCESS',
        payload: {
          ...data,
          funder: newFunder,
        },
      });
    })
    .catch(error => {
      const { status, data } = error.response;
      if (status === 404) {
        toast(
          'Investidor não encontrado! Recarregue sua página (F5) e tente novamente.',
          'error',
          8000,
        );
      } else {
        toast(data, 'error', 8000);
      }
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_ERROR',
      });
    });
};

export const funderRemove = funder => (dispatch, getState, endpoints) => {
  dispatch({
    type: 'PROPOSAL_SELECT_FUNDER_PENDING',
    payload: 'Buscando negociações',
  });
  return axios
    .delete(`${endpoints.funder.path}/${funder}`)
    .then(({ data }) => {
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_SUCCESS',
        payload: {
          ...data,
          funder: '',
        },
      });
    })
    .catch(error => {
      const { status, data } = error.response;
      if (status === 404) {
        toast(
          'Investidor não encontrado! Recarregue sua página (F5) e tente novamente.',
          'error',
          8000,
        );
      } else {
        toast(data, 'error', 8000);
      }
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_ERROR',
      });
    });
};

export const selectNegotiations =
  (negotiations, indexes, funder) => (dispatch, getState, endpoints) => {
    dispatch({
      type: 'PROPOSAL_SELECT_NEGOTIATIONS_PENDING',
      payload: indexes,
    });
    return axios
      .patch(endpoints.add_bill_to_cart.path, {
        negotiation_ids: negotiations,
        funder_id: funder,
      })
      .then(({ data }) => {
        dispatch({
          type: 'PROPOSAL_SELECT_NEGOTIATIONS_SUCCESS',
          payload: data.summary,
        });
      })
      .catch(error => {
        const { status, data } = error.response;
        if (status === 404) {
          toast(
            'Investidor não encontrado! Recarregue sua página (F5) e tente novamente.',
            'error',
            8000,
          );
        } else {
          toast(data, 'error', 8000);
        }
        dispatch({
          type: 'PROPOSAL_SELECT_NEGOTIATIONS_ERROR',
          payload: indexes,
        });
      });
  };

export const unselectNegotiations =
  (negotiations, indexes, funder) => (dispatch, getState, endpoints) => {
    dispatch({
      type: 'PROPOSAL_UNSELECT_NEGOTIATIONS_PENDING',
      payload: indexes,
    });
    return axios
      .patch(endpoints.remove_bill_from_cart.path, {
        negotiation_ids: negotiations,
        funder_id: funder,
      })
      .then(({ data }) => {
        dispatch({
          type: 'PROPOSAL_UNSELECT_NEGOTIATIONS_SUCCESS',
          payload: data.summary,
        });
      })
      .catch(error => {
        const { status, data } = error.response;
        if (status === 404) {
          toast(
            'Investidor não encontrado! Recarregue sua página (F5) e tente novamente.',
            'error',
            8000,
          );
        } else {
          toast(data, 'error', 8000);
        }
        dispatch({
          type: 'PROPOSAL_UNSELECT_NEGOTIATIONS_ERROR',
          payload: indexes,
        });
      });
  };

export const cableConnect = (conn, channel, data, dispatch) => () => {
  dispatch({
    type: 'PROPOSAL_SUBMIT_PROPOSALS_PENDING',
    payload: 'Enviando propostas',
  });
  conn.channel[channel].send(data);
};

export const cableReceived =
  (conn, dispatch, funder, endpoints) =>
  ({ data }) => {
    axios.delete(`${endpoints.funder.path}/${funder}`).then(({ data: deleteData }) => {
      dispatch({
        type: 'PROPOSAL_SELECT_FUNDER_SUCCESS',
        payload: {
          ...deleteData,
          funder: '',
        },
      });
    });
    dispatch({
      type: 'PROPOSAL_SUBMIT_PROPOSALS_SUCCESS',
      payload: {
        ...JSON.parse(data),
        funder,
      },
    });
    conn.cable.disconnect();
  };

export const submitProposal =
  (funderId, negotiationsIds, skipAcceptance, specialRate, specialRateType) =>
  (dispatch, getState, endpoints) => {
    const conn = {};
    const channel = 'AdminMultiOfferChannel';
    conn.cable = ActionCable.createConsumer(websocketUrl);

    conn.channel = {
      [channel]: conn.cable.subscriptions.create(
        { channel },
        {
          connected: cableConnect(
            conn,
            channel,
            {
              funder_id: funderId,
              negotiation_ids: negotiationsIds,
              skip_acceptance: skipAcceptance,
              special_rate: specialRate,
              special_rate_type: specialRateType,
            },
            dispatch,
          ),
          received: cableReceived(conn, dispatch, funderId, endpoints),
        },
      ),
    };
  };
