import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssinaMeSDK from 'assiname-sdk-js';
import _ from 'lodash';
import { Modal } from 'liber-components';
import {
  ModalContent,
  ModalButtons,
  BlueButton,
  WhiteButton,
  Center,
} from 'liber-components/components/GenericStyles';
import {
  ErrorBox,
  ExclamationIconSmall,
  ErrorCircleIconSmall,
  ErrorTitle,
  ErrorMsg,
  WindowsIcon,
  ChromeIcon,
  FirefoxIcon,
  IconLink,
  ErrorModalContainer,
} from '../../../styles';

class SupportErrors extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      modal: '',
    };
  }

  handleExtension = event => {
    event.preventDefault();
    const browser = AssinaMeSDK.Utils.currentBrowser();
    this.setState({ show: true, modal: `extension-${browser}` });
  };

  handleNative = event => {
    event.preventDefault();
    const browser = AssinaMeSDK.Utils.currentBrowser();
    const os = AssinaMeSDK.Utils.currentOS();
    this.setState({ show: true, modal: `native-${browser}-${os}` });
  };

  template = ({ children, href, target, onClick, column = false }) => (
    <ErrorModalContainer>
      <ModalContent column={column}>{children}</ModalContent>
      <ModalButtons>
        <WhiteButton
          onClick={() => {
            this.setState({ show: false });
          }}
        >
          Fechar
        </WhiteButton>
        {href || onClick ? (
          <a href={href || null} target={target || null}>
            <BlueButton onClick={onClick || null}>Instalar</BlueButton>
          </a>
        ) : null}
      </ModalButtons>
    </ErrorModalContainer>
  );

  firefoxInstallExtension = () => {
    const { logo } = this.props;

    // eslint-disable-next-line no-undef
    InstallTrigger.install(
      {
        AssinaMe: {
          URL: 'https://addons.mozilla.org/firefox/downloads/file/786482/assiname-0.1.1-an+fx-mac.xpi',
          IconURL: logo,
          Hash: 'sha1:b0af771c1dd32b520926984394db5a4f1920d370',
          toString() {
            return this.URL;
          },
        },
      },
      () => {
        console.log('Failed to install extension');
      },
    );
  };

  modalSelector = modal => {
    const {
      chromeExtensionImgs,
      firefoxExtensionImgs,
      chromeDownloadWindows,
      // chromeDownloadMacos,
      // chromeDownloadLinux,
      firefoxDownloadWindows,
      // firefoxDownloadMacos,
      // firefoxDownloadLinux,
    } = this.props;
    switch (modal) {
      case 'extension-chrome':
        return (
          <this.template
            href="https://chrome.google.com/webstore/detail/liber-capital/hgpmgkeohpmknlojmbolbpjhbmjceaap"
            target="_blank"
            column
          >
            <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp; Em seguida, clique no botão{' '}
              <strong>Adicionar extensão</strong>&nbsp; na janela que irá aparecer em seu
              navegador,&nbsp; conforme a imagem a seguir.
            </p>
            <Center>
              <img src={chromeExtensionImgs[0]} alt="chrome-extension" />
            </Center>
          </this.template>
        );
      case 'extension-firefox':
        return (
          <this.template onClick={this.firefoxInstallExtension} column>
            <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp; Em seguida, clique em{' '}
              <strong>Permitir</strong>&nbsp; na caixa que irá aparecer na área superior esquerda do
              seu navegador,&nbsp; conforme a imagem abaixo.
            </p>
            <Center>
              <img src={firefoxExtensionImgs[0]} alt="extension-firefox-01" />
            </Center>
            <p>
              Por fim, clique em <strong>Adicionar</strong>&nbsp; para adicionar as permissões
              necessárias para a extensão funcionar.
            </p>
            <Center>
              <img src={firefoxExtensionImgs[1]} alt="extension-firefox-02" />
            </Center>
          </this.template>
        );
      case 'native-chrome-windows':
        return (
          <this.template
            href="https://s3.amazonaws.com/liber-certificadora-files/liber-capital.exe"
            column
          >
            <p>
              Clique no botão <strong>Instalar</strong> abaixo. Quando o download for concluído,
              abra o aplicativo <strong>liber-capital.exe</strong>&nbsp; clicando no botão
              localizado na área inferior do seu navegador, conforme a imagem a seguir.
            </p>
            <Center>
              <img src={chromeDownloadWindows} alt="native-chrome-windows" />
            </Center>
          </this.template>
        );
      case 'native-chrome-mac':
        return (
          <this.template>
            {/* <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp;
              Quando o download for concluído, abra o aplicativo <strong>liber-capital.pkg</strong>&nbsp;
              clicando no botão localizado na área inferior do seu navegador, conforme a imagem a seguir.
            </p>
            <Center>
              <img src={chromeDownloadMacos} alt="native-chrome-mac" />
            </Center> */}
            <p>
              Nosso sistema de assinatura digital ainda não oferece suporte para este sistema
              operacional. Por favor, complete o processo de assinatura utilizando um computador
              com&nbsp;
              <strong>
                <WindowsIcon /> Microsoft Windows
              </strong>
              .
            </p>
          </this.template>
        );
      case 'native-chrome-linux':
        return (
          <this.template>
            {/* <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp;
              Quando o download for concluído, abra o aplicativo <strong>liber-capital.tar.gz</strong>&nbsp;
              clicando no botão localizado na área inferior do seu navegador, conforme a imagem a seguir.
            </p>
            <Center>
              <img src={chromeDownloadLinux} alt="native-chrome-linux" />
            </Center> */}
            <p>
              Nosso sistema de assinatura digital ainda não oferece suporte para este sistema
              operacional. Por favor, complete o processo de assinatura utilizando um computador
              com&nbsp;
              <strong>
                <WindowsIcon /> Microsoft Windows
              </strong>
              .
            </p>
          </this.template>
        );
      case 'native-firefox-windows':
        return (
          <this.template
            href="https://s3.amazonaws.com/liber-certificadora-files/liber-capital.exe"
            column
          >
            <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp; Em seguida, clique em{' '}
              <strong>Salvar arquivo</strong>,&nbsp; apresentado em uma caixa conforme mostra a
              imagem a seguir.
            </p>
            <Center>
              <img src={firefoxDownloadWindows} alt="native-firefox-windows" />
            </Center>
          </this.template>
        );
      case 'native-firefox-mac':
        return (
          <this.template>
            {/* <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp;
              Em seguida, clique em <strong>Salvar arquivo</strong>,&nbsp;
              apresentado em uma caixa conforme mostra a imagem a seguir.
            </p>
            <Center>
              <img src={firefoxDownloadMacos} alt="native-firefox-mac" />
            </Center> */}
            <p>
              Nosso sistema de assinatura digital ainda não oferece suporte para este sistema
              operacional. Por favor, complete o processo de assinatura utilizando um computador
              com&nbsp;
              <strong>
                <WindowsIcon /> Microsoft Windows
              </strong>
              .
            </p>
          </this.template>
        );
      case 'native-firefox-linux':
        return (
          <this.template>
            {/* <p>
              Clique no botão <strong>Instalar</strong> abaixo.&nbsp;
              Em seguida, clique em <strong>Salvar arquivo</strong>,&nbsp;
              apresentado em uma caixa conforme mostra a imagem a seguir.
            </p>
            <Center>
              <img src={firefoxDownloadLinux} alt="native-firefox-linux" />
            </Center> */}
            <p>
              Nosso sistema de assinatura digital ainda não oferece suporte para este sistema
              operacional. Por favor, complete o processo de assinatura utilizando um computador
              com&nbsp;
              <strong>
                <WindowsIcon /> Microsoft Windows
              </strong>
              .
            </p>
          </this.template>
        );
      default:
        return <>Não conseguimos identificar as informações do seu sistema.</>;
    }
  };

  errorSelector = type => {
    switch (type) {
      case 'platform':
        return (
          <div>
            Nosso sistema de assinatura digital ainda não oferece suporte para esta plataforma. Por
            favor, complete o processo de assinatura utilizando um <strong>computador</strong>
          </div>
        );
      case 'os':
        return (
          <div>
            Nosso sistema de assinatura digital ainda não oferece suporte para este sistema
            operacional. Por favor, complete o processo de assinatura utilizando um computador
            com&nbsp;
            <strong>
              <WindowsIcon /> Microsoft Windows
            </strong>
            .
          </div>
        );
      case 'browser':
        return (
          <div>
            Nosso sistema ainda não suporta assinaturas neste navegador. Por favor, complete o
            processo de assinatura utilizando o&nbsp;
            <IconLink href="#">
              <ChromeIcon /> Google Chrome
            </IconLink>{' '}
            ou o&nbsp;
            <IconLink href="#">
              <FirefoxIcon /> Mozilla Firefox
            </IconLink>
            .
          </div>
        );
      case 'extension':
        return (
          <div>
            Não encontramos a extensão para assinatura digital no seu navegador.&nbsp;
            <IconLink id="modal-extension" href="#" onClick={this.handleExtension}>
              Clique aqui
            </IconLink>
            &nbsp; para instalar a extensão e concluir o processo de assinatura.
          </div>
        );
      case 'native':
        return (
          <div>
            Não encontramos o aplicativo nativo para assinatura digital no seu computador.&nbsp;
            <IconLink id="modal-native" href="#" onClick={this.handleNative}>
              Clique aqui
            </IconLink>
            &nbsp; para instalar o aplicativo e concluir o processo de assinatura.
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { errors } = this.props;
    const mappedError = errors.map(error => (
      <div key={_.uniqueId(error.title)}>
        <ErrorTitle>
          {error.critical ? <ErrorCircleIconSmall /> : <ExclamationIconSmall />}
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: error.title }} />
        </ErrorTitle>
        <ErrorMsg>{this.errorSelector(error.type)}</ErrorMsg>
      </div>
    ));
    return (
      <>
        <Modal
          show={this.state.show}
          onLeaved={() => {
            this.setState({ show: false, modal: '' });
          }}
        >
          <div>{this.modalSelector(this.state.modal)}</div>
        </Modal>

        <ErrorBox>{mappedError}</ErrorBox>
      </>
    );
  }
}

SupportErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      critical: PropTypes.bool,
    }),
  ).isRequired,
  logo: PropTypes.string.isRequired,
  chromeExtensionImgs: PropTypes.arrayOf(PropTypes.string).isRequired,
  firefoxExtensionImgs: PropTypes.arrayOf(PropTypes.string).isRequired,
  chromeDownloadWindows: PropTypes.string.isRequired,
  // chromeDownloadMacos: PropTypes.string.isRequired,
  // chromeDownloadLinux: PropTypes.string.isRequired,
  firefoxDownloadWindows: PropTypes.string.isRequired,
  // firefoxDownloadMacos: PropTypes.string.isRequired,
  // firefoxDownloadLinux: PropTypes.string.isRequired,
};

export default SupportErrors;
