import { connect } from 'react-redux';
import DadosPessoais from './DadosPessoais';
import {
  changeName,
  changeCpf,
  changeEmail,
  changeCellphone,
  changePhone,
  changeGender,
  changeBirthDate,
  changeCivilStatus,
  changeCountryOrigin,
  changeStateOrigin,
  changeCityOrigin,
  submitForm,
} from '../../../actions/dadosPessoais';
import { getDadosPessoais } from '../../../presenters/dadosPessoais';

const mapStateToProps = state => ({ dadosPessoais: getDadosPessoais(state) });

const mapDispatchToProps = {
  changeName,
  changeCpf,
  changeEmail,
  changeCellphone,
  changePhone,
  changeGender,
  changeBirthDate,
  changeCivilStatus,
  changeCountryOrigin,
  changeStateOrigin,
  changeCityOrigin,
  submitForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(DadosPessoais);
