import styled from 'styled-components';
import {
  Table as TableRaw,
  LoadingBunny,
  FaTimes,
  CardHeader as CardHeaderRaw,
  media,
  handleThemeFromObject,
  MdInformation,
} from 'liber-components';

export const ModalContainer = styled.div`
  width: 1280px;
  max-width: 80vw;
  height: calc(80vh - 200px);
  max-height: 80vh;
  position: relative;
  overflow: auto;
`;

export const DrawerContainer = styled(ModalContainer)`
  max-height: unset;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 69px);
`;

export const Badge = styled.div`
  line-height: 20px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  border-radius: 8px;
  background-color: ${props => handleThemeFromObject(props.theme, 'colors.hoverBlue', '#007ecc')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberWhite', '#fafafa')};
  padding: 2px 9px;
  display: inline;
  margin-right: 12px;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  ${media.small`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
    margin-right: 14px;
  `}
  ${media.large`
  margin-right: 16px;
    line-height: 22px;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
  `}
`;

export const BuyerTitle = styled.div`
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  display: inline;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BuyerAmount = styled.div`
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  margin-left: 32px;
  position: relative;
  &:before {
    content: '-';
    position: absolute;
    top: 0;
    left: -19px;
  }
  ${media.large`
    &:before{
      content: '';
    }
  `}
`;

export const Table = styled(TableRaw)`
  margin: 0px 25px;
  box-sizing: border-box;
  width: calc(100% - 50px);
`;

export const AccordionLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  white-space: nowrap;
  ${media.small`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
  `}
  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
  `}
`;

export const Loading = styled(LoadingBunny)`
  width: 200px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 100px);
  z-index: 10;
`;

export const LoadingBlock = styled.div`
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  opacity: ${props => (props.loading ? props.opacity || '0.3' : '1')};
  pointer-events: ${props => (props.loading ? 'none' : null)};
  transition: opacity 150ms ease-in-out;
  display: inherit;
  flex-direction: inherit;
  position: inherit;
`;

export const CardHeader = styled(CardHeaderRaw)`
  padding: 0px 0px 0px 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.div`
  text-transform: uppercase;
`;

export const Close = styled(FaTimes)`
  width: 25px;
  height: 25px;
  float: right;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  transition: all 50ms ease;
`;

export const CloseButton = styled.button`
  height: 100%;
  width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-left: solid 1px #e6e6e6;
  background-color: transparent;
  cursor: pointer;
  &:hover > ${Close} {
    width: 30px;
    height: 30px;
  }
`;

export const ModalClose = styled(CloseButton)`
  border: 0px;
  width: 35px;
  position: absolute;
  top: 0px;
  right: 10px;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    outline: 0;
  }
`;

export const SummaryTitleRow = styled.div`
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.01px;
  color: #405f71;
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: ${({ theme }) => handleThemeFromObject(theme, 'fontFamily', 'Roboto')};
`;

export const SummaryRow = styled.div`
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8ebee;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & * {
    font-family: ${({ theme }) => handleThemeFromObject(theme, 'fontFamily', 'Roboto')};
  }
`;

export const SummaryInfo = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.01px;
  color: #405f71;
`;

export const SummaryValue = styled.div`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.01px;
  color: #798e9b;
  padding-left: 8px;
`;

export const InvoicesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
`;

export const InvoiceListItem = styled.li`
  width: 100%;
  height: 65px;
  padding: 16px 24px;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  cursor: pointer;
  &:last-child {
    border-bottom: 0px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 32px;
    top: calc(50% - 16px);
    left: 12px;
    background-color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
    opacity: 0.5;
  }
`;

export const InvoiceTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  align-items: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  line-height: 14px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
`;

export const Divider = styled.span`
  flex-grow: 1;
  margin: 0px 8px;
  height: 0px;
  border-top: 1px solid #f4f4f4;
`;

export const InvoiceBottom = styled.div`
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  line-height: 14px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ${Divider} {
    align-self: flex-end;
    height: 3px;
  }
`;

export const ToolTipInfo = styled.div`
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  width: 18px;
  height: 18px;
  border-radius: 9px;
`;

export const InfoIcon = styled(MdInformation)`
  width: 24px;
  height: 24px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  cursor: pointer;
  &:hover {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  }
`;

export const InvoiceModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InvoiceModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  position: relative;
`;

export const InvoiceModalItemTitle = styled.span`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
  white-space: nowrap;
  margin-bottom: 7px;
  color: #13ce66;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  `}
`;

export const InvoiceModalItemValue = styled(InvoiceModalItemTitle)`
  opacity: 0.55;
`;

export const TooltipTitle = styled.span`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
  white-space: nowrap;
  flex-basis: 60%;
  margin-bottom: 0px;
  color: ${({ theme }) => handleThemeFromObject(theme, 'colors.liberBlue', '#009dff')};
`;

export const InvoiceModalList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 18px 25px;
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & ${Divider} {
    align-self: flex-end;
    height: 3px;
    position: relative;
    bottom: 10px;
  }
`;
