import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'liber-components';
import Flash from '../Flash';
import { LoginCard, LoginContent, Title, InfoText, ButtonDiv, ButtonGreen } from '../styles';

const ConfirmationToken = ({
  flashes,
  changeToken,
  token,
  tokenError,
  submitForm,
  confirmationPath,
}) => (
  <LoginCard>
    <LoginContent width="467px">
      <Title>Confirmação de Conta</Title>
      <div style={{ margin: '20px 0px' }}>
        <Flash flashes={flashes} />
        <InfoText>
          Não foi possível recuperar o <b>token</b> de confirmação.
          <br />
          Por favor, digite-o no campo abaixo como mandado no email:
        </InfoText>
        <TextField
          fullWidth
          label="Token"
          errorMessage={tokenError}
          value={token}
          onChange={value => changeToken(value)}
          name="investidor[confirmation_token]"
          id="investidor_confirmation_token"
        />
        <ButtonDiv>
          <ButtonGreen onClick={() => submitForm(token, confirmationPath)}>Confirmar</ButtonGreen>
        </ButtonDiv>
      </div>
    </LoginContent>
  </LoginCard>
);

ConfirmationToken.propTypes = {
  changeToken: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tokenError: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  confirmationPath: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
};

export default ConfirmationToken;
