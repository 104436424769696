export const DATE_FIELDS = [
  {
    filterKey: 'venda_created_at_date',
    label: 'Data de antecipação do título',
  },
  {
    filterKey: 'created_at_date',
    label: 'Data de inserção do título',
  },
  {
    filterKey: 'issued_at',
    label: 'Data de emissão do título',
  },
  {
    filterKey: 'expires_at',
    label: 'Data de vencimento',
  },
];

const STATUS = {
  inserido: 0,
  confirmado: 1,
  verificado: 2,
  bloqueado: 3,
  negociacao: 4,
  vendido: 5,
  pendente: 6,
  vencido: 7,
};

export const INVOICE_STATUSES = [
  { label: 'Todos', value: Object.values(STATUS) },
  { label: 'Antecipados', value: [STATUS.vendido] },
  { label: 'Inseridos', value: [STATUS.inserido, STATUS.confirmado, STATUS.verificado] },
  { label: 'Em negociação', value: [STATUS.negociacao] },
  { label: 'Vencidos', value: [STATUS.vencido] },
  { label: 'Bloqueados', value: [STATUS.bloqueado, STATUS.pendente] },
];

export const NUMBER_IN = 'q[number_cont_any]';
export const STATUS_IN = 'q[status_in]';
export const ANTICIPATED_AT_GT = 'q[venda_created_at_date_gteq]';
export const ANTICIPATED_AT_LT = 'q[venda_created_at_date_lteq]';
export const INSERTED_AT_GT = 'q[created_at_date_gteq]';
export const INSERTED_AT_LT = 'q[created_at_date_lteq]';
export const ISSUED_AT_GT = 'q[issued_at_gteq]';
export const ISSUED_AT_LT = 'q[issued_at_lteq]';
export const EXPIRES_AT_GT = 'q[expires_at_gteq]';
export const EXPIRES_AT_LT = 'q[expires_at_lteq]';
