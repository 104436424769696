import { useState } from 'react';

export const useForm = () => {
  const [formData, setFormData] = useState({
    groupName: undefined,
    kind: undefined,
    category: undefined,
    traderName: undefined,
    traderEmail: undefined,
    commercialName: undefined,
    commercialEmail: undefined,
    allowVendorReplication: true,
  });

  const onChangeForm = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  return { formData, onChangeForm, setFormData };
};
