import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  // Resizable,
  FaChevronDown,
  Link as Button,
  FaFileTextO,
  TooltipBox,
  Wallet,
  Money,
  Bill,
  FaPlusCircle,
  FaMinusCircle,
  media,
  handleThemeFromObject,
} from 'liber-components';

const entryHeight = {
  large: '58px',
  small: '50px',
};

const dotSize = {
  large: '36px',
  small: '32px',
};

const iconDotSize = {
  large: '22px',
  small: '18px',
};

const lineSize = '11px';

export const EntryBackground = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: ${entryHeight.large};
  max-height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  ${media.large`
    background-color: ${props => (props.highlight ? '#F9FBFE' : 'transparent')};
  `}
`;

export const ActionOverlay = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: ${entryHeight.large};
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
`;

export const Date = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  display: flex;
  justify-content: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  align-items: center;
  margin-left: 8px;
  max-height: ${entryHeight.small};
  min-width: 43px;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
    margin-left: ${props => (props.isSummary ? '32px' : '52px')};
    max-height: ${entryHeight.large};
  `}
`;

const baseSizes = {
  ultraSmall: '7px',
  small: '12px',
  medium: '14px',
  large: '20px',
  summary: '28px',
};

export const GreyDot = styled.div`
  --size: ${props => {
    const sizes = {
      ...baseSizes,
      extraLarge: dotSize.small,
    };
    return sizes[props.size] || sizes.medium;
  }};
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  background-color: ${props =>
    handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC')};
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${media.large`
    --size: ${props => {
      const sizes = {
        ...baseSizes,
        extraLarge: dotSize.large,
      };
      return sizes[props.size] || sizes.medium;
    }};
`}
`;

export const BlueDot = styled(GreyDot)`
  --size: ${dotSize.small};
  background-color: #b6e3ff;

  & > svg {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  }

  ${media.large`
    --size: ${props => props.size || dotSize.large};
  `}
`;

export const RedDot = styled(GreyDot)`
  --size: ${props => (props.isFee ? '19px' : dotSize.small)};
  background-color: #ffc1c1;
  color: #ff6566;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.small', '10px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  line-height: var(--size);
  & > svg {
    fill: #ff6566;
  }
  ${media.large`
    --size: ${props => (props.isFee ? '22px' : props.size || dotSize.large)};
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  `}
`;

export const MarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  flex-basis: 32px;
  align-self: stretch;
  position: relative;

  ${media.large`
    margin: ${props => (props.isSummary ? '0 15px' : '0px 30px')};
    flex-basis: 50px;
  `}

  ${props => {
    if (props.isBalance) {
      return css`
        margin-right: 0;
        margin-left: 18px;
        flex-basis: unset;
        ${media.large`
          margin-right: 0;
          margin-left: 49px;
          flex-basis: unset;
      `}
      `;
    }
    return null;
  }}
`;

export const MarkLine = styled.span`
  width: 0px;
  max-height: ${props => (props.top && !props.isFee ? '12px' : null)};
  flex-grow: 1;
  border-right: ${props =>
    !props.hide
      ? `2px ${props.type} ${handleThemeFromObject(
          props.theme,
          'colors.bluishMediumGrey',
          '#DDE8EC',
        )};`
      : null};
  ${media.large`
    border-right: ${props =>
      !props.hide
        ? `3px ${props.type} ${handleThemeFromObject(
            props.theme,
            'colors.bluishMediumGrey',
            '#DDE8EC',
          )};`
        : null};
    max-height: ${props => (props.top && !props.isFee ? lineSize : null)};
  `}
`;

export const SummaryMarkLine = styled.span`
  width: 0px;
  ${props => {
    const color = props.open
      ? handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#496072')
      : handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC');
    return css`
      border-right: ${!props.hide ? `3px ${props.type} ${color}` : null};
    `;
  }}
  max-height: ${props => (props.top ? '21px' : null)};
  transition: all 100ms ease-in;
  flex-grow: 1;
`;

SummaryMarkLine.defaultProps = {
  type: 'solid',
};

MarkLine.defaultProps = {
  type: 'solid',
};

export const Mark = ({ type, start, end, dotted, children, summary, size, balance }) => {
  switch (type) {
    case 'grey':
      return (
        <MarkContainer isSummary={summary} isBalance={balance}>
          <MarkLine hide={start} />
          <GreyDot size={size} />
          <MarkLine hide={end} />
        </MarkContainer>
      );
    case 'blue':
      return (
        <MarkContainer>
          <MarkLine top />
          <BlueDot>{children}</BlueDot>
          <MarkLine type={dotted ? 'dotted' : 'solid'} />
        </MarkContainer>
      );
    case 'red':
      return (
        <MarkContainer>
          <MarkLine top isFee={size === 'small'} />
          <RedDot isFee={size === 'small'}>{children}</RedDot>
          <MarkLine type={dotted ? 'dotted' : 'solid'} />
        </MarkContainer>
      );
    default:
      return <MarkContainer />;
  }
};

Mark.propTypes = {
  type: PropTypes.string,
  start: PropTypes.bool,
  end: PropTypes.bool,
  dotted: PropTypes.bool,
  children: PropTypes.node,
  summary: PropTypes.bool,
  size: PropTypes.string,
  balance: PropTypes.bool,
};

Mark.defaultProps = {
  type: 'grey',
  start: false,
  end: false,
  dotted: false,
  children: <div />,
  summary: false,
  size: 'small',
  balance: false,
};

export const AccordionContainer = styled.div`
  padding-right: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SummaryContainer = styled(AccordionContainer)`
  padding-right: 0px;
`;

export const AccordionLabel = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  line-height: 20px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishBlack', '#496072')};
  display: flex;
  text-transform: capitalize;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  `}
`;

// export const AccordionValue = styled(Resizable)`
export const AccordionValue = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  line-height: 22px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  display: flex;
  align-items: center;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  `}
`;

export const SummaryValue = styled(AccordionValue)`
  color: ${props =>
    props.color || handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  & svg {
    fill: ${props =>
      props.color || handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  }
`;

export const InvoiceCounter = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileSmall', '11px')};
  line-height: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  color: #fff;
  background-color: ${props =>
    handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  border-radius: 6px;
  padding: 1px 4px;
  margin-left: 8px;
  align-self: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
    padding: 2px 4px;
  `}
`;

export const AccordionInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 26px;
  justify-content: space-between;

  ${media.large`
    min-height: unset;
  `}
`;

export const Chevron = styled(FaChevronDown)`
  --size: 9px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  transform: ${props => (props.open ? 'rotate(-90deg)' : 'rotate(0)')};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-left: 8px;

  ${media.large`
    --size: 14px;
    transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
    margin-left: unset;
  `}
`;

export const IconButton = styled(Button)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  padding: 0px;
  &:hover {
    background-color: #b6e3ff;
  }
  &:hover svg {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  }

  &:after {
    background-color: #6fc7ff;
  }
`;

const TooltipContent = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  font-weight: 200;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  width: 120px;
  text-align: center;
`;

export const TooltipHelper = styled.div`
  width: 30px;
`;

export const TooltipIconButton = ({ content, label, ...props }) => (
  <TooltipBox
    fixed
    mount="top"
    delay
    delayTime={1000}
    mousePadding={20}
    content={<TooltipContent>{content}</TooltipContent>}
    label={label}
  >
    <div>
      <TooltipHelper>
        <IconButton {...props} />
      </TooltipHelper>
    </div>
  </TooltipBox>
);

TooltipIconButton.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
};

TooltipIconButton.defaultProps = {
  content: '',
  label: '',
};

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: ${entryHeight.small};

  & ${TooltipHelper} {
    margin-left: 4px;
  }

  ${media.large`
    min-height: ${entryHeight.large};
    & ${TooltipHelper}{
    margin-left: 16px;
  }
  `}
`;

export const SummaryHeader = styled(AccordionHeader)`
  min-height: 50px;
`;

export const AccordionContent = styled.div.attrs(({ children, count }) => ({
  height: React.Children.toArray(children).length * 35 + count * 40,
}))`
  display: flex;
  flex-direction: column;
  pointer-events: ${props => (props.open ? 'unset' : 'none')};
  opacity: ${props => (props.open ? '1' : '0')};
  min-height: ${props => (props.open ? props.height : '0')}px;
  height: ${props => (props.open ? props.height : '0')}px;
  transition: all 100ms ease-in;
`;

export const SummaryContent = styled.div.attrs(({ children }) => ({
  height: React.Children.toArray(children).length * 54 + 8,
}))`
  display: flex;
  flex-direction: column;
  pointer-events: ${props => (props.open ? 'unset' : 'none')};
  opacity: ${props => (props.open ? '1' : '0')};
  min-height: ${props => (props.open ? props.height : '0')}px;
  height: ${props => (props.open ? props.height : '0')}px;
  transition: all 100ms ease-in;
  margin-top: 8px;
`;

export const ActionContainer = styled.div`
  min-height: 55px;
  min-width: 92px;
  border-bottom: 1px solid
    ${props => handleThemeFromObject(props.theme, 'colors.lightGrey', '#F7F7F7')};
  display: none;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 35px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};

  & ${IconButton}, & ${TooltipHelper} {
    margin-right: 16px;
  }

  ${media.large`
    display: flex;
  `}
`;

export const FileIcon = styled(FaFileTextO)`
  --size: 14px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
`;

export const BillIcon = styled(Bill)`
  --size: 15px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
`;

export const WalletIcon = styled(Wallet)`
  --size: ${iconDotSize.small};
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  ${media.large`
    --size: ${props => props.size || iconDotSize.large};
  `}
`;

export const MoneyIcon = styled(Money)`
  --size: ${iconDotSize.small};
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  ${media.large`
    --size: ${props => props.size || iconDotSize.large};
  `}
`;

export const InvoiceContent = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#7E8D95')};
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  padding: 0px;
  border-radius: 4px;
  position: relative;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover {
    background-color: ${props =>
      handleThemeFromObject(props.theme, 'colors.bluishLightgrey', '#F1F5F7')};
  }

  & hr {
    flex-grow: 1;
    margin: 0 0 8px 0;
    border-color: ${props =>
      handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#E5EEF1')};
    align-self: flex-end;
    border-width: 0px;
  }
  &:before {
    --size: 7px;
    content: '';
    top: calc(50% - var(--size) / 2);
    left: -27.5px;
    position: absolute;
    width: var(--size);
    height: var(--size);
    background-color: ${props =>
      handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC')};
    border-radius: 50%;
    pointer-events: none;
  }

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
    padding: 0 16px;

    &:before{
      left: -59px;
    }

    & hr{
      border-width: 1px;
    }
  `}
`;

export const InvoiceDetails = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#C7CFD3')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  width: 200px;
`;

export const InvoiceRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PriceLabel = styled.span`
  --dot-color: #dadada;
  position: relative;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  margin-left: 12px;
  &:before {
    --size: 6px;
    content: '';
    top: calc(50% - var(--size) / 2);
    left: -12px;
    position: absolute;
    width: var(--size);
    height: var(--size);
    background-color: var(--dot-color);
    border-radius: 50%;
  }
`;

export const FeeLabel = styled(PriceLabel)`
  --dot-color: #ffc1c1;
`;

export const ReceiptLabel = styled(PriceLabel)`
  --dot-color: #b6e3ff;
`;

export const InvoiceBox = ({ label, value, content, screenSize }) => {
  if (screenSize === 'large' || screenSize === 'extraLarge') {
    return (
      <TooltipBox fixed mount="right" mousePadding={20} content={content}>
        <div>
          <InvoiceContent>
            {label} <hr /> {value}
          </InvoiceContent>
        </div>
      </TooltipBox>
    );
  }
  return (
    <InvoiceContent>
      {label} <hr /> {value}
    </InvoiceContent>
  );
};

InvoiceBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  screenSize: PropTypes.string,
};

InvoiceBox.defaultProps = {
  content: undefined,
  screenSize: 'large',
};

export const PlusIcon = styled(FaPlusCircle)`
  --size: 10px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  margin-right: 4px;

  ${media.large`
    --size: 11px;
    margin-right: 8px;
  `}
`;

export const MinusIcon = styled(FaMinusCircle)`
  --size: 10px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  margin-right: 4px;

  ${media.large`
    --size: 11px;
    margin-right: 8px;
  `}
`;

// background-color: ${props => handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC')};
// border-radius: 4px;
// color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};

export const DotButton = styled(IconButton)`
  --size: 12px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: #e6e6e6;
  /* background-color: yellow; */
  /* position: absolute; */
  border: 0px;
  /* top: 34px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover:before {
    border-left: 3px solid
      ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  }
  ${props =>
    !props.empty
      ? css`
          &:before {
            content: '';
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-left: 3px solid
              ${props.open
                ? handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')
                : handleThemeFromObject(props.theme, 'colors.bluishMediumDarkGrey', '#DDE8EC')};
            border-right: 3px solid transparent;
            width: 3px;
            height: 3px;
            display: block;
            margin-right: ${props.open ? '0px' : '-3px'};
            margin-bottom: ${props.open ? '-3px' : '0px'};
            transform: ${props.open ? 'rotate(90deg)' : 'rotate(0deg)'};
            transition: all 100ms ease-in;
          }
        `
      : css`
          background-color: #e6e6e6 !important;
        `}

  &:focus {
    outline: none;
  }
`;

export const SummaryRow = styled(InvoiceRow)`
  align-items: center;
  min-height: 46px;
  margin-bottom: 8px;
`;

export const SummaryRedDot = styled(RedDot)`
  --size: 28px;
  margin: 0px 8px 0px 24px;
  & ${WalletIcon} {
    --size: 13px;
  }
`;

export const SummaryBlueDot = styled(BlueDot)`
  --size: 28px;
  margin: 0px 8px 0px 24px;
  & ${WalletIcon} {
    --size: 13px;
  }
`;

export const SummaryLabel = styled(AccordionLabel)`
  /* font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')}; */
  line-height: 14px;
  text-transform: capitalize;
`;

export const SummaryInfo = styled(AccordionInfo)`
  min-height: 40px;
`;

export const BalanceSeparator = styled.hr`
  flex-grow: 1;
  margin-right: 8px;
  border-top: 1px solid
    ${props => handleThemeFromObject(props.theme, 'colors.bluishMediumDarkGrey', '#DDE8EC')};
`;

export const BalanceAmount = styled.div`
  width: 35%;
  display: flex;
  color: #8f9ea7;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.small', '10px')};
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-weight: 200;
  line-height: 18px;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
    line-height: 26px;
  `}

  & ${BalanceSeparator} {
    margin-left: 8px;
  }
`;

export const BalanceLine = ({ amount }) => (
  <>
    <BalanceSeparator />
    <BalanceAmount>
      Saldo: {amount}
      <BalanceSeparator />
    </BalanceAmount>
  </>
);

BalanceLine.propTypes = {
  amount: PropTypes.string.isRequired,
};

export const DraweeList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DraweeLabel = styled.div`
  padding: 2px 8px;
  background-color: ${props =>
    handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC')};
  border-radius: 4px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', 500)};
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  align-self: flex-start;
  margin-bottom: 8px;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  `}
`;

export const DraweeBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 4px;
`;

export const EntryLine = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  ${media.large`
    &:hover > ${EntryBackground}{
      background-color: #F9FBFE;
    }

    &:hover ${GreyDot},
    &:hover ${BlueDot},
    &:hover ${RedDot} {
      border-color: #F9FBFE;
    }

    &:hover ${InvoiceCounter} {
      background-color: #C3E8FF;
      color: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
    }
    &:hover ${IconButton}{
      background-color: #C3E8FF;
    }
    &:hover ${Chevron}{
      fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
    }
    &:hover ${DotButton}:before{
      background-color: #C3E8FF;
      border-left-color: ${props =>
        handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
    }
  `}
`;
