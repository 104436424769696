import React, { useContext, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { handleError, convertToCamelCase } from '../../vendor/Utils';
import { TokenContext } from '../../hocs/withTokenProvider';
import { fetchHistory } from './api';

const VendorsGroupHistoryContext = createContext({});

const VendorsGroupHistoryStore = ({ groupId, name, referenceCode, isDefault, children }) => {
  const { token } = useContext(TokenContext);

  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  const handleFetchHistory = () => {
    fetchHistory(token, groupId)
      .then(response => {
        const { data } = response;
        const formattedData = convertToCamelCase(data?.data || []);
        setHistory(formattedData);
      })
      .catch(() => {
        handleError(
          'Erro no retorno das informações',
          'Houve um problema ao fazer a busca do histórico. Por favor, atualize a página e tente novamente.',
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleFetchHistory();
  }, []);

  return (
    <VendorsGroupHistoryContext.Provider
      value={{
        name,
        isDefault,
        history,
        loading,
        groupId,
        referenceCode,
      }}
    >
      {children}
    </VendorsGroupHistoryContext.Provider>
  );
};

VendorsGroupHistoryStore.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  referenceCode: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

export { VendorsGroupHistoryContext, VendorsGroupHistoryStore };
