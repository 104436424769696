export const getId = (state, index) => state.enderecos[index].id || -1;
export const getAddress = (state, index) => state.enderecos[index].address || '';
export const getNumber = (state, index) => state.enderecos[index].number || '';
export const getComplement = (state, index) => state.enderecos[index].complement || '';
export const getCity = (state, index) => state.enderecos[index].city || '';
export const getState = (state, index) => state.enderecos[index].state || '';
export const getCountry = (state, index) => state.enderecos[index].country || '';
export const getDistrict = (state, index) => state.enderecos[index].district || '';
export const getZipCode = (state, index) => state.enderecos[index].zip_code || '';
export const getKind = (state, index) => state.enderecos[index].kind || null;
export const getIsPatch = (state, index) => Boolean(state.enderecos[index].is_patch);
export const getIsFetching = (state, index) => Boolean(state.enderecos[index].is_fetching);
export const getIsLoaded = (state, index) => Boolean(state.enderecos[index].is_loaded);
export const getErrors = (state, index) => state.enderecos[index].errors || {};
export const getEnderecos = state =>
  Object.keys(state.enderecos).reduce(
    (last, index) => ({
      ...last,
      [index]: {
        id: getId(state, index),
        address: getAddress(state, index),
        number: getNumber(state, index),
        complement: getComplement(state, index),
        city: getCity(state, index),
        state: getState(state, index),
        country: getCountry(state, index),
        district: getDistrict(state, index),
        zipCode: getZipCode(state, index),
        kind: getKind(state, index),
        isPatch: getIsPatch(state, index),
        isFetching: getIsFetching(state, index),
        isLoaded: getIsLoaded(state, index),
        errors: getErrors(state, index),
      },
    }),
    {},
  );
