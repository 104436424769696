import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { getFingerprint } from 'client';
import { Checkbox, Form, TextField } from 'liber-components';
import Flash from '../Flash';
import {
  LoginCard,
  LoginContent,
  LoginSeparator,
  BlueLink,
  Title,
  Image,
  LoginLastContent,
  ButtonGreen,
  RadioDiv,
  ButtonDiv,
} from '../styles';
import { getSubdomain } from '../../../../vendor/Utils';

const Sacado = ({
  path,
  fingerprint,
  components,
  flashes,
  logoUrl,
  passwordPath,
  cnpj,
  cnpjError,
  password,
  passwordError,
  remember,
  changeCNPJ,
  changePassword,
  toogleRemember,
  setFingerprint,
  submitLogin,
}) => (
  <LoginCard>
    <LoginContent>
      <Title>SACADO</Title>
      <Flash flashes={flashes} />
      <Form
        action={path}
        method="POST"
        onSubmit={event => {
          const { target } = event;
          event.preventDefault();
          getFingerprint().then(data => {
            setFingerprint(data);
            submitLogin(target, cnpj, password);
          });
        }}
      >
        <input value={components} type="hidden" name="sacado[components]" id="sacado_components" />
        <input
          value={fingerprint}
          type="hidden"
          name="sacado[fingerprint]"
          id="sacado_fingerprint"
        />
        <input
          value="SACADO/LOGIN"
          type="hidden"
          name="sacado[breadcrumb]"
          id="sacado_breadcrumb"
        />
        <TextField
          fullWidth
          type="text"
          label="CNPJ"
          errorMessage={cnpjError}
          value={cnpj}
          onChange={value => changeCNPJ(value)}
          name="sacado[cnpj]"
          id="sacado_cnpj"
          mask="99.999.999/9999-99"
          maskChar={null}
          openLabel
        />
        <TextField
          fullWidth
          type="password"
          label="Senha"
          errorMessage={passwordError}
          value={password}
          onChange={value => changePassword(value)}
          name="sacado[password]"
          id="sacado_password"
          openLabel
        />
        <RadioDiv>
          <Checkbox
            id="sacado_remember_me"
            name="sacado[remember_me]"
            onChange={toogleRemember}
            checked={remember}
          >
            Lembrar
          </Checkbox>
        </RadioDiv>
        <ButtonDiv>
          <ButtonGreen width="100%">Entrar</ButtonGreen>
        </ButtonDiv>
        <BlueLink href={passwordPath}>Esqueci minha senha</BlueLink>
      </Form>
    </LoginContent>
    <LoginSeparator />
    <LoginLastContent>
      <Image alt={`Logo de ${getSubdomain()}`} src={logoUrl} />
    </LoginLastContent>
  </LoginCard>
);

Sacado.propTypes = {
  logoUrl: PropTypes.string,
  path: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  cnpj: PropTypes.string.isRequired,
  cnpjError: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  remember: PropTypes.bool.isRequired,
  changeCNPJ: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  toogleRemember: PropTypes.func.isRequired,
  submitLogin: PropTypes.func.isRequired,
  setFingerprint: PropTypes.func.isRequired,
  fingerprint: PropTypes.string.isRequired,
  components: PropTypes.string.isRequired,
};

Sacado.defaultProps = {
  logoUrl: '',
};

export default Sacado;
