import styled from 'styled-components';
import { media, handleThemeFromObject } from 'liber-components';

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116px;
  width: 100%;
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
  `}
`;

export const empty = null;
