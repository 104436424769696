import Immutable from 'seamless-immutable';
import { INVESTIDOR_SIGNUP_TOKEN_RECEIVED } from '../actions/investidorSignUpActions';

const initialState = Immutable({
  type: '',
  email: '',
  fullName: '',
  companyName: '',
  tradeName: '',
  cpf: '',
  cnpj: '',
  phone: '',
  ramal: '',
  cellphone: '',
  password: '',
  confirmation: '',
  emailError: '',
  fullNameError: '',
  companyNameError: '',
  tradeNameError: '',
  cpfError: '',
  cnpjError: '',
  phoneError: '',
  ramalError: '',
  cellphoneError: '',
  passwordError: '',
  path: '',
  flashes: [],
  originPath: '',
  reCaptchaToken: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INVESTIDOR_SIGNUP_CHANGE_TYPE':
      return state.merge({ type: action.payload });
    case INVESTIDOR_SIGNUP_TOKEN_RECEIVED:
      return state.merge({ reCaptchaToken: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_EMAIL':
      return state.merge({ email: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_FULLNAME':
      return state.merge({ fullName: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_COMPANYNAME':
      return state.merge({ companyName: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_TRADENAME':
      return state.merge({ tradeName: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_CPF':
      return state.merge({ cpf: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_CNPJ':
      return state.merge({ cnpj: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_PHONE':
      return state.merge({ phone: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_RAMAL':
      return state.merge({ ramal: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_CELLPHONE':
      return state.merge({ cellphone: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_PASSWORD':
      return state.merge({ password: action.payload });
    case 'INVESTIDOR_SIGNUP_CHANGE_CONFIRMATION':
      return state.merge({ confirmation: action.payload });
    case 'INVESTIDOR_SIGNUP_SUCESSFULL_SUBMIT':
      return state;
    case 'INVESTIDOR_SIGNUP_FAILED_SUBMIT':
      return state.merge(action.payload);
    default:
      return state;
  }
};
