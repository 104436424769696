import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import { ButtonsRow, SecondaryButton, PrimaryButton } from '../AnticipationCheckout.styles';
import {
  Card,
  RadioButton,
  Container,
  ShimmeringCard,
  ConfirmContainer,
  Info,
  InfoMessage,
  InfoIconWrapper,
} from './BankAccount.styles';
import {
  fetchBankAccounts,
  setSelectedBankAccountId,
} from '../../actions/AnticipationCheckoutActions';
import { BankAccount as BankAccountComponent } from '../../../../common-components/BankAccount/BankAccount';
import AccountChangeDialog from './AccountChangeDialog';
import { ANTICIPATIONS_PAGE_URL } from '../../urls';

export const BankAccount = ({
  backButtonLabel,
  handleBack,
  handleNext,
  bankAccounts,
  onFetchBankAccounts,
  selectedBankAccountId,
  onSetSelectedBankAccountId,
  sentBankAccountId,
  isAdmin,
  isLegalAgent,
  vendor,
  operator,
}) => {
  const [selected, setSelected] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  React.useEffect(() => {
    onFetchBankAccounts();
  }, []);

  useEffect(() => {
    if (bankAccounts.length > 0) {
      let index;
      if (selectedBankAccountId) {
        index = bankAccounts.findIndex(({ id }) => id === selectedBankAccountId);
      } else {
        index = bankAccounts.findIndex(account => account.default);
        const hasDefaultAccount = index !== -1;

        index = hasDefaultAccount ? index : 0;

        onSetSelectedBankAccountId(bankAccounts[index]?.id);
      }
      setSelected(index);
    }
  }, [bankAccounts]);

  const hasSelectedAccount = useMemo(() => selected != null, [selected]);

  const gaParams = useMemo(
    () => ({
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    }),
    [isAdmin, vendor, operator],
  );

  const handleSelect = index => {
    setSelected(index);
    onSetSelectedBankAccountId(bankAccounts[index].id);
  };

  const goToBackStage = useCallback(() => {
    handleBack();
  }, [gaParams]);

  const goToNextStage = useCallback(() => {
    if (sentBankAccountId && sentBankAccountId !== selectedBankAccountId) {
      setDialogOpen(true);
    } else {
      handleNext();
    }
  }, [sentBankAccountId, selectedBankAccountId, isAdmin, gaParams]);

  const goToAnticipations = () => {
    window.location.assign(ANTICIPATIONS_PAGE_URL);
  };

  return (
    <>
      <Container>
        {hasSelectedAccount ? (
          bankAccounts.map(({ id, ...bankInfo }, index) => (
            <Card
              key={`bank-account-${id}`}
              checked={index === selected}
              onClick={() => handleSelect(index)}
              marginBottom={index !== bankAccounts.length - 1}
            >
              <RadioButton id="bank-account-picker" checked={index === selected} />
              <BankAccountComponent {...bankInfo} />
            </Card>
          ))
        ) : (
          <ShimmeringCard />
        )}
      </Container>
      <ButtonsRow>
        <SecondaryButton onClick={goToBackStage}>{backButtonLabel}</SecondaryButton>
        {!isLegalAgent && (
          <SecondaryButton onClick={goToAnticipations}>IR PARA ANTECIPAÇÕES</SecondaryButton>
        )}
        <ConfirmContainer>
          <PrimaryButton onClick={goToNextStage} disabled={!isLegalAgent && !hasSelectedAccount}>
            SELECIONAR CONTA
          </PrimaryButton>
          {!isLegalAgent && (
            <Info>
              <InfoIconWrapper>
                <Icon path={mdiInformation} />
              </InfoIconWrapper>
              <InfoMessage>
                É necessáro ser representante legal para selecionar a conta e assinar o termo de
                cessão
              </InfoMessage>
            </Info>
          )}
        </ConfirmContainer>
      </ButtonsRow>
      <AccountChangeDialog
        open={dialogOpen}
        onConfirm={handleNext}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
};

BankAccount.propTypes = {
  backButtonLabel: PropTypes.string,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      default: PropTypes.bool,
      branch: PropTypes.string,
      branchCd: PropTypes.string,
      account: PropTypes.string,
      accountCd: PropTypes.string,
      bankCode: PropTypes.string,
      bankName: PropTypes.string,
      jointAccount: PropTypes.bool,
      accountSuffix: PropTypes.string,
      kind: PropTypes.string,
    }),
  ),
  onFetchBankAccounts: PropTypes.func,
  selectedBankAccountId: PropTypes.number,
  sentBankAccountId: PropTypes.number,
  onSetSelectedBankAccountId: PropTypes.func,
  isAdmin: PropTypes.bool,
  isLegalAgent: PropTypes.bool,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

BankAccount.defaultProps = {
  backButtonLabel: 'VOLTAR',
  handleBack: () => null,
  handleNext: () => null,
  bankAccounts: [],
  onFetchBankAccounts: () => null,
  selectedBankAccountId: null,
  sentBankAccountId: null,
  onSetSelectedBankAccountId: () => null,
  isAdmin: false,
  isLegalAgent: true,
  vendor: {},
  operator: {},
};

const mapStateToProps = ({
  anticipationCheckout: {
    bankAccounts,
    selectedBankAccountId,
    sentBankAccountId,
    isAdmin,
    operator: { isLegalAgent },
  },
  vendorAdvances: { vendor, operator },
}) => ({
  bankAccounts,
  selectedBankAccountId,
  sentBankAccountId,
  isAdmin,
  isLegalAgent,
  vendor,
  operator,
});

const mapDispatchesToProps = {
  onFetchBankAccounts: fetchBankAccounts,
  onSetSelectedBankAccountId: setSelectedBankAccountId,
};

export default connect(mapStateToProps, mapDispatchesToProps)(BankAccount);
