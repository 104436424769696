import styled, { createGlobalStyle } from 'styled-components';

export const ApplicationWrapperStyles = createGlobalStyle`
  #application-wrapper {
    @media (min-width: 1280px) {
      margin-left: ${({ open }) => (open ? '259px' : '59px')} !important;
      transition: margin-left 0.15s ease-in-out;
    }
    @media (max-width: 1280px) {
      margin-top: 64px;
    }
    @media (max-width: 960px) {
      margin-top: 56px;
    }
  }
`;

export const UserMenuStyles = createGlobalStyle`
  .MuiPopover-paper > ul > div > a:hover.MuiMenuItem-root {
    color: ${({ theme }) => theme.palette.text.primary}
  }
`;

export const AppBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1210;
`;

export const Container = styled.div`
  svg {
    margin-top: -6px;
  }
`;
