import axios from 'axios';
import {
  FETCH_VENDORS_GROUPS_URL,
  UPLOAD_URL,
  ARCHIVE_GROUP_URL,
  UPDATE_INCREMENT_URL,
} from './constants';

export async function fetchVendorsGroups(token = '', params) {
  return axios.get(FETCH_VENDORS_GROUPS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
}

export async function sendSpreadsheet(token = '', spreadsheet, roomId) {
  const formData = new FormData();
  formData.append('spreadsheet', spreadsheet);
  formData.append('room_id', roomId);

  return axios.post(UPLOAD_URL, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    timeout: 15000,
  });
}

export async function archiveGroup(token = '', groupId) {
  return axios.delete(ARCHIVE_GROUP_URL.replace(':id', groupId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 80000,
  });
}

export async function updateIncrementChange(token = '', body, vendorId) {
  return axios.put(UPDATE_INCREMENT_URL.replace(':id', vendorId), body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
