import styled from 'styled-components';
import { handleThemeFromObject as getTheme, LoadingBunny } from 'liber-components';

export const LoadingBox = styled.div`
  max-width: 100%;
  width: 770px;
  height: 333px;
  border-left: 7px solid ${props => getTheme(props.theme, 'colors.liberBlue', '#009dff')};
  border-right: 7px solid ${props => getTheme(props.theme, 'colors.liberBlue', '#009dff')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 62px;
  box-sizing: border-box;
  & span {
    text-align: center;
  }

  & h1 {
    color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009DFF')};
    font-size: ${props => getTheme(props.theme, 'fontSizes.h3', '32px')};
    font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
    font-weight: ${props => getTheme(props.theme, 'fontWeights.bold', '700')};
  }
`;

export const Bunny = styled(LoadingBunny)`
  width: 200px;
  height: 100px;
  margin-top: 32px;
  fill: none;
  stroke: ${props => getTheme(props.theme, 'colors.liberBlue', '#009dff')};
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2.52px;
`;
