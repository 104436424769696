import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { getFingerprint } from 'client';
import { Checkbox, Form, TextField } from 'liber-components';
import Flash from '../Flash';
import {
  LoginCard,
  LoginContent,
  LoginSeparator,
  BlueLink,
  Title,
  Image,
  LoginLastContent,
  ButtonGreen,
  RadioDiv,
  ButtonDiv,
} from '../styles';
import { getSubdomain } from '../../../../vendor/Utils';

const Operator = ({
  path,
  fingerprint,
  components,
  flashes,
  logoUrl,
  passwordPath,
  email,
  emailError,
  password,
  passwordError,
  token,
  tokenError,
  remember,
  changeEmail,
  changePassword,
  changeToken,
  toogleRemember,
  submitLogin,
  setFingerprint,
}) => (
  <LoginCard>
    <LoginContent>
      <Title>ENTRAR</Title>
      <Flash flashes={flashes} />
      <Form
        action={path}
        method="POST"
        onSubmit={event => {
          const { target } = event;
          event.preventDefault();
          getFingerprint().then(data => {
            setFingerprint(data);
            submitLogin(target, email, password);
          });
        }}
      >
        <input
          value={components}
          type="hidden"
          name="operator[components]"
          id="operator_components"
        />
        <input
          value={fingerprint}
          type="hidden"
          name="operator[fingerprint]"
          id="operator_fingerprint"
        />
        <input
          value="OPERADOR/LOGIN"
          type="hidden"
          name="operator[breadcrumb]"
          id="operator_breadcrumb"
        />
        <TextField
          fullWidth
          type="email"
          label="Email"
          errorMessage={emailError}
          value={email}
          onChange={value => changeEmail(value)}
          name="operator[email]"
          id="operator_email"
          openLabel
        />
        <TextField
          fullWidth
          type="password"
          label="Senha"
          errorMessage={passwordError}
          value={password}
          onChange={value => changePassword(value)}
          name="operator[password]"
          id="operator_password"
          openLabel
        />
        <TextField
          fullWidth
          type="number"
          label="Chave de Segurança (Token)"
          errorMessage={tokenError}
          value={token}
          onChange={value => changeToken(value)}
          name="operator[otp_attempt]"
          id="operator_otp_attempt"
          openLabel
        />
        <RadioDiv>
          <Checkbox
            value="1"
            name="operator[remember_me]"
            id="operator_remember_me"
            onChange={toogleRemember}
            checked={remember}
          >
            Lembrar
          </Checkbox>
        </RadioDiv>
        <ButtonDiv>
          <ButtonGreen width="100%">Entrar</ButtonGreen>
        </ButtonDiv>
        <BlueLink href={passwordPath}>Esqueci minha senha</BlueLink>
      </Form>
    </LoginContent>
    {logoUrl !== '' && <LoginSeparator />}
    {logoUrl !== '' && (
      <LoginLastContent>
        <Image alt={`Logo de ${getSubdomain()}`} src={logoUrl} />
      </LoginLastContent>
    )}
  </LoginCard>
);

Operator.propTypes = {
  logoUrl: PropTypes.string,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  path: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  tokenError: PropTypes.string.isRequired,
  remember: PropTypes.bool.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeToken: PropTypes.func.isRequired,
  toogleRemember: PropTypes.func.isRequired,
  submitLogin: PropTypes.func.isRequired,
  setFingerprint: PropTypes.func.isRequired,
  fingerprint: PropTypes.string.isRequired,
  components: PropTypes.string.isRequired,
};

Operator.defaultProps = {
  logoUrl: '',
};

export default Operator;
