import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabContentDisplay, TabContainer } from 'liber-components';
import { StickyContainer, HeaderContainer } from './TabControl.styles';
import InvoiceSimulationTableHeader, {
  tableHeaderShape,
} from '../InvoiceSimulation/InvoiceSimulationTableHeader';
import InvoiceSimulationSelectControlContainer from '../InvoiceSimulation/InvoiceSimulationSelectControl';
import { INVOICE_TABS } from '../../utils';
import Filters from '../Filters';

const { NEGOTIATION } = INVOICE_TABS;

const shouldDisplayTableHeader = (activeTab, invoices, isFiltered) =>
  activeTab !== NEGOTIATION && (invoices.length > 0 || isFiltered);

export const TabControl = ({
  header,
  active,
  tabs,
  tableHeaderProps,
  token,
  invoices,
  onSelectAllInMultiple,
  isFiltered,
}) => {
  const { activeTab } = tableHeaderProps;
  const [transitioning, setTransitioning] = useState(false);
  const mappedChildren = tabs.map((tab, index) => (
    <TabContent key={tab.key} active={active === index}>
      {tab.content}
    </TabContent>
  ));

  return (
    <>
      <HeaderContainer>{header}</HeaderContainer>
      {shouldDisplayTableHeader(activeTab, invoices, isFiltered) ? (
        <>
          <StickyContainer>
            <Filters token={token} />
            <InvoiceSimulationTableHeader {...tableHeaderProps} />
          </StickyContainer>
          <InvoiceSimulationSelectControlContainer
            token={token}
            onSelectAllInMultiple={onSelectAllInMultiple}
          />
        </>
      ) : null}
      <TabContainer transitioning={transitioning}>
        <TabContentDisplay onTransitionEnd={() => setTransitioning(false)} active={active}>
          {mappedChildren}
        </TabContentDisplay>
      </TabContainer>
    </>
  );
};

TabControl.propTypes = {
  header: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.node,
    }).isRequired,
  ),
  active: PropTypes.number,
  tableHeaderProps: tableHeaderShape.isRequired,
  token: PropTypes.string.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectAllInMultiple: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
};

TabControl.defaultProps = {
  active: 0,
  header: <div />,
  tabs: [],
  invoices: [],
  isFiltered: false,
};

export default TabControl;
