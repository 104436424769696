import styled from 'styled-components';
import { Drawer as RawDrawer } from 'liber-components';

export const Container = styled.div`
  margin-top: 56px;
`;

export const Drawer = styled(RawDrawer).attrs(() => ({ size: 700, position: 'right' }))``;

export const Title = styled.h4`
  color: #2b354a;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const Header = styled.div`
  padding: 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  padding: 16px;
`;
