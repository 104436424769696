import React, { useCallback, useState, useContext, useEffect } from 'react';
import { FlatButton } from 'liber-components';
import PropTypes from 'prop-types';
import * as S from './styles';
import OptionsFilter from '../../../Filters/OptionsFilter/OptionsFilter';
import { SingleDateFieldFilter } from '../../../Filters';
import { FILTER_TYPES } from '../../constants';
import { useVendorGroups } from './hooks/useVendorGroups';
import { TokenContext } from '../../../../hocs/withTokenProvider';
import Loading from '../../../Loading';

const initialFilters = {
  [FILTER_TYPES.VENDOR_GROUP_FILTER]: undefined,
  [FILTER_TYPES.FINANCING_DATE_FILTER]: undefined,
};

export const Filters = ({ handleFilter }) => {
  const { token } = useContext(TokenContext);
  const { isLoading, vendorGroupsList } = useVendorGroups(token);
  const [filters, setFilters] = useState(initialFilters);

  const handleChangeFilter = (field, value) => {
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);
    handleFilter(newFilters);
  };

  useEffect(() => {
    if (vendorGroupsList && vendorGroupsList.length) {
      const defaultGroup = vendorGroupsList.find(({ isDefaultGroup }) => isDefaultGroup);
      if (defaultGroup) {
        setFilters({
          ...filters,
          [FILTER_TYPES.VENDOR_GROUP_FILTER]: defaultGroup ? defaultGroup.value : undefined,
        });
      }
    }
  }, [vendorGroupsList]);

  const hasFilters = useCallback(
    () =>
      Object.keys(filters).reduce((flag, key) => {
        const hasValue = Boolean(filters[key]);

        if (hasValue && filters[key] instanceof Array) {
          return flag || filters[key].length > 0;
        }
        return flag || hasValue;
      }, false),
    [filters],
  );

  const clearFilters = () => {
    setFilters(initialFilters);
    handleFilter(initialFilters);
  };

  return (
    <Loading isLoading={isLoading}>
      <S.Container>
        <S.Flex>
          <S.Label>Filtros</S.Label>
          <OptionsFilter
            filterValues={filters[FILTER_TYPES.VENDOR_GROUP_FILTER]}
            onFilter={selected => handleChangeFilter(FILTER_TYPES.VENDOR_GROUP_FILTER, selected)}
            onClear={() => handleChangeFilter(FILTER_TYPES.VENDOR_GROUP_FILTER, undefined)}
            options={vendorGroupsList}
            label="Grupo"
            allowMultiple={false}
          />
          <SingleDateFieldFilter
            label="Data"
            description="Selecione a data para buscar o filtro"
            onFilter={date => handleChangeFilter(FILTER_TYPES.FINANCING_DATE_FILTER, date)}
            onClear={() => handleChangeFilter(FILTER_TYPES.FINANCING_DATE_FILTER, undefined)}
            selectedDate={filters[FILTER_TYPES.FINANCING_DATE_FILTER]}
          />
          {hasFilters() && <FlatButton onClick={() => clearFilters()}>Limpar Filtros</FlatButton>}
        </S.Flex>
      </S.Container>
    </Loading>
  );
};

Filters.propTypes = {
  handleFilter: PropTypes.func.isRequired,
};

export default Filters;
