import { handleThemeFromObject } from 'liber-components';
import styled from 'styled-components';

export const LoginContent = styled.div`
  width: 100%;
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: ${props => (props.width ? props.width : '351.8px')};
  }
  @media (min-width: 1200px) {
    width: ${props => (props.width ? props.width : '351.8px')};
  }
`;

export const LoginCard = styled.div`
  border-radius: 2px;
  background-color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  flex-flow: column-reverse;
  margin-top: 50px;
  margin-bottom: 16px;
  overflow: hidden;

  @media (min-width: 576px) {
    flex-flow: column-reverse;
    padding: 24px;
    width: 476px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    flex-flow: column-reverse;
    padding: 24px;
    width: 476px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
    margin-bottom: 16px;
  }
  @media (min-width: 992px) {
    flex-flow: row;
    padding: ${props => (props.padding ? props.padding : '48px')};
    width: unset;
    margin-bottom: 50px;
  }
  @media (min-width: 1200px) {
    flex-flow: row;
    padding: ${props => (props.padding ? props.padding : '48px')};
    width: unset;
    margin-bottom: 50px;
  }
`;

export const InfoText = styled.p`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
`;
