import styled from 'styled-components';
import { media, handleThemeFromObject } from 'liber-components';

export const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  ${media.large`
    flex-direction: row;
  `}
`;

export const TooltipContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-width: ${props => props.width || '300px'};
`;

export const TooltipTitle = styled.span`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
  white-space: nowrap;
  flex-basis: 60%;
  margin-bottom: 7px;
  color: '#13ce66';
`;

export const TooltipValue = styled(TooltipTitle)`
  flex-basis: 40%;
  opacity: 0.55;
  color: '#13ce66';
`;

export const TooltipFooter = styled.span`
  font-family: inherit;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: center;
  color: #dedede;
  width: 100%;
`;

export const TooltipList = styled.ul`
  margin-bottom: 0px;
  padding: 0;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
  & li {
    color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  }
  & li::before {
    content: '• ';
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
    color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  }
`;

export const TooltipText = styled.div`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  padding: 5px;
  width: 200px;
  line-height: 16px;
`;
