export const apiUrl = SERVICE_API_URL; // eslint-disable-line

export const VENDORS_PATH = '/sacado/fornecedores';
export const VENDORS_GROUP_HISTORY_PATH = '/sacado/fornecedores/grupos/:id/historico';
export const EDIT_VENDORS_GROUP_PATH = '/sacado/fornecedores/grupos/:id/edit';
export const VENDORS_GROUP_DETAILS_PATH = '/sacado/fornecedores/grupos/:id';
export const CREATE_VENDORS_GROUP_PATH = '/sacado/fornecedores/grupos/new';
export const FETCH_VENDORS_GROUPS_URL = `${apiUrl}/api/v1/buyer/vendors_groups`;
export const UPLOAD_URL = `${apiUrl}/api/v1/buyer/vendors_group_spreadsheets`;
export const ARCHIVE_GROUP_URL = `${apiUrl}/api/v1/buyer/vendors_groups/:id`;
export const UPDATE_INCREMENT_URL = `${apiUrl}/api/v1/buyer/vendors_groups/:id`;

export const MOCK_VENDORS_GROUPS_PAYLOAD = {
  data: {
    buyer: {
      allow_rebate_increment: false,
    },
    vendors_groups: [
      {
        id: 1,
        reference_code: 'DEF456',
        name: 'Padrão',
        default: true,
        active: true,
        vendors_count: 123,
        rebate_increment: '0.0',
      },
      {
        id: 2,
        reference_code: 'GHI789',
        name: 'ESG',
        default: false,
        active: false,
        vendors_count: 72,
        rebate_increment: '0.0',
      },
    ],
    pagination: {
      current: 1,
      previous: null,
      next: null,
      per: 25,
      count: 2,
    },
  },
};
