import React from 'react';
import PropTypes from 'prop-types';
import { Modal, FlatButton } from 'liber-components';
import { DialogHeader, Title, DialogContent, DialogActions } from './styles';

const InvoicesAvailabilityDialog = ({ open, onClose }) => (
  <Modal show={open} onLeaved={onClose}>
    <DialogHeader>
      <Title>Informações sobre o processo de disponibilização de notas para antecipação</Title>
    </DialogHeader>
    <DialogContent>
      <div>
        <ul>
          <li>
            As notas devem estar lançadas e desbloqueadas no sistema da BRF (com status
            &quot;Autorizado para pagamento&quot;).
          </li>
          <li>Os documentos são enviados com 2 dias úteis após o lançamento.</li>
          <li>O vencimento deve ser superior a 10 dias e inferior a 180 dias.</li>
          <li>
            As operações ficam disponíveis na plataforma Liber às terças e quintas, sendo que o
            crédito ocorrerá no dia útil seguinte ao dia da solicitação da antecipação.
          </li>
        </ul>
      </div>
      <div>
        Para confirmar se suas notas estão lançadas e disponíveis, consulte o portal de finanças da
        BRF{' '}
        <a
          href="https://brfprd.service-now.com/parceiros?id=csm_new_login"
          target="_blank"
          rel="noreferrer"
        >
          clicando aqui
        </a>
        .
      </div>
    </DialogContent>
    <DialogActions>
      <FlatButton onClick={onClose}>FECHAR</FlatButton>
    </DialogActions>
  </Modal>
);

InvoicesAvailabilityDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

InvoicesAvailabilityDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default InvoicesAvailabilityDialog;
