import axios from 'axios';
import { snakeCase } from 'lodash';
import {
  BLOCK_VENDOR_URL,
  UNBLOCK_VENDOR_URL,
  VENDORS_URL,
  RESET_PASSWORD_URL,
  SCHEDULE_VENDOR_DATA_SEND_PATH,
} from '../urls';
import { handleError, convertToCamelCase, handleSuccess } from '../../../vendor/Utils';

export const FETCH_VENDORS = 'ADMIN_FETCH_VENDORS';
export const UPDATE_VENDORS = 'ADMIN_UPDATE_VENDORS';
export const UPDATE_SORTING = 'ADMIN_UPDATE_SORTING';
export const CHANGE_PAGE = 'ADMIN_CHANGE_PAGE';
export const CHANGE_LISTING = 'ADMIN_CHANGE_LISTING';
export const UPDATE_FILTERS = 'ADMIN_UPDATE_FILTERS';
export const RESET_PASSWORD = 'ADMIN_RESET_PASSWORD';
export const HIDE_SCHEDULES = 'HIDE_SCHEDULES';
export const SET_SELECTED_VENDORS = 'SET_SELECTED_VENDORS';
export const SHOW_SCHEDULES_MODAL = 'SHOW_SCHEDULES_MODAL';
export const SET_SELECTED_VENDOR_BUYER_ID = 'SET_SELECTED_VENDOR_BUYER_ID';
export const SET_SHOW_FUNDERS_LIST_MODAL = 'SET_SHOW_FUNDERS_LIST_MODAL';
export const SET_SELECTED_VENDORS_FUNDERS_IDS = 'SET_SELECTED_VENDORS_FUNDERS_IDS';
export const SET_SELECTED_VENDOR_ID = 'SET_SELECTED_VENDOR_ID';
export const CHANGE_SELECTED_FUNDER_TO_BLOCK_OR_UNBLOCK =
  'CHANGE_SELECTED_FUNDER_TO_BLOCK_OR_UNBLOCK';
export const CHANGE_BLOCK_UNBLOCK_MODAL_STATUS = 'CHANGE_BLOCK_UNBLOCK_MODAL_STATUS';
export const CHANGE_INTERMEDIATE_STATUS_MODAL = 'CHANGE_INTERMEDIATE_STATUS_MODAL';
export const SET_SHOW_BATCH_BLOCK_UNBLOCK_MODAL = 'SET_SHOW_BATCH_BLOCK_UNBLOCK_MODAL';
export const SET_SELECTED_CNPJS_TO_BATCH_BLOCK_OR_UNBLOCK =
  'SET_SELECTED_CNPJS_TO_BATCH_BLOCK_OR_UNBLOCK';
export const SET_IS_BATCH_BLOCK_UNBLOCK = 'SET_IS_BATCH_BLOCK_UNBLOCK';

export const VENDOR_FILTER = 'q[email_or_company_or_trade_name_or_cnpj_contains_any]';
export const BUYER_FILTER = 'q[sacado_company_or_sacado_trade_name_or_sacado_cnpj_cont]';
export const SIGNATURE_METHOD_FILTER = 'q[politica_assinatura_termo_cessao_in]';
export const SIGNATURE_CERTIFICATION_FILTER = 'q[certificadora_in]';
export const SORT = 'q[s]';

const formatSortingToRequest = sorting => {
  const formattedSorting = sorting.map(
    ({ columnName, direction }) => `${snakeCase(columnName)} ${direction}`,
  );

  return {
    [SORT]: formattedSorting,
  };
};

export const updateFilters = filters => ({ type: UPDATE_FILTERS, payload: { filters } });

export const updateVendors = data => {
  const formattedData = convertToCamelCase(data);

  return {
    type: UPDATE_VENDORS,
    payload: {
      ...formattedData,
    },
  };
};

export const updateHideSchedules = hideSchedules => ({
  type: HIDE_SCHEDULES,
  payload: {
    showSchedules: !hideSchedules,
  },
});

export const updateSorting = sortInfo => ({
  type: UPDATE_SORTING,
  payload: { sorting: [sortInfo] },
});

export const setPage = page => ({ type: CHANGE_PAGE, payload: { page } });

export const changeToNextPage = () => (dispatch, getState) => {
  const {
    adminVendors: {
      pagination: { next },
    },
  } = getState();

  dispatch(setPage(next));
};

export const changeToPreviousPage = () => (dispatch, getState) => {
  const {
    adminVendors: {
      pagination: { previous },
    },
  } = getState();

  dispatch(setPage(previous));
};

export const changeListing = listing => ({
  type: CHANGE_LISTING,
  payload: {
    per: parseInt(listing, 10),
  },
});

export const fetchVendors = () => (dispatch, getState) => {
  const {
    adminVendors: {
      token,
      filters,
      sorting,
      pagination: { current, per },
    },
  } = getState();

  dispatch({ type: FETCH_VENDORS });

  const sort = formatSortingToRequest(sorting);

  return axios
    .get(VENDORS_URL, {
      headers: { Authorization: `Bearer ${token}` },
      params: { ...filters, ...sort, page: current, per },
    })
    .then(response => {
      const {
        data: { data, hide_schedules: hideSchedules },
      } = response;

      dispatch(updateHideSchedules(hideSchedules));
      dispatch(updateVendors(data));
    })
    .catch(() => {
      handleError();
    });
};

export const requestPasswordReset = vendorId => (dispatch, getState) => {
  const {
    adminVendors: { token },
  } = getState();

  dispatch({ type: RESET_PASSWORD });

  return axios
    .post(RESET_PASSWORD_URL, { id: vendorId }, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => {
      const {
        data: {
          data: { email },
        },
      } = response;

      handleSuccess('Senha resetada', `E-mail enviado para ${email}`);
    })
    .catch(() => handleError());
};

export const setSchedule = data => (_, getState) => {
  const {
    adminVendors: { token },
  } = getState();

  return axios
    .post(SCHEDULE_VENDOR_DATA_SEND_PATH, data, { headers: { Authorization: `Bearer ${token}` } })
    .then(() => {
      handleSuccess('Sucesso', `Agendamento realizado com sucesso`);
    })
    .catch(() => handleError());
};

export const blockVendor =
  ({ tradeName, id }, reason) =>
  (dispatch, getState) => {
    const {
      adminVendors: { token },
    } = getState();

    return axios
      .post(
        BLOCK_VENDOR_URL,
        {
          vendor_ids: [id],
          comment: reason,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then(() => {
        dispatch(fetchVendors());
        handleSuccess('Sucesso', `Fornecedor ${tradeName} bloqueado com sucesso`);
      })
      .catch(() => handleError());
  };

export const unblockVendor =
  ({ tradeName, id }, reason) =>
  (dispatch, getState) => {
    const {
      adminVendors: { token },
    } = getState();

    return axios
      .post(
        UNBLOCK_VENDOR_URL,
        {
          vendor_ids: [id],
          comment: reason,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then(() => {
        dispatch(fetchVendors());
        handleSuccess('Sucesso', `Fornecedor ${tradeName} desbloqueado com sucesso`);
      })
      .catch(() => handleError());
  };

export const updateSelectedVendorBuyerId = selectedVendorBuyerId => ({
  type: SET_SELECTED_VENDOR_BUYER_ID,
  payload: { selectedVendorBuyerId },
});

export const updateSelectedVendorsFundersIds = fundersIds => ({
  type: SET_SELECTED_VENDORS_FUNDERS_IDS,
  payload: { fundersIds },
});

export const updateSelectedVendorId = vendorId => ({
  type: SET_SELECTED_VENDOR_ID,
  payload: { vendorId },
});

export const updateSelectedFunderToBlockOrUnblock = payload => ({
  type: CHANGE_SELECTED_FUNDER_TO_BLOCK_OR_UNBLOCK,
  payload,
});

export const setShowVendorBlockUnblockModal = payload => ({
  type: CHANGE_BLOCK_UNBLOCK_MODAL_STATUS,
  payload,
});

export const updateHandleIntermediateStatusModal = payload => ({
  type: CHANGE_INTERMEDIATE_STATUS_MODAL,
  payload,
});

export const handleVendorBlockUnblockModalCancel = vendorId => dispatch => {
  dispatch(setShowVendorBlockUnblockModal(false));
  dispatch(updateSelectedVendorId(vendorId));
};

export const handleShowVendorBlockUnblockModal = blockUnblockPayload => dispatch => {
  dispatch(updateHandleIntermediateStatusModal(false));
  dispatch(updateSelectedFunderToBlockOrUnblock(blockUnblockPayload));
  dispatch(setShowVendorBlockUnblockModal(true));
  dispatch(updateSelectedVendorId(null));
};

export const handleIntermediateBlockUnblockStatuses = payload => dispatch => {
  dispatch(updateSelectedVendorId(null));
  dispatch(updateSelectedFunderToBlockOrUnblock(payload));
  dispatch(updateHandleIntermediateStatusModal(true));
};

export const setSelectedVendors = selectedVendors => dispatch => {
  const uniqueFundersIds = selectedVendors.reduce((acc, { fundersIds }) => {
    acc.push(...fundersIds);
    return [...new Set(acc)];
  }, []);

  dispatch(updateSelectedVendorsFundersIds(uniqueFundersIds));

  dispatch({
    type: SET_SELECTED_VENDORS,
    payload: { selectedVendors },
  });
};

export const changeShowSchedulesModal = shouldShowSchedulesModal => ({
  type: SHOW_SCHEDULES_MODAL,
  payload: { shouldShowSchedulesModal },
});

export const updateShowFundersListModal = shouldShowFundersListModal => ({
  type: SET_SHOW_FUNDERS_LIST_MODAL,
  payload: { shouldShowFundersListModal },
});

export const setShowBatchBlockUnblockModal = payload => ({
  type: SET_SHOW_BATCH_BLOCK_UNBLOCK_MODAL,
  payload,
});

export const handleSubmitBatchBlockUnblock = blockUnblockPayload => dispatch => {
  dispatch(setShowBatchBlockUnblockModal(false));
  dispatch(handleShowVendorBlockUnblockModal(blockUnblockPayload));
};

export const setSelectedCnpjsToBatchBlockOrUnblock = cnpjs => ({
  type: SET_SELECTED_CNPJS_TO_BATCH_BLOCK_OR_UNBLOCK,
  payload: { cnpjs },
});

export const setIsBatchBlockUnblock = isBatchBlockUnblock => ({
  type: SET_IS_BATCH_BLOCK_UNBLOCK,
  payload: { isBatchBlockUnblock },
});

export const handleClearBatchBlockUnblock = () => dispatch => {
  dispatch(setSelectedCnpjsToBatchBlockOrUnblock([]));
  dispatch(setIsBatchBlockUnblock(false));
};
