import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from '../../../../../../common-components/PerfectScrollbar';
import { Text, Title, BoxContainer, CheckboxRow, StyledCheckbox } from './SimulationTerms.styles';

export const SimulationTerms = ({ checked, onChange }) => (
  <>
    <Text>
      O relatório de simulação contém informações que podem variar ao fim do dia corrente
      (22/10/2019). Por favor, leia e aceite os termos a seguir para continuar.
    </Text>
    <BoxContainer>
      <PerfectScrollbar />
      <Title>Termos</Title>
      <Text>
        Este relatório foi elaborado pela Liber Capital e tem como objetivo fornecer informações que
        possam auxiliar o fornecedor, não constituindo qualquer tipo de oferta ou solicitação de
        compra/venda de título de crédito. A Liber Capital não se responsabiliza por qualquer
        decisão do fornecedor baseadas neste relatório.
        <br />
        <br />
        O signatário deste relatório declara que as recomendações refletem única e exclusivamente em
        análises pessoais, estando ciente de que se trata de uma simulação passível de modificações
        sem aviso prévio em decorrência de alterações nas condições de mercado.
        <br />
        <br />
        As informações presentes neste material são baseadas em simulações e os resultados reais
        poderão ser significativamente diferentes.
        <br />
        <br />
        A taxa de desconto é variável e o fornecedor será avisado do valor durante a negociação das
        operações.
        <br />
        <br />
        Este relatório e a simulação a ser realizada é destinado exclusivamente ao fornecedor
        signatário, sendo proibida sua reprodução ou redistribuição, no todo ou em parte, qualquer
        que seja o propósito.
      </Text>
    </BoxContainer>
    <CheckboxRow>
      <StyledCheckbox id="agreement" checked={checked} onChange={onChange}>
        Li e estou de acordo com o conteúdo apresentado nos Termos do Relatório de Simulação.
      </StyledCheckbox>
    </CheckboxRow>
  </>
);

SimulationTerms.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimulationTerms;
