import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'liber-components';
import { smoothScroll } from '../../actions/wizard';
import { steps } from '../../reducers/progressBar';
import ProgressBar from '../ProgressBar';
import {
  Confirmacao,
  Contratos,
  DadosBancarios,
  DadosPessoais,
  Documentacao,
  Enderecos,
  Loading,
  SolicitacaoEnviada,
} from '../Steps';

class Wizard extends React.Component {
  componentDidMount() {
    this.props.fetchData();
    this.props.postClientLog('INVESTIDOR/WIZARD_KYC');
    this.props.flashes.map(flash => {
      const [key, value] = flash;
      if (value) toast(value, key, 8000);
      return flash;
    });
    window.addEventListener('hashchange', () => this.setHashFromCurrent(this.props.current, true));
  }

  componentWillUpdate(nextProps) {
    if (nextProps.current !== this.props.current) this.setHashFromCurrent(nextProps.current, false);
  }

  componentDidUpdate() {
    smoothScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', () =>
      this.setHashFromCurrent(this.props.current, true),
    );
  }

  setHashFromCurrent = (current, hashChange) => {
    if (window.location.hash && hashChange) {
      const hash = Object.keys(steps).indexOf(window.location.hash.replace('#', '')) + 1;
      const step =
        hash === 0 || hash > this.props.completed + 1 || this.props.current === 7 ? current : hash;
      this.props.setCurrent(step);
    } else {
      this.props.setWindowHash(current);
    }
  };

  getCurrentStep = () => {
    switch (this.props.current) {
      default:
        return <Loading />;
      case 1:
        return <DadosPessoais />;
      case 2:
        return <Documentacao />;
      case 3:
        return <Enderecos />;
      case 4:
        return <DadosBancarios />;
      case 5:
        return <Confirmacao />;
      case 6:
        return <Contratos />;
      case 7:
        return <SolicitacaoEnviada />;
    }
  };

  getLabel = () => {
    const { current } = this.props;
    if (current === 5) {
      return 'Confirmação';
    }
    if (current === 6) {
      return 'Contratos';
    }
    if (current === 7) {
      return 'Solicitação Enviada';
    }
    return null;
  };

  render = () => {
    if (this.props.approved && this.props.current === 7) {
      this.props.redirectTo(this.props.root);
      return (
        <>
          <Loading />
        </>
      );
    }
    return (
      <>
        <ProgressBar label={this.getLabel()} />
        {this.getCurrentStep()}
      </>
    );
  };
}

Wizard.propTypes = {
  approved: PropTypes.bool.isRequired,
  current: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  root: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
  postClientLog: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  setWindowHash: PropTypes.func.isRequired,
};

export default Wizard;
