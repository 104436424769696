import Immutable from 'seamless-immutable';

const initialState = Immutable({
  adesao_investidor: '',
  condicoes_uso_plataforma: '',
  mailer_subscription: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'WIZARD_INVESTIDOR/CONTRATOS/MAILER_SUBSCRIPTION':
      return state.merge({ mailer_subscription: action.payload });
    case 'WIZARD_INVESTIDOR/CONTRATOS/SET_DATA':
      return state.merge({ ...action.payload });
    default:
      return state;
  }
};
