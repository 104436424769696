import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #405f71;
  }
`;

export const BlockReasonMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;

  span,
  svg {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #405f71;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResendButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const InformationButton = styled.div`
  cursor: pointer;
  width: 16px;

  svg {
    color: #405f71;
  }
`;
