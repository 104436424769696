import React from 'react';
import PropTypes from 'prop-types';
import { Form, TextField } from 'liber-components';
import Flash from '../Flash';
import { Title, ButtonGreen, InfoText, ButtonDiv, LoginContent, LoginCard } from '../styles';

const PasswordResetForm = ({
  password,
  passwordError,
  confirmation,
  changeToken,
  changePassword,
  changeConfirmation,
  submitToken,
  submitForm,
  passwordPath,
  flashes,
  origin,
  token,
  tokenManual,
  tokenError,
}) => {
  const tokenInput = (
    <input
      type="hidden"
      value={token}
      name={`${origin}[reset_password_token]`}
      id={`${origin}_reset_password_token`}
    />
  );
  const tokenField = (
    <>
      <TextField
        fullWidth
        label="Token"
        errorMessage={tokenError}
        value={tokenManual}
        onChange={value => changeToken(value)}
        name={`${origin}[reset_password_token]`}
        id={`${origin}_reset_password_token`}
      />
      <ButtonDiv>
        <ButtonGreen onClick={() => submitToken(tokenManual.trim(), passwordPath)} width="100%">
          Confirmar
        </ButtonGreen>
      </ButtonDiv>
    </>
  );

  return (
    <LoginCard>
      <LoginContent width="467px">
        <Title>NOVA SENHA</Title>
        <Flash flashes={flashes} />
        {token ? (
          <InfoText>
            Escolha uma nova senha para completar o processo de alteração de senha.
          </InfoText>
        ) : (
          <InfoText>
            Não foi possível recuperar o <b>token</b> de confirmação.
            <br />
            Por favor, digite-o no campo abaixo como mandado no email:
          </InfoText>
        )}
        {token ? (
          <Form
            action={passwordPath}
            method="POST"
            onSubmit={event => {
              submitForm(event, password, confirmation);
            }}
          >
            <input type="hidden" name="_method" value="put" />
            {tokenInput}
            <TextField
              fullWidth
              type="password"
              label="Senha"
              errorMessage={passwordError}
              value={password}
              onChange={value => changePassword(value)}
              name={`${origin}[password]`}
              id={`${origin}_password`}
            />
            <TextField
              fullWidth
              type="password"
              label="Confirmação de Senha"
              value={confirmation}
              onChange={value => changeConfirmation(value, password)}
              name={`${origin}[password_confirmation]`}
              id={`${origin}_password_confirmation`}
            />
            <ButtonDiv>
              <ButtonGreen width="100%">Confirmar</ButtonGreen>
            </ButtonDiv>
          </Form>
        ) : (
          tokenField
        )}
      </LoginContent>
    </LoginCard>
  );
};

PasswordResetForm.propTypes = {
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  confirmation: PropTypes.string.isRequired,
  changeToken: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeConfirmation: PropTypes.func.isRequired,
  submitToken: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  passwordPath: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  origin: PropTypes.string.isRequired,
  token: PropTypes.string,
  tokenManual: PropTypes.string.isRequired,
  tokenError: PropTypes.string.isRequired,
};

PasswordResetForm.defaultProps = {
  token: '',
};

export default PasswordResetForm;
