import styled from 'styled-components';
import { Card } from 'liber-salti';

export const Container = styled(Card)`
  max-width: 456px;
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  background-color: white;
`;
