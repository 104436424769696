import Immutable from 'seamless-immutable';
import axios from 'axios';

import { INVOICES_URL_V2 } from '../../url';
import { handleError, convertToCamelCase } from '../../../../vendor/Utils';

export const DIALOG_FETCH_CONTENT = 'investor-invoices-dialog/DIALOG_FETCH_CONTENT';
export const DIALOG_UPDATE_CONTENT = 'investor-invoices-dialog/DIALOG_UPDATE_CONTENT';
export const DIALOG_REJECT_CONTENT = 'investor-invoices-dialog/DIALOG_REJECT_CONTENT';
export const DIALOG_OPEN = 'investor-invoices-dialog/DIALOG_OPEN';
export const DIALOG_CLOSE = 'investor-invoices-dialog/DIALOG_CLOSE';
export const CLEAR_DIALOG_CONTENT = 'investor-invoices-dialog/CLEAR_DIALOG_CONTENT';

export const openInvoicesDialog = title => ({ type: DIALOG_OPEN, payload: { title } });
export const closeInvoicesDialog = () => ({ type: DIALOG_CLOSE });
export const updateDialogContent = payload => ({ type: DIALOG_UPDATE_CONTENT, payload });

export function getDialogContent(params = {}, title) {
  return async (dispatch, getState) => {
    dispatch({ type: CLEAR_DIALOG_CONTENT });
    dispatch(openInvoicesDialog(title));

    const {
      investorDashboard: {
        invoicesDialog: { token },
      },
    } = getState();

    return axios
      .get(INVOICES_URL_V2, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(response => {
        const {
          data: { data },
        } = response;

        const { buyerInvoices, summary } = convertToCamelCase(data);

        dispatch(updateDialogContent({ buyerInvoices, summary }));
      })
      .catch(() => {
        handleError();
        dispatch({ type: DIALOG_REJECT_CONTENT });
      });
  };
}

const INITIAL_SUMMARY = {
  settle: 0,
  invested: 0,
  yields: 0,
};
const INITIAL_TITLE = 'Carteira de títulos';

export const initialState = Immutable({
  token: '',
  loading: true,
  show: false,
  title: INITIAL_TITLE,
  buyerInvoices: [],
  summary: INITIAL_SUMMARY,
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case DIALOG_OPEN:
      return state.merge({ loading: true, show: true, title: payload?.title || INITIAL_TITLE });
    case CLEAR_DIALOG_CONTENT:
      return state.merge({ buyerInvoices: [], summary: INITIAL_SUMMARY });
    case DIALOG_CLOSE:
      return state.merge({ show: false });
    case DIALOG_REJECT_CONTENT:
      return state.merge({ loading: false, buyerInvoices: [], summary: INITIAL_SUMMARY });
    case DIALOG_UPDATE_CONTENT: {
      const { buyerInvoices, summary } = payload;
      const { title } = state;

      const invoicesCount = buyerInvoices.reduce(
        (total, { buyer: { invoices } }) => total + (invoices ? invoices.length : 0),
        0,
      );

      return state.merge({
        title: `${title} (${invoicesCount})`,
        loading: false,
        buyerInvoices,
        summary,
      });
    }
    default:
      return state;
  }
};
