import styled, { css } from 'styled-components';
import {
  media,
  Card as CardRaw,
  MdInformation,
  LoadingBunny,
  CardHeader as CardHeaderRaw,
  handleThemeFromObject,
} from 'liber-components';
import { Container as ContainerRaw } from 'liber-components/components/GenericStyles';

export const Container = styled(ContainerRaw)`
  padding: 0px;
`;

export const Card = styled(CardRaw)`
  ${media.large`
  width: 100%;
  margin-right:0px;
`}
  ${media.extraLarge`
  width: ${props => props.width};
  margin-right: 13px;
  margin-top: 6.5px;
  margin-bottom: 6.5px;
`}
`;

export const LoadingBlock = styled.div`
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  opacity: ${props => (props.loading ? props.opacity || '0.3' : '1')};
  pointer-events: ${props => (props.loading ? 'none' : null)};
  transition: opacity 150ms ease-in-out;
  display: inherit;
  flex-direction: inherit;
  position: inherit;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  ${media.extraLarge`
  flex-direction: row;
  `}

  & * {
    font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  }
`;

export const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  ${media.large`
  flex-direction: row;
  `}
`;

export const BigTooltipContent = styled.div`
  ${({ width }) =>
    width &&
    css`
      @media (min-width: 440px) {
        max-width: ${width};
        min-width: ${width};
      }
    `}

  ${({ mobileWidth }) =>
    mobileWidth &&
    css`
      @media (max-width: 439px) {
        max-width: ${mobileWidth};
        min-width: ${mobileWidth};
      }
    `}
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
`;

export const Cell = styled.div`
  flex-grow: 1;
  align-self: stretch;
  flex-basis: 50%;
  margin-right: 5px;
  margin-left: 5px;
  max-width: 100%;

  ${media.large`
  margin-right: 5px;
  margin-left: 5px;
  `}
  ${media.extraLarge`
  margin-right: 0px;
  margin-left: 0px;
  &:last-child{
    margin-right: 5px;
  }
  &:first-child{
    margin-left: 5px;
  }
  `}
`;

export const TooltipContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-width: ${props => props.width || '300px'};
`;

export const PaddingContainer = styled.div`
  padding: 4px;
`;

export const TooltipTitle = styled.span`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
  white-space: nowrap;
  flex-basis: 60%;
  margin-bottom: 7px;
`;

export const TooltipValue = styled(TooltipTitle)`
  flex-basis: 40%;
  opacity: 0.55;
`;

export const TooltipFooter = styled.span`
  font-family: inherit;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: center;
  color: #dedede;
  width: 100%;
`;

export const TooltipList = styled.ul`
  margin-bottom: 0px;
  padding: 0;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
  & li {
    color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  }
  & li::before {
    content: '• ';
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
    color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
    /* or whatever color you prefer */
  }
`;

export const TooltipText = styled.div`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  padding: 5px;
  width: 200px;
  line-height: 16px;
`;

export const Title = styled.h2`
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h3', '32px')};
  margin: 24px 0px calc(24px - 6.5px) 0px;
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
`;

export const Link = styled.a`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  padding: 10px 20px;
  align-self: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  }
  &:hover svg {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  }
  & svg {
    width: 20px;
    height: 20px;
    margin-right: 11px;
    fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  }
  ${media.large`
    align-self: flex-end;
  `}
`;

export const InfoBox = styled.div`
  width: 160px;
`;

export const InfoIcon = styled(MdInformation)`
  width: 16px;
  height: 16px;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  cursor: pointer;
  &:hover {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  }
`;

export const InfoIconWrap = styled.div`
  position: relative;

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}

  & > ${InfoIcon} {
    margin-top: -5px;
  }
`;

export const Loading = styled(LoadingBunny)`
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
`;

export const CardHeader = styled(CardHeaderRaw)`
  padding: 8px 16px;
  height: fit-content;
  color: #6287a7;
`;

export const FlexRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22.76px !important;
`;
