import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Value, SubValue } from './ValueDisplay.styles';

const ValueDisplay = ({ label, children, subValue, noPadding }) => (
  <Container noPadding={noPadding}>
    <Label>{label}</Label>
    <Value>{children}</Value>
    <SubValue>{subValue}</SubValue>
  </Container>
);

ValueDisplay.propTypes = {
  label: PropTypes.string,
  subValue: PropTypes.string,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
};

ValueDisplay.defaultProps = {
  children: null,
  label: null,
  subValue: null,
  noPadding: false,
};

export default ValueDisplay;
