import React from 'react';
import PropTypes from 'prop-types';
import { LiberV4, ToastContainer } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import * as S from './styles';
import PageHeader from './components/PageHeader';
import { BuyerOrganizationGroupProvider } from './context';
import GroupInfo from './components/GroupInfo';
import BuyersInfo from './components/BuyersInfo';

const ShowBuyerOrganizationGroup = ({ token, buyerOrganizationGroupId }) => (
  <ThemeProvider theme={LiberV4}>
    <BuyerOrganizationGroupProvider
      token={token}
      buyerOrganizationGroupId={buyerOrganizationGroupId}
    >
      <S.Container>
        <PageHeader />
        <GroupInfo />
        <BuyersInfo />
      </S.Container>

      <ToastContainer />
    </BuyerOrganizationGroupProvider>
  </ThemeProvider>
);

ShowBuyerOrganizationGroup.propTypes = {
  token: PropTypes.string.isRequired,
  buyerOrganizationGroupId: PropTypes.string.isRequired,
};

export default ShowBuyerOrganizationGroup;
