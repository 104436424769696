import React from 'react';
import * as S from './styles';
import DateFieldFilter from '../../../../common-components/Filters/DateFieldFilter/DateFieldFilter';
import { SchedulesContext } from '../../context';
import StatusCheckboxes from './components/StatusCheckboxes';

const AnticipationsTableFilters = () => {
  const { buildDateFilter, clearDateFilters, filtered, clearAllFilters } =
    React.useContext(SchedulesContext);

  return (
    <>
      <S.Label>Filtrar: </S.Label>
      <DateFieldFilter
        label="Data"
        description="Data do agendamento"
        onClear={clearDateFilters}
        onFilter={buildDateFilter}
      />
      <StatusCheckboxes />
      {filtered && <S.Button onClick={() => clearAllFilters()}>Limpar Filtros</S.Button>}
    </>
  );
};

export default AnticipationsTableFilters;
