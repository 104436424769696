import React, { useState, useEffect } from 'react';
import { TooltipBox } from 'liber-components';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { uniq } from 'lodash';
import TransferList from '../TransferList';
import { Container, TransferListsContainer, Button, CenterContainer } from './styles';
import { convertToCamelCase } from '../../../../vendor/Utils';

const VendorList = ({ vendors, currentVendors }) => {
  const [selectedAvailableVendorsIds, setSelectedAvailableVendorsIds] = useState([]);
  const [availableVendors, setAvailableVendors] = useState(convertToCamelCase(vendors));
  const [listVendors, setVendors] = useState(convertToCamelCase(currentVendors) || []);
  const [selectedVendorsIds, setSelectedVendorsIds] = useState([]);

  const moveLeftToRight = () => {
    const arrayBuffer = selectedAvailableVendorsIds.map(vendorId =>
      availableVendors.find(i => i.id === vendorId),
    );
    setVendors(item => [...arrayBuffer, ...item]);

    const newSelected = [];
    availableVendors.forEach(element => {
      if (arrayBuffer.indexOf(element) === -1) newSelected.push(element);
    });

    setAvailableVendors(newSelected);
    setSelectedAvailableVendorsIds([]);
  };

  const moveRightToLeft = () => {
    const arrayBuffer = selectedVendorsIds.map(VendorId =>
      listVendors.find(i => i.id === VendorId),
    );
    setAvailableVendors(item => [...arrayBuffer, ...item]);

    const newSelected = [];
    listVendors.forEach(element => {
      if (arrayBuffer.indexOf(element) === -1) newSelected.push(element);
    });

    setVendors(newSelected);
    setSelectedVendorsIds([]);
  };

  const handleSelectAvailableVendors = (e, allSelected, batchAction) => {
    let arrayBuffer = [...selectedAvailableVendorsIds];
    if (batchAction) {
      selectedAvailableVendorsIds.forEach(id => {
        const index = arrayBuffer.indexOf(id);
        if (allSelected) {
          arrayBuffer.splice(index, 1);
        } else if (index === -1) arrayBuffer = [...arrayBuffer, id];
      });
    } else {
      const index = arrayBuffer.indexOf(e[0]);
      if (index !== -1) {
        arrayBuffer.splice(index, 1);
      } else {
        arrayBuffer = [...arrayBuffer, ...e];
      }
    }
    arrayBuffer = uniq(arrayBuffer);
    setSelectedAvailableVendorsIds(arrayBuffer);
  };

  const handleSelectVendors = (e, allSelected, batchAction) => {
    let arrayBuffer = [...selectedVendorsIds];
    if (batchAction) {
      selectedVendorsIds.forEach(id => {
        const index = arrayBuffer.indexOf(id);
        if (allSelected) {
          arrayBuffer.splice(index, 1);
        } else if (index === -1) arrayBuffer = [...arrayBuffer, id];
      });
    } else {
      const index = arrayBuffer.indexOf(e[0]);
      if (index !== -1) {
        arrayBuffer.splice(index, 1);
      } else {
        arrayBuffer = [...arrayBuffer, ...e];
      }
    }
    arrayBuffer = uniq(arrayBuffer);
    setSelectedVendorsIds(arrayBuffer);
  };

  useEffect(() => {
    const vendorIdsInput = document.querySelector('.vendor-ids-input');
    vendorIdsInput.value = listVendors.map(({ id }) => id).join(',');
  }, [listVendors]);

  return (
    <>
      <Container>
        <TransferListsContainer>
          <TransferList
            vendors={availableVendors}
            selectedVendorsIds={selectedAvailableVendorsIds}
            vendorsCount={availableVendors.length}
            handleSelect={handleSelectAvailableVendors}
          />
          <CenterContainer>
            <TooltipBox mount="top" fixed content="Remover do grupo">
              <Button onClick={moveRightToLeft} active>
                <Icon path={mdiChevronLeft} />
              </Button>
            </TooltipBox>
            <TooltipBox mount="bottom" fixed content="Adicionar ao grupo">
              <Button data-testid="transferButton" onClick={moveLeftToRight} active>
                <Icon path={mdiChevronRight} />
              </Button>
            </TooltipBox>
          </CenterContainer>
          <TransferList
            vendors={listVendors}
            selectedVendorsIds={selectedVendorsIds}
            vendorsCount={listVendors.length}
            handleSelect={handleSelectVendors}
          />
        </TransferListsContainer>
      </Container>
    </>
  );
};

VendorList.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.shape({})),
  currentVendors: PropTypes.arrayOf(PropTypes.shape({})),
};

VendorList.defaultProps = {
  vendors: [],
  currentVendors: [],
};

export default VendorList;
