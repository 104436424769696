import styled from 'styled-components';
import { TextField as Input } from 'liber-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TextField = styled(Input)`
  height: 48px;
  max-width: 240px;
`;

export const FieldContainer = styled.div`
  margin-bottom: 30px;
`;

export const Caption = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #6287a7;
  width: 640px;
  margin-top: 32px;
`;
