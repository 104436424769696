import React, { useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CompleteTable } from 'liber-components';

import { ConfirmationContent, TableTitle } from './styles';
import { getPaginationInterval, handleError } from '../../../../vendor/Utils';
import { COLUMNS, SORTING_DIALOG_COLUMNS } from '../../constants';
import { removeLimit } from '../../api';
import { TokenContext } from '../../../../hocs/withTokenProvider';
import useSorting from '../../../../hooks/useSorting';
import Filters from './Filters/Filters';
import SortingDialog from '../../../../common-components/SortingDialog';
import Dialog from '../../../../common-components/Dialog/Dialog';
import VendorDrawer from '../VendorDrawer';
import { Context } from '../../context';
import Loading from '../../../../common-components/Loading';

const VendorsTable = ({ limitsAvailable, groupsAvailable }) => {
  const [showSortingDialog, setShowSortingDialog] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [removingLimit, setRemovingLimit] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});
  const { mustApproveVendors } = useContext(Context);

  const {
    vendors,
    filters,
    setSortingFilter,
    isLoading,
    handleChangeFilters,
    handleCleanAllFilters,
    updateVendors,
    currentPage,
    pages,
    setPageState,
    setCurrent,
    next,
    previous,
    onKeyDownPage,
    per,
    count,
    pagination,
    onSelectListing,
  } = useContext(Context);

  const [onSort, sorting, sortedColumns, setSorting] = useSorting(
    ['vendor', 'email', 'vendorsGroupName'],
    setSortingFilter,
    {},
  );

  const handleSorting = (column, sortInfo = {}) => {
    if (column?.name === 'vendor') {
      setShowSortingDialog(true);
    } else {
      onSort(column, sortInfo);
    }
  };

  const { token } = useContext(TokenContext);

  const handleConfirmRemoval = () => {
    setRemovingLimit(true);
    removeLimit(token, selectedVendor?.anticipationLimit?.id)
      .then(() => {
        setConfirmationOpen(false);
        setRemovingLimit(false);
        updateVendors();
      })
      .catch(error => {
        handleError('Erro!', error?.response?.data?.error);
        setRemovingLimit(false);
      });
  };

  const dialogProps = useMemo(
    () => ({
      open: confirmationOpen,
      onClose: () => setConfirmationOpen(false),
      confirmationButtonProps: {
        onConfirm: handleConfirmRemoval,
        label: 'REMOVER',
        color: 'danger',
        disabled: false,
        loading: removingLimit,
      },
      title: 'Remover Limite',
      children: (
        <ConfirmationContent>
          Tem certeza de que deseja remover o limite aplicado ao fornecedor{' '}
          {selectedVendor?.tradeName}?
        </ConfirmationContent>
      ),
    }),
    [selectedVendor, confirmationOpen, removingLimit],
  );

  const onRemoveLimit = useCallback(vendor => {
    setSelectedVendor(vendor);
    setConfirmationOpen(true);
  }, []);

  const columns = COLUMNS(
    limitsAvailable ? onRemoveLimit : null,
    groupsAvailable,
    mustApproveVendors,
  );

  return (
    <Loading isLoading={isLoading}>
      <TableTitle>Lista de fornecedores</TableTitle>
      <CompleteTable
        columns={columns}
        filters={
          <Filters
            clearFilters={handleCleanAllFilters}
            changeFilters={handleChangeFilters}
            mustApproveVendors={mustApproveVendors}
            filters={filters}
          />
        }
        items={vendors || []}
        paginationProps={{
          currentPage,
          pageTotal: pages,
          onChangePage: ({ target: { value } }) => setPageState(value),
          onClickNext: () => setCurrent(next),
          onClickPrev: () => setCurrent(previous),
          onKeyDownPage,
          hasListing: true,
          listingProps: {
            listing: String(per),
            total: String(count),
            showInterval: getPaginationInterval(pagination),
            onSelectListing,
            listingLabelSingular: 'fornecedor',
            listingLabelPlural: 'fornecedores',
          },
        }}
        sortingProps={{ onSort: handleSorting, sorting, sortedColumns }}
      />
      <SortingDialog
        open={showSortingDialog}
        onClose={() => setShowSortingDialog(false)}
        sorting={sorting}
        setSorting={setSorting}
        columns={SORTING_DIALOG_COLUMNS}
      />
      <Dialog {...dialogProps} />
      <VendorDrawer />
    </Loading>
  );
};

VendorsTable.propTypes = {
  setCount: PropTypes.func,
  limitsAvailable: PropTypes.bool,
  groupsAvailable: PropTypes.bool,
};

VendorsTable.defaultProps = {
  setCount: () => {},
  limitsAvailable: true,
  groupsAvailable: true,
};

VendorsTable.displayName = 'VendorsTable';

export default VendorsTable;
