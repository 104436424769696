import React, { useEffect, useContext } from 'react';
import { CompleteTable } from 'liber-components';

import { ScrollContainer, LimiterContainer } from './styles';
import { Bunny, Loading } from '../CommonStyles';
import usePageState from '../../../../hooks/usePageState';
import { getPaginationInterval } from '../../../../vendor/Utils';
import { buildColumns } from './columns';
import { VendorsGroupsContext } from '../../context';
import useSorting from '../../../../hooks/useSorting';

const GroupsTable = () => {
  const {
    loading,
    groups,
    allowRebateIncrement,
    sortingFilter,
    setSortingFilter,
    updateVendorsGroups,
    pagination,
    setCurrent,
    onSelectListing,
  } = useContext(VendorsGroupsContext);

  const { pages, current, next, previous, count, per } = pagination || {};
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const [onSort, sorting, sortedColumns] = useSorting(
    ['referenceCode', 'name', 'vendorsCount', 'active'],
    setSortingFilter,
    {},
  );

  const handleSorting = (column, sortInfo = {}) => {
    onSort(column, sortInfo);
  };

  useEffect(() => {
    updateVendorsGroups(current, per);
  }, [per, current, sortingFilter]);

  const COLUMNS = buildColumns(allowRebateIncrement);

  return (
    <Loading loading={loading ? 1 : 0}>
      {loading ? <Bunny /> : null}
      <ScrollContainer>
        <LimiterContainer>
          <CompleteTable
            columns={COLUMNS}
            items={groups || []}
            paginationProps={{
              currentPage,
              pageTotal: pages,
              onChangePage: ({ target: { value } }) => setPageState(value),
              onClickNext: () => setCurrent(next),
              onClickPrev: () => setCurrent(previous),
              onKeyDownPage,
              hasListing: true,
              listingProps: {
                listing: String(per),
                total: String(count),
                showInterval: getPaginationInterval(pagination),
                onSelectListing,
                listingLabelSingular: 'item',
                listingLabelPlural: 'itens',
              },
            }}
            sortingProps={{ onSort: handleSorting, sorting, sortedColumns }}
          />
        </LimiterContainer>
      </ScrollContainer>
    </Loading>
  );
};

GroupsTable.displayName = 'GroupsTable';

export default GroupsTable;
