import Immutable from 'seamless-immutable';
import {
  BORDERO_CANCELLATION_PROGRESS,
  BORDERO_CANCELLATION_SUCCESS,
  BORDERO_CANCELLATION_ERROR,
  CANCEL_BORDERO,
} from '../actions/AnticipationCancelActions';
import { CANCEL_ACTION_CABLE_ROOM } from '../urls';

export const initialState = Immutable({
  token: null,
  operator: {
    id: null,
    cpf: '',
    name: '',
  },
  cancellationProgress: 0,
  isFinished: false,
  hasError: false,
  isConnected: false,
});

export default (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case BORDERO_CANCELLATION_PROGRESS: {
      const {
        payload: { progress, total },
      } = action;
      const { cancellationProgress } = state;
      const updatedProgress = cancellationProgress + (progress / total) * 100;

      return state.merge({ cancellationProgress: updatedProgress });
    }

    case BORDERO_CANCELLATION_SUCCESS: {
      return state.merge({
        isFinished: true,
      });
    }
    case BORDERO_CANCELLATION_ERROR: {
      return state.merge({ hasError: true });
    }
    case CANCEL_BORDERO: {
      return state.merge({ hasError: false });
    }
    case `CONNECTED_SUBSCRIPTION`: {
      const {
        payload: { room },
      } = action;
      const {
        operator: { id },
      } = state;

      const isConnected = room === `${CANCEL_ACTION_CABLE_ROOM}${id}`;

      return state.merge({ isConnected });
    }
    default:
      return state;
  }
};
