import Immutable from 'seamless-immutable';
import { uniq } from 'lodash';
import {
  ADMIN_FETCH_TRADES_SUCCESS,
  ADMIN_CHANGE_PAGE,
  ADMIN_SET_LISTING,
  ADMIN_SET_FILTER,
  ADMIN_TOGGLE_ITEM_SELECTION,
  ADMIN_TOGGLE_PAGE_SELECTION,
  UPDATE_ACCOUNT,
  FETCH_REMITTANCE_TYPE,
  ADMIN_SET_LOADING,
  ADMIN_UPDATE_IS_ALL_SELECTED,
  ADMIN_CLEAR_SELECTION,
  ADMIN_REPORT_LOADING,
  CONFIG_FUNDER,
} from '../actions/remittanceActions';
import { REMITTANCE_STATUS } from '../utils';

export const DEFAULT_SORTING = {};
export const initialState = Immutable({
  token: null,
  trades: [],
  filters: { [REMITTANCE_STATUS]: '0' },
  selectedTradeIds: [],
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 25,
    pages: 1,
    count: 0,
  },
  isPageSelected: false,
  accounts: [],
  activeSelect: 0,
  isAdmin: false,
  hasSelectedAllItems: false,
  loading: false,
  downloadReportLoading: false,
  funderActionsText: '',
  showFunderBankAccount: false,
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_TOGGLE_ITEM_SELECTION: {
      const { id: selectedId } = payload;
      const { selectedTradeIds } = state;

      let updatedSelected = [];

      if (selectedTradeIds.includes(selectedId)) {
        updatedSelected = selectedTradeIds.filter(id => id !== selectedId);
      } else {
        updatedSelected = selectedTradeIds.concat([selectedId]);
      }
      return state.merge({
        selectedTradeIds: uniq(updatedSelected),
        hasSelectedAllItems: false,
      });
    }
    case UPDATE_ACCOUNT: {
      const { data } = payload;
      return state.merge({
        accounts: data,
      });
    }
    case CONFIG_FUNDER: {
      const { actionText, showBankAccount } = payload;
      return state.merge({
        funderActionsText: actionText,
        showFunderBankAccount: showBankAccount,
      });
    }
    case ADMIN_UPDATE_IS_ALL_SELECTED:
      return state.merge({
        hasSelectedAllItems: payload,
      });

    case ADMIN_TOGGLE_PAGE_SELECTION: {
      const { isPageSelected, selectedTradeIds, trades } = state;

      let updateSelected = [];
      const tradeIds = trades.map(({ id }) => id);
      if (isPageSelected) {
        updateSelected = selectedTradeIds.filter(id => !tradeIds.includes(id));
      } else {
        updateSelected = uniq(selectedTradeIds.concat(tradeIds));
      }

      return state.merge({
        isPageSelected: !isPageSelected,
        selectedTradeIds: updateSelected,
        hasSelectedAllItems: false,
      });
    }

    case ADMIN_FETCH_TRADES_SUCCESS: {
      const { trades, pagination } = payload.trades;
      const { selectedTradeIds, hasSelectedAllItems } = state;
      const tradeIds = trades.map(({ id }) => id);
      const hasItemsOnPageSelected = tradeIds.every(id => selectedTradeIds.includes(id));

      const isPageSelected = hasSelectedAllItems || hasItemsOnPageSelected;

      let updateSelected = null;
      if (hasSelectedAllItems) {
        updateSelected = uniq(selectedTradeIds.concat(tradeIds));
      }

      return state.merge({
        trades,
        pagination,
        selectedTradeIds: updateSelected ?? selectedTradeIds,
        isPageSelected,
      });
    }
    case ADMIN_CHANGE_PAGE: {
      const {
        payload: { page },
      } = action;
      const { pagination } = state;
      return state.merge({ pagination: { ...pagination, current: page } });
    }
    case ADMIN_SET_LISTING: {
      const {
        payload: { per },
      } = action;
      const { pagination } = state;

      return state.merge({
        pagination: { ...pagination, current: 1, per },
      });
    }
    case ADMIN_SET_FILTER: {
      const { payload: filters } = action;
      return state.merge({
        filters: { ...filters },
        selectedTradeIds: [],
        hasSelectedAllItems: false,
      });
    }
    case FETCH_REMITTANCE_TYPE: {
      const { payload: activeSelect } = action;
      return state.merge({
        activeSelect,
        selectedTradeIds: [],
      });
    }
    case ADMIN_SET_LOADING:
      return state.merge({ loading: payload });

    case ADMIN_CLEAR_SELECTION: {
      return state.merge({
        selectedTradeIds: [],
        hasSelectedAllItems: false,
        isPageSelected: false,
      });
    }
    case ADMIN_REPORT_LOADING: {
      return state.merge({
        downloadReportLoading: payload,
      });
    }

    default:
      return state;
  }
};
