/* eslint-disable react/prop-types */
import React from 'react';
import { CancelButton, TaxDocumentButton } from '../PendingAdvanceCard/buttons';

const goToCancelationPage = id => window.location.assign(`/fornecedor/solicitacao/cancel?id=${id}`);

export const getColumns = ({ handleDownloadNf }) => [
  {
    name: 'number',
    label: 'Título',
    width: 25,
  },
  {
    name: 'expires_at',
    label: 'Vencimento',
    width: 20,
  },
  {
    name: 'face_value',
    label: 'Valor Bruto',
    width: 20,
  },
  {
    name: 'agreed_rate',
    label: 'Taxa',
    width: 15,
  },
  {
    name: 'actions',
    label: 'Ações',
    width: 20,
    align: 'right',
    getCellValue: ({ trade_id: tradeId, tax_document_url: taxDocumentUrl }) => (
      <>
        <TaxDocumentButton
          disabled={!taxDocumentUrl}
          onClick={() => handleDownloadNf(taxDocumentUrl)}
        />
        <CancelButton onClick={() => goToCancelationPage(tradeId)} />
      </>
    ),
  },
];
