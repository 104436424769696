import styled from 'styled-components';
import {
  handleThemeFromObject as getTheme,
  FlatButton,
  PrimaryButton,
  TextField,
} from 'liber-components';

export const Container = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px 24px 12px 24px;
  * {
    font-family: Roboto;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  color: #6287a7;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '48px')};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'space-between'};
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const TextInput = styled(TextField).attrs(() => ({ version: 2 }))`
  width: 320px;
`;

export const InputWrapper = styled.div`
  width: 320px;

  &:not(:last-of-type) {
    margin-right: 32px;
  }
`;

export const TransferListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

export const TransferListsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  width: 100%;
  max-width: 1060px;

  & > div:first-of-type {
    min-width: 570px;
  }
`;

export const TransfersListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Button = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #009dff;
  border-radius: 4px;
  margin: 8px 0;
  opacity: 0.38;
  ${({ active = true }) => active && 'cursor: pointer; opacity: 1;'}
  & svg {
    height: 20px;
    color: #009dff;
  }
`;

export const HintText = styled.div`
  font-size: 12px;
  color: #6287a7;
  margin-top: 4px;
`;

export const CenterContainer = styled.div`
  margin: 0 20px;
`;

export const FooterActions = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
`;

export const ButtonRight = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
  margin-left: 8px;
`;

export const ButtonOutline = styled(PrimaryButton).attrs(() => ({
  outlined: true,
  version: 2,
  size: 'large',
}))`
  text-transform: uppercase;
  margin-left: auto;
`;

export const EditButton = styled(FlatButton).attrs(() => ({ size: 'large' }))`
  margin: 14px 0 0 8px;
`;

export const InfoSpacer = styled.div`
  width: 100%;
  height: 35px;
`;
