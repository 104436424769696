import React from 'react';
import PropTypes from 'prop-types';

import { TooltipContent, TooltipRow, TooltipTitle, TooltipValue } from './styles';
import { INVOICE_TOOLTIP_FIELD_MAP } from '../constants';

function InvoiceTooltip({ invoice }) {
  return (
    <TooltipContent>
      {INVOICE_TOOLTIP_FIELD_MAP.map(({ label, getValue }) => (
        <TooltipRow key={`tooltip-row-${label}`}>
          <TooltipTitle>{label}</TooltipTitle>
          <TooltipValue>{getValue(invoice)}</TooltipValue>
        </TooltipRow>
      ))}
    </TooltipContent>
  );
}

InvoiceTooltip.propTypes = {
  invoice: PropTypes.shape({
    toReceive: PropTypes.number,
    invested: PropTypes.number,
    yield: PropTypes.number,
    profitability: PropTypes.number,
    reference: PropTypes.shape({
      cdi: PropTypes.number,
    }),
  }),
};

InvoiceTooltip.defaultProps = {
  invoice: {
    toReceive: 0,
    invested: 0,
    yield: 0,
    profitability: 0,
    reference: {
      cdi: 0,
    },
  },
};

export default InvoiceTooltip;
