import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, FlatLink } from 'liber-components';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';

import { TokenContext } from '../../../../hocs/withTokenProvider';
import { Header, Row, Content, Input, InputFile, Button } from './styles';
import { ActionsRow, OutlinedButton, Container } from '../CommonStyles';
import { handleError, handleInfo, dispatchAmplitudeEvent } from '../../../../vendor/Utils';
import { sendSpreadsheet } from '../../api';
import { VendorsGroupsContext } from '../../context';

function UploadModal({ onLeaved, show }) {
  const inputRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);

  const { token } = useContext(TokenContext);
  const { roomId, exampleFile } = useContext(VendorsGroupsContext);

  const handleInputClick = event => {
    event.stopPropagation();
    inputRef.current.click();
  };

  const handleFileUpload = event => {
    const [file] = event?.target?.files;
    setSelectedFile(file);
  };

  const handleLeave = () => {
    onLeaved();
    setSelectedFile(null);
  };

  const handleSendFile = () => {
    sendSpreadsheet(token, selectedFile, roomId)
      .then(() => {
        handleLeave();
        handleInfo(
          'Criação de grupo em progresso',
          'Seu arquivo está sendo processado no momento. Por favor, aguarde alguns instantes.',
        );
      })
      .catch(() => {
        handleError(
          'Erro na criação do grupo',
          'Houve um problema ao fazer o upload do arquivo. Por favor, atualize a página e tente novamente.',
        );
      });
  };

  return (
    <Modal show={show} onLeaved={handleLeave}>
      <Container>
        <Row>
          <Header>Importar grupo</Header>
          <FlatLink
            onClick={() =>
              dispatchAmplitudeEvent('buyer_vendors_groups_import_group_download_template')
            }
            size="large"
            href={exampleFile}
          >
            <Icon path={mdiDownload} />
            Planilha Modelo
          </FlatLink>
        </Row>
        <Content>
          Para importar um grupo, faça o upload de um arquivo no formato xlsx contendo nome do
          grupo, código de referência e CNPJ dos fornecedores.
          <br />
          <br />
          <b>Importante:</b> A planilha deve seguir o formato da <u>planilha modelo</u> e não deve
          conter fórmulas.
        </Content>

        <Input data-testid="spreadsheet-upload-input" onChange={handleFileUpload} ref={inputRef} />

        <InputFile label="Planilha" onClick={handleInputClick} value={selectedFile?.name} />

        <ActionsRow>
          <Button onClick={handleLeave}>CANCELAR</Button>
          <OutlinedButton disabled={!selectedFile} onClick={handleSendFile}>
            ENVIAR
          </OutlinedButton>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

UploadModal.propTypes = {
  onLeaved: PropTypes.func,
  show: PropTypes.bool,
};

UploadModal.defaultProps = {
  onLeaved: () => {},
  show: true,
};

export default UploadModal;
