import Immutable from 'seamless-immutable';
import errorMessages from './errorMessages';

const initialState = Immutable({
  loading: true,
  status: true,
  error: {
    code: null,
    message: '',
  },
  data: {
    accumulated: 0.0,
    calendar: [],
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INVESTOR_DASHBOARD_GET_YIELD_DATA_FULFILLED':
      return state.merge(
        {
          loading: false,
          data: {
            accumulated: action.payload.data.data ? action.payload.data.data.accumulated : 0.0,
            calendar: action.payload.data.data ? action.payload.data.data.calendar : [],
          },
          status: action.payload.data.status,
          error: {
            message: action.payload.data.error ? action.payload.data.error.message : '',
            code: action.payload.data.error ? action.payload.data.error.code : null,
          },
        },
        { deep: true },
      );
    case 'INVESTOR_DASHBOARD_GET_YIELD_DATA_PENDING':
      return state.merge({ loading: true }, { deep: true });
    case 'INVESTOR_DASHBOARD_GET_YIELD_DATA_REJECTED':
      if (
        action.payload.response &&
        (typeof action.payload.response.data === 'string' ||
          action.payload.response.data instanceof String)
      ) {
        return state.merge(
          {
            error: { message: action.payload.response.data.error },
            status: false,
          },
          { deep: true },
        );
      }
      return state.merge(
        {
          error: {
            message: errorMessages[action.payload.response.status] || action.payload.message,
          },
          status: false,
        },
        { deep: true },
      );
    default:
      return state;
  }
};
