import styled from 'styled-components';
import { FlatButton, TextField } from 'liber-components';

const TEXT_COLOR = '#6287a7';

export const Container = styled.div`
  padding: 24px 24px 12px 24px;
  width: 480px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Header = styled.h2`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  color: ${TEXT_COLOR};
  margin-bottom: 0;
`;

export const List = styled.ul`
  padding: 0;
  margin-top: 10px;
  margin-left: 24px;
`;

export const ListItem = styled.li`
  list-style: circle;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin-bottom: 24px;
  color: ${TEXT_COLOR};
  margin-bottom: 24px;
`;

export const Input = styled.input.attrs(() => ({
  accept: '.xls,.xlsx',
  type: 'file',
}))`
  display: none;
`;

export const InputFile = styled(TextField).attrs(() => ({
  version: 2,
  openLabel: true,
  label: 'Planilha de Taxas',
}))`
  min-width: 432px;
  margin-bottom: 32px;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 4px;
  }
  & > *:last-child {
    margin-right: -12px;
  }
`;

export const Button = styled(FlatButton).attrs(() => ({ size: 'large' }))``;
