import React, { useContext } from 'react';
import TextFieldFilter from '../../../../../../common-components/Filters/TextFieldFilter/TextFieldFilter';
import {
  VENDORS_GROUPS_NAME_FILTER,
  VENDORS_GROUPS_BUYER_NAME_FILTER,
} from '../../../../constants';
import { ConfirmationModalContext } from '../../context';
import * as S from './styles';

const hasFilters = filters =>
  Object.keys(filters).reduce((flag, key) => flag || Boolean(filters[key]), false);

const Filters = () => {
  const { filters, handleChangeFilters, handleClearAllFilters } =
    useContext(ConfirmationModalContext);

  return (
    <>
      <S.FilterText>Filtrar por</S.FilterText>
      <TextFieldFilter
        data-testid="vendor-group-name-filter"
        label="Nome do Grupo"
        selectedItems={filters[VENDORS_GROUPS_NAME_FILTER]}
        onFilter={input => handleChangeFilters(VENDORS_GROUPS_NAME_FILTER, input)}
        onClear={() => handleChangeFilters(VENDORS_GROUPS_NAME_FILTER, null)}
      />
      <TextFieldFilter
        label="Sacado"
        selectedItems={filters[VENDORS_GROUPS_BUYER_NAME_FILTER]}
        onFilter={input => handleChangeFilters(VENDORS_GROUPS_BUYER_NAME_FILTER, input)}
        onClear={() => handleChangeFilters(VENDORS_GROUPS_BUYER_NAME_FILTER, null)}
      />
      {hasFilters(filters) && (
        <S.ClearFilters onClick={() => handleClearAllFilters()}>Limpar filtros</S.ClearFilters>
      )}
    </>
  );
};

export default Filters;
