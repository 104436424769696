import { CompleteTable } from 'liber-components/components/Table';
import React from 'react';
import { useBuyerOrganizationGroups } from '../../context';
import Filters from '../Filters';
import ActionButtons from '../ActionButtons';
import { IBUYER_ORGANIZATION_KIND_TYPES } from '../../interfaces';
import Loading from '../../../../../../common-components/Loading';
import { handleNavigate } from '../../../../../../vendor/Utils';

const COLUMNS = [
  {
    name: 'buyerOrganizationName',
    label: 'Nome do grupo econômico',
    align: 'left',
    width: 30,
    getCellValue: ({ groupName }) => groupName,
  },
  {
    name: 'BuyerOrganizationSize',
    label: 'Porte',
    align: 'left',
    width: 20,
    getCellValue: ({ kind }) => IBUYER_ORGANIZATION_KIND_TYPES[kind],
  },
  {
    name: 'BuyerOrganizationSegment',
    label: 'Segmento',
    align: 'left',
    width: 20,
    getCellValue: ({ buyerGroupCategory }) => buyerGroupCategory.name,
  },
  {
    name: 'BuyerOrganizationBuyerNumber',
    label: 'Número de sacados',
    align: 'left',
    width: 20,
    getCellValue: ({ buyersCount }) => buyersCount,
  },
  {
    name: 'buyerOrganizationActions',
    label: 'Ações',
    align: 'center',
    width: 10,
    getCellValue: ({ id }) => (
      <ActionButtons
        onEdit={() => handleNavigate(`/admin/grupos-economicos/${id}/edit`)}
        onViewInfo={() => handleNavigate(`/admin/grupos-economicos/${id}`)}
      />
    ),
  },
];

const Table = () => {
  const {
    organizationGroups,
    currentPage,
    setPageState,
    onKeyDownPage,
    isLoading,
    pagination,
    setCurrentPage,
  } = useBuyerOrganizationGroups();
  const { next, previous, pages } = pagination;
  return (
    <Loading isLoading={isLoading}>
      <CompleteTable
        columns={COLUMNS}
        items={organizationGroups}
        filters={<Filters />}
        paginationProps={{
          currentPage: String(currentPage),
          pageTotal: pages,
          onChangePage: ({ target }) => setPageState(target.value),
          onClickNext: () => setCurrentPage(next),
          onClickPrev: () => setCurrentPage(previous),
          onKeyDownPage,
          hasListing: false,
        }}
      />
    </Loading>
  );
};

export default Table;
