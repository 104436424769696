import Cookie from 'js.cookie';
import jwtDecode from 'jwt-decode';

export const isAdmin = () => {
  try {
    const jwt = Cookie.get('admin_jwt');
    const decodedJwt = jwtDecode(jwt);
    return !!decodedJwt?.id;
  } catch (e) {
    return false;
  }
};
