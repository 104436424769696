import React, { useContext } from 'react';
import { LimitsContext } from '../../context';
import * as S from './styles';

const LimitsHeader = () => {
  const { setShowLimitsModal, setSelectedBuyer, limits } = useContext(LimitsContext);
  const handleModal = () => {
    setSelectedBuyer(null);
    setShowLimitsModal(true);
  };

  return (
    <S.Flex>
      <S.Title>Limites</S.Title>
      <S.Button name="button" disabled={limits.length < 1} onClick={handleModal}>
        Atualizar Todos Limites
      </S.Button>
    </S.Flex>
  );
};

export default LimitsHeader;
