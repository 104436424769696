import { connect } from 'react-redux';
import NextForecast from './NextForecast';

const reducer = 'wallet';

const parentReducer = 'investorDashboard';

const mapStateToProps = state => ({ ...state[parentReducer][reducer] });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NextForecast);
