import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as theme } from 'liber-components';
import RatesConfirmation from './components/RatesConfirmation';
import { RatesPreviewStore } from './context';

const App = ({ token, exampleFile, id }) => (
  <ThemeProvider theme={theme}>
    <RatesPreviewStore token={token} exampleFile={exampleFile} roomId={uuid()} id={id}>
      <RatesConfirmation />
    </RatesPreviewStore>
  </ThemeProvider>
);

App.propTypes = {
  token: PropTypes.string.isRequired,
  exampleFile: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default App;
