import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'js.cookie';

export const TokenContext = createContext({});

const chooseToken = (token, tokenCookieKey) =>
  tokenCookieKey ? Cookie.get(tokenCookieKey) : token;

function withTokenProvider(Component, tokenCookieKey = null) {
  function WithTokenProvider(props) {
    const { token, ...rest } = props;

    return (
      <TokenContext.Provider value={{ token: chooseToken(token, tokenCookieKey) }}>
        <Component {...rest} />
      </TokenContext.Provider>
    );
  }

  WithTokenProvider.propTypes = {
    token: PropTypes.string,
  };

  WithTokenProvider.defaultProps = {
    token: '',
  };

  return WithTokenProvider;
}

export default withTokenProvider;
