import Immutable from 'seamless-immutable';

const initialState = Immutable({
  email: '',
  emailError: '',
  sended: false,
  path: '',
  flashes: [],
  passwordPath: '',
  origin: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'RECOVERY_CHANGE_EMAIL':
      return state.merge({ email: action.payload, emailError: '' });
    case 'RECOVERY_CHANGE_EMAIL_ERROR':
      return state.merge({ email: action.payload.email, emailError: action.payload.error });
    case 'RECOVERY_SUCESSFULL_SUBMIT':
      return state.merge({ sended: true });
    case 'RECOVERY_FAILED_SUBMIT':
      return state.merge({
        emailError: action.payload.emailError,
      });
    default:
      return state;
  }
};
