import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import VendorDetailsButton from '../../../../common-components/VendorDetailsButton';
import { Context } from '../../context';

const InfoButton = ({ vendorId, ...props }) => {
  const { setIsDrawerOpen, setSelectedVendorId } = useContext(Context);

  const handleClick = () => {
    setSelectedVendorId(vendorId);
    setIsDrawerOpen(current => !current);
  };

  return <VendorDetailsButton {...props} onClick={handleClick} />;
};

InfoButton.propTypes = {
  activeFunders: PropTypes.number.isRequired,
  totalFunders: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
  vendorId: PropTypes.number,
};

InfoButton.defaultProps = {
  isAdmin: false,
  vendorId: null,
};

export default InfoButton;
