import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiInformation } from '@mdi/js';

import {
  TitleRow,
  IconButton,
  SectionTitle,
  SectionTitleHelper,
  Option,
  OptionHelper,
  Observation,
  RadioButton,
  FooterActions,
  OutlineButton,
  Button,
} from './styles';
import OperationModeCard from '../OperationModeCard';
import {
  auctioneerBankCode,
  TARGETING_MODES,
  TARGETING_MODE_INTEGRATION,
  TARGETING_MODE_PLATFORM,
} from './constants';
import FundersTable from './FundersTable';
import ScreenTitle from '../ScreenTitle';

function EditOperationMode({
  title,
  funderTargetingMode,
  funders,
  selectedFunderId,
  onCancel,
  onSave,
  vendorsGroupId,
}) {
  const [localFunderTargetingMode, setLocalFunderTargetingMode] = useState(funderTargetingMode);
  const [localSelectedFunderId, setLocalSelectedFunderId] = useState(selectedFunderId);

  const funder = useMemo(
    () => funders.find(({ id }) => id === localSelectedFunderId),
    [funders, localSelectedFunderId],
  );

  const isPlatformMode = localFunderTargetingMode === TARGETING_MODE_PLATFORM;

  const handleCancel = () => {
    onCancel({
      funderTargetingMode: localFunderTargetingMode,
      selectedFunderId: isPlatformMode ? localSelectedFunderId : undefined,
    });
  };

  const handleSave = () => {
    onSave({
      funderTargetingMode: localFunderTargetingMode,
      selectedFunderId: isPlatformMode ? localSelectedFunderId : undefined,
    });
  };

  useEffect(() => {
    if (isPlatformMode) {
      if (!localSelectedFunderId) {
        if (selectedFunderId) setLocalSelectedFunderId(selectedFunderId);
        else {
          const auctioneerId = funders.find(({ bankCode }) => bankCode === auctioneerBankCode)?.id;
          setLocalSelectedFunderId(auctioneerId || funders[0]?.id);
        }
      }
    } else {
      setLocalSelectedFunderId();
    }
  }, [isPlatformMode, localSelectedFunderId]);

  return (
    <>
      <TitleRow>
        <TooltipBox mount="top" fixed content="Voltar">
          <IconButton data-testid="edit-op-mode-back" onClick={handleCancel}>
            <Icon path={mdiArrowLeft} />
          </IconButton>
        </TooltipBox>
        <ScreenTitle>{title}</ScreenTitle>
      </TitleRow>
      <SectionTitle>Modo de direcionamento de títulos</SectionTitle>
      <SectionTitleHelper>
        <Icon path={mdiInformation} />
        Determina se o envio dos títulos será via integração ou via plataforma
      </SectionTitleHelper>
      {TARGETING_MODES.map(({ name, key, helper }) => (
        <Fragment key={key}>
          <Option data-testid={`${key}-targeting`} onClick={() => setLocalFunderTargetingMode(key)}>
            <RadioButton name="targetingMode" id={key} checked={localFunderTargetingMode === key} />
            Direcionamento via {name}
          </Option>
          <OptionHelper>{helper}</OptionHelper>
        </Fragment>
      ))}
      <Observation>
        Obs.: A integração via API sempre será respeiteada, e nunca sobrescrita
      </Observation>
      <SectionTitle>Investidores</SectionTitle>
      {isPlatformMode && (
        <SectionTitleHelper>
          <Icon path={mdiInformation} />
          Os fornecedores neste grupo anteciparão com a taxa do investidor selecionado
        </SectionTitleHelper>
      )}
      {isPlatformMode && (
        <OperationModeCard
          funderTargetingMode={localFunderTargetingMode}
          funder={funder}
          vendorsGroupId={vendorsGroupId}
          isEditing
        />
      )}
      <FundersTable
        selectionEnabled={isPlatformMode}
        selectedFunderId={localSelectedFunderId}
        setSelectedFunderId={setLocalSelectedFunderId}
        funders={funders}
      />
      <FooterActions>
        <OutlineButton data-testid="edit-op-mode-cancel" onClick={handleCancel}>
          Voltar
        </OutlineButton>
        <Button onClick={handleSave}>Salvar modo de operação</Button>
      </FooterActions>
    </>
  );
}

EditOperationMode.propTypes = {
  title: PropTypes.string,
  funderTargetingMode: PropTypes.oneOf(TARGETING_MODES.map(({ key }) => key)),
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.string,
      bankCode: PropTypes.string,
      limit: PropTypes.number,
    }),
  ),
  selectedFunderId: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  vendorsGroupId: PropTypes.number,
};

EditOperationMode.defaultProps = {
  title: 'Editar modo de operação',
  funderTargetingMode: TARGETING_MODE_INTEGRATION,
  funders: [],
  selectedFunderId: undefined,
  onCancel: () => null,
  onSave: () => null,
  vendorsGroupId: undefined,
};

export default EditOperationMode;
