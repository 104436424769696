import styled from 'styled-components';
import { LoadingBunny } from 'liber-components';

export const SimulationPaginationSection = styled.div`
  margin-top: 40px;
`;

export const LoadingBlock = styled.div`
  flex-grow: 1;
  display: flex;
  opacity: ${props => (props.loading ? props.opacity || '0.3' : '1')};
  pointer-events: ${props => (props.loading ? 'none' : null)};
  transition: opacity 150ms ease-in-out;
  display: inherit;
  flex-direction: inherit;
  position: inherit;
  min-height: ${props => (props.loading ? '250px' : null)};
`;

export const Loading = styled(LoadingBunny)`
  width: 200px;
  position: absolute;
  left: 50%;
  margin-top: 90px;
  transform: translate(-50%);
`;

export const BottomSpacer = styled.div`
  height: 128px;
`;
