import Immutable from 'seamless-immutable';

const initialState = Immutable({
  adresses: [],
  showAddressForm: false,
  loading: '',
});

const deleteFromArray = (array, index) => {
  const base = [...array];
  base.splice(index, 1);
  return base;
};

const setDefault = (adresses, id) =>
  adresses.map(address => {
    if (address.id === id) {
      return address.merge({ kind: 'cobranca' });
    }
    return address.merge({ kind: null });
  });

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_HIDRATE_FORM':
      return state.merge({ showAddressForm: true });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SUBMIT_SUCCESS':
      if (action.payload.index === -1) {
        return state.merge({
          adresses: [...state.adresses, action.payload.address],
          showAddressForm: false,
        });
      }
      return state.merge({
        adresses: state.adresses.set(action.payload.index, action.payload.address),
        showAddressForm: false,
      });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_CLOSE_MODAL_FORM':
    case 'MY_ACCOUNT_ACCOUNT_INVESTOR_SUBMIT_REJECT':
      return state.merge({ showAddressForm: false });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_DELETE_PENDING':
      return state.merge({
        loading: 'delete',
      });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_DELETE_SUCCESS':
      return state.merge({
        adresses: deleteFromArray(state.adresses, action.payload),
        loading: '',
      });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SET_DEFAULT_PENDING':
      return state.merge({
        loading: 'default',
      });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SET_DEFAULT_SUCCESS':
      return state.merge({
        adresses: setDefault(state.adresses, action.payload),
        loading: '',
      });
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_DELETE_REJECT':
    case 'MY_ACCOUNT_ADDRESS_INVESTOR_SET_DEFAULT_REJECT':
      return state.merge({
        loading: '',
      });
    default:
      return state;
  }
};
