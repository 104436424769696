import React from 'react';
import PropTypes from 'prop-types';
import { Form, SelectField, TextField } from 'liber-components';
import { CSSTransition } from 'react-transition-group';
import Recaptcha from '../../utils/recaptcha';
import Flash from '../Flash';
import {
  Title,
  ButtonGreen,
  FieldDiv,
  LoginContent,
  SignUpCard,
  BlueLink,
  ButtonDiv,
} from '../styles';

const InvestidorSignUp = ({
  type,
  email,
  fullName,
  companyName,
  tradeName,
  cpf,
  cnpj,
  phone,
  ramal,
  cellphone,
  password,
  confirmation,
  emailError,
  fullNameError,
  companyNameError,
  tradeNameError,
  cpfError,
  cnpjError,
  phoneError,
  ramalError,
  cellphoneError,
  passwordError,
  changeType,
  changeEmail,
  changeFullName,
  changeCompanyName,
  changeTradeName,
  changeCpf,
  changeCnpj,
  changePhone,
  changeRamal,
  changeCellphone,
  changePassword,
  changeConfirmation,
  submitForm,
  flashes,
  path,
  originPath,
}) => {
  const { executeRecaptcha } = Recaptcha.useGoogleReCaptcha();

  const handleSubmit = async event => {
    event.persist();
    event.preventDefault();
    const reCaptchaToken = await executeRecaptcha();
    submitForm(
      event,
      type,
      email,
      fullName,
      companyName,
      tradeName,
      cpf,
      cnpj,
      phone,
      password,
      confirmation,
      reCaptchaToken,
    );
  };

  const emailField = (
    <TextField
      fullWidth
      type="text"
      label="Email"
      value={email}
      errorMessage={emailError}
      onChange={value => changeEmail(value)}
      name="investidor[email]"
      id="investidor_email"
    />
  );
  const fullNameField = (
    <TextField
      fullWidth
      type="text"
      label="Nome Completo"
      value={fullName}
      errorMessage={fullNameError}
      onChange={value => changeFullName(value)}
      name="investidor[name]"
      id="investidor_name"
    />
  );
  const companyNameField = (
    <TextField
      fullWidth
      type="text"
      label="Razão Social"
      value={companyName}
      errorMessage={companyNameError}
      onChange={value => changeCompanyName(value)}
      name="investidor[company]"
      id="investidor_company"
    />
  );
  const contactField = (
    <TextField
      fullWidth
      type="text"
      label="Nome para Contato"
      value={fullName}
      errorMessage={fullNameError}
      onChange={value => changeFullName(value)}
      name="investidor[name]"
      id="investidor_name"
    />
  );
  const tradeNameField = (
    <TextField
      fullWidth
      type="text"
      label="Nome Fantasia"
      value={tradeName}
      errorMessage={tradeNameError}
      onChange={value => changeTradeName(value)}
      name="investidor[trade_name]"
      id="investidor_trade_name"
    />
  );
  const cpfField = (
    <TextField
      fullWidth
      type="text"
      label="CPF"
      value={cpf}
      errorMessage={cpfError}
      onChange={value => changeCpf(value)}
      name="investidor[cnpj]"
      id="investidor_cnpj"
      mask="999.999.999-99"
      maskChar={null}
    />
  );
  const cnpjField = (
    <TextField
      fullWidth
      type="text"
      label="CNPJ"
      value={cnpj}
      errorMessage={cnpjError}
      onChange={value => changeCnpj(value)}
      name="investidor[cnpj]"
      id="investidor_cnpj"
      mask="99.999.999/9999-99"
      maskChar={null}
    />
  );
  const phoneField = (
    <TextField
      fullWidth
      type="text"
      label="Telefone"
      value={phone}
      errorMessage={phoneError}
      onChange={value => changePhone(value)}
      name="investidor[phone]"
      id="investidor_phone"
      mask={phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
      maskChar={null}
      formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
    />
  );
  const ramalField = (
    <TextField
      fullWidth
      type="number"
      label="Ramal"
      value={ramal}
      errorMessage={ramalError}
      onChange={value => changeRamal(value)}
      name="investidor[branch_line]"
      id="investidor_branch_line"
    />
  );
  const cellphoneField = (
    <TextField
      fullWidth
      type="text"
      label="Celular"
      value={cellphone}
      errorMessage={cellphoneError}
      onChange={value => changeCellphone(value)}
      name="investidor[cellphone]"
      id="investidor_cellphone"
      mask={cellphone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
      maskChar={null}
      formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
    />
  );
  const passwordField = (
    <TextField
      fullWidth
      type="password"
      label="Senha"
      value={password}
      errorMessage={passwordError}
      onChange={value => changePassword(value)}
      name="investidor[password]"
      id="investidor_password"
    />
  );
  const confirmationField = (
    <TextField
      fullWidth
      type="password"
      label="Confirmação de Senha"
      value={confirmation}
      onChange={value => changeConfirmation(value)}
      name="investidor[password_confirmation]"
      id="investidor_password_confirmation"
    />
  );
  const cpfForm = (
    <div>
      {fullNameField}
      {cpfField}
      {emailField}
      <FieldDiv>
        {phoneField}
        <span />
        {ramalField}
      </FieldDiv>
      {cellphoneField}
      {passwordField}
      {confirmationField}
    </div>
  );
  const cnpjForm = (
    <div>
      {emailField}
      {contactField}
      {companyNameField}
      {tradeNameField}
      {cnpjField}
      <FieldDiv>
        {phoneField}
        <span />
        {ramalField}
      </FieldDiv>
      {cellphoneField}
      {passwordField}
      {confirmationField}
    </div>
  );
  return (
    <SignUpCard grow={type.length !== 0}>
      <LoginContent width="380px">
        <Title>INVESTIDOR</Title>
        <Flash flashes={flashes} />
        <Form action={path} method="POST" id="investidorKindForm" onSubmit={handleSubmit}>
          <input
            type="hidden"
            readOnly
            name="investidor[g_recaptcha_response]"
            id="g_recaptcha_response"
          />
          <SelectField
            fullWidth
            label="Escolha um Tipo"
            value={type}
            onChange={value => {
              if (type.length !== 0 && value.length !== 0) {
                changeType('');
                setTimeout(() => {
                  changeType(value);
                }, 1000);
              } else {
                changeType(value);
              }
            }}
            name="investidor[kind]"
            id="investidor_kind"
          >
            <option value="pf">Pessoa Física</option>
            <option value="factoring">Factoring</option>
            <option value="fidc">FIDC</option>
            <option value="securitizadora">Securitizadora</option>
            <option value="banco">Banco</option>
            <option value="esc">Empresa Simples de Crédito</option>
          </SelectField>

          <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={600}
            classNames="form-open"
            in={type === 'pf'}
          >
            {cpfForm}
          </CSSTransition>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={600}
            classNames="form-open"
            in={['fidc', 'factoring', 'securitizadora', 'banco', 'esc'].includes(type)}
          >
            {cnpjForm}
          </CSSTransition>
          <ButtonDiv>
            <ButtonGreen disabled={type.length === 0} width="100%">
              Cadastrar
            </ButtonGreen>
          </ButtonDiv>
          <BlueLink href={originPath}>Voltar para o Login</BlueLink>
        </Form>
      </LoginContent>
    </SignUpCard>
  );
};

InvestidorSignUp.propTypes = {
  type: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  tradeName: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  cnpj: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  ramal: PropTypes.string.isRequired,
  cellphone: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  confirmation: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  fullNameError: PropTypes.string.isRequired,
  companyNameError: PropTypes.string.isRequired,
  tradeNameError: PropTypes.string.isRequired,
  cpfError: PropTypes.string.isRequired,
  cnpjError: PropTypes.string.isRequired,
  phoneError: PropTypes.string.isRequired,
  ramalError: PropTypes.string.isRequired,
  cellphoneError: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  changeType: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changeFullName: PropTypes.func.isRequired,
  changeCompanyName: PropTypes.func.isRequired,
  changeTradeName: PropTypes.func.isRequired,
  changeCpf: PropTypes.func.isRequired,
  changeCnpj: PropTypes.func.isRequired,
  changePhone: PropTypes.func.isRequired,
  changeRamal: PropTypes.func.isRequired,
  changeCellphone: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeConfirmation: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  path: PropTypes.string.isRequired,
  originPath: PropTypes.string.isRequired,
};

export default InvestidorSignUp;
