import React from 'react';
import PropTypes from 'prop-types';
import TextFieldFilter from '../../../../../common-components/Filters/TextFieldFilter/TextFieldFilter';
import { FilterText, CleanFilters } from './Filters.styles';
import { VENDOR_NAME_FILTER, VENDOR_CNPJ_FILTER, VENDOR_EMAIL_FILTER } from '../../../constants';

const hasFilters = filters =>
  Object.keys(filters).reduce((flag, key) => flag || Boolean(filters[key]), false);

export const Filters = ({ clearFilters, changeFilters, filters, isFunder }) => (
  <>
    <FilterText>Filtrar por</FilterText>
    <TextFieldFilter
      label="Empresa"
      selectedItems={filters[VENDOR_NAME_FILTER]}
      onFilter={text => changeFilters(VENDOR_NAME_FILTER, text)}
      onClear={() => changeFilters(VENDOR_NAME_FILTER, null)}
    />
    <TextFieldFilter
      label="CNPJ"
      selectedItems={filters[VENDOR_CNPJ_FILTER]}
      onFilter={text => changeFilters(VENDOR_CNPJ_FILTER, (text || '').replace(/\D/g, ''))}
      onClear={() => changeFilters(VENDOR_CNPJ_FILTER, null)}
    />
    {!isFunder && (
      <TextFieldFilter
        label="E-Mail"
        selectedItems={filters[VENDOR_EMAIL_FILTER]}
        onFilter={text => changeFilters(VENDOR_EMAIL_FILTER, text)}
        onClear={() => changeFilters(VENDOR_EMAIL_FILTER, null)}
      />
    )}
    {hasFilters(filters) ? (
      <CleanFilters onClick={() => clearFilters()}>Limpar filtros</CleanFilters>
    ) : null}
  </>
);

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any),
  changeFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  isFunder: PropTypes.bool,
};

Filters.defaultProps = {
  filters: {},
  changeFilters: () => {},
  clearFilters: () => {},
  isFunder: false,
};

export default Filters;
