import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CNPJ_CONT_FILTER, NAME_OR_TRADE_NAME_CONT_FILTER } from '../constants';
import { fetchFunders, fetchVendorInfo } from '../api';
import { convertToCamelCase, handleError } from '../../../vendor/Utils';
import usePagination from '../../../hooks/usePagination';
import usePageState from '../../../hooks/usePageState';

export const FundersDrawerContext = createContext({});

export const FundersDrawerProvider = ({
  children,
  token,
  selectedVendorBuyerId,
  onLeaved,
  selectedVendorId,
  handleSelectedFunderToBlockOrUnblock,
  handleIntermediateBlockUnblockStatuses,
  defaultFilters,
  isAdmin,
}) => {
  const [vendorInfo, setVendorInfo] = useState({
    name: '',
    funders: {
      available: '',
      total: '',
    },
  });
  const [funders, setFunders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    ...defaultFilters,
    [CNPJ_CONT_FILTER]: null,
    [NAME_OR_TRADE_NAME_CONT_FILTER]: null,
  });

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const handleChangeFilters = (key, text) => {
    setFilters({ ...filters, [key]: text });
  };

  const handleClearAllFilters = () => {
    setFilters({});
  };

  useEffect(() => {
    if (selectedVendorId) {
      fetchVendorInfo(selectedVendorId, token, isAdmin)
        .then(({ data: { data } }) => setVendorInfo(convertToCamelCase(data)))
        .catch(() => handleError('Erro ao requisitar informações do fornecedor.'));
    }
  }, [selectedVendorId]);

  useEffect(() => {
    if (selectedVendorId) {
      setLoading(true);
      const params = { ...filters, page: current, per };
      fetchFunders(selectedVendorId, token, params, isAdmin)
        .then(({ data: { data } }) => {
          setFunders(convertToCamelCase(data.funders));

          if (data.pagination) {
            updatePagination({ ...data.pagination, per });
          }
        })
        .catch(() => handleError('Erro ao requisitar investidores.'))
        .finally(() => setLoading(false));
    }
  }, [filters, current, per, selectedVendorId]);

  return (
    <FundersDrawerContext.Provider
      value={{
        funders,
        filters,
        loading,
        selectedVendorBuyerId,
        token,
        onLeaved,
        onSelectListing,
        next,
        previous,
        count,
        per,
        pages,
        currentPage,
        setPageState,
        onKeyDownPage,
        pagination,
        handleChangeFilters,
        handleClearAllFilters,
        vendorInfo,
        handleSelectedFunderToBlockOrUnblock,
        handleIntermediateBlockUnblockStatuses,
        selectedVendorId,
        isAdmin,
      }}
    >
      {children}
    </FundersDrawerContext.Provider>
  );
};

FundersDrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
  selectedVendorBuyerId: PropTypes.number,
  onLeaved: PropTypes.func,
  selectedVendorId: PropTypes.string,
  handleSelectedFunderToBlockOrUnblock: PropTypes.func.isRequired,
  handleIntermediateBlockUnblockStatuses: PropTypes.func.isRequired,
  defaultFilters: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

FundersDrawerProvider.defaultProps = {
  onLeaved: () => null,
  selectedVendorBuyerId: null,
  selectedVendorId: null,
  defaultFilters: null,
};
