import axios from 'axios';
import { ADMIN_FETCH_TRADES, ADMIN_FETCH_ACCOUNT, FETCH_REMITTANCE } from '../urls';
import {
  adjustDateFilters,
  handleSuccess,
  handleError,
  convertToCamelCase,
} from '../../../vendor/Utils';
import { FiltersFormatter } from '../../admin-negotiations/utils';
import { STATUS_EQ, CANCELEDATLTED } from '../utils';

export const ADMIN_SET_LOADING = 'ADMIN_SET_LOADING';
export const ADMIN_FETCH_TRADES_SUCCESS = 'ADMIN_FETCH_TRADES_SUCCESS';
export const ADMIN_CHANGE_PAGE = 'ADMIN_CHANGE_PAGE';
export const ADMIN_SET_LISTING = 'ADMIN_SET_LISTING';
export const ADMIN_SET_FILTER = 'ADMIN_SET_FILTER';
export const ADMIN_CLEAR_SELECTION = 'ADMIN_CLEAR_SELECTION';
export const ADMIN_TOGGLE_ITEM_SELECTION = 'ADMIN_TOGGLE_ITEM_SELECTION';
export const ADMIN_TOGGLE_PAGE_SELECTION = 'ADMIN_TOGGLE_PAGE_SELECTION';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const FETCH_REMITTANCE_TYPE = 'FETCH_REMITTANCE_TYPE';
export const ADMIN_UPDATE_IS_ALL_SELECTED = 'ADMIN_UPDATE_IS_ALL_SELECTED';
export const ADMIN_REPORT_LOADING = 'ADMIN_REPORT_LOADING';
export const CONFIG_FUNDER = 'CONFIG_FUNDER';

export const toggleItemSelection = selectedItem => ({
  type: ADMIN_TOGGLE_ITEM_SELECTION,
  payload: selectedItem,
});

export const togglePageSelection = () => ({
  type: ADMIN_TOGGLE_PAGE_SELECTION,
});

export const fetchRemittanceType = payload => ({
  type: FETCH_REMITTANCE_TYPE,
  payload,
});

export const updateTrades = trades => ({
  type: ADMIN_FETCH_TRADES_SUCCESS,
  payload: { trades },
});

export function fetchTrades() {
  return (dispatch, getState) => {
    const {
      remittance: {
        token,
        filters,
        activeSelect,
        pagination: { per, current },
      },
    } = getState();

    dispatch({ type: ADMIN_SET_LOADING, payload: true });

    const adjustableDateFields = [CANCELEDATLTED];
    const params = {
      [STATUS_EQ]: activeSelect,
      per,
      page: current,
      ...adjustDateFilters(adjustableDateFields, filters),
    };
    axios
      .get(ADMIN_FETCH_TRADES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(payload => {
        const { trades, pagination, actionText, showBankAccount } = convertToCamelCase(
          payload.data.data,
        );
        dispatch(
          updateTrades({
            trades,
            pagination: { ...pagination, per },
          }),
        );
        if (actionText) {
          dispatch({ type: CONFIG_FUNDER, payload: { actionText, showBankAccount } });
        }
      })
      .catch(() => {
        handleError();
      })
      .finally(() => dispatch({ type: ADMIN_SET_LOADING, payload: false }));
  };
}

export const selectAll = () => (dispatch, getState) => {
  const {
    remittance: { isPageSelected },
  } = getState();

  if (!isPageSelected) dispatch(togglePageSelection());
  dispatch({ type: ADMIN_UPDATE_IS_ALL_SELECTED, payload: true });
};

export function setPage(page) {
  return dispatch => {
    dispatch({ type: ADMIN_CHANGE_PAGE, payload: { page } });
    dispatch(fetchTrades());
  };
}

export const resetSelection = () => ({ type: ADMIN_CLEAR_SELECTION });

export const nextPage = () => (dispatch, getState) => {
  const {
    remittance: {
      pagination: { next },
    },
  } = getState();

  dispatch(setPage(next));
};

export const previousPage = () => (dispatch, getState) => {
  const {
    remittance: {
      pagination: { previous },
    },
  } = getState();

  dispatch(setPage(previous));
};

export function setListing(listing) {
  return dispatch => {
    dispatch({
      type: ADMIN_SET_LISTING,
      payload: {
        per: parseInt(listing, 10),
      },
    });
    dispatch(fetchTrades());
  };
}
export const clearFilters = () => (dispatch, getState) => {
  const {
    remittance: {
      pagination: { per },
    },
  } = getState();
  dispatch({
    type: ADMIN_SET_FILTER,
    payload: {},
  });
  dispatch(setListing(per));
  return dispatch(fetchTrades());
};

export function updateFilters(filters) {
  return dispatch => {
    dispatch({ type: ADMIN_CHANGE_PAGE, payload: { page: 1 } });
    dispatch({
      type: ADMIN_SET_FILTER,
      payload: {
        ...filters,
      },
    });
  };
}

export const updateInvestorAccount = accounts => dispatch =>
  dispatch({
    type: UPDATE_ACCOUNT,
    payload: accounts,
  });

export function showAccount() {
  return async (dispatch, getState) => {
    const {
      remittance: { token },
    } = getState();
    return axios
      .get(ADMIN_FETCH_ACCOUNT, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { data } = convertToCamelCase(response);
        dispatch(updateInvestorAccount(data));
      })
      .catch(() => handleError());
  };
}

export const setDownloadReportLoading = loading => ({
  type: ADMIN_REPORT_LOADING,
  payload: loading,
});

export function fetchRemittance(selectedItems, account) {
  return async (dispatch, getState) => {
    const {
      remittance: { token, hasSelectedAllItems, activeSelect, filters },
    } = getState();

    dispatch(setDownloadReportLoading(true));
    const adjustableDateFields = [CANCELEDATLTED];
    const backAccountId = account || null;
    const fixedDateParams = { ...adjustDateFilters(adjustableDateFields, filters) };

    const params = hasSelectedAllItems
      ? {
          all: true,
          bank_account_id: backAccountId,
          [STATUS_EQ]: activeSelect,
          q: FiltersFormatter(fixedDateParams),
        }
      : {
          trade_ids: selectedItems,
          bank_account_id: backAccountId,
          [STATUS_EQ]: activeSelect,
          q: FiltersFormatter(fixedDateParams),
        };

    return axios
      .post(FETCH_REMITTANCE, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { data } = response.data;
        if (response.data?.message) {
          handleSuccess(
            'Solicitação em processamento',
            'A remessa solicitada está sendo processada e o arquivo será enviado para seu e-mail após a conclusão',
          );
        } else {
          window.open(data);
          handleSuccess('Sucesso', 'Seu arquivo de remessa será baixado em instantes');
        }
        dispatch(setDownloadReportLoading(false));
      })
      .catch(error => {
        const { message } = error.response.data.error;
        handleError('Um erro ocorreu', message || undefined);
        dispatch(setDownloadReportLoading(false));
      });
  };
}
