import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, DateTimePicker, CompleteTable } from 'liber-components';
import { connect } from 'react-redux';
import {
  changeShowSchedulesModal,
  setSchedule,
  setSelectedVendors,
} from '../../actions/AdminVendorsActions';
import { fetchFundersForSchedule } from '../../api';
import { convertToCamelCase, handleError } from '../../../../vendor/Utils';
import {
  Container,
  Header,
  Title,
  Body,
  SelectFields,
  SelectInput,
  Table,
  Footer,
  FooterButton,
} from './VendorModal.styles';
import { ID_IN_FILTER } from '../../constants';

const columns = [
  {
    name: 'vendor',
    label: 'Fornecedor',
    width: 30,
    groupedColumns: ['company', 'tradeName', 'cnpj'],
    getCellValue: vendor => {
      const { cnpj, tradeName, company } = vendor;
      return (
        <>
          {company}
          <br />
          {tradeName}
          <br />
          {cnpj}
        </>
      );
    },
  },
  {
    name: 'politica_assinatura_termo_cessao',
    label: 'Assinatura',
    width: 30,
    getCellValue: vendor => {
      const {
        signature: { type, method },
      } = vendor;
      return (
        <>
          {method}
          <br />
          {type}
        </>
      );
    },
  },
  {
    name: 'sacado_company',
    label: 'Sacado',
    width: 30,
    getCellValue: vendor => {
      const { buyer } = vendor;
      return buyer.company;
    },
  },
];

export const VendorScheduleModal = ({
  selectedVendors,
  showSchedulesModal,
  updateShowSchedulesModal,
  setAppointment,
  updateSelectedVendors,
  token,
  selectedVendorsFundersIds,
}) => {
  const [availableFunders, setAvailableFunders] = useState([]);
  const [format, setFormat] = useState('');
  const [time, setTime] = useState('');

  const handleConfirm = () => {
    const vendorsIds = selectedVendors.map(item => item.id);
    const schedule = {
      scheduled_for: time,
      vendor_ids: vendorsIds,
      receipt_id: Number(format),
    };

    setAppointment(schedule);
    updateSelectedVendors([]);
  };

  useEffect(() => {
    const params = {
      [ID_IN_FILTER]: selectedVendorsFundersIds,
    };

    fetchFundersForSchedule(token, params)
      .then(({ data }) => setAvailableFunders(convertToCamelCase(data.data)))
      .catch(() => handleError());
  }, [selectedVendors]);

  return (
    <Modal show={showSchedulesModal} onLeaved={() => updateShowSchedulesModal(false)}>
      <Container>
        <Header>
          <Title>Cadastrar Agendamento</Title>
        </Header>
        <Body>
          <SelectFields>
            <SelectInput label="Investidor" openLabel onChange={setFormat}>
              {availableFunders.map(({ id, tradeName }) => (
                <option key={id} value={id}>
                  {tradeName}
                </option>
              ))}
            </SelectInput>
            <DateTimePicker label="Data do Agendamento" onChange={setTime} />
          </SelectFields>
          <Table>
            <CompleteTable columns={columns} items={selectedVendors} />
          </Table>
        </Body>
        <Footer>
          <FooterButton outlined onClick={() => updateShowSchedulesModal(false)}>
            Cancelar
          </FooterButton>
          <FooterButton onClick={handleConfirm}>Confirmar</FooterButton>
        </Footer>
      </Container>
    </Modal>
  );
};

VendorScheduleModal.propTypes = {
  selectedVendors: PropTypes.arrayOf(PropTypes.object),
  showSchedulesModal: PropTypes.bool,
  updateShowSchedulesModal: PropTypes.func,
  setAppointment: PropTypes.func,
  updateSelectedVendors: PropTypes.func,
  token: PropTypes.string.isRequired,
  selectedVendorsFundersIds: PropTypes.arrayOf(PropTypes.number),
};

VendorScheduleModal.defaultProps = {
  selectedVendors: [],
  showSchedulesModal: false,
  updateShowSchedulesModal: () => null,
  setAppointment: () => null,
  updateSelectedVendors: () => null,
  selectedVendorsFundersIds: [],
};

const mapDispatchToProps = {
  updateShowSchedulesModal: changeShowSchedulesModal,
  setAppointment: setSchedule,
  updateSelectedVendors: setSelectedVendors,
};

const mapStateToProps = ({
  adminVendors: { selectedVendors, showSchedulesModal, token, selectedVendorsFundersIds },
}) => ({
  selectedVendors,
  showSchedulesModal,
  selectedVendorsFundersIds,
  token,
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorScheduleModal);
