import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { getFingerprint } from 'client';
import { Checkbox, Form, TextField } from 'liber-components';
import Flash from '../Flash';
import {
  LoginCard,
  LoginContent,
  LoginSeparator,
  BlueLink,
  Title,
  Image,
  LoginLastContent,
  ButtonGreen,
  ButtonBlue,
  ButtonDiv,
  RadioDiv,
} from '../styles';
import { getSubdomain } from '../../../../vendor/Utils';

const Fornecedor = ({
  path,
  components,
  fingerprint,
  flashes,
  logoUrl,
  passwordPath,
  registrationPath,
  email,
  emailError,
  password,
  passwordError,
  remember,
  changeEmail,
  changePassword,
  toogleRemember,
  setFingerprint,
  submitLogin,
  loginAsBuyer,
}) => (
  <LoginCard>
    <LoginContent>
      <Title>FORNECEDOR</Title>
      <Flash flashes={flashes} />
      <Form
        action={path}
        method="POST"
        onSubmit={event => {
          const { target } = event;
          event.preventDefault();
          getFingerprint().then(data => {
            setFingerprint(data);
            submitLogin(target, email, password);
          });
        }}
      >
        <input
          value={components}
          type="hidden"
          name="fornecedor[components]"
          id="fornecedor_components"
        />
        <input
          value={fingerprint}
          type="hidden"
          name="fornecedor[fingerprint]"
          id="fornecedor_fingerprint"
        />
        <input
          value="FORNECEDOR/LOGIN"
          type="hidden"
          name="fornecedor[breadcrumb]"
          id="fornecedor_breadcrumb"
        />
        <TextField
          fullWidth
          label="Email"
          errorMessage={emailError}
          value={email}
          onChange={value => changeEmail(value)}
          name="fornecedor[email]"
          id="fornecedor_email"
          openLabel
        />
        <TextField
          fullWidth
          type="password"
          label="Senha"
          errorMessage={passwordError}
          value={password}
          onChange={value => changePassword(value)}
          name="fornecedor[password]"
          id="fornecedor_password"
          openLabel
        />
        <RadioDiv>
          <Checkbox
            id="fornecedor_remember_me"
            name="fornecedor[remember_me]"
            onChange={toogleRemember}
            checked={remember}
          >
            Lembrar
          </Checkbox>
        </RadioDiv>
        <ButtonDiv>
          <ButtonGreen type="submit" width="168px">
            Entrar
          </ButtonGreen>
          <ButtonBlue href={registrationPath} width="168px">
            Cadastrar
          </ButtonBlue>
        </ButtonDiv>
        <BlueLink href={passwordPath}>Recuperar ou criar uma senha</BlueLink>
        {loginAsBuyer && <BlueLink href={loginAsBuyer}>Logar como sacado</BlueLink>}
      </Form>
    </LoginContent>
    <LoginSeparator />
    <LoginLastContent>
      <Image alt={`Logo de ${getSubdomain()}`} src={logoUrl} />
    </LoginLastContent>
  </LoginCard>
);

Fornecedor.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
  ).isRequired,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  remember: PropTypes.bool.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  toogleRemember: PropTypes.func.isRequired,
  submitLogin: PropTypes.func.isRequired,
  registrationPath: PropTypes.string.isRequired,
  setFingerprint: PropTypes.func.isRequired,
  fingerprint: PropTypes.string.isRequired,
  components: PropTypes.string.isRequired,
  loginAsBuyer: PropTypes.string,
};

Fornecedor.defaultProps = {
  loginAsBuyer: undefined,
};

export default Fornecedor;
