import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'liber-components';

import Content from './components/Content';
import {
  handleSubmitBatchBlockUnblock,
  setIsBatchBlockUnblock,
  setSelectedCnpjsToBatchBlockOrUnblock,
  setShowBatchBlockUnblockModal,
} from '../../actions/AdminVendorsActions';
import BatchBlockUnblockModalProvider from './context';

const BatchBlockUnblockModal = ({
  token,
  show,
  updateShowBatchBlockUnblockModal,
  submitBatchBlockUnblock,
  handleSelectedCnpjs,
  exampleSheetUrl,
  handleIsBatchBlockUnblock,
}) => (
  <Modal show={show} onLeaved={() => updateShowBatchBlockUnblockModal(false)}>
    <BatchBlockUnblockModalProvider
      token={token}
      updateShowBatchBlockUnblockModal={updateShowBatchBlockUnblockModal}
      exampleSheetUrl={exampleSheetUrl}
      submitBatchBlockUnblock={submitBatchBlockUnblock}
      handleSelectedCnpjs={handleSelectedCnpjs}
      handleIsBatchBlockUnblock={handleIsBatchBlockUnblock}
    >
      <Content />
    </BatchBlockUnblockModalProvider>
  </Modal>
);

BatchBlockUnblockModal.propTypes = {
  show: PropTypes.bool,
  updateShowBatchBlockUnblockModal: PropTypes.func.isRequired,
  submitBatchBlockUnblock: PropTypes.func.isRequired,
  handleSelectedCnpjs: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  exampleSheetUrl: PropTypes.string.isRequired,
  handleIsBatchBlockUnblock: PropTypes.func.isRequired,
};

BatchBlockUnblockModal.defaultProps = {
  show: false,
};

const mapStateToProps = ({
  adminVendors: { showBatchBlockUnblockModal, token, exampleSheetUrl },
}) => ({
  show: showBatchBlockUnblockModal,
  token,
  exampleSheetUrl,
});

const mapDispatchToProps = {
  updateShowBatchBlockUnblockModal: setShowBatchBlockUnblockModal,
  submitBatchBlockUnblock: handleSubmitBatchBlockUnblock,
  handleSelectedCnpjs: setSelectedCnpjsToBatchBlockOrUnblock,
  handleIsBatchBlockUnblock: setIsBatchBlockUnblock,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchBlockUnblockModal);
