import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectField, TextField, RadioButton, LabeledContainer } from 'liber-components';
import { connect } from 'react-redux';
import {
  Row,
  ModalContent,
  ButtonsContainer,
  SubmitButton,
  HeaderRow,
  RadioContainer,
} from '../../Base.styles';
import {
  editField as edit,
  submitForm as submit,
} from '../../../actions/bankAccount/InvestorBankAccountActions';
import { accountLength, getSavingsComplementLabel } from './auxFunc';
import { fetchBanks } from '../../../../../vendor/Utils';

export const InvestorBankAccountForm = ({
  index,
  bankAccount,
  errors,
  loading,
  userKind,
  editField,
  submitForm,
}) => {
  const [banks, setBanks] = useState([]);

  const Banks = useCallback(() => {
    fetchBanks().then(({ data }) => setBanks(data));
  }, []);

  useEffect(() => {
    Banks();
  }, [Banks]);

  return (
    <ModalContent>
      <HeaderRow>
        <h1>Editar Dados Bancários</h1>
      </HeaderRow>
      <Row>
        <div>
          <SelectField
            label="Nome do Banco"
            searchable
            name={`conta_bancaria[${index}][bank_code]`}
            id={`conta_bancaria_${index}_bank_code`}
            errorMessage={errors.bankCode}
            onChange={value => editField('bankCode', value)}
            value={bankAccount.bankCode}
            width="100%"
          >
            {banks.length > 0 &&
              banks.map(bank => (
                <option key={bank.code} value={String(bank.code)}>
                  {`${bank.code} - ${bank.full_name}`}
                </option>
              ))}
          </SelectField>
        </div>
        <div>
          <LabeledContainer label="Tipo de conta" errorMessage={errors.kind}>
            <RadioContainer style={{ padding: 0, marginBottom: 12 }}>
              <RadioButton
                name={`conta_bancaria[${index}][kind]`}
                id={`conta_bancaria_${index}_kind_corrente`}
                onChange={event => editField('kind', event.target.value)}
                value="corrente"
                checked={bankAccount.kind.toLowerCase() === 'corrente'}
              >
                Conta corrente
              </RadioButton>
              <RadioButton
                name={`conta_bancaria[${index}][kind]`}
                id={`conta_bancaria_${index}_kind_poupanca`}
                onChange={event => editField('kind', event.target.value)}
                value="poupanca"
                checked={['poupanca', 'poupança'].includes(bankAccount.kind.toLowerCase())}
              >
                Conta poupança
              </RadioButton>
            </RadioContainer>
          </LabeledContainer>
        </div>
      </Row>
      <Row>
        <div>
          <TextField
            label="Agência"
            name={`conta_bancaria[${index}][agency]`}
            id={`conta_bancaria_${index}_agency`}
            errorMessage={errors.agency}
            onChange={value => editField('agency', value)}
            value={bankAccount.agency}
            mask="9999"
            maskChar={null}
            width="100%"
          />
        </div>
        <div>
          <TextField
            label="Dígito (Opcional)"
            name={`conta_bancaria[${index}][agency_digit]`}
            id={`conta_bancaria_${index}_agency_digit`}
            errorMessage={errors.agencyDigit}
            onChange={value => editField('agencyDigit', value)}
            value={bankAccount.agencyDigit}
            helper="Troque X por 0 quando houver"
            mask="9"
            maskChar={null}
            formatChars={{ 9: '[0-9xX]' }}
            width="100%"
          />
        </div>
      </Row>
      <Row>
        <div>
          <TextField
            label="Conta"
            name={`conta_bancaria[${index}][account]`}
            id={`conta_bancaria_${index}_account`}
            errorMessage={errors.account}
            onChange={value => editField('account', value)}
            value={bankAccount.account}
            mask={'9'.repeat(accountLength(bankAccount.bankCode))}
            maskChar={null}
            width="100%"
          />
        </div>
        <div>
          <TextField
            label="Dígito"
            name={`conta_bancaria[${index}][account_digit]`}
            id={`conta_bancaria_${index}_account_digit`}
            errorMessage={errors.accountDigit}
            onChange={value => editField('accountDigit', value)}
            value={bankAccount.accountDigit}
            mask="9"
            maskChar={null}
            width="100%"
          />
        </div>
      </Row>
      <Row>
        {['poupanca', 'poupança'].includes(bankAccount.kind.toLowerCase()) &&
        ['001', '104', '341'].includes(bankAccount.bankCode) ? (
          <div>
            <TextField
              label={getSavingsComplementLabel(bankAccount.bankCode)}
              name={`conta_bancaria[${index}][savings_complement]`}
              id={`conta_bancaria_${index}_savings_complement`}
              errorMessage={errors.savingsComplement}
              onChange={value => editField('savingsComplement', value)}
              value={bankAccount.savingsComplement}
              mask="999"
              maskChar={null}
              width="100%"
            />
          </div>
        ) : null}
        <div>
          <LabeledContainer label="Conta conjunta" errorMessage={errors.conjunct}>
            <RadioContainer style={{ justifyContent: 'center', padding: 0 }}>
              <RadioButton
                name={`conta_bancaria[${index}][conjunct]`}
                id={`conta_bancaria_${index}_conjunct_yes`}
                value="true"
                checked={bankAccount.conjunct.toLowerCase() === 'sim'}
                onChange={() => editField('conjunct', 'sim')}
              >
                Sim
              </RadioButton>
              <RadioButton
                name={`conta_bancaria[${index}][conjunct]`}
                id={`conta_bancaria_${index}_conjunct_no`}
                value="false"
                checked={['não', 'nao'].includes(bankAccount.conjunct.toLowerCase())}
                onChange={() => editField('conjunct', 'nao')}
              >
                Não
              </RadioButton>
            </RadioContainer>
          </LabeledContainer>
        </div>
      </Row>
      {userKind !== 'pf' ? (
        <>
          <Row>
            <div>
              <TextField
                label="Número do Contrato"
                name={`conta_bancaria[${index}][contract_number]`}
                id={`conta_bancaria_${index}_contract_number`}
                errorMessage={errors.contractNumber}
                onChange={value => editField('contractNumber', value)}
                value={bankAccount.contractNumber}
                width="100%"
              />
            </div>
          </Row>
          <Row>
            <div>
              <TextField
                label="Carteira"
                name={`conta_bancaria[${index}][wallet]`}
                id={`conta_bancaria_${index}_wallet`}
                errorMessage={errors.wallet}
                onChange={value => editField('wallet', value)}
                value={bankAccount.wallet}
                width="100%"
                mask="99"
                maskChar={null}
              />
            </div>
            <div>
              <TextField
                label="Variação da Carteira"
                name={`conta_bancaria[${index}][wallet_variation]`}
                id={`conta_bancaria_${index}_wallet_variation`}
                errorMessage={errors.walletVariation}
                onChange={value => editField('walletVariation', value)}
                value={bankAccount.walletVariation}
                width="100%"
                mask="999"
                maskChar={null}
              />
            </div>
          </Row>
        </>
      ) : null}
      <ButtonsContainer>
        <SubmitButton loading={loading} onClick={() => submitForm(bankAccount, index, userKind)}>
          Enviar
        </SubmitButton>
      </ButtonsContainer>
    </ModalContent>
  );
};

InvestorBankAccountForm.propTypes = {
  bankAccount: PropTypes.shape({
    default: PropTypes.bool,
    kind: PropTypes.string,
    agency: PropTypes.string,
    agencyDigit: PropTypes.string,
    account: PropTypes.string,
    accountDigit: PropTypes.string,
    company: PropTypes.string,
    conjunct: PropTypes.string,
    document: PropTypes.string,
    bankCode: PropTypes.string,
    savingsComplement: PropTypes.string,
    id: PropTypes.number,
    contractNumber: PropTypes.string,
    wallet: PropTypes.string,
    walletVariation: PropTypes.string,
  }).isRequired,
  userKind: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  editField: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const parentReducer = 'myAccount';
const tab = 'bankAccount';
const type = 'investor';
const reducer = 'form';

const mapStateToProps = state => ({ ...state[parentReducer][tab][type][reducer] });
const mapDispatchToProps = {
  editField: edit,
  submitForm: submit,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvestorBankAccountForm);
