import React from 'react';
import styled from 'styled-components';
import {
  ChartContainer,
  media,
  PrimaryLink as PrimaryLinkRaw,
  Link,
  handleThemeFromObject,
} from 'liber-components';

const PrimaryLink = styled(PrimaryLinkRaw)``;

export const Container = styled(ChartContainer)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-flow: row wrap;
  ${media.large`
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: row;
  `}
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin: 0px;
  width: 100%;
  ${media.large`
    width: unset;
    padding: 23px;
    padding-left: 0px;
    max-width: 60%;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  & > * {
    width: 100%;
  }
  ${media.large`
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 10px;
    & > *{
      width: unset;
      flex-basis: 50%;
    }
  `}
  ${media.extraLarge`
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 10px;
    & > *{
      width: unset;
      flex-basis:unset;
    }
  `}
`;

export const LabelTitle = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  line-height: 20px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  text-align: left;
  color: #a7a7a7;
  white-space: nowrap;
  margin-bottom: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  box-sizing: border-box;
  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
  `}
`;

export const LabelColorPercent = styled.div`
  border-left: 10px solid ${props => props.color || '#a7a7a7'};
  color: ${props => props.color || '#a7a7a7'};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  margin: 10px 16px 10px -10px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const LabelValue = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  line-height: 18px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  margin-bottom: 5px;
  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
    color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  `}
`;
export const LabelFooter = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  line-height: 16px;
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.regular', '400')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 23px;
  white-space: nowrap;
  ${media.large`
    justify-content: unset;
    align-items: unset;
    padding-right: unset;
    flex-grow: 0
    display:unset;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  `}
`;
export const LabelContainer = styled.div`
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 10px solid ${props => props.color || '#a7a7a7'};
  box-sizing: border-box;
  padding-left: 5px;
  width: 100%;
  ${media.large`
    display:inline-flex;
  `}
  & ${LabelTitle} {
    color: ${props => props.color || '#a7a7a7'};
  }
`;

export const LabelContainerSmall = styled.div`
  display: flex;
  border-top: 1px solid #e6e6e6;
  // border-bottom: 1px solid #e6e6e6;
  justify-content: flex-start;
  align-items: stretch;
  height: 63px;
  margin-left: 10px;
  ${media.large`
    display:none;
  `}
  & ${LabelTitle} {
    color: ${props => props.color || '#a7a7a7'};
  }
  & ${LabelColorPercent} {
    color: ${props => props.color || '#a7a7a7'};
    border-left: 10px solid ${props => props.color || '#a7a7a7'};
  }
`;

export const SmallInfoBox = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  ${media.large`
    display:none;
  `}
`;

export const SmallTitleName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 90%;
  vertical-align: middle;
  text-align: center;
`;

export const SmallTitleSep = styled.div`
  flex-grow: 1;
  text-align: center;
  margin: 0 5px;
`;
export const SmallTitleValue = styled.div`
  text-align: center;
`;

export const SmallInfoTitle = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
`;

export const SmallInfoValueDisplayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
    text-align: center;
    color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
    border-right: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
  }
  & > div > span {
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
    text-align: center;
    color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  }
  & > div:last-child {
    border-right: 0px;
  }
`;

export const SmallDetailLink = styled(Link)`
  margin-top: 24px;
  height: 30px;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  background-color: #fff;
  color: ${props =>
    handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)} !important;
  border-radius: 4px;
  border: 2px solid
    ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
  width: 132px;
  max-width: 95%;
  white-space: nowrap;
  align-self: center;
  &:hover {
    background-color: ${props =>
      handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171', props.color)};
    color: #fff !important;
  }
`;

export const Total = styled.div`
  width: 100%;
  text-align: right;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h5', '20px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  opacity: ${props => (props.fade ? '0.5' : '1')};
  transition: opacity 100ms ease;
  display: none;
  ${media.large`
    display:unset;
  `}
`;

export const ChippedText = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  display: inline;
  margin-left: 20px;

  &::before {
    content: '${props => props.value || 0} ';
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
    line-height: 21px;
    color: ${props => handleThemeFromObject(props.theme, 'colors.white', '#ffffff')};
    border-radius: 8px;
    background-color: ${props =>
      handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
    margin-right: 10px;
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.large`
    align-items: stretch;
    flex-direction: row;
  `}

  & svg {
    min-width: 230px;
    min-height: 230px;
  }
`;

export const EmptyStateInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0 50px 50px 50px;
  ${media.large`
  padding: 20px 20px 20px 0;
  `}

  & ${PrimaryLink} {
    height: 40px;
    width: 235px;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
    color: #fff !important;
    display: none;
    ${media.large`
      display:inline-flex;
    `}
  }
`;

export const EmptyStatePrimaryText = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  ${media.large`
  font-size: 22px;
  `}
`;

export const EmpyStateSecondaryText = styled.div`
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: center;
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  display: none;
  ${media.large`
    display: block;
  `}
`;

export const ChartRow = styled.div`
  display: flex;
  width: 100%;
  ${media.large`
    width: unset;
  `}
`;

export const LabelBox = styled.div`
  max-width: 100%;
  flex-grow: 1;
  ${media.large`
    max-width: ${props => (props.single ? '100%' : '50%')};
  `}
  & > div {
    width: 100%;
  }
`;

export const Donut = () => (
  <svg
    width="230px"
    height="230px"
    viewBox="-25 -25 274 274"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>gráfico-shadow copy</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M107.577548,0 C166.990988,0 215.155097,48.164109 215.155097,107.577548
        C215.155097,110.825095 215.011195,114.039032 214.729421,117.21333 L161.635411,117.21333 C162.189281,
        114.084895 162.478221,110.864965 162.478221,107.577548 C162.478221,77.2567441 137.898352,
        52.6768758 107.577548,52.6768758 L107.577548,2.66453526e-15 Z"
        id="path-1"
      />
      <path
        d="M106.577533,215.150545 C106.910512,215.153577 107.243853,
      215.155097 107.577548,215.155097 C140.253104,215.155097 169.526119,
      200.587091 189.255934,177.591741 L148.820624,143.815232 C138.759236,
      155.257337 124.011656,162.478221 107.577548,162.478221 C107.282659,
      162.478221 106.988312,162.475896 106.694526,162.471263 L106.577533,
      215.150545 Z"
        id="path-3"
      />
      <path
        d="M107.577548,215.155097 C48.164109,215.155097 0,166.990988
      0,107.577548 C0,48.164109 48.164109,-4.12904845e-15 107.577548,
      -4.12904845e-15 C166.990988,-4.12904845e-15 215.155097,48.164109
      215.155097,107.577548 C215.155097,166.990988 166.990988,215.155097
      107.577548,215.155097 Z M107.577548,162.478221 C137.898352,162.478221
      162.478221,137.898352 162.478221,107.577548 C162.478221,77.2567441
      137.898352,52.6768758 107.577548,52.6768758 C77.2567441,52.6768758
      52.6768758,77.2567441 52.6768758,107.577548 C52.6768758,137.898352
      77.2567441,162.478221 107.577548,162.478221 Z"
        id="path-5"
      />
    </defs>
    <g id="Final-(web)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Empty-states" transform="translate(-229.000000, -294.000000)">
        <g id="empty-state" transform="translate(181.000000, 223.000000)">
          <g id="gráfico-shadow-copy" transform="translate(48.000000, 71.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <use id="Combined-Shape" fill="#292929" xlinkHref="#path-1" />
            <path
              d="M214.949532,115.437136 C213.477753,139.369814
            204.181093,161.186103 189.586151,178.359404 L189.556047,
            178.354343 L165.556499,158.730084 L148.952168,145.631145
            L148.894226,144.88573 C155.965652,136.81105 160.708046,
            126.645336 162.072863,115.437136 L214.949532,115.437136 Z"
              id="Combined-Shape"
              fill="#585858"
            />
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3" />
            </mask>
            <use id="Combined-Shape" fill="#9B9B9B" xlinkHref="#path-3" />
            <mask id="mask-6" fill="white">
              <use xlinkHref="#path-5" />
            </mask>
            <use id="Combined-Shape" fill="#A4A4A4" opacity="0.608500466" xlinkHref="#path-5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
