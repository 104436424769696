import styled, { css } from 'styled-components';
import { SecondaryButton as RawSecondaryButton, Badge as RawBadge } from 'liber-components';

export const SecondaryButton = styled(RawSecondaryButton).attrs(() => ({
  version: 2,
  size: 'small',
}))``;

export const Button = styled(RawSecondaryButton).attrs(() => ({
  version: 2,
}))`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: none;
  border-radius: 4px;
`;

export const Badge = styled(RawBadge).attrs(() => ({
  position: { vertical: 'top', horizontal: 'right' },
  color: '#006AEB',
}))``;

export const Divisor = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.darkText70};
    position: absolute;
    width: 2px;
    height: 16px;
    left: calc(35% - 1px);
    top: calc(50% - 8px);
  `}
`;

export const Text = styled.p`
  margin: 0;
  padding-left: 4px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
