import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext({});

const ModalContextProvider = ({
  children,
  vendor,
  funder,
  kind,
  action,
  onConfirm,
  onCancel,
  isBatch,
}) => (
  <ModalContext.Provider value={{ vendor, funder, kind, action, onConfirm, onCancel, isBatch }}>
    {children}
  </ModalContext.Provider>
);

ModalContextProvider.propTypes = {
  action: PropTypes.oneOf(['block', 'unblock']),
  kind: PropTypes.oneOf(['funder', 'buyer']).isRequired,
  children: PropTypes.node.isRequired,
  vendor: PropTypes.string,
  funder: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isBatch: PropTypes.bool,
};

ModalContextProvider.defaultProps = {
  vendor: null,
  funder: null,
  onConfirm: () => null,
  onCancel: () => null,
  action: null,
  isBatch: false,
};

export default ModalContextProvider;
