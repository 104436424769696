import React from 'react';
import PropTypes from 'prop-types';
import AnticipationsTable from '../AnticipationsTable';
import * as S from './styles';

const ScheduledAnticipations = ({ token }) => (
  <S.AnticipationContainer>
    <S.TitleContainer>
      <S.ScreenTitle>Agendamentos</S.ScreenTitle>
    </S.TitleContainer>
    <AnticipationsTable token={token} />
  </S.AnticipationContainer>
);

ScheduledAnticipations.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ScheduledAnticipations;
