import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  TableHeaderColumn as RawTableHeaderColumn,
  TableRowColumn as RawTableRowColumn,
  FaBars,
  handleThemeFromObject as getTheme,
  LoadingSpinner,
} from 'liber-components';

import {
  DropdownToggle as RawDropDownToogle,
  Button as Btn,
  UncontrolledButtonDropdown as UbtnDd,
  DropdownItem as RawDropdownItem,
} from 'reactstrap';

export const DropdownItem = styled(RawDropdownItem)``;

DropdownItem.defaultProps = {
  tag: 'a',
};

export const UncontrolledButtonDropdown = styled(UbtnDd)`
  & .dropdown-menu {
    left: unset !important;
    right: 0px !important;
  }
`;

export const Button = styled(Btn)`
  white-space: nowrap;
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.bold', '700')};
  padding-right: 28px !important;
  padding-left: 28px !important;
  color: ${props => getTheme(props.theme, 'colors.v2.white', '#ffffff')}!important;
`;
Button.defaultProps = {
  tag: 'a',
};

export const DropdownToggle = styled(RawDropDownToogle)`
  color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')}!important;
  background-color: transparent !important;
  border-color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')}!important;

  &:hover {
    color: #212529 !important;
    background-color: transparent !important;
    border-color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')}!important;
  }

  &:focus:hover {
    color: #212529 !important;
    background-color: transparent !important;
    border-color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')}!important;
  }
  &:focus {
    color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')}!important;
    background-color: transparent !important;
    border-color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')}!important;
  }
`;

export const Bars = styled(FaBars)`
  --size: 18px;
  max-width: var(--size);
  min-width: var(--size);
  max-height: var(--size);
  min-height: var(--size);
  fill: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')};
`;

export const TableHeaderColumn = styled(RawTableHeaderColumn)`
  font-size: 17.1px;
  font-weight: ${props => getTheme(props.theme, 'fontWeights.medium', '500')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  --loading-color: ${props => getTheme(props.theme, 'colors.v2.lightBlue', '#4cbaff')};
`;

export const TableRowColumn = styled(RawTableRowColumn)`
  --loading-color: ${props => getTheme(props.theme, 'colors.v2.lightBlue', '#4cbaff')};
  font-size: 17.1px;
  font-weight: ${props => getTheme(props.theme, 'fontWeights.regular', '400')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  padding-left: 0px;
  padding-right: 0px;

  & .btn-group-sm > ${Button} {
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }
  & .btn-group-sm ${UncontrolledButtonDropdown} > .btn {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
`;
export const CheckboxColumn = styled(TableRowColumn)`
  font-size: 17.1px;
  font-weight: ${props => getTheme(props.theme, 'fontWeights.regular', '400')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  position: relative;
`;

const checkmarkShow = show => {
  if (show) {
    return css`
      height: 28px;
      width: 28px;
      border-width: 3px;
      margin: 0px;
    `;
  }
  return css`
    height: 0px;
    width: 0px;
    margin: 14px;
    border-width: 0px;
    &:after {
      display: none !important;
    }
  `;
};
const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  ${props => checkmarkShow(props.show)}
  /* height: 28px;
  width: 28px; */
  background-color: transparent;
  /* border: 3px solid #4CBAFF; */
  border-style: solid;
  border-color: ${props => getTheme(props.theme, 'colors.v2.lightBlue', '#4cbaff')};

  border-radius: 50%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;
const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Container = styled.label`
  display: block;
  position: absolute;
  top: calc(50% - 14px);
  left: 30px;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  &:hover input ~ ${Checkmark} {
    border-color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')};
  }
  & ${CheckboxInput}:checked ~ ${Checkmark} {
    background-color: ${props => getTheme(props.theme, 'colors.v2.lightGreen', '#13CE66')};
    border-color: ${props => getTheme(props.theme, 'colors.v2.lightGreen', '#13CE66')};
  }
  & ${CheckboxInput}:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    left: 8px;
    top: 5px;
    width: 7px;
    height: 10px;
    border: solid ${props => getTheme(props.theme, 'colors.v2.white', '#ffffff')};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg) scale(1.3);
  }
`;

CheckboxInput.defaultProps = {
  type: 'checkbox',
};

export const Checkbox = ({ show, ...props }) => (
  <Container>
    <CheckboxInput {...props} />
    <Checkmark show={show} />
  </Container>
);

Checkbox.propTypes = {
  show: PropTypes.bool,
};

Checkbox.defaultProps = {
  show: false,
};

export const Spinner = styled(LoadingSpinner)`
  display: block;
  position: absolute;
  --color: ${props => getTheme(props.theme, 'colors.v2.lightBlue', '#4cbaff')};
  --size: 28px;
  top: calc(50% - 14px);
  left: 30px;
  & * {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  &:hover {
    --color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009dff')};
  }
`;

Spinner.defaultProps = {
  color: 'var(--color)',
  size: 'var(--size)',
};
