import React, { useState, useEffect } from 'react';
import { Checkbox, Modal } from 'liber-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replicateVendor, fetchVendorOptions } from '../actions/replicateVendorActions';
import LoadingDialog from './loadingDialog';
import {
  PrimaryButton,
  Form,
  SelectBox,
  TextField,
  CheckboxLabel,
  SubTitle,
  SaveButton,
  TitleModal,
  DialogActions,
  CanceledButton,
  ContainerModal,
} from './styles';
import { handleSuccess } from '../../../vendor/Utils';

function ReplicateVendor({ onReplicateVendor, vendorPortalOptions, onFetchVendorOptions }) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [rate, setRate] = useState('');
  const [account, setAccount] = useState(true);
  const [portal, setPortal] = useState('');

  const urlParams = new URLSearchParams(window.location.search);

  const checkUrlParams = () => {
    if (urlParams.has('redirect')) {
      handleSuccess(
        'Cadastro duplicado com sucesso!',
        'O cadastro foi duplicado e você foi redirecionado para a página do fornecedor duplicado.',
      );
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  useEffect(() => {
    onFetchVendorOptions();
    checkUrlParams();
  }, []);

  const handleClear = () => {
    setPortal('');
    setRate('');
    setAccount(true);
  };

  const handleCloseModal = () => {
    setConfirmationOpen(false);
    handleClear();
  };

  const handleReplicateVendor = () => {
    onReplicateVendor(rate, account, portal);
    handleCloseModal();
  };

  const handleSetRate = value => {
    if (value >= 0) {
      setRate(parseFloat(value));
    }
  };

  return (
    <>
      <PrimaryButton onClick={() => setConfirmationOpen(true)}>DUPLICAR CADASTRO</PrimaryButton>
      <Modal show={confirmationOpen} onLeaved={handleCloseModal} overflow="auto">
        <ContainerModal>
          <TitleModal>Duplicar cadastro</TitleModal>
          <Form>
            <SubTitle>Insira as novas informações do fornecedor</SubTitle>
            <SelectBox onChange={setPortal} label="Portal *">
              {vendorPortalOptions.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </SelectBox>
            <TextField
              fullWidth
              value={rate}
              onChange={handleSetRate}
              step={0.1}
              precision={2}
              label="Taxa combinada (%) *"
            />
            <Checkbox checked={account} onChange={event => setAccount(event.target.checked)}>
              <CheckboxLabel>Conta bancária obrigatória</CheckboxLabel>
            </Checkbox>
          </Form>
          <DialogActions>
            <CanceledButton onClick={handleCloseModal}>CANCELAR</CanceledButton>
            <SaveButton disabled={!rate || !portal} onClick={handleReplicateVendor}>
              SALVAR
            </SaveButton>
          </DialogActions>
        </ContainerModal>
      </Modal>
      <LoadingDialog />
    </>
  );
}

ReplicateVendor.propTypes = {
  onReplicateVendor: PropTypes.func,
  onFetchVendorOptions: PropTypes.func,
  vendorPortalOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

ReplicateVendor.defaultProps = {
  onReplicateVendor: () => {},
  onFetchVendorOptions: () => {},
  vendorPortalOptions: [],
};

const mapDispatchToProps = {
  onReplicateVendor: replicateVendor,
  onFetchVendorOptions: fetchVendorOptions,
};

const mapStateToProps = ({ replicateVendorReducer: { vendorPortalOptions } }) => ({
  vendorPortalOptions,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplicateVendor);
