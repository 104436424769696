import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryButton, SelectField } from 'liber-components';
import { showAccount, fetchRemittanceType, fetchRemittance } from '../actions/remittanceActions';
import { ScreenTitle, TitleContainer } from './styles';
import { typesRemittance, showRemittanceKindFilter } from '../utils';
import Table from './Table/Table';
import { handleError } from '../../../vendor/Utils';
import LoadingButton from '../../../common-components/Buttons/LoadingButton';

function Remittance({
  selectedTradeIds,
  onAccountShow,
  accounts,
  onFetchRemittanceType,
  onFetchRemittance,
  isAdmin,
  downloadReportLoading,
  funderActionsText,
  showFunderBankAccount,
}) {
  const [remittance, setRemittance] = useState('0');
  const [accountSelect, setAccountSelect] = useState('');
  const billingRemittanceKind = '0';
  useEffect(() => {
    onAccountShow();
  }, []);
  const handleSelectRemittance = text => {
    setRemittance(text);
    onFetchRemittanceType(text);
  };
  useEffect(() => {
    if (remittance !== 0) {
      setAccountSelect('');
    }
  }, [remittance]);
  const isAccountSelected = Boolean(accountSelect);
  const shouldFetchRemittance = isAdmin
    ? isAccountSelected || remittance !== billingRemittanceKind
    : !showFunderBankAccount || isAccountSelected;
  const handleRemittance = () => {
    if (shouldFetchRemittance) {
      onFetchRemittance(selectedTradeIds, accountSelect);
    } else
      handleError(
        'Não foi possível criar a remessa',
        'Você precisa escolher uma conta bancária para criar uma remessa dos títulos selecionados',
      );
  };
  return (
    <>
      <ScreenTitle>Criar remessas</ScreenTitle>
      <TitleContainer>
        {showFunderBankAccount || isAdmin ? (
          <SelectField
            version="2"
            label="Conta bancária"
            openLabel
            disabled={remittance !== billingRemittanceKind}
            value={accountSelect}
            onChange={setAccountSelect}
          >
            {accounts?.map(({ id, bankAccount }) => (
              <option key={id} value={id}>
                {bankAccount}
              </option>
            ))}
          </SelectField>
        ) : (
          <div />
        )}

        {isAdmin && showRemittanceKindFilter() ? (
          <SelectField
            version="2"
            label="Tipo de remessa"
            openLabel
            value={remittance}
            onChange={handleSelectRemittance}
            data-testid="tipo-de-remessa-select"
          >
            {typesRemittance?.map(({ id, value }) => (
              <option value={id}>{value}</option>
            ))}
          </SelectField>
        ) : (
          <div />
        )}
        <LoadingButton
          ButtonComponent={PrimaryButton}
          onClick={handleRemittance}
          size="large"
          loading={downloadReportLoading}
          disabled={selectedTradeIds.length === 0}
        >
          {funderActionsText || 'Criar arquivo remessa'}
        </LoadingButton>
      </TitleContainer>
      <Table />
    </>
  );
}

Remittance.propTypes = {
  store: PropTypes.shape({}).isRequired,
  selectedTradeIds: PropTypes.arrayOf(PropTypes.number),
  accounts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onAccountShow: PropTypes.func,
  onFetchRemittanceType: PropTypes.func,
  onFetchRemittance: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
  downloadReportLoading: PropTypes.bool,
  funderActionsText: PropTypes.string,
  showFunderBankAccount: PropTypes.bool,
};
Remittance.defaultProps = {
  selectedTradeIds: [],
  accounts: [],
  onAccountShow: () => {},
  onFetchRemittanceType: () => {},
  onFetchRemittance: () => {},
  downloadReportLoading: false,
  funderActionsText: '',
  showFunderBankAccount: false,
};

const mapDispatchToProps = {
  onAccountShow: showAccount,
  onFetchRemittanceType: fetchRemittanceType,
  onFetchRemittance: fetchRemittance,
};

const mapStateToProps = ({
  remittance: {
    selectedTradeIds,
    accounts,
    isAdmin,
    downloadReportLoading,
    funderActionsText,
    showFunderBankAccount,
  },
}) => ({
  selectedTradeIds,
  accounts,
  isAdmin,
  downloadReportLoading,
  funderActionsText,
  showFunderBankAccount,
});

export default connect(mapStateToProps, mapDispatchToProps)(Remittance);
