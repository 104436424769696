import React from 'react';
import PropTypes from 'prop-types';
import { NotificationBox } from 'liber-components';

const ListErrors = ({ failedInvoices: invoices }) => (
  <NotificationBox
    label={
      invoices.length > 1
        ? `${invoices.length} documentos com erros encontrados:`
        : '1 documento com erro encontrado:'
    }
  >
    {invoices.map(invoice => {
      if (invoice.jobStatus === 'failed') {
        return <p key={invoice.id}> Título número: {invoice.invoiceNumber}</p>;
      }
      if (invoice.invoiceError) {
        return (
          <p key={invoice.id}>
            {' '}
            Título número: {invoice.invoiceNumber}, {invoice.errorMsg}
          </p>
        );
      }
      return null;
    })}
  </NotificationBox>
);

ListErrors.propTypes = {
  failedInvoices: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ListErrors;
