import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer, Liber } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import hydrateStore from '../store';
import MultiProposal from './components/MultiProposal';

const View = ({ proposals, summary, endpoints }) => {
  const store = {
    multiProposal: {
      proposals,
      summary,
    },
  };
  return (
    <Provider store={hydrateStore(store, endpoints)}>
      <div>
        <ThemeProvider theme={Liber}>
          <MultiProposal />
        </ThemeProvider>
        <ToastContainer />
      </div>
    </Provider>
  );
};

View.propTypes = {
  proposals: PropTypes.objectOf(PropTypes.any).isRequired,
  summary: PropTypes.objectOf(PropTypes.any).isRequired,
  endpoints: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
