import React, { useRef, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import { TokenContext } from '../../../../hocs/withTokenProvider';
import { Container, Header, Content, Input, InputFile, ActionsRow, Button } from './styles';
import { handleError } from '../../../../vendor/Utils';
import LoadingButton from '../../../../common-components/Buttons/LoadingButton';
import { sendSpreadsheet } from '../../api';
import { ERROR_MAPPINGS } from '../../constants';

const handleErrorMessage = errorMessage => {
  if (errorMessage) {
    Object.keys(ERROR_MAPPINGS).some(key => {
      if (errorMessage.toLowerCase().includes(key)) {
        const { title, message } = ERROR_MAPPINGS[key];
        handleError(title, message);

        return true;
      }
      return false;
    });
  } else {
    handleError();
  }
};

function UploadModal({ onLeaved, show, onUpload }) {
  const inputRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { token } = useContext(TokenContext);

  useEffect(() => {
    if (!show) {
      setSelectedFile(null);
      setLoading(false);
    }
  }, [show]);

  const handleInputClick = event => {
    event.stopPropagation();
    inputRef.current.click();
  };

  const handleFileUpload = event => {
    const [file] = event?.target?.files;
    setSelectedFile(file);
  };

  const handleSendFile = () => {
    setLoading(true);
    sendSpreadsheet(token, selectedFile)
      .then(() => {
        onUpload();
        onLeaved();
      })
      .catch(error => {
        const errorMessage = error?.response?.data?.error;

        handleErrorMessage(errorMessage);

        setLoading(false);
      });
  };

  return (
    <Modal show={show} onLeaved={onLeaved}>
      <Container>
        <Header>Atualizar Limites</Header>
        <Content>
          Insira uma planilha de limites do seu computador e envie para a atualização dos novos
          limites dos fornecedores.
          <br />
          <br />A atualização pode levar alguns minutos.
        </Content>

        {loading ? null : (
          <Input
            data-testid="spreadsheet-upload-input"
            onChange={handleFileUpload}
            ref={inputRef}
          />
        )}
        <InputFile
          label="Planilha de Limites"
          onClick={handleInputClick}
          value={selectedFile?.name}
        />

        <ActionsRow>
          <Button onClick={onLeaved}>FECHAR</Button>
          <LoadingButton
            ButtonComponent={Button}
            spinnerColor="#009dff"
            loading={loading}
            onClick={handleSendFile}
            disabled={!selectedFile}
          >
            ENVIAR
          </LoadingButton>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

UploadModal.propTypes = {
  onLeaved: PropTypes.func,
  show: PropTypes.bool,
  onUpload: PropTypes.func,
};

UploadModal.defaultProps = {
  onLeaved: () => {},
  show: true,
  onUpload: () => {},
};

export default UploadModal;
