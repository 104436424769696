import styled from 'styled-components';
import { TEXT_COLOR } from '../CommonStyles';

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin: 24px 0;
  color: ${TEXT_COLOR};
`;

export const Header = styled.div`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  color: ${TEXT_COLOR};
  margin-bottom: 0;
`;

export const ListContainer = styled.div`
  padding: 8px;
  background-color: #405f7114;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const ListContent = styled.div`
  max-height: 250px;
  overflow-y: auto;
  color: ${TEXT_COLOR};
  * {
    font-family: Roboto;
  }
  & li {
    list-style: inside;
  }
  &::-webkit-scrollbar {
    margin-right: 20px;
    width: 6px;
    padding: 2px;
    border-radius: 8px;
    background-color: #cdd7df;
  }
  &::-webkit-scrollbar-thumb {
    border: solid 2px #cdd7df;
    border-radius: 8px;
    background-color: #4b6f85;
  }
`;

export const List = styled.ul`
  padding-left: 4px;
`;

export const TopBorder = styled.div`
  padding-top: 4px;
  width: 480px;
  background-color: #dc3545;
  border-radius: 4px 4px 0;
`;
