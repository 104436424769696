/* eslint-disable no-unused-vars */
import { camelCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';
import Cookie from 'js.cookie';

import { toast } from 'liber-components/components/Toast';
import { BANKS_URL } from '../views/admin-vendors/urls';

export { default as Countries } from './constants/countries.json';
export { default as EmissionIssuers } from './constants/emissionIssuers.json';
export { default as States } from './constants/states.json';
export { default as Cities } from './constants/cities.json';

const TOAST_DURATION = 5000;

export const fetchBanks = async () => {
  try {
    const result = await axios.get(BANKS_URL);

    return result.data;
  } catch (e) {
    console.error(e);
  }

  return false;
};

export const paginationShape = PropTypes.shape({
  current: PropTypes.number,
  previous: PropTypes.number,
  next: PropTypes.number,
  per: PropTypes.number,
  pages: PropTypes.number,
  count: PropTypes.number,
});

export const capitalize = s =>
  s ? s.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase()) : s;

export const convertToCamelCase = object => {
  if (!!object && typeof object === 'object') {
    let convertedObject = {};

    if (Array.isArray(object)) {
      convertedObject = object.map(convertToCamelCase);
    } else {
      Object.keys(object).forEach(key => {
        const newKey = camelCase(key);
        convertedObject[newKey] = convertToCamelCase(object[key]);
      });
    }
    return convertedObject;
  }
  return object;
};

export const cleanTaxNumber = taxNumber => taxNumber?.replace(/\.|-|\//g, '');

export const isCNPJ = string => {
  const regex = /[0-9]{2}.?[0-9]{3}.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/;

  return regex.test(string);
};

export const isCPF = string => {
  const regex = /[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/;

  return regex.test(string);
};

export const handleNavigate = path => window.location.assign(path);

export const formatTaxNumber = taxNumber =>
  isCNPJ(taxNumber) || isCPF(taxNumber) ? cleanTaxNumber(taxNumber) : taxNumber;

export const handleError = (
  message = 'Um erro ocorreu',
  info = 'Por favor tente novamente mais tarde ou entre em contato com o suporte',
) => {
  toast(
    {
      message,
      info,
    },
    'error',
    TOAST_DURATION,
  );
};

export const handleSuccess = (message = 'Sucesso', info = 'Sua ação foi executada com sucesso') => {
  toast(
    {
      message,
      info,
    },
    'success',
    TOAST_DURATION,
  );
};

export const handleInfo = (message = 'Aviso', info = 'Sua ação foi executada com sucesso') => {
  toast(
    {
      message,
      info,
    },
    'info',
    TOAST_DURATION,
  );
};

export const handleWarning = (message = 'Atenção', info = 'Sua ação não foi executada') => {
  toast(
    {
      message,
      info,
    },
    'warning',
    TOAST_DURATION,
  );
};

export const getPaginationInterval = ({ current, per, total }) => {
  const end = current * per;
  const start = (current - 1) * per + 1;
  return { start: String(start), end: String(end > total ? total : end) };
};

export const dispatchDataLayerEvent = (event, isAdmin = false, additionalParams = {}) => {
  const dataLayer = global.dataLayer || [];
  dataLayer.push({ event, ...additionalParams });
};

export const dispatchAmplitudeEvent = (event, additionalParams = {}) => {
  if (global.amplitude && event) {
    global.amplitude.logEvent(event, additionalParams);
  }
};

export function formatCnpjOrCpf(value) {
  const cnpjOrCpf = String(value).replace(/\D/g, '');

  const isCpf = cnpjOrCpf.length === 11;
  if (isCpf) {
    return cnpjOrCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  const isCnpj = cnpjOrCpf.length === 14;
  if (isCnpj) {
    return cnpjOrCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  return value;
}

export const adjustDateFilters = (fields, filters) => {
  const filterKeys = Object.keys(filters);
  const newFilters = {};
  filterKeys.forEach(key => {
    let value = filters[key];
    if (key.includes('lteq') && fields.includes(key)) {
      const newDate = moment(value, 'DD/MM/YYYY').startOf('day').add(1, 'day');
      value = newDate.format('DD/MM/YYYY');
    }
    newFilters[key] = value;
  });
  return newFilters;
};

export const getSubdomain = () => window.location.hostname.match(/^(\w+)\./)?.[1];

export const hasFilters = filters => Object.values(filters).some(Boolean);

export const fixPercent = num =>
  parseFloat((num * 100).toPrecision(12))
    .toFixed(2)
    .replace('.', ',');

export const paymentDateIsGreaterThanToday = payUpAt => {
  const paymentDate = moment(payUpAt).startOf('day');
  const today = moment().startOf('day');

  return paymentDate > today;
};

export const setCookie = (name, value, days) => {
  const expires = moment().add(days, 'days').toDate();

  Cookie.set(name, value, { expires });
};

export const getCookie = name => Cookie.get(name);

export const getDifferenceInDays = (date1, date2) => moment(date1).diff(moment(date2), 'days');

export const getRelativeTimeMessage = dateString => {
  const referenceDate = new Date(dateString);
  const differenceInDays = Math.round((referenceDate - new Date()) / (1000 * 60 * 60 * 24));
  const rtf = new Intl.RelativeTimeFormat('pt-BR', { style: 'long' });

  return rtf.format(differenceInDays, 'day');
};

export const formatValueToBRL = value => {
  const formattedValue = parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedValue;
};
