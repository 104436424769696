import axios from 'axios';
import { VENDORS_GROUP_HISTORY_URL } from './constants';

export async function fetchHistory(token = '', groupId) {
  return axios.get(VENDORS_GROUP_HISTORY_URL.replace(':id', groupId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
