import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { FundersDrawerProvider } from './context';
import Table from './components/Table';
import VendorInfo from './components/VendorInfo';
import * as S from './styles';

const VendorDetailsDrawer = ({
  open,
  onLeaved,
  selectedVendorBuyerId,
  selectedVendorId,
  token,
  handleSelectedFunderToBlockOrUnblock,
  handleIntermediateBlockUnblockStatuses,
  defaultFilters,
  isAdmin,
}) => (
  <S.Drawer onLeaved={onLeaved} show={open}>
    <FundersDrawerProvider
      selectedVendorBuyerId={selectedVendorBuyerId}
      open={open}
      onLeaved={onLeaved}
      token={token}
      selectedVendorId={selectedVendorId}
      handleSelectedFunderToBlockOrUnblock={handleSelectedFunderToBlockOrUnblock}
      handleIntermediateBlockUnblockStatuses={handleIntermediateBlockUnblockStatuses}
      defaultFilters={defaultFilters}
      isAdmin={isAdmin}
    >
      <S.Header>
        <S.Title>Situação Cadastral</S.Title>
        <S.CloseButton onClick={onLeaved}>
          <Icon path={mdiClose} size={1} />
        </S.CloseButton>
      </S.Header>
      <VendorInfo />
      <Table />
      <S.Footer>
        <S.Button onClick={onLeaved}>Fechar</S.Button>
      </S.Footer>
    </FundersDrawerProvider>
  </S.Drawer>
);

VendorDetailsDrawer.propTypes = {
  open: PropTypes.bool,
  onLeaved: PropTypes.func,
  selectedVendorBuyerId: PropTypes.number,
  token: PropTypes.string.isRequired,
  selectedVendorId: PropTypes.number,
  handleSelectedFunderToBlockOrUnblock: PropTypes.func,
  isAdmin: PropTypes.bool,
  defaultFilters: PropTypes.object,
  handleIntermediateBlockUnblockStatuses: PropTypes.func,
};

VendorDetailsDrawer.defaultProps = {
  open: false,
  onLeaved: () => null,
  selectedVendorBuyerId: null,
  selectedVendorId: null,
  isAdmin: true,
  handleSelectedFunderToBlockOrUnblock: () => null,
  handleIntermediateBlockUnblockStatuses: () => null,
  defaultFilters: null,
};

export default VendorDetailsDrawer;
