import axios from 'axios';
import { WALLET_MEAN_URL } from '../url';
import { convertToCamelCase, handleError } from '../../../vendor/Utils';

export const FETCH_WALLET_MEAN = 'FETCH_WALLET_MEAN';
export const UPDATE_WALLET_MEAN = 'UPDATE_WALLET_MEAN';
export const WALLET_MEAN_ERROR = 'WALLET_MEAN_ERROR';

export const updateWalletMean = walletMeanData => ({ type: UPDATE_WALLET_MEAN, walletMeanData });

export const fetchWalletMean = () => (dispatch, getState) => {
  dispatch({ type: FETCH_WALLET_MEAN });

  const {
    investorDashboard: {
      walletMean: { token },
    },
  } = getState();

  return axios
    .get(WALLET_MEAN_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      const { data } = response;
      dispatch(updateWalletMean(convertToCamelCase(data)));
    })
    .catch(error => {
      const errorMessage =
        error?.response?.data?.error ||
        'Ocorreu um erro ao efetuar os cálculos da média da carteira.';

      dispatch({ type: WALLET_MEAN_ERROR });
      handleError('Erro!', errorMessage);
    });
};
