import styled from 'styled-components';
import { CompleteTable, LoadingBunny } from 'liber-components';

export const Table = styled(CompleteTable)`
  max-width: 392px;
  margin-bottom: 18px;
`;

export const Loading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  background-color: white;
  transition: opacity 150ms ease-in-out;
  z-index: 201;
  position: relative;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;
