import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiArrowRight } from '@mdi/js';
import { Text } from 'liber-components';

import { FlashAlert, LinkButton } from './styles';
import {
  checkContractAcceptance,
  getRenegotiatedCount,
  NEW_CONTRACT_PATH,
  RENEGOTIATIONS_PATH,
} from './helper';

function AlertsContainer({ token }) {
  const [renegotiatedCount, setRenegotiatedCount] = useState(0);
  const [acceptance, setAcceptance] = useState({});

  useEffect(() => {
    getRenegotiatedCount(token).then(setRenegotiatedCount);
    checkContractAcceptance(token).then(setAcceptance);
  }, []);

  const renderRenegotiationsMessage = () =>
    renegotiatedCount === 1 ? (
      <>
        Você possui <b>1</b> título que foi renegociado.
      </>
    ) : (
      <>
        Você possui <b>{renegotiatedCount}</b> títulos que foram renegociados.
      </>
    );

  const { mustAccept, deadline } = acceptance;

  return (
    <>
      {mustAccept ? (
        <FlashAlert>
          <Text large>
            Para continuar utilizando a plataforma, leia e aceite o <b>novo Contrato de Comissão</b>{' '}
            {deadline ? (
              <>
                até o dia <b>{deadline}</b>
              </>
            ) : null}
          </Text>
          <LinkButton href={NEW_CONTRACT_PATH}>
            <Icon path={mdiArrowRight} />
            Ver
          </LinkButton>
        </FlashAlert>
      ) : null}
      {renegotiatedCount ? (
        <FlashAlert>
          <Text large>{renderRenegotiationsMessage()}</Text>
          <LinkButton href={RENEGOTIATIONS_PATH}>
            <Icon path={mdiArrowRight} />
            Ir para renegociações
          </LinkButton>
        </FlashAlert>
      ) : null}
    </>
  );
}

AlertsContainer.propTypes = {
  token: PropTypes.string,
};

AlertsContainer.defaultProps = {
  token: '',
};

export default AlertsContainer;
