import { connect } from 'react-redux';
import { postClientLog } from '../../../../vendor/client';
import Wizard from './Wizard';
import { setCurrent } from '../../actions/progressBar';
import { fetchData, redirectTo, setWindowHash } from '../../actions/wizard';
import { getRoot } from '../../presenters/paths';

const mapStateToProps = state => ({
  current: state.progressBar.current,
  completed: state.progressBar.completed,
  flashes: state.progressBar.flashes,
  approved: state.progressBar.approved,
  root: getRoot(state),
});

const mapDispatchToProps = {
  fetchData,
  postClientLog,
  redirectTo,
  setCurrent,
  setWindowHash,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
