import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Theme } from 'liber-salti';

import Message from './Message';

function View(props) {
  const muiTheme = createTheme(Theme);

  return (
    <StyledThemeProvider theme={muiTheme}>
      <ThemeProvider theme={muiTheme}>
        <Message {...props} />
      </ThemeProvider>
    </StyledThemeProvider>
  );
}

export default View;
