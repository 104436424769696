import styled, { css } from 'styled-components';
import {
  RadioButton,
  TextField,
  FlatButton as RawFlatButton,
  PrimaryButton as RawPrimaryButton,
} from 'liber-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px 24px;
  max-width: 480px;
  overflow: auto;
`;

export const ModalTitle = styled.h6`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.darkText70};
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.p};
    line-height: 22px;
    color: ${theme.colors.darkText70};
  `}
`;

export const BlockOptionsTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.p};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.dark};
  `}
`;

export const BlockOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Radio = styled(RadioButton)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.p};
  `}
`;

export const Input = styled(TextField).attrs(() => ({
  version: 2,
}))`
  margin-top: -4px;
  width: 432px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const PrimaryButton = styled(RawPrimaryButton).attrs(() => ({ version: 2 }))`
  text-transform: uppercase;
`;

export const Button = styled(RawFlatButton).attrs(() => ({ version: 2 }))`
  text-transform: uppercase;
`;
