import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { handleThemeFromObject, LoadingSpinner, FlatButton } from 'liber-components';
import { mdiEye } from '@mdi/js';

const FONT_FAMILY = 'Roboto';
const DARK_BORDER_COLOR = 'rgba(35, 39, 47, 0.87)';
const TITLE_COLOR = 'rgba(35, 39, 47, 0.87)';
const TEXT_COLOR = 'rgba(35, 39, 47, 0.64)';
const PRIMARY_COLOR = '#009dff';
const SUCCESS_COLOR = '#008a5e;';
const ERROR_COLOR = '#ff5b56';
const WHITE = '#f9fafa';

const getTheme = (key, fallback) =>
  css`
    ${({ theme }) => handleThemeFromObject(theme, key, fallback)}
  `;

const getFont = () =>
  css`
    font-family: ${getTheme('fontFamily', FONT_FAMILY)};
  `;

const getLabelColor = ({ type }) => {
  switch (type) {
    case 'negative':
      return getTheme('colors.darkError', ERROR_COLOR);
    case 'positive':
      return SUCCESS_COLOR;
    case 'neutral':
    default:
      return getTheme('colors.darkText70', TEXT_COLOR);
  }
};

export const EyeIcon = styled(Icon).attrs(() => ({ path: mdiEye, size: '20px' }))``;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;
  justify-content: center;
  min-height: 400px;
`;

export const Spinner = styled(LoadingSpinner).attrs(() => ({
  size: '56px',
  color: '#009dff',
  thickness: '4px',
}))``;

const Flex = styled.div`
  display: flex;

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

    ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb};
    `}
`;

export const Row = styled(Flex)`
  width: 100%;
`;

export const CardTitle = styled(Row)`
  ${getFont()}
  font-size: 16px;
  font-weight: bold;
  line-height: 1.06;
  letter-spacing: 0.06px;
  color: ${getTheme('colors.darkText', TITLE_COLOR)};
  margin-bottom: 21px;

  svg {
    fill: ${getTheme('colors.darkText', TITLE_COLOR)};
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }
`;

export const StatusCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 15px;
  margin-right: 8px;
  margin-bottom: 2px;
`;

export const CardData = styled(Row).attrs(() => ({ justify: 'flex-end', align: 'center' }))`
  ${getFont()}
  font-size: 20px;
  font-weight: normal;
  line-height: 1.22;
  letter-spacing: 0.1px;
  color: ${getLabelColor};

  ${StatusCircle} {
    background-color: ${getLabelColor};
  }
`;

export const SelectableCard = styled.div`
  width: 200px;
  border-radius: 4px;
  border: solid 1px ${getTheme('colors.darkBorder', DARK_BORDER_COLOR)};
  padding: 16px;
  margin-right: 26px;

  ${({ onClick, selected }) =>
    Boolean(onClick) &&
    !selected &&
    css`
      cursor: pointer;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${getTheme('colors.primary', PRIMARY_COLOR)};
      border: solid 1px ${getTheme('colors.primary', PRIMARY_COLOR)};
      div {
        color: ${getTheme('colors.liberWhite', WHITE)};
      }
      svg {
        fill: ${getTheme('colors.liberWhite', WHITE)};
      }
    `}
`;

export const Button = styled(FlatButton).attrs(() => ({ size: 'large' }))`
  padding-left: 8px;
`;
