import React from 'react';
import PropTypes from 'prop-types';

import ScreenTitle from '../../common-components/ScreenTitle';
import FunderVendorsGroupsProvider from './context';
import GroupsTable from './components/Table';

const View = ({ token }) => (
  <FunderVendorsGroupsProvider token={token}>
    <ScreenTitle handleBack={() => window.history.back()}>Grupos de Fornecedores</ScreenTitle>
    <GroupsTable />
  </FunderVendorsGroupsProvider>
);

View.propTypes = {
  token: PropTypes.string.isRequired,
};

export default View;
