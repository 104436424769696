import styled from 'styled-components';
import { handleThemeFromObject as getTheme, TextField, PrimaryButton } from 'liber-components';

export const Container = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  padding: 2px 24px 12px 0px;
  * {
    font-family: Roboto;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const NameInput = styled(TextField).attrs(() => ({ version: 2 }))`
  width: 357px;
  margin-bottom: 24px;
`;

export const TextInput = styled(TextField).attrs(() => ({ version: 2 }))`
  width: 357px;
`;

export const TransferListsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  align-items: center;
  width: 100%;
  overflow: auto;
`;

export const Button = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #009dff;
  border-radius: 4px;
  margin: 8px 0;
  opacity: 0.38;
  ${({ active = true }) => active && 'cursor: pointer; opacity: 1;'}
  & svg {
    height: 20px;
    color: #009dff;
  }
`;

export const HintText = styled.div`
  font-size: 12px;
  color: #6287a7;
  margin-top: 4px;
`;

export const CenterContainer = styled.div`
  margin: 0 20px;
`;

export const FooterActions = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
`;

export const ButtonRight = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
  margin-left: 8px;
`;

export const ButtonOutline = styled(PrimaryButton).attrs(() => ({
  outlined: true,
  version: 2,
  size: 'large',
}))`
  text-transform: uppercase;
  margin-left: auto;
`;

export const HistoryButton = styled(PrimaryButton).attrs(() => ({
  outlined: true,
  version: 2,
  size: 'large',
}))`
  width: 190px;
  margin-left: auto;
  text-transform: uppercase;
  padding: 0 16px 0 16px;
`;
