import ActionCable from 'actioncable';

let instance = null;
let counter = 0;

class CableConnection {
  static instance;

  options = {
    counter: 3,
  };

  constructor(uri, options = {}) {
    if (instance) {
      return instance;
    }
    this.cable = ActionCable.createConsumer(uri);
    this.channels = {};
    this.options = { ...this.options, ...options };
    instance = this;
  }

  static getConnection = () => {
    if (instance) {
      return instance;
    }
    console.error('There are no connection established.');
    return null;
  };

  setUpChannel = (channelName, received, connected, timeoutFunc = null) => {
    const connection = instance;
    connection.channels[channelName] = connection.cable.subscriptions.create(
      { channel: channelName },
      { received, connected },
    );
    if (timeoutFunc) {
      timeoutFunc(connection, channelName);
    }
    return connection;
  };

  static resetConnection = () => {
    if (instance) {
      instance.cable.disconnect();
    }
    instance = null;
  };

  sendMessage = (channelName, data, timeoutFunc = null) => {
    const connection = instance;
    if (this.channels[channelName]) {
      this.channels[channelName].send(data);
      if (timeoutFunc) {
        timeoutFunc(connection, channelName);
      }
    } else {
      console.error('Channel does not exist');
    }
  };

  sendCountedMessage = (...args) => {
    if (counter < this.options.counter) {
      this.sendMessage(...args);
      counter += 1;
      return true;
    }
    counter = 0;
    return false;
  };

  getCounter = () => counter;
}
export default CableConnection;
