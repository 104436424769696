import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import hydrateStore from '../../views/store';
import VendorInvoices from './components/VendorInvoices/VendorInvoices';

const VendorInvoicesComponent = ({
  beforeOperatingStartTime,
  beforeOperatingStartTimeMessage,
  token,
  vendorId,
  canInputXml,
  inputXmlPath,
  isAdmin,
  showButton,
  buyerOperationMode,
  hasClicked,
  vendor,
  isBuyerAutomaticOffer,
  buyerIdentifier,
}) => (
  <>
    <Provider
      store={hydrateStore({
        vendorInvoices: {
          token,
          vendorId,
          canInputXml,
          inputXmlPath,
          isAdmin,
          buyerOperationMode,
          buyerIdentifier,
          beforeOperatingStartTime,
          isBuyerAutomaticOffer,
          beforeOperatingStartTimeMessage,
        },
        invoiceDialog: { isAdmin },
      })}
    >
      <VendorInvoices
        showButton={showButton}
        hasClicked={hasClicked}
        vendor={vendor}
        buyerIdentifier={buyerIdentifier}
        beforeOperatingStartTime={beforeOperatingStartTime}
        beforeOperatingStartTimeMessage={beforeOperatingStartTimeMessage}
      />
    </Provider>
  </>
);

VendorInvoicesComponent.propTypes = {
  beforeOperatingStartTime: PropTypes.bool,
  beforeOperatingStartTimeMessage: PropTypes.string,
  token: PropTypes.string.isRequired,
  vendorId: PropTypes.number.isRequired,
  inputXmlPath: PropTypes.string,
  canInputXml: PropTypes.bool,
  isAdmin: PropTypes.bool,
  hasClicked: PropTypes.bool,
  vendor: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  showButton: PropTypes.bool,
  isBuyerAutomaticOffer: PropTypes.bool,
  buyerOperationMode: PropTypes.string,
  buyerIdentifier: PropTypes.string,
};

VendorInvoicesComponent.defaultProps = {
  beforeOperatingStartTime: false,
  beforeOperatingStartTimeMessage: null,
  inputXmlPath: '',
  canInputXml: false,
  isAdmin: false,
  hasClicked: false,
  vendor: {},
  showButton: false,
  isBuyerAutomaticOffer: false,
  buyerOperationMode: undefined,
  buyerIdentifier: 'default',
};

export default VendorInvoicesComponent;
