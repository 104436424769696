import Immutable from 'seamless-immutable';
import _ from 'lodash';

export const steps = {
  dados_pessoais: 'Dados Pessoais',
  documentacao: 'Documentação',
  enderecos: 'Endereço',
  dados_bancarios: 'Dados Bancários',
  confirmacao: 'Confirmação',
  contratos: 'Contratos',
  concluido: 'Concluído',
};

const initialState = Immutable({
  steps: _.omit(steps, ['confirmacao', 'contratos', 'concluido']),
  approved: false,
  current: 0,
  completed: 0,
  flashes: [],
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'WIZARD_INVESTIDOR/PROGRESS_BAR/PREVIOUS_STEP':
      return state.merge({ current: state.current === 1 ? state.current : state.current - 1 });
    case 'WIZARD_INVESTIDOR/PROGRESS_BAR/NEXT_STEP':
      return state.merge({
        current: state.current === Object.keys(steps).length ? state.current : state.current + 1,
      });
    case 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_CURRENT':
      return state.merge({ current: action.current });
    case 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_CURRENT_STRING':
      return state.merge({
        current: Object.keys(steps).findIndex(step => step === action.current) + 1,
      });
    case 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_COMPLETED':
      return state.merge({ completed: Math.max(state.completed, action.completed) });
    case 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_COMPLETED_STRING':
      return state.merge({
        completed: Math.max(
          state.completed,
          Object.keys(steps).findIndex(step => step === action.completed),
        ),
      });
    default:
      return state;
  }
};
