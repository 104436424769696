import styled from 'styled-components';
import {
  Card as RawCard,
  CardHeader as RawCardHeader,
  Checkbox as RawCheckbox,
  FlatLink,
  PrimaryButton,
  handleThemeFromObject as getTheme,
} from 'liber-components';

const TEXT_COLOR = '#23272f'; // TODO: get color from future theme

export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  color: ${TEXT_COLOR};

  a > svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
    margin-bottom: 2px;
  }

  a:hover > svg {
    fill: ${({ theme }) => getTheme(theme, 'colors.darkBlue', '#007bff')};
  }
`;

export const Card = styled(RawCard)`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    height: calc(100vh - 486px);
  }
  @media (min-width: 768px) {
    height: calc(100vh - 464px);
  }
  @media (min-width: 1200px) {
    height: calc(100vh - 420px);
  }

  min-height: 320px;
`;

export const CardHeader = styled(RawCardHeader)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.38;
  padding: 14px 32px;
  min-height: 77px;
  color: ${TEXT_COLOR};
`;

export const CardFooter = styled(CardHeader)`
  min-height: 86px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: solid 1px #e6e6e6; /* TODO: get color from future theme */

  label {
    margin-top: -12px;
  }

  @media (max-width: 767px) {
    label {
      margin-top: -34px;
    }
  }
  @media (max-width: 420px) {
    min-height: 112px;
    label {
      margin-top: -50px;
    }
  }
  @media (max-width: 335px) {
    min-height: 134px;
    label {
      margin-top: -72px;
    }
  }
`;

export const DownloadLink = styled(FlatLink).attrs(() => ({ size: 'large' }))``;

export const PdfObject = styled.object.attrs(() => ({ type: 'application/pdf' }))`
  width: 100%;
  height: 100vh;
`;

export const ErrorContainer = styled.div`
  padding: 16px;
`;

export const Checkbox = styled(RawCheckbox)`
  margin: 0px;
  & > span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.01px;
    color: ${TEXT_COLOR};
  }
  & > span:before {
    width: 16px;
    height: 16px;
    border-radius: 0px;
    margin-top: 2px;
  }
  & > span:after {
    top: 3px;
    left: 4px;
    width: 7px;
    height: 11px;
  }
`;

export const PullRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ size: 'large', version: 2 }))`
  text-transform: uppercase;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
