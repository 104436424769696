export const errorMsg = {
  invalidFormat: 'Formato inválido',
  empty: 'Esse campo não pode estar vazio',
  emptyPassword: 'Senha não pode ser vazia.',
  shortPassword: 'Senhas devem ter pelo menos 6 digitos',
  unconfirmedPassword: 'Senha e Confirmação de senha devem ser iguais.',
  tokenError: 'Token inválido ou expirado',
};

export const other = {};
