import axios from 'axios';
import {
  handleError,
  convertToCamelCase,
  handleSuccess,
  dispatchAmplitudeEvent,
  adjustDateFilters,
} from '../../../vendor/Utils';
import {
  ADMIN_NEGOTIATIONS_URL,
  ADMIN_OPEN_NEGOTIATIONS_URL,
  ADMIN_CLOSE_NEGOTIATIONS_URL,
  ADMIN_DOWNLOAD_DOCUMENTS_URL,
  ADMIN_NEGOTIATIONS_REPORT_URL,
  REPORT_ACTION_CABLE_CHANNEL,
  REPORT_ACTION_CABLE_ROOM,
  apiUrl,
  ADMIN_EXPORT_TERMS_URL,
  INVESTOR_ACCOUNT,
} from '../urls';
import {
  STATUS_EQ,
  MAP_STATUS_TO_TAB,
  MAP_TAB_TO_STATUS,
  isUrlKey,
  newUniqueId,
  getTabLabelByIndex,
  getCleanUrl,
  FiltersFormatter,
} from '../utils';
import { SORT_KEY } from '../../../hooks/useSorting';

export const SUBSCRIBE_ACTION_CABLE = 'SUBSCRIBE_ACTION_CABLE';
export const ADMIN_SET_LOADING = 'ADMIN_SET_LOADING';
export const UPDATE_ADMIN_NEGOTIATIONS = 'UPDATE_ADMIN_NEGOTIATIONS';
export const ADMIN_SET_FILTER = 'ADMIN_SET_FILTER';
export const ADMIN_CHANGE_PAGE = 'ADMIN_CHANGE_PAGE';
export const ADMIN_SET_LISTING = 'ADMIN_SET_LISTING';
export const ADMIN_TOGGLE_PAGE_SELECTION = 'ADMIN_TOGGLE_PAGE_SELECTION';
export const ADMIN_TOGGLE_ITEM_SELECTION = 'ADMIN_TOGGLE_ITEM_SELECTION';
export const ADMIN_CLEAR_SELECTION = 'ADMIN_CLEAR_SELECTION';
export const ADMIN_UPDATE_SELECTION = 'ADMIN_SELECT_ALL_NEGOTIATIONS';
export const ADMIN_UPDATE_IS_ALL_SELECTED = 'ADMIN_UPDATE_IS_ALL_SELECTED';
export const ADMIN_CHANGE_TAB = 'ADMIN_CHANGE_TAB';
export const ADMIN_REPORT_LOADING = 'ADMIN_REPORT_LOADING';
export const ADMIN_REPORT_RECEIVED = 'ADMIN_REPORT_RECEIVED';
export const ADMIN_EXPORT_TERMS_REQUESTED = 'ADMIN_EXPORT_TERMS_REQUESTED';
export const ADMIN_EXPORT_TERMS_SUCCESS = 'ADMIN_EXPORT_TERMS_SUCCESS';
export const ADMIN_EXPORT_TERMS_FAILED = 'ADMIN_EXPORT_TERMS_FAILED';
export const ADMIN_TRADES_SET_SORTING = 'ADMIN_TRADES_SET_SORTING';
export const UPDATE_INVESTOR_ACCOUNT = 'UPDATE_INVESTOR_ACCOUNT';

export function subscribeActionCable() {
  return (dispatch, getState) => {
    const {
      adminNegotiations: { adminId: id },
    } = getState();

    dispatch({
      type: SUBSCRIBE_ACTION_CABLE,
      channel: REPORT_ACTION_CABLE_CHANNEL,
      room: REPORT_ACTION_CABLE_ROOM + id,
    });
  };
}

export const updateNegotiations = negotiations => ({
  type: UPDATE_ADMIN_NEGOTIATIONS,
  payload: negotiations,
});

export const updateInvestorAccount = account => ({
  type: UPDATE_INVESTOR_ACCOUNT,
  payload: account,
});

export function fetchAdminNegotiations() {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_SET_LOADING, payload: true });

    const {
      adminNegotiations: {
        token,
        filters,
        pagination: { current: page, per },
        activeTab,
        sorting,
      },
    } = getState();

    const adjustableDateFields = ['q[canceled_at_lteq]'];

    const params = {
      page,
      per,
      [STATUS_EQ]: MAP_TAB_TO_STATUS[activeTab],
      ...adjustDateFilters(adjustableDateFields, filters),
      ...sorting,
    };

    return axios
      .get(ADMIN_NEGOTIATIONS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(response => {
        const { trades: negotiations, pagination } = convertToCamelCase(response.data);

        dispatch(
          updateNegotiations({
            negotiations,
            pagination: { ...pagination, per },
          }),
        );
      })
      .catch(() => {
        dispatch({ type: ADMIN_SET_LOADING, payload: false });
        handleError();
      });
  };
}

export const setPage = page => ({ type: ADMIN_CHANGE_PAGE, payload: { page } });

export const nextPage = () => (dispatch, getState) => {
  const {
    adminNegotiations: {
      pagination: { next },
    },
  } = getState();

  dispatch(setPage(next));
};

export const previousPage = () => (dispatch, getState) => {
  const {
    adminNegotiations: {
      pagination: { previous },
    },
  } = getState();

  dispatch(setPage(previous));
};

export const setListing = listing => ({
  type: ADMIN_SET_LISTING,
  payload: {
    per: parseInt(listing, 10),
  },
});

export const togglePageSelection = () => ({ type: ADMIN_TOGGLE_PAGE_SELECTION });

export const toggleItemSelection = selectedItem => ({
  type: ADMIN_TOGGLE_ITEM_SELECTION,
  payload: selectedItem,
});

export const resetSelection = () => ({ type: ADMIN_CLEAR_SELECTION });

export const selectAll = () => (dispatch, getState) => {
  const {
    adminNegotiations: { isPageSelected },
  } = getState();

  if (!isPageSelected) dispatch(togglePageSelection());
  dispatch({ type: ADMIN_UPDATE_IS_ALL_SELECTED, payload: true });
};

export const updateActiveTab = tabIndex => (dispatch, getState) => {
  const {
    adminNegotiations: { filters },
  } = getState();

  dispatch(setPage(1));
  dispatch({
    type: ADMIN_SET_FILTER,
    payload: {
      ...filters,
      [STATUS_EQ]: MAP_TAB_TO_STATUS[tabIndex],
    },
  });

  return dispatch({ type: ADMIN_CHANGE_TAB, payload: tabIndex });
};

export const updateFilters = filters => (dispatch, getState) => {
  const {
    adminNegotiations: { activeTab },
  } = getState();

  dispatch(resetSelection());

  const url = getCleanUrl();
  Object.keys(filters).forEach(key => {
    url.searchParams.set(key, filters[key]);
  });
  window.history.pushState({}, '', url);

  if (Object.keys(filters).includes(STATUS_EQ)) {
    const tabIndex = MAP_STATUS_TO_TAB[filters[STATUS_EQ]];
    if (activeTab !== tabIndex) {
      dispatch(updateActiveTab(tabIndex));
    }
  }

  return dispatch({
    type: ADMIN_SET_FILTER,
    payload: {
      ...filters,
    },
  });
};

export const clearFilters = () => (dispatch, getState) => {
  const {
    adminNegotiations: { filters },
  } = getState();

  const currentStatusEq = filters[STATUS_EQ];

  let url = getCleanUrl();
  if (currentStatusEq) {
    url += `?${STATUS_EQ}=${currentStatusEq}`;
  }
  window.history.pushState({}, '', url);

  dispatch({
    type: ADMIN_SET_FILTER,
    payload: currentStatusEq ? { [STATUS_EQ]: filters[STATUS_EQ] } : {},
  });

  dispatch(resetSelection());

  return dispatch(fetchAdminNegotiations());
};

export function openNegotiations() {
  return (dispatch, getState) => {
    const {
      adminNegotiations: { token, selectedItems, hasSelectedAllItems, filters },
    } = getState();

    const params = hasSelectedAllItems
      ? {
          all: true,
          q: FiltersFormatter(filters),
        }
      : { trades: selectedItems };

    return axios
      .post(ADMIN_OPEN_NEGOTIATIONS_URL, params, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        dispatch(resetSelection());
        dispatch(fetchAdminNegotiations());

        const { data } = response;
        const { status, message } = data || {};
        if (status) {
          handleSuccess('Sucesso!', message || 'A ação foi executada corretamente');
        }
      })
      .catch(error => {
        handleError('Um erro ocorreu', error.response.data.error.message);
      });
  };
}

export function cancelNegotiations() {
  return async (dispatch, getState) => {
    const {
      adminNegotiations: { token, selectedItems, hasSelectedAllItems, filters },
    } = getState();

    const params = hasSelectedAllItems
      ? {
          all: true,
          q: FiltersFormatter(filters),
        }
      : { trades: selectedItems };

    return axios
      .post(ADMIN_CLOSE_NEGOTIATIONS_URL, params, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        dispatch(resetSelection());
        dispatch(fetchAdminNegotiations());

        const { data } = response;
        const { status, message } = data || {};
        if (status) {
          handleSuccess('Sucesso!', message || 'A ação foi executada corretamente');
        }
      })
      .catch(error => {
        handleError('Um erro ocorreu', error.response.data.error.message);
      });
  };
}

export function downloadDocuments(terms, cnab, bankAccount, bankAccountId) {
  return async (dispatch, getState) => {
    const {
      adminNegotiations: { token, selectedItems, hasSelectedAllItems, filters },
    } = getState();

    const params = hasSelectedAllItems
      ? {
          all: true,
          q: FiltersFormatter(filters),
          terms,
          cnab,
          bank_account: bankAccount,
          bank_account_id: bankAccountId,
        }
      : {
          trades: selectedItems,
          terms,
          cnab,
          bank_account: bankAccount,
          bank_account_id: bankAccountId,
        };

    return axios
      .post(ADMIN_DOWNLOAD_DOCUMENTS_URL, params, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const { data } = response;
        const { status, message } = data || {};

        if (status) {
          handleSuccess('Sucesso!', message || 'Seu arquivo será baixado');
          Object.keys(data).forEach(key => {
            if (isUrlKey(key)) {
              const downloadUrl = data[key];
              window.open(downloadUrl);
            }
          });
        }
      })
      .catch(error => {
        handleError('Um erro ocorreu', error.response.data.error.message);
      });
  };
}

export const setDownloadReportLoading = (loading, reportId) => ({
  type: ADMIN_REPORT_LOADING,
  payload: { loading, reportId },
});

export function downloadReport() {
  return async (dispatch, getState) => {
    const uniqueId = newUniqueId();
    dispatch(setDownloadReportLoading(true, uniqueId));

    const {
      adminNegotiations: { token, filters },
    } = getState();

    const params = { ...filters, file_format: 'xlsx', request_id: uniqueId };

    return axios
      .get(ADMIN_NEGOTIATIONS_REPORT_URL, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      })
      .catch(() => {
        dispatch(setDownloadReportLoading(false));
        handleError();
      });
  };
}

export function requestNegotiationAction(id, url) {
  return async (dispatch, getState) => {
    const {
      adminNegotiations: { token },
    } = getState();

    const params = { trades: [id] };

    return axios
      .post(apiUrl + url, params, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const { data } = response;
        const { status, message } = data || {};
        if (status) {
          handleSuccess('Sucesso!', message || 'A ação foi executada corretamente');
        }
        dispatch(fetchAdminNegotiations());
      })
      .catch(() => handleError());
  };
}

export function downloadNegotiationAction(url) {
  return async (_, getState) => {
    const {
      adminNegotiations: { token },
    } = getState();

    return axios
      .get(apiUrl + url, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const { data } = response;
        const { status, message } = data || {};
        if (status) {
          handleSuccess('Sucesso!', message || 'Seu arquivo será baixado');
          Object.keys(data).forEach(key => {
            if (isUrlKey(key)) {
              const downloadUrl = data[key];
              window.open(downloadUrl);
            }
          });
        }
      })
      .catch(() => handleError());
  };
}

export function exportTerms() {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_EXPORT_TERMS_REQUESTED });

    const {
      adminNegotiations: { token, filters },
    } = getState();

    const params = { ...filters };

    return axios
      .get(ADMIN_EXPORT_TERMS_URL, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      })
      .then(response => {
        const { data } = response;
        const { message } = data || {};

        dispatch({ type: ADMIN_EXPORT_TERMS_SUCCESS });

        handleSuccess(
          'Sucesso!',
          message || 'Relatório será enviado por email assim que estiver pronto.',
        );
      })
      .catch(() => {
        dispatch({ type: ADMIN_EXPORT_TERMS_FAILED });
        handleError();
      });
  };
}

export function updateSorting(sorting = {}) {
  return async (dispatch, getState) => {
    const {
      adminNegotiations: { activeTab },
    } = getState();

    const [column, direction] = sorting[SORT_KEY]?.split(' ');
    const tab = getTabLabelByIndex(activeTab);

    dispatchAmplitudeEvent('admin_trades_sorting', { column, direction, tab });

    dispatch({
      type: ADMIN_TRADES_SET_SORTING,
      payload: { sorting },
    });
  };
}
export function showInvestorAccount(id) {
  return async (dispatch, getState) => {
    const {
      adminNegotiations: { token },
    } = getState();
    return axios
      .get(INVESTOR_ACCOUNT.replace(':investidor_id', id), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { data } = response;
        dispatch(updateInvestorAccount(data));
      })
      .catch(() => {
        handleError();
      });
  };
}
