import axios from 'axios';
import { convertToCamelCase, handleError } from '../../../vendor/Utils';
import { REPLICATE_VENDOR, FETCH_VENDOR_REPLICATE_PORTALS_OPTION } from '../urls';

export const SET_LOADING = 'ADMIN_SET_LOADING';
export const UPDATE_VENDOR_REPLICATE_PORTALS = 'UPDATE_VENDOR_REPLICATE_PORTALS';

export const setLoading = payload => ({ type: SET_LOADING, payload });

export const updateReplicateVendorPortals = payload => ({
  type: UPDATE_VENDOR_REPLICATE_PORTALS,
  payload,
});

export function fetchVendorOptions() {
  return async (dispatch, getState) => {
    const {
      replicateVendorReducer: { token, vendorId },
    } = getState();

    axios
      .get(FETCH_VENDOR_REPLICATE_PORTALS_OPTION.replace(':vendor_id', vendorId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { portals } = convertToCamelCase(response.data.data);

        dispatch(updateReplicateVendorPortals({ portals }));
      })
      .catch(() => {
        handleError('Um erro ocorreu', 'Não foi possível obter as opções para este fornecedor');
      });
  };
}

export function replicateVendor(rate, account, portal) {
  return async (dispatch, getState) => {
    const {
      replicateVendorReducer: { token, vendorId },
    } = getState();

    dispatch(setLoading(true));

    const params = {
      agreed_rate: rate,
      bank_account_needed: account,
      portal_id: portal,
    };

    return axios
      .post(REPLICATE_VENDOR.replace(':vendor_id', vendorId), params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { vendorUrl } = convertToCamelCase(response.data.data);
        window.location.assign(`${vendorUrl}?redirect=true`);
      })
      .catch(error => {
        const { message } = error.response?.data?.error;
        dispatch(setLoading(false));
        handleError('Um erro ocorreu', message || undefined);
      });
  };
}
