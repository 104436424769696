import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'liber-components';
import {
  PrimaryButton,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalFooter,
  Button,
} from './ConfirmationModal.styles';
import { exportTerms } from '../../actions/AdminNegotiationsActions';

export const ConfirmationModal = ({ show, onClose, onExportTerms }) => {
  const handleConfirm = () => {
    onClose();
    onExportTerms();
  };

  return (
    <Modal show={show} onLeaved={onClose}>
      <>
        <ModalHeader>
          <ModalTitle>Solicitar termos de cessão</ModalTitle>
        </ModalHeader>
        <ModalContent>
          Será criado um arquivo ZIP com todos os termos de cessão e enviaremos para seu e-mail.
          <br />
          <br />
          Tem certeza que deseja continuar?
        </ModalContent>
        <ModalFooter>
          <Button id="cancel-export-terms-button" onClick={onClose}>
            Cancelar
          </Button>
          <PrimaryButton id="confirm-export-terms-button" onClick={handleConfirm}>
            Confirmar
          </PrimaryButton>
        </ModalFooter>
      </>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onExportTerms: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  show: false,
  onClose: () => null,
  onExportTerms: () => null,
};

const mapDispatchToProps = {
  onExportTerms: exportTerms,
};

export default connect(null, mapDispatchToProps)(ConfirmationModal);
