import React, { useContext, useState } from 'react';

import Icon from '@mdi/react';
import { mdiPlus, mdiContacts, mdiHistory } from '@mdi/js';
import ScreenTitle from '../../../../common-components/ScreenTitle/ScreenTitle';
import { SpreadsheetRatesContext } from '../../context';
import { GlobalStyle, TitleRow, Button, TableHeader, Link } from './styles';
import { sendSpreadsheet } from '../../api';
import SpreadsheetRatesUploadModal from '../../../../common-components/SpreadsheetRatesUploadModal';
import SpreadsheetRatesTable from '../../../../common-components/SpreadsheetRatesTable';

function InvestorSpreadsheetRates() {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const { token, roomId, exampleFile } = useContext(SpreadsheetRatesContext);

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  return (
    <>
      <GlobalStyle />
      <TitleRow>
        <ScreenTitle>Painel de Taxas</ScreenTitle>
        <Link href="painel_taxas/grupos">
          <Icon path={mdiContacts} />
          Grupos de taxas
        </Link>
        <Link href="painel_taxas/historico">
          <Icon path={mdiHistory} />
          Histórico
        </Link>
        <Button onClick={handleOpenUploadModal}>
          <Icon path={mdiPlus} />
          Criar Nova Taxa
        </Button>
      </TitleRow>
      <TableHeader>Taxas Ativas</TableHeader>
      <SpreadsheetRatesTable token={token} activeRates />
      <SpreadsheetRatesUploadModal
        show={showUploadModal}
        onLeaved={() => setShowUploadModal(false)}
        sendSpreadsheet={sendSpreadsheet}
        roomId={roomId}
        token={token}
        exampleFile={exampleFile}
      />
    </>
  );
}

export default InvestorSpreadsheetRates;
