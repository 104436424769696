const patterns = {
  dashboard: [/^\/sacado\/dashboard\/{0,1}$/],
  reports: [/^\/sacado\/relatorios\/{0,1}$/],
  invoices: [
    /^\/sacado\/duplicatas\/{0,1}$/,
    /^\/sacado\/duplicatas\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/duplicatas\/[a-zA-Z0-9-]+\/edit\/{0,1}$/,
    /^\/sacado\/confirmacao_titulos\/{0,1}$/,
    // eslint-disable-next-line max-len
    /^\/sacado\/confirmacao_titulos\/(prepare_declaration|aguardando_continuacao_confirmacao|continue_declaration|new|liber_sign_direito_creditorio)\/{0,1}$/,
  ],
  allInvoices: [/^\/sacado\/duplicatas\/{0,1}$/],
  confirmationHistory: [/^\/sacado\/confirmacao_titulos\/{0,1}$/],
  trades: [/^\/sacado\/negociacoes\/{0,1}$/],
  vendors: [
    /^\/sacado\/fornecedores((\/(batch_upload|new)\/{0,1})|\/{0,1})$/,
    /^\/sacado\/fornecedores\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/fornecedores\/grupos\/{0,1}$/,
    /^\/sacado\/fornecedores\/grupos\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/fornecedores\/grupos\/[a-zA-Z0-9-]+\/historico\/{0,1}$/,
  ],
  limits: [/^\/sacado\/limites\/{0,1}$/],
  bankAccounts: [
    /^\/sacado\/conta_bancarias\/{0,1}$/,
    /^\/sacado\/conta_bancarias\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/conta_bancarias\/new\/{0,1}$/,
  ],
  addresses: [
    /^\/sacado\/enderecos\/{0,1}$/,
    /^\/sacado\/enderecos\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/enderecos\/new\/{0,1}$/,
  ],
  contract: [/^\/sacado\/contrato_mae\/{0,1}$/],
  userInformation: [/^\/sacados\/edit\/{0,1}$/],
  testDigitalSignature: [/^\/testar_assinatura_digital\/{0,1}$/],
};

export const routes = (paths, anticipationLimitsAvailable) => {
  const { dashboard, invoices, invoicesFiltered, history, trades, vendors, limits, reports } =
    paths;

  const sidebarRoutes = [
    {
      label: 'Dashboard',
      icon: 'Board',
      path: dashboard,
      routePatterns: patterns.dashboard,
    },
    {
      label: 'Títulos',
      icon: 'Receipt',
      path: invoices,
      routePatterns: patterns.invoices,
      subPaths: [
        {
          label: 'Todos os títulos',
          path: invoicesFiltered,
          routePatterns: patterns.allInvoices,
        },
        {
          label: 'Histórico de confirmações',
          path: history,
          routePatterns: patterns.confirmationHistory,
        },
      ],
    },
    {
      label: 'Negociações',
      icon: 'Money',
      path: trades,
      routePatterns: patterns.trades,
    },
    {
      label: 'Fornecedores',
      icon: 'BuildingShop',
      path: vendors,
      routePatterns: patterns.vendors,
    },
  ];

  if (anticipationLimitsAvailable) {
    sidebarRoutes.push({
      label: 'Configurar limites',
      icon: 'Options',
      path: limits,
      routePatterns: patterns.limits,
    });
  }

  sidebarRoutes.push({
    label: 'Relatórios exportados',
    icon: 'Table',
    path: reports,
    routePatterns: patterns.reports,
  });

  return sidebarRoutes;
};

export const userActions = (
  pathname,
  usesDigitalSignature,
  loggedAsOperator,
  userPaths,
  navigate,
) => {
  const {
    bankAccounts,
    addresses,
    contract,
    userInformation,
    testDigitalSignature,
    switchOrganization,
    signOut,
  } = userPaths;

  const actions = [
    { label: 'Contas bancárias', path: bankAccounts, routePatterns: patterns.bankAccounts },
    { label: 'Endereços', path: addresses, routePatterns: patterns.addresses },
    { label: 'Seu contrato', path: contract, routePatterns: patterns.contract },
    {
      label: 'Dados cadastrados',
      path: userInformation,
      routePatterns: patterns.userInformation,
    },
  ];

  if (usesDigitalSignature) {
    actions.push({
      label: 'Testar assinatura digital',
      path: testDigitalSignature,
      routePatterns: patterns.testDigitalSignature,
    });
  }

  if (loggedAsOperator) {
    actions.push({ label: 'Trocar de organização', path: switchOrganization });
  }

  actions.push({
    label: 'Sair',
    path: signOut,
    iconName: 'SignOut',
  });

  return actions.map(({ label, path, iconName = undefined, routePatterns = [] }) => {
    if (path === signOut) {
      return {
        href: path,
        component: 'a',
        label,
        iconName,
        divider: true,
        'data-method': 'delete',
      };
    }

    return {
      onClick: () => navigate(path, label),
      label,
      iconName,
      selected: routePatterns.some(pattern => pattern.test(pathname)),
    };
  });
};

export const SIDEBAR_EXPANDED_COOKIE = 'buyer_sidebar_expanded';
