import ReactOnRails from 'react-on-rails';
import { toast, errors } from 'liber-components';
import axios from 'axios';
import messages from '../messages';
import { initialState } from '../../reducers/bankAccount/investor/form';

const makeRequest = (type, data) => {
  switch (type) {
    case 'post':
      return axios({
        method: 'POST',
        url: '/investidor/conta_bancarias.json',
        data,
        responseType: 'json',
      });
    case 'patch':
      return axios({
        method: 'PATCH',
        url: `/investidor/conta_bancarias/${data.conta_bancaria.id}.json`,
        data,
        responseType: 'json',
      });
    case 'delete':
      return axios({
        method: 'DELETE',
        url: `/investidor/conta_bancarias/${data.id}.json`,
        data,
        responseType: 'json',
      });
    case 'default':
      return axios({
        method: 'POST',
        url: `/investidor/conta_bancarias/${data.conta_bancaria.id}/default.json`,
        data,
        responseType: 'json',
      });
    case 'zipCode':
      return axios({
        method: 'GET',
        url: '/busca_cep',
        params: { zipcode: data.replace('-', '') },
        responseType: 'json',
      });
    default:
      return null;
  }
};

export const hidrateForm = (bankAccount, index, userKind) => ({
  type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_HIDRATE_FORM',
  payload: {
    bankAccount,
    index,
    userKind,
  },
});

export const closeModalForm = () => ({
  type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_CLOSE_MODAL_FORM',
});

const fieldPayload = (field, value) => {
  if (field === 'kind') {
    return {
      bankAccount: {
        kind: value,
        savingsComplement: '',
      },
    };
  }
  return {
    bankAccount: { [field]: value },
  };
};

export const editField = (field, value) => ({
  type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_EDIT_FIELD',
  payload: fieldPayload(field, value),
});

export const bankAccountPresenter = payload => {
  const attribute = {
    default: payload.default,
    kind: payload.kind,
    agency: payload.agency,
    agencyDigit: payload.agency_digit,
    account: payload.account,
    accountDigit: payload.account_digit,
    company: payload.company,
    conjunct: payload.conjunct ? 'Sim' : 'Não',
    document: payload.document,
    bankCode: payload.bank_code,
    savingsComplement: payload.savings_complement,
    id: payload.id,
    contractNumber: payload.contract_number,
    wallet: payload.wallet,
    walletVariation: payload.wallet_variation,
    firstInstruction: payload.first_instruction,
    secondInstruction: payload.second_instruction,
    emitterBank: payload.emitter_bank,
    bankingNumber: payload.banking_number,
    finePercentage: payload.fine_percentage,
    arrearsPercentage: payload.arrears_percentage,
    daysToProtest: payload.days_to_protest,
  };
  if (payload.fine_percentage !== null && payload.fine_percentage !== undefined) {
    const finePercentage = parseInt(payload.fine_percentage, 10);
    attribute.finePercentage = `${finePercentage.toFixed(2)}%`.replace('.', ',');
  }
  if (payload.arrears_percentage !== null && payload.arrears_percentage !== undefined) {
    const arrearsPercentage = parseInt(payload.arrears_percentage, 10);
    attribute.arrearsPercentage = `${arrearsPercentage.toFixed(2)}%/mês`.replace('.', ',');
  }
  if (payload.days_to_protest !== null && payload.days_to_protest !== undefined) {
    attribute.daysToProtest = `${payload.days_to_protest} dias`;
  }

  return attribute;
};

export const validateBankAccount = (bankAccount, userKind) => {
  const merged = { ...initialState.bankAccount, ...bankAccount };
  const keys = Object.keys(merged);
  const validationErrors = {};
  const isValid = keys.reduce((before, key) => {
    if (
      key === 'company' ||
      key === 'document' ||
      key === 'id' ||
      key === 'agencyDigit' ||
      key === 'firstInstruction' ||
      key === 'secondInstruction' ||
      key === 'finePercentage' ||
      key === 'arrearsPercentage' ||
      key === 'daysToProtest' ||
      key === 'emitterBank' ||
      key === 'bankingNumber' ||
      (userKind === 'pf' && key === 'contractNumber') ||
      (userKind === 'pf' && key === 'wallet') ||
      (userKind === 'pf' && key === 'walletVariation')
    ) {
      return true && before;
    }
    if (key === 'wallet') {
      if (merged.wallet.length !== 2) {
        validationErrors[key] = messages.wallet;
        return false && before;
      }
    }
    if (key === 'walletVariation') {
      if (merged.walletVariation.length !== 3) {
        validationErrors[key] = messages.walletVariation;
        return false && before;
      }
    }
    if (key === 'savingsComplement') {
      const kind = bankAccount.kind ? bankAccount.kind.toLowerCase() : '';
      if (
        ['poupanca', 'poupança'].includes(kind) &&
        ['001', '104', '341'].includes(bankAccount.bankCode) &&
        bankAccount.savingsComplement.length === 0
      ) {
        validationErrors[key] = messages.empty;
        return false && before;
      }
      return true && before;
    }
    if (
      bankAccount[key] === null ||
      bankAccount[key] === undefined ||
      bankAccount[key].length === 0
    ) {
      validationErrors[key] = messages.empty;
      return false && before;
    }
    return true && before;
  }, true);
  return {
    validate: isValid,
    validationErrors,
  };
};

export const removeBankAccount = (id, index) => dispatch => {
  const csrfToken = ReactOnRails.authenticityToken();
  const request = makeRequest('delete', {
    id,
    authenticity_token: csrfToken,
  });
  if (request) {
    dispatch({
      type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_DELETE_PENDING',
    });
    return request
      .then(({ data }) => {
        const { message } = data.data;
        dispatch({
          type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_DELETE_SUCCESS',
          payload: index,
        });
        toast(message, 'success', 8000);
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch({
          type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_DELETE_REJECT',
        });
      });
  }
  return toast('Metodo não suportado', 'error', 8000);
};

export const setDefaultAccount = id => dispatch => {
  const obj = {
    conta_bancaria: {
      id,
    },
  };
  const csrfToken = ReactOnRails.authenticityToken();
  const request = makeRequest('default', {
    ...obj,
    authenticity_token: csrfToken,
  });
  if (request) {
    dispatch({
      type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SET_DEFAULT_PENDING',
    });
    return request
      .then(({ data }) => {
        const { message } = data.data;
        dispatch({
          type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SET_DEFAULT_SUCCESS',
          payload: id,
        });
        toast(message, 'success', 8000);
      })
      .catch(error => {
        const message = errors.http[{ ...error.response }.status || 500];
        toast(message, 'error', 8000);
        dispatch({
          type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SET_DEFAULT_REJECT',
        });
      });
  }
  return toast('Metodo não suportado', 'error', 8000);
};

export const submitForm =
  (bankAccount = {}, index, userKind) =>
  dispatch => {
    const { validate, validationErrors } = validateBankAccount(bankAccount, userKind);
    if (validate) {
      const obj = {
        conta_bancaria: {
          id: bankAccount.id || undefined,
          default: bankAccount.default,
          kind: bankAccount.kind ? bankAccount.kind.toLowerCase() : '',
          bank_code: bankAccount.bankCode,
          agency: bankAccount.agency,
          agency_digit: bankAccount.agencyDigit,
          account: bankAccount.account,
          account_digit: bankAccount.accountDigit,
          conjunct: bankAccount.conjunct === 'sim',
          savings_complement: bankAccount.savingsComplement,
          contract_number:
            bankAccount.contractNumber.length !== 0 ? bankAccount.contractNumber : undefined,
          wallet: bankAccount.wallet.length !== 0 ? bankAccount.wallet : undefined,
          wallet_variation:
            bankAccount.walletVariation.length !== 0 ? bankAccount.walletVariation : undefined,
        },
      };
      const csrfToken = ReactOnRails.authenticityToken();
      dispatch({
        type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SUBMIT_PENDING',
      });
      const request = makeRequest(bankAccount.id ? 'patch' : 'post', {
        ...obj,
        authenticity_token: csrfToken,
      });
      if (request) {
        return request
          .then(({ data }) => {
            const { message, ...payload } = data.data;
            dispatch({
              type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SUBMIT_SUCCESS',
              payload: {
                bankAccount: bankAccountPresenter(payload),
                index,
              },
            });
            toast(message, 'success', 8000);
          })
          .catch(error => {
            const message = errors.http[{ ...error.response }.status || 500];
            toast(message, 'error', 8000);
            dispatch({
              type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SUBMIT_REJECT',
            });
          });
      }
      return toast('Metodo não suportado', 'error', 8000);
    }
    return dispatch({
      type: 'MY_ACCOUNT_BANK_ACCOUNT_INVESTOR_SUBMIT_VALIDATION_ERROR',
      payload: validationErrors,
    });
  };
