import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { convertToCamelCase, handleError } from '../../../../../vendor/Utils';
import { fetchBuyerOrganizationGroup } from '../services/requests';

export const BuyerOrganizationGroupContext = createContext({});

export const BuyerOrganizationGroupProvider = ({ token, buyerOrganizationGroupId, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buyerGroup, setBuyerGroup] = useState({});

  const getBuyerOrganizationGroup = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchBuyerOrganizationGroup(token, buyerOrganizationGroupId);
      const parsedData = convertToCamelCase(data.data);

      setBuyerGroup(parsedData);
    } catch {
      handleError('Ocorreu um erro ao requisitar os grupos econômicos');
    } finally {
      setIsLoading(false);
    }
  }, [buyerOrganizationGroupId, token]);

  useEffect(() => {
    getBuyerOrganizationGroup();
  }, []);

  return (
    <BuyerOrganizationGroupContext.Provider
      value={{
        isLoading,
        buyerGroup,
      }}
    >
      {children}
    </BuyerOrganizationGroupContext.Provider>
  );
};

BuyerOrganizationGroupProvider.propTypes = {
  token: PropTypes.string.isRequired,
  buyerOrganizationGroupId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useBuyerOrganizationGroup = () => {
  const context = useContext(BuyerOrganizationGroupContext);

  return context;
};
