import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LiberV4 } from 'liber-components';
import ToastContainer from 'liber-components/components/Toast/components/ToastContainer';
import hydrateStore from '../store';
import AdminNegotiations from './components/AdminNegotiations';

const View = ({ store }) => (
  <>
    <Provider store={hydrateStore(store)}>
      <ThemeProvider theme={LiberV4}>
        <AdminNegotiations />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </>
);

View.propTypes = {
  store: PropTypes.shape({}).isRequired,
};

export default View;
