import axios from 'axios';
import { FETCH_RATES_URL, FETCH_VENDOR_GROUPS_URL } from './constants';

export const fetchRates = ({ token, funderIds, vendorsGroupId, financingDate }) =>
  axios.get(FETCH_RATES_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      funder_ids: funderIds,
      vendors_group_id: vendorsGroupId,
      financing_date: financingDate,
    },
  });

export const fetchVendorGroups = token =>
  axios.get(FETCH_VENDOR_GROUPS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      pagination: {
        per: '50',
      },
    },
  });
