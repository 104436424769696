import axios from 'axios';
import { VENDORS_LIMITS_URL } from './constants';

export async function fetchLimits(token = '', params = {}) {
  return axios.get(VENDORS_LIMITS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
}

export async function updateLimits(token = '', limit, id = {}) {
  return axios.post(
    VENDORS_LIMITS_URL,
    {
      anticipation_limit_value: {
        value: limit,
        buyer_organization_group_id: id,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
