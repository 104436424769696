import React, { useCallback } from 'react';
import { FlatButton } from 'liber-components';
import * as S from './styles';
import { TextFieldFilter } from '../../../../../../common-components/Filters';

import { useBuyerOrganizationGroups } from '../../context';
import { IFILTER_TYPES } from '../../interfaces';

export const Filters = () => {
  const { onChangeFilter, filters, clearFilter, clearFilters } = useBuyerOrganizationGroups();
  const updateFilter = key => value => {
    onChangeFilter(key, value);
  };

  const hasFilters = useCallback(
    () =>
      Object.keys(filters).reduce((flag, key) => {
        const hasValue = Boolean(filters[key]);

        if (hasValue && filters[key] instanceof Array) {
          return flag || filters[key].length > 0;
        }

        return flag || hasValue;
      }, false),
    [filters],
  );

  return (
    <S.SpaceBetween>
      <S.Flex>
        <S.Label>Filtrar por:</S.Label>
        <TextFieldFilter
          label="Grupo"
          data-testid="field-group-name"
          hintText="Nome do grupo econômico"
          selectedItems={filters[IFILTER_TYPES.GROUP_NAME]}
          onFilter={updateFilter(IFILTER_TYPES.GROUP_NAME)}
          onClear={() => clearFilter(IFILTER_TYPES.GROUP_NAME)}
        />
        <TextFieldFilter
          label="Sacado"
          data-testid="field-buyer-name"
          hintText="Nome do sacado"
          selectedItems={filters[IFILTER_TYPES.BUYER_NAME]}
          onFilter={updateFilter(IFILTER_TYPES.BUYER_NAME)}
          onClear={() => clearFilter(IFILTER_TYPES.BUYER_NAME)}
        />
        {hasFilters() && <FlatButton onClick={clearFilters}>Limpar Filtros</FlatButton>}
      </S.Flex>
    </S.SpaceBetween>
  );
};

export default Filters;
