import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { AnticipationPropTypes, AnticipationDefaultPropTypes } from '../../propTypes';
import { fixPercent } from '../../../../vendor/Utils';
import {
  Card,
  CardHeader,
  CardContent,
  Row,
  Info,
  Label,
  Value,
  SignedBy,
  SignedIcon,
  ContentCaption,
  VerticalDivider,
  Divider,
  StatusLabel,
  Spacer,
} from './styles';
import { actionsConfig, statusReference } from './constants';
import { CancelButton, InvoicesButton, SignaturesButton } from './buttons';
import CancellationDialog from '../../../CancellationDialog/CancellationDialog';
import SignatureDrawer from '../SignatureDrawer/SignatureDrawer';
import DetailsDrawer from '../DetailsDrawer/DetailsDrawer';
import { formatTimeCut } from '../../utils';
import { CANCEL_PAGE_URL, SOLICITATION_CANCEL_URL } from '../../urls';

export const PendingAdvanceCard = ({ testId, advance, isAdmin, timeCut, vendor, operator }) => {
  const {
    id,
    invoiceCount,
    mappedStatus,
    netValue,
    averageRate,
    signatures: { required, done },
    userHasSigned,
  } = advance;

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [signatureDrawerOpen, setSignatureDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);

  const { disabled, caption, showUnavailableSignatures, showCancelReason } = useMemo(
    () => actionsConfig({ status: mappedStatus, timeCut: formatTimeCut(timeCut) }),
    [mappedStatus, timeCut],
  );

  const handleAdvanceShowClick = () => {
    setDetailsDrawerOpen(true);
  };

  const handleRedirectToCancel = () => {
    if (!id) {
      window.location.assign(SOLICITATION_CANCEL_URL);
      return;
    }
    window.location.assign(`${CANCEL_PAGE_URL}?id=${id}`);
  };

  return (
    <>
      <Card data-testid={testId}>
        <CardHeader>{id ? `Antecipação ${id}` : 'Solicitação'}</CardHeader>
        <CardContent>
          <Row mb={16}>
            <Info>
              <Label>Títulos</Label>
              <Value>
                {invoiceCount}
                <InvoicesButton onClick={handleAdvanceShowClick} />
              </Value>
            </Info>
            <VerticalDivider />
            <Info>
              <Label>Taxa média</Label>
              <Value>
                {fixPercent(averageRate)}
                <Label ml={2}>%</Label>
              </Value>
            </Info>
            <VerticalDivider />
            <Info>
              <Label>Valor líquido</Label>
              <Value>
                <Label mr={4}>R$</Label>
                {netValue?.replace('R$ ', '')}
              </Value>
            </Info>
          </Row>
          {showCancelReason ? (
            <Row mb={4}>
              <Label>Motivo do cancelamento:</Label>
            </Row>
          ) : (
            id && (
              <Row mb={4}>
                <Label>Assinaturas:</Label>
                <Value ml={8} mr={4}>
                  {showUnavailableSignatures ? 'Indisponível' : `${done} de ${required}`}
                </Value>
                {!showUnavailableSignatures && (
                  <>
                    <SignaturesButton
                      data-testid="signatures-button-1"
                      ml={4}
                      disabled={disabled.signatures}
                      onClick={() => setSignatureDrawerOpen(true)}
                    />
                    {userHasSigned && (
                      <SignedBy ml={8}>
                        Assinado por você
                        <SignedIcon ml={8} />
                      </SignedBy>
                    )}
                  </>
                )}
              </Row>
            )
          )}
          {caption && <ContentCaption>{caption}</ContentCaption>}
          <Divider />
          <Row>
            <StatusLabel status={mappedStatus}>{statusReference[mappedStatus]}</StatusLabel>
            <Spacer />
            <InvoicesButton onClick={handleAdvanceShowClick} />
            {id && (
              <>
                <SignaturesButton
                  data-testid="signatures-button-2"
                  disabled={disabled.signatures}
                  onClick={() => {
                    setSignatureDrawerOpen(true);
                  }}
                />
              </>
            )}
            <CancelButton
              data-testid="cancel-button"
              disabled={disabled.cancel}
              onClick={() => {
                setCancelDialogOpen(true);
              }}
            />
          </Row>
        </CardContent>
      </Card>
      <CancellationDialog
        advanceId={id}
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        onConfirm={handleRedirectToCancel}
      />
      <SignatureDrawer
        advance={advance}
        open={signatureDrawerOpen}
        onClose={() => setSignatureDrawerOpen(false)}
        isAdmin={isAdmin}
        operator={operator}
        vendor={vendor}
      />
      <DetailsDrawer
        advance={advance}
        open={detailsDrawerOpen}
        onClose={() => setDetailsDrawerOpen(false)}
      />
    </>
  );
};

PendingAdvanceCard.propTypes = {
  advance: AnticipationPropTypes,
  isAdmin: PropTypes.bool,
  timeCut: PropTypes.string,
  testId: PropTypes.string,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

PendingAdvanceCard.defaultProps = {
  advance: AnticipationDefaultPropTypes,
  isAdmin: false,
  timeCut: null,
  testId: '',
  vendor: {},
  operator: {},
};

export default PendingAdvanceCard;
