import { connect } from 'react-redux';
import Confirmation from './Confirmation';
import { resendEmail } from '../../actions/confirmation';

const mapStateToProps = state => ({
  fetched: state.confirmation.fetched,
  isFetching: state.confirmation.isFetching,
  success: state.confirmation.success,
});

const mapDispatchToProps = { resendEmail };

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
