import React, { Suspense, useContext, useState } from 'react';
import BatchBlockUnblockInfo from '../ModalHeader/BatchBlockUnblockInfo';
import { ModalContext } from '../../context';
import { REASONS, BUTTON_LABEL, MESSAGES } from '../../constants';

import Loading from '../Loading';

import {
  Wrapper,
  BlockOptions,
  BlockOptionsTitle,
  Radio,
  Input,
  ButtonsRow,
  Button,
  PrimaryButton,
} from '../../styles';

const InfoViews = {
  funder: {
    block: React.lazy(() => import('../ModalHeader/FunderBlockInfo')),
    unblock: React.lazy(() => import('../ModalHeader/FunderUnblockInfo')),
  },
  buyer: {
    block: React.lazy(() => import('../ModalHeader/BuyerBlockInfo')),
    unblock: React.lazy(() => import('../ModalHeader/BuyerUnblockInfo')),
  },
};

const Content = () => {
  const { action, kind, onCancel, onConfirm, isLoading, isBatch } = useContext(ModalContext);
  const [selectedReason, setSelectedReason] = useState(null);
  const [enableInput, setEnableInput] = useState(false);

  const InfoSpecificView = InfoViews[kind][action];

  return (
    <Wrapper>
      <Suspense fallback={<Loading />}>
        {isBatch ? <BatchBlockUnblockInfo /> : <InfoSpecificView />}
      </Suspense>
      <BlockOptions>
        <BlockOptionsTitle>{MESSAGES.blockOptionsTitle[action]}</BlockOptionsTitle>
        {REASONS[action]?.map(reason => (
          <Radio
            value={reason}
            checked={selectedReason === reason}
            onChange={event => setSelectedReason(event.target.value)}
            id={reason}
            key={`reason-${reason}`}
            name="reason"
          >
            {reason}
          </Radio>
        ))}
        <Radio name="reason" onChange={() => setEnableInput(current => !current)}>
          Outro
        </Radio>
        <Input
          name="reason"
          label="Motivo"
          onChange={setSelectedReason}
          value={selectedReason}
          disabled={!enableInput}
        />
      </BlockOptions>
      <ButtonsRow>
        <Button onClick={onCancel}>Cancelar</Button>
        <PrimaryButton
          loading={isLoading}
          onClick={() => onConfirm(selectedReason)}
          disabled={!selectedReason}
        >
          {BUTTON_LABEL[action]}
        </PrimaryButton>
      </ButtonsRow>
    </Wrapper>
  );
};

export default Content;
