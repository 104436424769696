import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { SelectField, ModalDangerButton, SuccessButton, Checkbox } from 'liber-components';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { showFixedBox, hideFixedBox } from '../../actions/summaryActions';
import {
  showConfirmation,
  funderSelection,
  funderUpdate,
  funderRemove,
  updateSkipOperations,
} from '../../actions/proposalAction';
import {
  SummaryContainer,
  FunderSelection,
  Values,
  Info,
  ButtonContainer,
  CircleNotice,
  TriangleNotice,
  LoadingValue,
  ContainerChecked,
} from './Summary.styles';
import { TooltipContentCustom } from '../Dialogs/Dialogs.styles.jsx';

const mappedOptions = funder => (
  <option value={funder.id} key={funder.id}>
    {funder.name}
  </option>
);

export class Summary extends PureComponent {
  handleFunderSelection = value => {
    const { selectFunder, updateFunder, removeFunder, funder } = this.props;
    if (funder === '' && value !== '') {
      selectFunder(value);
    } else if (funder !== '' && value !== '' && funder !== value) {
      updateFunder(funder, value);
    } else if (funder !== '' && value === '') {
      removeFunder(funder);
    }
  };

  handleCancel = () => {
    const { removeFunder, funder } = this.props;
    removeFunder(funder);
  };

  handleCheck = () => {
    const { operationsUpdateSkip } = this.props;
    operationsUpdateSkip(!this.props.skipAcceptance);
  };

  render() {
    const { funder, funders, showFixed, hideFixed, summary, openConfirmation } = this.props;
    const { amount, count, taxed, negotiations, loadingCount } = summary;
    const loading = loadingCount > 0;
    let info;
    if (funder === '') {
      info = (
        <Info>
          <CircleNotice />
          Selecione um investidor para poder realizar múltiplas propostas.
        </Info>
      );
    } else if (taxed) {
      info = (
        <Info selected>
          <TriangleNotice />
          Ao realizar esta proposta, o investidor estará sujeito ao pagamento do DARF.
        </Info>
      );
    }

    return ReactDOM.createPortal(
      <Waypoint onEnter={hideFixed} onLeave={showFixed}>
        <SummaryContainer>
          <FunderSelection>
            <SelectField
              label="Nome do investidor"
              searchable
              width="350px"
              labelOpened="Selecionar Investidor"
              value={funder}
              onChange={this.handleFunderSelection}
            >
              {funders.map(mappedOptions)}
            </SelectField>
            <Values selected={funder !== ''} loading={loading}>
              Títulos:
              {loading ? <LoadingValue /> : <span>{funder !== '' ? count : 0}</span>}
            </Values>
            <Values selected={funder !== ''} loading={loading}>
              Valor Total:
              {loading ? <LoadingValue /> : <span>{funder !== '' ? amount : 'R$0,00'}</span>}
            </Values>
          </FunderSelection>
          <ContainerChecked>
            <Checkbox checked={this.props.skipAcceptance} onChange={this.handleCheck}>
              <TooltipContentCustom>
                Fazer propostas com aceite automático do fornecedor
              </TooltipContentCustom>
            </Checkbox>
            {info}
          </ContainerChecked>
          <ButtonContainer>
            <ModalDangerButton
              disabled={funder === ''}
              onClick={funder !== '' ? this.handleCancel : undefined}
            >
              Cancelar
            </ModalDangerButton>
            <SuccessButton
              disabled={funder === '' || negotiations.length === 0 || loading}
              onClick={funder !== '' && negotiations.length !== 0 ? openConfirmation : undefined}
            >
              Fazer Propostas
            </SuccessButton>
          </ButtonContainer>
        </SummaryContainer>
      </Waypoint>,
      document.getElementById('summary-funder-selection'),
    );
  }
}
Summary.propTypes = {
  funder: PropTypes.string.isRequired,
  skipAcceptance: PropTypes.bool.isRequired,
  summary: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string,
    taxed: PropTypes.bool,
    loadingCount: PropTypes.number,
    negotiations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        slug: PropTypes.string,
        monthly_profitability: PropTypes.string,
        advance: PropTypes.string,
        deadline: PropTypes.string,
        vendor: PropTypes.string,
        payer: PropTypes.string,
        actions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        number: PropTypes.string,
        amount: PropTypes.string,
        rate: PropTypes.string,
        expires_at: PropTypes.string,
      }),
    ),
  }).isRequired,
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.name,
    }),
  ).isRequired,
  selectFunder: PropTypes.func,
  updateFunder: PropTypes.func,
  removeFunder: PropTypes.func,
  openConfirmation: PropTypes.func,
  showFixed: PropTypes.func,
  hideFixed: PropTypes.func,
  operationsUpdateSkip: PropTypes.func,
};

Summary.defaultProps = {
  selectFunder: () => {},
  updateFunder: () => {},
  removeFunder: () => {},
  openConfirmation: () => {},
  showFixed: () => {},
  hideFixed: () => {},
  operationsUpdateSkip: () => {},
};

const mapStateToProps = state => ({
  ...state.multiProposal.summary,
});
const mapDispatchToProps = {
  showFixed: showFixedBox,
  hideFixed: hideFixedBox,
  selectFunder: funderSelection,
  updateFunder: funderUpdate,
  removeFunder: funderRemove,
  openConfirmation: showConfirmation,
  operationsUpdateSkip: updateSkipOperations,
};
export default connect(mapStateToProps, mapDispatchToProps)(Summary);
