import React from 'react';
import { Modal } from 'liber-components';
import moment from 'moment';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { mdiTrashCan } from '@mdi/js';
import * as S from './styles';
import { handleError, handleSuccess } from '../../../../vendor/Utils';
import { deleteScheduled } from '../../api';
import { SchedulesContext } from '../../context';
import { STATUS } from '../../constants';

const AnticipationsTableActions = ({ scheduled, token }) => {
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const { fetchSchedules } = React.useContext(SchedulesContext);

  const removeScheduled = async () => {
    setLoading(true);
    setModal(false);
    try {
      await deleteScheduled(token, {
        ids: [scheduled.id],
      });
      handleSuccess('Sucesso', `Registro removido com sucesso`);
      await fetchSchedules();
    } catch (error) {
      handleError('Ocorreu um erro ao remover o registro');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <S.Button disabled={loading} type="button" onClick={() => setModal(true)}>
        <Icon path={mdiTrashCan} size={0.8} />
      </S.Button>
      <Modal show={modal} onLeaved={() => setModal(false)}>
        <S.ModalContainer>
          <S.ModalTitle>Tem certeza que deseja remover o agendamento abaixo?</S.ModalTitle>
          <S.Info>
            <S.InfoItem>
              <S.InfoItemTitle>Título:</S.InfoItemTitle>
              <S.InfoItemContent>
                Envio de fornecedores de {scheduled.sender} para {scheduled.receipt}
              </S.InfoItemContent>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Agendado para:</S.InfoItemTitle>
              <S.InfoItemContent>
                {moment(scheduled.scheduled_for).format('DD/MM/YYYY')}
              </S.InfoItemContent>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Status:</S.InfoItemTitle>
              <S.InfoItemContent>{STATUS[scheduled.status]}</S.InfoItemContent>
            </S.InfoItem>
          </S.Info>
          <S.ModalActions>
            <S.FooterButton outlined onClick={() => setModal(false)}>
              Cancelar
            </S.FooterButton>
            <S.FooterButton onClick={() => removeScheduled()}>Confirmar</S.FooterButton>
          </S.ModalActions>
        </S.ModalContainer>
      </Modal>
    </>
  );
};

AnticipationsTableActions.propTypes = {
  scheduled: PropTypes.objectOf(PropTypes.any).isRequired,
  token: PropTypes.string.isRequired,
};

export default AnticipationsTableActions;
