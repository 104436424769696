import Immutable from 'seamless-immutable';

const initialState = Immutable({
  open: 0,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SUM_OPEN_ACCORDIONS':
      return state.update('open', open => open + 1);
    case 'DEC_OPEN_ACCORDIONS':
      return state.update('open', open => open - 1);
    default:
      return state;
  }
};
