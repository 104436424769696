import React from 'react';
import PropTypes from 'prop-types';
import LoadingBunny from 'liber-components/components/LoadingBunny';
import * as S from './styles';

const Loading = ({ isLoading, children }) => (
  <S.LoadingContainer isLoading={isLoading}>
    {isLoading && (
      <S.Bunny aria-label="loading-bunny">
        <LoadingBunny loop />
      </S.Bunny>
    )}
    {children}
  </S.LoadingContainer>
);

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Loading;
