import axios from 'axios';
import {
  GET_VENDOR_URL,
  VENDOR_ANTICIPATION_SHOW_URL,
  VENDOR_ANTICIPATION_INVOICES_URL,
  ADMIN_VENDOR_ANTICIPATION_SHOW_URL,
  ADMIN_VENDOR_BORDERO_INVOICES_URL,
  apiUrl,
} from '../urls';
import { convertToCamelCase, handleError } from '../../../vendor/Utils';
import { formatFilters } from '../utils';

export const VENDOR_ANTICIPATION_UPDATE_SUMMARY = 'VENDOR_ANTICIPATION_UPDATE_SUMMARY';
export const VENDOR_BORDERO_UPDATE_INVOICES = 'VENDOR_BORDERO_UPDATE_INVOICES';
export const VENDOR_BORDERO_INVOICES_LOADING = 'VENDOR_BORDERO_INVOICES_LOADING';
export const VENDOR_BORDERO_UPDATE_FILTERS = 'VENDOR_BORDERO_UPDATE_FILTERS';
export const VENDOR_BORDERO_CHANGE_PAGE = 'VENDOR_BORDERO_CHANGE_PAGE';
export const VENDOR_BORDERO_SET_LISTING = 'VENDOR_BORDERO_SET_LISTING';
export const VENDOR_FETCH = 'VENDOR_FETCH';
export const VENDOR_FETCH_SUCCESS = 'VENDOR_FETCH_SUCCESS';

const getBorderoShowUrl = (isAdmin, fornecedorId, anticipationId) =>
  isAdmin
    ? ADMIN_VENDOR_ANTICIPATION_SHOW_URL.replace(':id', fornecedorId).replace(
        ':anticipation_id',
        anticipationId,
      )
    : VENDOR_ANTICIPATION_SHOW_URL.replace(':id', anticipationId);

const getBorderoInvoicesUrl = (isAdmin, fornecedorId, anticipationId) =>
  isAdmin
    ? ADMIN_VENDOR_BORDERO_INVOICES_URL.replace(':id', fornecedorId).replace(
        ':anticipation_id',
        anticipationId,
      )
    : VENDOR_ANTICIPATION_INVOICES_URL.replace(':anticipation_id', anticipationId);

export const updateSummary = data => ({ type: VENDOR_ANTICIPATION_UPDATE_SUMMARY, payload: data });

export function fetchSummary() {
  return (dispatch, getState) => {
    const {
      vendorBorderoShow: { token, anticipationId, isAdmin, isSolicitation, fornecedorId },
    } = getState();

    const url = isSolicitation
      ? `${apiUrl}/api/v1/fornecedor/solicitations/closed${window.location.search}`
      : getBorderoShowUrl(isAdmin, fornecedorId, anticipationId);

    return axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(payload => {
        const formattedData = convertToCamelCase(payload.data.data);
        dispatch(updateSummary(formattedData));
      })
      .catch(e => console.log(e));
  };
}

export const updateInvoices = data => ({ type: VENDOR_BORDERO_UPDATE_INVOICES, payload: data });

export const setInvoicesLoading = loading => ({
  type: VENDOR_BORDERO_INVOICES_LOADING,
  payload: loading,
});

export function updateVendor(vendor) {
  return {
    type: VENDOR_FETCH_SUCCESS,
    payload: {
      vendor,
    },
  };
}

export function fetchVendor() {
  return (dispatch, getState) => {
    dispatch({
      type: VENDOR_FETCH,
    });

    const {
      vendorBorderoShow: { token },
    } = getState();

    return axios
      .get(GET_VENDOR_URL, { headers: { Authorization: `Bearer ${token}` } })
      .then(payload => {
        const {
          data: { data },
        } = payload;

        dispatch(updateVendor(convertToCamelCase(data)));
      })
      .catch(error => dispatch(handleError(error)));
  };
}

export function fetchInvoices() {
  return (dispatch, getState) => {
    dispatch(setInvoicesLoading(true));

    const {
      vendorBorderoShow: {
        token,
        filters,
        pagination: { current: page, per },
        anticipationId,
        isAdmin,
        isSolicitation,
        fornecedorId,
      },
    } = getState();

    const params = { page, per, ...formatFilters(filters) };

    const url = isSolicitation
      ? `${apiUrl}/api/v1/fornecedor/solicitations/invoices${window.location.search}`
      : getBorderoInvoicesUrl(isAdmin, fornecedorId, anticipationId);

    return axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then(payload => {
        const formattedData = convertToCamelCase(payload.data.data);
        dispatch(updateInvoices(formattedData));
      })
      .catch(e => console.log(e));
  };
}

export const updateFilters = filters => ({
  type: VENDOR_BORDERO_UPDATE_FILTERS,
  payload: { filters },
});

export const setPage = page => ({ type: VENDOR_BORDERO_CHANGE_PAGE, payload: { page } });

export const nextPage = () => (dispatch, getState) => {
  const {
    vendorBorderoShow: {
      pagination: { next },
    },
  } = getState();

  dispatch(setPage(next));
};

export const previousPage = () => (dispatch, getState) => {
  const {
    vendorBorderoShow: {
      pagination: { previous },
    },
  } = getState();

  dispatch(setPage(previous));
};

export const setListing = listing => ({
  type: VENDOR_BORDERO_SET_LISTING,
  payload: {
    per: parseInt(listing, 10),
  },
});
