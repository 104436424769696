import React, { useState, useMemo, useRef } from 'react';
import { Card, Typography, TextField, Button, Alert } from 'liber-salti';
import ReactOnRails from 'react-on-rails';
import PropTypes from 'prop-types';

import tsscmp from 'tsscmp';
import LoginFooter from '../../../../common-components/LoginFooter';
import SaltiThemeWrapper from '../../../../common-components/SaltiThemeWrapper';

import { FUNDER_SUBMIT_PASSWORD_TOKEN_PATH, FUNDER_PASSWORD_PATH } from '../../paths';

import {
  Container,
  Content,
  CardContent,
  Logo,
  LogoWrapper,
  ActionsWrapper,
  InputsWrapper,
  HiddenForm,
  DescriptionWrapper,
} from '../../styles';

const FunderResetPassword = ({ token }) => {
  const formRef = useRef();

  const csrfToken = ReactOnRails.authenticityToken();

  const [tokenInput, setTokenInput] = useState('');

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const errorMessage = useMemo(() => {
    if (passwordConfirmation?.length > 0) {
      if (password?.length < 6 || passwordConfirmation?.length < 6) {
        return 'A senha deve ter no mínimo 6 caracteres.';
      }

      const passwordBuffer = Buffer.from(password, 'utf-8');
      const confirmationBuffer = Buffer.from(passwordConfirmation, 'utf-8');

      if (
        passwordBuffer.length !== confirmationBuffer.length ||
        !tsscmp(passwordBuffer.toString(), confirmationBuffer.toString())
      ) {
        return 'Verifique se as senhas conferem.';
      }
    }
    return undefined;
  }, [password, passwordConfirmation]);

  const confirmButtonDisabled = useMemo(
    () => Boolean(errorMessage) || password.length === 0 || passwordConfirmation.length === 0,
    [errorMessage, password, passwordConfirmation],
  );

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const submitToken = () => {
    window.location.assign(`${FUNDER_SUBMIT_PASSWORD_TOKEN_PATH}${tokenInput}`);
  };

  return (
    <Container>
      <Content>
        <Card>
          <CardContent>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography variant="h2" color="textPrimary">
              Atualizar senha
            </Typography>
            {token ? (
              <>
                <DescriptionWrapper>
                  <Typography variant="body1" color="textSecondary">
                    Crie uma nova senha.
                  </Typography>
                </DescriptionWrapper>
                <InputsWrapper>
                  <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    label="Criar nova senha"
                    required
                    fullWidth
                    type="password"
                    error={Boolean(errorMessage)}
                    data-testid="PasswordInput"
                  />
                  <TextField
                    value={passwordConfirmation}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    label="Confirmar senha"
                    required
                    fullWidth
                    type="password"
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                    data-testid="PasswordConfirmationInput"
                  />
                </InputsWrapper>
                <ActionsWrapper>
                  <Button
                    onClick={() => handleSubmit()}
                    disabled={confirmButtonDisabled}
                    size="large"
                    fullWidth
                    data-testid="SubmitButton"
                  >
                    RECUPERAR SENHA
                  </Button>
                </ActionsWrapper>
              </>
            ) : (
              <>
                <DescriptionWrapper>
                  <Alert
                    severity="error"
                    description={`Não foi possível recuperar o token de confirmação. 
                    Por favor, insira o token que enviamos por email.`}
                  />
                </DescriptionWrapper>
                <InputsWrapper>
                  <TextField
                    value={tokenInput}
                    onChange={e => setTokenInput(e.target.value)}
                    label="Token"
                    required
                    fullWidth
                    data-testid="TokenInput"
                  />
                </InputsWrapper>
                <ActionsWrapper>
                  <Button
                    onClick={() => submitToken()}
                    disabled={tokenInput.length === 0}
                    size="large"
                    fullWidth
                    data-testid="TokenSubmitButton"
                  >
                    CONFIRMAR
                  </Button>
                </ActionsWrapper>
              </>
            )}
          </CardContent>
        </Card>
      </Content>
      <LoginFooter />
      <HiddenForm data-testid="Form" ref={formRef} method="POST" action={FUNDER_PASSWORD_PATH}>
        <input value="put" name="_method" readOnly />
        <input value={passwordConfirmation} name="investidor[password_confirmation]" readOnly />
        <input value={password} name="investidor[password]" readOnly />
        <input value={token} name="investidor[reset_password_token]" readOnly />
        <input value={csrfToken} name="authenticity_token" readOnly />
        <input type="submit" />
      </HiddenForm>
    </Container>
  );
};

const View = ({ token }) => (
  <SaltiThemeWrapper>
    <FunderResetPassword token={token} />
  </SaltiThemeWrapper>
);

FunderResetPassword.propTypes = {
  token: PropTypes.string,
};

FunderResetPassword.defaultProps = {
  token: '',
};

View.propTypes = {
  token: PropTypes.string,
};

View.defaultProps = {
  token: '',
};

export default View;
