import styled from 'styled-components';
import { FlatButton, PrimaryButton } from 'liber-components';

export const Container = styled.div`
  padding: 24px 24px 12px 24px;
  width: 480px;

  * {
    font-family: Roboto;
  }
`;

export const Header = styled.div`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08px;
  color: #6287a7;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  margin-bottom: 24px;
  color: #6287a7;
  margin-bottom: 24px;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

export const ButtonCancel = styled(FlatButton).attrs(() => ({ version: 2, size: 'large' }))`
  margin-right: 8px;
`;
export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))``;
