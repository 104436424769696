import styled from 'styled-components';
import { PrimaryButton, LoadingBunny } from 'liber-components';

export const Container = styled.div`
  padding: 16px;
  width: 760px;
  max-width: 100vw;
  margin-left: auto;
  font-family: 'Roboto';
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DialogContent = styled.div`
  margin: 20px 0 16px 0;
  position: relative;
  overflow-y: auto;
  flex: auto;
`;

export const DialogFooter = styled.div`
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 16px;
  width: fit-content;
`;

export const Title = styled.div`
  color: #405f71;
  font-size: 28px;
  font-weight: 700;
  margin: auto 0;
`;

export const IconButton = styled.div`
  padding: 8px;
  color: #506286;
  & > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 50%;
`;

export const CloseButton = styled(PrimaryButton).attrs({
  version: '2',
  size: 'large',
  outlined: true,
})``;

export const DownloadButton = styled(PrimaryButton).attrs({
  version: '2',
  size: 'large',
})``;

export const BodyText = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
  white-space: nowrap;
`;

export const BodyTextBlue = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #009dff;
  white-space: nowrap;
`;

export const CaptionText = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #6287a7;
  margin-bottom: 8px;
  white-space: nowrap;
`;

export const DetailsContainer = styled.div`
  margin: 20px 0;
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  margin-top: 8px;
  gap: 16px;
`;

export const BottomDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin-top: 8px;
  gap: 32px;
  width: fit-content;
`;

export const Loading = styled(LoadingBunny)`
  width: 50%;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  z-index: 300;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffaa;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(64, 95, 113, 0.08);
  border-radius: 4px;
  margin-left: 8px;

  & > svg {
    width: 16px;
    height: 16px;
    fill: #2b354a;
  }
`;

export const TooltipLabel = styled.div`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #b6c2c9;
  margin-bottom: 8px;
`;

export const TooltipBottomGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-top: 8px;
  gap: 16px;
`;

export const TableWrapper = styled.div`
  min-width: 728px;
`;
