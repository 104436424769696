import ActionCable from 'actioncable';
import { INVOICES_ACTION_CABLE_URL } from '../urls';

export default function cableMiddleware() {
  const cable = ActionCable.createConsumer(INVOICES_ACTION_CABLE_URL);
  return ({ dispatch }) =>
    next =>
    action => {
      if (typeof action === 'function') {
        return next(action);
      }

      const { channel, room, roomId } = action;
      let { received } = action;

      if (!channel) {
        return next(action);
      }

      if (typeof received === 'string') {
        received = result => dispatch({ type: received, result });
      }
      return cable.subscriptions.create(
        { channel, room, room_id: roomId },
        {
          received: data => {
            const { type } = data;
            dispatch({
              type,
              payload: data,
            });
          },
          connected: () => {
            dispatch({
              type: 'CONNECTED_SUBSCRIPTION',
              payload: {
                room,
              },
            });
          },
        },
      );
    };
}
