import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import hydrateStore from '../store';
import Info from './components/Info';

const View = ({ store }) => (
  <Provider store={hydrateStore(store)}>
    <Info />
  </Provider>
);

View.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
