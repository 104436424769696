import { connect } from 'react-redux';
import InvestidorSignUp from './InvestidorSignUp';
import {
  changeType,
  changeEmail,
  changeFullName,
  changeCompanyName,
  changeTradeName,
  changeCpf,
  changeCnpj,
  changePhone,
  changeRamal,
  changeCellphone,
  changePassword,
  changeConfirmation,
  submitForm,
  setReCaptchaToken,
} from '../../actions/investidorSignUpActions';

const mapStateToProps = state => ({
  type: state.investidorSignUp.type,
  email: state.investidorSignUp.email,
  fullName: state.investidorSignUp.fullName,
  companyName: state.investidorSignUp.companyName,
  tradeName: state.investidorSignUp.tradeName,
  cpf: state.investidorSignUp.cpf,
  cnpj: state.investidorSignUp.cnpj,
  phone: state.investidorSignUp.phone,
  ramal: state.investidorSignUp.ramal,
  cellphone: state.investidorSignUp.cellphone,
  password: state.investidorSignUp.password,
  confirmation: state.investidorSignUp.confirmation,
  emailError: state.investidorSignUp.emailError,
  fullNameError: state.investidorSignUp.fullNameError,
  companyNameError: state.investidorSignUp.companyNameError,
  tradeNameError: state.investidorSignUp.tradeNameError,
  cpfError: state.investidorSignUp.cpfError,
  cnpjError: state.investidorSignUp.cnpjError,
  phoneError: state.investidorSignUp.phoneError,
  ramalError: state.investidorSignUp.ramalError,
  cellphoneError: state.investidorSignUp.cellphoneError,
  passwordError: state.investidorSignUp.passwordError,
  path: state.investidorSignUp.path,
  flashes: state.investidorSignUp.flashes,
  originPath: state.investidorSignUp.originPath,
  reCaptchaToken: state.investidorSignUp.reCaptchaToken,
});

const mapDispatchToProps = {
  changeType,
  changeEmail,
  changeFullName,
  changeCompanyName,
  changeTradeName,
  changeCpf,
  changeCnpj,
  changePhone,
  changeRamal,
  changeCellphone,
  changePassword,
  changeConfirmation,
  submitForm,
  setReCaptchaToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestidorSignUp);
