import Immutable from 'seamless-immutable';

const initialState = Immutable({
  cnpj: '',
  cnpjError: '',
  password: '',
  passwordError: '',
  remember: false,
  path: '',
  flashes: [],
  passwordPath: '',
  logoUrl: '',
  fingerprint: '',
  components: '',
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SACADO_CHANGE_CNPJ':
      return state.merge({ cnpj: action.payload, cnpjError: '' });
    case 'SACADO_CHANGE_CNPJ_ERROR':
      return state.merge({ cnpj: action.payload.cnpj, cnpjError: action.payload.error });
    case 'SACADO_CHANGE_PASSWORD':
      return state.merge({ password: action.payload, passwordError: '' });
    case 'SACADO_CHANGE_PASSWORD_ERROR':
      return state.merge({
        password: action.payload.password,
        passwordError: action.payload.error,
      });
    case 'SACADO_SUCESSFULL_LOGIN':
      return state;
    case 'SACADO_FAILED_LOGIN':
      return state.merge({
        cnpjError: action.payload.cnpjError,
        passwordError: action.payload.passwordError,
      });
    case 'SACADO_TOOGLE_REMEMBER':
      return state.merge({ remember: !state.remember });
    case 'SACADO_SET_FINGERPRINT':
      return state.merge({
        fingerprint: action.payload.fingerprint,
        components: JSON.stringify(action.payload.components),
      });
    default:
      return state;
  }
};
