import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import {
  Card,
  Header,
  Content,
  TargetingModeContent,
  FunderContent,
  ContentLabel,
  ContentValue,
  ContentInfo,
  InfoWrapper,
  IconWrapper,
  GraphWrapper,
  GraphEmptyWrapper,
} from './styles';
import RatesGraph from '../RatesGraph';
import { auctioneerBankCode } from './constants';
import {
  TARGETING_MODES,
  TARGETING_MODE_INTEGRATION,
  TARGETING_MODE_PLATFORM,
} from '../EditOperationMode/constants';

export function Info({ children }) {
  return (
    <InfoWrapper>
      <IconWrapper>
        <Icon path={mdiInformation} />
      </IconWrapper>
      <ContentInfo>{children}</ContentInfo>
    </InfoWrapper>
  );
}

Info.propTypes = {
  children: PropTypes.node.isRequired,
};

function OperationModeCard({ funderTargetingMode, funder, vendorsGroupId, isEditing }) {
  const isPlatformMode = funderTargetingMode === TARGETING_MODE_PLATFORM;
  const isAuctionFunder = funder?.bankCode === auctioneerBankCode;
  const isFixedHeight = isEditing && isPlatformMode;

  return (
    <Card>
      <Header>Modo de operação</Header>
      <Content>
        <TargetingModeContent>
          <ContentLabel>Modo de direcionamento de títulos</ContentLabel>
          <ContentValue>{`Via ${isPlatformMode ? 'plataforma' : 'integração'}`}</ContentValue>
          {isPlatformMode && <Info>Modo de operação inserido via API será respeitado</Info>}
        </TargetingModeContent>
        <FunderContent fixedHeight={isFixedHeight}>
          {isPlatformMode ? (
            <>
              <ContentLabel>Investidor selecionado</ContentLabel>
              <ContentValue>{funder?.company}</ContentValue>
              {isAuctionFunder && (
                <GraphEmptyWrapper fixedHeight={isFixedHeight}>
                  <Info>
                    Os títulos serão enviados ao investidor que possui a melhor taxa do dia
                  </Info>
                </GraphEmptyWrapper>
              )}
              {!isAuctionFunder && (
                <GraphWrapper>
                  <RatesGraph
                    noShadow
                    vendorsGroupId={vendorsGroupId}
                    funderIds={funder ? [funder.id] : [-1]}
                    emptyState={
                      <GraphEmptyWrapper fixedHeight={isFixedHeight}>
                        <Info>Sem taxas cadastradas deste investidor para este grupo</Info>
                      </GraphEmptyWrapper>
                    }
                  />
                </GraphWrapper>
              )}
            </>
          ) : (
            <Info>
              Os títulos serão enviados aos investidores conforme o que consta na integração (API,
              arquivo CNAB, etc.)
            </Info>
          )}
        </FunderContent>
      </Content>
    </Card>
  );
}

OperationModeCard.propTypes = {
  funderTargetingMode: PropTypes.oneOf(TARGETING_MODES.map(({ key }) => key)),
  funder: PropTypes.shape({
    id: PropTypes.number,
    company: PropTypes.string,
    bankCode: PropTypes.string,
  }),
  vendorsGroupId: PropTypes.number,
  isEditing: PropTypes.bool,
};

OperationModeCard.defaultProps = {
  funderTargetingMode: TARGETING_MODE_INTEGRATION,
  funder: undefined,
  vendorsGroupId: undefined,
  isEditing: false,
};

export default OperationModeCard;
