const apiUrl = SERVICE_API_URL; // eslint-disable-line

const investorApi = '/api/v1/investidor';
const investorApiV2 = '/api/v2/funder';

export const myWallet = `${apiUrl}${investorApi}/wallet`;
export const distribution = `${apiUrl}${investorApi}/distribution`;
export const calendar = `${apiUrl}${investorApi}/calendar`;
export const INVOICES_URL = `${apiUrl}${investorApi}/invoices`;
export const INVOICES_URL_V2 = `${apiUrl}${investorApiV2}/invoices`;
export const yieldUrl = `${apiUrl}${investorApi}/yield`;
export const PAYMENT_CONCILIATION_URL = `${apiUrl}${investorApi}/invoices/payment_conciliation`;
export const LIQUIDATED_INVOICES_URL = `${apiUrl}${investorApi}/invoices/liquidated`;
export const WALLET_MEAN_URL = `${apiUrl}${investorApi}/dashboard/wallet_average`;
