import { connect } from 'react-redux';
import Sacado from './Sacado';
import {
  changeCNPJ,
  changePassword,
  toogleRemember,
  submitLogin,
  setFingerprint,
} from '../../actions/sacadoActions';

const mapStateToProps = state => ({
  cnpj: state.sacado.cnpj,
  cnpjError: state.sacado.cnpjError,
  password: state.sacado.password,
  passwordError: state.sacado.passwordError,
  remember: state.sacado.remember,
  path: state.sacado.path,
  flashes: state.sacado.flashes,
  passwordPath: state.sacado.passwordPath,
  logoUrl: state.sacado.logoUrl,
  fingerprint: state.sacado.fingerprint,
  components: state.sacado.components,
});

const mapDispatchToProps = {
  changeCNPJ,
  changePassword,
  toogleRemember,
  submitLogin,
  setFingerprint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sacado);
