import axios from 'axios';

// eslint-disable-next-line no-undef
const BASE_URL = SERVICE_API_URL;

export async function fetchBuyerOrganizationGroups(token = '', params = {}) {
  return axios.get(`${BASE_URL}/api/v1/admin/buyer_organization_groups`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
}
