import styled from 'styled-components';
import { Checkbox } from 'liber-components';

export const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 380px;
`;

export const SelectCheckbox = styled(Checkbox)`
  margin: 0 0 auto 0;
  width: 40px;
  span::before {
    animation: unset !important;
  }
`;
