import React from 'react';
import PropTypes from 'prop-types';
import { IconPrevious, LinkButton } from './styles';

const Previous = ({ label, ...props }) => (
  <LinkButton {...props}>
    <IconPrevious />
    {label}
  </LinkButton>
);

Previous.propTypes = {
  label: PropTypes.string,
};

Previous.defaultProps = {
  label: 'Voltar',
};

export default Previous;
