import styled from 'styled-components';
import { DatePicker as RawDatePicker } from 'liber-components';

export const Container = styled.div`
  width: 100%;
`;

export const FieldLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #405f71;
  margin-bottom: -2px;
  padding-left: 2px;
`;

export const DatePicker = styled(RawDatePicker).attrs(() => ({
  openLabel: true,
  closeOnSelect: true,
}))``;

export const DatePickerWrapper = styled.div`
  & > div > div > div > div:nth-child(1) > div {
    width: 262px;
  }
`;

export const PickersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
