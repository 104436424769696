import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchUserInfo, fetchVendors, changeBuyerStatus } from './api';
import usePagination from '../../hooks/usePagination';
import { convertToCamelCase, handleError } from '../../vendor/Utils';
import usePageState from '../../hooks/usePageState';
import { TokenContext } from '../../hocs/withTokenProvider';

export const defaultStore = {
  token: '',
  isDrawerOpen: false,
  setIsDrawerOpen: () => null,
  selectedVendorId: null,
  setSelectedVendorId: () => null,
  isLoading: false,
  setIsLoading: () => null,
  filters: {},
  setFilters: () => null,
  sortingFilter: {},
  setSortingFilter: () => null,
  vendors: [],
  setVendors: () => null,
  onSelectListing: () => null,
  pagination: { current: 0, per: 10, total: 10 },
  updatePagination: () => null,
  currentPage: 0,
  setPageState: () => null,
  onKeyDownPage: () => null,
  next: () => null,
  previous: () => null,
  count: 0,
  per: 10,
  handleChangeFilters: () => null,
  handleCleanAllFilters: () => null,
  handleUpdateStatus: () => null,
  lastLoginDate: null,
};

export const Context = createContext(defaultStore);

const BuyerVendorProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [sortingFilter, setSortingFilter] = useState({});
  const [vendors, setVendors] = useState([]);
  const [lastLoginDate, setLastLoginDate] = useState(null);
  const [mustApproveVendors, setMustApproveVendors] = useState(null);

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const { token } = useContext(TokenContext);

  const updateVendors = page => {
    setIsLoading(true);
    fetchVendors(token, { page: page || current, per, ...sortingFilter, ...filters })
      .then(response => {
        const { data } = response;
        const formattedData = convertToCamelCase(data?.data || {});

        const { vendors: incomingVendors, pagination: incomingPagination } = formattedData;

        updatePagination({ ...incomingPagination, per });

        setVendors(incomingVendors);

        setMustApproveVendors(formattedData.buyer.mustApproveVendors);
      })
      .catch(error => {
        handleError('Erro!', error?.response?.data?.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchLastLoginDate = () => {
    fetchUserInfo(token)
      .then(response => {
        const { data } = response;
        const formattedData = convertToCamelCase(data?.data || {});
        const { lastLoginAt } = formattedData;

        setLastLoginDate(lastLoginAt);
        updateVendors();
      })
      .catch(error => {
        handleError(
          'Ocorreu um erro ao requisitar as informações do usuário',
          error?.response?.data?.error,
        );
      });
  };

  const handleUpdateStatus = (id, status, message) => {
    changeBuyerStatus(token, id, {
      operation_permission: status,
      operation_permission_change_reason: message,
    })
      .then(() => {
        updateVendors();
      })
      .catch(error => {
        handleError('Ocorreu um erro ao alterar o status', error?.response?.data?.error);
      });
  };

  useEffect(() => {
    updateVendors();
  }, [per, current, sortingFilter]);

  useEffect(() => {
    updateVendors(1);
  }, [filters]);

  useEffect(() => {
    fetchLastLoginDate();
  }, []);

  const handleChangeFilters = (key, text) => {
    setFilters(prevState => ({ ...prevState, ...{ [key]: text } }));
  };

  const handleCleanAllFilters = () => {
    setFilters({});
  };

  return (
    <Context.Provider
      value={{
        token,
        isDrawerOpen,
        setIsDrawerOpen,
        selectedVendorId,
        setSelectedVendorId,
        isLoading,
        setIsLoading,
        filters,
        setFilters,
        sortingFilter,
        setSortingFilter,
        vendors,
        setVendors,
        onSelectListing,
        pagination,
        updatePagination,
        currentPage,
        setPageState,
        onKeyDownPage,
        next,
        previous,
        count,
        per,
        handleChangeFilters,
        handleCleanAllFilters,
        handleUpdateStatus,
        lastLoginDate,
        setCurrent,
        pages,
        mustApproveVendors,
      }}
    >
      {children}
    </Context.Provider>
  );
};

BuyerVendorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BuyerVendorProvider;
