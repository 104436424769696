import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { handleThemeFromObject } from 'liber-components';

import { InfoText, Exclamation, ErrorText, NoticeText } from '../styles';

const Flash = ({ flashes, theme }) => {
  const mapped = (flashes || []).map(error => {
    const [key, value] = error;
    let msg;
    if (value instanceof Array) {
      msg = value.map(content => (
        <React.Fragment key={_.uniqueId(content)}>
          {content}
          <br />
        </React.Fragment>
      ));
    } else if (value instanceof Object) {
      msg = Object.keys(value).map(objectKey => (
        <React.Fragment key={_.uniqueId(objectKey)}>
          {objectKey}: {value[objectKey]}
          <br />
        </React.Fragment>
      ));
    } else if (value) {
      // eslint-disable-next-line react/no-danger
      msg = <div dangerouslySetInnerHTML={{ __html: value }} />;
    } else {
      return null;
    }

    switch (key) {
      case 'alert':
      case 'error':
        return (
          <ErrorText key={_.uniqueId(key)}>
            <Exclamation />
            {msg}
          </ErrorText>
        );
      case 'notice':
        return (
          <NoticeText key={_.uniqueId(key)}>
            <Exclamation color={handleThemeFromObject(theme, 'colors.liberBlue', '#009dff')} />
            {msg}
          </NoticeText>
        );
      default:
        return <InfoText key={_.uniqueId(key)}>{msg}</InfoText>;
    }
  });
  if (mapped.length === 0) return null;
  return <div>{mapped}</div>;
};

Flash.propTypes = {
  flashes: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.objectOf(PropTypes.string),
      ]),
    ),
  ).isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

Flash.defaultProps = {
  theme: {},
};

export default Flash;
