/* eslint-disable no-undef */
export const START_DATE_PARAM = 'start_date';
export const END_DATE_PARAM = 'end_date';
export const GET_RATES_PREVIEW = `${SERVICE_API_URL}/api/v1/investidor/spreadsheet_rates/:id/data`;
export const CONFIRM_RATES = `${SERVICE_API_URL}/api/v1/investidor/spreadsheet_rates/:id/confirm`;
export const SEND_SPREADSHEET_RATES = `${SERVICE_API_URL}/api/v1/investidor/spreadsheet_rates`;
export const GET_VENDORS_GROUPS_URL = `${SERVICE_API_URL}/api/v1/investidor/vendors_groups`;
export const VENDORS_GROUPS_NAME_FILTER = 'q[name_cont]';
export const VENDORS_GROUPS_BUYER_NAME_FILTER = 'q[buyer_trade_name_cont]';
export const VENDORS_GROUPS_ID_IN_FILTER = 'q[id_in]';
