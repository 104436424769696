import React from 'react';
import PropTypes from 'prop-types';
import FilterTag from '../FilterTag';

const formatLabel = () => list => {
  const [selectedOptions] = list;

  return selectedOptions ? 'Sim' : 'Não';
};

const BooleanFilter = ({ filters, onFilter, label }) => (
  <FilterTag
    show={false}
    selected={[filters]}
    label={label}
    formatSelected={formatLabel(label)}
    onChange={() => onFilter(!filters)}
    onClear={() => onFilter(!filters)}
  />
);

BooleanFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.any),
  onFilter: PropTypes.func,
  label: PropTypes.string,
};

BooleanFilter.defaultProps = {
  filters: null,
  onFilter: () => {},
  label: '',
};

export default BooleanFilter;
