import React from 'react';
import * as S from './styles';

const BuyerInfo = buyer => {
  const { cnpj, tradeName } = buyer.buyer;

  return (
    <div>
      <S.Label>{cnpj}</S.Label>
      <S.Value>{tradeName}</S.Value>
    </div>
  );
};

export default BuyerInfo;
