import React, { useContext } from 'react';
import { SpreadsheetRatesContext } from '../../context';
import { TextFieldFilter } from '../../../Filters';
import { CNPJ_FILTER, GROUP_NAME_FILTER, SACADO_NAME_FILTER } from '../../constants';

import * as S from './styles';
import { hasFilters } from '../../../../vendor/Utils';

const Filters = () => {
  const { filters, updateFilter, clearFilter, clearAllFilters } =
    useContext(SpreadsheetRatesContext);

  return (
    <>
      <S.Label>Filtros</S.Label>
      <TextFieldFilter
        label="CNPJ"
        hintText="CNPJ"
        selectedItems={filters[CNPJ_FILTER]}
        onFilter={cnpj => updateFilter(CNPJ_FILTER)((cnpj || '').replace(/\D/g, ''))}
        onClear={clearFilter(CNPJ_FILTER)}
      />
      <TextFieldFilter
        label="Sacado"
        hintText="R. Social/Nome Fantasia"
        selectedItems={filters[SACADO_NAME_FILTER]}
        onFilter={updateFilter(SACADO_NAME_FILTER)}
        onClear={clearFilter(SACADO_NAME_FILTER)}
      />
      <TextFieldFilter
        label="Grupo"
        hintText="Nome do Grupo"
        selectedItems={filters[GROUP_NAME_FILTER]}
        onFilter={updateFilter(GROUP_NAME_FILTER)}
        onClear={clearFilter(GROUP_NAME_FILTER)}
      />
      {hasFilters(filters) && (
        <S.ClearFilters onClick={clearAllFilters}>Limpar Filtros</S.ClearFilters>
      )}
    </>
  );
};

export default Filters;
