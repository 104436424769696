import styled from 'styled-components';
import {
  media,
  handleThemeFromObject,
  FaArrowLeft,
  FaPlus,
  SecondaryLink,
  SuccessButton,
} from 'liber-components';

export const IconAdd = styled(FaPlus)`
  height: 18px;
  width: 18px;
  margin: auto 15px auto 0px;
`;

export const IconRemove = styled(FaPlus)`
  height: 18px;
  width: 18px;
  margin: auto 15px auto 0px;
  transform: rotateZ(45deg);
`;

export const IconPrevious = styled(FaArrowLeft)`
  height: 18px;
  width: 18px;
  margin: auto 15px auto 0px;
`;

export const SuccessLink = styled(SuccessButton)`
  &:disabled {
    background: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  }
`;

export const LinkButton = styled(SecondaryLink)`
  background: white;
  border: none;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')} !important;
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlue', '#009dff')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  padding: 0;
  user-select: none;

  &:hover {
    background: white;
    border: none;
    color: ${props =>
      handleThemeFromObject(props.theme, 'colors.actionBlue', '#4cbaff')} !important;
    fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4cbaff')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.black', '900')};
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  & > a {
    margin-left: -33px;
  }
  & > button {
    order: -1;
    width: 100%;
  }

  ${media.medium`
    display: block;
    width: auto;
    & > a {
      margin: 0;
    }
    & > button {
      width: 280px;
    }
  `}
`;
