import styled, { css } from 'styled-components';
import { Card as BaseCard } from 'liber-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Card = styled(BaseCard)`
  width: 488px;
  min-height: 450px;
  padding: 0px 48px;
  padding-bottom: 16px;
  margin: 0px;
  border: solid 1px #cdd7df;
`;

export const LogoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export const ImageLogo = styled.img`
  width: 96px;
  height: 96px;
`;

export const ContainerRow = styled.div`
  margin-bottom: 30px;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

export const LeftColumn = styled.div`
  width: 158px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #405f71;
`;

export const RightColumn = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #4b6f85;

  ${({ green }) =>
    green &&
    css`
      color: #13ce66;
    `}
`;
