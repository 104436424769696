import styled, { css } from 'styled-components';
import { Container } from 'liber-components/components/GenericStyles';
import { media, Checkbox, Tooltip, LoadingSpinner, handleThemeFromObject } from 'liber-components';

export const CheckboxResponsive = styled(Checkbox)`
  & > span {
    display: inline;
  }
`;

export const Layout = styled(Container)`
  padding-bottom: 50px;
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;

  h2 {
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h3', '32px')};
    font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  }

  p {
    color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#a4a4a4')};
  }

  ${media.medium`
    padding-left: 15px;
    padding-right: 15px;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  width: auto;
  margin: 0px -15px;

  & > * {
    flex-grow: 1;
    padding: 10px 15px;
  }

  & > div {
    width: 100%;
  }

  ${media.medium`
    flex-direction: row;
    & > div {
      width: 256px;
    }
  `}
`;

export const Form = styled.div`
  margin: 50px 0px;
`;

export const RadioContainer = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-wrap: wrap;

  label {
    display: block;
    margin: 15px 0px;
  }

  ${media.medium`
    display: block;
    label {
      display: inline-block;
      margin: 5px 0px;
    }
  `}
`;

export const TooltipFlex = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'baseline'};
  margin-top: -10px;
  div:first-child {
    flex-grow: ${props => (props.grow ? '1' : '0')};
  }
`;

export const TooltipResponsive = styled(Tooltip)`
  margin-left: ${props => (props.small ? '30px' : '15px')};

  img,
  div {
    text-align: center;
    height: auto;
    width: ${props => (props.small ? '225px' : '350px')};
  }
`;

export const LoadSpinner = styled(LoadingSpinner)`
  margin: 15px;
  ${props =>
    props.visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;
