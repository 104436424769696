import React from 'react';
import PropTypes from 'prop-types';
import {
  Background,
  CenterCard,
  Card,
  CardTitle,
  CardParagraph,
  BankAccount,
  BankAccounts,
} from '../styles';

const WarningCard = ({ title, paragraph1, paragraph2, investidorPf, accounts }) => {
  const bankAccounts = accounts.map(account => (
    <BankAccount key={account.id}>
      <p>
        <b>Banco:</b> {account.bankName}
      </p>
      <p>
        <b>Razão Socal:</b> {account.companyName}
      </p>
      <p>
        <b>CNPJ:</b> {account.cnpj}
      </p>
      <p>
        <b>Agência:</b> {account.agency}
      </p>
      <p>
        <b>Conta:</b> {account.accountWithDigit}
      </p>
    </BankAccount>
  ));
  const investidorPfCard = (
    <Background>
      <CenterCard>
        <Card>
          <CardTitle>{title}</CardTitle>
          <CardParagraph>{paragraph1}</CardParagraph>
          <CardParagraph>{paragraph2}</CardParagraph>
          <BankAccounts>{bankAccounts}</BankAccounts>
        </Card>
      </CenterCard>
    </Background>
  );
  const investidorPjCard = (
    <Background>
      <CenterCard>
        <Card>
          <CardTitle>{title}</CardTitle>
          <CardParagraph>{paragraph1}</CardParagraph>
        </Card>
      </CenterCard>
    </Background>
  );
  return investidorPf ? investidorPfCard : investidorPjCard;
};

WarningCard.defaultProps = {
  accounts: [],
};

WarningCard.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph1: PropTypes.string.isRequired,
  paragraph2: PropTypes.string.isRequired,
  investidorPf: PropTypes.bool.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object),
};

export default WarningCard;
