import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import { Container, Header, Content, ActionsRow, Button, ButtonCancel } from '../styles';

function ConfirmOpModeEdit({ show, onLeaved, handleCancel, handleConfirm }) {
  return (
    <Modal show={show} onLeaved={onLeaved}>
      <Container>
        <Header>Salvar alterações do modo de operação?</Header>
        <Content>
          Alterações foram feitas no modo de operação.
          <br />
          <br />
          Deseja salvar essas alterações antes de sair?
        </Content>
        <ActionsRow>
          <ButtonCancel onClick={handleCancel}>DESCARTAR ALTERAÇÕES</ButtonCancel>
          <Button onClick={handleConfirm}>SALVAR</Button>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

ConfirmOpModeEdit.propTypes = {
  show: PropTypes.bool.isRequired,
  onLeaved: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ConfirmOpModeEdit;
