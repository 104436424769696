import styled, { css } from 'styled-components';
import { PrimaryButton, handleThemeFromObject as getTheme, FlatButton } from 'liber-components';

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  width: 130px;
  text-transform: uppercase;
  padding: 0 16px 0 16px;
`;

export const EditButton = styled(FlatButton).attrs(() => ({ size: 'large' }))`
  margin-left: 8px;
`;

export const Container = styled.div`
  width: 100%;

  * {
    font-family: Roboto;
  }
`;

export const IconButton = styled.div`
  margin-right: 8px;
  padding: 6px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
  & > svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &:hover {
    background-color: #f0f9ff;
  }
  border-radius: 4px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  ${({ $wrap }) =>
    $wrap
      ? css`
          width: fit-content;
          justify-content: flex-start;

          & > button {
            min-width: 101px;
          }
        `
      : css`
          width: 100%;
          justify-content: space-between;
        `};
`;

export const ReferenceLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6287a7;
  margin: -22px 8px 0 55px;
`;

export const ReferenceValue = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #405f71;
  margin-top: -24px;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  color: #6287a7;
  margin: 32px 0;
`;
