import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import {
  CardContent as RawCardContent,
  Card as RawCard,
  CardHeader,
  handleThemeFromObject as theme,
  FaChevronDown,
  media,
} from 'liber-components';
import { pattern } from '../styles';

export const Card = styled(RawCard)`
  max-height: 90vh;
  ${media.large`
    max-height: 68vh;
  `}
`;

export const Global = createGlobalStyle`
  .dashed-background {
      width: 100vw;
      min-height: 100vh;
      overflow: auto;
      background: url('${pattern}'), linear-gradient(311deg, #009dff, #63d0f8);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      ${media.large`
        padding: 0 50px;
      `}
    }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  ${media.large`
    min-height: unset;
    flex-grow: 1;
  `}
`;

export const CardContent = styled(RawCardContent)`
  width: 100%;
  padding: 16px 16px 0px 16px;
  overflow: auto;

  ${media.large`
    padding: 16px 52px 0px 52px;
    width: 500px;
  `}
`;

export const Header = styled(CardHeader)`
  width: 100%;
  padding: 32px 16px 8px 16px;
  height: unset;
  background-color: transparent;
  text-align: center;
  border-bottom: 1px solid ${props => theme(props.theme, 'colors.v2.grey2', '#dce8f0')};

  ${media.large`
    width: 500px;
    padding: 32px 52px 8px 52px;
  `}
  & h1 {
    font-size: ${props => theme(props.theme, 'fontSizes.h3', '32px')};
    font-weight: ${props => theme(props.theme, 'fontWeights.medium', 500)};
    font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};
    color: ${props => theme(props.theme, 'colors.v2.liberBlue', '#009DFF')};
    margin: 0px 0px 8px 0px;
  }

  & p {
    font-size: ${props => theme(props.theme, 'fontSizes.h5', '20px')};
    font-weight: ${props => theme(props.theme, 'fontWeights.regular', 400)};
    font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};
    color: ${props => theme(props.theme, 'colors.v2.grey3', '#C6D1D8')};
  }
`;
export const Footer = styled(Header)`
  margin-bottom: 0px;
  border-top: 1px solid ${props => theme(props.theme, 'colors.v2.grey2', '#dce8f0')};
  padding: 16px 0px;
`;

const Chevron = styled(FaChevronDown)`
  --height: 12px;
  --width: 12px;
  max-width: var(--width);
  max-height: var(--height);
  max-width: var(--width);
  max-height: var(--height);
  fill: #dce8f0;
  position: absolute;
  right: 16px;
  top: calc(50% - var(--height) / 2);
  transform: rotate(-90deg);
`;

export const ListHeader = styled.li`
  padding: 8px 16px;
  height: 37px;
  line-height: ${props => theme(props.theme, 'fontSizes.h4', '24px')};
  font-size: ${props => theme(props.theme, 'fontSizes.h4', '24px')};
  font-weight: ${props => theme(props.theme, 'fontWeights.bold', 700)};
  font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};
  color: ${props => theme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  background-color: ${props => theme(props.theme, 'colors.v2.grey2', '#DCE8F0')};
`;

const Item = styled.li`
  line-height: ${props => theme(props.theme, 'fontSizes.h5', '20px')};
  font-size: ${props => theme(props.theme, 'fontSizes.h5', '20px')};
  font-weight: ${props => theme(props.theme, 'fontWeights.medium', 500)};
  font-family: ${props => theme(props.theme, 'fontFamily', 'Roboto')};

  cursor: pointer;
  position: relative;

  & a {
    padding: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: ${props => theme(props.theme, 'colors.v2.petroleum', '#4b6f85')}!important;
  }

  & a span {
    font-size: ${props => theme(props.theme, 'fontSizes.p', '16px')};
    font-weight: ${props => theme(props.theme, 'fontWeights.medium', 500)};
  }
`;

export const List = styled.ul`
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px 0px 16px 0px;

  & > ${Item} {
    border-bottom: 1px solid ${props => theme(props.theme, 'colors.v2.grey2', '#DCE8F0')};
  }
  & > ${Item}:last-child {
    border-bottom: 0px;
  }
`;

export const ListItem = ({ children, ...props }) => (
  <Item {...props}>
    {children}
    <Chevron />
  </Item>
);

ListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
};
ListItem.defaultProps = {
  children: '',
};
