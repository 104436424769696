import React, { useContext } from 'react';
import moment from 'moment';
import { Table, TableCell, TableRow } from 'liber-salti';
import { FunderTradesSettlementContext } from '../../context';
import {
  COLUMNS,
  TRADE_STATUSES,
  TRADE_STATUS_COLOR_MAPPER,
  TRADE_STATUS_TEXT_MAPPER,
} from '../../constants';
import { getDifferenceInDays, getRelativeTimeMessage } from '../../../../vendor/Utils';

import * as S from './styles';

const getSettlementMessage = (finishedAt, deadline) => {
  const days = getDifferenceInDays(finishedAt, deadline);

  if (days === 1) return '1 dia';
  return `${days} dias`;
};

const markAsSettledAction = {
  icon: 'CheckmarkStarburst',
  label: 'Confirmar liquidação',
};

const postponeAction = {
  icon: 'Clock',
  label: 'Prorrogar',
};

const markAsDelayedAction = {
  icon: 'Calendar',
  label: 'Marcar como atrasado',
};

const getActions = status => {
  const actions = [markAsSettledAction];

  if (status === TRADE_STATUSES.DELAYED || status === TRADE_STATUSES.WAITING_SETTLEMENT) {
    actions.push(postponeAction);
    return actions;
  }

  if (status === TRADE_STATUSES.POSTPONED) {
    actions.push(markAsDelayedAction);
    actions.push(postponeAction);
    return actions;
  }

  return [];
};

const TradeSettlementsTable = () => {
  const { tradeSettlements, count, setCurrent, currentPage, per, onSelectListing } = useContext(
    FunderTradesSettlementContext,
  );

  const onPageChange = (_, page) => {
    setCurrent(page);
  };

  const onRowsPerPageChange = event => {
    onSelectListing(event.target.value);
  };

  const getExpirationTextType = (status, expirationDate) => {
    const expirationInDays = getDifferenceInDays(new Date(), expirationDate);
    if (status === TRADE_STATUSES.DELAYED) return 'danger';
    if (expirationInDays <= 7) return 'warning';
    return 'primary';
  };

  return (
    <Table
      headerSettings={{
        columns: COLUMNS,
        title: 'Negociações',
      }}
      pagination={{
        count,
        onPageChange,
        onRowsPerPageChange,
        page: currentPage - 1,
        rowsPerPage: per,
      }}
      rightActions={{
        actionsAmount: 4,
        label: 'Ações',
      }}
    >
      {tradeSettlements.map(
        ({ number, status, finishedAt, expiration, expectedSettlementAt, settledAt, vendor }) => (
          <TableRow
            checkboxTooltip=""
            rightActions={{
              actions: getActions(status),
              actionsAmount: 4,
              label: 'Ações',
            }}
          >
            <TableCell label={number} variant="numberLink" />
            <TableCell
              color={TRADE_STATUS_COLOR_MAPPER[status]}
              label={TRADE_STATUS_TEXT_MAPPER[status]}
              variant="chip"
            />
            <TableCell label={moment(finishedAt).format('DD/MM/YYYY')} />
            <TableCell>
              <S.ExpirationText type={getExpirationTextType(status)}>
                Vencido {getRelativeTimeMessage(finishedAt, expiration)}
              </S.ExpirationText>
              <span>{moment(expiration).format('DD/MM/YYYY')}</span>
            </TableCell>
            <TableCell label={getSettlementMessage(expectedSettlementAt)} />
            <TableCell label={settledAt} />
            <TableCell label={vendor.name} />
          </TableRow>
        ),
      )}
    </Table>
  );
};

export default TradeSettlementsTable;
