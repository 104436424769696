export default {
  empty: 'Esse campo não pode estar vazio',
  small: 'A senha deve ter pelo menos 6 caracteres',
  match: 'As senhas devem ser iguais',
  wrongZipCode: 'CEP não encontrado, verifique se o valor está correto!',
  zipApiError: 'Não foi possível recuperar os dados do CEP automaticamente',
  badAction: 'Não foi possivel realizar essa ação, tente novamente mais tarde!',
  wallet: 'Carteira deve ter 2 dígitos',
  walletVariation: 'Variação da Carteira deve ter 3 dígitos.',
};
