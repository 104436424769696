import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CompleteTable, Drawer, TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiClose, mdiInformation } from '@mdi/js';
import { connect } from 'react-redux';

import { AnticipationPropTypes, AnticipationDefaultPropTypes } from '../../propTypes';
import { fixPercent } from '../../../../vendor/Utils';
import {
  updateSelectedAdvance,
  fetchAdvanceInvoices,
  updateDetailsPagination,
  updateDetailsSorting,
  fetchAdvanceDetails,
} from '../../actions/VendorAdvancesActions';
import DetailsDrawerFilters from '../DetailsDrawerFilters/DetailsDrawerFilters';

import {
  Container,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Title,
  IconButton,
  CloseButton,
  DownloadButton,
  BodyText,
  BodyTextBlue,
  CaptionText,
  DetailsContainer,
  DetailsGrid,
  BottomDetailsGrid,
  Loading,
  LoadingContainer,
  IconWrapper,
  TooltipLabel,
  TooltipBottomGrid,
  TableWrapper,
} from './DetailsDrawer.styles';
import { getColumns } from './columns';

export const DetailsDrawer = ({
  advance,
  open,
  onClose,
  handleSelectAdvance,
  selectedAdvanceId,
  handleFetchAdvanceInvoices,
  handleUpdatePagination,
  handleFetchAdvanceDetails,
  invoices,
  loading,
  pagination,
  filters,
  sortingState,
  funderName,
  bankAccount,
  signatureCertificateUrl,
  invoiceTransferAgreementUrl,
  detailsLoading,
}) => {
  const {
    averageRate,
    discount,
    invoiceCount,
    netValue,
    requestedBy,
    requestedAt,
    signatures: { required: requiredSignatures, done: doneSignatures },
    id,
  } = advance;

  const isSolicitation = !id;

  const { branch, branchCd, account, accountCd, bankName, bankCode, kind } = bankAccount || {};

  const bankAccountAlias = useMemo(
    () => (kind && bankName ? `Conta ${kind} ${bankName}` : '-'),
    [kind, bankName],
  );

  const { pages, count, current, per } = pagination;

  const paginationInterval = useMemo(() => {
    const start = (current - 1) * per + 1;
    const end = current * per;
    return count > end ? { start, end } : { start, end: count };
  }, [current, per, count]);

  const handleUpdatePage = targetPage => handleUpdatePagination({ current: targetPage });

  const handleUpdatePerPage = targetPerPage =>
    handleUpdatePagination({ current: 1, per: Number(targetPerPage) });

  const handleDownloadNf = url => {
    window.open(url, '_blank');
  };

  const handleDownloadDocument = () => {
    window.open(signatureCertificateUrl ?? invoiceTransferAgreementUrl, '_blank');
  };

  const renderDocumentButton = ({ disabled } = { disabled: false }) => (
    <DownloadButton disabled={disabled || detailsLoading} onClick={handleDownloadDocument}>
      BAIXAR TERMO DE CESSÃO
    </DownloadButton>
  );

  useEffect(() => {
    if (open && selectedAdvanceId === id) {
      handleFetchAdvanceInvoices();
      handleFetchAdvanceDetails();
    }
  }, [open, selectedAdvanceId, current, per, filters, sortingState]);

  useEffect(() => {
    if (open) handleSelectAdvance(id);
  }, [open]);

  return (
    <Drawer show={open} onLeaved={onClose} position="right" size={760}>
      <Container>
        <DialogHeader>
          <Title>{isSolicitation ? 'Solicitação' : `Antecipação ${id}`}</Title>
          <IconButton onClick={onClose}>
            <Icon path={mdiClose} />
          </IconButton>
        </DialogHeader>
        <DialogContent>
          {(loading || detailsLoading) && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
          <DetailsContainer>
            <BodyText>Detalhes da {isSolicitation ? 'solicitação' : 'antecipação'}</BodyText>
            <DetailsGrid>
              <div>
                <CaptionText>Títulos</CaptionText>
                <BodyText>{invoiceCount}</BodyText>
              </div>
              <div>
                <CaptionText>Taxa</CaptionText>
                <BodyText>{fixPercent(averageRate)}%</BodyText>
              </div>
              <div>
                <CaptionText>Desconto</CaptionText>
                <BodyText>{discount}</BodyText>
              </div>
              <div>
                <CaptionText>Valor líquido</CaptionText>
                <BodyTextBlue>{netValue}</BodyTextBlue>
              </div>

              {!isSolicitation && (
                <div>
                  <CaptionText>Assinaturas coletadas</CaptionText>
                  <BodyText>
                    {doneSignatures} de {requiredSignatures}
                  </BodyText>
                </div>
              )}
            </DetailsGrid>
            <BottomDetailsGrid>
              {!isSolicitation && (
                <div>
                  <CaptionText>Pedido em</CaptionText>
                  <BodyText>{requestedAt}</BodyText>
                </div>
              )}

              {!isSolicitation && (
                <>
                  <div>
                    <CaptionText>Por</CaptionText>
                    <BodyText>{requestedBy || '-'}</BodyText>
                  </div>
                  <div>
                    <CaptionText>Investidor</CaptionText>
                    <BodyText>{funderName || '-'}</BodyText>
                  </div>
                </>
              )}
            </BottomDetailsGrid>
            {!isSolicitation && (
              <BottomDetailsGrid>
                <div>
                  <CaptionText>Conta para recebimento</CaptionText>
                  <BodyText>
                    {bankAccountAlias}
                    {bankAccount && (
                      <TooltipBox
                        mount="top"
                        fixed
                        content={
                          <div>
                            <TooltipLabel>Banco</TooltipLabel>
                            <BodyText>
                              {bankCode} - {bankName}
                            </BodyText>
                            <TooltipBottomGrid>
                              <div>
                                <TooltipLabel>Agência</TooltipLabel>
                                <BodyText>
                                  {branch}-{branchCd}
                                </BodyText>
                              </div>
                              <div>
                                <TooltipLabel>Conta</TooltipLabel>
                                <BodyText>
                                  {account}-{accountCd}
                                </BodyText>
                              </div>
                            </TooltipBottomGrid>
                          </div>
                        }
                      >
                        <IconWrapper>
                          <Icon path={mdiInformation} />
                        </IconWrapper>
                      </TooltipBox>
                    )}
                  </BodyText>
                </div>
              </BottomDetailsGrid>
            )}
          </DetailsContainer>
          <TableWrapper>
            <CompleteTable
              filters={<DetailsDrawerFilters />}
              columns={getColumns({ handleDownloadNf })}
              items={invoices || []}
              paginationProps={{
                currentPage: current,
                pageTotal: pages,
                onClickNext: () => handleUpdatePage(current + 1),
                onClickPrev: () => handleUpdatePage(current - 1),
                hasListing: true,
                listingProps: {
                  onSelectListing: targetPerPage => handleUpdatePerPage(targetPerPage),
                  listing: String(per),
                  total: count,
                  listingLabelSingular: 'título',
                  listingLabelPlural: 'títulos',
                  showInterval: paginationInterval,
                },
              }}
            />
          </TableWrapper>
        </DialogContent>
        <DialogFooter>
          <CloseButton onClick={onClose}>FECHAR</CloseButton>
          {signatureCertificateUrl || invoiceTransferAgreementUrl ? (
            renderDocumentButton()
          ) : (
            <TooltipBox mount="top" fixed content="Termo ainda não foi gerado">
              <div>{renderDocumentButton({ disabled: true })}</div>
            </TooltipBox>
          )}
        </DialogFooter>
      </Container>
    </Drawer>
  );
};

DetailsDrawer.propTypes = {
  advance: AnticipationPropTypes,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleSelectAdvance: PropTypes.func,
  selectedAdvanceId: PropTypes.number,
  handleFetchAdvanceInvoices: PropTypes.func,
  handleUpdatePagination: PropTypes.func,
  handleFetchAdvanceDetails: PropTypes.func,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      number: PropTypes.string,
      netValue: PropTypes.string,
      vendorCnpj: PropTypes.string,
      funder: PropTypes.string,
      detailsPath: PropTypes.string,
      taxDocumentUrl: PropTypes.string,
      expiresAt: PropTypes.string,
      faceValue: PropTypes.string,
      agreedRate: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    per: PropTypes.number,
    pages: PropTypes.number,
    count: PropTypes.number,
  }),
  filters: PropTypes.shape({}),
  sortingState: PropTypes.shape({}),
  funderName: PropTypes.string,
  bankAccount: PropTypes.shape({
    branch: PropTypes.string,
    branchCd: PropTypes.string,
    account: PropTypes.string,
    accountCd: PropTypes.string,
    bankName: PropTypes.string,
    id: PropTypes.number,
    bankCode: PropTypes.string,
    kind: PropTypes.string,
  }),
  signatureCertificateUrl: PropTypes.string,
  invoiceTransferAgreementUrl: PropTypes.string,
  detailsLoading: PropTypes.bool,
};

DetailsDrawer.defaultProps = {
  advance: AnticipationDefaultPropTypes,
  open: false,
  onClose: () => undefined,
  handleSelectAdvance: () => undefined,
  selectedAdvanceId: null,
  handleFetchAdvanceInvoices: () => undefined,
  handleUpdatePagination: () => undefined,
  handleFetchAdvanceDetails: () => undefined,
  invoices: [],
  loading: false,
  pagination: {
    current: 0,
    per: 25,
    pages: 1,
    count: 1,
  },
  filters: {},
  sortingState: {},
  funderName: '-',
  bankAccount: {},
  signatureCertificateUrl: undefined,
  invoiceTransferAgreementUrl: undefined,
  detailsLoading: false,
};

const mapStateToProps = ({
  vendorAdvances: {
    details: {
      selectedId,
      invoices,
      loading,
      pagination,
      filters,
      sorting,
      funderName,
      bankAccount,
      signatureCertificateUrl,
      invoiceTransferAgreementUrl,
      detailsLoading,
    },
  },
}) => ({
  selectedAdvanceId: selectedId,
  invoices,
  loading,
  pagination,
  filters,
  sortingState: sorting,
  funderName,
  bankAccount,
  signatureCertificateUrl,
  invoiceTransferAgreementUrl,
  detailsLoading,
});

const mapDispatchToProps = {
  handleSelectAdvance: updateSelectedAdvance,
  handleFetchAdvanceInvoices: fetchAdvanceInvoices,
  handleUpdatePagination: updateDetailsPagination,
  handleUpdateSorting: updateDetailsSorting,
  handleFetchAdvanceDetails: fetchAdvanceDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsDrawer);
