import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  media,
  Card,
  CardHeader as CardHeaderRaw,
  FaSearch,
  FaSortAmountAsc,
  Button,
  FaPrint,
  FaFileExel,
  FaFilePdfO,
  handleThemeFromObject,
} from 'liber-components';
import { IconButton as IconButtonRaw, TooltipHelper } from '../StatementEntry/StatementEntry-style';
import loadingState from './media/loading-state.svg';
import loadingStateMobile from './media/loading-state-mobile.svg';
import emptyStateSummary from './media/empty-state-summary.svg';
import emptyIcon from './media/empty-icon.svg';

export const StatementContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4E4E4E')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  margin: 24px 0px calc(24px - 6.5px) 0px;
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  text-align: left;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h3', '32px')};
  `}
`;

export const StatementCard = styled(Card)`
  --margin-right: 0px;
  width: 100%;
  min-height: 450px;
  margin-right: var(--margin-right);
  transition: all 250ms;
  ${media.large`
    --margin-right: 32px;
    min-height: 652px;
    width: calc(67.7% - var(--margin-right));
`}
`;

export const SummaryCard = styled(Card)`
  width: 32.3%;
  position: sticky;
  top: 20px;
  min-height: 310px;
`;

export const CardHeader = styled(CardHeaderRaw)`
  background-color: ${props => handleThemeFromObject(props.theme, 'colors.lightGrey', '#f7f7f7')};
  display: flex;
  align-items: center;
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  padding: 8px;
  height: 33px;
  text-transform: capitalize;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
    padding: 16px;
    height: 61px;
  `}
`;

export const PeriodBox = styled.div`
  margin-left: auto;
  z-index: 100;

  & > * {
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')};
    white-space: nowrap;
    text-transform: unset;
    ${media.large`
      font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')} !important;
  `}
  }

  & button {
    font-size: ${props =>
      handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')} !important;
    ${media.large`
      font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')} !important;
  `}
  }
`;

export const FilterBox = styled.div`
  margin-left: auto;
  z-index: 90;

  & * {
    font-size: ${props =>
      handleThemeFromObject(props.theme, 'fontSizes.mobileMedium', '12px')} !important;
    white-space: nowrap;
    text-transform: unset;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;

export const TabFilter = styled.div`
  width: 100%;
  height: 63px;

  & label {
    text-transform: unset;
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
  }
`;

export const SearchAndSort = styled(Row)`
  width: 100%;
  height: 63px;
  align-items: center;
  padding: 0px 16px;
  & button,
  & li {
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.p', '16px')};
    display: flex;
    align-items: center;
    font-weight: 200;
    font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  }

  ${media.large`
    padding: 0px 24px;
  `}
`;

export const TabSelect = styled(SearchAndSort)`
  position: absolute;
  width: 50%;
  right: 0px;
`;

const SearchRow = styled(Row)`
  align-items: center;
  margin-right: auto;
  ${media.large`
    margin-right: initial;
  `}
`;

const SearchIcon = styled(FaSearch)`
  --size: 18px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  margin-top: -2px;
`;

const SearchInput = styled.input`
  border: 0px;
  margin-left: 16px;
  width: 97px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};

  &:placeholder {
    color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
    opacity: 0.2;
  }

  &:focus {
    outline: none;
  }
`;

export const SearchField = props => (
  <SearchRow>
    <SearchIcon />
    <SearchInput {...props} placeholder="Buscar" />
  </SearchRow>
);

export const IconButton = styled(Button)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  padding: 0px;
  &:hover {
    background-color: #b6e3ff;
  }
  &:hover svg {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  }
  &:after {
    background-color: #6fc7ff;
  }
`;

export const SortIcon = styled(FaSortAmountAsc)`
  --size: 18px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  margin-right: 16px;
  transform: ${props => (props.change ? 'scaleY(-1)' : 'scaleY(1)')};
`;

const SeparatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;

  ${media.large`
    margin-left: 24px;
  `}
`;

const SeparatorLabel = styled.div`
  height: 22px;
  padding: 4px 5px;
  background-color: ${props =>
    handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC')};
  border-radius: 6px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.bluishDarkGrey', '#8F9EA7')};
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', 500)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
    height: 32px;
  `}
`;

const SeparatorLine = styled.span`
  height: 0;
  flex-grow: 1;
  border-bottom: 2px solid
    ${props => handleThemeFromObject(props.theme, 'colors.bluishMediumGrey', '#DDE8EC')};
  margin-right: 8px;
`;

export const MonthSeparator = props => (
  <SeparatorContainer>
    <SeparatorLabel {...props} />
    <SeparatorLine />
  </SeparatorContainer>
);

export const SummaryButtons = styled.div`
  margin-right: 40px;
  border-top: 1px solid
    ${props => handleThemeFromObject(props.theme, 'colors.lightGrey', '#F7F7F7')};
  width: 50%;
  height: 70px;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & ${TooltipHelper} {
    min-width: 45px;
  }

  & ${IconButtonRaw} {
    min-width: 45px;
    min-height: 45px;
    color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
    margin-right: 4px;
  }
  & ${IconButtonRaw}:hover {
    background-color: #fff;
  }
  & ${IconButtonRaw}:after {
    background-color: ${props =>
      handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  }
  & ${IconButtonRaw} svg {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  }
  & ${IconButtonRaw}:hover svg {
    fill: ${props => handleThemeFromObject(props.theme, 'colors.actionBlue', '#4CBAFF')};
  }
  & ${IconButtonRaw}:last-child {
    margin-right: 0px;
  }
`;

export const PrintIcon = styled(FaPrint)`
  --size: 26px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  margin-top: -2px;
`;

export const ExelIcon = styled(FaFileExel)`
  --size: 26px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  margin-top: -2px;
`;

export const PdfIcon = styled(FaFilePdfO)`
  --size: 26px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  margin-top: -2px;
`;

export const LoadingMessage = styled.div`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.mediumGrey', '#A4A4A4')};
  margin: 0px 8px;
  position: absolute;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h6', '18px')};
    margin: 0px 24px;
  `}
`;

// const pulse = keyframes`
//    0%{
//      opacity: 0.7;
//    }
//    50%{
//      opacity: 0.1;
//    }
//    100%{
//     opacity: 0.7;
//    }
// `;

const move = keyframes`
  0%{
      background-position: -671px 0
  }
  100%{
      background-position: 671px 0
  }
`;

export const LoadingState = styled.div`
  position: absolute;
  background-image: url(${loadingStateMobile});
  width: calc(100% - 8px);
  height: 340px;
  margin: 0px 8px 8px 8px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
    display: block;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${move};
    animation-timing-function: linear;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7) 8%,
      rgba(255, 255, 255, 0.3) 18%,
      rgba(255, 255, 255, 0.7) 33%
    );

    ${media.large`
      animation-duration: 2s;
    `}
  }

  ${media.large`
     width: calc(100% - 32px);
    height: 455px;
    background-image: url(${loadingState});
    margin:0px 8px 8px 24px;
   `}
`;

export const EmptyText = styled.p`
  font-family: ${props => handleThemeFromObject(props.theme, 'fontFamily', 'Roboto')};
  color: ${props => handleThemeFromObject(props.theme, 'colors.darkGrey', '#717171')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.mobileLarge', '14px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.medium', '500')};
  line-height: 24px;
  width: 78%;
  text-align: center;

  ${media.large`
    font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
    width: 60%;
    margin-top: 100px;
  `}
`;

export const EmptyState = styled.div`
  position: absolute;
  background-image: url(${emptyIcon});
  background-repeat: no-repeat;
  background-size: 143px 143px;
  background-position: top center;
  width: 100%;
  height: 214px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  text-align: center;
  ${media.large`
    height: 299px;
    background-size: 200px 200px;
    margin-top: 96px;
  `}
`;

// const summaryPulse = keyframes`
//    0%{
//      opacity: 0.5;
//    }
//    50%{
//      opacity: 0.1;
//    }
//    100%{
//     opacity: 0.5;
//    }
// `;

export const SummaryEmptyState = styled.div`
  position: absolute;
  background-image: url(${emptyStateSummary});
  background-size: 100% 100%;
  width: calc(100% - 120px);
  height: 200px;
  margin: 24px 96px 24px 24px;
  box-sizing: border-box;
  opacity: 0.5;
`;

const summaryMove = keyframes`
  0%{
      background-position: -255px 0
  }
  100%{
      background-position: 255px 0
  }
`;

export const SummaryLoadingState = styled(SummaryEmptyState)`
  opacity: 1;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${summaryMove};
    animation-timing-function: linear;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7) 8%,
      rgba(255, 255, 255, 0.3) 18%,
      rgba(255, 255, 255, 0.7) 33%
    );
  }
`;
