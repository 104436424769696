import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const Fade = ({ children, show, timeout, maxOpacity, minOpacity }) => {
  const defaultStyle = {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: minOpacity,
  };

  const transitionStyles = {
    entering: { opacity: minOpacity },
    entered: { opacity: maxOpacity },
  };
  return (
    <Transition mountOnEnter unmountOnExit in={show} timeout={timeout}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  show: PropTypes.bool.isRequired,
  timeout: PropTypes.number,
  maxOpacity: PropTypes.number,
  minOpacity: PropTypes.number,
};
Fade.defaultProps = {
  timeout: 100,
  maxOpacity: 1,
  minOpacity: 0,
};

export default Fade;
