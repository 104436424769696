import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text } from 'liber-components';

import { Container, FlashAlert } from './styles';

function Alert({ message, expiration }) {
  const alertActive = useMemo(() => {
    const alertExpiration = moment(expiration, 'DD/MM/YYYY');

    return moment().startOf('day').isBefore(alertExpiration);
  }, [expiration]);

  return alertActive ? (
    <Container>
      <FlashAlert>
        <Text large dangerouslySetInnerHTML={{ __html: `<b>Atenção:</b> ${message}` }} />
      </FlashAlert>
    </Container>
  ) : (
    ''
  );
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
};

export default Alert;
