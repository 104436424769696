import React from 'react';
import { Link } from 'liber-components/components/Buttons/Buttons';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useCreateOrganizationGroup } from '../../context';
import * as S from './styles';

const PageHeader = () => {
  const { isEditing } = useCreateOrganizationGroup();
  const pageTitle = `${isEditing ? 'Editar' : 'Criar'} novo grupo econômico`;
  return (
    <S.HeadingContent>
      <Link
        data-testid="btn-action-edit"
        version={2}
        size="large"
        href="/admin/grupos-economicos"
        circle
      >
        <Icon path={mdiArrowLeft} />
      </Link>
      <S.PageTitle>{pageTitle}</S.PageTitle>
    </S.HeadingContent>
  );
};

export default PageHeader;
