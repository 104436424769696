import { useEffect, useState } from 'react';
import { fetchCategories } from '../services/requests';
import { convertToCamelCase } from '../../../../../vendor/Utils';

export const useGetCategories = token => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchCategories(token);
      const parsedData = convertToCamelCase(data.data);
      setCategories(parsedData.buyerGroupCategories);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return { categories, isLoading };
};
