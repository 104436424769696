import React from 'react';
import PropTypes from 'prop-types';
import { Modal, LinearProgress } from 'liber-components';
import { connect } from 'react-redux';
import { LoadingBox, Bunny } from './Dialogs.styles';

export const Loading = ({ show, message }) => (
  <Modal closable={false} show={show}>
    <LoadingBox>
      <h1>{message}</h1>
      <Bunny />
      <LinearProgress value={50} />
    </LoadingBox>
  </Modal>
);

Loading.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

Loading.defaultProps = {
  show: false,
  message: '',
};

const mapStateToProps = state => ({
  show: state.multiProposal.proposals.loading,
  message: state.multiProposal.proposals.loadingMessage,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Loading);
