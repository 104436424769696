import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { convertToCamelCase, handleError } from '../../../../../vendor/Utils';
import { fetchBuyerOrganizationGroups } from '../services/requests';
import { useBuyerOrganizationGroupFilters } from '../hooks/useBuyerOrganizationGroupFilters';

export const BuyerOrganizationGroupsContext = createContext({});

const BuyerOrganizationGroupsProvider = ({ token, children }) => {
  const [organizationGroups, setOrganizationGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    currentPage,
    setPageState,
    onKeyDownPage,
    pagination,
    filters,
    onChangeFilter,
    clearFilter,
    clearFilters,
    buildFilters,
    updatePagination,
    setCurrentPage,
  } = useBuyerOrganizationGroupFilters();

  const { current, per } = pagination;

  const getBuyerOrganizationGroups = useCallback(
    (resetPage = false) => {
      setIsLoading(true);
      fetchBuyerOrganizationGroups(token, { page: resetPage ? 1 : current, per, ...buildFilters() })
        .then(({ data: { data } }) => {
          const parsedData = convertToCamelCase(data);
          setOrganizationGroups(parsedData.buyerOrganizationGroups);
          updatePagination(data.pagination);
        })
        .catch(() => {
          handleError('Ocorreu um erro ao requisitar os grupos econômicos');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [current, per, token, filters],
  );

  useEffect(() => {
    getBuyerOrganizationGroups();
  }, [currentPage]);

  useEffect(() => {
    getBuyerOrganizationGroups(true);
  }, [filters]);

  return (
    <BuyerOrganizationGroupsContext.Provider
      value={{
        organizationGroups,
        currentPage,
        setPageState,
        onKeyDownPage,
        filters,
        onChangeFilter,
        clearFilter,
        clearFilters,
        isLoading,
        pagination,
        setCurrentPage,
      }}
    >
      {children}
    </BuyerOrganizationGroupsContext.Provider>
  );
};

BuyerOrganizationGroupsProvider.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const useBuyerOrganizationGroups = () => {
  const context = useContext(BuyerOrganizationGroupsContext);

  return context;
};

export { useBuyerOrganizationGroups, BuyerOrganizationGroupsProvider };
