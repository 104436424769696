import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, Button } from 'liber-components';
import { connect } from 'react-redux';
import { clearFilters, fetchTrades } from '../../actions/remittanceActions';
import InputField from './InputField';
import { FILTERS } from '../../utils';
import {
  ModalTitle,
  ModalContainer,
  ModalFooter,
  ModalContent,
  CustomModal,
  FilterModalGlobalStyle,
  InputsArea,
} from './Filters.styles';

export const FilterModal = ({ show, onLeaved, onFilter, onClearFilters }) => {
  const handleFilter = () => {
    onFilter();
    onLeaved();
  };

  const handleClear = () => {
    onClearFilters();
    onLeaved();
  };

  return (
    <>
      {show && <FilterModalGlobalStyle />}
      <CustomModal show={show}>
        <ModalContainer>
          <ModalTitle>
            Filtros
            <PrimaryButton id="clear-btn" version={2} size="large" outlined onClick={handleClear}>
              LIMPAR
            </PrimaryButton>
          </ModalTitle>
          <ModalContent>
            <InputsArea>
              {FILTERS.map(input => (
                <InputField key={input.label} input={input} />
              ))}
            </InputsArea>
          </ModalContent>
          <ModalFooter>
            <Button id="cancel-btn" version={2} size="large" onClick={onLeaved}>
              CANCELAR
            </Button>
            <PrimaryButton id="filter-btn" version={2} size="large" onClick={handleFilter}>
              FILTRAR
            </PrimaryButton>
          </ModalFooter>
        </ModalContainer>
      </CustomModal>
    </>
  );
};

FilterModal.propTypes = {
  show: PropTypes.bool,
  onLeaved: PropTypes.func,
  onFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
  input: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'date', 'select', 'checkbox']),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    filterKey: PropTypes.string,
    filterKeys: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
  }),
};

FilterModal.defaultProps = {
  show: true,
  onLeaved: () => null,
  onFilter: () => null,
  onClearFilters: () => null,
  input: {},
};

const mapDispatchToProps = {
  onFilter: fetchTrades,
  onClearFilters: clearFilters,
};

export default connect(null, mapDispatchToProps)(FilterModal);
