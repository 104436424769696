import { connect } from 'react-redux';
import FornecedorSignUp from './FornecedorSignUp';
import {
  changeEmail,
  changeFullName,
  changeCompanyName,
  changeTradeName,
  changeCnpj,
  changePhone,
  changeRamal,
  changeCellphone,
  submitForm,
  setReCaptchaToken,
} from '../../actions/fornecedorSignUpActions';

const mapStateToProps = state => ({
  email: state.fornecedorSignUp.email,
  fullName: state.fornecedorSignUp.fullName,
  companyName: state.fornecedorSignUp.companyName,
  tradeName: state.fornecedorSignUp.tradeName,
  cnpj: state.fornecedorSignUp.cnpj,
  phone: state.fornecedorSignUp.phone,
  ramal: state.fornecedorSignUp.ramal,
  cellphone: state.fornecedorSignUp.cellphone,
  emailError: state.fornecedorSignUp.emailError,
  fullNameError: state.fornecedorSignUp.fullNameError,
  companyNameError: state.fornecedorSignUp.companyNameError,
  tradeNameError: state.fornecedorSignUp.tradeNameError,
  cnpjError: state.fornecedorSignUp.cnpjError,
  phoneError: state.fornecedorSignUp.phoneError,
  path: state.fornecedorSignUp.path,
  flashes: state.fornecedorSignUp.flashes,
  originPath: state.fornecedorSignUp.originPath,
  logoUrl: state.fornecedorSignUp.logoUrl,
  portalId: state.fornecedorSignUp.portalId,
  reCaptchaToken: state.fornecedorSignUp.reCaptchaToken,
});

const mapDispatchToProps = {
  changeEmail,
  changeFullName,
  changeCompanyName,
  changeTradeName,
  changeCnpj,
  changePhone,
  changeRamal,
  changeCellphone,
  submitForm,
  setReCaptchaToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(FornecedorSignUp);
