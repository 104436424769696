import Immutable from 'seamless-immutable';

export const initialState = Immutable({
  showConfirmation: false,
  loading: false,
  loadingMessage: '',
  negotiations: [],
  summaryDone: {
    show: false,
    funder: '',
    successes: {
      count: 0,
      amount: 'R$ 0,00',
      negotiations: [],
    },
    failures: {
      count: 0,
      amount: 'R$ 0,00',
      negotiations: [],
    },
  },
});

const checkProposalById = (negotiations, ids) => {
  let selected = negotiations;
  negotiations.forEach((negotiation, index) => {
    selected = selected.setIn([index, 'checked'], ids.includes(negotiation.id));
  });
  return selected;
};

const selectNegotiation = (negotiations, indexes) => {
  let selected = negotiations;
  indexes.forEach(index => {
    selected = selected.setIn([index, 'checked'], true);
  });
  return selected;
};
const unselectNegotiation = (negotiations, indexes) => {
  let selected = negotiations;
  indexes.forEach(index => {
    selected = selected.setIn([index, 'checked'], false);
  });
  return selected;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'PROPOSAL_SHOW_CONFIRMATION':
      return state.merge({ showConfirmation: true });
    case 'PROPOSAL_HIDE_CONFIRMATION':
      return state.merge({ showConfirmation: false });
    case 'PROPOSAL_SELECT_NEGOTIATIONS_PENDING':
      return state.merge({
        negotiations: selectNegotiation(state.negotiations, action.payload),
      });
    case 'PROPOSAL_UNSELECT_NEGOTIATIONS_PENDING':
    case 'PROPOSAL_SELECT_NEGOTIATIONS_ERROR':
    case 'PROPOSAL_UNSELECT_NEGOTIATIONS_ERROR':
      return state.merge({
        negotiations: unselectNegotiation(state.negotiations, action.payload),
      });
    case 'PROPOSAL_SUBMIT_PROPOSALS_PENDING':
    case 'PROPOSAL_SELECT_FUNDER_PENDING':
      return state.merge({
        loading: true,
        showConfirmation: false,
        loadingMessage: action.payload,
      });
    case 'PROPOSAL_SELECT_FUNDER_SUCCESS':
      return state.merge({
        negotiations: action.payload.proposals.negotiations,
        loading: false,
        loadingMessage: '',
      });
    case 'PROPOSAL_SUBMIT_PROPOSALS_ERROR':
    case 'PROPOSAL_SELECT_FUNDER_ERROR':
      return state.merge({
        loading: false,
        loadingMessage: '',
      });
    case 'PROPOSAL_SUBMIT_PROPOSALS_SUCCESS':
      return state.merge({
        summaryDone: { ...action.payload, show: true },
        loading: false,
        loadingMessage: '',
      });
    case 'PROPOSAL_ADD_CHECK_FROM_SUMMARY':
      return state.merge({
        negotiations: checkProposalById(
          state.negotiations,
          action.payload.map(negotiation => negotiation.id),
        ),
      });
    case 'PROPOSAL_CLOSE_DONE':
      return state.merge(
        {
          summaryDone: {
            show: false,
          },
        },
        { deep: true },
      );
    default:
      return state;
  }
};
