import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import { TokenContext } from '../../../../hocs/withTokenProvider';
import { Header, Content, FlatButton, Button } from './styles';
import { ActionsRow, Container } from '../CommonStyles';
import { handleError, handleSuccess, dispatchAmplitudeEvent } from '../../../../vendor/Utils';
import { archiveGroup } from '../../api';
import { VendorsGroupsContext } from '../../context';
import LoadingButton from '../../../../common-components/Buttons/LoadingButton';

function ArchiveModal({ onLeaved, show }) {
  const [loading, setLoading] = useState(false);

  const { token } = useContext(TokenContext);
  const { selectedGroup, setArchiveGroupModalOpen, updateVendorsGroups } =
    useContext(VendorsGroupsContext);
  const { name, id, referenceCode } = selectedGroup || {};

  const handleArchive = () => {
    setLoading(true);
    archiveGroup(token, id)
      .then(() => {
        dispatchAmplitudeEvent('buyer_vendors_groups_archive_success', { name, id, referenceCode });
        setArchiveGroupModalOpen(false);
        updateVendorsGroups();
        handleSuccess(`Grupo ${name} arquivado com sucesso.`);
      })
      .catch(() => {
        handleError(
          'Erro ao arquivar grupo',
          'Houve um problema ao arquivar o grupo. Por favor, atualize a página e tente novamente.',
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onLeaved={onLeaved}>
      <Container>
        <Header>Arquivar o grupo &quot;{name}&quot;?</Header>
        <Content>
          Ao arquivar esse grupo, todos os fornecedores irão voltar para o grupo padrão. Esta ação
          não pode ser desfeita.
        </Content>
        <ActionsRow>
          <FlatButton onClick={onLeaved}>CANCELAR</FlatButton>
          <LoadingButton
            ButtonComponent={Button}
            onClick={() => handleArchive()}
            loading={loading}
            size="large"
          >
            ARQUIVAR
          </LoadingButton>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

ArchiveModal.propTypes = {
  onLeaved: PropTypes.func,
  show: PropTypes.bool,
};

ArchiveModal.defaultProps = {
  onLeaved: () => {},
  show: true,
};

export default ArchiveModal;
