import React from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Background, CenterCard } from '../styles';
import Form from '../FormBuilder';
import Footer from '../Footer';

const Login = ({ type, config, reCaptchaSecret }) => (
  <Background>
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSecret} scriptProps={{ async: true }}>
      <CenterCard>
        <Form type={type} config={config} />
      </CenterCard>
    </GoogleReCaptchaProvider>
    <Footer />
  </Background>
);

Login.propTypes = {
  type: PropTypes.string,
  config: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
  ).isRequired,
  reCaptchaSecret: PropTypes.string.isRequired,
};

Login.defaultProps = {
  type: 'fornecedor',
};

export default Login;
