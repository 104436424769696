import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, CardFooter, handleThemeFromObject } from 'liber-components';
import { CheckboxLabel, Download, Icon, PdfViewer } from './styles';
import { CheckboxResponsive, Layout, Row } from '../styles';
import { NavigationContainer, Next, Previous } from '../../Navigation';
import { openInNewTab } from '../../../actions/wizard';

class Contratos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreedContract: false,
      agreedIrpf: false,
    };
  }

  handleCheckContract = () => {
    this.setState(prevState => ({ agreedContract: !prevState.agreedContract }));
  };

  handleCheckIrpf = () => {
    this.setState(prevState => ({ agreedIrpf: !prevState.agreedIrpf }));
  };

  render() {
    const {
      adesaoInvestidor,
      condicoesUsoPlataforma,
      mailerSubscription,
      changeMailerSubscription,
      onPrevious,
      submitContracts,
      theme,
    } = this.props;
    const { agreedContract, agreedIrpf } = this.state;

    return (
      <Layout>
        <h2>Contratos</h2>
        <Row>
          <Card width="auto" height="auto">
            <CardHeader
              style={{
                textAlign: 'center',
                color: handleThemeFromObject(theme, 'colors.liberBlack', '#4e4e4e'),
                height: 'auto',
              }}
            >
              Contrato de Adesão
            </CardHeader>
            <CardContent>
              <PdfViewer data={adesaoInvestidor} type="application/pdf">
                <p>Arquivo Indisponível</p>
              </PdfViewer>
            </CardContent>
            <CardFooter>
              <Download onClick={() => openInNewTab(adesaoInvestidor)}>
                <Icon />
                Baixar
              </Download>
            </CardFooter>
          </Card>
          <Card width="auto" height="auto">
            <CardHeader
              style={{
                textAlign: 'center',
                color: handleThemeFromObject(theme, 'colors.liberBlack', '#4e4e4e'),
                height: 'auto',
              }}
            >
              Condições de Uso da Plataforma
            </CardHeader>
            <CardContent>
              <PdfViewer data={condicoesUsoPlataforma} type="application/pdf">
                <p>Arquivo Indisponível</p>
              </PdfViewer>
            </CardContent>
            <CardFooter>
              <Download onClick={() => openInNewTab(condicoesUsoPlataforma)}>
                <Icon />
                Baixar
              </Download>
            </CardFooter>
          </Card>
        </Row>
        <div>
          <CheckboxResponsive
            label="Aceite de Termos"
            name="contract[accept_terms]"
            id="contract_accept_terms"
            onChange={this.handleCheckContract}
          />
          <CheckboxLabel>
            Li e concordo com os termos do&nbsp;
            <i>Contrato de Adesão</i> e <i>Condições de Uso da Plataforma</i>
          </CheckboxLabel>
        </div>
        <div>
          <CheckboxResponsive
            label="Concordância IRPF"
            name="contract[accept_irpf]"
            id="contract_accept_irpf"
            onChange={this.handleCheckIrpf}
          />
          <CheckboxLabel>
            Confirmo que estou ciente das&nbsp;
            <a href="/irpf" target="blank">
              Obrigações do Imposto de Renda para o Investidor Pessoa Física
            </a>
          </CheckboxLabel>
        </div>
        <div>
          <CheckboxResponsive
            label="Notificações"
            name="investidor[mailer-subscription]"
            id="investidor_mailer_subscription"
            onChange={event => changeMailerSubscription(event.target.checked)}
            checked={mailerSubscription}
          />
          <CheckboxLabel>
            Quero receber relatórios diários sobre os títulos disponíveis para investimento
          </CheckboxLabel>
        </div>
        <NavigationContainer style={{ paddingTop: 25 }}>
          <Previous onClick={onPrevious} />
          <Next
            label="Concluir"
            onClick={() => submitContracts(mailerSubscription)}
            style={{ float: 'right' }}
            disabled={!(agreedContract && agreedIrpf)}
          />
        </NavigationContainer>
      </Layout>
    );
  }
}

Contratos.propTypes = {
  adesaoInvestidor: PropTypes.string.isRequired,
  condicoesUsoPlataforma: PropTypes.string.isRequired,
  mailerSubscription: PropTypes.bool.isRequired,
  changeMailerSubscription: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  submitContracts: PropTypes.func.isRequired,
  theme: PropTypes.objectOf(PropTypes.any),
};

Contratos.defaultProps = {
  theme: {},
};

export default Contratos;
