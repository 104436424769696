import Immutable from 'seamless-immutable';
import _ from 'lodash';

const initialState = Immutable({
  0: {
    id: -1,
    address: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    country: '',
    district: '',
    zip_code: '',
    kind: 'cobranca',
    is_patch: false,
    is_fetching: false,
    is_loaded: false,
    errors: {},
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'WIZARD_INVESTIDOR/ENDERECOS/ADDRESS':
      return state
        .setIn([action.index, 'address'], action.payload)
        .setIn([action.index, 'errors', 'address'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/NUMBER':
      return state
        .setIn([action.index, 'number'], action.payload)
        .setIn([action.index, 'errors', 'number'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/COMPLEMENT':
      return state
        .setIn([action.index, 'complement'], action.payload)
        .setIn([action.index, 'errors', 'complement'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/CITY':
      return state
        .setIn([action.index, 'city'], action.payload)
        .setIn([action.index, 'errors', 'city'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/STATE':
      return state
        .setIn([action.index, 'state'], action.payload)
        .setIn([action.index, 'errors', 'state'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/COUNTRY':
      return state
        .setIn([action.index, 'country'], action.payload)
        .setIn([action.index, 'errors', 'country'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/DISTRICT':
      return state
        .setIn([action.index, 'district'], action.payload)
        .setIn([action.index, 'errors', 'district'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/ZIP_CODE':
      return state
        .setIn([action.index, 'zip_code'], action.payload)
        .setIn([action.index, 'errors', 'zip_code'], '');
    case 'WIZARD_INVESTIDOR/ENDERECOS/TOGGLE_FETCH':
      return state.setIn([action.index, 'is_fetching'], !state[action.index].is_fetching);
    case 'WIZARD_INVESTIDOR/ENDERECOS/SET_DATA':
      return state.merge({ ...action.payload });
    case 'WIZARD_INVESTIDOR/ENDERECOS/SET_DATA_FROM_ZIP_CODE':
      return state.setIn([action.index], {
        ...state[action.index],
        ...action.payload,
        errors: {},
        is_loaded: true,
      });
    case 'WIZARD_INVESTIDOR/ENDERECOS/SET_IS_LOADED':
      return state.setIn([action.index], {
        ...state[action.index],
        is_loaded: action.payload,
      });
    case 'WIZARD_INVESTIDOR/ENDERECOS/SUCCESSFULL_SUBMIT':
      return state.setIn([action.index], {
        ...state[action.index],
        id: action.payload,
        errors: {},
        is_patch: true,
      });
    case 'WIZARD_INVESTIDOR/ENDERECOS/FAILED_SUBMIT':
      return state.setIn([action.index, 'errors'], action.payload);
    case 'WIZARD_INVESTIDOR/ENDERECOS/ADD_ADDRESS':
      return state.merge({ [Object.keys(state).length]: initialState[0].merge({ kind: null }) });
    case 'WIZARD_INVESTIDOR/ENDERECOS/REMOVE_ADDRESS':
      return Immutable(_.omit(state, action.index));
    default:
      return state;
  }
};
