import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';
import SpreadsheetRatesTable from '../../common-components/SpreadsheetRatesTable';
import ScreenTitle from '../../common-components/ScreenTitle';
import { TableHeader } from './styles';

function View({ token }) {
  return (
    <ThemeProvider theme={Theme}>
      <ScreenTitle handleBack={() => window.history.back()}>Histórico</ScreenTitle>
      <TableHeader>Taxas inativas</TableHeader>
      <SpreadsheetRatesTable token={token} activeRates={false} />
      <ToastContainer />
    </ThemeProvider>
  );
}

View.propTypes = {
  token: PropTypes.string,
};

View.defaultProps = {
  token: '',
};

export default View;
