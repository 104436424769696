import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { DropDownContent, DropDownContentRow, ConfirmationContent } from './Table.styles';
import { ACTIONS } from '../../utils';
import {
  requestNegotiationAction,
  downloadNegotiationAction,
} from '../../actions/AdminNegotiationsActions';
import Dialog from '../../../../common-components/Dialog/Dialog';

const confirmationMap = {
  'Abrir negociação': {
    title: 'Abrir Negociação',
    message: number => `Tem certeza que deseja abrir a negociação referente ao título ${number}?`,
    confirmColor: 'primary',
    cancelLabel: 'CANCELAR',
  },
  Cancelar: {
    title: 'Cancelar Negociação',
    message: number =>
      `Esta ação não poderá ser desfeita. Tem certeza que deseja cancelar a negociação referente ao título ${number}?`,
    confirmColor: 'danger',
    cancelLabel: 'FECHAR',
  },
};

export const ItemActions = ({ onRequestAction, onDownloadAction, item }) => {
  const {
    id,
    invoice: { number },
    actions,
  } = item;

  const [selectedAction, setSelectedAction] = useState('');
  const [selectedActionPath, setSelectedActionPath] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleRequestAction = (name, path) => {
    setSelectedAction(name);
    setSelectedActionPath(path);
    setConfirmationOpen(true);
  };

  const confirmationDialogProps = () => {
    const {
      title,
      message,
      confirmColor: color,
      cancelLabel,
    } = confirmationMap[selectedAction] || {};

    return {
      open: confirmationOpen,
      onClose: () => setConfirmationOpen(false),
      confirmationButtonProps: {
        onConfirm: () => {
          setConfirmationOpen(false);
          onRequestAction(id, selectedActionPath, selectedAction);
        },
        label: 'CONTINUAR',
        color,
        disabled: false,
      },
      title,
      children: <ConfirmationContent>{message && message(number)}</ConfirmationContent>,
      cancelLabel,
    };
  };

  const renderActionButton = (name, icon, { path, kind }) => {
    switch (kind) {
      case 'api':
        return (
          <DropDownContentRow href="#" onClick={() => handleRequestAction(name, path)}>
            <Icon path={icon} />
            {name}
          </DropDownContentRow>
        );
      case 'download':
        return (
          <DropDownContentRow href="#" onClick={() => onDownloadAction(path)}>
            <Icon path={icon} />
            {name}
          </DropDownContentRow>
        );
      case 'navigation':
      default:
        return (
          <DropDownContentRow href={path} target="_blank" rel="noopener noreferrer">
            <Icon path={icon} />
            {name}
          </DropDownContentRow>
        );
    }
  };

  return (
    <>
      {actions ? (
        <DropDownContent>
          {ACTIONS.filter(({ key }) => Boolean(actions[key])).map(({ name, icon, key }) => (
            <Fragment key={key}>{renderActionButton(name, icon, actions[key])}</Fragment>
          ))}
          <Dialog {...confirmationDialogProps()} />
        </DropDownContent>
      ) : (
        <DropDownContent>Nenhuma ação disponível</DropDownContent>
      )}
    </>
  );
};

ItemActions.propTypes = {
  onRequestAction: PropTypes.func,
  onDownloadAction: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    actions: PropTypes.shape({}),
    invoice: PropTypes.shape({
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

ItemActions.defaultProps = {
  onRequestAction: () => null,
  onDownloadAction: () => null,
  item: {
    id: null,
    actions: null,
    invoice: {
      number: null,
    },
  },
};

const mapDispatchToProps = {
  onRequestAction: requestNegotiationAction,
  onDownloadAction: downloadNegotiationAction,
};

export default connect(null, mapDispatchToProps)(ItemActions);
