import styled from 'styled-components';
import { media, FaEdit, handleThemeFromObject } from 'liber-components';

export const StepContainer = styled.div`
  position: relative;
  margin: 25px 0px;
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
`;

export const Button = styled.div`
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
  float: right;

  ${media.medium`
    height: 20px;
    width: 20px;
    margin-left: 25px;
    float: none;
  `}
`;

export const Icon = styled(FaEdit)`
  display: block;
  position: relative;
  height: inherit;
  width: inherit;
`;

export const Label = styled.div`
  color: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  fill: ${props => handleThemeFromObject(props.theme, 'colors.liberBlack', '#4e4e4e')};
  font-size: ${props => handleThemeFromObject(props.theme, 'fontSizes.h4', '24px')};
  font-weight: ${props => handleThemeFromObject(props.theme, 'fontWeights.bold', '700')};
  padding: 25px 0px;
`;
