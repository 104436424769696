import styled, { css } from 'styled-components';
import { Title as BaseTitle } from 'liber-components';

export const Container = styled.div`
  width: 488px;
  min-width: 100%;
  max-width: 100%;
  height: 523px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  ${({ shouldChangeDirection }) =>
    shouldChangeDirection &&
    css`
      @media (max-width: 620px) {
        flex-direction: column;
      }
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 32px;
    `}
`;

export const Header = styled(Row)`
  align-items: center;
  justify-content: space-between;
  padding: 14px 14px 14px 24px;
  border-bottom: 1px solid #ecf0f3;
`;

export const Title = styled(BaseTitle).attrs(() => ({ size: 'small' }))`
  max-width: calc(100% - 40px);
`;

export const IconButton = styled.button.attrs(() => ({ version: 2 }))`
  background-color: transparent;
  border: none;
  padding: 4px;
  border-radius: 50%;
  margin: -6px 0;

  :focus {
    outline: none;
  }

  & > svg {
    fill: rgba(35, 39, 47, 0.87);
    width: 20px;
    height: 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 24px;
  overflow-y: auto;
`;

export const InfoBlock = styled.div`
  width: 50%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (max-width: 620px) {
    width: 100%;
  }
`;

export const InfoLabel = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.06px;
  color: rgba(35, 39, 47, 0.87);
  margin-bottom: 8px;
`;

export const TextBox = styled.div`
  width: 100%;
  height: 166px;
  padding: 12px;
  overflow-y: auto;
  border: solid 1px rgba(35, 39, 47, 0.3);
  border-radius: 8px;
`;
