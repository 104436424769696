import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, TextField } from 'liber-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Countries, States, Cities } from '../../../../../vendor/Utils';
import { Row, ModalContent, ButtonsContainer, SubmitButton, HeaderRow } from '../../Base.styles';
import {
  editField as edit,
  submitForm as submit,
} from '../../../actions/account/InvestorAccountActions';

export const InvestorAccountForm = ({
  name,
  cnpj,
  email,
  cellphone,
  phone,
  gender,
  birthdate,
  civilStatus,
  countryOrigin,
  stateOrigin,
  cityOrigin,
  editField,
  submitForm,
  errors,
  loading,
  kind,
  company,
  tradeName,
}) => (
  <ModalContent>
    <HeaderRow>
      <h1>Editar Dados Pessoais</h1>
    </HeaderRow>
    {kind !== 'pf' ? (
      <Row>
        <div>
          <TextField
            label="Razão Social"
            name="investidor[company]"
            id="investidor_company"
            value={company}
            errorMessage={errors.company}
            onChange={value => {
              editField('company', value);
            }}
            width="100%"
          />
        </div>
        <div>
          <TextField
            label="Nome Fantasia"
            name="investidor[trade_name]"
            id="investidor_trade_name"
            value={tradeName}
            errorMessage={errors.tradeName}
            onChange={value => {
              editField('tradeName', value);
            }}
            width="100%"
          />
        </div>
      </Row>
    ) : null}
    <Row>
      <div>
        <TextField
          label="Nome para contato"
          name="investidor[name]"
          id="investidor_name"
          value={name}
          errorMessage={errors.name}
          onChange={value => {
            editField('name', value);
          }}
          width="100%"
        />
      </div>
      <div>
        <TextField
          label={kind === 'pf' ? 'CPF' : 'CNPJ'}
          name="investidor[cnpj]"
          id="investidor_cnpj"
          value={cnpj}
          errorMessage={errors.cnpj}
          mask={kind === 'pf' ? '999.999.999-99' : '99.999.999/9999-99'}
          maskChar={null}
          disabled
          width="100%"
        />
      </div>
    </Row>
    <Row>
      <div>
        <TextField
          label="Email"
          name="investidor[email]"
          id="investidor_email"
          errorMessage={errors.email}
          value={email}
          onChange={value => {
            editField('email', value);
          }}
          width="100%"
        />
      </div>
      <div>
        <TextField
          label="Celular"
          name="investidor[cellphone]"
          id="investidor_cellphone"
          errorMessage={errors.cellphone}
          onChange={value => {
            editField('cellphone', value);
          }}
          value={cellphone}
          mask={cellphone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
          maskChar={null}
          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
          width="100%"
        />
      </div>
      <div>
        <TextField
          label="Telefone (opcional)"
          name="investidor[phone]"
          id="investidor_phone"
          errorMessage={errors.phone}
          onChange={value => {
            editField('phone', value);
          }}
          value={phone}
          mask={phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-9999?'}
          maskChar={null}
          formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
          width="100%"
        />
      </div>
    </Row>
    {kind === 'pf' ? (
      <>
        <Row>
          <div>
            <SelectField
              label="Gênero"
              name="investidor[gender]"
              id="investidor_gender"
              value={gender.toLowerCase()}
              errorMessage={errors.gender}
              onChange={value => {
                editField('gender', value);
              }}
              width="100%"
            >
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
            </SelectField>
          </div>
          <div>
            <TextField
              label="Data de Nascimento"
              name="investidor[birthdate]"
              id="investidor_birthdate"
              hintText="DD/MM/AAAA"
              errorMessage={errors.birthdate}
              onChange={value => {
                editField('birthdate', value);
              }}
              value={birthdate}
              mask="99/99/9999"
              maskChar={null}
              width="100%"
            />
          </div>
          <div>
            <SelectField
              label="Estado Civil"
              name="investidor[civil-status]"
              id="investidor_civil_status"
              value={civilStatus.toLowerCase()}
              errorMessage={errors.civilStatus}
              onChange={value => {
                editField('civilStatus', value);
              }}
              width="100%"
            >
              <option value="solteiro">Solteiro(a)</option>
              <option value="casado">Casado(a)</option>
              <option value="divorciado">Divorciado(a)</option>
              <option value="viuvo">Viúvo(a)</option>
              <option value="separado">Separado(a)</option>
              <option value="uniao_estavel">União Estável</option>
            </SelectField>
          </div>
        </Row>
        <Row>
          <div>
            <SelectField
              label="País de Origem"
              searchable
              name="investidor[country-origin]"
              id="investidor_countryOrigin"
              errorMessage={errors.contryOrigin}
              value={countryOrigin}
              onChange={value => {
                editField('countryOrigin', value);
              }}
              width="100%"
            >
              {Object.keys(Countries).map(country => (
                <option key={_.uniqueId()} value={Countries[country]}>
                  {country}
                </option>
              ))}
            </SelectField>
          </div>
          {countryOrigin === 'BR' ? (
            <div>
              <SelectField
                searchable
                label="UF de Nascimento"
                name="investidor[state-origin]"
                id="investidor_stateOrigin"
                errorMessage={errors.stateOrigin}
                onChange={value => {
                  editField('stateOrigin', value, countryOrigin);
                }}
                value={stateOrigin}
                width="100%"
              >
                {Object.keys(States).map(st => (
                  <option key={_.uniqueId()} value={States[st]}>
                    {st}
                  </option>
                ))}
              </SelectField>
            </div>
          ) : (
            <div>
              <TextField
                label="UF de Nascimento"
                name="investidor[state-origin]"
                id="investidor_stateOrigin"
                errorMessage={errors.stateOrigin}
                onChange={value => {
                  editField('stateOrigin', value);
                }}
                value={stateOrigin}
                width="100%"
              />
            </div>
          )}
          {countryOrigin === 'BR' ? (
            <div>
              <SelectField
                searchable
                label="Cidade de Nascimento"
                name="investidor[city-origin]"
                id="investidor_cityOrigin"
                disabled={!stateOrigin}
                errorMessage={errors.cityOrigin}
                onChange={value => {
                  editField('cityOrigin', value);
                }}
                value={cityOrigin}
                width="100%"
              >
                {Cities[stateOrigin] ? (
                  Cities[stateOrigin].map(city => (
                    <option key={_.uniqueId()} value={city}>
                      {city}
                    </option>
                  ))
                ) : (
                  <option value="">Selecione uma cidade</option>
                )}
              </SelectField>
            </div>
          ) : (
            <div>
              <TextField
                label="Cidade de Nascimento"
                name="investidor[city-origin]"
                id="investidor_cityOrigin"
                errorMessage={errors.cityOrigin}
                onChange={value => {
                  editField('cityOrigin', value);
                }}
                value={cityOrigin}
                width="100%"
              />
            </div>
          )}
        </Row>
      </>
    ) : null}

    <ButtonsContainer>
      <SubmitButton
        loading={loading}
        onClick={() =>
          submitForm({
            name,
            cnpj,
            email,
            cellphone,
            phone,
            gender,
            birthdate,
            civilStatus,
            countryOrigin,
            stateOrigin,
            cityOrigin,
            company,
            tradeName,
            kind,
          })
        }
      >
        Enviar
      </SubmitButton>
    </ButtonsContainer>
  </ModalContent>
);

InvestorAccountForm.propTypes = {
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cnpj: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  cellphone: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  gender: PropTypes.string,
  birthdate: PropTypes.string,
  civilStatus: PropTypes.string,
  countryOrigin: PropTypes.string,
  stateOrigin: PropTypes.string,
  cityOrigin: PropTypes.string,
  company: PropTypes.string,
  tradeName: PropTypes.string,
  editField: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
};

InvestorAccountForm.defaultProps = {
  company: '',
  tradeName: '',
  gender: '',
  birthdate: '',
  civilStatus: '',
  countryOrigin: '',
  stateOrigin: '',
  cityOrigin: '',
};

const parentReducer = 'myAccount';
const tab = 'account';
const type = 'investor';
const reducer = 'form';

const mapDispatchToProps = {
  editField: edit,
  submitForm: submit,
};

const mapStateToProps = state => ({ ...state[parentReducer][tab][type][reducer] });

export default connect(mapStateToProps, mapDispatchToProps)(InvestorAccountForm);
