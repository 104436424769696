import styled from 'styled-components';
import { LoadingBunny, Button as ButtonBase } from 'liber-components';
import Icon from '@mdi/react';
import { mdiEye } from '@mdi/js';

export const TEXT_COLOR = '#6287a7';

export const DetailsIcon = styled(Icon).attrs(() => ({
  color: TEXT_COLOR,
  path: mdiEye,
  size: '20px',
}))``;

export const IconButton = styled(ButtonBase).attrs(() => ({
  version: 2,
  as: 'a',
}))`
  padding: 4px;
  border-radius: 50%;
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  background-color: white;
  transition: opacity 150ms ease-in-out;
  z-index: 201;
  position: relative;
`;

export const Bunny = styled(LoadingBunny).attrs(() => ({ loop: true }))`
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 202;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 160px;
  margin-bottom: -80px;
`;

export const LimiterContainer = styled.div`
  width: 100%;
  min-width: 930px;
`;
