import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CompleteTable } from 'liber-components';
import {
  SpaceBetween,
  Title,
  AddLink,
  Root,
  ButtonsRow,
  OutlinedButton,
  OutlinedLink,
} from './AdminVendors.styles';
import { NEW_VENDOR_PATH, SCHEDULES_PATH } from '../urls';
import {
  fetchVendors,
  updateSorting,
  changeListing,
  changeToNextPage,
  changeToPreviousPage,
  setPage,
  setSchedule,
  blockVendor,
  unblockVendor,
  setSelectedVendors,
  changeShowSchedulesModal,
  updateSelectedVendorId,
  updateSelectedVendorBuyerId,
  setShowBatchBlockUnblockModal,
} from '../actions/AdminVendorsActions';
import usePageState from '../../../hooks/usePageState';
import { paginationShape, getPaginationInterval } from '../../../vendor/Utils';
import Actions from './Actions/Actions';
import SortingDialog from './SortingDialog/SortingDialog';
import VendorFilters from './VendorFilters/VendorFilters';
import ScheduleModal from './VendorScheduleModal/VendorModal';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import FundersButton from './FundersButton';
import FundersDrawer from './FundersDrawer';
import VendorBlockUnblockModal from './VendorBlockUnblockModal';
import HandleIntermediateStatusModal from './HandleBlockUnblockModal';
import Loading from '../../../common-components/Loading';
import BatchBlockUnblockModal from './BatchBlockUnblockModal';

const NEXT_DIRECTION = {
  asc: 'desc',
  desc: 'asc',
};

const columns = (setVendorToBlock, setVendorToUnblock) => [
  {
    name: 'vendor',
    label: 'Fornecedor',
    width: 30,
    groupedColumns: ['company', 'tradeName', 'cnpj'],
    getCellValue: vendor => {
      const { cnpj, tradeName, company } = vendor;
      return (
        <>
          {company}
          <br />
          {tradeName}
          <br />
          {cnpj}
        </>
      );
    },
  },
  {
    name: 'sacado_company',
    label: 'Sacado',
    width: 25,
    getCellValue: vendor => {
      const {
        buyer: { company },
      } = vendor;
      return company;
    },
  },
  {
    name: 'funders',
    label: 'Situação Cadastral ',
    width: 12,
    getCellValue: vendor => {
      const {
        id,
        blockedByBuyer,
        funders: { total, active },
        fundersIds,
        buyer,
      } = vendor;
      const funderIdsLength = fundersIds.length;
      return (
        <FundersButton
          vendorId={id}
          buyerId={buyer.id}
          activeFunders={active}
          totalFunders={total}
          blockedByBuyer={blockedByBuyer}
          disabled={funderIdsLength < 1}
        />
      );
    },
  },
  {
    name: 'politica_assinatura_termo_cessao',
    label: 'Modos de Assinatura',
    width: 15,
    getCellValue: vendor => {
      const {
        signature: { type, method },
      } = vendor;
      return (
        <>
          {method}
          <br />
          {type}
        </>
      );
    },
  },
  {
    name: 'actions',
    label: 'Ações',
    width: 12,
    align: 'center',
    getCellValue: vendor => (
      <Actions
        setVendorToBlock={setVendorToBlock}
        setVendorToUnblock={setVendorToUnblock}
        vendor={vendor}
      />
    ),
  },
];

export const AdminVendors = ({
  vendors,
  getVendors,
  pagination,
  filters,
  sorting,
  changeSorting,
  setListing,
  goToNextPage,
  goToPreviousPage,
  goToPage,
  vendorBlock,
  vendorUnblock,
  loading,
  showSchedules,
  selectedVendors,
  updateSelectedVendors,
  selectedVendorId,
  setSelectedVendorId,
  selectedVendorsFundersIds,
  updateShowBatchBlockUnblockModal,
  selectedBatchBlockUnblockFile,
  token,
}) => {
  const { current, per: pageListing, count: vendorTotal, pages } = pagination;

  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, goToPage);
  const [openDialog, setOpenDialog] = useState(false);
  const [vendorToBlock, setVendorToBlock] = useState(null);
  const [vendorToUnblock, setVendorToUnblock] = useState(null);

  let selectionTableProps = {};

  const isItemSelected = item => selectedVendors.includes(item);
  const onSelect = item => {
    if (!isItemSelected(item)) updateSelectedVendors([item, ...selectedVendors]);
    else {
      const currentSelected = [...selectedVendors];
      currentSelected.splice(currentSelected.indexOf(item), 1);
      updateSelectedVendors([...currentSelected]);
    }
  };

  if (showSchedules) {
    selectionTableProps = {
      onSelectAll: () => {
        if (selectedVendors.length === vendors.length) {
          updateSelectedVendors([]);
        } else {
          updateSelectedVendors([...vendors]);
        }
      },
      allChecked: selectedVendors.length === vendors.length,
      isItemSelected,
      onSelect,
    };
  }

  React.useEffect(() => {
    getVendors();
  }, [currentPage, pageListing, filters, sorting, showSchedules]);

  const isItemSelectable = ({ fundersIds }) => {
    if (fundersIds.length === 0) return false;
    if (selectedVendorsFundersIds.length < 1) return true;
    return fundersIds.some(id => selectedVendorsFundersIds.includes(id));
  };

  const handleSorting = (column, sortInfo = {}) => {
    if (column.name === 'vendor') {
      setOpenDialog(true);
    } else {
      const { columnName = column.name, direction } = sortInfo;
      changeSorting({ columnName, direction: NEXT_DIRECTION[direction] || 'asc' });
    }
  };

  const handleBlockVendor = reason => {
    vendorBlock(vendorToBlock, reason).then(() => setVendorToBlock(null));
  };

  const handleUnblockVendor = reason => {
    vendorUnblock(vendorToUnblock, reason).then(() => setVendorToUnblock(null));
  };

  return (
    <>
      <FundersDrawer
        open={!!selectedVendorId}
        onLeaved={() => setSelectedVendorId(null)}
        token={token}
      />
      <ScheduleModal />
      <BatchBlockUnblockModal isBatch={!!selectedBatchBlockUnblockFile} />
      {vendorToBlock && (
        <ConfirmModal
          name={vendorToBlock?.tradeName}
          onLeaved={() => setVendorToBlock(null)}
          handleConfirm={handleBlockVendor}
        />
      )}
      {vendorToUnblock && (
        <ConfirmModal
          unblock
          name={vendorToUnblock?.tradeName}
          onLeaved={() => setVendorToUnblock(null)}
          handleConfirm={handleUnblockVendor}
        />
      )}
      <VendorBlockUnblockModal />
      <HandleIntermediateStatusModal />
      <Loading isLoading={loading}>
        <Root>
          <SpaceBetween>
            <Title>Fornecedores</Title>
            <ButtonsRow>
              <OutlinedButton onClick={() => updateShowBatchBlockUnblockModal(true)}>
                Bloqueio e desbloqueio em lote
              </OutlinedButton>
              {showSchedules && (
                <OutlinedLink href={SCHEDULES_PATH}>Agendamentos Feitos</OutlinedLink>
              )}
              <AddLink href={NEW_VENDOR_PATH}>Adicionar Fornecedor</AddLink>
            </ButtonsRow>
          </SpaceBetween>
          <CompleteTable
            columns={columns(setVendorToBlock, setVendorToUnblock)}
            items={vendors}
            sortingProps={{
              onSort: handleSorting,
              sorting,
              sortedColumns: ['vendor', 'politica_assinatura_termo_cessao', 'sacado_company'],
            }}
            {...selectionTableProps}
            filters={<VendorFilters />}
            paginationProps={{
              currentPage,
              pageTotal: pages,
              onChangePage: ({ target: { value } }) => setPageState(value),
              onClickNext: goToNextPage,
              onClickPrev: goToPreviousPage,
              onKeyDownPage,
              hasListing: true,
              listingProps: {
                listing: String(pageListing),
                total: String(vendorTotal),
                showInterval: getPaginationInterval(pagination),
                onSelectListing: setListing,
                listingLabelSingular: 'fornecedor',
                listingLabelPlural: 'fornecedores',
              },
            }}
            isItemSelectable={isItemSelectable}
          />
          <SortingDialog open={openDialog} onClose={() => setOpenDialog(false)} />{' '}
        </Root>
      </Loading>
    </>
  );
};

AdminVendors.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.object),
  getVendors: PropTypes.func,
  pagination: paginationShape,
  filters: PropTypes.shape({}),
  sorting: PropTypes.arrayOf(PropTypes.object),
  changeSorting: PropTypes.func,
  setListing: PropTypes.func,
  goToNextPage: PropTypes.func,
  goToPreviousPage: PropTypes.func,
  goToPage: PropTypes.func,
  setAppointment: PropTypes.func,
  vendorBlock: PropTypes.func,
  vendorUnblock: PropTypes.func,
  loading: PropTypes.bool,
  showSchedules: PropTypes.bool,
  selectedVendors: PropTypes.arrayOf(PropTypes.any),
  updateSelectedVendors: PropTypes.func,
  showSchedulesModal: PropTypes.bool,
  updateShowSchedulesModal: PropTypes.func,
  selectedVendorId: PropTypes.number,
  setSelectedVendorId: PropTypes.func,
  selectedVendorsFundersIds: PropTypes.arrayOf(PropTypes.number),
  updateShowBatchBlockUnblockModal: PropTypes.func,
  selectedBatchBlockUnblockFile: PropTypes.shape({
    name: PropTypes.string,
  }),
  token: PropTypes.string.isRequired,
};

AdminVendors.defaultProps = {
  vendors: [],
  getVendors: () => null,
  pagination: {},
  filters: {},
  sorting: [],
  changeSorting: () => null,
  setListing: () => null,
  goToPreviousPage: () => null,
  goToNextPage: () => null,
  goToPage: () => null,
  setAppointment: () => null,
  vendorBlock: () => null,
  vendorUnblock: () => null,
  loading: false,
  showSchedules: false,
  selectedVendors: [],
  updateSelectedVendors: () => null,
  showSchedulesModal: false,
  updateShowSchedulesModal: () => null,
  selectedVendorId: null,
  setSelectedVendorId: () => null,
  selectedVendorsFundersIds: [],
  updateShowBatchBlockUnblockModal: () => null,
  selectedBatchBlockUnblockFile: null,
};

const mapDispatchToProps = {
  getVendors: fetchVendors,
  changeSorting: updateSorting,
  setListing: changeListing,
  goToNextPage: changeToNextPage,
  goToPreviousPage: changeToPreviousPage,
  goToPage: setPage,
  setAppointment: setSchedule,
  vendorBlock: blockVendor,
  vendorUnblock: unblockVendor,
  updateSelectedVendors: setSelectedVendors,
  updateShowSchedulesModal: changeShowSchedulesModal,
  setSelectedVendorId: updateSelectedVendorId,
  setSelectedVendorBuyerId: updateSelectedVendorBuyerId,
  updateShowBatchBlockUnblockModal: setShowBatchBlockUnblockModal,
};

const mapStateToProps = ({
  adminVendors: {
    vendors,
    pagination,
    filters,
    sorting,
    loading,
    showSchedules,
    selectedVendors,
    showSchedulesModal,
    selectedVendorId,
    selectedVendorsFundersIds,
    selectedBatchBlockUnblockFile,
    token,
  },
}) => ({
  vendors,
  pagination,
  filters,
  sorting,
  loading,
  showSchedules,
  selectedVendors,
  showSchedulesModal,
  selectedVendorId,
  selectedVendorsFundersIds,
  selectedBatchBlockUnblockFile,
  token,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminVendors);
