import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'liber-components';
import Icon from '@mdi/react';
import { mdiClose, mdiPen, mdiInformation, mdiCheck } from '@mdi/js';
import { connect } from 'react-redux';

import { AnticipationPropTypes, AnticipationDefaultPropTypes } from '../../propTypes';
import { handleNavigate } from '../../../../vendor/Utils';
import { SIGN_PAGE_URL } from '../../urls';

import {
  Container,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Title,
  IconButton,
  SignButton,
  CloseButton,
  CountContainer,
  Spacer,
  BodyText,
  SubTitle,
  RepresentantsContainer,
  RepresentantsList,
  RepresentantRow,
  MutedText,
  DescriptionContainer,
  DescriptionText,
  SignedChip,
  ChipIconWrapper,
  SignedCheckmark,
} from './SignatureDrawer.styles';

export const SignatureDrawer = ({ advance, open, onClose, timeCut }) => {
  const {
    signatures: { required: requiredSignatures, done: doneSignatures },
    legalAgents,
    id,
    status,
    userHasSigned,
  } = advance;

  const isDocumentGenerated = status !== 'created';

  const enoughSigned = requiredSignatures === doneSignatures;

  const handleConfirmClick = () => {
    handleNavigate(`${SIGN_PAGE_URL}?id=${id}&generated=${isDocumentGenerated}`);
  };

  return (
    <Drawer show={open} onLeaved={onClose} position="right" size={640}>
      <Container>
        <DialogHeader>
          <Title>Assinaturas da antecipação {id}</Title>
          <IconButton onClick={onClose}>
            <Icon path={mdiClose} />
          </IconButton>
        </DialogHeader>
        <DialogContent>
          <CountContainer>
            <div>
              <BodyText>Assinaturas coletadas</BodyText>
              <Title data-testid="DoneSignatures">
                {doneSignatures}
                {enoughSigned && <SignedCheckmark path={mdiCheck} />}
              </Title>
            </div>
            <Spacer />
            <div>
              <BodyText>Assinaturas necessárias</BodyText>
              <Title data-testid="RequiredSignatures">{requiredSignatures}</Title>
            </div>
          </CountContainer>
          <RepresentantsContainer>
            <SubTitle>Representantes</SubTitle>
            <RepresentantsList>
              {legalAgents.map(agent => (
                <RepresentantRow key={agent.fullName}>
                  <BodyText>{agent.fullName}</BodyText>
                  {agent.userHasSigned ? (
                    <>
                      <MutedText>(em {agent.userSignedAt})</MutedText>
                      <SignedChip signed>Assinado</SignedChip>
                    </>
                  ) : (
                    <SignedChip>
                      <ChipIconWrapper>
                        <Icon path={mdiInformation} />
                      </ChipIconWrapper>
                      Pendente
                    </SignedChip>
                  )}
                </RepresentantRow>
              ))}
            </RepresentantsList>
          </RepresentantsContainer>
          <DescriptionContainer>
            {enoughSigned ? (
              <DescriptionText>
                Todas as assinaturas necessárias para esta antecipação foram coletadas.
              </DescriptionText>
            ) : (
              <>
                <DescriptionText>
                  Ainda faltam mais <b>{requiredSignatures - doneSignatures}</b> assinaturas.
                </DescriptionText>
                <DescriptionText>
                  Assim que todas as assinaturas forem coletadas, o pagamento será realizado em até
                  1 dia útil.
                </DescriptionText>
                <DescriptionText>
                  Obs.: a <b>negociação será cancelada</b> se todas as assinaturas necessárias não
                  forem coletadas até {timeCut || '22:00h'}.
                </DescriptionText>
              </>
            )}
          </DescriptionContainer>
        </DialogContent>
        <DialogFooter>
          <CloseButton onClick={onClose}>FECHAR</CloseButton>
          {!enoughSigned && !userHasSigned && (
            <SignButton onClick={handleConfirmClick}>
              <Icon path={mdiPen} />
              ASSINAR ANTECIPAÇÃO
            </SignButton>
          )}
        </DialogFooter>
      </Container>
    </Drawer>
  );
};

SignatureDrawer.propTypes = {
  advance: AnticipationPropTypes,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  timeCut: PropTypes.string,
};

SignatureDrawer.defaultProps = {
  advance: AnticipationDefaultPropTypes,
  open: false,
  onClose: () => undefined,
  timeCut: '22:00h',
};

const mapStateToProps = ({ vendorAdvances: { timeCut } }) => ({
  timeCut,
});

export default connect(mapStateToProps, null)(SignatureDrawer);
