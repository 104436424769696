export const extractInvoices = (store, path = 'vendorInvoices') => {
  const state = store.getState();
  const { invoices } = state[path];
  return invoices;
};

export const extractSelectedItems = (store, path = 'vendorInvoices') => {
  const state = store.getState();
  const { selectedInvoices } = state[path];
  return selectedInvoices;
};
