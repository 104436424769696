import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CNPJ_FILTER, SACADO_NAME_FILTER, GROUP_NAME_FILTER } from './constants';
import usePagination from '../../hooks/usePagination';
import usePageState from '../../hooks/usePageState';
import { fetchSpreadsheets } from './api';
import { convertToCamelCase, handleError } from '../../vendor/Utils';

export const SpreadsheetRatesContext = createContext({});

export const SpreadsheetRatesContextProvider = ({ children, token, activeRates }) => {
  const [spreadsheets, setSpreadsheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedSpreadsheetId, setSelectedSpreadsheetId] = useState(null);
  const [filters, setFilters] = useState({
    [CNPJ_FILTER]: null,
    [SACADO_NAME_FILTER]: null,
    [GROUP_NAME_FILTER]: null,
  });

  const { pagination, setCurrent, onSelectListing, updatePagination } = usePagination();
  const { pages, current, next, previous, count, per } = pagination;
  const [currentPage, setPageState, onKeyDownPage] = usePageState(current, pages, setCurrent);

  const updateSheets = () => {
    setLoading(true);
    fetchSpreadsheets(token, activeRates, { page: current, per, ...filters })
      .then(({ data }) => {
        const { spreadsheetRates, pagination: incomingPagination } = convertToCamelCase(data);
        if (incomingPagination) {
          updatePagination({ ...incomingPagination, per });
        }
        setSpreadsheets(spreadsheetRates);
      })
      .catch(error => {
        handleError('Erro!', error?.response?.data?.error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    updateSheets();
  }, [per, current, filters]);

  const updateFilter = key => value => {
    setFilters({ ...filters, [key]: value });
  };

  const clearFilter = key => () => {
    setFilters({ ...filters, [key]: null });
  };

  const clearAllFilters = () => {
    setFilters({
      [CNPJ_FILTER]: null,
      [SACADO_NAME_FILTER]: null,
      [GROUP_NAME_FILTER]: null,
    });
  };

  return (
    <SpreadsheetRatesContext.Provider
      value={{
        token,
        filters,
        setFilters,
        pagination,
        setCurrent,
        onSelectListing,
        updatePagination,
        pages,
        current,
        next,
        previous,
        count,
        per,
        currentPage,
        setPageState,
        onKeyDownPage,
        loading,
        spreadsheets,
        updateFilter,
        clearFilter,
        clearAllFilters,
        showDetailsModal,
        setShowDetailsModal,
        selectedSpreadsheetId,
        setSelectedSpreadsheetId,
      }}
    >
      {children}
    </SpreadsheetRatesContext.Provider>
  );
};

SpreadsheetRatesContextProvider.propTypes = {
  children: PropTypes.node,
  token: PropTypes.string,
  activeRates: PropTypes.bool,
};

SpreadsheetRatesContextProvider.defaultProps = {
  children: null,
  token: '',
  activeRates: true,
};
