import Immutable from 'seamless-immutable';

const initialState = Immutable({
  fetched: false,
  isFetching: false,
  success: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOGIN/CONFIRMATION/TOGGLE_FETCH':
      return state.merge({ isFetching: !state.isFetching, fetched: state.isFetching === true });
    case 'LOGIN/CONFIRMATION/RESEND_EMAIL/SUCCESSFULL_SUBMIT':
      return state.merge({ success: true });
    case 'LOGIN/CONFIRMATION/RESEND_EMAIL/FAILED_SUBMIT':
      return state.merge({ success: false });
    default:
      return state;
  }
};
