import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Label = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: rgba(75, 111, 133, 0.6);
  margin-right: 8px;
`;

export const Value = styled.span`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: ${({ valueColor }) => valueColor || '#4b6f85'};
`;
