import React, { useState, useEffect, useCallback, useContext } from 'react';
import { TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import ScreenTitle from '../../../../common-components/ScreenTitle';
import { Container, Subtitle, Row, Button, OutlinedButton, IconButton } from './styles';
import { CREATE_VENDORS_GROUP_PATH, VENDORS_PATH } from '../../constants';
import GroupsTable from '../GroupsTable';
import ArchiveModal from '../ArchiveModal';
import UploadModal from '../UploadModal';
import ErrorModal from '../ErrorModal';
import { createSubscription } from '../../../../vendor/action-cable';
import { VendorsGroupsContext } from '../../context';
import {
  handleError,
  handleSuccess,
  convertToCamelCase,
  handleNavigate,
  dispatchAmplitudeEvent,
} from '../../../../vendor/Utils';

export default function VendorsGroups() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [failedCnpjs, setFailedCnpjs] = useState([]);

  const {
    roomId,
    updateVendorsGroups,
    archiveGroupModalOpen,
    setArchiveGroupModalOpen,
    setCount,
    count,
  } = useContext(VendorsGroupsContext);

  const onBroadcastReceived = useCallback(
    broadcast => {
      const {
        data: { groupName, cnpjs },
        type,
      } = convertToCamelCase(broadcast);
      if (type === 'VENDORS_GROUP_SUCCESS') {
        dispatchAmplitudeEvent('buyer_vendors_groups_import_group_success', { name: groupName });
      } else {
        dispatchAmplitudeEvent('buyer_vendors_groups_import_group_fail', { type });
      }
      switch (type) {
        case 'VENDORS_GROUP_SUCCESS': {
          handleSuccess(`Grupo ${groupName} criado com sucesso!`, '');
          updateVendorsGroups();
          break;
        }
        case 'VENDORS_GROUP_ERROR': {
          handleError(
            'Erro na criação do grupo',
            'Houve um problema ao fazer o upload do arquivo. Por favor, atualize a página e tente novamente.',
          );
          break;
        }
        case 'VENDORS_GROUP_EMPTY_NAME': {
          handleError(
            'Erro na criação do grupo',
            'O campo ”Nome do grupo” não pode ser vazio. Por favor, corrija a planilha e tente novamente.',
          );
          break;
        }
        case 'VENDORS_GROUP_EXISTING_REFERENCE': {
          handleError(
            'Erro na criação do grupo',
            `Já existe um grupo cadastrado com o mesmo código 
            de referência. Por favor, corrija a página e tente novamente.`,
          );
          break;
        }
        case 'VENDORS_GROUP_CNPJS_NOT_FOUND': {
          setShowErrorModal(true);
          setFailedCnpjs(cnpjs);
          break;
        }
        default:
          break;
      }
    },
    [setShowUploadModal],
  );

  const checkUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const toastMessage = params.get('toastMessage');
    if (toastMessage) {
      handleSuccess('Sucesso!', toastMessage);
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  const handleCreateButton = () => {
    dispatchAmplitudeEvent('buyer_vendors_groups_create_group');
    handleNavigate(CREATE_VENDORS_GROUP_PATH);
  };

  const handleImportButton = () => {
    dispatchAmplitudeEvent('buyer_vendors_groups_import_group');
    setShowUploadModal(true);
  };

  useEffect(() => {
    createSubscription('VendorsGroupChannel', roomId, {
      received: onBroadcastReceived,
      connectionFailed: () => console.log('Unable to connect to action cable!'),
    });
    checkUrlParams();
  }, []);

  return (
    <Container>
      <Row>
        <TooltipBox mount="top" fixed content="Voltar">
          <IconButton onClick={() => handleNavigate(VENDORS_PATH)}>
            <Icon path={mdiArrowLeft} />
          </IconButton>
        </TooltipBox>
        <ScreenTitle>Grupos de Fornecedores</ScreenTitle>
        <OutlinedButton onClick={() => handleCreateButton()}>CRIAR GRUPO</OutlinedButton>
        <Button onClick={() => handleImportButton()}>IMPORTAR GRUPO</Button>
      </Row>
      <Subtitle>
        Você possui {count} grupos cadastrados.
        <br />
        Verifique a seguir as suas informações e edite se necessário.
      </Subtitle>
      <GroupsTable setCount={setCount} />
      <UploadModal show={showUploadModal} onLeaved={() => setShowUploadModal(false)} />
      <ErrorModal
        show={showErrorModal}
        failedCnpjs={failedCnpjs}
        onLeaved={() => setShowErrorModal(false)}
      />
      <ArchiveModal show={archiveGroupModalOpen} onLeaved={() => setArchiveGroupModalOpen(false)} />
    </Container>
  );
}
