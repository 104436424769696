import styled, { css } from 'styled-components';
import {
  handleThemeFromObject as getTheme,
  FaCheckCircle,
  FaTimesCircle,
  LoadingBunny,
  TableBody,
  TableRow as RawTableRow,
  hexToRgb,
  Checkbox,
} from 'liber-components';
import { Info as SummaryInfo, TriangleNotice as SummaryNotice } from '../Summary/Summary.styles';

export { ButtonContainer } from '../Summary/Summary.styles';

export {
  TableHeaderColumn,
  Checkbox,
  CheckboxColumn,
  TableRowColumn,
} from '../MultiProposal/MultiProposal.styles';

export const TableRow = styled(RawTableRow)`
  ${props => {
    if (props.error) {
      return css`
        ${TableBody} & {
          background-color: ${hexToRgb(
            getTheme(props.theme, 'colors.v2.red25', '#f6cbcf'),
            '0.3',
          )}};
          &:nth-of-type(even) {
            background-color: ${`${hexToRgb(
              getTheme(props.theme, 'colors.v2.red25', '#f6cbcf'),
              '0.5',
            )}!important`}};
          }
          &:hover{
            background-color: ${`${hexToRgb(
              getTheme(props.theme, 'colors.v2.red25', '#f6cbcf'),
              '0.7',
            )}!important`}};
          }
        }
      `;
    }
    return '';
  }}
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.h5', '20px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.bold', '700')};
  height: 43px;
  border-bottom: 1px solid ${props => getTheme(props.theme, 'colors.v2.gray2', '#dce8f0')};
`;

export const DialogContent = styled.div`
  width: 715px;
  min-height: 490px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

export const DialogBody = styled.div`
  flex-grow: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;
`;

export const Desc = styled.div`
  color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.h6', '18px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.medium', '500')};
  text-align: center;
  margin-bottom: 8px;
`;

export const FunderTag = styled.div`
  padding: 8px 24px;
  background-color: ${props => getTheme(props.theme, 'colors.v2.gray1', '#EAF7FF')};
  border-radius: 6px;
  color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.h4', '24px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.bold', '700')};
  margin: 0px auto 24px auto;
  text-align: center;
`;

export const Info = styled(SummaryInfo)`
  font-size: ${props => getTheme(props.theme, 'fontSizes.p', '16px')};
  margin-bottom: 24px;
`;
Info.defaultProps = {
  selected: true,
};

export const TriangleNotice = styled(SummaryNotice)`
  --size: 24px;
`;

export const Total = styled.div`
  margin-top: 8px;
  margin-left: auto;
  margin-bottom: 32px;
  border-top: 1px solid ${props => getTheme(props.theme, 'colors.v2.gray3', '#c6d1d8')};
  padding: 8px;
  color: ${props => getTheme(props.theme, 'colors.v2.gray3', '#c6d1d8')};
  font-size: ${props => getTheme(props.theme, 'fontSizes.h5', '20px')};
  line-height: ${props => getTheme(props.theme, 'fontSizes.h5', '20px')};
  font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
  font-weight: ${props => getTheme(props.theme, 'fontWeights.medium', '500')};
  & span {
    color: ${props => getTheme(props.theme, 'colors.v2.petroleum', '#4b6f85')};
  }
`;

export const Check = styled(FaCheckCircle)`
  --size: 18px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => getTheme(props.theme, 'colors.v2.lightGreen', '#13CE66')};
  margin-right: 8px;
`;
export const Times = styled(FaTimesCircle)`
  --size: 18px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  fill: ${props => getTheme(props.theme, 'colors.v2.red', '#dc3545')};
  margin-right: 8px;
`;

export const LoadingBox = styled.div`
  max-width: 100%;
  width: 770px;
  height: 333px;
  border-left: 7px solid ${props => getTheme(props.theme, 'colors.liberBlue', '#009dff')};
  border-right: 7px solid ${props => getTheme(props.theme, 'colors.liberBlue', '#009dff')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 62px;
  box-sizing: border-box;
  & span {
    text-align: center;
  }

  & h1 {
    color: ${props => getTheme(props.theme, 'colors.v2.liberBlue', '#009DFF')};
    font-size: ${props => getTheme(props.theme, 'fontSizes.h3', '32px')};
    font-family: ${props => getTheme(props.theme, 'fontFamily', 'Roboto')};
    font-weight: ${props => getTheme(props.theme, 'fontWeights.bold', '700')};
  }
`;

export const Bunny = styled(LoadingBunny)`
  width: 200px;
  height: 100px;
  fill: none;
  stroke: ${props => getTheme(props.theme, 'colors.liberBlue', '#009dff')};
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2.52px;
`;

Bunny.defaultProps = {
  loop: true,
};

export const TableContainer = styled.div`
  max-height: 400px;
  min-height: 100px;
  overflow: auto;
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ${Total} {
    margin-left: 0px;
  }

  & ${Total} span {
    line-height: 28px;
  }

  & ${Total}:last-child {
    text-align: right;
  }
  & ${Total}:first-child {
    text-align: left;
    border-top: 1px solid ${props => getTheme(props.theme, 'colors.v2.red', '#dc3545')};
    color: ${props => getTheme(props.theme, 'colors.v2.red', '#dc3545')};
  }
`;

export const TooltipContentCustom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  color: #6287a7;
`;

export const CheckboxCustom = styled(Checkbox)`
  margin-top: 0;
  margin-bottom: 16px;
`;

export const ButtonNewProposta = styled.div`
  margin-top: 1rem;
`;

export const ToggleButton = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #007bff;
  margin-top: 1rem; /* Equivalent to mt-3 mt-sm-0 */
  font-size: 1rem;

  i {
    margin-left: 0.5rem;
    color: #007bff;
  }
`;
export const CollapseContent = styled.div`
  margin-top: 1rem;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const Input = styled.input`
  display: block;
  margin-top: 0.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;
export const RadioLabel = styled.label`
  margin-right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;
