import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiChevronLeft, mdiChevronRight, mdiPencil } from '@mdi/js';

import ScreenTitle from '../../../../common-components/ScreenTitle';
import TransferList from '../TransferList';
import ConfirmGroupEdit from '../Alerts/ConfirmGroupEdit';
import {
  Container,
  SectionTitle,
  Row,
  EditButton,
  IconButton,
  TextInput,
  InputWrapper,
  TransferListsContainer,
  TransferListsTitleContainer,
  TransfersListRow,
  Button,
  CenterContainer,
  HintText,
  FooterActions,
  ButtonRight,
  ButtonOutline,
  InfoSpacer,
} from './styles';
import { CreateVendorsGroupContext } from '../../context';
import LoadingButton from '../../../../common-components/Buttons/LoadingButton';
import OperationModeCard, { Info } from '../../../../common-components/OperationModeCard';
import EditOperationMode from '../../../../common-components/EditOperationMode';
import ConfirmOpModeEdit from '../Alerts/ConfirmOpModeEdit';
import {
  ReferenceLabel,
  ReferenceValue,
} from '../../../vendors-group-details/components/VendorsGroupDetails/styles';

export default function VendorsGroups() {
  const [showConfirmOpModeEdit, setShowConfirmOpModeEdit] = useState(false);
  const [temporaryOperationMode, setTemporaryOperationMode] = useState({});

  const {
    groupLoading,
    name,
    reference,
    funderTargetingMode,
    selectedFunderId,
    handleChangeReference,
    setName,
    setFunderTargetingMode,
    setSelectedFunderId,
    selectedBuyerVendors,
    funders,
    groupVendors,
    selectedGroupVendors,
    referenceError,
    isEditing,
    handleConfirm,
    moveLeftToRight,
    moveRightToLeft,
    alertModal,
    setAlertModal,
    movedVendors,
    handleConfirmAlert,
    handleSelectBuyerVendors,
    handleSelectGroupVendors,
    buyerVendors,
    loadingBuyerVendors,
    loadingGroupVendors,
    firstLoadingBuyer,
    firstLoadingGroup,
    vendorsCount,
    groupVendorsCount,
    loadingAction,
    hasChanges,
    groupId,
    isOnlyEditingOpMode,
  } = useContext(CreateVendorsGroupContext);

  const [isEditingOperationMode, setIsEditingOperationMode] = useState(isOnlyEditingOpMode);

  const submitButtonEnabled = useMemo(
    () => name.length > 0 && hasChanges && (groupVendors.length > 0 || isEditing),
    [name, groupVendors, hasChanges],
  );

  const funder = useMemo(
    () => funders.find(({ id }) => id === selectedFunderId),
    [funders, selectedFunderId],
  );

  const groupIdForRates = useMemo(
    () => parseInt(groupId, 10) || -1, // -1 for inexistent group if it's a group creation
    [groupId],
  );

  const handleCancelConfirmOpModeEdit = () => {
    if (isOnlyEditingOpMode) {
      window.history.back();
    } else {
      setShowConfirmOpModeEdit(false);
      setIsEditingOperationMode(false);
    }
  };

  const handleConfirmOpModeEdit = () => {
    const { newFunderTargetingMode, newSelectedFunderId } = temporaryOperationMode;
    setFunderTargetingMode(newFunderTargetingMode);
    setSelectedFunderId(newSelectedFunderId);
    if (isOnlyEditingOpMode) {
      handleConfirm({ newFunderTargetingMode, newSelectedFunderId });
    } else {
      setShowConfirmOpModeEdit(false);
      setIsEditingOperationMode(false);
    }
  };

  const handleCancelEditOperationMode = ({
    funderTargetingMode: newFunderTargetingMode,
    selectedFunderId: newSelectedFunderId,
  }) => {
    const isTargetingDifferent = newFunderTargetingMode !== funderTargetingMode;
    const isFunderDifferent = (newSelectedFunderId ?? null) !== (selectedFunderId ?? null);

    if (isTargetingDifferent || isFunderDifferent) {
      setTemporaryOperationMode({ newFunderTargetingMode, newSelectedFunderId });
      setShowConfirmOpModeEdit(true);
    } else if (isOnlyEditingOpMode) {
      window.history.back();
    } else {
      setTemporaryOperationMode({});
      setIsEditingOperationMode(false);
    }
  };

  const handleSaveOperationMode = ({
    funderTargetingMode: newFunderTargetingMode,
    selectedFunderId: newSelectedFunderId,
  }) => {
    setFunderTargetingMode(newFunderTargetingMode);
    setSelectedFunderId(newSelectedFunderId);
    if (isOnlyEditingOpMode) {
      handleConfirm({ newFunderTargetingMode, newSelectedFunderId });
    } else {
      setIsEditingOperationMode(false);
    }
  };

  useEffect(() => {
    if (isOnlyEditingOpMode) setIsEditingOperationMode(true);
  }, [isOnlyEditingOpMode]);

  const submitButton = () => (
    <LoadingButton
      ButtonComponent={ButtonRight}
      onClick={handleConfirm}
      disabled={!submitButtonEnabled}
      loading={loadingAction}
      size="large"
    >
      {isEditing ? 'Salvar Grupo' : 'Criar Grupo'}
    </LoadingButton>
  );

  return (
    <>
      <Container>
        {isEditingOperationMode && Boolean(funders.length) && !groupLoading ? (
          <EditOperationMode
            title={`Modo de operação do ${isEditing ? `Grupo ${name}` : 'Novo grupo'}`}
            funderTargetingMode={funderTargetingMode}
            selectedFunderId={selectedFunderId}
            onCancel={handleCancelEditOperationMode}
            onSave={handleSaveOperationMode}
            funders={funders}
            vendorsGroupId={groupIdForRates}
          />
        ) : (
          <>
            <Row>
              <TooltipBox mount="top" fixed content="Voltar">
                <IconButton onClick={() => window.history.back()}>
                  <Icon path={mdiArrowLeft} />
                </IconButton>
              </TooltipBox>
              <ScreenTitle>
                {isEditing ? `Editar fornecedores do Grupo ${name}` : 'Criar novo Grupo'}
              </ScreenTitle>
            </Row>
            {isEditing && (
              <>
                <Row justify="flex-start">
                  <ReferenceLabel>Código de referência:</ReferenceLabel>
                  <ReferenceValue>{reference}</ReferenceValue>
                </Row>
                <InfoSpacer />
                <Info>
                  Use os campos abaixo para adicionar ou remover fornecedores deste grupo
                  <br />
                  Obs.: Fornecedores removidos deste grupo serão enviados ao grupo padrão
                </Info>
              </>
            )}
            {!isEditing && (
              <>
                <SectionTitle marginBottom={14} marginTop={24}>
                  Dados do grupo
                </SectionTitle>
                <Row align="flex-start" justify="flex-start">
                  <InputWrapper>
                    <TextInput value={name} onChange={setName} label="Nome do grupo*" />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      value={reference}
                      onChange={handleChangeReference}
                      label="Código de referência"
                      errorMessage={referenceError}
                      disabled={isEditing}
                    />
                    {!referenceError && (
                      <HintText>Deve possuir apenas letras, números, hífen e underscore.</HintText>
                    )}
                  </InputWrapper>
                </Row>
                <Row justify="flex-start">
                  <SectionTitle marginBottom={37}>Modo de operação</SectionTitle>
                  <EditButton
                    onClick={() => {
                      setIsEditingOperationMode(true);
                    }}
                  >
                    <Icon path={mdiPencil} />
                    editar
                  </EditButton>
                </Row>
                {(!isEditing || !groupLoading) && (
                  <OperationModeCard
                    funderTargetingMode={funderTargetingMode}
                    funder={funder}
                    vendorsGroupId={groupIdForRates}
                  />
                )}
              </>
            )}
            {!isOnlyEditingOpMode && (
              <TransferListsContainer>
                <TransferListsTitleContainer>
                  <SectionTitle>Lista de todos os fornecedores</SectionTitle>
                  <SectionTitle>Fornecedores neste grupo</SectionTitle>
                </TransferListsTitleContainer>
                <TransfersListRow>
                  <TransferList
                    loading={loadingBuyerVendors}
                    firstLoading={firstLoadingBuyer}
                    vendors={buyerVendors}
                    selectedVendorsIds={selectedBuyerVendors}
                    vendorsCount={vendorsCount}
                    handleSelect={handleSelectBuyerVendors}
                  />
                  <CenterContainer>
                    <TooltipBox mount="top" fixed content="Remover do grupo">
                      <Button onClick={moveRightToLeft} active={selectedGroupVendors.length > 0}>
                        <Icon path={mdiChevronLeft} />
                      </Button>
                    </TooltipBox>
                    <TooltipBox mount="bottom" fixed content="Adicionar ao grupo">
                      <Button
                        data-testid="transferButton"
                        onClick={moveLeftToRight}
                        active={selectedBuyerVendors.length > 0}
                      >
                        <Icon path={mdiChevronRight} />
                      </Button>
                    </TooltipBox>
                  </CenterContainer>
                  <TransferList
                    loading={loadingGroupVendors}
                    firstLoading={firstLoadingGroup}
                    vendors={groupVendors}
                    selectedVendorsIds={selectedGroupVendors}
                    vendorsCount={groupVendorsCount}
                    handleSelect={handleSelectGroupVendors}
                    isVendorsGroupList
                  />
                </TransfersListRow>
              </TransferListsContainer>
            )}
            <FooterActions>
              <ButtonOutline onClick={() => window.history.back()}>Cancelar</ButtonOutline>
              {isEditing && !hasChanges ? (
                <TooltipBox mount="top" fixed content="Não há alterações">
                  <div>{submitButton()}</div>
                </TooltipBox>
              ) : (
                submitButton()
              )}
            </FooterActions>
          </>
        )}
      </Container>
      {alertModal && (
        <ConfirmGroupEdit
          onLeaved={() => setAlertModal(false)}
          movedVendors={movedVendors}
          handleConfirm={() => handleConfirmAlert()}
        />
      )}
      <ConfirmOpModeEdit
        show={showConfirmOpModeEdit}
        onLeaved={() => setShowConfirmOpModeEdit(false)}
        handleCancel={handleCancelConfirmOpModeEdit}
        handleConfirm={handleConfirmOpModeEdit}
      />
    </>
  );
}
