export default {
  invalidFormat: 'Formato inválido',
  invalidDate: 'Data inválida',
  empty: 'Esse campo não pode estar vazio',
  name: 'Verifique se o nome está completo',
  pendingForms: 'Existem campos que estão vazios ou precisam ser corrigidos',
  serverError: 'Ocorreu um erro ao enviar os dados. Tente novamente!',
  underage: 'Você precisa ser maior de idade para se cadastrar',
  wrongZipCode: 'CEP não encontrado, verifique se o valor está correto!',
  zipApiError: 'Não foi possível recuperar os dados do CEP automaticamente',
};
