import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ModalDangerButton, SuccessButton, TooltipBox, Checkbox } from 'liber-components';
import { showConfirmation, funderRemove, updateSkipOperations } from '../../actions/proposalAction';
import {
  FixedBox,
  BoxHead,
  Values,
  BoxBody,
  ButtonContainer,
  MinIcon,
  MaxIcon,
  Action,
  EmptyInfo,
  LoadingValue,
  TooltipContent,
  TooltipItemValue,
  TooltipItemMonth,
  TooltipBar,
  Info,
  TriangleNotice,
  TooltipRow,
} from './Summary.styles';
import { TooltipContentCustom } from '../Dialogs/Dialogs.styles.jsx';

const mapTaxedMonths = months => (
  <TooltipRow key={months.amount} selected>
    <TooltipItemMonth>{months.month}</TooltipItemMonth>
    <TooltipBar />
    <TooltipItemValue>{months.amount}</TooltipItemValue>
  </TooltipRow>
);

export class SummaryBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pos: 'open',
      show: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.show !== state.show && !props.show) {
      return {
        pos: 'closed',
        show: props.show,
      };
    }
    return {
      show: props.show,
    };
  }

  handleMaximize = () => {
    this.setState({ pos: 'open' });
  };

  handleMinimize = () => {
    this.setState({ pos: 'closed' });
  };

  handleCancel = () => {
    const { removeFunder, funder } = this.props;
    removeFunder(funder);
  };

  handleCheck = () => {
    const { operationsUpdateSkip } = this.props;
    operationsUpdateSkip(!this.props.skipAcceptance);
  };

  render() {
    const { hide, summary, funder: funderId, funders, openConfirmation } = this.props;
    const { pos } = this.state;
    const { count, amount, taxed, negotiations, loadingCount, months } = summary;
    const loading = loadingCount > 0;
    const funder = funders.find(item => item.id === funderId);
    const action = {
      open: this.handleMinimize,
      closed: this.handleMaximize,
    };
    const icon = {
      open: <MinIcon />,
      closed: <MaxIcon />,
    };
    return ReactDOM.createPortal(
      <FixedBox pos={hide ? 'hide' : pos}>
        <BoxHead>
          Novas propostas {loading ? '' : `(${count})`}
          <Action onClick={action[pos]}>{icon[pos]}</Action>
        </BoxHead>
        <BoxBody>
          <Values selected loading={loading}>
            Títulos: {loading ? <LoadingValue /> : <span>{count}</span>}
          </Values>
          <Values selected loading={loading}>
            Valor Total: {loading ? <LoadingValue /> : <span>{amount}</span>}
          </Values>
          <Values selected>
            Investidor:<span>{funder ? funder.name : null}</span>
          </Values>
          <Checkbox checked={this.props.skipAcceptance} onChange={this.handleCheck}>
            <TooltipContentCustom>
              Fazer propostas com aceite automático do fornecedor
            </TooltipContentCustom>
          </Checkbox>
          {taxed ? (
            <TooltipBox
              fixed
              mount="top"
              label="Tributação Mensal"
              content={<TooltipContent>{months.map(mapTaxedMonths)}</TooltipContent>}
            >
              <Info>
                <TriangleNotice />
                Ao realizar esta proposta, o investidor estará <br />
                <span /> sujeito ao pagamento do DARF.
              </Info>
            </TooltipBox>
          ) : (
            <EmptyInfo />
          )}

          <ButtonContainer>
            <ModalDangerButton
              disabled={funderId === ''}
              onClick={funderId !== '' ? this.handleCancel : undefined}
            >
              Cancelar
            </ModalDangerButton>
            <SuccessButton
              disabled={negotiations.length === 0 || loading}
              onClick={funderId !== '' && negotiations.length !== 0 ? openConfirmation : undefined}
            >
              Fazer Propostas
            </SuccessButton>
          </ButtonContainer>
        </BoxBody>
      </FixedBox>,
      document.getElementById('fixed-summary'),
    );
  }
}

SummaryBox.propTypes = {
  show: PropTypes.bool.isRequired, // eslint-disable-line
  hide: PropTypes.bool.isRequired,
  skipAcceptance: PropTypes.bool.isRequired,
  funder: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    loadingCount: PropTypes.number,
    count: PropTypes.number,
    amount: PropTypes.string,
    taxed: PropTypes.bool,
    months: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        month: PropTypes.string,
        amout: PropTypes.string,
      }),
    ),
    negotiations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        slug: PropTypes.string,
        monthly_profitability: PropTypes.string,
        advance: PropTypes.string,
        deadline: PropTypes.string,
        actions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        number: PropTypes.string,
        amount: PropTypes.string,
        rate: PropTypes.string,
        expires_at: PropTypes.string,
      }),
    ),
  }).isRequired,
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.name,
    }),
  ).isRequired,
  openConfirmation: PropTypes.func,
  removeFunder: PropTypes.func,
  operationsUpdateSkip: PropTypes.func,
};

SummaryBox.defaultProps = {
  openConfirmation: () => {},
  removeFunder: () => {},
  operationsUpdateSkip: () => {},
};

const mapStateToProps = state => ({
  ...state.multiProposal.summary,
  show: state.multiProposal.summary.showFixedBox,
  hide: state.multiProposal.summary.funder === '',
});
const mapDispatchToProps = {
  openConfirmation: showConfirmation,
  removeFunder: funderRemove,
  operationsUpdateSkip: updateSkipOperations,
};
export default connect(mapStateToProps, mapDispatchToProps)(SummaryBox);
