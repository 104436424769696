import axios from 'axios';
import { INVOICE_SHOW_URL, ADMIN_INVOICE_SHOW_URL } from '../urls';
import { handleError, convertToCamelCase } from '../../../vendor/Utils';

export const FETCH_INVOICE = 'FETCH_INVOICE';
export const SAVE_INVOICE = 'SAVE_INVOICE';

const getInvoiceShowUrl = (isAdmin, vendorId) =>
  isAdmin ? ADMIN_INVOICE_SHOW_URL.replace(':id', vendorId) : INVOICE_SHOW_URL;

export function saveInvoice(rawInvoice) {
  const invoice = convertToCamelCase(rawInvoice);
  return {
    type: SAVE_INVOICE,
    payload: { invoice },
  };
}

export function fetchInvoice(invoiceId, token, borderoId, isAdmin, vendorId) {
  return dispatch => {
    dispatch({ type: FETCH_INVOICE });

    return axios
      .get(`${getInvoiceShowUrl(isAdmin, vendorId)}${invoiceId}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { bordero_id: borderoId },
      })
      .then(response => {
        const {
          data: { data },
        } = response;
        dispatch(saveInvoice(data));
      })
      .catch(() => handleError());
  };
}
