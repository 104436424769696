import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4 as Theme, ToastContainer } from 'liber-components';

import CreateVendorsGroup from './components/CreateVendorsGroup';
import withTokenProvider from '../../hocs/withTokenProvider';
import { CreateVendorsGroupStore } from './context';

function View({ groupId }) {
  return (
    <ThemeProvider theme={Theme}>
      <CreateVendorsGroupStore groupId={groupId}>
        <CreateVendorsGroup />
      </CreateVendorsGroupStore>
      <ToastContainer />
    </ThemeProvider>
  );
}

View.propTypes = {
  groupId: PropTypes.string,
};

View.defaultProps = {
  groupId: null,
};

export default withTokenProvider(View, 'buyer_jwt');
