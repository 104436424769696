import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AccordionBox, ShowBox, AccordionAction } from './styles';

class Accordion extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  onClick = () => {
    this.setState(prevState => ({ open: !prevState.open }));
    this.countAccordions();
  };

  countAccordions = () => {
    if (this.state.open) {
      this.props.decOpenAccordions();
    } else {
      this.props.sumOpenAccordions();
    }
  };

  render() {
    const { children, label } = this.props;
    const { open } = this.state;
    return (
      <AccordionBox open={open}>
        <AccordionAction open={open} dark={this.props.dark} onClick={this.onClick}>
          <span>{label}</span>
        </AccordionAction>
        <ShowBox open={open}>{children}</ShowBox>
      </AccordionBox>
    );
  }
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  dark: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  decOpenAccordions: PropTypes.func.isRequired,
  sumOpenAccordions: PropTypes.func.isRequired,
};

Accordion.defaultProps = {
  children: '',
  dark: true,
  label: '',
};

export default Accordion;
