import axios from 'axios';
import { errorMsg } from '../messages';

export const changeToken = value => ({
  type: 'LOGIN/CONFIRMATION_TOKEN/CHANGE_TOKEN',
  payload: value,
});

export const submitForm = (token, confirmationPath) => dispatch => {
  if (token.length === 0) {
    return dispatch({
      type: 'LOGIN/CONFIRMATION_TOKEN/SUBMIT_FORM/FAILURE',
      payload: errorMsg.empty,
    });
  }

  return axios({
    method: 'GET',
    url: `${confirmationPath}.json`,
    params: {
      ajax: true,
      confirmation_token: token,
    },
  })
    .then(response => {
      dispatch({
        type: 'LOGIN/CONFIRMATION_TOKEN/SUBMIT_FORM/SUCCESS',
        payload: response.data,
      });

      window.location = response.data.location;
    })
    .catch(() => {
      dispatch({
        type: 'LOGIN/CONFIRMATION_TOKEN/SUBMIT_FORM/FAILURE',
        payload: errorMsg.tokenError,
      });
    });
};
