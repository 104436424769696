import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { LiberV4, ToastContainer } from 'liber-components';
import AcceptTerms from './components/AcceptTerms';

const View = ({ token, adhesionContract }) => (
  <>
    <ThemeProvider theme={LiberV4}>
      <AcceptTerms token={token} adhesionContract={adhesionContract} />
      <ToastContainer />
    </ThemeProvider>
  </>
);

View.propTypes = {
  token: PropTypes.string,
  adhesionContract: PropTypes.string,
};

View.defaultProps = {
  token: null,
  adhesionContract: null,
};

export default View;
