import React from 'react';
import PropTypes from 'prop-types';
import { IconRemove, LinkButton } from './styles';

const Remove = ({ label, ...props }) => (
  <LinkButton {...props}>
    <IconRemove />
    {label}
  </LinkButton>
);

Remove.propTypes = {
  label: PropTypes.string,
};

Remove.defaultProps = {
  label: 'Voltar',
};

export default Remove;
