import React, { useState } from 'react';
import { Tab } from 'liber-components';
import UploadForm from '../UploadForm';
import * as S from './styles';

const FunderSpreadsheetRates = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <S.Tabs type="center" onChange={setActiveTab} activeTab={activeTab}>
      <Tab version={2} label="Taxa por prazo">
        <UploadForm kind="term" />
      </Tab>
      <Tab version={2} label="Taxa por data">
        <UploadForm kind="date" />
      </Tab>
    </S.Tabs>
  );
};

export default FunderSpreadsheetRates;
