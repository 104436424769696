import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Label = styled.label`
  font-size: 0.9rem;
  color: #405f71;
  margin: 10px 20px 10px 2px;
  font-weight: normal;
`;

export const Input = styled.input`
  color: #009dff;
`;
