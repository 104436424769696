import styled from 'styled-components';
import {
  Button as RawButton,
  handleThemeFromObject as getTheme,
  PrimaryButton,
} from 'liber-components';

export const FilterText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: #405f71;
  margin-right: 16px;
`;

export const CleanFilters = styled(RawButton).attrs(() => ({ version: '2' }))`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => getTheme(theme, 'colors.primary', '#009dff')};
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FiltersRow = styled(Row)`
  justify-content: flex-start;
`;

export const ButtonsRow = styled(Row)`
  gap: 5px;
  justify-content: flex-end;
  width: fit-content;
`;

export const FlexColumn = styled.div`
  width: 320px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;
