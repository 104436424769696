import { toast } from 'liber-components/components/Toast/actions';

export const TOAST_DURATION = 5000;
export const PIN_TOKEN_ERROR_MESSAGE = 'Senha e/ou Token incorretos. Por favor, tente novamente.';
const REQUEST_ERROR_MESSAGE =
  'Por favor tente novamente mais tarde ou entre em contato com o suporte';

export const formatInNumbers = (value, quantity) => {
  const onlyNumbers = value.replace(/[^0-9]/g, '');
  const desiredQuantity = onlyNumbers.slice(0, quantity);
  return desiredQuantity;
};

export const handleError = (error = {}) => {
  const errorMessage = error.response?.data?.error?.message;

  toast(
    {
      message: 'Um erro ocorreu',
      info: errorMessage || REQUEST_ERROR_MESSAGE,
    },
    'error',
    TOAST_DURATION,
  );
};

export const mockAnticipation = {
  borderos: [
    {
      id: 2,
      invoiceCount: 10,
      agreedRate: '0,90',
      netValue: '1000,00',
      faceValue: '990,00',
      signatureProgress: '0/0',
      documentUrl: 'mockUrl',
      signingId: 'id12345',
      userHasSigned: false,
      requestedAt: null,
    },
    {
      id: 4,
      invoiceCount: 15,
      agreedRate: '0,90',
      netValue: '800,00',
      faceValue: '790,00',
      signatureProgress: '0/0',
      documentUrl: 'mockUrl2',
      signingId: 'id67890',
      userHasSigned: false,
      requestedAt: null,
    },
  ],
  total: {
    invoiceCount: 25,
    agreedRate: '0,90',
    netValue: '1.800,00',
    faceValue: '1.780,00',
    documentsUrl: 'mockUrl',
  },
};
