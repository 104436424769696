import { connect } from 'react-redux';
import MultiSignFornecedorView from './MultiSignFornecedorView';
import {
  setSelectedPartner,
  setSelectedCertificate,
  showPdf,
  hidePdf,
  assinaMeWaitForExtension,
  assinaMeBrowserSupportErrors,
  assinaMeSystemCheck,
  signDocuments,
  startSocket,
  removeSocket,
} from '../../actions/MultiSignFornecedorViewActions';

const reducer = 'multiSignFornecedor';

const mapStateToProps = state => ({ ...state[reducer] });

const mapDispatchToProps = {
  setSelectedPartner,
  setSelectedCertificate,
  showPdf,
  hidePdf,
  assinaMeWaitForExtension,
  assinaMeBrowserSupportErrors,
  assinaMeSystemCheck,
  signDocuments,
  startSocket,
  removeSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiSignFornecedorView);
