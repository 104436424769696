import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DisplayRow, SimpleDisplay } from 'liber-components';
import { Cdi } from './styles';
import { Card, LoadingBlock, TooltipList, TooltipText, CardHeader } from '../styles';
import { fetchWalletMean } from '../../actions/walletMeanActions';

export const WalletMean = ({
  loading,
  averageWeightedCdi,
  averageRunningDays,
  monthlyWeightedProfitability,
  loadWalletMean,
}) => {
  React.useEffect(() => {
    loadWalletMean();
  }, []);

  const tooltipContentCdi = (
    <TooltipList>
      <li>{monthlyWeightedProfitability} ao Mês</li>
    </TooltipList>
  );

  const tooltipContentTerm = (
    <TooltipText>Prazo médio ponderado de recebimento da carteira de títulos</TooltipText>
  );

  return (
    <>
      <Card width="460px" height="165px">
        <CardHeader height="59px" padding="13px">
          MÉDIA DA CARTEIRA
        </CardHeader>
        <LoadingBlock loading={loading}>
          <DisplayRow>
            <SimpleDisplay
              divider={false}
              label="Rentabilidade"
              tooltipLabel="Rentabilidade"
              tooltipContent={tooltipContentCdi}
            >
              {averageWeightedCdi}
              <Cdi>&nbsp;do DI</Cdi>
            </SimpleDisplay>
            <SimpleDisplay divider={false} tooltipContent={tooltipContentTerm} label="Prazo Médio">
              {averageRunningDays}
            </SimpleDisplay>
          </DisplayRow>
        </LoadingBlock>
      </Card>
    </>
  );
};

WalletMean.propTypes = {
  loading: PropTypes.bool,
  averageWeightedCdi: PropTypes.string,
  averageRunningDays: PropTypes.number,
  monthlyWeightedProfitability: PropTypes.string,
  loadWalletMean: PropTypes.func,
};

WalletMean.defaultProps = {
  loading: false,
  averageWeightedCdi: '0,00%',
  averageRunningDays: 0,
  monthlyWeightedProfitability: '0,00%',
  loadWalletMean: () => {},
};

const mapStateToProps = ({
  investorDashboard: {
    walletMean: { loading, averageWeightedCdi, averageRunningDays, monthlyWeightedProfitability },
  },
}) => ({ loading, averageWeightedCdi, averageRunningDays, monthlyWeightedProfitability });

const mapDispatchToProps = {
  loadWalletMean: fetchWalletMean,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletMean);
