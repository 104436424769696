export const BANCO_DO_BRASIL = BANCO_DO_BRASIL_ID; //eslint-disable-line
export const ID_IN_FILTER = 'q[id_in]';
export const CAN_RECEIVE_VENDORS_FILTER = 'q[can_receive_vendors_eq]';

export const EMPTY_VENDOR_OBJECT = {
  id: null,
  name: null,
  action: null,
  target: {
    name: null,
    id: null,
  },
};

export const ACTIONS = {
  BLOCK: 'block',
  UNBLOCK: 'unblock',
};

export const ACTION_KIND = {
  block: 'Bloqueio',
  unblock: 'Desbloqueio',
};

export const STATUSES = {
  block: 'blocked',
  unblock: 'unblocked',
};
