import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchRatesPreview } from '../api';
import { convertToCamelCase, handleError } from '../../../vendor/Utils';
import { END_DATE_PARAM, START_DATE_PARAM } from '../constants';

export const RatesPreviewContext = createContext({});

export const RatesPreviewStore = ({ children, token, exampleFile, roomId, id }) => {
  const [loading, setLoading] = useState(false);
  const [ratesPreview, setRatesPreview] = useState({ buyers: [], rates: {} });
  const [dateInterval, setDateInterval] = useState({ start: null, end: null });

  const fetchPreview = () => {
    const params = {
      [START_DATE_PARAM]: dateInterval.start?.format('YYYY-MM-DD'),
      [END_DATE_PARAM]: dateInterval.end?.format('YYYY-MM-DD'),
    };

    setLoading(true);
    fetchRatesPreview(id, token, params)
      .then(({ data }) => setRatesPreview(convertToCamelCase(data)))
      .catch(() => handleError('Ocorreu um erro ao carregar o preview das taxas.'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPreview();
  }, [dateInterval]);

  return (
    <RatesPreviewContext.Provider
      value={{
        token,
        exampleFile,
        roomId,
        dateInterval,
        setDateInterval,
        ratesPreview,
        id,
        loading,
      }}
    >
      {children}
    </RatesPreviewContext.Provider>
  );
};

RatesPreviewStore.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  exampleFile: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};
