import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CompleteTable } from 'liber-components';
import Filters from '../Filters/Filters';
import { COLUMNS } from '../../utils';
import {
  fetchTrades,
  setPage,
  nextPage,
  previousPage,
  setListing,
  toggleItemSelection,
  togglePageSelection,
  selectAll,
  resetSelection,
} from '../../actions/remittanceActions';
import usePageState from '../../../../hooks/usePageState';
import { getPaginationInterval } from '../../../../vendor/Utils';
import { LoadingContainer, Bunny } from './Table.styles';
import { SelectionControl } from '../../../../common-components/SelectionControl/SelectionControl';

const Table = ({
  onFetchTrades,
  loading,
  trades,
  pagination,
  onSetPage,
  onNextPage,
  onPreviousPage,
  onSetListing,
  onToggleItemSelect,
  onTogglePageSelect,
  selectedTradeIds,
  isPageSelected,
  activeSelect,
  hasSelectedAllItems,
  itemsCount,
  isAdmin,
  onSelectAll,
  onResetSelection,
}) => {
  const { current, pages: pageTotal, count: total, per } = pagination;
  const [pageState, setPageState, onKeyDownPage] = usePageState(current, pageTotal, onSetPage);
  useEffect(() => {
    onFetchTrades(activeSelect);
  }, [activeSelect]);
  const isItemSelected = ({ id }) => selectedTradeIds.includes(id);

  const onChangePage = ({ target: { value } }) => {
    setPageState(value);
  };

  return (
    <>
      <CompleteTable
        columns={COLUMNS}
        items={loading ? [] : trades}
        onSelectAll={onTogglePageSelect}
        emptyState={
          loading ? (
            <LoadingContainer>
              <Bunny />
            </LoadingContainer>
          ) : undefined
        }
        allChecked={isPageSelected}
        isItemSelected={isItemSelected}
        onSelect={onToggleItemSelect}
        selectionControl={
          <SelectionControl
            selectedItems={selectedTradeIds}
            hasSelectedAllItems={hasSelectedAllItems}
            itemsCount={itemsCount}
            selectAll={onSelectAll}
            clearSelection={onResetSelection}
            itemName={{ singular: 'Título', plural: 'Títulos' }}
            isAdmin={isAdmin}
          />
        }
        filters={<Filters />}
        paginationProps={{
          currentPage: String(pageState),
          pageTotal: String(pageTotal),
          onChangePage,
          onKeyDownPage,
          onClickNext: onNextPage,
          onClickPrev: onPreviousPage,
          hasListing: true,
          listingProps: {
            listing: String(per),
            total: String(total),
            showInterval: getPaginationInterval(pagination),
            onSelectListing: onSetListing,
          },
        }}
      />
    </>
  );
};

Table.propTypes = {
  store: PropTypes.shape({}).isRequired,
  onFetchTrades: PropTypes.func,
  loading: PropTypes.bool,
  hasSelectedAllItems: PropTypes.bool,
  trades: PropTypes.arrayOf(PropTypes.shape({})),
  pagination: PropTypes.shape({
    current: PropTypes.number,
    previous: PropTypes.number,
    next: PropTypes.number,
    per: PropTypes.number,
    pages: PropTypes.number,
    count: PropTypes.number,
  }),
  onSetPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onSetListing: PropTypes.func,
  onToggleItemSelect: PropTypes.func,
  onTogglePageSelect: PropTypes.func,
  selectedTradeIds: PropTypes.arrayOf(PropTypes.number),
  itemsCount: PropTypes.number,
  isPageSelected: PropTypes.bool,
  activeSelect: PropTypes.string,
  isAdmin: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onResetSelection: PropTypes.func,
};
Table.defaultProps = {
  onFetchTrades: () => {},
  onSetPage: () => {},
  loading: false,
  hasSelectedAllItems: false,
  itemsCount: 0,
  trades: [],
  selectedTradeIds: [],
  pagination: {
    current: 1,
    previous: null,
    next: null,
    per: 25,
    pages: 1,
    count: 0,
  },

  onNextPage: () => null,
  onPreviousPage: () => null,
  onSetListing: () => null,
  isPageSelected: false,
  onToggleItemSelect: () => {},
  onTogglePageSelect: () => {},
  activeSelect: 'remessa',
  isAdmin: false,
  onSelectAll: () => {},
  onResetSelection: () => {},
};

const mapDispatchToProps = {
  onFetchTrades: fetchTrades,
  onNextPage: nextPage,
  onSetPage: setPage,
  onPreviousPage: previousPage,
  onSetListing: setListing,
  onToggleItemSelect: toggleItemSelection,
  onTogglePageSelect: togglePageSelection,
  onSelectAll: selectAll,
  onResetSelection: resetSelection,
};

const mapStateToProps = ({
  remittance: {
    trades,
    loading,
    pagination,
    selectedTradeIds,
    isPageSelected,
    activeSelect,
    hasSelectedAllItems,
    pagination: { count },
    isAdmin,
  },
}) => ({
  trades,
  loading,
  pagination,
  selectedTradeIds,
  isPageSelected,
  activeSelect,
  hasSelectedAllItems,
  itemsCount: count,
  isAdmin,
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
