import styled from 'styled-components';
import { MdChevronRight, Card } from 'liber-components';

export const NegotiationStatus = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #717171;
  margin-bottom: 4px;
`;

export const NegotiationContent = styled.div``;

export const NegotiationContentInvoicesCountContent = styled.div`
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  color: ${props => (props.disabled ? '#b6c2c9' : '#4e4e4e')};
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const NegotiationContentInvoicesCount = styled.div`
  font-size: 32px;
  margin-right: 6px;
`;

export const NegotiationContentValue = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  color: ${props => (props.disabled ? '#b6c2c9' : '#4e4e4e')};
`;

export const NegotiationLinkDetails = styled.a`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  color: ${props => (props.disabled ? '#b6c2c9' : '#009dff')};
  pointer-events: ${props => (props.disabled ? 'none' : null)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MdChevronRightStyled = styled(MdChevronRight)`
  width: 18px;
  height: 18px;
  fill: ${props => (props.disabled ? '#b6c2c9' : '#009dff')};
`;

export const CardStyled = styled(Card)`
  width: 100%;
  margin-right: 0px;
`;
