import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: 2 }))``;
