import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from 'liber-salti';
import { ScopedCssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const SaltiThemeWrapper = ({ children }) => {
  const muiTheme = createTheme(Theme);

  return (
    <StyledThemeProvider theme={muiTheme}>
      <ThemeProvider theme={muiTheme}>
        <ScopedCssBaseline>{children}</ScopedCssBaseline>
      </ThemeProvider>
    </StyledThemeProvider>
  );
};

SaltiThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SaltiThemeWrapper;
