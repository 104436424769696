import { combineReducers } from 'redux';
import changePassword from './change-password/reducers';
import irpf from './irpf/reducers';
import login from './login/reducers';
import multiAssinatura from './multi-assinatura/reducers';
import wizardInvestidor from './wizard-investidor/reducers';
import investorDashboard from './investor-dashboard/reducers';
import investorStatement from './investor-statement/reducers';
import myAccount from './my-account/reducers';
import multiProposal from './multi-proposal/reducers';
import invoiceSimulation from './fornecedor-duplicatas/reducers';
import vendorAdvances from '../common-components/VendorAnticipations/reducers';
import confirmationHistory from './confirmation-history/reducers';
import vendorBorderoShow from '../common-components/VendorBorderoShow/reducers';
import anticipationCheckout from './anticipation-checkout/reducers';
import anticipationCancel from './anticipation-cancel/reducer/AnticipationCancelReducer';
import vendorInvoices from '../common-components/VendorInvoices/reducer/VendorInvoicesReducer';
import adminNegotiations from './admin-negotiations/reducer/AdminNegotiationsReducer';
import vendorReports from './vendor-reports/reducer/VendorReportsReducer';
import invoiceDialog from './invoice-dialog/reducer/InvoiceDialogReducer';
import adminVendors from './admin-vendors/reducer/AdminVendorsReducer';
import remittance from './remittance/Reducer/remittanceReducer';
import replicateVendorReducer from './replicateVendor/Reducer/replicateVendorReducer';

export default combineReducers({
  ...changePassword,
  ...irpf,
  ...login,
  ...multiAssinatura,
  ...wizardInvestidor,
  investorDashboard,
  investorStatement,
  myAccount,
  multiProposal,
  ...invoiceSimulation,
  ...vendorAdvances,
  ...confirmationHistory,
  ...vendorBorderoShow,
  ...anticipationCheckout,
  vendorInvoices,
  adminNegotiations,
  vendorReports,
  invoiceDialog,
  anticipationCancel,
  adminVendors,
  remittance,
  replicateVendorReducer,
});
