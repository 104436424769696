import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ToastContainer, Liber } from 'liber-components';
import { ThemeProvider } from 'styled-components';
import hydrateStore from '../store';
import InvestorDashboard from './components/InvestorDashboard';

const View = ({ store, title, token }) => (
  <>
    <Provider
      store={hydrateStore({
        ...store,
        investorDashboard: { walletMean: { token }, invoicesDialog: { token } },
      })}
    >
      <ThemeProvider theme={Liber}>
        <InvestorDashboard token={token} title={title} />
      </ThemeProvider>
    </Provider>
    <ToastContainer />
  </>
);

View.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  token: PropTypes.string.isRequired,
  title: PropTypes.string,
};

View.defaultProps = {
  title: '',
};

export default View;
