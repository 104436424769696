import React from 'react';
import { SecondaryLink, Link } from 'liber-components/components/Buttons/Buttons';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

import { useBuyerOrganizationGroup } from '../../context';
import * as S from './styles';

const PageHeader = () => {
  const { buyerGroup } = useBuyerOrganizationGroup();

  return (
    <S.Container>
      <S.HeadingContent>
        <Link
          data-testid="btn-action-edit"
          version={2}
          size="large"
          href="/admin/grupos-economicos"
          circle
        >
          <Icon path={mdiArrowLeft} />
        </Link>
        <S.PageTitle>{buyerGroup.groupName}</S.PageTitle>
      </S.HeadingContent>
      <SecondaryLink href={`/admin/grupos-economicos/${buyerGroup.id}/edit`}>Editar</SecondaryLink>
    </S.Container>
  );
};

export default PageHeader;
