import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiAccountCardDetails, mdiFileDocument } from '@mdi/js';
import {
  Row,
  SelectableCard,
  CardTitle,
  CardData,
  StatusCircle,
  EyeIcon,
  LoadingContainer,
  Spinner,
  Button,
} from './AdminVendorAnticipations.styles';
import VendorInvoices from '../../../common-components/VendorInvoices';
import { fetchVendor } from '../utils';
import VendorInfo from './VendorInfo/VendorInfo';
import VendorBorderoShow from '../../../common-components/VendorBorderoShow';
import ScreenTitle from '../../../common-components/ScreenTitle/ScreenTitle';

const BACK_URL = '/admin/fornecedores';

const CARDS = [
  {
    title: 'Cadastro',
    iconPath: mdiAccountCardDetails,
    key: 'account',
    redirectPath: '/admin/fornecedores/:slug',
    type: 'positive',
  },
  {
    title: 'Títulos',
    iconPath: mdiFileDocument,
    key: 'invoices',
  },
];

const AdminVendorAnticipations = ({ vendorId, anticipationId, token, startingTab }) => {
  const [vendor, setVendor] = React.useState(null);
  const [selected, setSelected] = useState(startingTab);

  React.useEffect(() => {
    fetchVendor(token, vendorId, setVendor);
  }, []);

  const handleBack = () => window.location.assign(BACK_URL);

  const getAccountStatus = () => (
    <>
      <StatusCircle />
      Válido
    </>
  );

  const renderSelectableCards = () =>
    CARDS.map(({ title, iconPath, key, redirectPath, type }) => (
      <SelectableCard
        selected={key === selected}
        onClick={
          redirectPath
            ? () => window.location.assign(redirectPath.replace(':slug', vendor?.slug))
            : () => setSelected(key)
        }
        key={key}
      >
        <CardTitle>
          <Icon path={iconPath} />
          {title}
        </CardTitle>
        <CardData type={type}>{vendor[`${key}Count`] ?? getAccountStatus()}</CardData>
      </SelectableCard>
    ));

  const renderContent = () => {
    switch (selected) {
      case 'invoices':
        return <VendorInvoices vendorId={vendorId} token={token} isAdmin />;
      case 'show':
        return (
          <>
            <Button size="large" onClick={() => setSelected('borderos')}>
              <EyeIcon /> Mostrar Antecipações
            </Button>
            <VendorBorderoShow
              isAdmin
              fornecedorId={vendorId}
              anticipationId={anticipationId}
              token={token}
              operator={{ id: 'mock' }}
            />
          </>
        );
      default:
        return null;
    }
  };

  return !vendor ? (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  ) : (
    <>
      <ScreenTitle handleBack={handleBack}>Fornecedor</ScreenTitle>
      <Row>
        <VendorInfo vendor={vendor} />
      </Row>
      <Row mb="56px">{renderSelectableCards()}</Row>
      {renderContent()}
    </>
  );
};

AdminVendorAnticipations.propTypes = {
  token: PropTypes.string,
  vendorId: PropTypes.number,
  anticipationId: PropTypes.number,
  startingTab: PropTypes.string,
};

AdminVendorAnticipations.defaultProps = {
  token: '',
  vendorId: null,
  anticipationId: null,
  startingTab: 'invoices',
};

export default AdminVendorAnticipations;
