import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryButton, ToastContainer } from 'liber-components';
import jwtDecode from 'jwt-decode';
import { toast } from 'liber-components/components/Toast';
import moment from 'moment';
import ComunicationCard from '../ComunicationCard/ComunicationCard';
import {
  TitleContainer,
  Title,
  HeaderText,
  TotalInvoices,
  RootContainer,
  DisableBootstrap,
  ButtonsContainer,
  ButtonLink,
} from './VendorInvoices.styles';
import {
  fetchInvoices,
  EXPIRES_EQ_FILTER,
  EXPIRES_GT_FILTER,
  NUMBER_FILTER,
  CNPJ_FILTER,
  EXPIRES_LT_FILTER,
  STATUS_FILTER,
  subscribeInvoices,
  anticipateInvoices,
  showAnticipationLimitError,
  subscribeToLimits,
  MAPPED_STATUS_IN,
} from '../../actions/VendorInvoicesActions';
import InvoicesTable from '../InvoicesTable/InvoicesTable';
import LoadingButton from '../../../Buttons/LoadingButton';
import LoadingContainer from './LoadingContainer';
import AnticipationLimitCard from '../AnticipationLimitCard';
import UnavailableLimitDialog from '../../../UnavailableLimitDialog';
import { ELIGIBLE_INVOICE_MAPPED_STATUS } from '../../../../hooks/useInvoiceUnavailableReason';
import InvoicesAvailabilityDialog from '../InvoicesAvailabilityDialog';
import { filteringByAvailable } from '../../utils';
import VendorInterest from '../../../../views/vendor-interest/components/VendorInterest';

const getIntroductionText = (totalInvoices, mappedStatusFilter) => {
  if (filteringByAvailable(mappedStatusFilter) && totalInvoices > 0) {
    return (
      <>
        No momento, <TotalInvoices>{totalInvoices}</TotalInvoices>
        {totalInvoices === 1 ? ' título está disponível ' : ' títulos estão disponíveis '}
        para antecipação. <br />
        Selecione os títulos disponíveis para solicitar sua antecipação.
      </>
    );
  }

  if (
    mappedStatusFilter &&
    !mappedStatusFilter.includes(ELIGIBLE_INVOICE_MAPPED_STATUS) &&
    totalInvoices > 0
  ) {
    return (
      <>
        No momento, <TotalInvoices>{totalInvoices}</TotalInvoices>
        {totalInvoices === 1 ? ' título está indisponível ' : ' títulos estão indisponíveis '}
        para antecipação. <br />
        Filtre por títulos disponíveis para solicitar sua antecipação.
      </>
    );
  }

  return (
    <>
      No momento, existem <TotalInvoices>{totalInvoices}</TotalInvoices>
      {totalInvoices === 1
        ? ' título.'
        : ' títulos entre disponíveis e indisponíveis para antecipação.'}
      <br />
      {totalInvoices > 0 ? 'Selecione os títulos disponíveis para solicitar sua antecipação.' : ''}
    </>
  );
};

export const VendorInvoices = ({
  beforeOperatingStartTime,
  beforeOperatingStartTimeMessage,
  totalInvoices,
  currentPage,
  getInvoices,
  filters,
  loading,
  canInputXml,
  inputXmlPath,
  buyerOperationMode,
  subscribe,
  pageListing,
  isFiltered,
  selectedInvoices,
  report,
  requestAnticipateAll,
  loadingButton,
  redirectUrl,
  isAdmin,
  sorting,
  token,
  limitError,
  updateShowAnticipationLimitError,
  onSubscribeToLimits,
  vendor,
  buyerIdentifier,
  showButton,
}) => {
  const [operatorInfo, setOperatorInfo] = useState({});

  useEffect(() => {
    const tokenDecoded = jwtDecode(token);
    const operator = {
      id: tokenDecoded.operator_id,
      name: tokenDecoded.name,
      legalRepresentative: tokenDecoded.legal_representative,
    };

    setOperatorInfo(operator);

    function brfOperationMsg() {
      const element = document.querySelector('[class^="CompleteTablestyles__DefaultEmptyState"]');
      const date = moment();
      const hour = date.format('hh:mm:ss');
      const dayOfWeek = date.day();

      const outOfoperationDay = dayOfWeek !== 2 && dayOfWeek !== 4;
      const outOfTimeCut = hour < '13:00:00' && hour > '16:30:00';
      const brfConditions = outOfoperationDay || outOfTimeCut;

      if (element && brfConditions) {
        element.textContent = `No momento, não temos títulos disponíveis,
                                  pois estamos fora do horário de operações`;
      }
    }

    if (buyerIdentifier === 'brf') {
      brfOperationMsg();
    }
  }, [vendor, token]);

  React.useEffect(() => {
    getInvoices();
  }, [currentPage, pageListing, filters, sorting]);

  const handleRequestAnticipateAll = useCallback(() => {
    requestAnticipateAll();
  }, [requestAnticipateAll, vendor, isAdmin, operatorInfo]);

  const [showAvailabilityDialog, setShowAvailabilityDialog] = useState(false);

  if (!isAdmin) {
    React.useEffect(() => {
      subscribe();
      onSubscribeToLimits();
    }, []);

    React.useEffect(() => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }, [redirectUrl]);

    React.useEffect(() => {
      const { successful, url, errorMessage } = report;
      if (successful) {
        window.open(url);
      } else if (errorMessage) {
        toast(
          {
            message: 'Erro ao baixar o Relatório da Simulação',
            info: `Ocorreu um erro ao baixar o relatório, tente novamente.
            Se o problema persistir, entre em contato com o suporte`,
          },
          'error',
          8000,
        );
      }
    }, [report]);
  }

  const showAvailabilityMessage = useMemo(() => buyerOperationMode === 'brf', [buyerOperationMode]);

  const handleCloseLimitDialog = () =>
    updateShowAnticipationLimitError({ show: false, surplusValue: 0 });

  return isAdmin ? (
    <LoadingContainer loading={loading}>
      <div>
        <InvoicesTable vendorName={vendor.name} operatorInfo={operatorInfo} />
      </div>
    </LoadingContainer>
  ) : (
    <LoadingContainer loading={loading}>
      <ToastContainer />
      <RootContainer>
        <DisableBootstrap />
        <UnavailableLimitDialog
          show={limitError?.show}
          onLeaved={handleCloseLimitDialog}
          surplusValue={limitError?.surplusValue}
        />
        <TitleContainer>
          <Title>Títulos</Title>
          <ButtonsContainer>
            {canInputXml ? (
              <ButtonLink href={inputXmlPath}>INSERIR NOTAS FISCAIS</ButtonLink>
            ) : null}
            <LoadingButton
              disabled={isFiltered || selectedInvoices.length > 0 || !totalInvoices}
              size="large"
              loading={loadingButton}
              ButtonComponent={PrimaryButton}
              onClick={handleRequestAnticipateAll}
            >
              ANTECIPAR TODOS
            </LoadingButton>
          </ButtonsContainer>
        </TitleContainer>
        <HeaderText>
          {getIntroductionText(totalInvoices, filters[MAPPED_STATUS_IN])}
          {showAvailabilityMessage}
        </HeaderText>
        {beforeOperatingStartTime ? (
          <ComunicationCard identifier="custom" customMessage={beforeOperatingStartTimeMessage} />
        ) : (
          <ComunicationCard identifier={buyerIdentifier} />
        )}
        <AnticipationLimitCard token={token} />
        {showButton && <VendorInterest token={token} vendor={vendor} />}
        <div>
          <InvoicesAvailabilityDialog
            open={showAvailabilityDialog}
            onClose={() => setShowAvailabilityDialog(false)}
          />
          <InvoicesTable
            buyerIdentifier={buyerIdentifier}
            vendorName={vendor.name}
            operatorInfo={operatorInfo}
          />
        </div>
      </RootContainer>
    </LoadingContainer>
  );
};

VendorInvoices.propTypes = {
  beforeOperatingStartTime: PropTypes.bool,
  beforeOperatingStartTimeMessage: PropTypes.string,
  totalInvoices: PropTypes.number,
  currentPage: PropTypes.number,
  getInvoices: PropTypes.func,
  filters: PropTypes.shape({
    [NUMBER_FILTER]: PropTypes.arrayOf(PropTypes.number),
    [CNPJ_FILTER]: PropTypes.string,
    [EXPIRES_EQ_FILTER]: PropTypes.string,
    [EXPIRES_GT_FILTER]: PropTypes.string,
    [EXPIRES_LT_FILTER]: PropTypes.string,
    [STATUS_FILTER]: PropTypes.string,
  }),
  loading: PropTypes.bool,
  canInputXml: PropTypes.bool,
  inputXmlPath: PropTypes.string,
  buyerOperationMode: PropTypes.string,
  subscribe: PropTypes.func,
  pageListing: PropTypes.number,
  isFiltered: PropTypes.bool,
  selectedInvoices: PropTypes.arrayOf(PropTypes.number),
  report: PropTypes.shape({
    successful: PropTypes.bool,
    url: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
  requestAnticipateAll: PropTypes.func,
  loadingButton: PropTypes.bool,
  redirectUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  sorting: PropTypes.shape({}),
  token: PropTypes.string,
  limitError: PropTypes.shape({
    show: PropTypes.bool,
    surplusValue: PropTypes.number,
  }),
  updateShowAnticipationLimitError: PropTypes.func,
  onSubscribeToLimits: PropTypes.func,
  vendor: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  }),
  buyerIdentifier: PropTypes.string,
  showButton: PropTypes.bool,
};

VendorInvoices.defaultProps = {
  beforeOperatingStartTime: false,
  beforeOperatingStartTimeMessage: null,
  totalInvoices: 0,
  currentPage: 1,
  getInvoices: () => null,
  filters: {
    [NUMBER_FILTER]: null,
    [CNPJ_FILTER]: null,
    [EXPIRES_EQ_FILTER]: null,
    [EXPIRES_GT_FILTER]: null,
    [EXPIRES_LT_FILTER]: null,
    [STATUS_FILTER]: null,
  },
  loading: false,
  canInputXml: false,
  inputXmlPath: null,
  buyerOperationMode: undefined,
  subscribe: () => {},
  pageListing: 10,
  isFiltered: false,
  selectedInvoices: [],
  report: {},
  requestAnticipateAll: () => {},
  loadingButton: false,
  redirectUrl: null,
  isAdmin: false,
  sorting: {},
  token: '',
  limitError: { show: false, surplusValue: 0 },
  updateShowAnticipationLimitError: () => {},
  onSubscribeToLimits: () => {},
  vendor: {},
  buyerIdentifier: 'default',
  showButton: false,
};

const mapStateToProps = ({
  vendorInvoices: {
    pagination: { count: totalInvoices, current: currentPage, per: pageListing },
    filters,
    loading,
    canInputXml,
    inputXmlPath,
    buyerOperationMode,
    isFiltered,
    selectedInvoices,
    report,
    loadingButton,
    redirectUrl,
    isAdmin,
    sorting,
    token,
    limitError,
    beforeOperatingStartTime,
    beforeOperatingStartTimeMessage,
  },
}) => ({
  totalInvoices,
  currentPage,
  filters,
  loading,
  canInputXml,
  inputXmlPath,
  buyerOperationMode,
  pageListing,
  isFiltered,
  selectedInvoices,
  report,
  loadingButton,
  redirectUrl,
  isAdmin,
  sorting,
  token,
  limitError,
  beforeOperatingStartTime,
  beforeOperatingStartTimeMessage,
});

const mapDispatchToProps = {
  getInvoices: fetchInvoices,
  subscribe: subscribeInvoices,
  requestAnticipateAll: anticipateInvoices,
  updateShowAnticipationLimitError: showAnticipationLimitError,
  onSubscribeToLimits: subscribeToLimits,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorInvoices);
