import { connect } from 'react-redux';
import Liquidations from './Liquidations';
import { getCalendarData } from '../../../actions/CalendarActions';
import { getInvoicesByLiquidation } from '../../../actions/InvoicesActions';

const mapStateToProps = ({ investorDashboard: { calendar } }) => ({ ...calendar });

const mapDispatchToProps = {
  getCalendarData,
  getInvoices: getInvoicesByLiquidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Liquidations);
