import styled from 'styled-components';
import { PrimaryButton as RawPrimaryButton } from 'liber-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ spacing }) => `${spacing}px`};
`;

export const PrimaryButton = styled(RawPrimaryButton).attrs(() => ({ version: 2, size: 'large' }))`
  text-transform: uppercase;
`;
