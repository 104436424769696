import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TooltipBox } from 'liber-components';
import { mdiPencil, mdiLockReset, mdiLogin, mdiInformation } from '@mdi/js';
import { FlexEnd, Icon, IconLink, IconButton } from './Actions.styles';
import { requestPasswordReset } from '../../actions/AdminVendorsActions';

export const Actions = ({ vendor, resetPassword }) => {
  const { showVendorPath, editVendorPath, id, loginAs } = vendor;
  return (
    <FlexEnd>
      <TooltipBox mount="top" fixed content="Visualizar fornecedor">
        <IconLink href={showVendorPath}>
          <Icon path={mdiInformation} />
        </IconLink>
      </TooltipBox>
      <TooltipBox mount="top" fixed content="Editar fornecedor">
        <IconLink href={editVendorPath}>
          <Icon path={mdiPencil} />
        </IconLink>
      </TooltipBox>
      <TooltipBox mount="top" fixed content="Resetar senha">
        <IconButton onClick={() => resetPassword(id)}>
          <Icon path={mdiLockReset} />
        </IconButton>
      </TooltipBox>
      {loginAs && (
        <TooltipBox mount="top" fixed content="Fazer login">
          <IconLink href={loginAs}>
            <Icon path={mdiLogin} />
          </IconLink>
        </TooltipBox>
      )}
    </FlexEnd>
  );
};

Actions.propTypes = {
  vendor: PropTypes.shape({
    id: PropTypes.number,
    showVendorPath: PropTypes.string,
    editVendorPath: PropTypes.string,
    loginAs: PropTypes.string,
    status: PropTypes.string,
  }),
  resetPassword: PropTypes.func,
};

Actions.defaultProps = {
  vendor: {
    status: '#',
    showVendorPath: '#',
    editVendorPath: '#',
    loginAs: '#',
    id: 0,
  },
  resetPassword: () => null,
};

const mapDispatchToProps = {
  resetPassword: requestPasswordReset,
};

export default connect(null, mapDispatchToProps)(Actions);
