export const previousStep = () => ({
  type: 'WIZARD_INVESTIDOR/PROGRESS_BAR/PREVIOUS_STEP',
});

export const nextStep = () => ({
  type: 'WIZARD_INVESTIDOR/PROGRESS_BAR/NEXT_STEP',
});

export const setCurrent = current => ({
  current,
  type: 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_CURRENT',
});

export const setCurrentString = current => ({
  current,
  type: 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_CURRENT_STRING',
});

export const setCompleted = completed => ({
  completed,
  type: 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_COMPLETED',
});

export const setCompletedString = completed => ({
  completed,
  type: 'WIZARD_INVESTIDOR/PROGRESS_BAR/SET_COMPLETED_STRING',
});
