import { connect } from 'react-redux';
import Selection from './Selection';

const mapStateToProps = state => ({
  operator: state.operatorSelection.operator,
  organizations: state.operatorSelection.organizations,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Selection);
