import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'liber-components';

import {
  Container,
  Header,
  Content,
  ActionsRow,
  Button,
  ButtonCancel,
  InputField,
} from './ConfirmModal.styles';

function ConfirmModal({ name, onLeaved, handleConfirm, unblock = false }) {
  const [reason, setReason] = useState('');

  const unblockPrefix = unblock ? 'des' : '';

  return (
    <Modal show onLeaved={onLeaved}>
      <Container>
        <Header>Confirme o {unblockPrefix}bloqueio</Header>
        <Content>
          <p>
            Você irá {unblockPrefix}bloquear o fornecedor <b>{name}</b>. Ao {unblockPrefix}
            bloqueá-lo, ele {unblock ? 'voltará a ter' : 'não terá'} títulos para antecipar pelo
            portal.
          </p>
          <p>
            Para continuar com a ação será necessário informar o motivo para o {unblockPrefix}
            bloqueio.
          </p>
          <InputField
            value={reason}
            onChange={setReason}
            label={`Motivo do ${unblockPrefix}bloqueio`}
          />
        </Content>
        <ActionsRow>
          <ButtonCancel onClick={onLeaved}>CANCELAR</ButtonCancel>
          <Button disabled={reason.length < 1} onClick={() => handleConfirm(reason)}>
            CONTINUAR
          </Button>
        </ActionsRow>
      </Container>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  onLeaved: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  unblock: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  unblock: false,
};

export default ConfirmModal;
