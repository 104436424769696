import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CompleteTable } from 'liber-components';

import { RadioWrapper, Spacer } from './styles';
import { RadioButton } from '../styles';
import { fundersColumns } from '../constants';

function FundersTable({ selectionEnabled, selectedFunderId, setSelectedFunderId, funders }) {
  const dynamicColumns = useMemo(
    () =>
      selectionEnabled
        ? [
            {
              name: '',
              label: <>&nbsp;&nbsp;&nbsp;Selecionar</>,
              width: 10,
              align: 'center',
              getCellValue: funder => (
                <RadioWrapper
                  data-testid={`funder-radio-${funder.bankCode}`}
                  onClick={() => setSelectedFunderId(funder.id)}
                >
                  <RadioButton
                    name="funder"
                    id={funder.bankCode}
                    checked={funder.id === selectedFunderId}
                  />
                </RadioWrapper>
              ),
            },
            ...fundersColumns,
          ]
        : fundersColumns,
    [selectionEnabled, selectedFunderId],
  );

  return (
    <>
      <Spacer />
      <CompleteTable columns={dynamicColumns} items={funders} />
    </>
  );
}

FundersTable.propTypes = {
  selectionEnabled: PropTypes.bool,
  selectedFunderId: PropTypes.number,
  setSelectedFunderId: PropTypes.func,
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.string,
      bankCode: PropTypes.string,
      limit: PropTypes.number,
    }),
  ),
};

FundersTable.defaultProps = {
  selectionEnabled: false,
  selectedFunderId: undefined,
  setSelectedFunderId: () => null,
  funders: [],
};

export default FundersTable;
