import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import { PrimaryButton } from 'liber-components';
import {
  Container,
  Flex,
  OverrideBootstrap,
  Tag,
  TagContent,
  FlatButton,
  Counter,
} from './Filters.styles';
import FilterModal from './FilterModal';
import {
  getLabelByFilterKey,
  getFilterParamsFromUrl,
  LABEL_XML,
  INVOICES_LABEL,
  INVESTOR_TYPE_LABEL,
  INVESTOR_TYPE_MAP,
  INVOICE_BASED_MAP,
  INVOICE_BASED_LABEL,
  RESPONSABILITY_LABEL,
  RESPONSABILITY_MAP,
} from './utils';
import {
  updateFilters,
  fetchAdminNegotiations,
  clearFilters,
} from '../../../actions/AdminNegotiationsActions';
import { STATUS_EQ } from '../../../utils';

export const Filters = ({ filteredCount, onFilter, filters, setFilters, onClearFilters }) => {
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    const newParams = getFilterParamsFromUrl();
    setFilters(newParams);
  }, []);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const filterKeysWithoutTags = key => !key.endsWith('_lteq]') && key !== STATUS_EQ;

  const hasFilterApplied = () => Object.keys(filters).filter(filterKeysWithoutTags).length > 0;

  const getCounterLabel = value =>
    `${value} ${value === 1 ? 'negociação filtrada' : 'negociações filtradas'}`;

  const isStartingDateKey = key => key.endsWith('_gteq]');

  const handleClearFilter = filterKey => {
    const currentFilters = { ...filters };
    delete currentFilters[filterKey];
    if (isStartingDateKey(filterKey)) {
      const correspondingEndingDateKey = filterKey.replace('gteq', 'lteq');
      delete currentFilters[correspondingEndingDateKey];
    }
    setFilters(currentFilters);
    onFilter();
  };

  const handleClearAllFilters = () => {
    onClearFilters();
  };

  const renderFilterTags = () => {
    const filterKeys = Object.keys(filters).filter(filterKeysWithoutTags);
    const filterKeysToShow = filterKeys.slice(0, 2);
    const remainingFiltersCount = filterKeys.length - 2;
    return (
      <>
        {filterKeysToShow.map(key => {
          let value = filters[key];

          const correspondingEndingDate = filters[key.replace('gteq', 'lteq')];
          if (isStartingDateKey(key) && value !== correspondingEndingDate) {
            value += ` a ${correspondingEndingDate}`;
          }

          let label = getLabelByFilterKey(key);

          if (label === INVOICES_LABEL) {
            const invoices = value.split(/[\s|,|;|-]+/);
            value = `${invoices[0]}${
              invoices.length > 1 ? ` (+ ${invoices.length - 1} títulos)` : ''
            }`;
          } else if (label === INVOICE_BASED_LABEL) {
            value = INVOICE_BASED_MAP[value];
          } else if (label === RESPONSABILITY_LABEL) {
            value = RESPONSABILITY_MAP[value];
          } else if (label === INVESTOR_TYPE_LABEL) {
            value = INVESTOR_TYPE_MAP[value];
          } else if (label === LABEL_XML) {
            value = LABEL_XML;
            label = `Anexo`;
          }

          return (
            <Tag key={key} closable onClose={() => handleClearFilter(key)}>
              <TagContent>
                <div>{value}</div>
                <div>{label}</div>
              </TagContent>
            </Tag>
          );
        })}
        {remainingFiltersCount > 0 && (
          <Tag>
            {`+ ${remainingFiltersCount} filtro${
              remainingFiltersCount > 1 ? 's aplicados' : ' aplicado'
            }`}
          </Tag>
        )}
      </>
    );
  };

  return (
    <>
      <OverrideBootstrap />
      <Container>
        <Flex>
          <PrimaryButton version={2} outlined onClick={() => setShowModal(true)}>
            <Icon path={mdiFilter} />
            Filtrar
          </PrimaryButton>
          {renderFilterTags()}
          {hasFilterApplied() && (
            <FlatButton id="clear-all-btn" onClick={handleClearAllFilters}>
              Limpar filtros
            </FlatButton>
          )}
        </Flex>
        {hasFilterApplied() && <Counter>{getCounterLabel(filteredCount)}</Counter>}
      </Container>
      <FilterModal show={showModal} onLeaved={onCloseModal} />
    </>
  );
};

Filters.propTypes = {
  filteredCount: PropTypes.number,
  onFilter: PropTypes.func,
  filters: PropTypes.shape({}),
  setFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
};

Filters.defaultProps = {
  filteredCount: 0,
  onFilter: () => null,
  filters: {},
  setFilters: () => {},
  onClearFilters: () => {},
};

const mapStateToProps = ({
  adminNegotiations: {
    pagination: { count: filteredCount },
    filters,
  },
}) => ({
  filteredCount,
  filters,
});

const mapDispatchToProps = {
  setFilters: updateFilters,
  onFilter: fetchAdminNegotiations,
  onClearFilters: clearFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
